import React, { useCallback, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';
import { ApiRequestOptions, ApiResult, ApiSaveResult } from '../../api/apiHooks';
import { CompaniesUsersJobsRequest } from '../../api/requests/companiesUsersJobsRequest';
import { CompaniesUsersRolesRequest } from '../../api/requests/companiesUsersRolesRequest';
import {
  CompaniesUsersStatisticsRequest,
  TaskProfileIncludeStatistics,
} from '../../api/requests/companiesUsersStatisticsRequest';
import { CompaniesUsersTasksRequest } from '../../api/requests/companiesUsersTasksRequest';
import { CompanyQualificationRequest } from '../../api/requests/companyQualificationRequest';
import { CustomViewType } from '../../api/requests/customViewType';
import { DeleteCustomViewRequest } from '../../api/requests/deleteCustomViewRequest';
import { CompaniesUsersJobsResponse } from '../../api/responses/companiesUsersJobsResponse';
import { CompaniesUsersRolesResponse } from '../../api/responses/companiesUsersRolesResponse';
import { CompaniesUsersTasksResponse } from '../../api/responses/companiesUsersTasksResponse';
import { CompanyQualificationResponse } from '../../api/responses/companyQualificationResponse';
import { CompanyResponse } from '../../api/responses/companyResponse';
import { CustomViewReport } from '../../api/responses/customViewReport';
import { GetCustomViewAccessUsersResponse } from '../../api/responses/getCustomViewAccessUsersResponse';
import { UserAccessResponse } from '../../api/responses/userAccessResponse';
import { UserComponentResponse } from '../../api/responses/userComponentResponse';
import { UserInformation } from '../../api/responses/userInformation';
import { UserStatisticsResponse } from '../../api/responses/userStatisticsResponse';
import { UserTrainingResponse } from '../../api/responses/userTrainingResponse';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import Paging from '../../components/Layout/Paging/Paging';
import SwipeContainer from '../../components/Layout/SwipeContainer/SwipeContainer';
import TaskProfileCardGrid from '../../components/TaskProfile/CardGrid/TaskProfileCardGrid';
import TaskProfileDetailModalContainer from '../../components/TaskProfile/DetailModal/TaskProfileDetailModalContainer';
import { TaskProfileModalProps } from '../../components/TaskProfile/Modal/TaskProfileModalProps';
import { getCardDisplayOptionIncludeParams } from '../../components/TaskProfile/ReportDrawer/taskProfileCardDisplayOptions';
import TaskProfileReportDrawer from '../../components/TaskProfile/ReportDrawer/TaskProfileReportDrawer';
import TaskProfileTopNavBar from '../../components/TaskProfile/TopNavBar/TaskProfileTopNavBar';
import { OneSecondInMilliseconds, ThirtySecondsInMilliseconds } from '../../constants/measurements';
import routes from '../../constants/routes';
import CustomViewAccessType from '../../models/customViewAccessType';
import { CustomViewSaveMutations } from '../../models/customViewSaveMutations';
import defaultTaskProfileReportData from '../../models/defaultTaskProfileReportData';
import { DisplayItemWithCustomViewAccessType } from '../../models/displayItemWithCustomViewAccessType';
import { TaskProfileReportData } from '../../models/taskProfileReportData';
import { UseDisplayItemsResponse } from '../../models/useDisplayItemsResponse';
import { AvailablePolicies } from '../../policyService/policyEnforcer/policyEnforcer';
import file from '../../utils/file';
import pagingUtils from '../../utils/pagingUtils';
import { UserHook } from '../../hooks/useUserId';
import ReportRequestFilter, {
  JobCompletionStatus,
  ReportRequestOutputType,
  ReportRequestType,
} from '../../api/requests/ReportRequestFilter';
import JobAssocMethod from '../../models/JobAssocMethod';
import { JobStatsResponse } from '../../api/responses/jobStatsResponse';

export interface TaskProfileReportProps {
  reportHeader: string;
  customViewId?: number;
  canDeleteView: boolean;
  reportDataResult: ApiResult<CustomViewReport<TaskProfileReportData>>;
  saveMutation: CustomViewSaveMutations;
  deleteMutation: ApiSaveResult<DeleteCustomViewRequest, void>;
  customViewAccessUsersResult: ApiResult<GetCustomViewAccessUsersResponse>;
  policyService: { policies: AvailablePolicies; isLoading: boolean; isError: boolean };
  messageOfTheDayImageResult: string;
  onGetUserStatistics: (
    requestParams: CompaniesUsersStatisticsRequest,
    options?: ApiRequestOptions<UserStatisticsResponse, UserStatisticsResponse>
  ) => ApiResult<UserStatisticsResponse>;
  useQueryParam: () => URLSearchParams;
  companyId: number;
  company: CompanyResponse | undefined;
  user: UserInformation | undefined;
  isUserLoading: boolean;
  isCompanyLoading: boolean;
  useEventListener: (
    element: Window | Document | null | undefined,
    eventType: string,
    listener: (this: Document, evt: Event) => void
  ) => void;
  useInterval: (callBack: () => void, interval: number | undefined) => void;
  useMessageOfTheDayInactivity: (
    isEnabled: boolean,
    isShownWhenInactive: boolean,
    isUserDetailModalOpen: boolean,
    onInactive: () => void
  ) => void;
  useCostCenters: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useDepartments: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useEmployees: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useJobs: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useRoles: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useSupervisors: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useWorkLocations: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useSharedUsers: (
    companyId: number,
    searchText: string
  ) => UseDisplayItemsResponse<DisplayItemWithCustomViewAccessType>;
  useCompanyQualifications: (
    requestParams: CompanyQualificationRequest,
    options?:
      | ApiRequestOptions<CompanyQualificationResponse, CompanyQualificationResponse>
      | undefined
  ) => ApiResult<CompanyQualificationResponse>;
  useCompaniesUsersTasks: (
    requestParams: CompaniesUsersTasksRequest,
    options?:
      | ApiRequestOptions<CompaniesUsersTasksResponse, CompaniesUsersTasksResponse>
      | undefined
  ) => ApiResult<CompaniesUsersTasksResponse>;
  useCompaniesUsersRoles: (
    requestParams: CompaniesUsersRolesRequest,
    options?:
      | ApiRequestOptions<CompaniesUsersRolesResponse, CompaniesUsersRolesResponse>
      | undefined
  ) => ApiResult<CompaniesUsersRolesResponse>;
  useCompaniesUsersComponents: (
    requestParams: CompaniesUsersTasksRequest,
    options?: ApiRequestOptions<UserComponentResponse, UserComponentResponse> | undefined
  ) => ApiResult<UserComponentResponse>;
  useCompaniesUsersTrainings: (
    requestParams: CompaniesUsersTasksRequest,
    options?: ApiRequestOptions<UserTrainingResponse, UserTrainingResponse> | undefined
  ) => ApiResult<UserTrainingResponse>;
  useCompaniesUsersJobs: (
    requestParams: CompaniesUsersJobsRequest,
    options?: ApiRequestOptions<CompaniesUsersJobsResponse, CompaniesUsersJobsResponse> | undefined
  ) => ApiResult<CompaniesUsersJobsResponse>;
  useUserId: () => UserHook;
  useReportRequest: (
    requestParams: ReportRequestFilter,
    options?: ApiRequestOptions<CompaniesUsersJobsResponse, CompaniesUsersJobsResponse> | undefined
  ) => ApiResult<CompaniesUsersJobsResponse>;
  onRequestJobStats: (
    requestParams: ReportRequestFilter,
    options?: ApiRequestOptions<JobStatsResponse, JobStatsResponse> | undefined
  ) => ApiResult<JobStatsResponse>;
}

const TaskProfileReport: React.FC<TaskProfileReportProps> = ({
  reportHeader,
  customViewId = undefined,
  canDeleteView,
  reportDataResult,
  saveMutation,
  deleteMutation,
  customViewAccessUsersResult,
  policyService,
  messageOfTheDayImageResult,
  onGetUserStatistics,
  useQueryParam,
  companyId,
  company,
  isUserLoading,
  isCompanyLoading,
  user,
  useEventListener,
  useInterval,
  useMessageOfTheDayInactivity,
  useCostCenters,
  useDepartments,
  useEmployees,
  useJobs,
  useRoles,
  useSharedUsers,
  useSupervisors,
  useWorkLocations,
  useCompanyQualifications,
  useCompaniesUsersTasks,
  useCompaniesUsersRoles,
  useCompaniesUsersComponents,
  useCompaniesUsersTrainings,
  useCompaniesUsersJobs,
  useUserId,
  useReportRequest,
  onRequestJobStats,
}) => {
  const navigate = useNavigate();
  const params = useQueryParam();
  const drawerExpandedInitialState = !!params.get(
    routes.taskProfileReport.queryParams.isDrawerExpanded
  );
  const [drawerExpanded, setDrawerExpanded] = useState(drawerExpandedInitialState);
  const [take, setTake] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [reportData, setReportData] = useState<TaskProfileReportData>(defaultTaskProfileReportData);
  const [reportName, setReportName] = useState(reportHeader);
  const [sharedUserIds, setSharedUserIds] = useState<UserAccessResponse[]>([]);
  const [messageOfTheDayImage, setMessageOfTheDayImage] = useState<File | undefined>(undefined);
  const [messageOfTheDayImageUrl, setMessageOfTheDayImageUrl] = useState<string>('');
  const [isInitialDataFetched, setIsInitialDataFetched] = useState(false);
  const [searchByText, setSearchByText] = useState('');
  const { userId: currentUserId } = useUserId();

  const isShowingMessageOfTheDay = reportData.messageOfTheDay.isEnabled && currentPage === 1;
  useEffect(() => {
    if (reportDataResult.data !== undefined) {
      setReportName(reportDataResult.data.name);
      setReportData(reportDataResult.data.reportData);
      setIsInitialDataFetched(true);
    }
  }, [reportDataResult.data]);

  useEffect(() => {
    if (customViewAccessUsersResult.data !== undefined) {
      setSharedUserIds(customViewAccessUsersResult.data.userAccessResponses);
    }
  }, [customViewAccessUsersResult.data]);

  useEffect(() => {
    setMessageOfTheDayImageUrl(messageOfTheDayImageResult);
  }, [messageOfTheDayImageResult]);

  useEffect(() => {
    if (deleteMutation.isSuccess) {
      const timer = setTimeout(() => {
        navigate(routes.viewsReport.url());
      }, OneSecondInMilliseconds);

      return () => clearTimeout(timer);
    }

    return () => {};
  }, [deleteMutation.isSuccess, navigate]);

  const {
    data: companyQualifications,
    isLoading: isLoadingQualifications,
    isError: isErrorQualifications,
  } = useCompanyQualifications(
    {
      companyId,
      skip: reportData.messageOfTheDay.isEnabled
        ? Math.max((currentPage - 2) * take, 0)
        : Math.max((currentPage - 1) * take, 0),
      take,
      sortBy: reportData.sortBy,
      userIds: reportData.userIds,
      costCenterIds: reportData.costCenterIds,
      departmentIds: reportData.departmentIds,
      jobIds: reportData.jobIds,
      roleIds: reportData.roleIds,
      supervisorIds: reportData.supervisorIds,
      workLocationIds: reportData.workLocationIds,
      searchBy: searchByText,
    },
    { keepPreviousData: true, enabled: isInitialDataFetched }
  );

  const includeStatisticsParams = getCardDisplayOptionIncludeParams(reportData.cardDisplayOptions);

  let totalPages = pagingUtils.getTotalPages(companyQualifications?.ItemCount ?? 0, take);
  if (reportData.messageOfTheDay.isEnabled) {
    totalPages += 1;
  }

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(Math.max(1, totalPages));
    }
  }, [currentPage, totalPages]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalProps, setModalProps] = useState<TaskProfileModalProps>();

  const popModal = useCallback((taskProfileModalProps: TaskProfileModalProps) => {
    setModalProps(taskProfileModalProps);
    setIsModalOpen(prevIsModalOpen => !prevIsModalOpen);
  }, []);

  const handlePageResize = useCallback((numCardsShown: number) => {
    setTake(numCardsShown);
  }, []);

  const handleReportDataChange = (changedReportData: TaskProfileReportData) => {
    const firstPage = 1;
    setCurrentPage(firstPage);
    setReportData(changedReportData);
  };

  const handleMessageOfTheDayImageChange = (imageFile: File | undefined) => {
    file.getDataUrlForImageFile(imageFile, imageUrl => {
      setMessageOfTheDayImage(imageFile);
      setMessageOfTheDayImageUrl(imageUrl);
    });
  };

  const handleDeleteReport = () => {
    if (customViewId !== undefined) {
      deleteMutation.mutate({ companyId, customViewId });
    }
  };

  const handleSearch = useCallback(
    (text: string) => {
      const firstPage = reportData.messageOfTheDay.isEnabled ? 2 : 1;
      setCurrentPage(firstPage);
      setSearchByText(text);
    },
    [reportData.messageOfTheDay.isEnabled]
  );

  const [isFullScreenActive, fullscreenchange] = useState(false);

  useEventListener(window, 'fullscreenchange', () => {
    fullscreenchange(prevState => !prevState);
  });

  function GetAutoScrollInterval(): number {
    if (reportData.scrollingOptions.scrollingIntervalMilliseconds <= ThirtySecondsInMilliseconds) {
      return ThirtySecondsInMilliseconds;
    }
    return reportData.scrollingOptions.scrollingIntervalMilliseconds;
  }

  useInterval(
    () => setCurrentPage(current => pagingUtils.getNextPage(current, totalPages)),
    reportData.scrollingOptions.isScrolling ? GetAutoScrollInterval() : undefined
  );

  const handleMessageOfTheDayInactive = useCallback(() => {
    setCurrentPage(1);
    setIsModalOpen(false);
  }, []);

  useMessageOfTheDayInactivity(
    reportData.messageOfTheDay.isEnabled,
    reportData.messageOfTheDay.isShownWhenInactive,
    isModalOpen,
    handleMessageOfTheDayInactive
  );

  const handleSwipeLeft = () => setCurrentPage(Math.min(totalPages, currentPage + 1));
  const handleSwipeRight = () => setCurrentPage(Math.max(1, currentPage - 1));

  function handleOnGetJobStatistics(userId: number) {
    return onRequestJobStats(
      {
        currentCompanyId: companyId,
        currentUserId,
        reportType: ReportRequestType.JOB_READINESS_ONBOARD_LIVE_STATS,
        reportOutputType: ReportRequestOutputType.UI,
        includeContractors: false,
        companyIds: [companyId],
        costCenterIds: [],
        departmentIds: [],
        enforceTaskProfiling: true,
        jobMatrixIds: [],
        supervisorIds: [],
        userIds: [userId],
        workLocationIds: [],
        jobCompletionStatus: JobCompletionStatus.All,
      },
      {
        // Check if feature flag is enabled AND the includeStatisticsParams says job stats are turned on, so we don't call the new
        // job stats endpoint just because they're on the feature flag
        enabled:
          policyService.policies.canViewNewJobReport &&
          includeStatisticsParams.length > 0 &&
          includeStatisticsParams.findIndex(item => item === TaskProfileIncludeStatistics.jobs) >=
            0,
      }
    );
  }

  function handleOnGetUserStatistics(userId: number) {
    return onGetUserStatistics(
      {
        userId,
        companyId,
        roleIds: reportData.roleIds,
        include: includeStatisticsParams,
      },
      {
        keepPreviousData: true,
        enabled: includeStatisticsParams.length > 0,
      }
    );
  }

  // Set Job association method based on `canViewNewJobReport` which is based on a feature flag.
  useEffect(() => {
    setReportData(currentReportData => ({
      ...currentReportData,
      detailDisplayModes: {
        jobsAssocMethod: policyService.policies.canViewNewJobReport
          ? JobAssocMethod.JobsRespectMappingsAndTaskProfiling
          : JobAssocMethod.Jobs,
      },
    }));
  }, [policyService.policies.canViewNewJobReport]);

  // TODO:  If we end up needing to get job stats via Reporting API then
  // Add another handler for Reporting API's function. Give it student's userId
  // Since ours is conditional (feature flag), we can set the ApiRequstOptions' enabled value based on whether flag is true or false
  return (
    <MainLayout
      isCompanyLoading={isCompanyLoading}
      companyImage={company?.logo}
      companyName={company?.name}
      policies={policyService.policies}
      isSidebarVisible={!isFullScreenActive}
    >
      <TaskProfileTopNavBar
        isFullscreenActive={isFullScreenActive}
        reportName={reportHeader}
        onSearch={handleSearch}
        user={user}
        isUserLoading={isUserLoading}
      />
      <SwipeContainer
        className="flex-grow-1 py-0 ps-0 pe-5 overflow-hidden h-100"
        onSwipeLeft={handleSwipeLeft}
        onSwipeRight={handleSwipeRight}
        fluid
      >
        <TaskProfileCardGrid
          data={companyQualifications?.ResultData ?? []}
          features={reportData.cardDisplayOptions}
          primaryDataPoint={reportData.primaryDataPoint}
          isLoading={reportDataResult.isLoading || isLoadingQualifications}
          isError={reportDataResult.isError || isErrorQualifications}
          isShowingMessageOfTheDay={isShowingMessageOfTheDay}
          messageOfTheDayTitle={reportData.messageOfTheDay.title}
          messageOfTheDayDescription={reportData.messageOfTheDay.description}
          messageOfTheDayImage={messageOfTheDayImageUrl}
          onCardClick={popModal}
          onResize={handlePageResize}
          onGetUserStatistics={handleOnGetUserStatistics}
          onGetJobStatistics={handleOnGetJobStatistics}
          jobAssocMethod={
            policyService.policies.canViewNewJobReport
              ? JobAssocMethod.JobsRespectMappingsAndTaskProfiling
              : JobAssocMethod.Jobs
          }
        />
      </SwipeContainer>
      <Row className="justify-content-center pe-5 ">
        <Col xs="auto">
          <Paging totalPages={totalPages} currentPage={currentPage} onClick={setCurrentPage} />
        </Col>
      </Row>
      <TaskProfileDetailModalContainer
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        userId={modalProps?.userId ?? -1}
        companyId={companyId}
        fullName={modalProps?.fullName ?? ''}
        userName={modalProps?.userName ?? ''}
        roleIds={reportData.roleIds}
        jobIds={reportData.jobIds}
        features={reportData.detailDisplayOptions}
        jobAssocMethod={
          policyService.policies.canViewNewJobReport
            ? JobAssocMethod.JobsRespectMappingsAndTaskProfiling
            : JobAssocMethod.Jobs
        }
        useCompaniesUsersTasks={useCompaniesUsersTasks}
        useCompaniesUsersRoles={useCompaniesUsersRoles}
        useCompaniesUsersComponents={useCompaniesUsersComponents}
        useCompaniesUsersTrainings={useCompaniesUsersTrainings}
        useCompaniesUsersJobs={useCompaniesUsersJobs}
        useUserId={useUserId}
        useReportRequest={useReportRequest}
      />
      {policyService.policies.canViewTaskProfileDrawer ? (
        <TaskProfileReportDrawer
          companyId={companyId}
          reportName={reportName}
          reportData={reportData}
          sharedUserIds={sharedUserIds}
          onReportNameChange={setReportName}
          onReportDataChange={handleReportDataChange}
          onSharedUserIds={setSharedUserIds}
          messageOfTheDayImageUrl={messageOfTheDayImageUrl}
          onMessageOfTheDayImageChange={handleMessageOfTheDayImageChange}
          onSave={() =>
            saveMutation.onSave(
              {
                reportName,
                reportType: CustomViewType.taskProfile,
                reportData,
              },
              sharedUserIds
                .filter(value => value.customViewAccessType !== CustomViewAccessType.Owner)
                .map(value => ({
                  userId: value.userId,
                  customViewRequestAccessType: value.customViewAccessType as Exclude<
                    CustomViewAccessType,
                    CustomViewAccessType.Owner
                  >,
                })),
              messageOfTheDayImage
            )
          }
          isSaveLoading={saveMutation.isLoading}
          isSaveError={saveMutation.isError}
          isSaveSuccess={saveMutation.isSuccess}
          onDelete={handleDeleteReport}
          isDeleteLoading={deleteMutation.isLoading}
          isDeleteError={deleteMutation.isError}
          isDeleteSuccess={deleteMutation.isSuccess}
          canDelete={canDeleteView}
          expanded={drawerExpanded}
          onDrawerChange={() => setDrawerExpanded(prevState => !prevState)}
          policies={policyService.policies}
          useCostCenters={useCostCenters}
          useDepartments={useDepartments}
          useEmployees={useEmployees}
          useJobs={useJobs}
          useRoles={useRoles}
          useSupervisors={useSupervisors}
          useWorkLocations={useWorkLocations}
          useSharedUsers={useSharedUsers}
        />
      ) : undefined}
    </MainLayout>
  );
};

export default TaskProfileReport;
