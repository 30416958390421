import { DateTime } from 'luxon';

//  Table of Tokens for formatting can be found at
//  https://moment.github.io/luxon/#/formatting?id=table-of-tokens
const formatDate = (date: string | null | undefined, format: string): string => {
  if (date === undefined || date === null) return '';

  return DateTime.fromISO(date).toFormat(format);
};

function fromDate(date: Date, format: string, zone = 'utc'): string {
  return DateTime.fromJSDate(date, { zone: zone }).toFormat(format);
}

export default { formatDate, fromDate };
