import React, { useEffect, useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import { IoMdRefresh } from 'react-icons/io';
import { CompanySftpUserResponse } from '../../../api/responses/getCompanySftpUserResponse';
import testIds from '../../../constants/testIds';
import dateFormatter from '../../../utils/dateFormatter';
import ButtonWithLabelAndIcon from '../../Buttons/ButtonWithLabelAndIcon/ButtonWithLabelAndIcon';
import CopyButton from '../CopyButton';
import SshKeyModal from '../SshkeyModal/SshKeyModal';

export interface UserCredentialsCardProps {
  companySftpUsers: CompanySftpUserResponse[];
  onGenerateSftpCredentials: () => void;
  onRegenerateSftpCredentials: () => void;
  isCreateSftpCredentialsLoading: boolean;
  isRegenerateSftpCredentialsLoading: boolean;
  privateKey: string;
}

const UserCredentialsCard: React.FC<UserCredentialsCardProps> = ({
  companySftpUsers,
  onGenerateSftpCredentials,
  onRegenerateSftpCredentials,
  isCreateSftpCredentialsLoading,
  isRegenerateSftpCredentialsLoading,
  privateKey,
}) => {
  const [companySftpUser] = companySftpUsers;
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (privateKey !== '') {
      handleToggleModal(true);
    }
  }, [privateKey]);

  function handleToggleModal(isOpen: boolean) {
    setIsModalOpen(isOpen);
  }

  return (
    <>
      <Card className="mt-4" data-testid={testIds.userCredentialsCard()}>
        <Card.Header className="bg-gray">
          <h5 className="fw-bold">User Credentials</h5>
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-items-center mb-2">
            <span className="font-size-m fw-bold">Connection Url:</span>
            <span className="font-size-m ms-2">
              {process.env.REACT_APP_SFTP_CONNECTION_URL as string}
            </span>
            <CopyButton
              classname="ms-2"
              textToCopy={process.env.REACT_APP_SFTP_CONNECTION_URL as string}
              toolTipPlacement="right"
            />
          </div>
          <Table>
            <thead>
              <tr>
                <td className="fw-bold">Username</td>
                <td className="fw-bold">Created Date</td>
                <td />
              </tr>
            </thead>
            <tbody>
              {companySftpUsers.length === 0 ? (
                <tr>
                  <td>
                    <ButtonWithLabelAndIcon
                      className="d-flex align-items-center ms-2 rounded-pill"
                      onClick={onGenerateSftpCredentials}
                      buttonColor="transparent"
                      icon={<IoMdRefresh color="green" size={15} />}
                      label="Generate"
                      iconBackgroundColor="bg-transparent"
                      iconFront
                      isLoading={isCreateSftpCredentialsLoading}
                    />
                  </td>
                  <td />
                  <td />
                </tr>
              ) : (
                <tr key={companySftpUser.Id}>
                  <td className="align-middle">{companySftpUser.SftpUsername}</td>
                  <td className="align-middle">
                    {dateFormatter.formatDate(companySftpUser.CredentialsDateCreated, 'MM/dd/yyyy')}
                  </td>
                  <td className="align-middle p-1">
                    <ButtonWithLabelAndIcon
                      className="d-flex align-items-center ms-2 rounded-pill"
                      onClick={onRegenerateSftpCredentials}
                      buttonColor="transparent"
                      icon={<IoMdRefresh color="green" size={15} />}
                      label="Regenerate"
                      iconBackgroundColor="bg-transparent"
                      iconFront
                      isLoading={isRegenerateSftpCredentialsLoading}
                      size="sm"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <SshKeyModal
        privateKey={privateKey}
        isModalOpen={isModalOpen}
        sshUsername={companySftpUsers.length > 0 ? companySftpUser.SftpUsername : ''}
        toggleModal={handleToggleModal}
      />
    </>
  );
};

export default UserCredentialsCard;
