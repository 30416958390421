// eslint-disable-next-line import/no-cycle
import api from '../api/api';
import { AuthSession } from '../models/authSession';
import authUtils from './authUtils';

const AuthStorageKey = {
  AuthToken: 'token',
};

async function getAuthSession(): Promise<AuthSession> {
  const session = await isUserAuthenticated();
  if (!session) {
    throw Error('Invalid session');
  }

  if (new Date(session.token.expiresAt) < new Date(Date.now())) {
    return refreshSession(session.user.userId, session.token.refreshToken);
  }

  return session;
}

async function refreshSession(userId: number, refreshToken: string): Promise<AuthSession> {
  const responseData = await api.refreshToken(userId, refreshToken);
  const authSession = authUtils.getAuthSession(userId, refreshToken, responseData);
  localStorage.setItem(AuthStorageKey.AuthToken, JSON.stringify(authSession));
  return authSession;
}

async function isUserAuthenticated(): Promise<AuthSession | undefined> {
  const sessionString = localStorage.getItem(AuthStorageKey.AuthToken);
  if (!sessionString) {
    return undefined;
  }

  // TODO: Add logic to ensure the json string is actually of type AuthSession
  return JSON.parse(sessionString) as AuthSession;
}

export default {
  getAuthSession,
  refreshSession,
  isUserAuthenticated,
};
