import {
  LearningHistoryReport,
  LearningHistoryReportResponse,
} from '../responses/learningHistoryReportResponse';

export default function convert(
  data: LearningHistoryReportResponse[] | undefined
): LearningHistoryReport[] | undefined {
  if (data === undefined) return undefined;
  const temp: { [key: string]: LearningHistoryReport } = {};

  data.forEach((item: LearningHistoryReportResponse) => {
    if (temp[item.User_Id] !== undefined) {
      temp[item.User_Id].Qualifications.push({
        Activities: item.Activities,
        Is_Current: item.Is_Current,
        Qualification_Completed: item.Qualification_Completed,
        Qualification_Expires: item.Qualification_Expires,
        Qualification_Report_Id: item.Qualification_Report_Id,
        Qualification_Status: item.Qualification_Status,
        Qualification_Status_Code: item.Qualification_Status_Code,
        Reason: item.Reason,
        Series_Id: item.Series_Id,
        Task_Code: item.Task_Code,
        Task_Id: item.Task_Id,
        Task_Name: item.Task_Name,
        User_Cost_Center_Id: item.User_Cost_Center_Id,
        User_Cost_Center_Name: item.User_Cost_Center_Name,
        User_Department_Id: item.User_Department_Id,
        User_Department_Name: item.User_Department_Name,
        User_Name: item.User_Name,
        User_Work_Location_Id: item.User_Work_Location_Id,
        User_Work_Location_Name: item.User_Work_Location_Name,
      });
    } else {
      temp[item.User_Id] = {
        Company_Id: item.Company_Id,
        Company_Name: item.Company_Name,
        User_Full_Name: item.User_Full_Name,
        User_Id: item.User_Id,
        User_Name: item.User_Name,
        Qualifications: [
          {
            Activities: item.Activities,
            Is_Current: item.Is_Current,
            Qualification_Completed: item.Qualification_Completed,
            Qualification_Expires: item.Qualification_Expires,
            Qualification_Report_Id: item.Qualification_Report_Id,
            Qualification_Status: item.Qualification_Status,
            Qualification_Status_Code: item.Qualification_Status_Code,
            Reason: item.Reason,
            Series_Id: item.Series_Id,
            Task_Code: item.Task_Code,
            Task_Id: item.Task_Id,
            Task_Name: item.Task_Name,
            User_Cost_Center_Id: item.User_Cost_Center_Id,
            User_Cost_Center_Name: item.User_Cost_Center_Name,
            User_Department_Id: item.User_Department_Id,
            User_Department_Name: item.User_Department_Name,
            User_Name: item.User_Name,
            User_Work_Location_Id: item.User_Work_Location_Id,
            User_Work_Location_Name: item.User_Work_Location_Name,
          },
        ],
      };
    }
  });
  return Object.values(temp);
}
