import { useEffect } from 'react';
import useIsFirstRender from './useIsFirstRender';

const useEffectAfterFirstRender: typeof useEffect = (effect, deps) => {
  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    if (!isFirstRender) {
      return effect();
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useEffectAfterFirstRender;
