import localStorageKeys from '../constants/localStorageKeys';
import Radix from '../constants/radix';

export interface UseCompanyIdHook {
  companyId: number;
  setCompanyId: (companyId: number) => void;
}

export default function useCompanyId(): UseCompanyIdHook {
  return {
    companyId: parseInt(localStorage.getItem(localStorageKeys.companyId) ?? '-1', Radix.Default),
    setCompanyId: (companyId: number) =>
      localStorage.setItem(localStorageKeys.companyId, `${companyId}`),
  };
}
