import React from 'react';
import { Navigate, Params, useParams } from 'react-router-dom';
import useRefreshToken from '../../auth/useRefreshToken';
import Routes from '../../constants/routes';
import { AvailablePolicies } from '../../policyService/policyEnforcer/policyEnforcer';
import usePolicyService from '../../policyService/usePolicyService';
import Loading from '../Loading/Loading';

interface ProtectedRouteProps {
  validate: (
    policyService: (customViewId?: number) => AvailablePolicies,
    routeProps: Params<string>
  ) => boolean;
  callbackUrl: string;
  children: React.ReactNode;
}

function ProtectedRoute({ validate, callbackUrl, children }: ProtectedRouteProps): JSX.Element {
  const { error } = useRefreshToken();
  const { policies, isLoading, isError } = usePolicyService();
  const params = useParams();

  if (error) {
    return (
      <Navigate
        to={Routes.errorPage.url({
          callbackUrl: Routes.errorPage.path,
        })}
      />
    );
  }

  if (isError) {
    return <Navigate to={Routes.errorPage.url({ callbackUrl })} />;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (!validate(policies, params)) {
    return <Navigate to={Routes.errorPage.url({ callbackUrl })} />;
  }

  return <>{children}</>;
}

export default ProtectedRoute;
