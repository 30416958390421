import { ComputedDatum } from '@nivo/bar';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { UserInformation } from '../../api/responses/userInformation';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import TopNavbar from '../../components/Layout/TopNavBar/TopNavBar';
import { AvailablePolicies } from '../../policyService/policyEnforcer/policyEnforcer';
import { BatchUserUploadIntegrationDisplayItem } from '../../hooks/useIntegrationBatchStatisticsByCompanyId';
import CurrentIntegrationsTable from '../../components/IntegrationAnalytics/CurrentIntegrationsTable';
import CurrentIntegrationsResponse from '../../models/CurrentIntegrationsResponse';
import { Container } from 'react-bootstrap';
import RecentUploadsChart, {
  BatchUserStatisticBarData,
} from '../../components/IntegrationAnalytics/RecentUploadsChart';

export interface IntegrationAnalyticsProps {
  isCompanyLoading: boolean;
  companyImage?: string;
  companyName?: string;
  policies: Pick<
    AvailablePolicies,
    | 'canViewTaskProfile'
    | 'canViewCustomView'
    | 'canViewActivities'
    | 'canViewIntegrationHub'
    | 'canViewLearningHistory'
  >;
  isUserLoading: boolean;
  user: UserInformation | undefined;
  integrationBatchStatisticsByCompanyIdResponses: BatchUserUploadIntegrationDisplayItem[];
  isIntegrationBatchStatisticsByCompanyIdLoading: boolean;
  currentIntegrations: CurrentIntegrationsResponse[];
  isCurrentIntegrationsLoading: boolean;
  setSelectedIntegration: (currentIntegration: CurrentIntegrationsResponse) => void;
  selectedIntegration: CurrentIntegrationsResponse;
  handleBarClick: (column: string, date: string) => void;
  currentIntegrationsData: CurrentIntegrationsResponse[];
  isCurrentIntegrationsDataLoading: boolean;
  currentIntegrationsDataError: boolean;
}

const IntegrationsAnalytics: React.FC<IntegrationAnalyticsProps> = ({
  companyImage,
  companyName,
  isCompanyLoading,
  policies,
  isUserLoading,
  user,
  currentIntegrations,
  isCurrentIntegrationsLoading,
  selectedIntegration,
  setSelectedIntegration,
  integrationBatchStatisticsByCompanyIdResponses,
  isIntegrationBatchStatisticsByCompanyIdLoading,
  handleBarClick,
  currentIntegrationsData,
  isCurrentIntegrationsDataLoading,
  currentIntegrationsDataError,
}) => {
  const navigateToIntegrationHistory = (e: ComputedDatum<BatchUserStatisticBarData>) => {
    handleBarClick(e.id as string, e.data.batchDate);
  };

  return (
    <MainLayout
      policies={policies}
      companyImage={companyImage}
      companyName={companyName}
      isCompanyLoading={isCompanyLoading}
    >
      <TopNavbar reportName="Analytics" user={user} isUserLoading={isUserLoading} />
      <Container fluid className="h-100">
        <Row className="h-100">
          <Col>
            <Row className="mt-4">
              <Col>
                <h5 color="primary" className="fw-bold">
                  Recent Uploads
                </h5>
                <RecentUploadsChart
                  userStatistics={integrationBatchStatisticsByCompanyIdResponses}
                  isBatchUserStatisticsLoading={isIntegrationBatchStatisticsByCompanyIdLoading}
                  onBarClick={navigateToIntegrationHistory}
                  currentIntegrations={currentIntegrations}
                  isCurrentIntegrationsLoading={isCurrentIntegrationsLoading}
                  selectedIntegration={selectedIntegration}
                  setSelectedIntegration={setSelectedIntegration}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <h5 color="primary" className="fw-bold">
                  Current Integrations
                </h5>
                <CurrentIntegrationsTable
                  data={currentIntegrationsData}
                  isLoading={isCurrentIntegrationsDataLoading}
                  isError={currentIntegrationsDataError}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default IntegrationsAnalytics;
