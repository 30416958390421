import React, { useEffect, useMemo, useState } from 'react';
import TabPanel from '../../components/Layout/TabPanel/TabPanel';
import { BatchUserStatisticResponse } from '../../api/responses/batchUserStatisticResponse';
import Table from '../../components/Table/Table';
import { TableColumnModel } from '../../models/TableColumnModel';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';
import { BatchUserStatisticsPaginatedResponse } from '../../api/responses/BatchUserStatisticsPaginatedResponse';
import OverflowTooltip from '../../components/OverflowTooltip/OverflowTooltip';

export interface IntegrationAnalyticsChartModalTabPanelProps {
  tabId: string;
  isActiveTab: boolean;
  batchUserStatisticResponse: BatchUserStatisticsPaginatedResponse[];
  dataCount: number;
  isDataLoading: boolean;
  showErrorColumn?: boolean;
  onPageChange: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<BatchUserStatisticsPaginatedResponse, unknown> | void>;
  hasNextPage: boolean;
}

const IntegrationAnalyticsChartModalTabPanel: React.FC<
  IntegrationAnalyticsChartModalTabPanelProps
> = ({
  tabId,
  isActiveTab,
  batchUserStatisticResponse,
  dataCount,
  isDataLoading,
  onPageChange,
  hasNextPage,
  showErrorColumn,
}) => {
  const columns: TableColumnModel[] = [
    {
      name: 'fullName',
      header: 'Full Name',
      sortKey: 'Full_Name',
      width: 25,
      value: 'Full_Name',
      isSortable: false,
    },
    {
      name: 'userName',
      header: 'Username',
      sortKey: 'Username',
      width: 25,
      value: 'Username',
      isSortable: false,
    },
    {
      name: 'supervisor',
      header: 'Supervisor',
      sortKey: 'Supervisor',
      width: 25,
      value: 'Supervisor',
      isSortable: false,
    },
    {
      name: 'Supervisor2',
      header: 'Supervisor2',
      sortKey: 'Supervisor2',
      width: 25,
      value: 'Supervisor2',
      isSortable: false,
    },
  ];

  const errorColumns: TableColumnModel[] = useMemo(
    () => [
      {
        name: 'fullName',
        header: 'Full Name',
        sortKey: 'Full_Name',
        width: 20,
        value: 'Full_Name',
        isSortable: false,
      },
      {
        name: 'userName',
        header: 'Username',
        sortKey: 'Username',
        width: 20,
        value: 'Username',
        isSortable: false,
      },
      {
        name: 'supervisor',
        header: 'Supervisor',
        sortKey: 'Supervisor',
        width: 20,
        value: 'Supervisor',
        isSortable: false,
      },
      {
        name: 'Supervisor2',
        header: 'Supervisor2',
        sortKey: 'Supervisor2',
        width: 20,
        value: 'Supervisor2',
        isSortable: false,
      },
      {
        name: 'errorInformation',
        header: 'Error Information',
        sortKey: 'Error_Information',
        width: 20,
        value: 'Error_Information',
        isSortable: false,
      },
    ],
    []
  );
  const [columnsState, setColumnsState] = useState<TableColumnModel[]>(columns);

  useEffect(() => {
    if (showErrorColumn) {
      setColumnsState(errorColumns);
    }
  }, [showErrorColumn, errorColumns]);

  const rowTemplate = (value: BatchUserStatisticResponse, index: number) => {
    if (value === undefined) return null;
    return (
      <div
        key={`${value.FirstName}${value.LastName}-${index}`}
        className={`w-100 d-flex flex-row flex-nowrap align-items-center ${
          index % 2 === 0 ? '' : 'table-row-background-color'
        }`}
        style={{ height: '36px' }}
      >
        <div className="text-truncate p-2" style={{ flexBasis: `${columnsState[0].width}%` }}>
          {value.FullName}
        </div>
        <div className="text-truncate p-2" style={{ flexBasis: `${columnsState[1].width}%` }}>
          {value.Username}
        </div>
        <div className="text-truncate p-2" style={{ flexBasis: `${columnsState[2].width}%` }}>
          {value.SupervisorUsername}
        </div>
        <div className="text-truncate p-2" style={{ flexBasis: `${columnsState[3].width}%` }}>
          {value.Supervisor2Username}
        </div>
        {showErrorColumn && (
          <div className="text-truncate p-2" style={{ flexBasis: `${columnsState[4].width}%` }}>
            <OverflowTooltip
              tooltipText={value.Error}
              children={({ ref, style }, tooltipText) => (
                <div ref={ref as React.LegacyRef<HTMLParagraphElement>} style={style}>
                  {tooltipText}
                </div>
              )}
              placement="left"
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <TabPanel tabId={tabId} isActiveTab={isActiveTab} className="h-100">
      <Table<BatchUserStatisticResponse, undefined>
        data={batchUserStatisticResponse.reduce(
          (accum: BatchUserStatisticResponse[], item: BatchUserStatisticsPaginatedResponse) => [
            ...accum,
            ...item.ResultData,
          ],
          []
        )}
        columns={columnsState}
        height={768}
        itemHeight={36}
        numberOfItems={dataCount || 0}
        sortInformation={[]}
        requestInformation={undefined}
        visibleRows={16}
        rowTemplate={rowTemplate}
        onSortChange={() => {}}
        isLoading={isDataLoading}
        pageSize={50}
        onPageChange={onPageChange}
        requestError={false}
        hasNextPage={hasNextPage}
        estimateSize={() => 54}
        emptyStateMessage="No Records Available"
        variableHeight={false}
        tableContainerClassname="h-100"
      />
    </TabPanel>
  );
};

export default IntegrationAnalyticsChartModalTabPanel;
