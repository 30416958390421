import ESTraverse from 'estraverse';
import * as ESTree from 'estree';
import { JSONParsed } from '../../types/json-types';
import { ExprToken } from './exprToken';
import OperatorCollator from './operatorCollator';

export interface TreeToExprTokenTraverserDelegateOptions {
  tokenMapper: (tokens: ExprToken[]) => void;
  exprTreeGetter: () => ESTree.Node;
  tokenCallback?: (callee: string, json: JSONParsed) => JSONParsed;
}

/**
 * @summary This class is a(n event) delegate for converting an ESPrima tree into a list
 *	of `ExprToken`s for use with `ExpresssionBuilder`.
 * @description `ExprToken`s are terms (Tasks as 'CallExpression's),
 *	logical operators (AND and OR as 'LogicalExpression's),
 *	parenthesis (opening or closing ones as 'Punctuator's), and
 *	placeholder nodes (as 'Literal's).  When this delegate's `handleEvent`
 *	function is called:
 *	1. it takes the ESPrima tree it gets from `exprTreeGetter`,
 *	2. converts the tree to and ordered list of `ExprToken`s, then
 *	3. calls tokenMapper` with that list to rebuild a serialized tree.
 *
 * Keep in mind that `ExprToken`s are at the granularity the user creates an
 *	expression, not `ESPrima.Token`s which are very detailed
 *	(e.g., 'MemberExpression', 'ObjectExpression', etc.).
 * @see ExprToken
 */
export default class TreeToExprTokenTraverserDelegate implements EventListenerObject {
  private tokenMapper: (tokens: ExprToken[]) => void;

  private tokenCallback: (callee: string, json: JSONParsed) => JSONParsed;

  private exprTreeGetter: () => ESTree.Node;

  private treeTraverser: ESTraverse.Controller;

  private operatorCollator: OperatorCollator;

  constructor({
    tokenMapper,
    exprTreeGetter,
    tokenCallback = (callee: string, json: JSONParsed) => json,
  }: TreeToExprTokenTraverserDelegateOptions) {
    this.tokenMapper = tokenMapper;
    this.exprTreeGetter = exprTreeGetter;
    this.tokenCallback = tokenCallback;
    this.treeTraverser = new ESTraverse.Controller();
    this.operatorCollator = new OperatorCollator();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handleEvent(_evt: Event): void {
    const exprTokens = this.convertTreeToExprTokens();
    this.tokenMapper(exprTokens);
  }

  public convertTreeToExprTokens(): ExprToken[] {
    const exprTokens: ExprToken[] = [];
    const esTree = this.exprTreeGetter();
    // Hash of each operator and whether its precedence was overridden.
    //	This information could have also been kept in a stack.
    const operatorHashmap = new Map<ESTree.Node, boolean>();
    if (esTree) {
      this.treeTraverser.traverse(esTree, {
        enter: (node, parent) => {
          switch (node.type) {
            case 'Program':
            case 'EmptyStatement':
            case 'ExpressionStatement':
            case 'Identifier':
            case 'ObjectExpression':
            case 'Property':
            case 'Literal':
              break;
            case 'CallExpression':
              {
                // node.callee.name === 'task';
                const params = node.arguments.map(arg => {
                  let param: string;
                  switch (arg.type) {
                    case 'ObjectExpression':
                      {
                        const keyValuePairs = arg.properties.map(prop => {
                          const property = prop as ESTree.Property;
                          let key: string | number | bigint | boolean | RegExp | null | undefined;
                          let value: string | number | bigint | boolean | RegExp | null | undefined;
                          switch (property.key.type) {
                            case 'Literal':
                              key = `"${property.key.value}"`;
                              break;
                            case 'Identifier':
                              key = property.key.name;
                              break;
                            default:
                              throw new EvalError(
                                `Unhandled Property key 'type': '${property.key.type}'.`
                              );
                          }
                          switch (property.value.type) {
                            case 'Literal':
                              value = property.value.value;
                              if (typeof value === 'string') {
                                value = JSON.stringify(value);
                              }
                              break;
                            case 'Identifier':
                              value = property.value.name;
                              break;
                            default:
                              throw new EvalError(
                                `Unhandled Property value 'type': '${property.value.type}'.`
                              );
                          }
                          return `${key}: ${value}`;
                        });
                        // Wrap object parameters in braces.
                        param = `{${keyValuePairs.join(', ')}}`;
                      }
                      break;
                    default:
                      throw new EvalError(
                        `Unhandled CallExpression argument 'type': '${arg.type}'.`
                      );
                  }
                  return param;
                });
                const callee = node.callee as ESTree.Identifier;
                const paramsSerialized = `${params.join(', ')}`;
                // This is not wrapped in a try-catch because if it fails here,
                //	it will fail later when the user tries to save changes.
                const raw = this.tokenCallback(callee.name, JSON.parse(paramsSerialized));
                const subExpr = `${callee.name}(${JSON.stringify(raw)})`;
                exprTokens.push({ type: 'CallExpression', value: subExpr, raw });
              }
              break;
            case 'LogicalExpression':
              switch (node.operator) {
                case '&&':
                case '||':
                  {
                    // Add operator and whether its precedence was overridden.
                    const isOperPrecOverridden = this.isPrecedenceOverridden(node, parent);
                    // Open parenthetical group.
                    if (isOperPrecOverridden) {
                      exprTokens.push({ type: 'Punctuator', value: '(' });
                    }
                    operatorHashmap.set(node, isOperPrecOverridden);
                  }
                  break;
                default:
                  throw new EvalError(
                    `Unhandled LogicalExpression 'operator': '${node.operator}'.`
                  );
              }
              break;
            default:
              throw new EvalError(`Unhandled expression type: '${node.type}'.`);
          }
        },
        leave: (node, parent) => {
          switch (node.type) {
            case 'Program':
            case 'EmptyStatement':
            case 'ExpressionStatement':
            case 'Identifier':
            case 'ObjectExpression':
            case 'Property':
            case 'Literal':
              break;
            case 'CallExpression':
              // If node is the left child of a logical operator, add operator
              //	to token list.  This is produces an in-order traversal
              //	between `enter` and `leave`.
              if (parent?.type === 'LogicalExpression' && node === parent.left) {
                exprTokens.push({ type: 'LogicalExpression', value: parent.operator });
              }
              break;
            case 'LogicalExpression':
              // [ 'CallExpression', 'LogicalExpression' ].includes( node.left.type );
              // [ '&&', '||' ].includes( node.operator );
              // [ 'CallExpression', 'LogicalExpression' ].includes( node.right.type );
              switch (node.operator) {
                case '&&':
                case '||':
                  {
                    // If the operator's precedence was overridden, close the parenthetical group.
                    const wasPrecedenceOverridden = operatorHashmap.get(node);
                    if (wasPrecedenceOverridden) {
                      exprTokens.push({ type: 'Punctuator', value: ')' });
                    }
                    operatorHashmap.delete(node);
                    // If node is the left child of a logical operator, add operator
                    //	to token list.  This is produces an in-order traversal
                    //	between `enter` and `leave`.
                    if (parent?.type === 'LogicalExpression' && node === parent.left) {
                      exprTokens.push({ type: 'LogicalExpression', value: parent.operator });
                    }
                  }
                  break;
                default:
                  throw new EvalError(
                    `Unhandled LogicalExpression 'operator': '${node.operator}'.`
                  );
              }
              break;
            default:
              throw new EvalError(`Unhandled expression type: '${node.type}'.`);
          }
        },
      });
    }
    return exprTokens;
  }

  protected isPrecedenceOverridden(node: ESTree.Node, parent: ESTree.Node | null): boolean {
    let isOverridden = false;
    if (parent?.type === 'LogicalExpression' && node.type === 'LogicalExpression') {
      const comparison = this.operatorCollator.compare(node.operator, parent.operator);
      if (comparison > 0) {
        isOverridden = true;
      }
    }
    return isOverridden;
  }
}
