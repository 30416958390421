import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import featureFlagService, { FeatureFlagService } from './featureFlagService';

function useFeatureFlagService(): FeatureFlagService {
  const flags = useFlags();
  const ldClient = useLDClient();

  return featureFlagService(ldClient, flags);
}

export default useFeatureFlagService;
