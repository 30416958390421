import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { BsQuestionCircle } from 'react-icons/bs';
// @ts-ignore
import TemplateCSV from '../../../assets/downloads/BulkUserUploadTemplate.csv';
import commonText from '../../../constants/commonText';
import testIds from '../../../constants/testIds';
import { DropDownSelectionType } from '../../../pages/CreateUserIntegration/defaultDropDownSelections';
import DragAndDropFileSelector from '../../DragAndDropFileSelector/DragAndDropFileSelector';
import Tooltip from '../../ToolTip/ToolTip';
import CreateUserIntegrationDropDown from '../CreateUserIntegrationDropDown/CreateUserIntegrationDropDown';
import { MappingOption } from '../../../models/MappingOption';
import { FileRejection } from 'react-dropzone';

export interface MappingCardProps {
  dropDownSelections: DropDownSelectionType;
  optionHeaders: string[];
  onDropdownSelection: (option: MappingOption) => void;
  onDropAccepted: (acceptedFiles: File[]) => void;
  onDropRejected: (rejectedFiles: FileRejection[]) => void;
  dragAndDropInputTestId?: string;
  readOnly: boolean;
  onEditMapping: () => void;
}

const MappingCard: React.FC<MappingCardProps> = ({
  dropDownSelections,
  optionHeaders,
  onDropdownSelection,
  onDropAccepted,
  onDropRejected,
  dragAndDropInputTestId,
  readOnly,
  onEditMapping,
}) => {
  return (
    <Card className="mt-4" data-testid={testIds.mappingCard()}>
      <Card.Header className="bg-gray">
        <h5 className="fw-bold">Mapping</h5>
      </Card.Header>
      <Card.Body data-testid={testIds.mappingCardContainer()}>
        {readOnly && <Button onClick={onEditMapping}>Edit Mapping</Button>}
        {!readOnly && (
          <>
            <Alert className="d-flex flex-column" variant="info">
              <span>
                The template you use for mapping should match the file you will use in the upload.
              </span>
              <Button
                href={TemplateCSV}
                className="mt-2"
                rel="noreferrer"
                variant="primary"
                style={{ maxWidth: 'fit-content' }}
              >
                Example Template
              </Button>
            </Alert>
            <DragAndDropFileSelector
              onDropAccepted={onDropAccepted}
              onDropRejected={onDropRejected}
              testId={dragAndDropInputTestId}
              maxFiles={1}
            />
            <Row className="mt-4">
              <Col>
                <CreateUserIntegrationDropDown
                  mappingOption={dropDownSelections.status}
                  options={optionHeaders}
                  onDropDownItemClick={onDropdownSelection}
                  labelIcon={
                    <Tooltip content={commonText.userStatusToolTipText}>
                      <BsQuestionCircle
                        className="ml-1 mb-1"
                        data-testid={testIds.userStatusTooltipContainer()}
                      />
                    </Tooltip>
                  }
                  readOnly={readOnly}
                />
                <CreateUserIntegrationDropDown
                  mappingOption={dropDownSelections.email}
                  options={optionHeaders}
                  onDropDownItemClick={onDropdownSelection}
                  readOnly={readOnly}
                />
                <CreateUserIntegrationDropDown
                  mappingOption={dropDownSelections.username}
                  options={optionHeaders}
                  onDropDownItemClick={onDropdownSelection}
                  readOnly={readOnly}
                />
                <CreateUserIntegrationDropDown
                  mappingOption={dropDownSelections.username2}
                  options={optionHeaders}
                  onDropDownItemClick={onDropdownSelection}
                  readOnly={readOnly}
                />
                <CreateUserIntegrationDropDown
                  mappingOption={dropDownSelections.firstName}
                  options={optionHeaders}
                  onDropDownItemClick={onDropdownSelection}
                  readOnly={readOnly}
                />
                <CreateUserIntegrationDropDown
                  mappingOption={dropDownSelections.middleName}
                  options={optionHeaders}
                  onDropDownItemClick={onDropdownSelection}
                  readOnly={readOnly}
                />
                <CreateUserIntegrationDropDown
                  mappingOption={dropDownSelections.lastName}
                  options={optionHeaders}
                  onDropDownItemClick={onDropdownSelection}
                  readOnly={readOnly}
                />
                <CreateUserIntegrationDropDown
                  mappingOption={dropDownSelections.hireDate}
                  options={optionHeaders}
                  onDropDownItemClick={onDropdownSelection}
                  readOnly={readOnly}
                />
              </Col>
              <Col>
                <CreateUserIntegrationDropDown
                  mappingOption={dropDownSelections.supervisorUsername}
                  options={optionHeaders}
                  onDropDownItemClick={onDropdownSelection}
                  readOnly={readOnly}
                />
                <CreateUserIntegrationDropDown
                  mappingOption={dropDownSelections.supervisor2Username}
                  options={optionHeaders}
                  onDropDownItemClick={onDropdownSelection}
                  readOnly={readOnly}
                />
                <CreateUserIntegrationDropDown
                  mappingOption={dropDownSelections.department}
                  options={optionHeaders}
                  onDropDownItemClick={onDropdownSelection}
                  readOnly={readOnly}
                />

                <CreateUserIntegrationDropDown
                  mappingOption={dropDownSelections.costCenter}
                  options={optionHeaders}
                  onDropDownItemClick={onDropdownSelection}
                  readOnly={readOnly}
                />
                <CreateUserIntegrationDropDown
                  mappingOption={dropDownSelections.companySite}
                  options={optionHeaders}
                  onDropDownItemClick={onDropdownSelection}
                  readOnly={readOnly}
                />
                <CreateUserIntegrationDropDown
                  mappingOption={dropDownSelections.companyName}
                  options={optionHeaders}
                  onDropDownItemClick={onDropdownSelection}
                  readOnly={readOnly}
                />
                <CreateUserIntegrationDropDown
                  mappingOption={dropDownSelections.role}
                  options={optionHeaders}
                  onDropDownItemClick={onDropdownSelection}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default MappingCard;
