import { Values } from '../types/values';

const FeatureFlags = {
  HasOnBoard_LiveAccess: 'onboard-live-report-access',
  Equivalencies_Jobs_New_Mapping: 'equivalencies-jobs-new-mapping-logic',
  Has_Activity_Report_Access: 'has-activity-report-access',
  CanViewIntegrationHub: 'can-view-integration-hub',
  Has_Learning_History_Report_Access: 'has-learning-history-report-access',
} as const;

export type FeatureFlag = Values<typeof FeatureFlags>;

export default FeatureFlags;
