import { BatchUserStatisticResponse } from '../../api/responses/batchUserStatisticResponse';
import IntegrationHistoryTabNames from '../../models/IntegrationHistoryTabNames';
import excelBuilder from './excelBuilder';

interface CleanedData {
  Username: string;
  'First Name': string;
  'Middle Name': string;
  'Last Name': string;
  'Full Name': string;
  'Supervisor Username': string;
  'Supervisor2 Username': string;
}

interface CleanedErrorData extends CleanedData {
  Error: string;
}

function exportExcel(
  createdBatchUserStatistics: BatchUserStatisticResponse[],
  updatedBatchUserStatistics: BatchUserStatisticResponse[],
  errorBatchUserStatistics: BatchUserStatisticResponse[],
  fileName: string
): void {
  const headers = [
    [
      'Username',
      'First Name',
      'Middle Name',
      'Last Name',
      'Full Name',
      'Supervisor Username',
      'Supervisor2 Username',
    ],
  ];
  const cleanedCreateData = cleanData(createdBatchUserStatistics);
  const createdWorkSheet = excelBuilder.buildWorkSheet(cleanedCreateData, headers);

  const cleanedUpdatedData = cleanData(updatedBatchUserStatistics);
  const updateWorkSheet = excelBuilder.buildWorkSheet(cleanedUpdatedData, headers);

  const cleanedErrorData = cleanErrorData(errorBatchUserStatistics);
  const errorWorkSheet = excelBuilder.buildWorkSheet(cleanedErrorData, [[...headers[0], 'Error']]);

  excelBuilder.build(
    [createdWorkSheet, updateWorkSheet, errorWorkSheet],
    [
      IntegrationHistoryTabNames.Created.name,
      IntegrationHistoryTabNames.Updated.name,
      IntegrationHistoryTabNames.Errors.name,
    ],
    fileName
  );
}

function cleanData(createdBatchUserStatistics: BatchUserStatisticResponse[]): CleanedData[] {
  return createdBatchUserStatistics.map(value => ({
    Username: value.Username,
    'First Name': value.FirstName,
    'Middle Name': value.MiddleName,
    'Last Name': value.LastName,
    'Full Name': value.FullName,
    'Supervisor Username': value.SupervisorUsername,
    'Supervisor2 Username': value.Supervisor2Username,
  }));
}

function cleanErrorData(
  errorBatchUserStatistics: BatchUserStatisticResponse[]
): CleanedErrorData[] {
  return errorBatchUserStatistics.map(value => ({
    Username: value.Username,
    'First Name': value.FirstName,
    'Middle Name': value.MiddleName,
    'Last Name': value.LastName,
    'Full Name': value.FullName,
    'Supervisor Username': value.SupervisorUsername,
    'Supervisor2 Username': value.Supervisor2Username,
    Error: value.Error,
  }));
}

export default { exportExcel };
