import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import commonText from '../../../constants/commonText';
import { ScrollingOptions } from '../../../models/scrollingOptions';
import Checkbox from '../../Checkbox/Checkbox';
import DrawerMenuGroup from '../../Layout/Drawer/DrawerMenuGroup';
import Switch from '../../Switch/Switch';
import TaskProfilePrimaryDataPointDropdown from '../PrimaryDataPointDropdown/TaskProfilePrimaryDataPointDropdown';
import FullscreenMenuGroup from './FullscreenMenuGroup';
import {
  TaskProfileCardDisplayOption,
  TaskProfileCardDisplayOptions,
  TaskProfileCardFeatures,
} from './taskProfileCardDisplayOptions';
import {
  getPrimaryDataPointCardDisplayOption,
  TaskProfileCardPrimaryDataPoints,
} from './taskProfileCardPrimaryDataPoints';
import {
  TaskProfileDetailDisplayOption,
  TaskProfileDetailDisplayOptions,
  TaskProfileDetailFeatures,
} from './taskProfileDetailDisplayOptions';
import RadioButtonGroup from '../../RadioButton/RadioButtonGroup';
import {
  TaskProfileDetailDisplayModes,
  TaskProfileDetailModes,
} from './taskProfileDetailDisplayModes';
import { AvailablePolicies } from '../../../policyService/policyEnforcer/policyEnforcer';

interface TaskProfileReportDrawerTabCardsProps {
  policies: AvailablePolicies;
  activePrimaryDataPoint: TaskProfileCardPrimaryDataPoints;
  activeCardDisplayOptions: TaskProfileCardFeatures;
  activeDetailDisplayOptions: TaskProfileDetailFeatures;
  activeDetailDisplayModes: TaskProfileDetailModes;
  scrollingOptions: ScrollingOptions;
  onPrimaryDataPointChange: (option: TaskProfileCardPrimaryDataPoints) => void;
  onCardDisplayOptionChange: (displayOption: TaskProfileCardDisplayOption) => void;
  onDetailDisplayOptionChange: (displayOption: TaskProfileDetailDisplayOption) => void;
  onDetailDisplayModeChange: (displayMode: TaskProfileDetailModes) => void;
  onScrollingOptionChange: (scrollingOptions: ScrollingOptions) => void;
}

const TaskProfileReportDrawerTabCards: React.FC<TaskProfileReportDrawerTabCardsProps> = ({
  policies,
  activePrimaryDataPoint,
  activeCardDisplayOptions,
  activeDetailDisplayOptions,
  activeDetailDisplayModes,
  scrollingOptions,
  onPrimaryDataPointChange,
  onCardDisplayOptionChange,
  onDetailDisplayOptionChange,
  onDetailDisplayModeChange,
  onScrollingOptionChange,
}) => {
  const primaryDataPointCardDisplayOption =
    getPrimaryDataPointCardDisplayOption(activePrimaryDataPoint);

  return (
    <>
      <DrawerMenuGroup id={`${commonText.cards}Options`} label={commonText.cards}>
        <Row aria-labelledby={`${commonText.cards}Options`} role="group">
          <Col className="border-end">
            <Form.Group className="d-flex flex-nowrap justify-content-start align-items-center no-gutters mb-0">
              <Form.Label className="me-3 mb-0 fw-bold">Focus</Form.Label>
              <TaskProfilePrimaryDataPointDropdown
                activePrimaryDataPoint={activePrimaryDataPoint}
                onPrimaryDataPointChange={onPrimaryDataPointChange}
              />
            </Form.Group>
            <Form.Group>
              <Switch
                label={TaskProfileCardDisplayOptions.viewAsIncomplete.label}
                id={TaskProfileCardDisplayOptions.viewAsIncomplete.id}
                checked={activeCardDisplayOptions.viewAsIncomplete}
                onChange={() =>
                  onCardDisplayOptionChange(TaskProfileCardDisplayOptions.viewAsIncomplete)
                }
              />
              {[
                TaskProfileCardDisplayOptions.tasks,
                TaskProfileCardDisplayOptions.training,
                TaskProfileCardDisplayOptions.wePe,
                TaskProfileCardDisplayOptions.roles,
                TaskProfileCardDisplayOptions.jobs,
              ].map(displayOption => (
                <Checkbox
                  key={displayOption.id}
                  id={displayOption.id}
                  label={displayOption.label}
                  checked={activeCardDisplayOptions[displayOption.id]}
                  onChange={() => onCardDisplayOptionChange(displayOption)}
                  disabled={displayOption.id === primaryDataPointCardDisplayOption.id}
                />
              ))}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              {[
                TaskProfileCardDisplayOptions.username,
                TaskProfileCardDisplayOptions.userId,
                TaskProfileCardDisplayOptions.note,
              ].map(displayOption => (
                <Checkbox
                  key={displayOption.id}
                  id={displayOption.id}
                  label={displayOption.label}
                  checked={activeCardDisplayOptions[displayOption.id]}
                  onChange={() => onCardDisplayOptionChange(displayOption)}
                />
              ))}
            </Form.Group>
          </Col>
        </Row>
      </DrawerMenuGroup>
      <DrawerMenuGroup id={`${commonText.details}Options`} label={commonText.details}>
        <Row aria-labelledby={`${commonText.details}Options`} role="group">
          <Col className="border-end">
            <Form.Group>
              {[
                TaskProfileDetailDisplayOptions.roles,
                TaskProfileDetailDisplayOptions.completeTasks,
                TaskProfileDetailDisplayOptions.incompleteTasks,
              ].map(displayOption => (
                <Switch
                  key={displayOption.id}
                  id={`${displayOption.id}Detail`}
                  label={displayOption.label}
                  checked={activeDetailDisplayOptions[displayOption.id]}
                  onChange={() => onDetailDisplayOptionChange(displayOption)}
                />
              ))}
              {[TaskProfileDetailDisplayOptions.jobs].map(displayOption => (
                <React.Fragment key={displayOption.id}>
                  <Switch
                    // key={displayOption.id}
                    id={`${displayOption.id}Detail`}
                    label={displayOption.label}
                    checked={activeDetailDisplayOptions[displayOption.id]}
                    onChange={() => onDetailDisplayOptionChange(displayOption)}
                  />
                  <RadioButtonGroup
                    list={[
                      TaskProfileDetailDisplayModes.jobsAssocMethod[0],
                      TaskProfileDetailDisplayModes.jobsAssocMethod[1],
                      TaskProfileDetailDisplayModes.jobsAssocMethod[2],
                    ]}
                    name="job-assoc-method"
                    isSelected={item =>
                      item.id ===
                      (activeDetailDisplayModes?.jobsAssocMethod ||
                        // Default, default values until the VerifyAPI is implemented.
                        TaskProfileDetailDisplayModes.jobsAssocMethod[0].id)
                    }
                    getLabel={item => item.label}
                    getValue={item => item.id}
                    onChange={item => onDetailDisplayModeChange({ jobsAssocMethod: item.id })}
                    orientation={'vertical'}
                    groupStyle={{ marginLeft: '2.5em' }}
                    // Product has decided to hide the radio button group for now.  This
                    //	decision may revisited in the future to offer these options again.
                    //	This code is left here as the pivot point for re-enabling that.
                    className={policies.canViewNewJobReport ? 'd-none' : 'd-none'}
                    isDisabled={() =>
                      !activeDetailDisplayOptions[TaskProfileCardDisplayOptions.jobs.id]
                    }
                  />
                </React.Fragment>
              ))}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              {[
                TaskProfileDetailDisplayOptions.incompleteWePe,
                TaskProfileDetailDisplayOptions.completeWePe,
                TaskProfileDetailDisplayOptions.incompleteTrainings,
                TaskProfileDetailDisplayOptions.completeTrainings,
              ].map(displayOption => (
                <Switch
                  key={displayOption.id}
                  id={`${displayOption.id}Detail`}
                  label={displayOption.label}
                  checked={activeDetailDisplayOptions[displayOption.id]}
                  onChange={() => onDetailDisplayOptionChange(displayOption)}
                />
              ))}
            </Form.Group>
          </Col>
        </Row>
      </DrawerMenuGroup>
      <FullscreenMenuGroup
        scrollingOptions={scrollingOptions}
        onScrollingOptionChange={onScrollingOptionChange}
      />
    </>
  );
};

export default TaskProfileReportDrawerTabCards;
