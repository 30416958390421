import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import {
  OneSecondInMilliseconds,
  ThirtySeconds,
  ThirtySecondsInMilliseconds,
} from '../../../constants/measurements';
import { ScrollingOptions } from '../../../models/scrollingOptions';
import DrawerMenuGroup from '../../Layout/Drawer/DrawerMenuGroup';
import Switch from '../../Switch/Switch';

export interface FullscreenMenuGroupProps {
  scrollingOptions: ScrollingOptions;
  onScrollingOptionChange: (scrollingOptions: ScrollingOptions) => void;
}

const FullscreenMenuGroup: React.FC<FullscreenMenuGroupProps> = ({
  scrollingOptions,
  onScrollingOptionChange,
}) => {
  const defaultInterval: number = ThirtySeconds;
  function GetAutoScrollInterval(): number {
    if (scrollingOptions.scrollingIntervalMilliseconds <= ThirtySecondsInMilliseconds) {
      return ThirtySecondsInMilliseconds / OneSecondInMilliseconds;
    }
    return scrollingOptions.scrollingIntervalMilliseconds / OneSecondInMilliseconds;
  }

  return (
    <DrawerMenuGroup label="Fullscreen">
      <Row>
        <Col>
          <Form.Group className="d-flex align-items-center">
            <Switch
              key="auto-0scrolling-switch"
              id="auto-scrolling-switch"
              checked={scrollingOptions.isScrolling}
              label="Auto Scrolling"
              onChange={() => {
                onScrollingOptionChange({
                  ...scrollingOptions,
                  isScrolling: !scrollingOptions.isScrolling,
                });
              }}
            />
            <input
              className="ms-2"
              type="number"
              min={`${defaultInterval}`}
              max="999"
              style={{ width: 55 }}
              defaultValue={GetAutoScrollInterval()}
              disabled={!scrollingOptions.isScrolling}
              onBlur={event => {
                let inputValue: number = Number(event.target.value);
                if (inputValue < defaultInterval) {
                  inputValue = defaultInterval;
                  // eslint-disable-next-line no-param-reassign
                  event.target.value = `${defaultInterval}`;
                }
                onScrollingOptionChange({
                  ...scrollingOptions,
                  scrollingIntervalMilliseconds: inputValue * OneSecondInMilliseconds,
                });
              }}
            />
            <div className="ms-2">Seconds</div>
          </Form.Group>
        </Col>
      </Row>
    </DrawerMenuGroup>
  );
};

export default FullscreenMenuGroup;
