export default {
  cards: 'Cards',
  companyType: 'Company Type',
  complete: 'Complete',
  costCenters: 'Cost Centers',
  delete: 'Delete',
  departments: 'Departments',
  details: 'Details',
  editor: 'Editor',
  employees: 'Employees',
  employeesStatus: 'Employee Status',
  error: 'Error',
  incomplete: 'Incomplete',
  job: 'Job',
  jobs: 'Jobs',
  jobsRespectMappingsOnly: 'Jobs - With Mappings Only',
  jobsRespectMappingsAndTaskProfiling: 'Jobs - With Task Profiling and Mapping',
  note: 'Note',
  owner: 'Owner',
  pe: 'PE',
  progress: 'Progress',
  report: 'Report',
  role: 'Role',
  roles: 'Roles',
  save: 'Save',
  sort: 'Sort',
  status: 'Status',
  supervisors: 'Supervisors',
  task: 'Task',
  live: 'Live',
  tasks: 'Tasks',
  training: 'Training',
  trainings: 'Trainings',
  userId: 'User ID',
  username: 'Username',
  users: 'users',
  viewOnly: 'View Only',
  views: 'Live - Views',
  we: 'WE',
  workLocations: 'Work Locations',
  assigners: 'Assigners',
  evaluators: 'Evaluators/Proctor',
  series: 'Series',
  selectSeries: 'Select series',
  selectEmployeesStatus: 'Select employee status',
  clearAll: 'Clear All',
  selectAll: 'Select All',
  date: 'Date',
  completed: 'Completed',
  expires: 'Expires',
  expiresInDays: 'Expires In (Days)',
  lastAccessed: 'Last Accessed',
  from: 'From',
  to: 'To',
  activity: 'Activity',
  analytics: 'Analytics',
  learningHistory: 'Learning History',
  learningHistoryDescription: 'View current and historical learning records for individual(s).',
  failed: 'Failed',
  passed: 'Passed',
  currentRecords: 'Current Records',
  historicalRecords: 'Historical Records',
  allRecords: 'All Records',
  qualified: 'Qualified',
  notQualified: 'Not Qualified',
  reason: 'Reason',
  excelSuccessTitle: 'Excel Call Successful',
  excelSuccessText: 'An email will be sent containing the excel file',
  excelFailureTitle: 'Excel Call Failure',
  excelFailureText: 'An error has occured. Please contact an ITS administrator',
  toastUnauthorizedTitle: 'Unauthorized',
  toastUnauthorizedText:
    "You don't have the permissions to see this page. Please contact ITS support.",
  toastUnknownTitle: 'Unknown error',
  toastUnknownText: 'Please refresh the page or try again later.',
  userStatusToolTipText:
    "When this field is marked 'Active' the user is marked active. Any other value marks the user inactive.",
  usernamePrefixToolTipText: 'Characters added to the front of the uploaded usernames.',
  createMissingFieldsText: 'Create Missing Departments, Cost Centers, Work Locations and Roles',
  leadingZerosToolTipText:
    'To standardize username, select the number of digits required. If a username does not have a required number of digits, zeros will be added to the front of the digits. For this option to work the username in the file must be numeric and a Username Prefix must be configured.',
  pageNotFound: 'Page Not Found',
  userIntegrationNotFoundHeader: 'User Integration does not exist',
  userIntegrationNotFoundBody: 'Please contact an ITS Administrator',
  history: 'History',
  selectAnIntegration: 'Select an integration',
  startDate: 'Start Date',
  endDate: 'End Date',
  disabled: 'Disabled',
  close: 'Close',
  leaveIntegrationHeader: 'Are you sure you want to close this page?',
  leaveIntegrationText: 'Changes you made will not be saved.',
  noRecentUploads: 'No Recent Uploads',
  userFile: 'User File',
  userAPI: 'User API',
  enabled: 'Enabled',
} as const;
