import ESTraverse from 'estraverse';
import * as ESTree from 'estree';
import OperatorCollator from './operatorCollator';

interface DiagramEvaluationStructure {
  // Mimic an `esprima` expression node for evaluating the stack.
  type: 'DiagramEvaluationStructure';
  // Assign to `null` if unable to evaluate so it will be serializable.
  evaluation: LogicStructure;
}
interface DiagramEvaluationRow {
  // Mimic an `esprima` expression node for evaluating the stack.
  type: 'DiagramEvaluationRow';
  // Assign to `null` if unable to evaluate so it will be serializable.
  evaluation: LogicRow;
}
interface DiagramEvaluationColumn {
  // Mimic an `esprima` expression node for evaluating the stack.
  type: 'DiagramEvaluationColumn';
  // Assign to `null` if unable to evaluate so it will be serializable.
  evaluation: LogicColumn;
}
interface DiagramEvaluationGroup {
  // Mimic an `esprima` expression node for evaluating the stack.
  type: 'DiagramEvaluationGroup';
  // Assign to `null` if unable to evaluate so it will be serializable.
  evaluation: LogicGroup;
}
interface DiagramEvaluationCell {
  // Mimic an `esprima` expression node for evaluating the stack.
  type: 'DiagramEvaluationCell';
  // Assign to `null` if unable to evaluate so it will be serializable.
  evaluation: LogicCell;
}
type DiagramEvaluationNode =
  | DiagramEvaluationStructure
  | DiagramEvaluationRow
  | DiagramEvaluationColumn
  | DiagramEvaluationGroup
  | DiagramEvaluationCell
  | ESTree.Node;

type LogicCell = string | number | bigint | boolean | RegExp | null | undefined;
type LogicGroup = { group: LogicStructure; depth: number };
type LogicColumn = LogicCell | LogicGroup | LogicStructure;
type LogicRow = { columns: LogicColumn[] };
export type LogicStructure = { rows: LogicRow[] };
type LogicEvaluation = LogicStructure | LogicRow | LogicColumn | LogicGroup | LogicCell;

export interface TreeToDiagramTraverserDelegateOptions {
  canvasSetter: (elem: HTMLElement) => void;
  exprTreeGetter: () => ESTree.Node;
}

/**
 * @summary This class is a(n event) delegate for converting an ESPrima tree
 *	into a matrix logic structure of rows and columns.
 * @description When this delegate's `handleEvent` function is called:
 *	1. it takes the ESPrima tree it gets from `exprTreeGetter`,
 *	2. converts the tree to an HTML grid, then
 *	3. calls `canvasSetter` with that grid to visualize it in the UI.
 *
 * The output is a logic expression arranged in a matrix of terms/Tasks where:
 *	- ORs are joined horizontally/columns,
 *	- ANDs are joined vertically/rows, and
 *	- parenthesis are boxed in a nested matrix.
 */
export default class TreeToDiagramTraverserDelegate implements EventListenerObject {
  private canvasSetter: (elem: HTMLElement) => void;

  private exprTreeGetter: () => ESTree.Node;

  private treeTraverser: ESTraverse.Controller;

  private operatorCollator: OperatorCollator;

  constructor({ canvasSetter, exprTreeGetter }: TreeToDiagramTraverserDelegateOptions) {
    this.canvasSetter = canvasSetter;
    this.exprTreeGetter = exprTreeGetter;
    this.treeTraverser = new ESTraverse.Controller();
    this.operatorCollator = new OperatorCollator();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handleEvent(_evt: Event): void {
    let logicStructure = this.calculateLogicStructure();
    if (!logicStructure) {
      logicStructure = { rows: [] };
    }
    this.redraw(logicStructure);
  }

  public calculateLogicStructure(): LogicStructure | null {
    let logicStructure: LogicStructure | null = { rows: [] };
    const esTree = this.exprTreeGetter();
    if (esTree) {
      const evalStack: DiagramEvaluationNode[] = [];
      let groupDepth = 0;
      this.treeTraverser.traverse(esTree, {
        enter: (node, parent) => {
          switch (node.type) {
            case 'Program':
            case 'EmptyStatement':
            case 'ExpressionStatement':
            case 'Identifier':
            case 'ObjectExpression':
            case 'Property':
              break;
            case 'Literal':
              // The 'TBD' temporary nodes are 'Literal's (but so might the 'ObjectExpression' 'Property' 'key's).
              break;
            case 'CallExpression':
              // node.callee.name === 'task';
              switch (node.callee.type) {
                case 'Identifier':
                  switch (node.callee.name) {
                    case 'task':
                      evalStack.push(node);
                      break;
                    default:
                      throw new EvalError(
                        `Unhandled CallExpression 'callee' Identifier 'name': '${node.callee.name}'.`
                      );
                  }
                  break;
                default:
                  throw new EvalError(
                    `Unhandled CallExpression 'callee.type': '${node.callee.type}'.`
                  );
              }
              break;
            case 'LogicalExpression':
              switch (node.operator) {
                case '&&':
                case '||':
                  evalStack.push(node);
                  if (this.isPrecedenceOverridden(node, parent)) {
                    groupDepth += 1;
                  }
                  break;
                default:
                  throw new EvalError(
                    `Unhandled LogicalExpression 'operator': '${node.operator}'.`
                  );
              }
              break;
            default:
              throw new EvalError(`Unhandled expression type: '${node.type}'.`);
          }
        },
        leave: (node, parent) => {
          switch (node.type) {
            case 'Program':
              {
                const nodeEval = evalStack.pop();
                switch (nodeEval?.type) {
                  case 'DiagramEvaluationStructure':
                    evalStack.push(nodeEval);
                    break;
                  default:
                    throw new ReferenceError(`Unhandled expression type: '${nodeEval?.type}'.`);
                }
              }
              break;
            case 'EmptyStatement':
              evalStack.push({
                type: 'DiagramEvaluationStructure',
                evaluation: { rows: [] },
              });
              break;
            case 'ExpressionStatement':
            case 'Identifier':
            case 'ObjectExpression':
            case 'Property':
              break;
            case 'Literal':
              {
                // The 'TBD' temporary nodes are 'Literal's (but so might the 'ObjectExpression' 'Property' 'key's).
                const isPlaceholderNode =
                  parent?.type === 'ExpressionStatement' || parent?.type === 'LogicalExpression';
                if (isPlaceholderNode) {
                  const nodeEval = node as ESTree.Literal;
                  evalStack.push({
                    type: 'DiagramEvaluationStructure',
                    evaluation: { rows: [{ columns: [nodeEval.value] }] },
                  });
                }
              }
              break;
            case 'CallExpression':
              {
                // node.callee.name === 'task';
                // node.arguments[ 0 ].properties[ 0 ].key.name === 'label';
                // node.arguments[ 0 ].properties[ 0 ].value.value;
                // node.arguments[ 0 ].properties[ 1 ].key.name === 'id';
                // node.arguments[ 0 ].properties[ 1 ].value.value;
                // node.arguments[ 0 ].properties[ 2 ].key.name === 'desc';
                // node.arguments[ 0 ].properties[ 2 ].value.value;
                const nodeEval = evalStack.pop();
                switch (nodeEval!.type) {
                  case 'CallExpression':
                    {
                      const simpleCallExpr = nodeEval as ESTree.CallExpression;
                      const objectExprList = simpleCallExpr.arguments as ESTree.ObjectExpression[];
                      const objectExpr = objectExprList[0];
                      const propertiesList = objectExpr.properties as ESTree.Property[];
                      const property = propertiesList.find(
                        this.propertyByKey('label')
                      ) as ESTree.Property;
                      const literal = property.value as ESTree.Literal;
                      const evaluation = literal.value;
                      evalStack.push({
                        type: 'DiagramEvaluationStructure',
                        evaluation: { rows: [{ columns: [evaluation] }] },
                      });
                    }
                    break;
                  default:
                    throw new ReferenceError(`Unhandled expression type: '${nodeEval?.type}'.`);
                }
              }
              break;
            // A 'CallExpression' on the 'left' side of a 'LogicalExpression' will start a new row.
            // The 'label' (or possibly 'desc') property will become a column.
            // Top-level '&&'s will become their own row.
            // '||' will become another column.
            case 'LogicalExpression':
              // [ 'CallExpression', 'LogicalExpression' ].includes( node.left.type );
              // [ '&&', '||' ].includes( node.operator );
              // [ 'CallExpression', 'LogicalExpression' ].includes( node.right.type );
              switch (node.operator) {
                case '&&':
                case '||':
                  {
                    const isOperPrecOverridden = this.isPrecedenceOverridden(node, parent);
                    const rightNode = evalStack.pop();
                    const leftNode = evalStack.pop();
                    const operNode = evalStack.pop();
                    const operEvaluation: ESTree.LogicalOperator | ESTree.LogicalOperator[] =
                      operNode?.type === 'LogicalExpression' ? operNode.operator : '??';
                    let rightEvaluation: LogicEvaluation = null;
                    let leftEvaluation: LogicEvaluation = null;
                    switch (rightNode?.type) {
                      case 'DiagramEvaluationStructure':
                        rightEvaluation = rightNode.evaluation;
                        break;
                      default:
                        throw new ReferenceError(
                          `Unhandled expression type: '${rightNode?.type}'.`
                        );
                    }
                    switch (leftNode?.type) {
                      case 'DiagramEvaluationStructure':
                        leftEvaluation = leftNode.evaluation;
                        break;
                      default:
                        throw new ReferenceError(`Unhandled expression type: '${leftNode?.type}'.`);
                    }

                    let nodeEvaluation: LogicStructure = { rows: [] };

                    if (node.operator === '||') {
                      // Create the (first) row if it does not exist.
                      if (nodeEvaluation.rows.length === 0) {
                        nodeEvaluation.rows.push({ columns: [] });
                      }

                      // Left child/operand (different from right child this time).
                      // Do not spread strings (to accommodate 'TBD' temporary node).
                      if (Array.isArray(leftEvaluation)) {
                        nodeEvaluation.rows[nodeEvaluation.rows.length - 1].columns.push(
                          ...leftEvaluation
                        );
                      } else if (typeof leftEvaluation === 'object' && leftEvaluation !== null) {
                        if ('rows' in leftEvaluation) {
                          // All structures are a matrix of rows and columns:
                          if (leftEvaluation.rows.length === 1) {
                            // OR operator ('||') collapses columns into each other if there is exactly one column...
                            nodeEvaluation.rows = leftEvaluation.rows.slice();
                          } else {
                            // ...otherwise, '||' can make no other assumptions and wraps it in a new row.
                            nodeEvaluation.rows[nodeEvaluation.rows.length - 1].columns.push(
                              leftEvaluation
                            );
                          }
                        } else if ('columns' in leftEvaluation) {
                          nodeEvaluation.rows[nodeEvaluation.rows.length - 1].columns.push(
                            ...(leftEvaluation as LogicRow).columns
                          );
                        } else if ('group' in leftEvaluation) {
                          nodeEvaluation.rows[nodeEvaluation.rows.length - 1].columns.push(
                            leftEvaluation
                          );
                        }
                      } else {
                        nodeEvaluation.rows[nodeEvaluation.rows.length - 1].columns.push(
                          leftEvaluation
                        );
                      }

                      // Operator ('||').
                      nodeEvaluation.rows[nodeEvaluation.rows.length - 1].columns.push(
                        operEvaluation
                      );

                      // Right child/operand (different from right child this time).
                      // Do not spread strings (to accommodate 'TBD' temporary node).
                      if (Array.isArray(rightEvaluation)) {
                        nodeEvaluation.rows[nodeEvaluation.rows.length - 1].columns.push(
                          ...rightEvaluation
                        );
                      } else if (typeof rightEvaluation === 'object' && rightEvaluation !== null) {
                        if ('rows' in rightEvaluation) {
                          // All structures are a matrix of rows and columns:
                          if (rightEvaluation.rows.length === 1) {
                            // OR operator ('||') collapses columns into each other if there is exactly one column...
                            nodeEvaluation.rows[nodeEvaluation.rows.length - 1].columns.push(
                              ...rightEvaluation.rows[0].columns
                            );
                          } else {
                            // ...otherwise, '||' can make no other assumptions and wraps it in a new row.
                            nodeEvaluation.rows[nodeEvaluation.rows.length - 1].columns.push(
                              rightEvaluation
                            );
                          }
                        } else if ('columns' in rightEvaluation) {
                          nodeEvaluation.rows[nodeEvaluation.rows.length - 1].columns.push(
                            ...(rightEvaluation as LogicRow).columns
                          );
                        } else if ('group' in rightEvaluation) {
                          nodeEvaluation.rows[nodeEvaluation.rows.length - 1].columns.push(
                            rightEvaluation
                          );
                        }
                      } else {
                        nodeEvaluation.rows[nodeEvaluation.rows.length - 1].columns.push(
                          rightEvaluation
                        );
                      }
                    } else if (node.operator === '&&') {
                      // Left child (different from the right this time).
                      if (typeof leftEvaluation === 'object' && leftEvaluation !== null) {
                        // `LogicStructure`
                        if ('rows' in leftEvaluation) {
                          nodeEvaluation.rows.push(...leftEvaluation.rows);
                        }
                        // `LogicRow`
                        else if ('columns' in leftEvaluation) {
                          nodeEvaluation.rows[nodeEvaluation.rows.length - 1].columns.push(
                            ...(leftEvaluation as LogicRow).columns
                          );
                        }
                        // `LogicGroup`
                        else if ('group' in leftEvaluation) {
                          nodeEvaluation.rows.push({
                            columns: [leftEvaluation],
                          });
                        }
                        // `LogicColumn`
                        else {
                          nodeEvaluation.rows.push({
                            columns: [leftEvaluation],
                          });
                        }
                      }
                      // `LogicCell`
                      else {
                        nodeEvaluation.rows.push({
                          columns: [leftEvaluation],
                        });
                      }

                      // Operator ('&&').
                      nodeEvaluation.rows.push({ columns: [operEvaluation] });

                      // Right child (different from the left this time).
                      if (typeof rightEvaluation === 'object' && rightEvaluation !== null) {
                        // `LogicStructure`
                        if ('rows' in rightEvaluation) {
                          // All structures are a matrix of rows and columns:
                          if (
                            rightEvaluation.rows[rightEvaluation.rows.length - 1].columns.length ===
                            1
                          ) {
                            // AND operator ('&&') collapses rows onto each other if there is exactly one row...
                            nodeEvaluation.rows.push(...rightEvaluation.rows);
                          } else {
                            // ...otherwise, '&&' can make no other assumptions and wraps it in a new column.
                            nodeEvaluation.rows.push({ columns: [rightEvaluation] });
                          }
                        }
                        // `LogicRow`
                        else if ('columns' in rightEvaluation) {
                          nodeEvaluation.rows.push(rightEvaluation);
                        }
                        // `LogicGroup`
                        else if ('group' in rightEvaluation) {
                          nodeEvaluation.rows.push({
                            columns: [rightEvaluation],
                          });
                        }
                        // `LogicColumn`
                        else {
                          nodeEvaluation.rows.push({
                            columns: [rightEvaluation],
                          });
                        }
                      }
                      // `LogicCell`
                      else {
                        nodeEvaluation.rows.push({
                          columns: [rightEvaluation],
                        });
                      }
                    }

                    if (isOperPrecOverridden) {
                      // This is adding a parenthetical group to another row.
                      nodeEvaluation = {
                        rows: [{ columns: [{ group: nodeEvaluation, depth: groupDepth }] }],
                      };
                      groupDepth -= 1;
                    } else if (
                      // If still inside the expression and not at the top (i.e. parent is 'LogicalExpression')...
                      parent?.type === 'LogicalExpression' &&
                      // If the operators precdences differ, but precedence is not overridden (+1)...
                      this.operatorCollator.compare(node.operator, parent.operator) < 0 &&
                      // AND operators get stacked in a wrapped column.
                      node.operator === '&&' &&
                      node.left.type === 'CallExpression'
                      // The node.right.type does NOT have to be a 'CallExpression'.
                    ) {
                      // This is keeping a sub-expression on the same row by wrapping it in a column.
                      nodeEvaluation = {
                        rows: [{ columns: [{ rows: nodeEvaluation.rows }] }],
                      };
                    }

                    evalStack.push({
                      type: 'DiagramEvaluationStructure',
                      evaluation: nodeEvaluation,
                    });
                  }
                  break;
                default:
                  throw new EvalError(
                    `Unhandled LogicalExpression 'operator': '${node.operator}'.`
                  );
              }
              break;
            default:
              throw new EvalError(`Unhandled expression type: '${node.type}'.`);
          }
        },
      });
      // If the stack is not reduced to just one node, the evaluation was not performed correctly.
      logicStructure =
        evalStack.length === 1 && evalStack[0].type === 'DiagramEvaluationStructure'
          ? (evalStack[0] as DiagramEvaluationStructure).evaluation
          : null;
    }
    return logicStructure;
  }

  protected generateColumn(column: LogicColumn): HTMLElement {
    let contents: HTMLElement | undefined;
    let containerClasses;
    if (typeof column === 'object' && column !== null) {
      // Reserve space for the nested group's width.
      // let maxNestedColCount = 0;
      // const maxColumnLengthAccumulator = (colCount: number, row: LogicRow) =>
      //  row.columns.length > colCount ? row.columns.length : colCount;
      // Explicit group with parenthesis.
      if (
        'group' in column &&
        typeof column.group === 'object' &&
        column.group !== null &&
        Array.isArray(column.group.rows)
      ) {
        contents = this.generateGrid(column.group);
        // maxNestedColCount = column.group.rows.reduce(maxColumnLengthAccumulator, 0);
      }
      // Implicit group by precedence.
      else if ('rows' in column && Array.isArray(column.rows)) {
        contents = this.generateGrid(column);
        // maxNestedColCount = column.rows.reduce(maxColumnLengthAccumulator, 0);
      }
      containerClasses = [
        'col', // , 'col-'.concat(maxNestedColCount.toString())
      ].join(' ');
    } else {
      let innerClasses = '';
      let value = null;
      let text = '';
      switch (column) {
        case '&&':
          text = 'AND';
          value = '$and';
          innerClasses = 'btn btn-outline-primary shadow expression-operator operator-logical-and';
          containerClasses = 'col operator-node';
          break;
        case '||':
          text = 'OR';
          value = '$or';
          innerClasses = 'btn btn-primary shadow expression-operator operator-logical-or';
          containerClasses = 'col operator-node';
          break;
        default:
          text = String(column);
          value = column;
          innerClasses = 'btn btn-secondary shadow term expression-term text-wrap';
          containerClasses = 'col term-node';
          break;
      }
      contents = document.createElement('button');
      contents.setAttribute('type', 'button');
      contents.classList.add(...innerClasses.split(/\s+/));
      contents.setAttribute('value', String(value));
      contents.innerText = text;
    }
    const colContainer = document.createElement('div');
    colContainer.classList.add(...containerClasses.split(/\s+/));
    if (contents) {
      colContainer.append(contents);
    }
    return colContainer;
  }

  protected generateColumns(columns: LogicColumn[]): HTMLElement[] {
    const colsContainer: HTMLElement[] = [];
    const colElems = columns.reduce((resultCols, col) => {
      return resultCols.concat(this.generateColumn(col));
    }, [] as HTMLElement[]);
    colsContainer.push(...colElems);
    return colsContainer;
  }

  protected generateGrid(structure: LogicStructure): HTMLElement {
    // Bootstrap 'row's are wrapped in a 'container'.
    const rowWrapperClasses = 'container justify-content-center align-items-center h-100';
    const rowsContainer = document.createElement('div');
    rowsContainer.classList.add(...rowWrapperClasses.split(/\s+/));
    const rowElems = this.generateRows(structure.rows);
    rowsContainer.append(...rowElems);
    return rowsContainer;
  }

  protected generateRow(row: LogicRow): HTMLElement {
    const rowClasses = 'row flex-nowrap';
    const rowContainer = document.createElement('div');
    rowContainer.classList.add(...rowClasses.split(/\s+/));
    const contents = this.generateColumns(row.columns);
    rowContainer.append(...contents);
    return rowContainer;
  }

  protected generateRows(rows: LogicRow[]): HTMLElement[] {
    const rowElems = rows.reduce((resultRows, row) => {
      return resultRows.concat(this.generateRow(row));
    }, [] as HTMLElement[]);
    return rowElems;
  }

  protected isPrecedenceOverridden(node: ESTree.Node, parent: ESTree.Node | null): boolean {
    let isOverridden = false;
    if (parent?.type === 'LogicalExpression' && node.type === 'LogicalExpression') {
      const comparison = this.operatorCollator.compare(node.operator, parent.operator);
      if (comparison > 0) {
        isOverridden = true;
      }
    }
    return isOverridden;
  }

  /**
   * Property keys of objects which are strings are 'Literal's and bare names are 'Identifier's.
   * @example
   * {identifier:0}
   * {"literal":0}
   */
  // eslint-disable-next-line class-methods-use-this
  protected propertyByKey(
    key: bigint | boolean | number | RegExp | string
  ): (property: ESTree.Property) => boolean {
    return (property: ESTree.Property) => {
      const name =
        (property.key.type === 'Literal' && property.key.value) ||
        (property.key.type === 'Identifier' && property.key.name);
      return name === key;
    };
  }

  protected redraw(logicStructure: LogicStructure): void {
    const gridElems = this.generateGrid(logicStructure);
    this.canvasSetter(gridElems);
  }
}
