import { DateTime } from 'luxon';
import React, { ChangeEvent, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import commonText from '../../constants/commonText';
import { DateFilters } from '../../pages/IntegrationHistory/IntegrationHistoryContainer';

export interface IntegrationDateFilterProps {
  disableDatePickers: boolean;
  dateFilters: DateFilters;
  setDateFilters: (change: Partial<DateFilters>) => void;
}

const IntegrationDateFilter: React.FC<IntegrationDateFilterProps> = ({
  disableDatePickers,
  dateFilters,
  setDateFilters,
}) => {
  const handleStartDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDateFilters({ startDate: e.target.value });
  };

  const handleEndDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDateFilters({ endDate: e.target.value });
  };

  const [maxDate] = useState<string>(DateTime.now().toFormat('yyyy-MM-dd'));

  return (
    <>
      <Col xs={6} sm={4} md={3} lg={2}>
        <b style={{ fontSize: '12px' }}>Start Date</b>
        <Form.Control
          className="bg-white"
          placeholder={commonText.startDate}
          type="date"
          value={dateFilters.startDate}
          max={dateFilters.endDate}
          onChange={handleStartDateChange}
          disabled={disableDatePickers}
        />
      </Col>
      <Col xs={6} sm={4} md={3} lg={2}>
        <b style={{ fontSize: '12px' }}>End Date</b>
        <Form.Control
          className="bg-white"
          placeholder={commonText.endDate}
          type="date"
          value={dateFilters.endDate}
          min={dateFilters.startDate}
          max={maxDate}
          onChange={handleEndDateChange}
          disabled={disableDatePickers}
        />
      </Col>
    </>
  );
};

export default IntegrationDateFilter;
