import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
  QueryObserverResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import integrationApiHooks from '../api/integrationApi/integrationApiHooks';
import {
  BatchUserStatisticResponse,
  IntegrationDetailType,
} from '../api/responses/batchUserStatisticResponse';
import { BatchUserStatisticsPaginatedResponse } from '../api/responses/BatchUserStatisticsPaginatedResponse';
import UserIntegrationStatisticsType from '../models/UserIntegrationStatisticsType';
import { DateFilters } from '../pages/IntegrationHistory/IntegrationHistoryContainer';

interface BatchUserStatisticsDetailsResponse {
  integrationUserStatistics: InfiniteData<BatchUserStatisticsPaginatedResponse>;
  isLoading: boolean;
  refetch: () => Promise<
    QueryObserverResult<InfiniteData<BatchUserStatisticsPaginatedResponse>, AxiosError>
  >;
  hasNextPage?: boolean;
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<
    InfiniteQueryObserverResult<BatchUserStatisticsPaginatedResponse, AxiosError<unknown, unknown>>
  >;
}

function useBatchUserStatisticsDetails(
  companyId: number,
  integrationId: number,
  dateFilters: DateFilters,
  userType: UserIntegrationStatisticsType,
  queryString: string
): BatchUserStatisticsDetailsResponse {
  let {
    data = { pages: [{ ItemCount: 0, ResultData: [] }], pageParams: [] },
    isLoading,
    refetch,
    hasNextPage,
    fetchNextPage,
  } = integrationApiHooks.useGetIntegrationUserStatisticsDetails(
    {
      companyId,
      integrationId,
      dateFilters,
      userType,
    },
    queryString
  );

  return {
    integrationUserStatistics: data,
    isLoading,
    refetch,
    hasNextPage,
    fetchNextPage,
  };
}

export function parseResponseTypes(batchUserStatistics: BatchUserStatisticResponse[]): {
  parsedCreatedBatchUserStatistics: BatchUserStatisticResponse[];
  parsedUpdatedBatchUserStatistics: BatchUserStatisticResponse[];
  parsedErrorBatchUserStatistics: BatchUserStatisticResponse[];
} {
  const parsedCreatedBatchUserStatistics: BatchUserStatisticResponse[] = batchUserStatistics.filter(
    value => value.DetailType === IntegrationDetailType.NewUser
  );
  const parsedUpdatedBatchUserStatistics: BatchUserStatisticResponse[] = batchUserStatistics.filter(
    value => value.DetailType === IntegrationDetailType.UpdatedUser
  );
  const parsedErrorBatchUserStatistics: BatchUserStatisticResponse[] = batchUserStatistics.filter(
    value => value.DetailType === IntegrationDetailType.Error
  );

  return {
    parsedCreatedBatchUserStatistics,
    parsedUpdatedBatchUserStatistics,
    parsedErrorBatchUserStatistics,
  };
}

export default useBatchUserStatisticsDetails;
