import { useEffect, useState } from 'react';
import messages from '../../../constants/messages';

function useTaskProfileEmptyStateMessage(
  dataLength: number,
  isLoading: boolean,
  isError: boolean
): string {
  const [emptyStateMessage, setEmptyStateMessage] = useState(
    getEmptyStateMessage(dataLength, isLoading, isError)
  );

  useEffect(() => {
    setEmptyStateMessage(getEmptyStateMessage(dataLength, isLoading, isError));
  }, [dataLength, isError, isLoading]);

  return emptyStateMessage;
}

function getEmptyStateMessage(dataLength: number, isLoading: boolean, isError: boolean): string {
  let message = '';
  if (isLoading && !isError) {
    message = messages.loading;
  } else if (isError) {
    message = messages.error;
  } else if (dataLength <= 0) {
    message = messages.noRecords;
  }
  return message;
}

export default useTaskProfileEmptyStateMessage;
