import React from 'react';

export interface EmptyStateProps {
  message: string;
}

const EmptyState: React.FC<EmptyStateProps> = ({ message }) => {
  return (
    <div className="d-flex justify-content-center align-items-center w-100 h-100 text-muted">
      <h4 className="text-muted">{message}</h4>
    </div>
  );
};

export default EmptyState;
