import React from 'react';
import { Table } from 'react-bootstrap';
import { QualificationAuditInformationResponse } from '../../api/responses/qualificationAuditInformationResponse';
import Dialog from '../../components/Dialog/Dialog';

interface LifeCycleModalProps {
  taskName: string;
  showLifeCycle: boolean;
  onHide: () => void;
  qualificationAuditInformation: QualificationAuditInformationResponse[];
}

const LifeCycleModal: React.FC<LifeCycleModalProps> = ({
  taskName,
  showLifeCycle,
  onHide,
  qualificationAuditInformation,
}) => {
  return (
    <Dialog
      className="life-cycle"
      show={showLifeCycle}
      size="xl"
      onHide={onHide}
      headerProps={{
        closeButton: true,
        onHide,
      }}
      scrollable
      header={<h4 className="fw-bold">Life Cycle - {taskName}</h4>}
      body={
        <Table>
          <thead>
            <tr>
              <td className="fw-bold">Time</td>
              <td className="fw-bold">User</td>
              <td className="fw-bold">Field</td>
              <td className="fw-bold">Action</td>
              <td className="fw-bold">Previous Value</td>
              <td className="fw-bold">New Value</td>
            </tr>
          </thead>
          <tbody>
            {qualificationAuditInformation.length > 0 ? (
              qualificationAuditInformation.map(auditEntry => {
                return (
                  <tr key={auditEntry.date_Created_Utc}>
                    <td className="align-middle">
                      {new Date(auditEntry.date_Created_Utc).toLocaleString()}
                    </td>
                    <td className="align-middle">{auditEntry.by_User}</td>
                    <td className="align-middle">
                      {auditEntry.column_Name?.replaceAll('|', '\n')}
                    </td>
                    <td className="align-middle">{auditEntry.operation}</td>
                    <td className="align-middle">
                      {auditEntry.previous_Value?.replaceAll('|', '\n')}
                    </td>
                    <td className="align-middle">{auditEntry.new_Value?.replaceAll('|', '\n')}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td>
                  <span className="align-middle">No information available</span>
                </td>
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
            )}
          </tbody>
        </Table>
      }
      footer={null}
    />
  );
};

export default LifeCycleModal;
