import { utils, writeFileXLSX } from 'xlsx';

type Worksheet = ReturnType<typeof utils.json_to_sheet>;

function build(data: Worksheet[], worksheetNames: string[], fileName: string): void {
  const workbook = utils.book_new();
  data.forEach((worksheet, index) => {
    utils.book_append_sheet(workbook, worksheet, worksheetNames[index]);
  });

  writeFileXLSX(workbook, `${fileName}.xlsx`);
}

function buildWorkSheet<T>(data: T[], headers: string[][]): Worksheet {
  const sheet = utils.json_to_sheet(data);
  utils.sheet_add_aoa(sheet, headers);
  return sheet;
}
export default { build, buildWorkSheet };
