/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Button, Card, Collapse, Form } from 'react-bootstrap';
import Select, { MultiValue, SingleValue } from 'react-select';
import { RolesResponse } from '../../../api/responses/rolesResponse';
import testIds from '../../../constants/testIds';
import CompanyUserImportSettingsType from '../../../models/CompanyUserImportSettingsType';
import { DisplayItem } from '../../../models/displayItem';
import Series from '../../../models/Series';
import AdvanceConfigurationCard from '../AdvanceConfigurationCard/AdvanceConfigurationCard';

export interface ConfigurationCardProps {
  availableSeriesList: Series[];
  handleOnChange: (
    newValue: MultiValue<{
      value: number;
      label: string;
    }>
  ) => void;
  handleHasHeaderRowOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleMakeTaskProfilingAssignmentsOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDefaultRoleOnChange: (
    newValue: SingleValue<{
      value: number | undefined;
      label: string;
    }>
  ) => void;
  fileUploadType: CompanyUserImportSettingsType;
  availableRolesList: RolesResponse[];
  handleLeadingZerosChange: (leadingZeros: DisplayItem) => void;
  leadingZeros: DisplayItem;
  handlePrependUsernameChange: (prependUsername: string) => void;
  handleMissingFieldsAssignmentsChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hasHeaderRow: boolean;
  missingFields: boolean;
  defaultRoleValue: { label: string; value: number | undefined } | undefined;
  seriesAccessValues: MultiValue<{ value: number; label: string }>;
  makeTaskProfilingAssignments: boolean;
  prependUsernameValue: string;
  initialAdvancedConfigurationToogleState: boolean;
}

const ConfigurationCard: React.FC<ConfigurationCardProps> = ({
  availableSeriesList,
  handleOnChange,
  handleHasHeaderRowOnChange,
  handleMakeTaskProfilingAssignmentsOnChange,
  fileUploadType,
  availableRolesList,
  handleDefaultRoleOnChange,
  handleLeadingZerosChange,
  leadingZeros,
  handlePrependUsernameChange,
  handleMissingFieldsAssignmentsChange,
  hasHeaderRow,
  missingFields,
  defaultRoleValue,
  seriesAccessValues,
  makeTaskProfilingAssignments,
  prependUsernameValue,
  initialAdvancedConfigurationToogleState,
}) => {
  const options = availableSeriesList.map(x => ({ value: x.id, label: x.name }));
  const roleOptions = availableRolesList.map(x => ({ value: x.roleId, label: x.roleName }));
  const [expanded, setExpanded] = useState<boolean>(false);
  useEffect(() => {
    setExpanded(initialAdvancedConfigurationToogleState);
  }, [initialAdvancedConfigurationToogleState]);

  function onToggleClick() {
    setExpanded(prevState => !prevState);
  }
  return (
    <Card className="mb-auto mr-auto mt-4" data-testid={testIds.configurationCard()}>
      <Card.Header className="bg-gray">
        <h5 className="fw-bold">Configuration</h5>
      </Card.Header>
      <Card.Body>
        {fileUploadType === CompanyUserImportSettingsType.SftpFileTransfer && (
          <div className="p-1">
            <Form.Check
              id="hasHeaderRowCheckbox"
              label="Has Header Row"
              data-testid={testIds.hasHeaderRow()}
              onChange={handleHasHeaderRowOnChange}
              checked={hasHeaderRow}
            />
          </div>
        )}
        <div className="p-1">
          <Form.Check
            id="makeTaskProfilingAssignmentsCheckbox"
            label="Make Assignments on Creation"
            data-testid={testIds.makeAssigmentsOnCreation()}
            onChange={handleMakeTaskProfilingAssignmentsOnChange}
            checked={makeTaskProfilingAssignments}
          />
        </div>
        <div className="mt-2 p-1">
          <form data-testid="defaultRoleForm">
            <label htmlFor="defaultRole">
              Default Role<span className="text-danger">*</span>
            </label>
            <Select
              isClearable
              isSearchable
              name="defaultRole"
              options={roleOptions}
              onChange={handleDefaultRoleOnChange}
              placeholder="Select Default Role"
              inputId="defaultRole"
              value={defaultRoleValue}
            />
          </form>
        </div>
        <div className="p-1">
          <form data-testid="form">
            <label htmlFor="series">
              Series Access<span className="text-danger">*</span>
            </label>
            <Select
              isClearable
              isSearchable
              isMulti
              name="series"
              options={options}
              onChange={handleOnChange}
              placeholder="Select Series"
              inputId="series"
              closeMenuOnSelect={false}
              value={seriesAccessValues}
            />
          </form>
        </div>
        <div className="mt-2 p-1">
          <Button
            data-testid={testIds.toggleAdvanceCofiguration()}
            variant="primary"
            className="border border-secondary"
            onClick={onToggleClick}
          >
            <span className="fw-bold">Advanced Configuration</span>
          </Button>
          <Collapse in={expanded}>
            <div data-testid={testIds.advanceConfigurationCard()}>
              <AdvanceConfigurationCard
                onLeadingZerosChange={displayItem => handleLeadingZerosChange(displayItem)}
                selectedLeadingZerosDisplayItem={leadingZeros}
                onPrependUsernameChange={prependUsername =>
                  handlePrependUsernameChange(prependUsername)
                }
                handleMissingFieldsAssignmentsOnChange={handleMissingFieldsAssignmentsChange}
                missingFields={missingFields}
                prependUsername={prependUsernameValue}
              />
            </div>
          </Collapse>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ConfigurationCard;
