import React, { useCallback, useEffect, useState } from 'react';
import useResizeObserver from './useResizeObserver';

const useIsOverflowing = (elementRef: React.RefObject<HTMLElement>): boolean => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  const checkOverflow = useCallback(() => {
    if (!elementRef.current) {
      return;
    }

    setIsOverflowing(elementRef.current.scrollWidth > elementRef.current.clientWidth);
  }, [elementRef]);

  useEffect(() => {
    checkOverflow();
  }, [checkOverflow]);

  useResizeObserver({
    callback: checkOverflow,
    elementRef,
  });

  return isOverflowing;
};

export default useIsOverflowing;
