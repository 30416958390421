export interface BasicAuthSession {
  user: {
    userId: number;
  };
  token: {
    type: 'Basic';
    expiresAt?: Date;
    expiresIn?: number;
    accessToken: string;
    idToken?: string;
    refreshToken?: string;
  };
}

async function getAuthSession(): Promise<BasicAuthSession> {
  return new Promise((resolve, reject) => {
    const userId = parseInt(localStorage.getItem('userId') || 'NaN', 10) || undefined;
    const accessToken = sessionStorage.getItem('basicToken') || undefined;
    if (userId && accessToken) {
      const authSession: BasicAuthSession = {
        user: {
          userId,
        },
        token: { accessToken, type: 'Basic' },
      };
      resolve(authSession);
    } else {
      reject(new Error('Invalid session'));
    }
  });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function refreshSession(_userId: number, _refreshToken: string): Promise<BasicAuthSession> {
  return Promise.reject(new Error(`Not implemented.`));
}

async function isUserAuthenticated(): Promise<boolean> {
  let hasAuth = false;
  try {
    const authSession = await getAuthSession();
    hasAuth = !!(authSession.token && authSession.token.accessToken);
  } catch (exc) {
    hasAuth = false;
  }
  return hasAuth;
}

export default {
  getAuthSession,
  refreshSession,
  isUserAuthenticated,
};
