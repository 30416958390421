const activitiesReportRequestQueryParams = {
  companyId: 'Company_Id',
  departmentId: 'User_Department_Id',
  userId: 'User_Id',
  companyType: 'Company_Type',
  activityStatusId: 'Activity_Status_Id',
  costCenterId: 'User_Cost_Center_Id',
  roleId: 'User_Role_Id',
  activityAssignerId: 'Activity_Assigner_Id',
  userSupervisorId: 'User_Supervisor_Id',
  workLocationId: 'User_Work_Location_Id',
  taskId: 'Task_Id',
  activityEvaluatorId: 'Activity_Evaluator_Id',
  seriesId: 'Series_Id',
  isEnabled: 'Is_Enabled',
  isCurrent: 'Is_Current',
  completedDate: 'Activity_Completion_Date',
  expirationDate: 'Activity_Expiration_Date',
  lastAccessedDate: 'Activity_Last_Accessed_Date',
} as const;

export default activitiesReportRequestQueryParams;
