import commonText from '../../../constants/commonText';
import JobAssocMethod from '../../../models/JobAssocMethod';
import { OptionsModes } from '../../../types/optionsModes';
import { ValuesByKey } from '../../../types/valuesByKey';

export const TaskProfileDetailDisplayModes = {
  // jobsAssocMethod maps to the JobAssocMethod enum by the id
  jobsAssocMethod: {
    // Legacy jobs
    [JobAssocMethod.Jobs]: { id: JobAssocMethod.Jobs, label: commonText.jobs },
    // Jobs with complex task mappings and enforcing task profiling
    [JobAssocMethod.JobsRespectMappingsAndTaskProfiling]: {
      id: JobAssocMethod.JobsRespectMappingsAndTaskProfiling,
      label: commonText.jobsRespectMappingsAndTaskProfiling,
    },
    // Jobs with complex task mappings and NOT enforcing task profiling
    [JobAssocMethod.JobsRespectMappingsOnly]: {
      id: JobAssocMethod.JobsRespectMappingsOnly,
      label: commonText.jobsRespectMappingsOnly,
    },
  },
} as const;

export type TaskProfileDetailDisplayMode = ValuesByKey<typeof TaskProfileDetailDisplayModes>;

export type TaskProfileDetailModes = OptionsModes<typeof TaskProfileDetailDisplayModes>;
