import React from 'react';
import DrawerMenuGroup from '../../Layout/Drawer/DrawerMenuGroup';

interface TaskProfileReportDrawerTabAccessProps {
  children: React.ReactNode;
}

const TaskProfileReportDrawerTabAccess: React.FC<TaskProfileReportDrawerTabAccessProps> = ({
  children,
}) => {
  return <DrawerMenuGroup>{children}</DrawerMenuGroup>;
};

export default TaskProfileReportDrawerTabAccess;
