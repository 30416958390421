import React from 'react';
import TabPane from 'react-bootstrap/TabPane';

export interface TabPanelProps<T> {
  tabId: T;
  children: React.ReactElement;
  className?: string;
  isActiveTab: boolean;
}

const TabPanel = <T extends string>({
  tabId,
  children,
  className = '',
  isActiveTab,
}: TabPanelProps<T>): JSX.Element => {
  return isActiveTab ? (
    <TabPane
      aria-labelledby={tabId}
      aria-label={tabId}
      className={className}
      id={`tab-pane-${tabId}`}
      role="tabpanel"
      eventKey={tabId}
    >
      {children}
    </TabPane>
  ) : (
    <></>
  );
};

export default TabPanel;
