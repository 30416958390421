import { useEffect, useState } from 'react';
import useMediaQuery, { MediaQuery } from '../../../hooks/useMediaQuery';
import { Dimensions } from '../../../models/dimensions';

const gridCardDimensions = {
  [MediaQuery.xs]: { width: 240, height: 185 },
  [MediaQuery.sm]: { width: 295, height: 185 },
};

function useTaskProfileCardDimensions(): Dimensions {
  const isSmallOrLarger = useMediaQuery(MediaQuery.sm);
  const [cardDimensions, setCardDimensions] = useState<Dimensions>(
    gridCardDimensions[MediaQuery.sm]
  );

  useEffect(() => {
    const dimensions = isSmallOrLarger
      ? gridCardDimensions[MediaQuery.sm]
      : gridCardDimensions[MediaQuery.xs];

    setCardDimensions(dimensions);
  }, [isSmallOrLarger]);

  return cardDimensions;
}

export default useTaskProfileCardDimensions;
