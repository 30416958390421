import { useMutation, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import JobMatrix from '../models/JobMatrix';
import { ApiSaveRequestOptions, ApiSaveResult } from './apiHooks';
import onBoardWebApiSvc from './apiOnBoardWebApi';
import { JobMatrixGetItemRequest } from './requests/jobMatrixGetItemRequest';
import { JobMatrixPostRequest } from './requests/jobMatrixPostRequest';
import { JobMatrixPutRequest } from './requests/jobMatrixPutRequest';
import { JobMatrixTaskDeleteRequest } from './requests/jobMatrixTaskDeleteRequest';
import { JobMatrixTaskGetListRequest } from './requests/jobMatrixTaskGetListRequest';
import { JobMatrixTaskPostRequest } from './requests/jobMatrixTaskPostRequest';
import { TaskByJobMatrixReadListRequest } from './requests/taskByJobMatrixReadListRequest';
import { UserDemographicsGetItemRequest } from './requests/userDemographicsGetItemRequest';
import { JobMatrixGetItemResponse } from './responses/jobMatrixGetItemResponse';
import { JobMatrixTaskDeleteResponse } from './responses/jobMatrixTaskDeleteResponse';
import { JobMatrixTaskGetListResponse } from './responses/jobMatrixTaskGetListResponse';
import { JobMatrixTaskPostResponse } from './responses/jobMatrixTaskPostResponse';
import { TaskGetListResponse } from './responses/taskGetListResponse';
import { UserDemographicsGetItemResponse } from './responses/userDemographicsGetItemResponse';

const useJobMatrixReadItem = <TError = unknown>(
  requestParams: JobMatrixGetItemRequest,
  options?: UseQueryOptions<
    JobMatrixGetItemResponse,
    TError,
    JobMatrixGetItemResponse,
    (string | JobMatrixGetItemRequest)[]
  >
): UseQueryResult<JobMatrixGetItemResponse, TError> => {
  return useQuery(
    ['jobMatrixReadItem', requestParams],
    async ({ queryKey }) =>
      onBoardWebApiSvc.jobMatrixReadItem(queryKey[1] as JobMatrixGetItemRequest),
    options
  );
};

const useJobMatrixCreate = (
  requestParams: JobMatrixPostRequest,
  options?: ApiSaveRequestOptions<JobMatrix, JobMatrixPostRequest>
): ApiSaveResult<JobMatrixPostRequest, JobMatrix> => {
  return useMutation(
    ['jobMatrixCreate', requestParams],
    params => onBoardWebApiSvc.jobMatrixCreate(params),
    options
  );
};

const useJobMatrixUpdate = (
  requestParams: JobMatrixPutRequest,
  options?: ApiSaveRequestOptions<void, JobMatrixPutRequest>
): ApiSaveResult<JobMatrixPutRequest, void> => {
  return useMutation(
    ['jobMatrixUpdate', requestParams],
    (params: JobMatrixPutRequest) => onBoardWebApiSvc.jobMatrixUpdate(params),
    options
  );
};

const useJobMatrixTaskReadList = <TError = unknown>(
  requestParams: JobMatrixTaskGetListRequest,
  options?: UseQueryOptions<
    JobMatrixTaskGetListResponse,
    TError,
    JobMatrixTaskGetListResponse,
    (string | JobMatrixTaskGetListRequest)[]
  >
): UseQueryResult<JobMatrixTaskGetListResponse, TError> => {
  return useQuery(
    ['jobMatrixTaskReadList', requestParams],
    async ({ queryKey }) =>
      onBoardWebApiSvc.jobMatrixTaskReadList(queryKey[1] as JobMatrixTaskGetListRequest),
    options
  );
};

const useJobMatrixTaskCreate = (
  requestParams: JobMatrixTaskPostRequest,
  options?: ApiSaveRequestOptions<JobMatrixTaskPostResponse, JobMatrixTaskPostRequest>
): ApiSaveResult<JobMatrixTaskPostRequest, JobMatrixTaskPostResponse> => {
  return useMutation(
    ['jobMatrixTaskCreate', requestParams],
    params => onBoardWebApiSvc.jobMatrixTaskCreate(params),
    options
  );
};

const useJobMatrixTaskDelete = (
  requestParams: JobMatrixTaskDeleteRequest,
  options?: ApiSaveRequestOptions<JobMatrixTaskDeleteResponse, JobMatrixTaskDeleteRequest>
): ApiSaveResult<JobMatrixTaskDeleteRequest, JobMatrixTaskDeleteResponse> => {
  return useMutation(
    ['jobMatrixTaskDelete', requestParams],
    params => onBoardWebApiSvc.jobMatrixTaskDelete(params),
    options
  );
};

const useTaskByJobMatrixReadList = (
  requestParams: TaskByJobMatrixReadListRequest,
  options?: UseQueryOptions<
    TaskGetListResponse,
    Error,
    TaskGetListResponse,
    (string | TaskByJobMatrixReadListRequest)[]
  >
): UseQueryResult<TaskGetListResponse, Error> => {
  return useQuery(
    ['taskByJobMatrixReadList', requestParams],
    async ({ queryKey }) =>
      onBoardWebApiSvc.taskByJobMatrixReadList(queryKey[1] as TaskByJobMatrixReadListRequest),
    options
  );
};

const useUserDemographicsReadItem = <TError = unknown>(
  requestParams: UserDemographicsGetItemRequest,
  options?: UseQueryOptions<
    UserDemographicsGetItemResponse,
    TError,
    UserDemographicsGetItemResponse,
    (string | UserDemographicsGetItemRequest)[]
  >
): UseQueryResult<UserDemographicsGetItemResponse, TError> => {
  return useQuery(
    ['userDemographicsReadItem', requestParams],
    async ({ queryKey }) =>
      onBoardWebApiSvc.userDemographicsReadItem(queryKey[1] as UserDemographicsGetItemRequest),
    options
  );
};

export default {
  useJobMatrixCreate,
  useJobMatrixReadItem,
  useJobMatrixUpdate,
  useJobMatrixTaskCreate,
  useJobMatrixTaskReadList,
  useJobMatrixTaskDelete,
  useTaskByJobMatrixReadList,
  useUserDemographicsReadItem,
};
