import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import routes from '../../constants/routes';
import useCompany from '../../hooks/useCompany';
import useUserInformation from '../../hooks/useUserInformation';
import usePolicyService from '../../policyService/usePolicyService';
import IntegrationAnalytics from './IntegrationAnalytics';
import useIntegrationBatchStatisticsByCompanyId from '../../hooks/useIntegrationBatchStatisticsByCompanyId';
import useGetCurrentIntegrations from '../../hooks/useGetCurrentIntegrations';
import useCompanyId from '../../hooks/useCompanyId';
import CurrentIntegrationsResponse from '../../models/CurrentIntegrationsResponse';
import IntegrationHistoryTabNames from '../../models/IntegrationHistoryTabNames';
import commonText from '../../constants/commonText';
import RecentUploadsChartColumnIds from '../../models/RecentUploadsChartColumnIds';
import { DateTime } from 'luxon';
import useGetCurrentIntegrationsTable from '../../hooks/useGetCurrentIntegrationsTable';

const IntegrationAnalyticsContainer: React.FC = () => {
  const policyService = usePolicyService();
  const { company, isLoading: isCompanyLoading } = useCompany();
  const { companyId } = useCompanyId();
  const { user, isLoading: isUserLoading } = useUserInformation();
  const navigate = useNavigate();
  const [selectedIntegration, setSelectedIntegration] = useState<CurrentIntegrationsResponse>({
    Id: -1,
    DateEditedUtc: '',
    IntegrationName: commonText.selectAnIntegration,
    Type: 1,
    IsEnabled: true,
  });

  const {
    data: integrationBatchStatistics,
    isFetching: isIntegrationBatchStatisticsFetching,
    refetch,
  } = useIntegrationBatchStatisticsByCompanyId(companyId, selectedIntegration.Id);

  const {
    data: currentIntegrations,
    isLoading: isCurrentIntegrationsLoading,
    isFetching: isCurrentIntegrationsFetching,
  } = useGetCurrentIntegrations(companyId);

  const {
    data: currentIntegrationsData,
    isLoading: isCurrentIntegrationsDataLoading,
    isFetching: isCurrentIntegrationsDataFetching,
    isError: currentIntegrationsDataError,
  } = useGetCurrentIntegrationsTable(companyId);

  const handleBarClick = (column: string, date: string) => {
    let activeTab = '';
    switch (column) {
      case RecentUploadsChartColumnIds.newUsers:
        activeTab = IntegrationHistoryTabNames.Created.id;
        break;
      case RecentUploadsChartColumnIds.updateUsers:
        activeTab = IntegrationHistoryTabNames.Updated.id;
        break;
      case RecentUploadsChartColumnIds.errorUsers:
        activeTab = IntegrationHistoryTabNames.Errors.id;
        break;
      default:
        break;
    }
    const parsedDate = DateTime.fromISO(date).toFormat('yyyy-MM-dd');
    navigate(
      routes.integrationHistory.url(undefined, {
        activeTab,
        date: parsedDate,
        integrationId: selectedIntegration.Id,
      })
    );
  };

  useEffect(() => {
    if (selectedIntegration.Id !== -1) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIntegration.Id]);

  useEffect(() => {
    if (selectedIntegration.Id === -1 && currentIntegrations.length > 0) {
      setSelectedIntegration(currentIntegrations[0]);
    }
  }, [currentIntegrations, selectedIntegration.Id]);

  const onSelectionChange = (currentIntegration: CurrentIntegrationsResponse) => {
    setSelectedIntegration(currentIntegration);
  };

  return (
    <IntegrationAnalytics
      policies={policyService.policies()}
      companyName={company?.name}
      companyImage={company?.logo}
      isCompanyLoading={isCompanyLoading}
      isUserLoading={isUserLoading}
      user={user}
      integrationBatchStatisticsByCompanyIdResponses={integrationBatchStatistics}
      isIntegrationBatchStatisticsByCompanyIdLoading={isIntegrationBatchStatisticsFetching}
      currentIntegrations={currentIntegrations || []}
      isCurrentIntegrationsLoading={isCurrentIntegrationsLoading || isCurrentIntegrationsFetching}
      selectedIntegration={selectedIntegration}
      setSelectedIntegration={onSelectionChange}
      handleBarClick={handleBarClick}
      currentIntegrationsData={currentIntegrationsData}
      isCurrentIntegrationsDataLoading={
        isCurrentIntegrationsDataLoading || isCurrentIntegrationsDataFetching
      }
      currentIntegrationsDataError={currentIntegrationsDataError}
    />
  );
};

export default IntegrationAnalyticsContainer;
