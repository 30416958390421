import { MutationFunction } from '@tanstack/react-query';
import axios from 'axios';
import QueryString from 'qs';
import authService from '../auth/authOnBoardWebApiService';
import JobMatrix from '../models/JobMatrix';
import {
  JobMatrixGetItemRoute,
  JobMatrixPostRoute,
  JobMatrixPutRoute,
  JobMatrixTaskDeleteRoute,
  JobMatrixTaskGetListRoute,
  JobMatrixTaskPostRoute,
  TaskByJobMatrixGetListRoute,
  UserDemographicsGetItemRoute,
} from './apiRoutesOnBoardWebApi';
import { JobMatrixGetItemRequest } from './requests/jobMatrixGetItemRequest';
import { JobMatrixPostRequest } from './requests/jobMatrixPostRequest';
import { JobMatrixPutRequest } from './requests/jobMatrixPutRequest';
import { JobMatrixTaskDeleteRequest } from './requests/jobMatrixTaskDeleteRequest';
import { JobMatrixTaskGetListRequest } from './requests/jobMatrixTaskGetListRequest';
import { JobMatrixTaskPostRequest } from './requests/jobMatrixTaskPostRequest';
import { UserDemographicsGetItemRequest } from './requests/userDemographicsGetItemRequest';
import { JobMatrixGetItemResponse } from './responses/jobMatrixGetItemResponse';
import { JobMatrixTaskDeleteResponse } from './responses/jobMatrixTaskDeleteResponse';
import { JobMatrixTaskGetListResponse } from './responses/jobMatrixTaskGetListResponse';
import { JobMatrixTaskPostResponse } from './responses/jobMatrixTaskPostResponse';
import { TaskGetListResponse } from './responses/taskGetListResponse';
import { UserDemographicsGetItemResponse } from './responses/userDemographicsGetItemResponse';

const onBoardApi = axios.create({
  baseURL: process.env.REACT_APP_ONBOARD_WEB_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: {
    serialize: params => QueryString.stringify(params),
  },
});

onBoardApi.interceptors.request.use(async config => {
  const authSession = await authService.getAuthSession();
  if (config !== undefined && config.headers !== undefined) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `${authSession.token.type} ${authSession.token.accessToken}`;
  }
  return config;
});

async function jobMatrixCreate(requestParams: JobMatrixPostRequest): Promise<JobMatrix> {
  const response = await onBoardApi.post(
    JobMatrixPostRoute(requestParams.companyId),
    requestParams.data
  );

  return response.data;
}

const jobMatrixReadItem = async (
  requestParams: JobMatrixGetItemRequest
): Promise<JobMatrixGetItemResponse> => {
  const remoteResponse = await onBoardApi.get(
    JobMatrixGetItemRoute(requestParams.companyId, requestParams.jobMatrixId)
  );
  const response: JobMatrixGetItemResponse = {
    data: { ...remoteResponse.data },
  };
  // Hydrate properties.
  if (response.data.dateCreated) {
    response.data.dateCreated = new Date(response.data.dateCreated);
  }
  return Promise.resolve(response);
};

async function jobMatrixUpdate(requestParams: JobMatrixPutRequest): Promise<void> {
  await onBoardApi.put(
    JobMatrixPutRoute(requestParams.companyId, requestParams.jobMatrixId),
    requestParams.data
  );
}

const jobMatrixTaskCreate: MutationFunction<
  JobMatrixTaskPostResponse,
  JobMatrixTaskPostRequest
> = async (requestParams: JobMatrixTaskPostRequest): Promise<JobMatrixTaskPostResponse> => {
  const remoteResponse = await onBoardApi.post(
    JobMatrixTaskPostRoute(requestParams.companyId, requestParams.jobMatrixId),
    requestParams.data
  );
  const response: JobMatrixTaskPostResponse = {
    routeName: JobMatrixTaskGetListRoute(requestParams.companyId, remoteResponse.data.jobMatrixId),
    routeValues: {
      companyId: requestParams.companyId,
      jobMatrixId: remoteResponse.data.jobMatrixId,
    },
    value: remoteResponse.data,
  };
  return Promise.resolve(response);
};

const jobMatrixTaskDelete: MutationFunction<
  JobMatrixTaskDeleteResponse,
  JobMatrixTaskDeleteRequest
> = async (requestParams: JobMatrixTaskDeleteRequest): Promise<JobMatrixTaskDeleteResponse> => {
  await onBoardApi.delete(
    JobMatrixTaskDeleteRoute(
      requestParams.companyId,
      requestParams.jobMatrixId,
      requestParams.taskId
    )
  );
  const response: JobMatrixTaskDeleteResponse = {};
  return response;
};

const jobMatrixTaskReadList = async (
  requestParams: JobMatrixTaskGetListRequest
): Promise<JobMatrixTaskGetListResponse> => {
  const remoteResponse = await onBoardApi.get(
    JobMatrixTaskGetListRoute(requestParams.companyId, requestParams.jobMatrixId)
  );
  const response: JobMatrixTaskGetListResponse = {
    data: [...remoteResponse.data],
  };
  return response;
};

const taskByJobMatrixReadList = async (
  requestParams: JobMatrixTaskGetListRequest
): Promise<TaskGetListResponse> => {
  const remoteResponse = await onBoardApi.get(
    TaskByJobMatrixGetListRoute(requestParams.companyId, requestParams.jobMatrixId)
  );
  return remoteResponse;
};

const userDemographicsReadItem = async (
  requestParams: UserDemographicsGetItemRequest
): Promise<UserDemographicsGetItemResponse> => {
  const remoteResponse = await onBoardApi.get(UserDemographicsGetItemRoute(requestParams.userId));
  return remoteResponse;
};

export default {
  jobMatrixCreate,
  jobMatrixReadItem,
  jobMatrixUpdate,
  jobMatrixTaskCreate,
  jobMatrixTaskReadList,
  jobMatrixTaskDelete,
  taskByJobMatrixReadList,
  userDemographicsReadItem,
};
