import React from 'react';
import CloseButton from 'react-bootstrap/CloseButton';
import Dropdown from 'react-bootstrap/Dropdown';
import ListGroup from 'react-bootstrap/ListGroup';
import testIds from '../../../constants/testIds';
import CustomViewAccessType from '../../../models/customViewAccessType';
import customViewAccessTypeConvertor from '../../../utils/converter/customViewAccessTypeConverter';

export interface ListItemWithDropDownProps {
  text: string;
  onRemove: () => void;
  selectedCustomViewAccessType: CustomViewAccessType;
  onChange: (customViewAccessType: CustomViewAccessType) => void;
  isDisabled: boolean;
}

const ListItemWithDropDown: React.FC<ListItemWithDropDownProps> = ({
  text,
  onRemove,
  selectedCustomViewAccessType,
  onChange,
  isDisabled,
}) => {
  return (
    <ListGroup.Item
      role="listitem"
      className="m-1 border-0 rounded btn-sm user-select-none d-flex align-items-center"
    >
      <div className="">{text}</div>
      {!isDisabled ? (
        <Dropdown className="ms-auto" data-testid={testIds.listItemWithDropdownContainer()}>
          <Dropdown.Toggle
            variant="light"
            id={testIds.listItemWithDropdownToggle()}
            data-testid={testIds.listItemWithDropdownToggle()}
          >
            {customViewAccessTypeConvertor.convert(selectedCustomViewAccessType)}
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="z-index-popover"
            renderOnMount
            popperConfig={{ strategy: 'fixed' }}
          >
            <Dropdown.Item
              role="button"
              as="button"
              active={selectedCustomViewAccessType === CustomViewAccessType.ViewOnly}
              onClick={() => onChange(CustomViewAccessType.ViewOnly)}
            >
              View Only
            </Dropdown.Item>
            <Dropdown.Item
              role="button"
              as="button"
              active={selectedCustomViewAccessType === CustomViewAccessType.Editor}
              onClick={() => onChange(CustomViewAccessType.Editor)}
            >
              Editor
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <div className="ms-auto me-5 font-size-m">
          {customViewAccessTypeConvertor.convert(selectedCustomViewAccessType)}
        </div>
      )}
      {!isDisabled ? <CloseButton onClick={onRemove} className="ms-2" aria-hidden /> : null}
    </ListGroup.Item>
  );
};

export default ListItemWithDropDown;
