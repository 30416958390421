import apiHooks from '../api/integrationApi/integrationApiHooks';
import CurrentIntegrationsResponse from '../models/CurrentIntegrationsResponse';

function useGetAllIntegrations(companyId: number): {
  data: CurrentIntegrationsResponse[];
  isLoading: boolean;
  isError: boolean;
  refetch: Function;
  isFetching: boolean;
} {
  const {
    data = [],
    isLoading,
    isError,
    refetch,
    isFetching,
  } = apiHooks.useGetAllIntegrations(companyId);

  return { data, isLoading, isError, refetch, isFetching };
}

export default useGetAllIntegrations;
