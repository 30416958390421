import React, { useEffect, useState } from 'react';
import { UserAccessResponse } from '../../../api/responses/userAccessResponse';
import CustomViewAccessType from '../../../models/customViewAccessType';
import { DisplayItemWithCustomViewAccessType } from '../../../models/displayItemWithCustomViewAccessType';
import ListItemWithDropDown from '../../MultiPartSelect/ListItemWithDropDown/ListItemWithDropDown';
import MultiPartSelectBase from '../../MultiPartSelect/MultiPartSelectBase/MultiPartSelectBase';

export interface ShareTabMultiPartSelectProps {
  items: DisplayItemWithCustomViewAccessType[];
  onSearch: (text: string) => void;
  onChange: (item: DisplayItemWithCustomViewAccessType) => void;
  selectedUsers: UserAccessResponse[];
  emptyListText: string;
  isLoading: boolean;
  onClear: () => void;
  onSelectAll: () => void;
  onAccessChange: (displayItem: DisplayItemWithCustomViewAccessType) => void;
  isDisabled?: boolean;
}

const ShareTabMultiPartSelect: React.FC<ShareTabMultiPartSelectProps> = ({
  items,
  onSearch,
  onChange,
  selectedUsers,
  emptyListText,
  isLoading,
  onClear,
  onSelectAll,
  onAccessChange,
  isDisabled = false,
}) => {
  const [selectedListItems, setSelectedListItems] = useState<
    Record<number, DisplayItemWithCustomViewAccessType>
  >({});

  useEffect(() => {
    const selectedItems: Record<number, DisplayItemWithCustomViewAccessType> = {};

    selectedUsers.forEach(selectedId => {
      const value = items.find(item => item.itemId === selectedId.userId);
      if (value !== undefined) {
        selectedItems[value.itemId] = {
          ...value,
          customViewAccessType: selectedId.customViewAccessType,
        };
      }
    });

    setSelectedListItems(selectedItems);
  }, [selectedUsers, items, setSelectedListItems]);

  return (
    <MultiPartSelectBase
      items={items}
      onSearch={onSearch}
      onChange={onChange}
      numberOfSelected={selectedUsers.length}
      emptyListText={emptyListText}
      isLoading={isLoading}
      onClear={onClear}
      onSelectAll={onSelectAll}
      selectedList={
        <>
          {Object.values(selectedListItems)
            .sort((left, right) => {
              if (left.customViewAccessType === CustomViewAccessType.Owner) {
                return -1;
              }
              if (left.customViewAccessType === right.customViewAccessType) {
                return 0;
              }
              return 1;
            })
            .map(listItem => (
              <ListItemWithDropDown
                key={listItem.itemId}
                onRemove={() => onChange(listItem)}
                text={listItem.display}
                onChange={customViewAccessType =>
                  onAccessChange({ ...listItem, customViewAccessType })
                }
                selectedCustomViewAccessType={listItem.customViewAccessType}
                isDisabled={listItem.customViewAccessType === CustomViewAccessType.Owner}
              />
            ))}
        </>
      }
      selectedListItems={selectedListItems}
      selectedListGroupClassName="flex-column overflow-auto flex-shrink-0"
      isDisabled={isDisabled}
      tabId="shared-tab-multipart-select"
    />
  );
};

export default ShareTabMultiPartSelect;
