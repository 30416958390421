import React, { useEffect, useState } from 'react';
import apiHooksLearningHistory from '../../api/apiHooksLearningHistory';
import { GetLearningHistoryReportRequest } from '../../api/requests/getLearningHistoryReport';
import useActiveEmployeeStatus from '../../hooks/useActiveEmployeeStatus';
import useCompany from '../../hooks/useCompany';
import useCompanyId from '../../hooks/useCompanyId';
import useCompanySeriesTasks from '../../hooks/useCompanySeriesTasks';
import useCostCenters from '../../hooks/useCostCenters';
import useDepartments from '../../hooks/useDepartments';
import useEmployeesAll from '../../hooks/useEmployeesAll';
import useCompanyEvaluators from '../../hooks/useEvaluators';
import useQualificationsReasons from '../../hooks/useQualificationsReasons';
import useQualificationStatus from '../../hooks/useQualificationStatus';
import useSeries from '../../hooks/useSeries';
import useSupervisors from '../../hooks/useSupervisors';
import useUserInformation from '../../hooks/useUserInformation';
import useWorkLocations from '../../hooks/useWorkLocations';
import usePolicyService from '../../policyService/usePolicyService';
import { ToastInformation } from '../ActivitiesReport/ActivitiesReport';
import LearningHistory from './LearningHistory';

const LearningHistoryContainer: React.FC = () => {
  const { companyId } = useCompanyId();
  const { isLoading: isCompanyLoading, company } = useCompany();
  const { isLoading: isUserLoading, user } = useUserInformation();
  const policyService = usePolicyService();
  const [toastInformation, setToastInformation] = useState<ToastInformation>({
    showToast: false,
    isSuccessful: false,
  });
  const [lifeCycleToastInformation, setLifeCycleToastInformation] = useState<ToastInformation>({
    showToast: false,
    isSuccessful: false,
  });
  const [qualificationId, setQualificationId] = useState<string>('');
  const [showLifeCycleDialog, setShowLifeCycleDialog] = useState(false);
  const [learningHistoryRequest, setLearningHistoryRequest] =
    useState<GetLearningHistoryReportRequest>({
      companyId,
      sortBy: [
        {
          isDesc: false,
          key: 'user_Id',
        },
        {
          isDesc: false,
          key: 'task_Code',
        },
        {
          isDesc: false,
          key: 'task_Name',
        },
        {
          isDesc: false,
          key: 'qualification_Completed',
        },
      ],
    });
  const {
    data: learningHistoryData,
    isLoading: isDataLoading,
    isFetching: isFetchingData,
    isError,
    fetchNextPage,
    hasNextPage,
  } = apiHooksLearningHistory.useGetLearningHistoryReport(learningHistoryRequest);

  const onRequestChange = (changes: GetLearningHistoryReportRequest) => {
    setLearningHistoryRequest(changes);
  };

  const downloadExcel = apiHooksLearningHistory.useGetLearningHistoryReportExcel(
    learningHistoryRequest,
    {
      retry: false,
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setToastInformation({ showToast: true, isSuccessful: true });
      },
      onError: () => {
        setToastInformation({ showToast: true, isSuccessful: false });
      },
    }
  );

  const {
    data,
    refetch,
    isFetching: isFetchingAuditInfo,
  } = apiHooksLearningHistory.useGetQualificationAuditInformation(qualificationId, {
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: () => {
      setShowLifeCycleDialog(true);
    },
    onError: () => {
      setLifeCycleToastInformation({ showToast: true, isSuccessful: false });
      setQualificationId('');
    },
  });

  useEffect(() => {
    if (qualificationId !== undefined && qualificationId !== '') {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qualificationId]);

  return (
    <LearningHistory
      companyId={companyId}
      company={company}
      isCompanyLoading={isCompanyLoading}
      isUserLoading={isUserLoading}
      policies={policyService.policies()}
      user={user}
      isLearningHistoryDataLoading={isDataLoading || isFetchingData}
      requestError={isError}
      learningHistoryData={learningHistoryData?.pages}
      learningHistoryRequest={learningHistoryRequest}
      downloadExcel={downloadExcel}
      toastInformation={toastInformation}
      onLearningHistoryRequestChange={onRequestChange}
      setToastInformation={setToastInformation}
      useCostCenters={useCostCenters}
      useDepartments={useDepartments}
      useEmployeesAll={useEmployeesAll}
      useActiveEmployeeStatus={useActiveEmployeeStatus}
      useSeries={useSeries}
      useTasks={useCompanySeriesTasks}
      useQualificationStatus={useQualificationStatus}
      useReasons={useQualificationsReasons}
      useWorkLocations={useWorkLocations}
      useSupervisors={useSupervisors}
      useEvaluators={useCompanyEvaluators}
      qualificationAuditInformation={data ?? []}
      setQualificationId={setQualificationId}
      qualificationAuditInformationIsLoading={isFetchingAuditInfo}
      showLifeCycleDialog={showLifeCycleDialog}
      setShowLifeCycleDialog={setShowLifeCycleDialog}
      lifeCycleToastInformation={lifeCycleToastInformation}
      setLifeCycleInformation={setLifeCycleToastInformation}
      onPageChange={fetchNextPage}
      hasNextPage={hasNextPage || false}
    />
  );
};

export default LearningHistoryContainer;
