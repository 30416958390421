export const GetBatchUserStatisticsDetailsRoute = (
  companyId: number,
  integrationId: number
): string => `/companies/${companyId}/integrations/${integrationId}/statistics`;

export const GetBatchUserStatisticsByCompanyIdRoute = (companyId: number): string =>
  `/companies/${companyId}/batches/statistics`;

export const GetIntegrationBatchStatisticsByCompanyIdRoute = (
  companyId: number,
  integrationId: number
): string => `/companies/${companyId}/integrations/${integrationId}/details`;

export const GetCurrentIntegrationsRoute = (companyId: number): string =>
  `/companies/${companyId}/userImportSettings`;

export const DeleteCurrentIntegrationRoute = (companyId: number, integrationId: number): string =>
  `/companies/${companyId}/userImportSettings/${integrationId}`;

export const GetIntegrationHistoryExcelReportRoute = (
  companyId: number,
  integrationId: number
): string => `/companies/${companyId}/integrations/${integrationId}/report`;
