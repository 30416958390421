import commonText from '../constants/commonText';

const FilterTabNames = {
  employees: { id: 'employees', name: commonText.employees },
  costCenters: { id: 'costCenters', name: commonText.costCenters },
  departments: { id: 'departments', name: commonText.departments },
  jobs: { id: 'jobs', name: commonText.jobs },
  roles: { id: 'roles', name: 'Roles' },
  supervisors: { id: 'supervisors', name: commonText.supervisors },
  workLocations: { id: 'workLocations', name: commonText.workLocations },
  companyType: { id: 'companyType', name: commonText.companyType },
  status: { id: 'status', name: commonText.status },
  assigners: { id: 'assigners', name: commonText.assigners },
  evaluators: { id: 'evaluators', name: commonText.evaluators },
  tasks: { id: 'tasks', name: commonText.tasks },
  series: { id: 'series', name: commonText.series },
  date: { id: 'date', name: commonText.date },
  reasons: { id: 'reasons', name: commonText.reason },
} as const;

export default FilterTabNames;
