import { TaskProfileCardFeatures } from '../../components/TaskProfile/ReportDrawer/taskProfileCardDisplayOptions';
import commonText from '../../constants/commonText';

function parse(cardDisplayOptions: TaskProfileCardFeatures): string {
  const cardTexts = [];

  cardTexts.push(cardDisplayOptions.viewAsIncomplete ? commonText.incomplete : commonText.complete);

  if (cardDisplayOptions.tasks) {
    cardTexts.push(commonText.tasks);
  }

  if (cardDisplayOptions.training) {
    cardTexts.push(commonText.training);
  }

  if (cardDisplayOptions.wePe) {
    cardTexts.push(`${commonText.we}/${commonText.pe}`);
  }

  if (cardDisplayOptions.roles) {
    cardTexts.push(commonText.roles);
  }

  if (cardDisplayOptions.jobs) {
    cardTexts.push(commonText.jobs);
  }

  if (cardDisplayOptions.username) {
    cardTexts.push(commonText.username);
  }

  if (cardDisplayOptions.userId) {
    cardTexts.push(commonText.userId);
  }

  if (cardDisplayOptions.note) {
    cardTexts.push(commonText.note);
  }

  return cardTexts.join(' | ');
}

export default { parse };
