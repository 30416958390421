import _ from 'lodash';
import { DateRangeFilter } from '../requests/getActivitiesReportRequest';

export enum QueryFilterType {
  string,
  number,
  boolean,
  array,
  arrayString,
  date,
  dateRange,
  arrayDateRange,
  arrayNumberProperty,
  arrayStringProperty,
  arrayArrayProperty,
}

export type QueryFilterValueType =
  | boolean[]
  | string[]
  | number[]
  | Date[]
  | DateRangeFilter
  | undefined;

export interface QueryFilters {
  key: string | string[];
  type: QueryFilterType;
  value: QueryFilterValueType;
}

export function processSpecialCharacters(searchText: string): string {
  let processedText = searchText;
  processedText = processedText.replace(/&/g, '&amp;');
  processedText = processedText.replace(/'/g, '&apos;');
  processedText = processedText.replace(/</g, '&lt;');
  processedText = processedText.replace(/>/g, '&gt;');
  processedText = processedText.replace(/"/g, '&quot;');
  processedText = processedText.replace(/\\/g, '\\\\');
  return processedText;
}

export function addPropertyToFilterObject(
  filters: QueryFilters[],
  key: string | string[],
  value: QueryFilterValueType,
  filterType: QueryFilterType
): QueryFilters[] {
  if (value === undefined) return filters;
  if (_.isEmpty(value)) return filters;

  return filters.concat([{ key, type: filterType, value }]);
}

export function createOdataFilter(filters: QueryFilters[]): string {
  const filterGroup: string[] = [];
  let auxGroup: string[];
  let auxQuery: string;
  let auxValue;
  filters.forEach(({ key, type, value }) => {
    if (value === undefined) return;
    switch (type) {
      case QueryFilterType.string:
        if (!Array.isArray(value)) break;
        auxValue = value.map((val: string | number | boolean | Date) =>
          processSpecialCharacters(val.toString())
        );
        auxGroup = auxValue.map((id: number | boolean | string | Date) => `${key} eq '${id}'`);
        filterGroup.push(`(${auxGroup.join(' or ')})`);
        break;
      case QueryFilterType.number:
      case QueryFilterType.boolean:
        if (!Array.isArray(value)) break;

        auxGroup = value.map((id: number | boolean | string | Date) => `${key} eq ${id}`);
        filterGroup.push(`(${auxGroup.join(' or ')})`);
        break;
      case QueryFilterType.date:
        if (!Array.isArray(value)) break;

        auxQuery = `(${key} ge ${value[0]} and ${key} le ${value[1]})`;
        filterGroup.push(auxQuery);
        break;
      case QueryFilterType.dateRange:
        if (Array.isArray(value)) break;

        auxGroup = [];
        if (value.From !== '') auxGroup.push(`${key} ge ${value.From}`);
        if (value.To !== '') auxGroup.push(`${key} le ${value.To}`);
        if (auxGroup.length > 0) filterGroup.push(auxGroup.join(' and '));
        break;
      case QueryFilterType.array:
        if (!Array.isArray(value)) break;

        auxGroup = value.map((id: number | boolean | string | Date) => `x eq ${id}`);
        auxQuery = `${key}/any(x: ${auxGroup.join(' or ')})`;
        filterGroup.push(auxQuery);
        break;
      case QueryFilterType.arrayDateRange:
        if (Array.isArray(value)) break;

        auxGroup = [];
        auxQuery = '';
        if (value.From !== '') auxGroup.push(`x/${key[1]} ge ${value.From}`);
        if (value.To !== '') auxGroup.push(`x/${key[1]} le ${value.To}`);
        if (auxGroup.length > 0) {
          auxQuery = auxGroup.join(' and ');
        }
        if (auxQuery.length > 0) {
          auxQuery = `${key[0]}/any(x: ${auxQuery})`;
          filterGroup.push(auxQuery);
        }
        break;
      case QueryFilterType.arrayNumberProperty:
        if (!Array.isArray(value)) break;

        auxGroup = [];
        auxQuery = '';

        auxGroup = value.map((id: number | boolean | string | Date) => `x/${key[1]} eq ${id}`);
        if (auxGroup.length > 0) {
          auxQuery = auxGroup.join(' and ');
        }
        if (auxQuery.length > 0) {
          auxQuery = `${key[0]}/any(x: ${auxQuery})`;
          filterGroup.push(auxQuery);
        }
        break;
      case QueryFilterType.arrayStringProperty:
        if (!Array.isArray(value)) break;

        auxGroup = [];
        auxQuery = '';
        auxGroup = value.map((id: number | boolean | string | Date) => {
          const processesText = processSpecialCharacters(id as string);
          return `x/${key[1]} eq '${processesText}'`;
        });
        if (auxGroup.length > 0) {
          auxQuery = auxGroup.join(' and ');
        }
        if (auxQuery.length > 0) {
          auxQuery = `${key[0]}/any(x: ${auxQuery})`;
          filterGroup.push(auxQuery);
        }
        break;
      case QueryFilterType.arrayArrayProperty:
        if (!Array.isArray(value)) break;

        auxGroup = [];
        auxQuery = '';
        auxGroup = value.map((id: number | boolean | string | Date) => `y eq ${id}`);
        if (auxGroup.length > 0) {
          auxQuery = auxGroup.join(' and ');
        }
        if (auxQuery.length > 0) {
          auxQuery = `${key[0]}/any(x: x/${[key[1]]}/any(y: ${auxQuery}))`;
          filterGroup.push(auxQuery);
        }
        break;
      default:
        break;
    }
  });
  return filterGroup.join(' and ');
}
