const LearningHistoryRequestQueryParams = {
  companyId: 'Company_Id',
  isCurrent: 'Is_Current',
  costCenters: 'User_Cost_Center_Id',
  departments: 'User_Department_Id',
  completedDate: 'Qualification_Completed',
  expirationDate: 'Qualification_Expires',
  employees: 'User_Id',
  seriesIds: 'Series_Id',
  tasksIds: 'Task_Id',
  qualificationStatus: 'Qualification_Status_Code',
  reasons: 'Reason',
  workLocations: 'User_Work_Location_Id',
  supervisors: 'User_Supervisor_Id',
  evaluator: 'Activity_Evaluator_Id',
  roles: 'User_Role_Id',
  activities: 'Activities',
} as const;

export default LearningHistoryRequestQueryParams;
