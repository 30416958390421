// Seconds
export const ThirtySeconds = 30;
export const SixtySeconds = 60;

// Milliseconds
export const TwoHundredAndFiftyMilliseconds = 250;
export const OneSecondInMilliseconds = 1000;
export const TwentySecondsInMilliseconds = 20 * OneSecondInMilliseconds;
export const ThirtySecondsInMilliseconds = OneSecondInMilliseconds * ThirtySeconds;
export const OneMinuteInMilliseconds = OneSecondInMilliseconds * 60;
export const OneMinuteFortyFiveSecondsInMilliseconds = OneMinuteInMilliseconds * 1.75;
export const FiveMinutesInMilliseconds = 300000;
export const OneHourInMilliseconds = 60 * OneMinuteInMilliseconds;

export default {
  TwoHundredAndFiftyMilliseconds,
  OneSecondInMilliseconds,
  TwentySecondsInMilliseconds,
  ThirtySecondsInMilliseconds,
  OneMinuteInMilliseconds,
  OneMinuteFortyFiveSecondsInMilliseconds,
  FiveMinutesInMilliseconds,
  OneHourInMilliseconds,
};
