import React, { useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import Circle from '../Icons/Circle/Circle';
import ExpressionNode from '../../models/ExpressionNode';
import BadgeListItem from '../MultiPartSelect/BadgeListItem/BadgeListItem';

// Value will be true when the button should be enabled.
// Used to manage which operator buttons should be enabled as the user builds the task mapping.
export interface EquivalenciesExpressionPaletteButtonDisabledState {
  [key: string]: boolean;
}

// Value will be true when the selected item should be visible, false if not.
export interface EquivalenciesExpressionPaletteButtonVisibleState {
  [key: string]: boolean;
}

/**
 * @param {ExpressionNode[]} availableExpressionTerms - All the expression terms as `ExpressionNode`s available in the palette for adding to the canvas.
 * @param {EquivalenciesExpressionPaletteButtonDisabledState} buttonIsDisabled - A hash indicating which buttons should be disabled (`HTMLInputElement.disabled`) based on the state of the expression.
 * @param {number} expressionGroupDepth - A value representing how many unclosed precedence groups there are in the expression.
 * @param onSelectTerm - Callback called when the user adds an expression term from `availableExpressionTerms` (for interacting with the expression builder).
 * @param onSelectOperator - Callback called when the user adds an operator like 'AND' or 'OR' (for interacting with the expression builder).
 * @param onSelectPunctuator - Callback called when the user adds a punctuator like '(' or ')' (for interacting with the expression builder).
 * @param {EquivalenciesExpressionPaletteButtonVisibleState} selectedTasksPaletteVisibilityState - A hash indicating which expression node Tasks should be visible (`HTMLElement.className`) based on whether it has been used.
 * @param {boolean|undefined} isDisabled - Optional indicator whether this component is disabled, false by default.
 */
export interface EquivalenciesExpressionPaletteProps {
  availableExpressionTerms: ExpressionNode[];
  buttonIsDisabled: EquivalenciesExpressionPaletteButtonDisabledState;
  expressionGroupDepth: number;
  onSelectTerm: (term: ExpressionNode) => void;
  onSelectOperator: (operator: ExpressionNode) => void;
  onSelectPunctuator: (punctuator: ExpressionNode) => void;
  selectedTasksPaletteVisibilityState: EquivalenciesExpressionPaletteButtonVisibleState;
  isDisabled?: boolean;
}

/**
 * A component for building logic expressions visually.
 * @see {@linkcode EquivalenciesExpressionCanvas}.
 */
const EquivalenciesExpressionPalette: React.FC<EquivalenciesExpressionPaletteProps> = ({
  availableExpressionTerms,
  buttonIsDisabled,
  expressionGroupDepth,
  onSelectTerm,
  onSelectOperator,
  onSelectPunctuator,
  selectedTasksPaletteVisibilityState,
  isDisabled = false,
}) => {
  const termBadgeCanBeRemoved = useCallback(() => false, []);

  return (
    <div className="section" id="equivalencies-palette">
      <div id="equivalencies-palette-headers" className="d-inline-flex mt-2">
        <Circle
          styleParam={{ aspectRatio: '1' }}
          className="mx-auto mt-1 text-center text-white"
          color="primary"
          text="3"
        />
        <div>
          <h3 className="header mb-0 ms-1">Add Mapping</h3>
          <h6 className="ms-1">
            <span className="text-muted">Select items to visualize Task relationships.</span>
          </h6>
        </div>
      </div>
      <Form aria-disabled={isDisabled}>
        <fieldset className="border rounded-1 p-2" disabled={isDisabled}>
          <legend className="float-none w-auto mb-0 font-size-l">Selected Tasks</legend>
          <div className="container g-0">
            <div className="row">
              <div className="col">
                <ul
                  id="task-selected-list"
                  className="flex-wrap overflow-auto flex-shrink-0 list-group list-group-horizontal"
                >
                  {Object.values(availableExpressionTerms).map(exprTerm => (
                    <BadgeListItem
                      key={exprTerm.termId}
                      onClick={() => onSelectTerm(exprTerm)}
                      text={exprTerm.label}
                      canBeRemoved={termBadgeCanBeRemoved}
                      isHidden={!selectedTasksPaletteVisibilityState[exprTerm.label]}
                    />
                  ))}
                </ul>
              </div>
              <div className="col-2 d-grid gap-1 d-block me-3" id="available-operator-list">
                <div className="row">
                  <div className="col px-0">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-primary shadow fw-bold w-100 available-operator operator operator-logical operator-logical-and"
                      value="$and"
                      onClick={() => onSelectOperator({ nodeType: 'logicalOperator', label: '&&' })}
                      disabled={buttonIsDisabled.logicalOper}
                      aria-label="logical and"
                    >
                      AND
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col px-0">
                    <button
                      type="button"
                      className="btn btn-sm btn-primary shadow fw-bold w-100 available-operator operator operator-logical operator-logical-or"
                      value="$or"
                      onClick={() => onSelectOperator({ nodeType: 'logicalOperator', label: '||' })}
                      disabled={buttonIsDisabled.logicalOper}
                      aria-label="logical or"
                    >
                      OR
                    </button>
                  </div>
                </div>
                <div className="row text-center">
                  <div className="col px-0">
                    <button
                      type="button"
                      className="btn btn-sm btn-secondary shadow fw-bold available-operator operator operator-grouping operator-grouping-open"
                      value="$groupingOpen"
                      onClick={() =>
                        onSelectPunctuator({ nodeType: 'groupingParenthesis', label: '(' })
                      }
                      disabled={buttonIsDisabled.openParen}
                      aria-label="opening parenthesis"
                    >
                      <span>(</span>
                      <sub
                        id="groupDepth"
                        className={expressionGroupDepth > 0 ? 'visible' : 'invisible'}
                      >
                        {expressionGroupDepth}
                      </sub>
                    </button>
                  </div>
                  <div className="col px-0">
                    <button
                      type="button"
                      className="btn btn-sm btn-secondary shadow fw-bold available-operator operator operator-grouping operator-grouping-close"
                      value="$groupingClose"
                      onClick={() =>
                        onSelectPunctuator({ nodeType: 'groupingParenthesis', label: ')' })
                      }
                      disabled={buttonIsDisabled.closeParen}
                      aria-label="closing parenthesis"
                    >
                      <span>)</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </Form>
    </div>
  );
};

export default EquivalenciesExpressionPalette;
