import { ApiSaveRequestOptions } from '../api/apiHooks';
import apiHooksOnBoardWebApi from '../api/apiHooksOnBoardWebApi';
import onBoardWebApiSvc from '../api/apiOnBoardWebApi';
import { JobMatrixPostRequest } from '../api/requests/jobMatrixPostRequest';
import { JobMatrixPutRequest } from '../api/requests/jobMatrixPutRequest';
import JobMatrix from '../models/JobMatrix';

export interface JobMatrixSaveMutation {
  onSaveAsync: (
    companyId: number,
    jobMatrix: JobMatrix,
    nextJobMatrixTaskIds: number[]
  ) => Promise<JobMatrix | void>;
}

function useJobMatrixSave(
  companyId: number,
  jobMatrix: JobMatrix,
  isEditing: boolean,
  saveOptions?: ApiSaveRequestOptions<JobMatrix, JobMatrixPostRequest>,
  updateOptions?: ApiSaveRequestOptions<void, JobMatrixPutRequest>
): JobMatrixSaveMutation {
  const createJobMatrix = apiHooksOnBoardWebApi.useJobMatrixCreate(
    { companyId, data: jobMatrix },
    saveOptions
  );
  const updateJobMatrix = apiHooksOnBoardWebApi.useJobMatrixUpdate(
    {
      companyId,
      jobMatrixId: jobMatrix.id,
      data: jobMatrix,
    },
    updateOptions
  );

  const handleCreateJobMatrix = async (
    theCompanyId: number,
    data: JobMatrix,
    nextJobMatrixTaskIds: number[]
  ) => {
    return createJobMatrix
      .mutateAsync({ companyId: theCompanyId, data })
      .then(async remoteJobMatrix => {
        await Promise.all(
          // Create each `JobMatrixTask`.
          nextJobMatrixTaskIds.map(taskId =>
            onBoardWebApiSvc.jobMatrixTaskCreate({
              companyId: theCompanyId,
              jobMatrixId: remoteJobMatrix.id,
              data: {
                jobMatrixId: remoteJobMatrix.id,
                taskId,
                isEnabled: true,
                dateCreated: new Date(),
              },
            })
          )
        );
      });
  };

  const handleUpdateJobMatrix = async (
    theCompanyId: number,
    data: JobMatrix,
    nextJobMatrixTaskIds: number[]
  ) => {
    return updateJobMatrix
      .mutateAsync({
        companyId: theCompanyId,
        jobMatrixId: data.id,
        data,
      })
      .then(async () => {
        // Create or update each `JobMatrixTask`.
        const jobMatrixTaskGetResp = await onBoardWebApiSvc.jobMatrixTaskReadList({
          companyId: theCompanyId,
          jobMatrixId: data.id,
        });
        const prevJobMatrixTaskIds = jobMatrixTaskGetResp?.data.map(jm => jm.taskId) ?? [];

        const prevNotInNext: number[] = prevJobMatrixTaskIds.filter(
          prevId => !nextJobMatrixTaskIds.includes(prevId)
        );
        const nextNotInPrev: number[] = nextJobMatrixTaskIds.filter(
          nextId => !prevJobMatrixTaskIds.includes(nextId)
        );

        await Promise.all(
          // Delete (softly) each `JobMatrixTask`.
          prevNotInNext.map(taskId =>
            onBoardWebApiSvc.jobMatrixTaskDelete({
              companyId: theCompanyId,
              jobMatrixId: jobMatrix.id,
              taskId,
            })
          )
        );
        await Promise.all(
          // Create each `JobMatrixTask`.
          nextNotInPrev.map(taskId =>
            onBoardWebApiSvc.jobMatrixTaskCreate({
              companyId: theCompanyId,
              jobMatrixId: jobMatrix.id,
              data: {
                jobMatrixId: jobMatrix.id,
                taskId,
                isEnabled: true,
                dateCreated: new Date(),
              },
            })
          )
        );
      });
  };

  return {
    onSaveAsync: isEditing ? handleUpdateJobMatrix : handleCreateJobMatrix,
  };
}

export default useJobMatrixSave;
