// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= //
/**
 * Reusable collator/comparator for comparing expression operators consistently.
 */
export default class OperatorCollator {
  /**
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Operator_Precedence#table
   */
  public static readonly PrecedenceByOperator: { [operator: string]: number } = {
    '(': 19,
    ')': 19,
    '!': 15,
    '&&': 5,
    '||': 4,
  };

  private numberCollator: Intl.Collator;

  constructor() {
    this.numberCollator = new Intl.Collator([], { numeric: true });
  }

  /**
   * Operator precedence values reflect a priority so, larger numbers come
   *	first, not smaller numbers.
   * @param {string} nodeOperator - Operator which comes first (left-to-right) unless precedence is overridden.
   * @param {string} parentOperator - Operator which comes second (left-to-right) unless precedence is overridden.
   * @returns -1 if the `leftOperator` has a lower precedence, 0 if the operators
   *	have the same precedence, and +1 if the `leftOperator` has a higher precedence.
   * @example <caption>Logical OR has a lower precedence than logical AND.</caption>
   *	const operColl = new OperatorCollator();
   *	const precedenceIsUnchanged = operColl.compare( '&&', '||' ) < 0;
   *	// true
   *	const precedenceIsOverridden = operColl.compare( '||', '&&' ) > 0;
   *	// true
   */
  // eslint-disable-next-line class-methods-use-this
  public compare(
    nodeOperator: keyof typeof OperatorCollator.PrecedenceByOperator,
    parentOperator: keyof typeof OperatorCollator.PrecedenceByOperator
  ): number {
    const comparison = this.numberCollator.compare(
      // The numeric precedence values must be explicitly converted to strings
      //	because the TypeScript definition accepts only strings.
      OperatorCollator.PrecedenceByOperator[parentOperator].toString(),
      OperatorCollator.PrecedenceByOperator[nodeOperator].toString()
    );
    // Value kept in temporary variable for access to the result when debugging.
    return comparison;
  }
}
// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= //
