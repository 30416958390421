export const RefreshTokenRoute = (): string => `/refreshtoken`;

export const CompanyDetailsRoute = (companyId: number): string => `/companies/${companyId}/details`;

export const CompanyQualificationsRoute = (companyId: number): string =>
  `/companies/${companyId}/qualifications`;

export const CompanySeriesRoute = (companyId: number): string => `/companies/${companyId}/series`;

export const CompanySeriesTasksRoute = (companyId: number, seriesId: number): string =>
  `/companies/${companyId}/series/${seriesId}/tasks`;

export const CompaniesUserStatisticsRoute = (companyId: number, userId: number): string =>
  `/companies/${companyId}/users/${userId}/statistics`;

export const CompaniesUsersRolesRoute = (companyId: number, userId: number): string =>
  `/companies/${companyId}/users/${userId}/roles`;

export const CompaniesUsersTasksRoute = (companyId: number, userId: number): string =>
  `/companies/${companyId}/users/${userId}/tasks`;

export const CompaniesUsersComponentsRoute = (companyId: number, userId: number): string =>
  `/companies/${companyId}/users/${userId}/components`;

export const CompaniesUsersTrainingsRoute = (companyId: number, userId: number): string =>
  `/companies/${companyId}/users/${userId}/trainings`;

export const CompaniesUsersJobsRoute = (companyId: number, userId: number): string =>
  `/reporting/companies/${companyId}/users/${userId}/jobs`;

export const CompaniesRolesRoute = (companyId: number): string => `/companies/${companyId}/roles`;

export const CompaniesCompanySitesRoute = (companyId: number): string =>
  `/companies/${companyId}/sites`;

export const CompaniesSupervisorsRoute = (companyId: number): string =>
  `/companies/${companyId}/supervisors`;

export const CompaniesCostCentersRoute = (companyId: number): string =>
  `/companies/${companyId}/cost-centers`;

export const CompaniesDepartmentsRoute = (companyId: number): string =>
  `/companies/${companyId}/departments`;

export const CompaniesUsersRoute = (companyId: number): string => `/companies/${companyId}/users`;

export const CompaniesJobsRoute = (companyId: number): string =>
  `/reporting/companies/${companyId}/jobs`;

export const SaveCustomViewRoute = (companyId: number): string => `/companies/${companyId}/views`;

export const UpdateCustomViewRoute = (companyId: number, viewId: number): string =>
  `/companies/${companyId}/views/${viewId}`;

export const DeleteCustomViewRoute = (companyId: number, viewId: number): string =>
  `/companies/${companyId}/views/${viewId}`;

export const UsersCustomViewsRoute = (companyId: number): string => `/companies/${companyId}/views`;

export const ReadCustomViewsRoute = (companyId: number, viewId: number): string =>
  `/companies/${companyId}/views/${viewId}`;

export const GetCustomViewAccessUsersRoute = (companyId: number, viewId: number): string =>
  `/companies/${companyId}/views/${viewId}/users`;

export const UpdateCustomViewAccessRoute = (companyId: number, viewId: number): string =>
  `/companies/${companyId}/views/${viewId}/users`;

export const UpdateCustomViewConnectionRoute = (companyId: number, viewId: number): string =>
  `/companies/${companyId}/views/${viewId}/connection`;

export const GetCustomViewConnectionsRoute = (companyId: number): string =>
  `/companies/${companyId}/views/connection`;

export const GetMessageOfTheDayImageUrlsRoute = (companyId: number, customViewId: number): string =>
  `/companies/${companyId}/views/${customViewId}/imageUrls`;

export const GetMessageOfTheDayImageRoute = (companyId: number, customViewId: number): string =>
  `/companies/${companyId}/views/${customViewId}/image`;

export const GetUsersPoliciesRoute = (companyId: number): string =>
  `/companies/${companyId}/policies`;

export const GetUsersCustomViewPoliciesRoute = (companyId: number): string =>
  `/companies/${companyId}/views/policies`;

export const GetActivitiesReportRoute = (): string =>
  `/activities/reports/text/SearchActivityReports`;

export const GetActivitiesReportCountRoute = (): string =>
  `/activities/reports/text/SearchActivityReports/$count`;

export const GetCompanyAssignersRoute = (companyId: number): string =>
  `/companies/${companyId}/assignments/assigners`;

export const GetCompanyEvaluatorsRoute = (companyId: number): string =>
  `/companies/${companyId}/skillactivity/evaluators`;

export const GetActivitiesReportExcelRoute = (): string =>
  `/activities/reports/ActivityReports/download`;

export const CompaniesUsersAllRoute = (companyId: number): string =>
  `/companies/${companyId}/users/all`;

export const GetActivityAffidavitByIdRoute = (affidavitId: string | undefined): string =>
  `/activities/reports/ActivityReports/affidavit/${affidavitId}`;

export const GetUserInformation = (userId: number): string => `/users/${userId}`;

export const GetAllTransferClientUser = (companyId: number): string =>
  `/companies/${companyId}/users/integrations/sftp`;

export const PostCompanyUserImportSettingsRoute = (companyId: number): string =>
  `/companies/${companyId}/userImportSettings`;

export const GetLearningHistoryReportRoute = (): string =>
  '/qualifications/text/QualificationReports';

export const GetQualificationsReasonsRoute = (): string => '/qualifications/qualifications/reasons';

export const GetLearningHistoryReportExcelRoute = (): string => '/qualifications/reports/download';

export const PostCompanySftpUserRoute = (companyId: number): string =>
  `companies/${companyId}/users/integrations/sftp`;

export const PostRegenerateCompanySftpCredentialsRoute = (
  companyId: number,
  companySftpId: number
): string => `companies/${companyId}/users/integrations/sftp/regenerate/${companySftpId}`;

export const GetQualificationAuditInformationRoute = (qualificationId: string): string =>
  `/qualifications/AuditLog/${qualificationId}`;

export const GetUserIntegrationRoute = (companyId: number, userIntegrationId: number): string =>
  `companies/${companyId}/userImportSettings/${userIntegrationId}`;

export const PutCompanyUserImportSettingsRoute = (
  companyId: number,
  integrationId: number
): string => `/companies/${companyId}/userImportSettings/${integrationId}`;

export default {
  RefreshTokenRoute,
  CompanyDetailsRoute,
  CompanyQualificationsRoute,
  CompaniesUserStatisticsRoute,
  CompaniesUsersRolesRoute,
  CompaniesUsersTasksRoute,
  CompaniesUsersComponentsRoute,
  CompaniesUsersTrainingsRoute,
  CompaniesUsersJobsRoute,
  CompaniesRolesRoute,
  CompaniesCompanySitesRoute,
  CompaniesSupervisorsRoute,
  CompaniesCostCentersRoute,
  CompaniesDepartmentsRoute,
  CompaniesUsersRoute,
  CompaniesJobsRoute,
  SaveCustomViewRoute,
  DeleteCustomViewRoute,
  UsersCustomViewsRoute,
  GetCustomViewAccessUsersRoute,
  UpdateCustomViewAccessRoute,
  UpdateCustomViewConnectionRoute,
  GetMessageOfTheDayImageUrlsRoute,
  GetMessageOfTheDayImageRoute,
  GetUsersPoliciesRoute,
  GetUsersCustomViewPoliciesRoute,
  GetActivitiesReportRoute,
  GetCompanyAssignersRoute,
  GetActivitiesReportExcelRoute,
  GetActivityAffidavitByIdRoute,
  GetUserInformation,
  PostCompanyUserImportSettingsRoute,
  GetLearningHistoryReportRoute,
  GetAllTransferClientUser,
  PostCompanySftpUserRoute,
  GetQualificationsReasonsRoute,
  GetLearningHistoryReportExcelRoute,
  GetUserIntegrationRoute,
  PutCompanyUserImportSettingsRoute,
};
