/* eslint-disable no-bitwise */
enum CompanyPreferences {
  //  transcript report
  TaskAsPrefix = 0,
  TaskAsSuffix = 1,
  //  "Third Party Verified" field displayed on transcript reports
  ShowThirdPartyVerified = 2,

  // OBSOLETE: DO NOT USE THIS ITEM. Use language pack instead - AM 10.5.11.
  // term used on reports: CETP uses Certified, Natural Gas uses Qualified
  // UseCertifiedTerm = 4, CompanyPreference&4=4: Certified, CompanyPreference&4=0: Qualified
  // END OBSOLETE
  // / <summary>
  // / If this is checked the Task Profile Report will be in grouped mode to start for all users.
  // / </summary>
  GroupTaskProfileReportByDefault = 4,
  // This company uses a custom logo. Otherwise use standard logo.
  CustomLogo = 8,

  // Is template company. The template company serves as a template for any other company. This company is created on install as the default company.
  IsTemplate = 16,

  // Use custom task version. Since the Company.Preference is stored in memory (session) by checking this value,
  // we save ourselves a trip to the DB. Once this value is set for a single series, it will apply as true for
  // all series, causing a trip to the DB.
  // [Obsolete("This is original design. no longer in use")]
  CustomTaskVersion = 32,

  // certification expiration date is calculated differently.
  // Method 1: Expires on latest date of the knowledge/skill combo
  // Method 2: Expires on earliest date of the knowledge/skill combo
  // default is method 2: Company.Preference|=64 (on)
  CertificationExpiresFromEarliestDate = 64,

  // InvoicingAllowed. Allows a company to purchase items and use immediately and be invoiced later. If this option is
  // set after purchase, a company is allowed to assign the purchased courses immediately.
  InvoicingAllowed = 128,

  // VoucherAllowed. Allows a company to use the voucher system. Can be set by a sysadmin
  VoucherAllowed = 256,
  ViewHelpGuides = 512,

  // RUn custom script upon company creation
  CustomAddCompanyScript = 1024,

  // UsersShareEmailAddress allow company to use same email address for multiple users. By default, this option is set to false.
  // 	Users that register new accounts will require a unique email address.
  UsersShareEmailAddress = 2048,

  // AllowDuplicateEmailSingleAddress. Allows duplicate email but for a single address instead of company wide.
  // Registration page uses this feature. Will check the CompanyEmailDuplicate table for allowed emails
  AllowDuplicateEmailSingleAddress = 4096,

  // ShowInternalProctors If this is set then the proctor filter will only show internal proctors by default
  // Will have a checkbox to show all proctors internal to the company and external company proctors.
  ShowInternalProctors = 8192,

  // UseWorkflowDefault allows companies to have the workflow pre-selected on the NewCourseAssignmentsUnlimited page
  UseWorkflowDefault = 1 << 14, // 16384

  // NOT IN USE
  // NotifyDeptSupervisor: [Workflow] Notifies department supervisors of pass/failures
  // NotifyDeptSupervisor = 1 << 15,  // 32768

  // Determines whether a company has access to the Events module
  UseEventsModule = 1 << 15, // 32768

  // Notify of Course completion
  NotifyOfCourseCompletion = 1 << 16, // 65536

  // Notify of Course completion
  NotifyOfCourseFailure = 1 << 17, // 131072

  // Company will use the alternative skill upload affidavit statement which allows administrators to upload affidavits
  // on behalf of the evaluator.
  AllowSkillUploadByAdmin = 1 << 18, // 262144

  // RequalificationReminderSeparateEmail: sets the reminder emails to go out in separate emails for each qualification
  // reminder period. For example, 90 day reminders will go out in one email, 60 day reminders in another, etc.
  RequalificationReminderSeparateEmail = 1 << 19, // 524288

  // if set, the checkbox on the gap report and the missing skill report will be checked by default
  // that checkbox shows only missing skills that fall within the skill submit period
  ViewMissingSkillsWithinSubmitPeriodDefault = 1 << 20, // 1048576

  // UseQRCode allows companies to use the QR code. Company managers can generate QRCs while students can view.
  UseQRCode = 1 << 21, // 2097152

  // on the Requalification reminder, if no reminders are sent, this option will email the supervisors
  NotifyWhenNoReminders = 1 << 22, // 4194304

  // on the qualification gap report, if this is checked, the show optional skills checkbox will be checked by default
  ShowOptionalSkills = 1 << 23, // 8388608

  // during login, if this is set, then we will check to see if any NOCs are expiring within the month
  UsesTaskRevisions = 1 << 24, // 16777216
  // if UsesTaskRevisions is set, then on the reports we need to display the NOC expiring message
  CheckTaskRevisionOnReport = 1 << 25, // 33554432

  // display the requalify tab on myassignments.aspx
  DisplyRequalifyTab = 1 << 26, //  67108864

  // view Expiring Report column on User Page
  HideExpiringReportColumn = 1 << 27, // 134217728

  DisplayMinimalContent = 1 << 28, // 268435456

  // use task profiling
  UseTaskProfiling = 1 << 29, // 536870912

  // disable all emails in this company
  DisableCompanyEmail = 1 << 30, // 1073741824

  // hide find proctors link on the learn landing page
  // [Obsolete("HideFindProctors is being removed from Learning Page.", false)]
  HideFindProctors = 2147483648,

  // Note: We have reached the maximum Int value. More company preferences will require a move to BigInt.

  // Use Assessments Module
  UseAssessmentsModule = 4294967296,

  // Use Single Sign On
  UseSSO = 8589934592,

  // Use PII
  UsePII = 17179869184,

  // View jobs on the wallet card
  ViewJobsOnWalletCard = 34359738368,

  // / <summary>
  // / Hide OnBoard links on email notifications
  // / </summary>
  HideOnBoardLinksOnEmails = 68719476736,
  // / <summary>
  // / Enforce password change in prometric if set
  // / </summary>
  EnforcePasswordChangeInPrometric = 137438953472,

  // / <summary>
  // / Needed to use dashboard microservices
  // / </summary>
  ContractorManagement = 274877906944,

  // / <summary>
  // / View roles on the wallet card
  // / </summary>
  ViewRolesOnWalletCard = 549755813888,

  // / <summary>
  // / View NCMS Status on wallet card
  // / </summary>
  ViewNCMSOnWalletCard = 1099511627776,

  UseVerify = 2199023255552,

  // / <summary>
  // / Verify users can save drafts
  // / </summary>
  VerifyUseDrafts = 4398046511104,

  // / <summary>
  // / Allows users in this company to view the NCMS status for a company and users under that company in  OnBoard LMS reporting
  // / </summary>
  ViewNCMSinOnBoard = 8796093022208, //  1 << 43

  // / <summary>
  // / Allows all users in the company that have report view permission to see the legacy task profile report
  // / </summary>
  ViewLegacyTaskProfileReport = 17592186044416,

  // / <summary>
  // / Allows users in this company to mark new or existing Jobs to use with custom verification in Verify.
  // / </summary>
  UseCustomJobs = 35184372088832, // 1 << 45,

  // / <summary>
  // / Allows users in this company to create jobs within Verify.
  // / </summary>
  VerifyCreateJobs = 70368744177664, // 1 << 46

  // / <summary>
  // / When this preference is enabled, verifcation completed for this company will allow users to associate Non-OQ workers to their
  // / crew by providing a first and last name
  // / </summary>
  VerifyAllowNonOQ = 140737488355328, // 1 << 47
  VerifyNCMS = 281474976710656, // 1 << 48
}

export default CompanyPreferences;
