import React from 'react';

export interface TaskProfileCardUsernameProps {
  userId: number;
  userName: string;
  isUserIdEnabled: boolean;
  isUsernameEnabled: boolean;
}

const TaskProfileCardUsername: React.FC<TaskProfileCardUsernameProps> = ({
  userId,
  userName,
  isUserIdEnabled,
  isUsernameEnabled,
}) => {
  if (isUserIdEnabled && isUsernameEnabled) {
    return (
      <>
        {userName} | {userId}
      </>
    );
  }

  if (isUsernameEnabled) {
    return <>{userName}</>;
  }

  if (isUserIdEnabled) {
    return <>{userId}</>;
  }

  return <>&nbsp;</>;
};

export default TaskProfileCardUsername;
