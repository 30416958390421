import { useQuery } from '@tanstack/react-query';
import useAuth from './useAuth';

/**
 * A hook that is used to check to see if the user is authenticated and if so, appropriately initialize them.
 */
function useSignInExistingUser(): boolean {
  const { isUserAuthenticated } = useAuth();

  const { isLoading } = useQuery(['signInExistingUser'], () => isUserAuthenticated());

  return isLoading;
}

export default useSignInExistingUser;
