import React, { useEffect, useState } from 'react';
import commonText from '../../constants/commonText';
import useCompany from '../../hooks/useCompany';
import useCompanyId from '../../hooks/useCompanyId';
import useGetAllIntegrations from '../../hooks/useGetAllIntegrations';
import useGetIntegrationHistoryExcelReport from '../../hooks/useGetIntegrationHistoryExcelReport';
import useGetIntegrationUserStatisticsDetails from '../../hooks/useGetIntegrationUserStatisticsDetails';
import useUserInformation from '../../hooks/useUserInformation';
import CurrentIntegrationsResponse from '../../models/CurrentIntegrationsResponse';
import IntegrationHistoryTabNames from '../../models/IntegrationHistoryTabNames';
import UserIntegrationStatisticsType from '../../models/UserIntegrationStatisticsType';
import usePolicyService from '../../policyService/usePolicyService';
import batchUserExporter from '../../utils/excelBuilder/batchUserExporter';
import IntegrationHistory from './IntegrationHistory';
import { useSearchParams } from 'react-router-dom';
import batchUserStatisticsExcelReport from '../../api/responses/batchUserStatisticsExcelReport';

export interface DateFilters {
  startDate: string;
  endDate: string;
}

const IntegrationHistoryContainer: React.FC = () => {
  const policyService = usePolicyService();
  const { companyId } = useCompanyId();
  const { company, isLoading: isCompanyLoading } = useCompany();
  const { user, isLoading: isUserLoading } = useUserInformation();
  const [searchParams] = useSearchParams();
  const [selectedIntegration, setSelectedIntegration] = useState<CurrentIntegrationsResponse>({
    Id: -1,
    DateEditedUtc: '',
    IntegrationName: commonText.selectAnIntegration,
    Type: 1,
    IsEnabled: true,
  });
  const [activeTabState, setActiveTab] = useState<string>(IntegrationHistoryTabNames.Created.id);
  const [dateFilters, setDateFilters] = useState<DateFilters>({
    startDate: '',
    endDate: '',
  });
  const activeTab = searchParams.get('activeTab');
  const date = searchParams.get('date') || '';
  const integrationId = searchParams.get('integrationId');

  useEffect(() => {
    setActiveTab(activeTab || IntegrationHistoryTabNames.Created.id);
    if (date !== '') {
      setDateFilters({
        endDate: date,
        startDate: date,
      });
    }
  }, [activeTab, date]);

  const {
    data: currentIntegrations,
    isLoading: isCurrentIntegrationsLoading,
    isFetching: isCurrentIntegrationsFetching,
  } = useGetAllIntegrations(companyId);

  useEffect(() => {
    const id = Number(integrationId);
    if (!Number.isNaN(integrationId)) {
      const integration = currentIntegrations.find(i => i.Id === id);
      if (integration !== undefined) {
        setSelectedIntegration(integration);
      }
    }
  }, [currentIntegrations, integrationId]);

  const onIntegrationSelection = (integration: CurrentIntegrationsResponse) => {
    setSelectedIntegration(integration);
  };

  const onDateFiltersChange = (change: Partial<DateFilters>) => {
    setDateFilters(prev => ({ ...prev, ...change }));
  };

  const {
    integrationUserStatistics: newUsersStatistics,
    isLoading: isNewUserStatisticsLoading,
    hasNextPage: newUsersHasNextPage,
    fetchNextPage: fetchNextNewUsersPage,
  } = useGetIntegrationUserStatisticsDetails(
    companyId,
    selectedIntegration.Id,
    dateFilters,
    UserIntegrationStatisticsType.newUsers,
    'getNewUserStatistics'
  );

  const onExcelReportSuccess = (data: batchUserStatisticsExcelReport) => {
    batchUserExporter.exportExcel(
      data.NewUsers,
      data.UpdatedUsers,
      data.ErrorUsers,
      selectedIntegration.IntegrationName
    );
  };

  const { isFetching: isFetchingExcelReport, refetch: refetchExcelReport } =
    useGetIntegrationHistoryExcelReport(
      companyId,
      selectedIntegration.Id,
      dateFilters,
      onExcelReportSuccess
    );

  const {
    integrationUserStatistics: updatedUsersStatistics,
    isLoading: isUpdatedUserStatisticsLoading,
    hasNextPage: updatedUsersHasNextPage,
    fetchNextPage: fetchNextUpdatedUsersPage,
  } = useGetIntegrationUserStatisticsDetails(
    companyId,
    selectedIntegration.Id,
    dateFilters,
    UserIntegrationStatisticsType.updatedUsers,
    'getUpdatedUserStatistics'
  );

  const {
    integrationUserStatistics: totalErrorsUsersStatistics,
    isLoading: isTotalErrorsUserStatisticsLoading,
    hasNextPage: totalErrosHasNextPage,
    fetchNextPage: fetchNextTotalErrorsPage,
  } = useGetIntegrationUserStatisticsDetails(
    companyId,
    selectedIntegration.Id,
    dateFilters,
    UserIntegrationStatisticsType.totalErrors,
    'getTotalErrorsUserStatistics'
  );

  const onExcelReportClick = () => refetchExcelReport();

  return (
    <IntegrationHistory
      policies={policyService.policies()}
      company={company}
      isCompanyLoading={isCompanyLoading}
      isUserLoading={isUserLoading}
      user={user}
      currentIntegrations={currentIntegrations}
      isCurrentIntegrationsLoading={isCurrentIntegrationsFetching || isCurrentIntegrationsLoading}
      selectedIntegration={selectedIntegration}
      setSelectedIntegration={onIntegrationSelection}
      dateFilters={dateFilters}
      setDateFilters={onDateFiltersChange}
      newUserStatistics={newUsersStatistics.pages}
      updatedUserStatistics={updatedUsersStatistics.pages}
      totalErrorsStatistics={totalErrorsUsersStatistics.pages}
      activeTab={activeTabState}
      setActiveTab={setActiveTab}
      isNewUserStatisticsLoading={isNewUserStatisticsLoading}
      isUpdatedUserStatisticsLoading={isUpdatedUserStatisticsLoading}
      isTotalErrorsUserStatisticsLoading={isTotalErrorsUserStatisticsLoading}
      newUsersHasNextPage={newUsersHasNextPage || false}
      updatedUsersHasNextPage={updatedUsersHasNextPage || false}
      totalErrorsHasNextPage={totalErrosHasNextPage || false}
      fetchNextNewUsersPage={fetchNextNewUsersPage}
      fetchNextUpdatedUsersPage={fetchNextUpdatedUsersPage}
      fetchNextTotalErrorsPage={fetchNextTotalErrorsPage}
      onExcelReportClick={onExcelReportClick}
      isExcelReportLoading={isFetchingExcelReport}
    />
  );
};

export default IntegrationHistoryContainer;
