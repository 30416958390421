import React from 'react';
import { OverlayTriggerProps } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import RBTooltip from 'react-bootstrap/Tooltip';

export interface TooltipsProps extends Omit<OverlayTriggerProps, 'overlay' | 'children'> {
  content: React.ReactNode;
  trigger?: 'hover' | 'click' | 'focus' | Array<'hover' | 'click' | 'focus'>;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipsProps> = ({
  content,
  placement = 'right',
  children,
  trigger = ['hover', 'focus'],
  show,
}) => {
  return (
    <div>
      <OverlayTrigger
        trigger={trigger}
        placement={placement}
        show={show}
        overlay={<RBTooltip>{content}</RBTooltip>}
      >
        {({ ref, ...tooltipProps }) => {
          return (
            //  eslint-disable-next-line react/jsx-props-no-spreading
            <div ref={ref} {...tooltipProps}>
              {children}
            </div>
          );
        }}
      </OverlayTrigger>
    </div>
  );
};

export default Tooltip;
