import { CustomViewReport } from '../../api/responses/customViewReport';
import { getPrimaryDataPointCardDisplayOption } from '../../components/TaskProfile/ReportDrawer/taskProfileCardPrimaryDataPoints';
import defaultTaskProfileReportData from '../../models/defaultTaskProfileReportData';
import { TaskProfileReportData } from '../../models/taskProfileReportData';

function convert(
  customViewReport: CustomViewReport<string>
): CustomViewReport<TaskProfileReportData> {
  const taskProfileReportDataParsed: TaskProfileReportData = JSON.parse(
    customViewReport.reportData
  );

  const primaryDataPoint =
    taskProfileReportDataParsed.primaryDataPoint ?? defaultTaskProfileReportData.primaryDataPoint;

  const primaryDataPointCardDisplayOption = getPrimaryDataPointCardDisplayOption(primaryDataPoint);

  const customViewReportResult: CustomViewReport<TaskProfileReportData> = {
    id: customViewReport.id,
    name: customViewReport.name,
    reportData: {
      sortBy: taskProfileReportDataParsed.sortBy,
      userIds: taskProfileReportDataParsed.userIds,
      costCenterIds: taskProfileReportDataParsed.costCenterIds,
      departmentIds: taskProfileReportDataParsed.departmentIds,
      jobIds: taskProfileReportDataParsed.jobIds ?? [],
      roleIds: taskProfileReportDataParsed.roleIds,
      supervisorIds: taskProfileReportDataParsed.supervisorIds,
      workLocationIds: taskProfileReportDataParsed.workLocationIds,
      primaryDataPoint,
      cardDisplayOptions: {
        ...taskProfileReportDataParsed.cardDisplayOptions,
        [primaryDataPointCardDisplayOption.id]: true,
      },
      detailDisplayModes: taskProfileReportDataParsed.detailDisplayModes,
      detailDisplayOptions: taskProfileReportDataParsed.detailDisplayOptions,
      messageOfTheDay: {
        ...defaultTaskProfileReportData.messageOfTheDay,
        ...(taskProfileReportDataParsed.messageOfTheDay ?? {}),
      },
      scrollingOptions: {
        ...defaultTaskProfileReportData.scrollingOptions,
        isScrolling: taskProfileReportDataParsed.scrollingOptions.isScrolling,
        scrollingIntervalMilliseconds:
          taskProfileReportDataParsed.scrollingOptions.scrollingIntervalMilliseconds,
      },
    },
  };

  return customViewReportResult;
}

export default {
  convert,
};
