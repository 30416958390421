import React, { lazy, Suspense } from 'react';
import { Route, Routes as RRRoutes, Navigate } from 'react-router-dom';
import Loading from './components/Loading/Loading';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import Routes from './constants/routes';
import ActivitiesReportContainer from './pages/ActivitiesReport/ActivitiesReportContainer';
import AuthorizationErrorPageContainer from './pages/AuthorizationErrorPage/AuthorizationErrorPageContainer';
import CreateUserIntegrationContainer from './pages/CreateUserIntegration/CreateUserIntegrationContainer';
import JobsEditContainer from './pages/EquivalenciesMapper/JobsEditContainer';
import IntegrationAnalyticsContainer from './pages/IntegrationAnalytics/IntegrationAnalyticsContainer';
import IntegrationHistoryContainer from './pages/IntegrationHistory/IntegrationHistoryContainer';
import LearningHistoryContainer from './pages/LearningHistory/LearningHistoryContainer';
import NotFoundContainer from './pages/NotFound/NotFoundContainer';
import TaskProfileReportContainer from './pages/TaskProfileReport/TaskProfileReportContainer';
import ViewsReport from './pages/ViewsReport/ViewsReportContainer';

const AuthorizePage = lazy(() => import('./pages/Authorize/AuthorizePage'));

const AppRouting: React.FC = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routing />
    </Suspense>
  );
};

const Routing: React.FC = () => {
  return (
    <RRRoutes>
      <Route path={Routes.authorize.path} element={<AuthorizePage />} />
      <Route path={Routes.errorPage.path} element={<AuthorizationErrorPageContainer />} />
      <Route path={Routes.homePageRoot.path} element={<div>Home Page</div>} />
      <Route
        path={Routes.taskProfileReport.path}
        element={
          <ProtectedRoute
            validate={policyService => policyService().canViewTaskProfile}
            callbackUrl={Routes.taskProfileReport.path}
          >
            <TaskProfileReportContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path={Routes.taskProfileReportCustomView.path}
        element={
          <ProtectedRoute
            validate={(policyService, params) => {
              const customViewId: number = Number(params.id);
              return policyService(customViewId).canAccessCustomViewReport;
            }}
            callbackUrl={Routes.taskProfileReportCustomView.path}
          >
            <TaskProfileReportContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path={Routes.viewsReport.path}
        element={
          <ProtectedRoute
            validate={policyService => policyService().canViewCustomView}
            callbackUrl={Routes.viewsReport.path}
          >
            <ViewsReport />
          </ProtectedRoute>
        }
      />
      <Route
        path={Routes.jobsCreate.path}
        element={
          <ProtectedRoute
            validate={policyService => policyService().canCreateJob}
            callbackUrl={Routes.jobsCreate.path}
          >
            <JobsEditContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path={Routes.jobsEdit.path}
        element={
          <ProtectedRoute
            validate={policyService => policyService().canUpdateJob}
            callbackUrl={Routes.jobsEdit.path}
          >
            <JobsEditContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path={Routes.activitiesReport.path}
        element={
          <ProtectedRoute
            validate={policyService => policyService().canViewActivities}
            callbackUrl={Routes.activitiesReport.path}
          >
            <ActivitiesReportContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path={Routes.integrationAnalytics.path}
        element={
          <ProtectedRoute
            validate={policyService => policyService().canViewIntegrationHub}
            callbackUrl={Routes.integrationAnalytics.path}
          >
            <IntegrationAnalyticsContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path={Routes.learningHistory.path}
        element={
          <ProtectedRoute
            validate={policyService => policyService().canViewLearningHistory}
            callbackUrl={Routes.learningHistory.path}
          >
            <LearningHistoryContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path={Routes.userIntegration.path}
        element={
          <ProtectedRoute
            validate={policyService => policyService().canViewIntegrationHub}
            callbackUrl={Routes.userIntegration.path}
          >
            <CreateUserIntegrationContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path={Routes.userIntegrationEdit.path}
        element={
          <ProtectedRoute
            validate={policyService => policyService().canViewIntegrationHub}
            callbackUrl={Routes.userIntegrationEdit.path}
          >
            <CreateUserIntegrationContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path={Routes.integrationHistory.path}
        element={
          <ProtectedRoute
            validate={policyService => policyService().canViewIntegrationHub}
            callbackUrl={Routes.integrationHistory.path}
          >
            <IntegrationHistoryContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path={Routes.notFound.path}
        element={
          <ProtectedRoute
            validate={policyService =>
              policyService().canViewIntegrationHub ||
              policyService().canViewLearningHistory ||
              policyService().canViewActivities ||
              policyService().canViewTaskProfile ||
              policyService().canViewCustomView ||
              policyService().canCreateJob
            }
            callbackUrl={Routes.notFound.path}
          >
            <NotFoundContainer />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<Navigate to={Routes.notFound.path} />} />
    </RRRoutes>
  );
};

export default AppRouting;
