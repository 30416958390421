import apiHooks from '../api/apiHooks';
import { UseDisplayItemsResponse } from '../models/useDisplayItemsResponse';
import displayItemConverters from '../pages/TaskProfileReport/hooks/displayItemConverters';

function useCompanySeriesTasks(
  companyId: number,
  seriesId: number,
  searchText: string,
  includeHistorical: boolean = true
): UseDisplayItemsResponse {
  const {
    data = [],
    isLoading,
    isSuccess,
  } = apiHooks.useCompanySeriesTasks(
    { companyId, seriesId, searchText, includeHistorical },
    {
      keepPreviousData: true,
      select: tasks => {
        let items = displayItemConverters.convertCompanySeriesTasksResponse(tasks);

        if (searchText !== '') {
          items = items.filter(s => s.display.toLowerCase().includes(searchText.toLowerCase()));
        }
        return items;
      },
    }
  );

  return { data, isLoading, isSuccess };
}

export default useCompanySeriesTasks;
