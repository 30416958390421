import React, { Key, useState } from 'react';
import testIds from '../../constants/testIds';
import Dropdown from './Dropdown';

export interface SeriesDropDownProps<TItem> {
  items: TItem[];
  isLoading: boolean;
  defaultLabel: string;
  emptyListLabel: string;
  onChange: (item: TItem) => boolean | void;
  keyExtractor: (item: TItem) => Key;
  labelExtractor: (item: TItem) => string;
  valueExtractor?: (item: TItem) => Key;
  itemSorter?: (leftItem: TItem, rightItem: TItem) => number;
  isDisabled?: boolean;
  variant?: string;
  selectedSeriesId: number;
}

const SeriesDropDown = <TItem,>({
  items,
  isLoading,
  defaultLabel,
  emptyListLabel,
  onChange,
  keyExtractor,
  labelExtractor,
  itemSorter = () => 0,
  isDisabled,
  selectedSeriesId,
  variant = 'primary',
}: SeriesDropDownProps<TItem>): JSX.Element => {
  const [dropDownLabel, setDropDownLabel] = useState<string>(defaultLabel);
  const handleChange = (item: TItem) =>
    // Reset dropdown display text if selected option's text cannot be displayed.
    setDropDownLabel(labelExtractor(item) || defaultLabel);
  return (
    <Dropdown
      emptyListLabel={emptyListLabel}
      items={items}
      itemSorter={itemSorter}
      itemKey={keyExtractor}
      itemLabel={labelExtractor}
      onItemSelect={item => {
        onChange(item);
        handleChange(item);
      }}
      isLoading={isLoading}
      title={dropDownLabel}
      variant={variant}
      disabled={isDisabled}
      dropdownTestId={testIds.seriesDropdown()}
      selectedItem={selectedSeriesId}
    />
  );
};

export default SeriesDropDown;
