import React from 'react';
import { CompanyUserJobTaskResponse } from '../../../../api/responses/companyUserJobTaskResponse';
import Accordion from '../../../Accordion/Accordion';
import TaskProfileDetailListJobItem from './TaskProfileDetailListJobItem';
import TaskProfileDetailListJobItemTasks from './TaskProfileDetailListJobItemTasks';

export interface TaskProfileDetailListJobProps {
  id: number;
  name: string;
  isComplete: boolean;
  tasks?: CompanyUserJobTaskResponse[];
}

const TaskProfileDetailListJob: React.FC<TaskProfileDetailListJobProps> = ({
  id,
  name,
  isComplete,
  tasks = [],
}) => {
  return (
    <Accordion
      toggle={<TaskProfileDetailListJobItem id={id} name={name} isComplete={isComplete} />}
      toggleColor="transparent"
      buttonClassName="py-1 mt-3 pe-3"
    >
      <TaskProfileDetailListJobItemTasks tasks={tasks} />
    </Accordion>
  );
};

export default TaskProfileDetailListJob;
