import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { ApiResult } from '../../../api/apiHooks';
import { UserStatisticsResponse } from '../../../api/responses/userStatisticsResponse';
import commonText from '../../../constants/commonText';
import testIds from '../../../constants/testIds';
import eventUtils from '../../../utils/eventUtils';
import statisticParser from '../../../utils/parsers/statisticParser';
import chartColors from '../../Charts/DoughnutCharts/ProgessDoughnutChart/chartColors';
import ProgressDoughnutChart from '../../Charts/DoughnutCharts/ProgessDoughnutChart/ProgressDoughnutChart';
import OverflowTooltip from '../../OverflowTooltip/OverflowTooltip';
import TaskProfileCardNote from '../CardNote/TaskProfileCardNote';
import TaskProfileCardStat from '../CardStat/TaskProfileCardStat';
import TaskProfileCardUsername from '../CardUsername/TaskProfileCardUsername';
import { TaskProfileModalProps } from '../Modal/TaskProfileModalProps';
import { TaskProfileCardFeatures } from '../ReportDrawer/taskProfileCardDisplayOptions';
import {
  TaskProfileCardPrimaryDataPoint,
  TaskProfileCardPrimaryDataPoints,
} from '../ReportDrawer/taskProfileCardPrimaryDataPoints';
import { JobStatsResponse } from '../../../api/responses/jobStatsResponse';
import JobAssocMethod from '../../../models/JobAssocMethod';

const progressChartContainerStyles: React.CSSProperties = {
  height: '6.5rem',
  width: '6.5rem',
};

interface PrimaryDataPointValues {
  title: string;
  totalCompleted?: number;
  totalCount?: number;
  isEnabled: boolean;
}

export interface TaskProfileCardProps {
  userId: number;
  fullName: string;
  userName: string;
  note: string;
  features: TaskProfileCardFeatures;
  primaryDataPoint: TaskProfileCardPrimaryDataPoints;
  onClick: (modalProps: TaskProfileModalProps) => void;
  onGetUserStatistics: (userId: number) => ApiResult<UserStatisticsResponse>;
  onGetJobStatistics: (userId: number) => ApiResult<JobStatsResponse>;
  jobAssocMethod: JobAssocMethod;
}

export const TaskProfileCard: React.FC<TaskProfileCardProps> = ({
  userId,
  fullName,
  userName,
  features,
  note,
  primaryDataPoint,
  onClick,
  onGetUserStatistics,
  onGetJobStatistics,
  jobAssocMethod,
}) => {
  const { data: userData, isFetching: isFetchingUser } = onGetUserStatistics(userId);
  const { data: jobData, isFetching: isFetchingJob } = onGetJobStatistics(userId);
  const jobDataValues: PrimaryDataPointValues = {
    title: commonText.jobs,
    totalCompleted:
      jobAssocMethod === JobAssocMethod.Jobs
        ? userData?.totalJobsCompletedCount
        : jobData?.totalJobsCompletedCount,
    totalCount:
      jobAssocMethod === JobAssocMethod.Jobs ? userData?.totalJobsCount : jobData?.totalJobsCount,
    isEnabled: features.jobs,
  };

  const primaryDataPointValueMap: Record<TaskProfileCardPrimaryDataPoints, PrimaryDataPointValues> =
    {
      [TaskProfileCardPrimaryDataPoint.roles]: {
        title: commonText.roles,
        totalCompleted: userData?.totalRolesCompletedCount,
        totalCount: userData?.totalRolesCount,
        isEnabled: features.roles,
      },
      [TaskProfileCardPrimaryDataPoint.tasks]: {
        title: commonText.tasks,
        totalCompleted: userData?.totalTasksCompletedCount,
        totalCount: userData?.totalTasksCount,
        isEnabled: features.tasks,
      },
      [TaskProfileCardPrimaryDataPoint.jobs]: jobDataValues,
    };
  const primaryDataPointValues = primaryDataPointValueMap[primaryDataPoint];

  const isLabelVisible =
    (features.jobs && primaryDataPoint !== TaskProfileCardPrimaryDataPoint.jobs) ||
    (features.tasks && primaryDataPoint !== TaskProfileCardPrimaryDataPoint.tasks) ||
    (features.roles && primaryDataPoint !== TaskProfileCardPrimaryDataPoint.roles) ||
    features.wePe ||
    features.training;

  return (
    <Card
      className="h-100"
      onClick={() => onClick({ userId, fullName, userName })}
      data-testid={testIds.taskProfileCard(userId)}
    >
      <Card.Body className="pb-0 px-3">
        <Row>
          <Col
            xs="auto"
            className="d-inline-flex align-items-baseline justify-content-between overflow-hidden w-100"
          >
            <OverflowTooltip<HTMLHeadingElement> tooltipText={fullName}>
              {({ ref, style }) => (
                <h4
                  className="card-title mb-1 mw-100 flex-fill"
                  ref={ref}
                  style={style}
                  role="presentation"
                  onClick={eventUtils.stopPropagation}
                >
                  {fullName}
                </h4>
              )}
            </OverflowTooltip>
            {features.note && <TaskProfileCardNote note={note} isLoading={isFetchingUser} />}
          </Col>
        </Row>
        <Row>
          <Col>
            <Card.Subtitle className="text-muted">
              <TaskProfileCardUsername
                userId={userId}
                userName={userName}
                isUserIdEnabled={features.userId}
                isUsernameEnabled={features.username}
              />
            </Card.Subtitle>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="pt-0 px-3">
        <Row className="justify-content-between align-items-end">
          <Col xs="auto" className="flex-grow-1">
            {isLabelVisible && (
              <div className="fw-bold">
                {features.viewAsIncomplete ? commonText.incomplete : commonText.progress}
              </div>
            )}
            {features.jobs && primaryDataPoint !== TaskProfileCardPrimaryDataPoint.jobs && (
              <TaskProfileCardStat
                className="mb-0"
                isLoading={
                  isFetchingUser || (jobAssocMethod !== JobAssocMethod.Jobs && isFetchingJob)
                }
                title={commonText.jobs}
                total={jobDataValues.totalCount ?? 0}
                totalCompleted={jobDataValues.totalCompleted ?? 0}
                isIncomplete={features.viewAsIncomplete}
                statisticTextParser={statisticParser.parseJobStatistics}
              />
            )}
            {features.roles && primaryDataPoint !== TaskProfileCardPrimaryDataPoint.roles && (
              <TaskProfileCardStat
                className="mb-0"
                isLoading={isFetchingUser}
                title={commonText.roles}
                total={userData?.totalRolesCount ?? 0}
                totalCompleted={userData?.totalRolesCompletedCount ?? 0}
                isIncomplete={features.viewAsIncomplete}
              />
            )}
            {features.tasks && primaryDataPoint !== TaskProfileCardPrimaryDataPoint.tasks && (
              <TaskProfileCardStat
                className="mb-0"
                isLoading={isFetchingUser}
                title={commonText.tasks}
                total={userData?.totalTasksCount ?? 0}
                totalCompleted={userData?.totalTasksCompletedCount ?? 0}
                isIncomplete={features.viewAsIncomplete}
              />
            )}
            {features.wePe && (
              <TaskProfileCardStat
                className="mb-0"
                isLoading={isFetchingUser}
                title={commonText.we}
                total={userData?.totalWeCount ?? 0}
                totalCompleted={userData?.totalWeCompletedCount ?? 0}
                isIncomplete={features.viewAsIncomplete}
              />
            )}
            {features.wePe && (
              <TaskProfileCardStat
                className="mb-0"
                isLoading={isFetchingUser}
                title={commonText.pe}
                total={userData?.totalPeCount ?? 0}
                totalCompleted={userData?.totalPeCompletedCount ?? 0}
                isIncomplete={features.viewAsIncomplete}
              />
            )}
            {features.training && (
              <TaskProfileCardStat
                className="mb-0"
                isLoading={isFetchingUser}
                title={commonText.training}
                total={userData?.totalTrainingsCount ?? 0}
                totalCompleted={userData?.totalTrainingsCompletedCount ?? 0}
                isIncomplete={features.viewAsIncomplete}
              />
            )}
          </Col>
          {primaryDataPointValues?.isEnabled && (
            <Col className="ms-auto" xs="auto">
              <div
                style={progressChartContainerStyles}
                data-testid={testIds.taskProfileCardProgressChart(userId)}
              >
                <ProgressDoughnutChart
                  className="h-100 w-100"
                  complete={primaryDataPointValues.totalCompleted ?? 0}
                  total={primaryDataPointValues.totalCount ?? 0}
                  centerText={primaryDataPointValues.title}
                  chartColors={chartColors.progressChart}
                  isLoading={
                    isFetchingUser ||
                    (features.jobs &&
                      primaryDataPoint === TaskProfileCardPrimaryDataPoint.jobs &&
                      jobAssocMethod !== JobAssocMethod.Jobs &&
                      isFetchingJob)
                  }
                />
              </div>
            </Col>
          )}
        </Row>
      </Card.Footer>
    </Card>
  );
};
