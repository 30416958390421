import { useVirtualizer } from '@tanstack/react-virtual';
import React, { useEffect, useRef } from 'react';
import RBTable from 'react-bootstrap/Table';
import testIds from '../../constants/testIds';
import { TableColumnModel } from '../../models/TableColumnModel';
import { TableSortInformation } from '../../models/TableSortInformation';
import TableSkeleton from '../TableSkeleton/TableSkeleton';
import Body from './Body/Body';
import Header from './Header/Header';

export interface TableProps<T, U> {
  columns: TableColumnModel[];
  data: T[] | undefined;
  height: number;
  numberOfItems: number;
  itemHeight: number;
  visibleRows: number;
  isLoading: boolean;
  sortInformation: TableSortInformation[];
  requestInformation: U;
  pageSize: number;
  requestError: boolean;
  variableHeight?: boolean;
  rowTemplate: (data: T, index: number, isLoading: boolean) => React.ReactElement | null;
  onSortChange: (request: TableSortInformation[]) => void;
  onPageChange: () => void;
  hasNextPage: boolean;
  estimateSize: (index: number) => number;
  emptyStateMessage: string;
  tableContainerClassname?: string;
}

const Table = <T, U>({
  columns,
  data,
  height,
  numberOfItems,
  itemHeight,
  visibleRows,
  isLoading,
  sortInformation,
  requestError,
  rowTemplate,
  onSortChange,
  onPageChange,
  hasNextPage,
  estimateSize,
  emptyStateMessage,
  tableContainerClassname = '',
}: TableProps<T, U>): JSX.Element => {
  // The scrollable element for your list
  const parentRef = useRef<HTMLDivElement>(null);

  // The virtualizer
  const rowVirtualizer = useVirtualizer({
    count: numberOfItems,
    getScrollElement: () => parentRef.current || null,
    measureElement: element => {
      return element.clientHeight;
    },
    estimateSize,
    overscan: 5,
  });

  const virtualItems = rowVirtualizer.getVirtualItems();
  useEffect(() => {
    if (!parentRef.current) return;

    const { offsetHeight, scrollHeight, scrollTop } = parentRef.current;
    const percentage = scrollTop / (scrollHeight - offsetHeight);
    if (percentage > 0.9 && hasNextPage && !isLoading) {
      onPageChange();
    }
  }, [hasNextPage, onPageChange, isLoading, parentRef, rowVirtualizer, virtualItems]);

  return (
    <div
      ref={parentRef}
      className={`w-100 mh-100 overflow-auto d-flex flex-column ${tableContainerClassname}`}
      data-testid={testIds.tableContainer()}
    >
      <Header
        columns={columns}
        itemHeight={itemHeight}
        sortInformation={sortInformation}
        onSortChange={onSortChange}
      />
      {data !== undefined ? (
        <Body<T>
          columns={columns}
          data={data}
          height={height}
          visibleRows={visibleRows}
          rowTemplate={rowTemplate}
          isLoading={isLoading}
          rowVirtualizer={rowVirtualizer}
          emptyStateMessage={emptyStateMessage}
        />
      ) : (
        <RBTable>
          <tbody data-testid={testIds.tableSkeletonBody()}>
            {!requestError && isLoading && <TableSkeleton columns={columns} rows={visibleRows} />}
          </tbody>
        </RBTable>
      )}
    </div>
  );
};

export default Table;
