import { useEffect, useRef } from 'react';

function useEventListener<
  TDocumentEventKey extends keyof DocumentEventMap,
  THtmlElementEventKey extends keyof HTMLElementEventMap,
  TWindowEventEventKey extends keyof WindowEventMap
>(
  element: Document | EventTarget | HTMLElement | Window | null | undefined,
  eventType: TDocumentEventKey | THtmlElementEventKey | TWindowEventEventKey | string,
  listener: (
    this: typeof element,
    evt:
      | DocumentEventMap[TDocumentEventKey]
      | HTMLElementEventMap[THtmlElementEventKey]
      | WindowEventMap[TWindowEventEventKey]
      | Event
  ) => void
): void {
  const listenerRef = useRef(listener);

  useEffect(() => {
    listenerRef.current = listener;
  }, [listener]);

  useEffect(() => {
    if (!element) {
      return undefined;
    }

    const eventListener: typeof listenerRef.current = evt => listenerRef.current.call(element, evt);

    element.addEventListener(eventType, eventListener);

    return () => {
      element.removeEventListener(eventType, eventListener);
    };
  }, [eventType, element]);
}

export default useEventListener;
