import React from 'react';
import { TableColumnModel } from '../../models/TableColumnModel';
import Skeleton from '../Skeleton/Skeleton';

export interface TableSkeletonProps {
  columns: TableColumnModel[];
  rows: number;
}

const TableSkeleton: React.FC<TableSkeletonProps> = ({ columns, rows }) => {
  return (
    <>
      {Array.from(Array(rows).keys()).map(row => (
        <tr key={row}>
          {columns.map(column => (
            <td key={column.sortKey} style={{ width: `${column.width}%` }}>
              <Skeleton className="table-skeleton" width="100%" />
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

export default TableSkeleton;
