import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { OneMinuteInMilliseconds } from '../constants/measurements';
import authService from './authService';
import { AuthSession } from '../models/authSession';

interface UseRefreshTokenParams {
  refetchInterval: number;
  refetchIntervalInBackground: boolean;
}

const defaultValues: UseRefreshTokenParams = {
  refetchInterval: OneMinuteInMilliseconds,
  refetchIntervalInBackground: true,
};

const useRefreshToken = ({
  refetchInterval,
  refetchIntervalInBackground,
}: UseRefreshTokenParams = defaultValues): UseQueryResult<AuthSession, Error> => {
  return useQuery(['refreshToken'], () => authService.getAuthSession(), {
    refetchInterval,
    refetchIntervalInBackground,
  });
};

export default useRefreshToken;
