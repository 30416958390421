import { PostCompanyImportSettingsRequestBody } from '../../api/requests/PostCompanyImportSettingsRequest';

const requiredPropertyKeys = [
  { key: 'IntegrationName', name: 'Title' },
  { key: 'HasHeaderRow', name: 'HasHeaderRow' },
  { key: 'CreateDependentData', name: 'CreateDependentData' },
  { key: 'UniqueUsernames', name: 'UniqueUsernames' },
  { key: 'IsPGPEncrypted', name: 'IsPGPEncrypted' },
  { key: 'IsEnabled', name: 'IsEnabled' },
  { key: 'UserNameColumnIndex', name: 'Username' },
  { key: 'FirstNameColumnIndex', name: 'First Name' },
  { key: 'LastNameColumnIndex', name: 'Last Name' },
  { key: 'UserStatusNameColumnIndex', name: 'User Status' },
  { key: 'ActiveStatus', name: 'ActiveStatus' },
  { key: 'PrimaryUserLookupField', name: 'PrimaryUserLookupField' },
  { key: 'UseSecondaryUserLookupField', name: 'UseSecondaryUserLookupField' },
  { key: 'EmailColumnIndex', name: 'Email' },
  { key: 'MakeTaskProfilingAssignments', name: 'MakeTaskProfilingAssignments' },
  { key: 'HasMultipleCompanies', name: 'HasMultipleCompanies' },
  { key: 'SeriesIds', name: 'Series Access' },
  { key: 'DefaultRoleId', name: 'Default Role' },
] as const;

function validate(requestBody: PostCompanyImportSettingsRequestBody): {
  isValid: boolean;
  invalidPropertyNames: string[];
} {
  let isValid = true;
  const invalidPropertyNames: string[] = [];

  requiredPropertyKeys.forEach(property => {
    if (requestBody[property.key] === null) {
      isValid = false;
      invalidPropertyNames.push(property.name);
    }
  });

  if (requestBody.IntegrationName.match(/^ *$/) !== null) {
    isValid = false;
    invalidPropertyNames.push('Title');
  }

  if (requestBody.SeriesIds === '' || requestBody.SeriesIds === undefined) {
    isValid = false;
    invalidPropertyNames.push('Series Access');
  }

  if (requestBody.DefaultRoleId === undefined || requestBody.DefaultRoleId === 0) {
    isValid = false;
    invalidPropertyNames.push('Default Role');
  }
  return { isValid, invalidPropertyNames };
}

export default { validate };
