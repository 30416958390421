import React from 'react';

export interface CountProps {
  count: number;
  testId?: string;
  className?: string;
}

const Count: React.FC<CountProps> = ({ count, testId, className }) => {
  return count > 0 ? <span className={className} data-testid={testId}>{`(${count})`}</span> : null;
};

export default Count;
