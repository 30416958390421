import React from 'react';

export interface CardGridCollectionProps<T extends unknown> {
  /**
   * The number of cards there should be
   */
  numOfCards: number;

  /**
   * The data to be rendered in the grid
   */
  data: T[];

  /**
   * Render prop for rendering the card in the grid
   */
  render(data: T): React.ReactNode;
}

function CardGridCollection<T>({
  numOfCards,
  data,
  render,
}: CardGridCollectionProps<T>): JSX.Element {
  return (
    <>
      {Array.from(Array(numOfCards), (_, index) => {
        return data?.[index] !== undefined ? (
          <React.Fragment key={index}>{render(data?.[index])}</React.Fragment>
        ) : (
          <div key={`empty-card-${index}`} />
        );
      })}
    </>
  );
}

export default CardGridCollection;
