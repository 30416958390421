import React, { useCallback, useMemo } from 'react';
import useInitFeatureFlagUser from '../feature-flags/useInitFeatureFlagUser';
import AuthContext from './AuthContext';
import authService from './authService';

interface AuthProviderProps {
  children?: React.ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const initFeatureFlagUser = useInitFeatureFlagUser();

  const signInWithToken = useCallback(
    async (userId: number, refreshToken: string) => {
      const authSession = await authService.refreshSession(userId, refreshToken);
      await initFeatureFlagUser(authSession);
      return authSession;
    },
    [initFeatureFlagUser]
  );

  const isUserAuthenticated = useCallback(async () => {
    const authSession = await authService.isUserAuthenticated();
    await initFeatureFlagUser(authSession);
    return authSession;
  }, [initFeatureFlagUser]);

  const value = useMemo(
    () => ({
      signInWithToken,
      isUserAuthenticated,
    }),
    [signInWithToken, isUserAuthenticated]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
