import { CustomViewAccessResponse } from '../api/responses/customViewAccessResponse';
import CustomViewAccessType from '../models/customViewAccessType';
import { UsersCustomViewAccessPolicy } from '../models/usersCustomViewAccessPolicy';

export default class UsersCustomViewAccessPolicies {
  private policies: UsersCustomViewAccessPolicy[];

  private readonly customViewId?: number;

  constructor(customViewAccessResponses: CustomViewAccessResponse[], customViewId?: number) {
    this.policies = customViewAccessResponses.map(response => ({
      customViewReportId: response.customViewId,
      customViewAccessType: response.customViewAccessType,
    }));

    this.customViewId = customViewId;
  }

  public HasEditorAccessToCustomView(): boolean {
    if (this.customViewId === undefined) {
      return false;
    }

    return this.policies.some(
      value =>
        value.customViewReportId === this.customViewId &&
        value.customViewAccessType === CustomViewAccessType.Editor
    );
  }

  public HasViewOnlyAccessToCustomView(): boolean {
    if (this.customViewId === undefined) {
      return false;
    }

    return this.policies.some(
      value =>
        value.customViewReportId === this.customViewId &&
        value.customViewAccessType === CustomViewAccessType.ViewOnly
    );
  }

  public HasAccess(): boolean {
    if (this.customViewId === undefined) {
      return false;
    }

    return this.policies.some(value => value.customViewReportId === this.customViewId);
  }

  public HasOwnerAccess(): boolean {
    if (this.customViewId === undefined) {
      return false;
    }

    return this.policies.some(
      value =>
        value.customViewReportId === this.customViewId &&
        value.customViewAccessType === CustomViewAccessType.Owner
    );
  }
}
