import { useEffect, useState } from 'react';
import createActivityDetector, {
  ActivityDetectorOptions,
  ActivityState,
} from '../utils/activityDetector';

const useIdle = (options: ActivityDetectorOptions = {}): boolean => {
  const [isIdle, setIsIdle] = useState(false);

  useEffect(() => {
    const activityDetector = createActivityDetector(options);

    activityDetector.on(ActivityState.idle, () => setIsIdle(true));
    activityDetector.on(ActivityState.active, () => setIsIdle(false));

    return () => activityDetector.destroy();
  }, [options]);

  return isIdle;
};

export default useIdle;
