import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { MdCheck, MdClear } from 'react-icons/md';
import testIds from '../../constants/testIds';

export interface ConfirmButtonProps {
  text: string;
  confirmText: string;
  disabled?: boolean;
  onConfirm: () => void;
}

const ConfirmButton: React.FC<ConfirmButtonProps> = ({
  text,
  confirmText,
  disabled = false,
  onConfirm,
}) => {
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);

  const handleConfirmDelete = () => {
    setIsConfirmDelete(false);
    onConfirm();
  };

  return (
    <div className="d-inline-flex justify-content-start align-items-center">
      {!isConfirmDelete && (
        <Button
          className="text-danger"
          variant="transparent"
          disabled={disabled}
          onClick={() => setIsConfirmDelete(true)}
          data-testid={testIds.confirmButton()}
        >
          {text}
        </Button>
      )}
      {isConfirmDelete && (
        <>
          <Button
            className="btn-icon rounded-circle ms-2"
            variant="outline-primary"
            size="sm"
            aria-label="Cancel"
            disabled={disabled}
            onClick={() => setIsConfirmDelete(false)}
          >
            <MdClear color="primary" />
          </Button>
          <Button
            className="btn-icon rounded-circle ms-2"
            variant="outline-danger"
            size="sm"
            aria-label="Confirm"
            disabled={disabled}
            onClick={handleConfirmDelete}
          >
            <MdCheck color="danger" />
          </Button>
          <p className="mb-0 ms-2" style={{ fontSize: '0.875rem' }}>
            {confirmText}
          </p>
        </>
      )}
    </div>
  );
};

export default ConfirmButton;
