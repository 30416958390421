import { Virtualizer } from '@tanstack/react-virtual';
import React from 'react';
import testIds from '../../../constants/testIds';
import { TableColumnModel } from '../../../models/TableColumnModel';

export interface BodyProps<T> {
  columns: TableColumnModel[];
  data: T[];
  height: number;
  isLoading: boolean;
  visibleRows: number;
  rowTemplate: (item: T, index: number, isLoading: boolean) => React.ReactElement | null;
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
  emptyStateMessage: string;
}

const Body = <T,>({
  data,
  isLoading,
  rowTemplate,
  rowVirtualizer,
  emptyStateMessage,
}: BodyProps<T>): JSX.Element => {
  return (
    <div className="w-100 flex-grow-1">
      {data.length > 0 ? (
        <div
          className="w-100 border position-relative"
          style={{ height: `${rowVirtualizer.getTotalSize()}px` }}
          data-testid={testIds.tableBody()}
        >
          {/* Only the visible items in the virtualizer, manually positioned to be in view */}
          {rowVirtualizer.getVirtualItems().map(virtualItem => (
            <div
              key={virtualItem.key}
              data-index={virtualItem.index}
              ref={rowVirtualizer.measureElement}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${virtualItem.start}px)`,
              }}
            >
              {rowTemplate(data[virtualItem.index], virtualItem.index, isLoading)}
            </div>
          ))}
        </div>
      ) : (
        <div className="h-100 d-flex align-items-center justify-content-center text-secondary h2">
          {emptyStateMessage}
        </div>
      )}
    </div>
  );
};

export default Body;
