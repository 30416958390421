import { CustomViewConnection } from '../../api/responses/customViewConnection';
import { CustomViewConnectionsResponse } from '../../api/responses/customViewConnectionsResponse';

function convert(response: CustomViewConnectionsResponse): Record<number, CustomViewConnection> {
  return response.customViewConnections.reduce(
    (acc, current) => ({ ...acc, [current.customViewId]: current }),
    {}
  );
}

export default {
  convert,
};
