const OdataQueryParams = {
  sortBy: '$orderby',
  skip: '$skip',
  top: '$top',
  searchText: '$search',
  filter: '$filter',
  count: '$count',
} as const;

export default OdataQueryParams;
