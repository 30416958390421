import React from 'react';
import useSignInExistingUser from './auth/useSignInExistingUser';
import Loading from './components/Loading/Loading';

interface AppSetupProviderProps {
  children?: React.ReactNode;
}

const AppSetupProvider: React.FC<AppSetupProviderProps> = ({ children }) => {
  const isLoading = useSignInExistingUser();

  if (isLoading) {
    return <Loading />;
  }

  return <>{children}</>;
};

export default AppSetupProvider;
