import { datadogRum } from '@datadog/browser-rum';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import { UserInformation } from '../../../api/responses/userInformation';
import testIds from '../../../constants/testIds';
import UserNavBar from '../UserNavBar/UserNavBar';

export interface TopNavBarProps {
  reportName: string;
  isSidebarButtonActive?: boolean;
  children?: React.ReactNode;
  user: UserInformation | undefined;
  isUserLoading: boolean;
}

const TopNavbar: React.FC<TopNavBarProps> = ({
  reportName,
  isSidebarButtonActive = true,
  children,
  isUserLoading,
  user,
}) => {
  datadogRum.setUser({
    name: user?.FullName || '',
    id: user?.userId?.toString() || '',
  });

  datadogRum.setUserProperty('type', 'user');

  const handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  return (
    <Navbar
      className="navbar navbar-dark bg-primary navbar-expand-lg flex-shrink-0 justify-content-between px-1"
      data-testid={testIds.topNavBar()}
    >
      <div className="d-inline-flex align-items-center">
        {isSidebarButtonActive ? (
          <div className="navbar-minimize me-2">
            <Button
              className="btn-icon btn-round text text-white bg-transparent"
              variant="transparent"
              onClick={handleMiniClick}
            >
              &#9776;
            </Button>
          </div>
        ) : null}
        <Navbar.Brand>
          <span className="d-block">{`${reportName}`}</span>
        </Navbar.Brand>
      </div>
      <div className="d-flex flex-nowrap">
        {children}
        <UserNavBar
          isLoading={isUserLoading}
          image={user?.photo || ''}
          name={user?.FullName || ''}
        />
      </div>
    </Navbar>
  );
};
export default TopNavbar;
