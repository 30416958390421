import apiHooks from '../api/apiHooks';
import { CompanyResponse } from '../api/responses/companyResponse';
import { OneHourInMilliseconds } from '../constants/measurements';
import useCompanyId from './useCompanyId';

export interface CompanyHook {
  company?: CompanyResponse;
  isLoading: boolean;
}

export default function useCompany(): CompanyHook {
  const { companyId } = useCompanyId();
  const { data, isLoading } = apiHooks.useCompanyDetails(companyId, {
    cacheTime: OneHourInMilliseconds,
    staleTime: OneHourInMilliseconds,
  });

  return {
    company: data,
    isLoading,
  };
}
