import { UseQueryResult, useQuery } from '@tanstack/react-query';
import reportingApi from './apiReporting';
import ReportRequestFilter from './requests/ReportRequestFilter';
import { CompaniesUsersJobsResponse } from './responses/companiesUsersJobsResponse';
import { JobStatsResponse } from './responses/jobStatsResponse';

export interface ApiResult<TData> {
  data?: TData;
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
}

export interface ApiRequestOptions<TResponseData = unknown, TData = TResponseData> {
  enabled?: boolean;
  keepPreviousData?: boolean;
  cacheTime?: number;
  staleTime?: number;
  refetchInterval?: number | false;
  refetchOnWindowFocus?: boolean;
  select?: (data: TResponseData) => TData;
}

export interface ApiSaveResult<TRequestData, TResponseData> {
  mutate: (data: TRequestData) => void;
  mutateAsync: (data: TRequestData) => Promise<TResponseData>;
  data?: TResponseData;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
}

export interface ApiSaveRequestOptions<TData = unknown, TVariables = unknown, TContext = unknown> {
  onSuccess?: (
    data: TData,
    variables: TVariables,
    context: TContext | undefined
  ) => Promise<unknown> | void;
}

function useReportRequest(
  requestParams: ReportRequestFilter,
  options?: ApiRequestOptions<CompaniesUsersJobsResponse, CompaniesUsersJobsResponse>
): UseQueryResult<CompaniesUsersJobsResponse> {
  return useQuery(
    [
      'createReportRequest',
      requestParams.companyIds,
      requestParams.userIds,
      requestParams.jobMatrixIds,
    ],
    () => reportingApi.createReportRequest(requestParams),
    {
      ...options,
    }
  );
}

function useJobStatsRequest(
  requestParams: ReportRequestFilter,
  options?: ApiRequestOptions<JobStatsResponse, JobStatsResponse>
): UseQueryResult<JobStatsResponse> {
  return useQuery(
    ['getJobStats', requestParams.companyIds, requestParams.userIds, requestParams.jobMatrixIds],
    () => reportingApi.getJobStats(requestParams),
    {
      ...options,
    }
  );
}

export default {
  useReportRequest,
  useJobStatsRequest,
};
