import React from 'react';
import Button from 'react-bootstrap/Button';
import { CompanyResponse } from '../../api/responses/companyResponse';
import { UserInformation } from '../../api/responses/userInformation';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import TopNavbar from '../../components/Layout/TopNavBar/TopNavBar';
import commonText from '../../constants/commonText';
import { AvailablePolicies } from '../../policyService/policyEnforcer/policyEnforcer';

export interface AuthorizationErrorPageProps {
  isCompanyLoading: boolean;
  company?: CompanyResponse;
  policies: Pick<
    AvailablePolicies,
    | 'canViewTaskProfile'
    | 'canViewCustomView'
    | 'canViewActivities'
    | 'canViewIntegrationHub'
    | 'canViewLearningHistory'
  >;
  error: Error | null;
  callbackUrl: string | null;
  user: UserInformation | undefined;
  isUserLoading: boolean;
  pushHistory: (callbackUrl: string) => void;
}

const AuthorizationErrorPage: React.FC<AuthorizationErrorPageProps> = ({
  isCompanyLoading,
  company,
  policies,
  error,
  callbackUrl,
  isUserLoading,
  user,
  pushHistory,
}) => {
  return (
    <MainLayout
      isCompanyLoading={isCompanyLoading}
      companyImage={company?.logo}
      companyName={company?.name}
      policies={policies}
      isSidebarVisible
    >
      <div className="d-flex flex-column justify-content-center">
        <TopNavbar reportName={commonText.error} user={user} isUserLoading={isUserLoading} />
        <h3 className="mx-auto mt-5">Sorry, You have been disconnected</h3>
        {error ? (
          <Button
            className="text-center mx-auto"
            variant="primary"
            style={{ width: '10em' }}
            onClick={() => window.location.assign(process.env.REACT_APP_ONBOARD_BASE_URL as string)}
          >
            Retry
          </Button>
        ) : (
          <>
            {callbackUrl !== null ? (
              <Button
                className="text-center mx-auto"
                variant="primary"
                style={{ width: '10em' }}
                onClick={() => pushHistory(callbackUrl)}
              >
                Retry
              </Button>
            ) : (
              <h3 className="mx-auto">Select an option for the menu</h3>
            )}
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default AuthorizationErrorPage;
