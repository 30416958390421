import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import ListGroup from 'react-bootstrap/ListGroup';
import Tab from 'react-bootstrap/Tab';
import { TaskProfileSortByOption } from '../../../api/requests/companyQualificationRequest';
import DrawerFilterTab from '../../../models/DrawerFilterTab';
import FilterTabNameId from '../../../models/FilterTabNameId';
import FilterTabNames from '../../../models/FilterTabNames';
import Count from '../../Count/Count';
import DrawerMenuGroup from '../../Layout/Drawer/DrawerMenuGroup';
import TabPanel from '../../Layout/TabPanel/TabPanel';

interface TaskProfileReportDrawerTabViewProps {
  employeesContent: React.ReactElement;
  costCentersContent: React.ReactElement;
  departmentsContent: React.ReactElement;
  jobsContent: React.ReactElement;
  rolesContent: React.ReactElement;
  supervisorsContent: React.ReactElement;
  workLocationsContent: React.ReactElement;
  sortByOptions: TaskProfileSortByOption[];
  activeSortByOption: TaskProfileSortByOption;
  onSortByOptionChange: (sortByOption: TaskProfileSortByOption) => void;
  selectedEmployeesCount: number;
  selectedCostCentersCount: number;
  selectedDepartmentsCount: number;
  selectedJobsCount: number;
  selectedRolesCount: number;
  selectedSupervisorsCount: number;
  selectedWorkLocationsCount: number;
  onActiveTabChanged: (selectedTabId: FilterTabNameId) => void;
  activeTabId: FilterTabNameId;
}

const TaskProfileReportDrawerTabView: React.FC<TaskProfileReportDrawerTabViewProps> = ({
  employeesContent,
  costCentersContent,
  departmentsContent,
  jobsContent,
  rolesContent,
  supervisorsContent,
  workLocationsContent,
  sortByOptions,
  activeSortByOption,
  onSortByOptionChange,
  selectedEmployeesCount,
  selectedCostCentersCount,
  selectedDepartmentsCount,
  selectedJobsCount,
  selectedRolesCount,
  selectedSupervisorsCount,
  selectedWorkLocationsCount,
  onActiveTabChanged,
  activeTabId,
}) => {
  function handleOnSortByChanged(displayOption: TaskProfileSortByOption) {
    onSortByOptionChange(displayOption);
  }

  function handleOnTabChange(selectedTabId: FilterTabNameId) {
    onActiveTabChanged(selectedTabId);
  }

  const tabs: DrawerFilterTab[] = [
    {
      id: FilterTabNames.employees.id,
      label: FilterTabNames.employees.name,
      count: <Count className="ms-1" count={selectedEmployeesCount} />,
    },
    {
      id: FilterTabNames.costCenters.id,
      label: FilterTabNames.costCenters.name,
      count: <Count className="ms-1" count={selectedCostCentersCount} />,
    },
    {
      id: FilterTabNames.departments.id,
      label: FilterTabNames.departments.name,
      count: <Count className="ms-1" count={selectedDepartmentsCount} />,
    },
    {
      id: FilterTabNames.jobs.id,
      label: FilterTabNames.jobs.name,
      count: <Count className="ms-1" count={selectedJobsCount} />,
    },
    {
      id: FilterTabNames.roles.id,
      label: FilterTabNames.roles.name,
      count: <Count className="ms-1" count={selectedRolesCount} />,
    },
    {
      id: FilterTabNames.supervisors.id,
      label: FilterTabNames.supervisors.name,
      count: <Count className="ms-1" count={selectedSupervisorsCount} />,
    },
    {
      id: FilterTabNames.workLocations.id,
      label: FilterTabNames.workLocations.name,
      count: <Count className="ms-1" count={selectedWorkLocationsCount} />,
    },
  ];

  return (
    <>
      <DrawerMenuGroup>
        <div className="d-inline-flex align-items-center">
          <p className="fw-bold mb-2 me-3">Sort</p>
          <Dropdown className="mb-2">
            <Dropdown.Toggle variant="transparent" id="task-profile-report-sort-button">
              <span className="me-3">{activeSortByOption.name}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="text-center" style={{ minWidth: '100%' }} role="menu">
              {sortByOptions.map(sortByOption => (
                <Dropdown.Item
                  key={sortByOption.id}
                  className="btn-sm border-bottom-0 rounded-0"
                  as="button"
                  active={sortByOption.id === activeSortByOption.id}
                  onClick={() => handleOnSortByChanged(sortByOption)}
                >
                  {sortByOption.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </DrawerMenuGroup>
      <DrawerMenuGroup label="Filters">
        <ListGroup className="w-100 list-group-horizontal flex-wrap">
          {tabs.map(({ id, label, count }: DrawerFilterTab) => {
            return (
              <ListGroup.Item
                key={id}
                role="tab"
                as="button"
                className="btn-sm border-0 rounded"
                variant="transparent"
                id={`task-profile-drawer-${id}`}
                onClick={() => handleOnTabChange(id)}
                active={activeTabId === id}
              >
                {label}
                {count}
              </ListGroup.Item>
            );
          })}
        </ListGroup>
        <Tab.Container defaultActiveKey={activeTabId} activeKey={activeTabId}>
          <Tab.Content className="w-100 flex-grow-1 overflow-auto">
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.employees.id}
              isActiveTab={activeTabId === FilterTabNames.employees.id}
            >
              {employeesContent}
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.costCenters.id}
              isActiveTab={activeTabId === FilterTabNames.costCenters.id}
            >
              {costCentersContent}
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.departments.id}
              isActiveTab={activeTabId === FilterTabNames.departments.id}
            >
              {departmentsContent}
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.jobs.id}
              isActiveTab={activeTabId === FilterTabNames.jobs.id}
            >
              {jobsContent}
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.roles.id}
              isActiveTab={activeTabId === FilterTabNames.roles.id}
            >
              {rolesContent}
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.supervisors.id}
              isActiveTab={activeTabId === FilterTabNames.supervisors.id}
            >
              {supervisorsContent}
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex "
              tabId={FilterTabNames.workLocations.id}
              isActiveTab={activeTabId === FilterTabNames.workLocations.id}
            >
              {workLocationsContent}
            </TabPanel>
          </Tab.Content>
        </Tab.Container>
      </DrawerMenuGroup>
    </>
  );
};

export default TaskProfileReportDrawerTabView;
