import { useEffect, useState } from 'react';
import { Values } from '../types/values';

/**
 * The various CSS media queries that can be used to check when the screen meets the condition of the breakpoint in a react component.
 *
 * Note: These currently match bootstraps media queries.
 */
export const MediaQuery = {
  /** Extra small devices (portrait phones, 0px and up) */
  xs: '(min-width: 0px)',
  /** Small devices (landscape phones, 576px and up) */
  sm: '(min-width: 576px)',
  /** Medium devices (tablets, 768px and up) */
  md: '(min-width: 768px)',
  /** Large devices (desktops, 992px and up) */
  lg: '(min-width: 992px)',
  /** Extra large devices (large desktops, 1200px and up) */
  xl: '(min-width: 1200px)',
} as const;

/**
 * A react hook that monitors the document and detects when the provided media query is satisfied or not.
 */
const useMediaQuery = (mediaQuery: Values<typeof MediaQuery>): boolean => {
  const [isMatch, setIsMatch] = useState(window.matchMedia(mediaQuery).matches);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery);
    const documentChangeHandler = () => setIsMatch(mediaQueryList.matches);

    mediaQueryList.addListener(documentChangeHandler);

    documentChangeHandler();
    return () => {
      mediaQueryList.removeListener(documentChangeHandler);
    };
  }, [mediaQuery]);

  return isMatch;
};

export default useMediaQuery;
