import { SearchRequest } from '../requests/searchRequest';

function getSearchRequestParams(request?: SearchRequest): Partial<SearchRequest> {
  const queryParams: Partial<SearchRequest> = {};

  if (request?.searchText?.trim() !== '') {
    queryParams.searchText = request?.searchText.trim();
  }

  return queryParams;
}

export default {
  getSearchRequestParams,
};
