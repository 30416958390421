import React, { forwardRef, PropsWithChildren, useMemo } from 'react';
import Card from 'react-bootstrap/Card';
import testIds from '../../constants/testIds';

export interface CardGridBaseProps {
  /**
   * The number of columns within the grid.
   */
  numColumns: number;
}

const CardGridBase = forwardRef<HTMLDivElement, PropsWithChildren<CardGridBaseProps>>(
  ({ numColumns, children }, ref) => {
    const style = useMemo(
      () => ({ gridTemplateColumns: `repeat(${numColumns}, 1fr)` }),
      [numColumns]
    );

    return (
      <Card className="h-100 shadow-none bg-transparent">
        <Card.Body
          ref={ref}
          className="card-grid"
          style={style}
          data-testid={testIds.cardGridContainer()}
        >
          {children}
        </Card.Body>
      </Card>
    );
  }
);

CardGridBase.displayName = 'CardGridBase';

export default CardGridBase;
