import React, { ReactElement } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import testIds from '../../../constants/testIds';
import UncontrolledDropDownWithLabel from '../../DropDownWithLabel/UncontrolledDropDownWithLabel';
import { MappingOption } from '../../../models/MappingOption';

export interface CreateUserIntegrationDropDownProps {
  mappingOption: MappingOption;
  options: string[];
  onDropDownItemClick: (option: MappingOption) => void;
  labelIcon?: ReactElement;
  readOnly: boolean;
}

const CreateUserIntegrationDropDown: React.FC<CreateUserIntegrationDropDownProps> = ({
  mappingOption,
  options,
  onDropDownItemClick,
  labelIcon,
  readOnly,
}) => {
  return (
    <UncontrolledDropDownWithLabel
      className="w-100"
      label={mappingOption.dataPointLabel}
      isRequired={mappingOption.isRequired}
      testId={`${mappingOption.dataPointLabel.replace(/\s/g, '')}Dropdown`}
      labelIcon={labelIcon}
      title={mappingOption.columnName === '' ? 'Select a column' : mappingOption.columnName}
      variant="transparent"
      disabled={readOnly}
      dropdownStyle={{ maxWidth: '300px' }}
    >
      {options.length > 0 ? (
        options.map(option => (
          <Dropdown.Item
            key={option}
            onClick={() => {
              const newOption = {
                ...mappingOption,
                columnName: option,
                columnNumber: options.indexOf(option),
              };
              onDropDownItemClick(newOption);
            }}
            as="button"
            data-testid={testIds.uncontrolledDropdownItem()}
            active={mappingOption.columnName === option}
            className="text-truncate"
          >
            {option}
          </Dropdown.Item>
        ))
      ) : (
        <Dropdown.Item disabled>Upload a Template</Dropdown.Item>
      )}
    </UncontrolledDropDownWithLabel>
  );
};

export default CreateUserIntegrationDropDown;
