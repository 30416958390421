import React, { ChangeEvent } from 'react';
import Form from 'react-bootstrap/Form';
import commonText from '../../constants/commonText';

export interface DateRangeFilterProps {
  from: string;
  to: string;
  label: string;
  gridArea: string;
  onFromChange: (newDate: string) => void;
  onToChange: (newDate: string) => void;
}

const DateRangeFilter: React.FC<DateRangeFilterProps> = ({
  label,
  from,
  to,
  gridArea,
  onFromChange,
  onToChange,
}) => {
  const handleFromChange = (e: ChangeEvent<HTMLInputElement>) => {
    onFromChange(e.target.value);
  };

  const handleToChange = (e: ChangeEvent<HTMLInputElement>) => {
    onToChange(e.target.value);
  };

  return (
    <>
      <div className={`flex-grow-1 ${gridArea}-label align-self-center `}>{label}</div>
      <div className={`flex-grow-1 ${gridArea}-from`}>
        <Form.Control
          className="bg-white"
          placeholder={commonText.from}
          type="date"
          value={from}
          max={to}
          onChange={handleFromChange}
        />
      </div>
      <div className={`flex-grow-1 ${gridArea}-to`}>
        <Form.Control
          className="bg-white"
          placeholder={commonText.to}
          type="date"
          value={to}
          min={from}
          onChange={handleToChange}
        />
      </div>
    </>
  );
};

export default DateRangeFilter;
