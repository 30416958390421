import axios, { AxiosRequestConfig } from 'axios';
import authService from '../../auth/authService';
import { BatchUserStatisticResponse } from '../responses/batchUserStatisticResponse';
import { GetBatchUserStatisticsDetailsRequest } from './requests/getBatchUserStatisticsDetailRequest';
import {
  DeleteCurrentIntegrationRoute,
  GetBatchUserStatisticsByCompanyIdRoute,
  GetBatchUserStatisticsDetailsRoute,
  GetCurrentIntegrationsRoute,
  GetIntegrationBatchStatisticsByCompanyIdRoute,
  GetIntegrationHistoryExcelReportRoute,
} from './integrationApiRoutes';
import { BatchUserUploadIntegrationResponse } from './responses/BatchUserUploadIntegrationResponse';
import CurrentIntegrationsResponse from '../../models/CurrentIntegrationsResponse';
import { GetIntegrationUserStatisticsDetailsRequest } from './requests/getIntegrationUserStatisticsDetailRequest';
import UserIntegrationDetailsQueryParams from '../../models/UserIntegrationDetailsQueryParams';
import { QueryFunctionContext } from '@tanstack/react-query';
import { BatchUserStatisticsPaginatedResponse } from '../responses/BatchUserStatisticsPaginatedResponse';
import batchUserStatisticsExcelReport from '../responses/batchUserStatisticsExcelReport';

const integrationApi = axios.create({
  // todo: replace baseUrl
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/verifyAPI`,
  headers: {
    'Content-Type': 'application/json',
  },
});

integrationApi.interceptors.request.use(async config => {
  const bearerToken = await authService.getAuthSession();
  if (config !== undefined && config.headers !== undefined) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${bearerToken.token.idToken}`;
  }

  return config;
});

async function getBatchUserStatisticsDetails(
  requestParams: GetBatchUserStatisticsDetailsRequest
): Promise<BatchUserStatisticResponse[]> {
  const response = await integrationApi.get(
    GetBatchUserStatisticsDetailsRoute(requestParams.companyId, requestParams.batchId)
  );

  return response.data;
}

async function getBatchUserStatisticsByCompanyId(
  companyId: number
): Promise<BatchUserUploadIntegrationResponse[]> {
  const response = await integrationApi.get(GetBatchUserStatisticsByCompanyIdRoute(companyId));

  return response.data;
}

async function getIntegrationBatchStatisticsByCompanyId(
  companyId: number,
  integrationId: number
): Promise<BatchUserUploadIntegrationResponse[]> {
  const response = await integrationApi.get(
    GetIntegrationBatchStatisticsByCompanyIdRoute(companyId, integrationId)
  );

  return response.data;
}

async function getCurrentIntegrations(companyId: number): Promise<CurrentIntegrationsResponse[]> {
  const params = new URLSearchParams();
  const config: AxiosRequestConfig = { params };
  const response = await integrationApi.get(GetCurrentIntegrationsRoute(companyId), config);
  return response.data;
}

async function deleteCurrentIntegration(companyId: number, integrationId: number): Promise<number> {
  const response = await integrationApi.delete(
    DeleteCurrentIntegrationRoute(companyId, integrationId)
  );
  return response.status;
}

async function getIntegrationUserStatisticsDetails(
  requestParams: GetIntegrationUserStatisticsDetailsRequest,
  options: QueryFunctionContext<(string | GetIntegrationUserStatisticsDetailsRequest)[], number>
): Promise<BatchUserStatisticsPaginatedResponse> {
  const { companyId, dateFilters, integrationId, userType } = requestParams;
  if (integrationId === -1) return { ItemCount: 0, ResultData: [] };
  if (dateFilters.startDate === '' && dateFilters.endDate === '')
    return { ItemCount: 0, ResultData: [] };

  const params = new URLSearchParams();
  // Filters
  if (dateFilters.startDate !== '') {
    params.append(UserIntegrationDetailsQueryParams.startDate, dateFilters.startDate);
  }
  if (dateFilters.endDate !== '') {
    params.append(UserIntegrationDetailsQueryParams.endDate, dateFilters.endDate);
  }
  params.append(UserIntegrationDetailsQueryParams.userType, userType.toString());

  // Paging
  params.append(UserIntegrationDetailsQueryParams.skip, options.pageParam?.toString() || '0');
  params.append(UserIntegrationDetailsQueryParams.take, '50');

  const config: AxiosRequestConfig = { params };
  const response = await integrationApi.get(
    GetBatchUserStatisticsDetailsRoute(companyId, integrationId),
    config
  );

  return response.data;
}

async function getIntegrationHistoryExcelReport(
  requestParams: GetIntegrationUserStatisticsDetailsRequest
): Promise<batchUserStatisticsExcelReport> {
  const params = new URLSearchParams();
  // Filters
  params.append(UserIntegrationDetailsQueryParams.startDate, requestParams.dateFilters.startDate);
  params.append(UserIntegrationDetailsQueryParams.endDate, requestParams.dateFilters.endDate);

  const config: AxiosRequestConfig = { params };
  const response = await integrationApi.get(
    GetIntegrationHistoryExcelReportRoute(requestParams.companyId, requestParams.integrationId),
    config
  );

  return response.data;
}

export default {
  getBatchUserStatisticsDetails,
  getBatchUserStatisticsByCompanyId,
  getCurrentIntegrations,
  deleteCurrentIntegration,
  getIntegrationUserStatisticsDetails,
  getIntegrationBatchStatisticsByCompanyId,
  getIntegrationHistoryExcelReport,
};
