import React from 'react';
import statisticParser from '../../../utils/parsers/statisticParser';
import CardStat from '../../CardStat/CardStat';

interface TaskProfileCardStatProps {
  className: string;
  isLoading: boolean;
  title: string;
  total: number;
  totalCompleted: number;
  isIncomplete: boolean;
  statisticTextParser?: (isIncomplete: boolean, totalCompleted: number, total: number) => string;
}

const TaskProfileCardStat: React.FC<TaskProfileCardStatProps> = ({
  className,
  isLoading,
  title,
  total,
  totalCompleted,
  isIncomplete,
  statisticTextParser = statisticParser.parse,
}) => {
  const statisticText = statisticTextParser(isIncomplete, totalCompleted, total);

  return (
    <CardStat className={className} isLoading={isLoading}>
      {`${title}: ${statisticText}`}
    </CardStat>
  );
};

export default TaskProfileCardStat;
