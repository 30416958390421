import React, { useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Tab from 'react-bootstrap/Tab';
import { GetLearningHistoryReportRequest } from '../../../api/requests/getLearningHistoryReport';
import commonText from '../../../constants/commonText';
import messages from '../../../constants/messages';
import testIds from '../../../constants/testIds';
import { DisplayItem } from '../../../models/displayItem';
import DrawerFilterTab from '../../../models/DrawerFilterTab';
import { DropdownOption } from '../../../models/DropdownOption';
import FilterTabNameId from '../../../models/FilterTabNameId';
import FilterTabNames from '../../../models/FilterTabNames';
import { UseDisplayItemsResponse } from '../../../models/useDisplayItemsResponse';
import Count from '../../Count/Count';
import DateFilterContainer from '../../DateFilterContainer/DateFilterContainer';
import Dropdown from '../../Dropdown/Dropdown';
import Drawer from '../../Layout/Drawer/Drawer';
import DrawerMenuGroup from '../../Layout/Drawer/DrawerMenuGroup';
import TabPanel from '../../Layout/TabPanel/TabPanel';
import MultiPartSelectContainer from '../../MultiPartSelect/Container/MultiPartSelectContainer';
import MultiPartSelectWithDropdownContainer from '../../MultiPartSelect/MultiPartSelectWithDropdownContainer/MultiPartSelectWithDropdownContainer';

export interface LearningHistoryDrawerProps {
  activeShowOption: DropdownOption;
  companyId: number;
  expanded: boolean;
  learningHistoryRequest: GetLearningHistoryReportRequest;
  handleActiveShowOptionChange: (option: DropdownOption) => void;
  onExpandChange: () => void;
  onLearningHistoryRequestChange: (newRequest: GetLearningHistoryReportRequest) => void;
  useCostCenters: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useDepartments: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useActiveEmployeeStatus: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useEmployeesAll: (
    companyId: number,
    activeStatus: number,
    searchText: string
  ) => UseDisplayItemsResponse;
  useSeries: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useTasks: (companyId: number, seriesId: number, searchText: string) => UseDisplayItemsResponse;
  useQualificationStatus: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useReasons: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useWorkLocations: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useSupervisors: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useEvaluators: (companyId: number, searchText: string) => UseDisplayItemsResponse;
}

export const learningHistoryReportShowOptions: DropdownOption[] = [
  {
    id: 0,
    name: commonText.currentRecords,
  },
  {
    id: 1,
    name: commonText.historicalRecords,
  },
  {
    id: 2,
    name: commonText.allRecords,
  },
];

const LearningHistoryDrawer: React.FC<LearningHistoryDrawerProps> = ({
  activeShowOption,
  companyId,
  expanded,
  learningHistoryRequest,
  handleActiveShowOptionChange,
  onExpandChange,
  onLearningHistoryRequestChange,
  useCostCenters,
  useDepartments,
  useEmployeesAll,
  useActiveEmployeeStatus,
  useSeries,
  useTasks,
  useQualificationStatus,
  useReasons,
  useWorkLocations,
  useEvaluators,
  useSupervisors,
}) => {
  const [activeFilterTab, setActiveFilterTab] = useState<FilterTabNameId>(
    FilterTabNames.costCenters.id
  );

  const handleActiveFilterTabChange = (
    updatedSearchText: string,
    selectedFilterTabId: FilterTabNameId,
    stateSetter: React.Dispatch<React.SetStateAction<string>> | null
  ) => {
    setActiveFilterTab(selectedFilterTabId);
    if (stateSetter) stateSetter(updatedSearchText);
  };

  // Start Cost Centers Section
  const [costCentersSearchText, setCostCentersSearchText] = useState('');
  const costCenters: UseDisplayItemsResponse = useCostCenters(companyId, '');
  const costCentersDisplayItems = costCenters.data.filter(costCentersDisplayItem =>
    learningHistoryRequest.costCenterIds?.includes(costCentersDisplayItem.itemId)
  );

  const handleCostCentersIdsChange = (displayItems: DisplayItem[]) => {
    onLearningHistoryRequestChange({
      ...learningHistoryRequest,
      costCenterIds: displayItems.map(cc => cc.itemId),
    });
  };
  // End Cost Centers Section

  // Start Departments Section
  const [departmentsSearchText, setDepartmentsSearchText] = useState('');
  const departments: UseDisplayItemsResponse = useDepartments(companyId, '');
  const departmentsDisplayItems = departments.data.filter(departmentsDisplayItem =>
    learningHistoryRequest.departmentIds?.includes(departmentsDisplayItem.itemId)
  );

  const handleDepartmentsIdsChange = (displayItems: DisplayItem[]) => {
    onLearningHistoryRequestChange({
      ...learningHistoryRequest,
      departmentIds: displayItems.map(d => d.itemId),
    });
  };
  // End Departments Section

  // Start Date Section
  const handleDateFiltersChange = (changes: Partial<GetLearningHistoryReportRequest>) => {
    onLearningHistoryRequestChange({
      ...learningHistoryRequest,
      ...changes,
    });
  };

  const handleDateFiltersClear = () => {
    onLearningHistoryRequestChange({
      ...learningHistoryRequest,
      expiresInDays: 0,
      completedDate: { From: '', To: '' },
      expirationDate: { From: '', To: '' },
    });
  };
  // End Date Section

  // Start Employees Section
  const [employeeSearchText, setEmployeeSearchText] = useState('');
  const [employeesDisplayItems, setEmployeesDisplayItems] = useState<DisplayItem[]>([]);

  const handleEmployeeIdsChange = (displayItems: DisplayItem[]) => {
    onLearningHistoryRequestChange({
      ...learningHistoryRequest,
      employeeIds: displayItems.map(e => e.itemId),
    });
    setEmployeesDisplayItems(displayItems);
  };
  // End Employees Section

  // Start Series Section
  const [seriesSearchText, setSeriesSearchText] = useState('');
  const series: UseDisplayItemsResponse = useSeries(companyId, '');
  const seriesDisplayItems = series.data.filter(seriesDisplayItem =>
    learningHistoryRequest.seriesIds?.includes(seriesDisplayItem.itemId)
  );

  const handleSeriesIdsChange = (displayItems: DisplayItem[]) => {
    onLearningHistoryRequestChange({
      ...learningHistoryRequest,
      seriesIds: displayItems.map(s => s.itemId),
    });
  };
  // End Series Section

  // Start Tasks Section
  const [tasksSearchText, setTasksSearchText] = useState('');
  const [tasksDisplayItems, setTasksDisplayItems] = useState<DisplayItem[]>([]);

  const handleTasksIdsChange = (displayItems: DisplayItem[]) => {
    onLearningHistoryRequestChange({
      ...learningHistoryRequest,
      taskIds: displayItems.map(t => t.itemId),
    });
    setTasksDisplayItems(displayItems);
  };
  // End Tasks Section

  // Start Qualification Status Section
  const [qualificationStatusSearchText, setQualificationStatusSearchText] = useState('');
  const qualificationStatus: UseDisplayItemsResponse = useQualificationStatus(companyId, '');
  const qualificationStatusDisplayItems = qualificationStatus.data.filter(
    qualificationStatusDisplayItem =>
      learningHistoryRequest.qualificationStatus?.includes(qualificationStatusDisplayItem.itemId)
  );

  const handleQualificationStatusChange = (displayItems: DisplayItem[]) => {
    onLearningHistoryRequestChange({
      ...learningHistoryRequest,
      qualificationStatus: displayItems.map(s => s.itemId),
    });
  };
  // End Qualification Status Section

  // Start Work Locations Section
  const [workLocationsSearchText, setWorkLocationsSearchText] = useState('');
  const workLocations: UseDisplayItemsResponse = useWorkLocations(companyId, '');
  const workLocationsDisplayItems = workLocations.data.filter(workLocationsDisplayItem =>
    learningHistoryRequest.workLocationIds?.includes(workLocationsDisplayItem.itemId)
  );

  const handleWorkLocationsChange = (displayItems: DisplayItem[]) => {
    onLearningHistoryRequestChange({
      ...learningHistoryRequest,
      workLocationIds: displayItems.map(s => s.itemId),
    });
  };
  // End Work Locations Section

  // Start Reason Section
  const [reasonsSearchText, setReasonsSearchText] = useState('');
  const reasons: UseDisplayItemsResponse = useReasons(companyId, reasonsSearchText);
  const reasonsDisplayItems = reasons.data.filter(reasonDisplayItem =>
    learningHistoryRequest.reasons?.includes(reasonDisplayItem.display)
  );

  const handleReasonsChange = (displayItems: DisplayItem[]) => {
    onLearningHistoryRequestChange({
      ...learningHistoryRequest,
      reasons: displayItems.map(r => r.display),
    });
  };
  // End Reason Section

  // Start Supervisors Section
  const [supervisorsSearchText, setSupervisorsSearchText] = useState('');
  const supervisors: UseDisplayItemsResponse = useSupervisors(companyId, '');
  const supervisorsDisplayItems = supervisors.data.filter(supervisorsDisplayItem =>
    learningHistoryRequest.supervisorIds?.includes(supervisorsDisplayItem.itemId)
  );

  const handleSupervisorsChange = (displayItems: DisplayItem[]) => {
    onLearningHistoryRequestChange({
      ...learningHistoryRequest,
      supervisorIds: displayItems.map(s => s.itemId),
    });
  };
  // End Supervisors Section

  // Start Evaluators Section
  const [evaluatorsSearchText, setEvaluatorsSearchText] = useState('');
  const evaluators: UseDisplayItemsResponse = useEvaluators(companyId, '');
  const evaluatorsDisplayItems = evaluators.data.filter(evaluatorsDisplayItem =>
    learningHistoryRequest.evaluatorIds?.includes(evaluatorsDisplayItem.itemId)
  );

  const handleEvaluatorsChange = (displayItems: DisplayItem[]) => {
    onLearningHistoryRequestChange({
      ...learningHistoryRequest,
      evaluatorIds: displayItems.map(s => s.itemId),
    });
  };
  // End Evaluators Section

  const tabs: DrawerFilterTab[] = [
    {
      id: FilterTabNames.costCenters.id,
      label: FilterTabNames.costCenters.name,
      count: <Count className="ms-1" count={learningHistoryRequest.costCenterIds?.length || 0} />,
      onFilterChangeStateSetter: setCostCentersSearchText,
    },
    {
      id: FilterTabNames.departments.id,
      label: FilterTabNames.departments.name,
      count: <Count className="ms-1" count={learningHistoryRequest.departmentIds?.length || 0} />,
      onFilterChangeStateSetter: setDepartmentsSearchText,
    },
    {
      id: FilterTabNames.date.id,
      label: FilterTabNames.date.name,
      count: null,
      onFilterChangeStateSetter: null,
    },
    {
      id: FilterTabNames.employees.id,
      label: FilterTabNames.employees.name,
      count: <Count className="ms-1" count={learningHistoryRequest.employeeIds?.length || 0} />,
      onFilterChangeStateSetter: setEmployeeSearchText,
    },
    {
      id: FilterTabNames.series.id,
      label: FilterTabNames.series.name,
      count: <Count className="ms-1" count={learningHistoryRequest.seriesIds?.length || 0} />,
      onFilterChangeStateSetter: setSeriesSearchText,
    },
    {
      id: FilterTabNames.tasks.id,
      label: FilterTabNames.tasks.name,
      count: <Count className="ms-1" count={learningHistoryRequest.taskIds?.length || 0} />,
      onFilterChangeStateSetter: setTasksSearchText,
    },
    {
      id: FilterTabNames.status.id,
      label: FilterTabNames.status.name,
      count: (
        <Count className="ms-1" count={learningHistoryRequest.qualificationStatus?.length || 0} />
      ),
      onFilterChangeStateSetter: setQualificationStatusSearchText,
    },
    {
      id: FilterTabNames.reasons.id,
      label: FilterTabNames.reasons.name,
      count: <Count className="ms-1" count={learningHistoryRequest.reasons?.length || 0} />,
      onFilterChangeStateSetter: setReasonsSearchText,
    },
    {
      id: FilterTabNames.workLocations.id,
      label: FilterTabNames.workLocations.name,
      count: <Count className="ms-1" count={learningHistoryRequest.workLocationIds?.length || 0} />,
      onFilterChangeStateSetter: setWorkLocationsSearchText,
    },
    {
      id: FilterTabNames.supervisors.id,
      label: FilterTabNames.supervisors.name,
      count: <Count className="ms-1" count={learningHistoryRequest.supervisorIds?.length || 0} />,
      onFilterChangeStateSetter: setSupervisorsSearchText,
    },
    {
      id: FilterTabNames.evaluators.id,
      label: FilterTabNames.evaluators.name,
      count: <Count className="ms-1" count={learningHistoryRequest.evaluatorIds?.length || 0} />,
      onFilterChangeStateSetter: setEvaluatorsSearchText,
    },
  ];
  return (
    <Drawer expanded={expanded} onDrawerChange={onExpandChange}>
      <DrawerMenuGroup>
        <div className="d-inline-flex align-items-center">
          <p className="fw-bold my-0 me-3">Show</p>
          <Dropdown<DropdownOption>
            items={learningHistoryReportShowOptions}
            title={activeShowOption.name}
            itemKey={(item: DropdownOption) => item.id}
            itemLabel={(item: DropdownOption) => item.name}
            emptyListLabel=""
            variant="transparent"
            onItemSelect={handleActiveShowOptionChange}
            isLoading={false}
            dropdownTestId={testIds.learningHistoryDropdownToggle()}
            selectedItem={activeShowOption.id}
          />
        </div>
      </DrawerMenuGroup>
      <DrawerMenuGroup>
        <Tab.Container defaultActiveKey={FilterTabNames.costCenters.id} activeKey={activeFilterTab}>
          <ListGroup
            className="w-100 list-group-horizontal flex-wrap"
            data-testid={testIds.filterTabButtons()}
          >
            {tabs.map(({ id, label, count, onFilterChangeStateSetter = null }: DrawerFilterTab) => {
              return (
                <ListGroup.Item
                  role="tab"
                  as="button"
                  className="btn-sm border-0 rounded"
                  variant="transparent"
                  key={id}
                  onClick={() => handleActiveFilterTabChange('', id, onFilterChangeStateSetter)}
                  active={activeFilterTab === id}
                >
                  {label}
                  {count}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
          <Tab.Content className="overflow-auto h-100">
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.costCenters.id}
              isActiveTab={activeFilterTab === FilterTabNames.costCenters.id}
            >
              <MultiPartSelectContainer
                companyId={companyId}
                useData={useCostCenters}
                onChange={handleCostCentersIdsChange}
                selectedDisplayItems={costCentersDisplayItems}
                emptyListText={messages.noAvailableMessage(commonText.costCenters)}
                onSearchTextChanged={setCostCentersSearchText}
                searchText={costCentersSearchText}
                tabId={FilterTabNames.costCenters.id}
              />
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.departments.id}
              isActiveTab={activeFilterTab === FilterTabNames.departments.id}
            >
              <MultiPartSelectContainer
                companyId={companyId}
                useData={useDepartments}
                onChange={handleDepartmentsIdsChange}
                selectedDisplayItems={departmentsDisplayItems}
                emptyListText={messages.noAvailableMessage(commonText.departments)}
                onSearchTextChanged={setDepartmentsSearchText}
                searchText={departmentsSearchText}
                tabId={FilterTabNames.departments.id}
              />
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.date.id}
              isActiveTab={activeFilterTab === FilterTabNames.date.id}
            >
              <DateFilterContainer
                hideLastAccessedFilter
                completedDayFilter={learningHistoryRequest.completedDate || { From: '', To: '' }}
                expirationDateFilter={learningHistoryRequest.expirationDate || { From: '', To: '' }}
                expiresInDays={learningHistoryRequest.expiresInDays || 0}
                lastAccessedFilter={{ From: '', To: '' }}
                onDataChange={handleDateFiltersChange}
                onClear={handleDateFiltersClear}
              />
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.employees.id}
              isActiveTab={activeFilterTab === FilterTabNames.employees.id}
            >
              <MultiPartSelectWithDropdownContainer
                companyId={companyId}
                useDropdownData={useActiveEmployeeStatus}
                useListData={useEmployeesAll}
                onListItemSelection={handleEmployeeIdsChange}
                emptyListText={messages.noAvailableMessage(commonText.employees)}
                onSearch={setEmployeeSearchText}
                searchText={employeeSearchText}
                dropdownLabel={commonText.employeesStatus}
                emptyDropdownLabel={commonText.selectEmployeesStatus}
                selectedDisplayItems={employeesDisplayItems}
                defaultParentId={1}
                tabId={FilterTabNames.employees.id}
              />
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.series.id}
              isActiveTab={activeFilterTab === FilterTabNames.series.id}
            >
              <MultiPartSelectContainer
                companyId={companyId}
                useData={useSeries}
                onChange={handleSeriesIdsChange}
                selectedDisplayItems={seriesDisplayItems}
                emptyListText={messages.noAvailableMessage(commonText.series)}
                onSearchTextChanged={setSeriesSearchText}
                searchText={seriesSearchText}
                tabId={FilterTabNames.series.id}
              />
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.tasks.id}
              isActiveTab={activeFilterTab === FilterTabNames.tasks.id}
            >
              <MultiPartSelectWithDropdownContainer
                companyId={companyId}
                useDropdownData={useSeries}
                useListData={useTasks}
                onListItemSelection={handleTasksIdsChange}
                emptyListText={messages.noAvailableMessage(commonText.task)}
                onSearch={setTasksSearchText}
                searchText={tasksSearchText}
                dropdownLabel={commonText.series}
                emptyDropdownLabel={commonText.selectSeries}
                selectedDisplayItems={tasksDisplayItems}
                tabId={FilterTabNames.tasks.id}
              />
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.reasons.id}
              isActiveTab={activeFilterTab === FilterTabNames.reasons.id}
            >
              <MultiPartSelectContainer
                companyId={companyId}
                useData={useReasons}
                onChange={handleReasonsChange}
                selectedDisplayItems={reasonsDisplayItems}
                emptyListText={messages.noAvailableMessage(commonText.reason)}
                onSearchTextChanged={setReasonsSearchText}
                searchText={reasonsSearchText}
                tabId={FilterTabNames.reasons.id}
              />
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.status.id}
              isActiveTab={activeFilterTab === FilterTabNames.status.id}
            >
              <MultiPartSelectContainer
                companyId={companyId}
                useData={useQualificationStatus}
                onChange={handleQualificationStatusChange}
                selectedDisplayItems={qualificationStatusDisplayItems}
                emptyListText={messages.noAvailableMessage(commonText.status)}
                onSearchTextChanged={setQualificationStatusSearchText}
                searchText={qualificationStatusSearchText}
                tabId={FilterTabNames.status.id}
              />
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.workLocations.id}
              isActiveTab={activeFilterTab === FilterTabNames.workLocations.id}
            >
              <MultiPartSelectContainer
                companyId={companyId}
                useData={useWorkLocations}
                onChange={handleWorkLocationsChange}
                selectedDisplayItems={workLocationsDisplayItems}
                emptyListText={messages.noAvailableMessage(commonText.workLocations)}
                onSearchTextChanged={setWorkLocationsSearchText}
                searchText={workLocationsSearchText}
                tabId={FilterTabNames.workLocations.id}
              />
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.supervisors.id}
              isActiveTab={activeFilterTab === FilterTabNames.supervisors.id}
            >
              <MultiPartSelectContainer
                companyId={companyId}
                useData={useSupervisors}
                onChange={handleSupervisorsChange}
                selectedDisplayItems={supervisorsDisplayItems}
                emptyListText={messages.noAvailableMessage(commonText.supervisors)}
                onSearchTextChanged={setSupervisorsSearchText}
                searchText={supervisorsSearchText}
                tabId={FilterTabNames.supervisors.id}
              />
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.evaluators.id}
              isActiveTab={activeFilterTab === FilterTabNames.evaluators.id}
            >
              <MultiPartSelectContainer
                companyId={companyId}
                useData={useEvaluators}
                onChange={handleEvaluatorsChange}
                selectedDisplayItems={evaluatorsDisplayItems}
                emptyListText={messages.noAvailableMessage(commonText.evaluators)}
                onSearchTextChanged={setEvaluatorsSearchText}
                searchText={evaluatorsSearchText}
                tabId={FilterTabNames.evaluators.id}
              />
            </TabPanel>
          </Tab.Content>
        </Tab.Container>
      </DrawerMenuGroup>
    </Drawer>
  );
};

export default LearningHistoryDrawer;
