import React from 'react';
import Toast from 'react-bootstrap/Toast';

export type ToastIconColor =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'info'
  | 'light'
  | 'dark'
  | 'link';

export interface ToastMessageProps {
  iconColor: ToastIconColor;
  headerText: string;
  bodyText: string;
  toggle: () => void;
  className?: string;
}

const ToastMessage: React.FC<ToastMessageProps> = ({
  iconColor,
  headerText,
  bodyText,
  toggle,
  className = '',
}) => {
  return (
    <Toast className={className} onClose={toggle} bg={iconColor}>
      <Toast.Header closeButton>
        <div className="w-100 d-flex">
          <div style={{ height: '20px', width: '20px' }} className={`rounded bg-${iconColor}`} />
          <div className="ms-1">{headerText}</div>
        </div>
      </Toast.Header>
      <Toast.Body>{bodyText}</Toast.Body>
    </Toast>
  );
};

export default ToastMessage;
