import { RefObject, useEffect, useRef } from 'react';

export interface UseResizeObserverProps {
  callback: ResizeObserverCallback;
  elementRef: RefObject<HTMLElement>;
}

const useResizeObserver = ({ callback, elementRef }: UseResizeObserverProps): void => {
  const observerRef = useRef<ResizeObserver | undefined>(undefined);
  const currentElement = elementRef && elementRef.current;

  useEffect(() => {
    if (observerRef.current && currentElement) {
      observerRef.current.unobserve(currentElement);
    }

    observerRef.current = new ResizeObserver(callback);

    if (observerRef.current && currentElement) {
      observerRef.current.observe(currentElement);
    }

    return () => {
      if (observerRef.current && currentElement) {
        observerRef.current.unobserve(currentElement);
      }
    };
  }, [currentElement, callback]);
};

export default useResizeObserver;
