import React from 'react';
import Container from 'react-bootstrap/Container';
import Fade from 'react-bootstrap/Fade';
import keyboard from '../../../constants/keyboard';
import testIds from '../../../constants/testIds';
import useEventListener from '../../../hooks/useEventListener';
import DrawerHeader from './DrawerHeader';
import DrawerToggle from './DrawerToggle';

export interface DrawerProps {
  title?: React.ReactNode;
  expanded: boolean;
  onDrawerChange: () => void;
  children?: React.ReactNode;
}

const Drawer: React.FC<DrawerProps> = ({ title, onDrawerChange, expanded, children }) => {
  useEventListener(window, 'keyup', evt => {
    if ((evt as KeyboardEvent).key === keyboard.escape.key && expanded) {
      onDrawerChange();
    }
  });

  return (
    <>
      <Fade in={expanded} data-testid={testIds.drawerOverlay()}>
        {/* eslint-disable-next-line */}
        <div className={`${expanded ? 'drawer-overlay' : ''}`} onClick={onDrawerChange} />
      </Fade>
      <div
        className={`drawer drawer-right ${expanded ? 'drawer-expanded' : 'drawer-collapsed'}`}
        data-testid={testIds.drawer()}
      >
        <DrawerToggle onToggle={onDrawerChange} expanded={expanded} />
        <Container fluid className="py-3 h-100 justify-content-start d-flex flex-column">
          {expanded ? (
            <>
              <DrawerHeader title={title} onCloseClick={onDrawerChange} />
              {children}
            </>
          ) : null}
        </Container>
      </div>
    </>
  );
};

export default Drawer;
