import React from 'react';
import Nav from 'react-bootstrap/Nav';

interface ChildNavListProps {
  expanded?: boolean;
  children: React.ReactNode;
}

const ChildNavList: React.FC<ChildNavListProps> = ({ expanded = false, children }) => {
  return (
    <div className={expanded ? 'collapse show' : 'collapse'}>
      <Nav>{children}</Nav>
    </div>
  );
};

export default ChildNavList;
