import React from 'react';
import CardGridBase from './CardGridBase';
import CardGridCollection from './CardGridCollection';
import useCardGrid from './useCardGrid';

export interface CardGridProps<T extends unknown> {
  /**
   * The minimum width of a card within the grid
   */
  minWidth: number;

  /**
   * The minimum height of a card within the grid.
   */
  minHeight: number;

  /**
   * The data to be rendered in the grid
   */
  data: T[];

  /**
   * Render prop for rendering the card in the grid
   */
  render(data: T): React.ReactNode;

  /**
   * A callback function which is invoked whenever the grid is resized and the number of cards displayed has changed
   */
  onResize: (numCardsShown: number) => void;
}

function CardGrid<T>({
  minWidth,
  minHeight,
  data,
  render,
  onResize,
}: CardGridProps<T>): JSX.Element {
  const { ref, gridDimensions } = useCardGrid(minWidth, minHeight, onResize);
  const numOfCards = gridDimensions.numRows * gridDimensions.numColumns;

  return (
    <CardGridBase numColumns={gridDimensions.numColumns} ref={ref}>
      <CardGridCollection numOfCards={numOfCards} data={data} render={render} />
    </CardGridBase>
  );
}

export default CardGrid;
