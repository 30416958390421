import { FeatureFlag } from './featureFlags';

interface FeatureFlagUser {
  key?: string;
  custom?: {
    [key: string]: string | boolean | number | Array<string | boolean | number>;
  };
  anonymous?: boolean;
}

interface FeatureFlagClient {
  identify(user: FeatureFlagUser): Promise<unknown>;
}

export interface FeatureFlagService {
  setCurrentUser: (user: { id: number; companyId: number }) => Promise<unknown> | undefined;
  setAnonymousUser: () => Promise<unknown> | undefined;
  isEnabled: (flag: FeatureFlag, defaultValue?: boolean) => boolean;
}

function featureFlagService(
  ldClient: FeatureFlagClient | undefined,
  flags: Partial<Record<FeatureFlag, boolean>>
): FeatureFlagService {
  function setCurrentUser(user: { id: number; companyId: number }): Promise<unknown> | undefined {
    return ldClient?.identify({
      key: user.id.toString(),
      custom: {
        currentCompanyId: user.companyId,
      },
    });
  }

  function setAnonymousUser(): Promise<unknown> | undefined {
    return ldClient?.identify({ anonymous: true });
  }

  function isEnabled(flag: FeatureFlag, defaultValue: boolean = false): boolean {
    return flags[flag] ?? defaultValue;
  }

  return { isEnabled, setCurrentUser, setAnonymousUser };
}

export default featureFlagService;
