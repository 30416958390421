import { AuthSession } from '../models/authSession';
import useCompanyId from '../hooks/useCompanyId';
import useFeatureFlagService from './useFeatureFlagService';

type InitFeatureFlagUser = (authSession?: AuthSession) => Promise<void>;

function useInitFeatureFlagUser(): InitFeatureFlagUser {
  const { companyId } = useCompanyId();
  const featureFlagService = useFeatureFlagService();

  return async (authSession?: AuthSession) => {
    if (authSession) {
      await featureFlagService.setCurrentUser({ id: authSession.user.userId, companyId });
    } else {
      await featureFlagService.setAnonymousUser();
    }
  };
}

export default useInitFeatureFlagUser;
