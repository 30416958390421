import commonText from './commonText';

export default {
  clearAll: 'Clear All',
  clearMapping: 'Clear mapping?',
  error: 'Oops! Something went wrong.',
  getSeriesListError: `An error occurred getting the series list`,
  getTaskListError: `An error occurred getting the task list`,
  incompleteInProgress: 'Incomplete...so far.',
  loading: 'Loading...',
  noCompleteTasks: `[No Complete ${commonText.tasks}]`,
  noCompleteTrainings: `[No Complete ${commonText.trainings}]`,
  noCompleteWePe: `[No Complete ${commonText.we}/${commonText.pe}]`,
  noIncompleteTasks: `[No Incomplete ${commonText.tasks}]`,
  noIncompleteTrainings: `[No Incomplete ${commonText.trainings}]`,
  noIncompleteWePe: `[No Incomplete ${commonText.we}/${commonText.pe}]`,
  noJobs: `[No ${commonText.jobs}]`,
  noRecords: `No records available`,
  noRoles: `[No ${commonText.roles}]`,
  noViews: `No views currently available`,
  requireAll: 'Require All',
  saveJob: `${commonText.save} ${commonText.job}`,
  selectToCreateView: 'Select "+" to Create a View',
  viewAsIncomplete: `View as ${commonText.incomplete}`,
  actionFailed: (action: string): string => `${action} Failed`,
  actionSuccessful: (action: string): string => `${action} Successful`,
  deleteResource: (resource: string): string => `Delete ${resource}?`,
  deletingResource: (resource: string): string => `Deleting ${resource}...`,
  noAvailableMessage: (item: string): string => `[No ${item} Available]`,
  fileUploadError: (): string => 'File Upload Error',
  mappingFileSelectionError: (): string => 'Please select only one file.',
  deleteCurrentIntegrationHeader: (): string => 'Confirm delete',
  deleteCurrentIntegrationBody: (integrationName: string): string =>
    `Are you sure you want to delete integration "${integrationName}"?`,
};
