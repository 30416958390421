import { useContext } from 'react';
import AuthContext, { AuthContextParams } from './AuthContext';

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
const useAuth = (): AuthContextParams => {
  return useContext(AuthContext);
};

export default useAuth;
