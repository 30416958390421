import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React from 'react';
import { BatchUserStatisticsPaginatedResponse } from '../../api/responses/BatchUserStatisticsPaginatedResponse';
import useMediaQuery, { MediaQuery } from '../../hooks/useMediaQuery';
import IntegrationHistoryTabNames from '../../models/IntegrationHistoryTabNames';
import IntegrationAnalyticsChartModalTabPanel from '../../pages/IntegrationAnalytics/IntegrationAnalyticsChartModalTabPanel';
import Tab from '../Layout/Tab/Tab';
import Tabs from '../Layout/Tabs/Tabs';

export interface IntegrationHistoryTabsContainerProps {
  newUserStatistics: BatchUserStatisticsPaginatedResponse[];
  updatedUserStatistics: BatchUserStatisticsPaginatedResponse[];
  totalErrorsStatistics: BatchUserStatisticsPaginatedResponse[];
  activeTab: string;
  setActiveTab: (tabId: string) => void;
  isNewUserStatisticsLoading: boolean;
  isUpdatedUserStatisticsLoading: boolean;
  isTotalErrorsUserStatisticsLoading: boolean;
  fetchNextNewUsersPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<
    InfiniteQueryObserverResult<BatchUserStatisticsPaginatedResponse, AxiosError<unknown, unknown>>
  >;
  fetchNextUpdatedUsersPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<
    InfiniteQueryObserverResult<BatchUserStatisticsPaginatedResponse, AxiosError<unknown, unknown>>
  >;
  fetchNextTotalErrorsPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<
    InfiniteQueryObserverResult<BatchUserStatisticsPaginatedResponse, AxiosError<unknown, unknown>>
  >;
  newUsersHasNextPage: boolean;
  updatedUserHasNextPage: boolean;
  totalErrorsHasNextPage: boolean;
}

const IntegrationHistoryTabsContainer: React.FC<IntegrationHistoryTabsContainerProps> = ({
  newUserStatistics,
  updatedUserStatistics,
  totalErrorsStatistics,
  activeTab,
  setActiveTab,
  isNewUserStatisticsLoading,
  isUpdatedUserStatisticsLoading,
  isTotalErrorsUserStatisticsLoading,
  fetchNextNewUsersPage,
  fetchNextUpdatedUsersPage,
  fetchNextTotalErrorsPage,
  newUsersHasNextPage,
  updatedUserHasNextPage,
  totalErrorsHasNextPage,
}) => {
  const isLargeScreen = useMediaQuery(MediaQuery.lg);

  return (
    <>
      <Tabs
        isVertical={isLargeScreen}
        activeKey={activeTab}
        tabRowContainerClassname="h-100"
        tabContentRowContainerClassname="h-100"
        tabContentClassName="h-100"
        tabs={
          <>
            <Tab<string>
              setActiveTab={setActiveTab}
              activeTabId={activeTab}
              tabId={IntegrationHistoryTabNames.Created.id}
              panelId={`${IntegrationHistoryTabNames.Created.id}-panel`}
            >
              {`${IntegrationHistoryTabNames.Created.name} (${newUserStatistics[0].ItemCount})`}
            </Tab>
            <Tab
              setActiveTab={setActiveTab}
              activeTabId={activeTab}
              tabId={IntegrationHistoryTabNames.Updated.id}
              panelId={`${IntegrationHistoryTabNames.Updated.id}-panel`}
            >
              {`${IntegrationHistoryTabNames.Updated.name} (${updatedUserStatistics[0].ItemCount})`}
            </Tab>
            <Tab
              setActiveTab={setActiveTab}
              activeTabId={activeTab}
              tabId={IntegrationHistoryTabNames.Errors.id}
              panelId={`${IntegrationHistoryTabNames.Errors.id}-panel`}
            >
              {`${IntegrationHistoryTabNames.Errors.name} (${totalErrorsStatistics[0].ItemCount})`}
            </Tab>
          </>
        }
        tabPanes={
          <div className="d-flex flex-column h-100">
            <IntegrationAnalyticsChartModalTabPanel
              tabId={IntegrationHistoryTabNames.Created.id}
              isActiveTab={activeTab === IntegrationHistoryTabNames.Created.id}
              batchUserStatisticResponse={newUserStatistics}
              dataCount={newUserStatistics[0].ItemCount}
              isDataLoading={isNewUserStatisticsLoading}
              onPageChange={fetchNextNewUsersPage}
              hasNextPage={newUsersHasNextPage}
            />
            <IntegrationAnalyticsChartModalTabPanel
              tabId={IntegrationHistoryTabNames.Updated.id}
              isActiveTab={activeTab === IntegrationHistoryTabNames.Updated.id}
              batchUserStatisticResponse={updatedUserStatistics}
              dataCount={updatedUserStatistics[0].ItemCount}
              isDataLoading={isUpdatedUserStatisticsLoading}
              onPageChange={fetchNextUpdatedUsersPage}
              hasNextPage={updatedUserHasNextPage}
            />
            <IntegrationAnalyticsChartModalTabPanel
              showErrorColumn
              tabId={IntegrationHistoryTabNames.Errors.id}
              isActiveTab={activeTab === IntegrationHistoryTabNames.Errors.id}
              batchUserStatisticResponse={totalErrorsStatistics}
              dataCount={totalErrorsStatistics[0].ItemCount}
              isDataLoading={isTotalErrorsUserStatisticsLoading}
              onPageChange={fetchNextTotalErrorsPage}
              hasNextPage={totalErrorsHasNextPage}
            />
          </div>
        }
      />
    </>
  );
};

export default IntegrationHistoryTabsContainer;
