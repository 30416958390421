import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import testIds from '../../../constants/testIds';
import {
  getPrimaryDataPointText,
  TaskProfileCardPrimaryDataPoint,
  TaskProfileCardPrimaryDataPoints,
} from '../ReportDrawer/taskProfileCardPrimaryDataPoints';

interface TaskProfilePrimaryDataPointDropdownProps {
  activePrimaryDataPoint: TaskProfileCardPrimaryDataPoints;
  onPrimaryDataPointChange: (primaryDataPoint: TaskProfileCardPrimaryDataPoints) => void;
}

const TaskProfilePrimaryDataPointDropdown: React.FC<TaskProfilePrimaryDataPointDropdownProps> = ({
  activePrimaryDataPoint,
  onPrimaryDataPointChange,
}) => {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="transparent">
        <span className="me-3" data-testid={testIds.primaryDataPointToggle()}>
          {getPrimaryDataPointText(activePrimaryDataPoint)}
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="text-center" style={{ minWidth: '100%' }}>
        {Object.values(TaskProfileCardPrimaryDataPoint).map(dataPoint => (
          <Dropdown.Item
            key={dataPoint}
            className="btn-sm border-bottom-0 rounded-0"
            as="button"
            data-testid={testIds.primaryDataPoint(getPrimaryDataPointText(dataPoint))}
            active={dataPoint === activePrimaryDataPoint}
            onClick={() => onPrimaryDataPointChange(dataPoint)}
          >
            {getPrimaryDataPointText(dataPoint)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TaskProfilePrimaryDataPointDropdown;
