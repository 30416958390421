import { useEffect, useState } from 'react';
import commonText from '../constants/commonText';
import { DisplayItem } from '../models/displayItem';
import { UseDisplayItemsResponse } from '../models/useDisplayItemsResponse';

function useQualificationStatus(companyId: number, searchText: string): UseDisplayItemsResponse {
  useEffect(() => {
    const dataList: DisplayItem[] = [
      {
        itemId: 1,
        display: commonText.qualified,
      },
      {
        itemId: 0,
        display: commonText.notQualified,
      },
    ];
    setData(dataList.filter(item => item.display.toLowerCase().includes(searchText.toLowerCase())));
  }, [searchText]);

  const [data, setData] = useState<DisplayItem[]>([]);

  return { data, isLoading: false };
}

export default useQualificationStatus;
