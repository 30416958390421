import { CustomViewsResponse } from '../../api/responses/customViewsResponse';
import { TaskProfileReportData } from '../../models/taskProfileReportData';
import customViewReportConverter from './customViewReportConverter';

function convert(
  customViewsResponse: CustomViewsResponse<string>
): CustomViewsResponse<TaskProfileReportData> {
  const resultData = customViewsResponse.ResultData.map(response => {
    return customViewReportConverter.convert(response);
  });

  return { ItemCount: customViewsResponse.ItemCount, ResultData: resultData };
}

export default {
  convert,
};
