import { Values } from '../../types/values';
import { PagingRequest } from './pagingRequest';

export interface CompanyQualificationRequest extends PagingRequest {
  companyId: number;
  sortBy: readonly SortByOption[];
  userIds: number[];
  costCenterIds: number[];
  departmentIds: number[];
  jobIds: number[];
  roleIds: number[];
  supervisorIds: number[];
  workLocationIds: number[];
  searchBy: string;
}

const SortDirection = {
  ascending: 0,
  descending: 1,
} as const;

const CompanyQualificationRequestSortOption = {
  lastName: 'lastname',
  qualified: 'qualified',
} as const;

export const SortByOptions = {
  qualifiedASC: `${CompanyQualificationRequestSortOption.qualified},${SortDirection.ascending}`,
  qualifiedDSC: `${CompanyQualificationRequestSortOption.qualified},${SortDirection.descending}`,
  lastnameASC: `${CompanyQualificationRequestSortOption.lastName},${SortDirection.ascending}`,
  lastnameDSC: `${CompanyQualificationRequestSortOption.lastName},${SortDirection.descending}`,
} as const;

export type SortByOption = Values<typeof SortByOptions>;

export const TaskProfileSortByOptions = {
  lastnameASC: {
    id: 'lastnameASC',
    name: 'Alphabetically A - Z',
    sortByParams: [SortByOptions.lastnameASC],
  },
  lastnameDSC: {
    id: 'lastnameDSC',
    name: 'Alphabetically Z - A',
    sortByParams: [SortByOptions.lastnameDSC],
  },
  qualifiedDSC: {
    id: 'qualifiedDSC',
    name: 'Qualified Users First',
    sortByParams: [SortByOptions.qualifiedDSC, SortByOptions.lastnameASC],
  },
  qualifiedASC: {
    id: 'qualifiedASC',
    name: 'Qualified Users Last',
    sortByParams: [SortByOptions.qualifiedASC, SortByOptions.lastnameASC],
  },
} as const;

export type TaskProfileSortByOption = Values<typeof TaskProfileSortByOptions>;
