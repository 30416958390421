import { debounce } from 'lodash';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { AiOutlineSearch } from 'react-icons/ai';
import { TwoHundredAndFiftyMilliseconds } from '../../constants/measurements';
import testIds from '../../constants/testIds';
import eventUtils from '../../utils/eventUtils';

export const searchBoxDebounceTime = TwoHundredAndFiftyMilliseconds;

export interface SearchBoxProps {
  size?: 'sm' | 'lg';
  onChange: (text: string) => void;
  isDisabled?: boolean;
}

const SearchBox: React.FC<SearchBoxProps> = ({ size, onChange, isDisabled = false }) => {
  const [text, setText] = useState<string>('');

  const changeHandler = () => {
    onChange(text);
  };

  const debouncedHandleOnChange = useMemo(
    () => debounce(changeHandler, TwoHundredAndFiftyMilliseconds),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [text]
  );

  useEffect(() => {
    debouncedHandleOnChange();
    return () => {
      debouncedHandleOnChange.cancel();
    };
  }, [text, debouncedHandleOnChange]);

  return (
    <Form role="search" onSubmit={eventUtils.preventDefault}>
      <InputGroup className="no-border" size={size}>
        <Form.Control
          placeholder="Search..."
          className="bg-white"
          role="searchbox"
          type="search"
          onChange={(e: ChangeEvent<HTMLInputElement>) => setText(e.target.value)}
          disabled={isDisabled}
          value={text}
          data-testid={testIds.searchbox()}
        />
        <InputGroup.Text>
          <AiOutlineSearch />
        </InputGroup.Text>
      </InputGroup>
    </Form>
  );
};

export default SearchBox;
