import React, { useRef } from 'react';
import Form from 'react-bootstrap/Form';
import testIds from '../../../constants/testIds';
import Switch from '../../Switch/Switch';
import MessageOfTheDay from './MessageOfTheDay';

interface TaskProfileReportDrawerTabMessageProps {
  title: string;
  description: string;
  image: string;
  isEnabled: boolean;
  isShownWhenInactive: boolean;
  onTitleChange: (title: string) => void;
  onDescriptionChange: (description: string) => void;
  onImageChange: (image: File | undefined) => void;
  onIsEnabledChange: (isEnabled: boolean) => void;
  onIsShownWhenInactiveChange: (isShownWhenInactive: boolean) => void;
}

const TaskProfileReportDrawerTabMessage: React.FC<TaskProfileReportDrawerTabMessageProps> = ({
  title,
  description,
  image,
  isEnabled,
  isShownWhenInactive,
  onTitleChange,
  onDescriptionChange,
  onImageChange,
  onIsEnabledChange,
  onIsShownWhenInactiveChange,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const resetInputValue = () => {
    if (inputRef.current !== null) {
      inputRef.current.value = '';
    }
  };

  const handleImageRemoved = () => {
    onImageChange(undefined);
    resetInputValue();
  };

  return (
    <Form>
      <Form.Group className="mb-3">
        <Switch
          label="Is Enabled"
          id="messageOfTheDayIsEnabled"
          checked={isEnabled}
          onChange={() => onIsEnabledChange(!isEnabled)}
        />
        <Switch
          label="Is Shown When Inactive"
          id="messageOfTheDayIsShownWhenInactive"
          checked={isShownWhenInactive}
          onChange={() => onIsShownWhenInactiveChange(!isShownWhenInactive)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Control
          aria-label="Title"
          name="messageOfTheDayTitle"
          id="messageOfTheDayTitle"
          className="bg-white"
          placeholder="Title"
          value={title}
          onChange={event => onTitleChange(event.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Control
          aria-label="Description"
          as="textarea"
          name="messageOfTheDayDescription"
          id="messageOfTheDayDescription"
          className="bg-white"
          style={{ resize: 'none' }}
          value={description}
          placeholder="Description"
          onChange={event => onDescriptionChange(event.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <input
          aria-label="Image"
          type="file"
          name="messageOfTheDayImage"
          id="messageOfTheDayImage"
          ref={inputRef}
          accept="image/png, image/jpeg, image/jpg"
          onChange={event => {
            onImageChange(event.target.files?.[0]);
          }}
          data-testid={testIds.messageOfTheDayImageInput()}
        />
      </Form.Group>
      <Form.Group className="mb-3" role="group" aria-labelledby="messagePreview">
        <Form.Label id="messagePreview">Message Preview</Form.Label>
        <MessageOfTheDay
          className="border border-secondary"
          title={title}
          description={description}
          image={image}
          onImageRemoved={handleImageRemoved}
        />
      </Form.Group>
    </Form>
  );
};
export default TaskProfileReportDrawerTabMessage;
