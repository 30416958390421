import commonText from '../../../constants/commonText';
import { OptionsFlags } from '../../../types/optionsFlags';
import { Values } from '../../../types/values';

export const TaskProfileDetailDisplayOptions = {
  incompleteTasks: { id: 'incompleteTasks', label: `${commonText.incomplete} ${commonText.tasks}` },
  completeTasks: { id: 'completeTasks', label: `${commonText.complete} ${commonText.tasks}` },
  roles: { id: 'roles', label: `${commonText.roles}` },
  jobs: { id: 'jobs', label: `${commonText.jobs}` },
  incompleteWePe: {
    id: 'incompleteWePe',
    label: `${commonText.incomplete} ${commonText.we}/${commonText.pe}`,
  },
  completeWePe: {
    id: 'completeWePe',
    label: `${commonText.complete} ${commonText.we}/${commonText.pe}`,
  },
  incompleteTrainings: {
    id: 'incompleteTrainings',
    label: `${commonText.incomplete} ${commonText.trainings}`,
  },
  completeTrainings: {
    id: 'completeTrainings',
    label: `${commonText.complete} ${commonText.trainings}`,
  },
} as const;

export type TaskProfileDetailDisplayOption = Values<typeof TaskProfileDetailDisplayOptions>;

export type TaskProfileDetailFeatures = OptionsFlags<typeof TaskProfileDetailDisplayOptions>;
