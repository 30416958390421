import React, { FC } from 'react';
import Nav from 'react-bootstrap/Nav';

interface TabWrapperProps {
  className?: string;
  isVertical?: boolean;
  children: React.ReactNode;
}

const TabWrapper: FC<TabWrapperProps> = ({ className = '', isVertical = true, children }) => {
  return (
    <div
      className={`nav-tabs-navigation p-0 ${isVertical ? 'vertical-navs' : 'mb-3'} ${className}`}
    >
      <div className={`nav-tabs-wrapper ${isVertical ? 'h-100' : ''}`}>
        <Nav
          className={
            isVertical
              ? 'nav-stacked flex-column justify-content-start h-100'
              : 'justify-content-center'
          }
          role="tablist"
          variant="tabs"
        >
          {children}
        </Nav>
      </div>
    </div>
  );
};

export default TabWrapper;
