import React from 'react';
import { ComponentResponse } from '../../../../api/responses/componentResponse';
import { TaskResponse } from '../../../../api/responses/taskResponse';
import Accordion from '../../../Accordion/Accordion';
import TaskProfileDetailListTaskItem from './TaskProfileDetailListTaskItem';
import TaskProfileDetailListTaskItemComponents from './TaskProfileDetailListTaskItemComponents';
import TaskProfileDetailListTaskAssociationItem from './TaskProfileDetailTaskAssociationItem';

export interface TaskProfileDetailListTaskProps {
  id: number;
  name: string;
  isComplete: boolean;
  components?: ComponentResponse[];
  associatedTasks?: TaskResponse[];
  hasSeriesAccess: boolean;
}

const TaskProfileDetailListTask: React.FC<TaskProfileDetailListTaskProps> = ({
  id,
  name,
  isComplete,
  components = [],
  associatedTasks = [],
  hasSeriesAccess,
}) => {
  return (
    <Accordion
      toggle={
        <TaskProfileDetailListTaskItem
          id={id}
          name={name}
          isComplete={isComplete}
          hasSeriesAccess={hasSeriesAccess}
        />
      }
      toggleColor="transparent"
      buttonClassName="py-1 mt-3"
    >
      <div className="ps-4">
        <TaskProfileDetailListTaskItemComponents taskId={id} components={components} />
        {associatedTasks?.map(x => (
          <Accordion
            key={x.taskId}
            toggle={
              <TaskProfileDetailListTaskAssociationItem
                id={x.taskId}
                name={x.taskName}
                requiredStatusText={x.taskRequiredStatusText}
                hasSeriesAccess={x.hasSeriesAccess}
              />
            }
            toggleColor="transparent"
            buttonClassName="btn-sm"
          >
            <div className="ps-4">
              <TaskProfileDetailListTaskItemComponents
                taskId={x.taskId}
                components={x.components}
              />
            </div>
          </Accordion>
        ))}
      </div>
    </Accordion>
  );
};

export default TaskProfileDetailListTask;
