import { QueryObserverResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import integrationApiHooks from '../api/integrationApi/integrationApiHooks';
import batchUserStatisticsExcelReport from '../api/responses/batchUserStatisticsExcelReport';
import UserIntegrationStatisticsType from '../models/UserIntegrationStatisticsType';
import { DateFilters } from '../pages/IntegrationHistory/IntegrationHistoryContainer';

interface UseGetIntegrationHistoryExcelReportResult {
  data: batchUserStatisticsExcelReport;
  isLoading: boolean;
  isFetching: boolean;
  refetch: () => Promise<QueryObserverResult<batchUserStatisticsExcelReport, AxiosError>>;
}

function useGetIntegrationHistoryExcelReport(
  companyId: number,
  integrationId: number,
  dateFilters: DateFilters,
  onSuccess: (data: batchUserStatisticsExcelReport) => void
): UseGetIntegrationHistoryExcelReportResult {
  let {
    data = { ErrorUsers: [], NewUsers: [], UpdatedUsers: [] },
    isLoading,
    isFetching,
    refetch,
  } = integrationApiHooks.useGetIntegrationHistoryExcelReport(
    {
      companyId,
      integrationId,
      dateFilters,
      userType: UserIntegrationStatisticsType.newUsers,
    },
    onSuccess
  );

  return { data, isLoading, isFetching, refetch };
}

export default useGetIntegrationHistoryExcelReport;
