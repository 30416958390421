import React, { ChangeEvent } from 'react';
import Form from 'react-bootstrap/Form';
import { DateRangeFilter as DateRangeFilterType } from '../../api/requests/getActivitiesReportRequest';
import commonText from '../../constants/commonText';
import testIds from '../../constants/testIds';
import Button from '../Buttons/Button/Button';
import DateRangeFilter from '../DateRangeFilter/DateRangeFilter';

export interface DateFilterContainerProps {
  completedDayFilter: DateRangeFilterType;
  expirationDateFilter: DateRangeFilterType;
  lastAccessedFilter: DateRangeFilterType;
  expiresInDays: number;
  hideLastAccessedFilter?: boolean;
  onClear: () => void;
  onDataChange: (changes: object) => void;
}

const DateFilterContainer: React.FC<DateFilterContainerProps> = ({
  completedDayFilter,
  expirationDateFilter,
  expiresInDays,
  lastAccessedFilter,
  hideLastAccessedFilter = false,
  onClear,
  onDataChange,
}) => {
  const isButtonDisabled = () =>
    completedDayFilter.From === '' &&
    completedDayFilter.To === '' &&
    expirationDateFilter.From === '' &&
    expirationDateFilter.To === '' &&
    lastAccessedFilter.From === '' &&
    lastAccessedFilter.To === '' &&
    expiresInDays === 0;

  const handleExpiresInDaysChange = (e: ChangeEvent<HTMLInputElement>) => {
    let changeValue = e.target.value;
    if (changeValue === '') changeValue = '0';
    onDataChange({ expiresInDays: Number.parseInt(changeValue, 10) });
  };

  return (
    <div data-testid={testIds.dateFilterContainer()}>
      <div className="mb-1 mt-2">
        <Button size="sm" variant="transparent" disabled={isButtonDisabled()} onClick={onClear}>
          Clear All
        </Button>
      </div>
      <div className="mx-3 date-filter-container">
        <DateRangeFilter
          label={commonText.completed}
          from={completedDayFilter.From}
          to={completedDayFilter.To}
          gridArea="completed"
          onFromChange={(value: string) =>
            onDataChange({ completedDate: { ...completedDayFilter, From: value } })
          }
          onToChange={(value: string) =>
            onDataChange({ completedDate: { ...completedDayFilter, To: value } })
          }
        />
        <DateRangeFilter
          label={commonText.expires}
          from={expirationDateFilter.From}
          to={expirationDateFilter.To}
          gridArea="expired"
          onFromChange={(value: string) =>
            onDataChange({ expirationDate: { ...expirationDateFilter, From: value } })
          }
          onToChange={(value: string) =>
            onDataChange({ expirationDate: { ...expirationDateFilter, To: value } })
          }
        />
        <>
          <div className="expires-days-label align-self-center">{commonText.expiresInDays}</div>
          <div className="expires-days-value">
            <Form.Control
              className="bg-white"
              type="number"
              value={expiresInDays}
              placeholder={commonText.expires}
              min="0"
              onChange={handleExpiresInDaysChange}
            />
          </div>
        </>
        {!hideLastAccessedFilter && (
          <DateRangeFilter
            label={commonText.lastAccessed}
            from={lastAccessedFilter?.From}
            to={lastAccessedFilter?.To}
            gridArea="last-accessed"
            onFromChange={(value: string) =>
              onDataChange({ lastAccessedDate: { ...lastAccessedFilter, From: value } })
            }
            onToChange={(value: string) =>
              onDataChange({ lastAccessedDate: { ...lastAccessedFilter, To: value } })
            }
          />
        )}
      </div>
    </div>
  );
};

export default DateFilterContainer;
