import React, { useCallback, useRef, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import useIsOverflowing from '../../hooks/useIsOverflowing';
import { OverlayPlacement } from '../../models/OverlayPlacement';

interface OverflowTooltipRenderProps<T extends HTMLElement> {
  ref: React.LegacyRef<T>;
  style: React.CSSProperties;
}

export interface OverflowTooltipProps<T extends HTMLElement = HTMLElement> {
  tooltipText: string;
  placement?: OverlayPlacement;
  children: (props: OverflowTooltipRenderProps<T>, tooltipText: string) => React.ReactElement;
}

function OverflowTooltip<T extends HTMLElement = HTMLElement>({
  tooltipText,
  placement = 'right',
  children,
}: OverflowTooltipProps<T>): React.ReactElement {
  const textElementRef = useRef<T>(null);
  const isOverflowing = useIsOverflowing(textElementRef);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = useCallback(() => setTooltipOpen(isOpen => !isOpen), []);

  return (
    <>
      <OverlayTrigger
        trigger={['hover', 'focus']}
        show={tooltipOpen && isOverflowing}
        placement={placement}
        onToggle={toggleTooltip}
        overlay={<Tooltip style={{ position: 'fixed' }}>{tooltipText}</Tooltip>}
      >
        {({ ref, ...tooltipProps }) => (
          //  eslint-disable-next-line react/jsx-props-no-spreading
          <div ref={ref} {...tooltipProps}>
            {children(
              {
                ref: textElementRef,
                style: {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              },
              tooltipText
            )}
          </div>
        )}
      </OverlayTrigger>
    </>
  );
}

export default OverflowTooltip;
