/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { ContainerProps } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import useSwipe from '../../../hooks/useSwipe';

export interface SwipeContainerProps extends ContainerProps {
  className?: string;
  onSwipeLeft: () => void;
  onSwipeRight: () => void;
  children?: React.ReactNode;
}

const SwipeContainer: React.FC<SwipeContainerProps> = ({
  onSwipeLeft,
  onSwipeRight,
  children,
  className = '',
  ...containerProps
}) => {
  const bindSwipeHandlers = useSwipe({ onSwipeLeft, onSwipeRight });

  return (
    <Container
      className={`${className} touch-action-pan-y`}
      {...containerProps}
      {...bindSwipeHandlers()}
      fluid
    >
      {children}
    </Container>
  );
};

export default SwipeContainer;
