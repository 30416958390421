import React, { useCallback, useEffect, useState } from 'react';
import { CustomViewConnection } from '../../../api/responses/customViewConnection';
import { CustomViewReport } from '../../../api/responses/customViewReport';
import messages from '../../../constants/messages';
import useMediaQuery, { MediaQuery } from '../../../hooks/useMediaQuery';
import { Dimensions } from '../../../models/dimensions';
import { TaskProfileReportData } from '../../../models/taskProfileReportData';
import { PolicyServiceResponse } from '../../../utils/converter/policyConverter';
import CardGrid from '../../CardGrid/CardGrid';
import EmptyState from '../../EmptyState/EmptyState';
import CustomReportCard from '../Card/CustomReportCard';

export interface CustomReportCardGridProps {
  data: CustomViewReport<TaskProfileReportData>[];
  isLoading: boolean;
  isError: boolean;
  customViewConnectionsData?: Record<number, CustomViewConnection>;
  onResize: (numCardsShown: number) => void;
  onCardClick: (id: number) => void;
  policies: Pick<PolicyServiceResponse, 'canViewCustomViewConnections' | 'canCreateCustomView'>;
}

const gridCardDimensions = {
  [MediaQuery.xs]: { width: 220, height: 190 },
  [MediaQuery.sm]: { width: 370, height: 190 },
};

const CustomReportCardGrid: React.FC<CustomReportCardGridProps> = ({
  data,
  isLoading,
  isError,
  customViewConnectionsData,
  onResize,
  onCardClick,
  policies,
}) => {
  const [emptyStateMessage, setEmptyStateMessage] = useState<string>(
    getEmptyStateMessage(data.length, isLoading, isError, policies.canCreateCustomView)
  );
  const isSmallOrLarger = useMediaQuery(MediaQuery.sm);
  const [cardDimensions, setCardDimensions] = useState<Dimensions>(
    gridCardDimensions[MediaQuery.sm]
  );

  useEffect(() => {
    const dimensions = isSmallOrLarger
      ? gridCardDimensions[MediaQuery.sm]
      : gridCardDimensions[MediaQuery.xs];
    setCardDimensions(dimensions);
  }, [isSmallOrLarger]);

  useEffect(() => {
    setEmptyStateMessage(
      getEmptyStateMessage(data.length, isLoading, isError, policies.canCreateCustomView)
    );
  }, [data.length, isError, isLoading, policies.canCreateCustomView]);

  const renderCard = useCallback(
    (cardData: CustomViewReport<TaskProfileReportData>) => (
      <CustomReportCard
        customViewId={cardData.id}
        isLoading={isLoading}
        connectedUsers={customViewConnectionsData?.[cardData.id]?.connectedUsers ?? []}
        reportName={cardData.name}
        reportData={cardData.reportData}
        onClick={() => onCardClick(cardData.id)}
        canViewCustomViewConnections={policies.canViewCustomViewConnections}
      />
    ),
    [customViewConnectionsData, isLoading, onCardClick, policies.canViewCustomViewConnections]
  );

  return (
    <>
      {emptyStateMessage.length > 0 && <EmptyState message={emptyStateMessage} />}
      <CardGrid
        minWidth={cardDimensions.width}
        minHeight={cardDimensions.height}
        data={data}
        onResize={onResize}
        render={renderCard}
      />
    </>
  );
};

function getEmptyStateMessage(
  dataLength: number,
  isLoading: boolean,
  isError: boolean,
  canViewCustomViewConnections: boolean
): string {
  let message = '';
  if (isLoading) {
    message = messages.loading;
  } else if (isError) {
    message = messages.error;
  } else if (dataLength <= 0) {
    message = canViewCustomViewConnections ? messages.selectToCreateView : messages.noViews;
  }
  return message;
}

export default CustomReportCardGrid;
