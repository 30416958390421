import React from 'react';
import testIds from '../../../../constants/testIds';
import { CompletionStatus } from '../../../CompletionStatus/CompletionStatus';
import { FaExclamationCircle } from 'react-icons/fa';

interface TaskProfileDetailListTaskItemProps {
  id: number;
  name: string;
  isComplete: boolean;
  hasSeriesAccess: boolean;
}

const TaskProfileDetailListTaskItem: React.FC<TaskProfileDetailListTaskItemProps> = ({
  id,
  name,
  isComplete,
  hasSeriesAccess,
}) => (
  <div
    className="d-inline-flex justify-content-between align-items-center border-0 w-100 text-start pb-0 text-dark fw-bold"
    data-testid={testIds.taskProfileDetailListTask(id)}
  >
    <div className="px-2 me-1">{name}</div>
    {!hasSeriesAccess && <div style={{ color: 'red' }}>Needs Series Access</div>}
    <span className="flex-shrink-0 text-end" style={{ width: '4rem' }}>
      {!hasSeriesAccess && <FaExclamationCircle size={26} color="red" />}
      <CompletionStatus isCompleted={isComplete} />
    </span>
  </div>
);

export default TaskProfileDetailListTaskItem;
