import React from 'react';
import RBDropdown from 'react-bootstrap/Dropdown';
import Spinner from 'react-bootstrap/Spinner';
import testIds from '../../constants/testIds';

export interface DropdownProps<T> {
  items: T[];
  emptyListLabel: string;
  isLoading?: boolean;
  disabled?: boolean;
  title: string;
  itemKey: (item: T) => string | number;
  itemLabel: (item: T) => string;
  itemSorter?: (left: T, right: T) => number;
  onItemSelect: (item: T) => void;
  variant: string;
  toogleClassName?: string;
  dropdownTestId: string;
  selectedItem: string | number | undefined;
}

interface RefProps {
  children?: React.ReactNode;
  style: React.CSSProperties;
  className: string;
  labeledBy: string;
}

const Dropdown = <T,>({
  items,
  onItemSelect,
  emptyListLabel,
  isLoading = false,
  itemKey,
  itemLabel,
  itemSorter = () => 1,
  disabled = false,
  title,
  selectedItem,
  variant = 'primary',
  toogleClassName = '',
  dropdownTestId = '',
}: DropdownProps<T>): JSX.Element => {
  const dropdownItems: React.ReactNode =
    items.length === 0 ? (
      <RBDropdown.Item>{emptyListLabel}</RBDropdown.Item>
    ) : (
      items.sort(itemSorter).map((item: T) => {
        return (
          <RBDropdown.Item
            eventKey={itemKey(item)}
            onClick={() => onItemSelect(item)}
            key={itemKey(item)}
            active={selectedItem === itemKey(item)}
            as="button"
          >
            {itemLabel(item)}
          </RBDropdown.Item>
        );
      })
    );

  const CustomMenu = React.forwardRef<HTMLDivElement, RefProps>(
    ({ className, style, children, labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
          data-testid={testIds.dropdownMenu()}
        >
          <div style={{ maxHeight: '300px', overflow: 'auto' }}>{children}</div>
        </div>
      );
    }
  );

  return (
    <RBDropdown>
      <RBDropdown.Toggle
        disabled={disabled}
        className={toogleClassName}
        variant={variant}
        data-testid={`${testIds.dropdownMenuToggle()}${dropdownTestId}`}
      >
        {title}
      </RBDropdown.Toggle>
      <RBDropdown.Menu as={CustomMenu} labeledBy="dropdown" style={{ zIndex: 1100 }}>
        {isLoading && <Spinner className="m-1" role="status" />}
        {!isLoading && dropdownItems}
      </RBDropdown.Menu>
    </RBDropdown>
  );
};

export default Dropdown;
