import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import apiHooks from '../../api/apiHooks';
import { GetActivitiesReportRequest } from '../../api/requests/getActivitiesReportRequest';
import useActiveEmployeeStatus from '../../hooks/useActiveEmployeeStatus';
import useAssigners from '../../hooks/useAssigners';
import useCompany from '../../hooks/useCompany';
import useCompanyId from '../../hooks/useCompanyId';
import useCompanySeriesTasks from '../../hooks/useCompanySeriesTasks';
import useCompanyTypes from '../../hooks/useCompanyTypes';
import useCostCenters from '../../hooks/useCostCenters';
import useDepartments from '../../hooks/useDepartments';
import useEmployeesAll from '../../hooks/useEmployeesAll';
import useEvaluators from '../../hooks/useEvaluators';
import useRoles from '../../hooks/useRoles';
import useSeries from '../../hooks/useSeries';
import useStatus from '../../hooks/useStatus';
import useSupervisors from '../../hooks/useSupervisors';
import useUserInformation from '../../hooks/useUserInformation';
import useWorkLocations from '../../hooks/useWorkLocations';
import usePolicyService from '../../policyService/usePolicyService';
import ActivitiesReport, { AffidavitModalInformation, ToastInformation } from './ActivitiesReport';

const ActivitiesReportContainer: React.FC = () => {
  const { companyId } = useCompanyId();
  const { isLoading, company } = useCompany();
  const { isLoading: isUserLoading, user } = useUserInformation();
  const policyService = usePolicyService();
  const [request, setRequestData] = useState<GetActivitiesReportRequest>({
    companyId,
    sortBy: [
      { key: 'User_Full_Name', isDesc: true },
      { key: 'Series_Name', isDesc: true },
      { key: 'Activity_Name', isDesc: true },
      { key: 'Activity_Completion_Date', isDesc: true },
    ],
  });
  const [toastInformation, setToastInformation] = useState<ToastInformation>({
    showToast: false,
    isSuccessful: false,
  });
  const [affidavitModalInformation, setAffidavitModalInformation] =
    useState<AffidavitModalInformation>({ affidavitData: undefined, isOpen: false });

  const [affidavitId, setAffidavitId] = useState<string | undefined>(undefined);

  const onRequestChange = (changes: GetActivitiesReportRequest) => {
    setRequestData(changes);
  };
  const {
    data: activityData,
    isFetching: isActivitiesReportFetching,
    isLoading: isActivitiesReportLoading,
    fetchNextPage,
    isError,
    hasNextPage,
  } = apiHooks.useGetActivitiesReport(request, (error: AxiosError) => {
    setToastInformation({ showToast: true, isSuccessful: false, error });
  });

  const downloadExcel = apiHooks.useGetActivitiesReportExcel(request, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      setToastInformation({ showToast: true, isSuccessful: true });
    },
    onError: () => {
      setToastInformation({ showToast: true, isSuccessful: false });
    },
  });

  const getAffidavitInformationHook = apiHooks.useGetActivityAffidavitInformation(affidavitId, {
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: data => {
      setAffidavitModalInformation({ affidavitData: data, isOpen: true });
    },
    onError: (error: AxiosError) => {
      setToastInformation({ showToast: true, isSuccessful: false, error });
      setAffidavitModalInformation({ affidavitData: undefined, isOpen: false });
      setAffidavitId('');
    },
  });

  useEffect(() => {
    if (affidavitId !== undefined && affidavitId !== '') {
      getAffidavitInformationHook.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affidavitId]);

  return (
    <ActivitiesReport
      isCompanyLoading={isLoading}
      company={company}
      user={user}
      isUserLoading={isUserLoading}
      policies={policyService.policies()}
      activityData={activityData?.pages}
      activitiesReportRequest={request}
      isActivitiesReportLoading={isActivitiesReportFetching || isActivitiesReportLoading}
      onActivitiesReportRequestChange={onRequestChange}
      onPageChange={fetchNextPage}
      useAssigners={useAssigners}
      useDepartments={useDepartments}
      useCompanyTypes={useCompanyTypes}
      useCompanySeriesTasks={useCompanySeriesTasks}
      useCostCenters={useCostCenters}
      useEvaluators={useEvaluators}
      useRoles={useRoles}
      useStatus={useStatus}
      useSupervisors={useSupervisors}
      useWorkLocations={useWorkLocations}
      useSeries={useSeries}
      downloadExcel={downloadExcel}
      toastInformation={toastInformation}
      setToastInformation={setToastInformation}
      useEmployeesAll={useEmployeesAll}
      useActiveEmployeeStatus={useActiveEmployeeStatus}
      requestError={isError}
      affidavitModalInformation={affidavitModalInformation}
      setAffidavitModalInformation={setAffidavitModalInformation}
      setAffidavitId={setAffidavitId}
      affidavitLoading={getAffidavitInformationHook.isFetching}
      hasNextPage={hasNextPage || false}
    />
  );
};

export default ActivitiesReportContainer;
