import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import RBTab from 'react-bootstrap/Tab';
import { MdClose } from 'react-icons/md';
import {
  TaskProfileSortByOption,
  TaskProfileSortByOptions,
} from '../../../api/requests/companyQualificationRequest';
import { UserAccessResponse } from '../../../api/responses/userAccessResponse';
import commonText from '../../../constants/commonText';
import messages from '../../../constants/messages';
import { DisplayItemWithCustomViewAccessType } from '../../../models/displayItemWithCustomViewAccessType';
import FilterTabNameId from '../../../models/FilterTabNameId';
import FilterTabNames from '../../../models/FilterTabNames';
import { ScrollingOptions } from '../../../models/scrollingOptions';
import { TaskProfileReportData } from '../../../models/taskProfileReportData';
import { UseDisplayItemsResponse } from '../../../models/useDisplayItemsResponse';
import { AvailablePolicies } from '../../../policyService/policyEnforcer/policyEnforcer';
import { Values } from '../../../types/values';
import sortOptionParser from '../../../utils/parsers/sortOptionParser';
import ConfirmButton from '../../ConfirmButton/ConfirmButton';
import Drawer from '../../Layout/Drawer/Drawer';
import Tab from '../../Layout/Tab/Tab';
import TabPanel from '../../Layout/TabPanel/TabPanel';
import TabWrapper from '../../Layout/TabWrapper/TabWrapper';
import ShareTabMultiPartSelectContainer from '../ShareTabMultipartSelect/ShareTabMultiPartSelectContainer';
import {
  TaskProfileCardDisplayOption,
  TaskProfileCardFeatures,
} from './taskProfileCardDisplayOptions';
import {
  getPrimaryDataPointCardDisplayOption,
  TaskProfileCardPrimaryDataPoints,
} from './taskProfileCardPrimaryDataPoints';
import {
  TaskProfileDetailDisplayOption,
  TaskProfileDetailFeatures,
} from './taskProfileDetailDisplayOptions';
import TaskProfileMultiPartSelectContainer from './TaskProfileMultiPartSelectContainer';
import TaskProfileReportDrawerTabAccess from './TaskProfileReportDrawerTabAccess';
import TaskProfileReportDrawerTabCards from './TaskProfileReportDrawerTabCards';
import TaskProfileReportDrawerTabMessage from './TaskProfileReportDrawerTabMessage';
import TaskProfileReportDrawerTabView from './TaskProfileReportDrawerTabView';
import { TaskProfileDetailModes } from './taskProfileDetailDisplayModes';

export const TabNames = {
  View: { id: 'View', name: 'View' },
  Cards: { id: 'Cards', name: 'Cards' },
  Message: { id: 'Message', name: 'Message' },
  Access: { id: 'Access', name: 'Access' },
} as const;

type TabNameId = Values<typeof TabNames>['id'];

const sortByOptions = Object.values(TaskProfileSortByOptions);

export interface TaskProfileReportDrawerProps {
  companyId: number;
  reportName: string;
  reportData: TaskProfileReportData;
  sharedUserIds: UserAccessResponse[];
  messageOfTheDayImageUrl: string;
  onReportNameChange: (reportName: string) => void;
  onReportDataChange: (reportData: TaskProfileReportData) => void;
  onSharedUserIds: (sharedUserIds: UserAccessResponse[]) => void;
  onMessageOfTheDayImageChange: (image: File | undefined) => void;
  onSave: () => void;
  isSaveLoading: boolean;
  isSaveError: boolean;
  isSaveSuccess: boolean;
  canDelete: boolean;
  onDelete: () => void;
  isDeleteLoading: boolean;
  isDeleteError: boolean;
  isDeleteSuccess: boolean;
  expanded: boolean;
  onDrawerChange: () => void;
  policies: AvailablePolicies;
  useCostCenters: (companyId: number, text: string) => UseDisplayItemsResponse;
  useDepartments: (companyId: number, text: string) => UseDisplayItemsResponse;
  useEmployees: (companyId: number, text: string) => UseDisplayItemsResponse;
  useJobs: (companyId: number, text: string) => UseDisplayItemsResponse;
  useRoles: (companyId: number, text: string) => UseDisplayItemsResponse;
  useSupervisors: (companyId: number, text: string) => UseDisplayItemsResponse;
  useWorkLocations: (companyId: number, text: string) => UseDisplayItemsResponse;
  useSharedUsers: (
    companyId: number,
    text: string
  ) => UseDisplayItemsResponse<DisplayItemWithCustomViewAccessType>;
}

const TaskProfileReportDrawer: React.FC<TaskProfileReportDrawerProps> = ({
  companyId,
  reportName,
  reportData,
  sharedUserIds,
  messageOfTheDayImageUrl,
  onReportNameChange,
  onReportDataChange,
  onSharedUserIds,
  onMessageOfTheDayImageChange,
  onSave,
  isSaveLoading,
  isSaveError,
  isSaveSuccess,
  canDelete,
  onDelete,
  isDeleteLoading,
  isDeleteError,
  isDeleteSuccess,
  expanded,
  onDrawerChange,
  policies,
  useCostCenters,
  useDepartments,
  useEmployees,
  useJobs,
  useRoles,
  useSupervisors,
  useWorkLocations,
  useSharedUsers,
}) => {
  const [activeTab, setActiveTab] = useState<TabNameId>(TabNames.View.id);
  const [activeFilterTab, setActiveFilterTab] = useState<FilterTabNameId>(
    FilterTabNames.employees.id
  );
  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(() => {
    setShowAlert(isSaveSuccess || isSaveError || isDeleteSuccess || isDeleteError);
  }, [isSaveSuccess, isSaveError, isDeleteSuccess, isDeleteError]);

  const [employeeSearchText, setEmployeeSearchText] = useState('');
  const employees: UseDisplayItemsResponse = useEmployees(companyId, employeeSearchText);

  const handleEmployeeIdsChange = (displayItems: number[]) => {
    onReportDataChange({
      ...reportData,
      userIds: displayItems,
    });
  };

  const [costCenterSearchText, setCostCenterSearchText] = useState('');
  const costCenters: UseDisplayItemsResponse = useCostCenters(companyId, costCenterSearchText);

  const handleCostCenterIdsChange = (displayItems: number[]) => {
    onReportDataChange({
      ...reportData,
      costCenterIds: displayItems,
    });
  };

  const [departmentSearchText, setDepartmentSearchText] = useState('');
  const departments: UseDisplayItemsResponse = useDepartments(companyId, departmentSearchText);

  const handleDepartmentIdsChange = (displayItems: number[]) => {
    onReportDataChange({
      ...reportData,
      departmentIds: displayItems,
    });
  };

  const [jobsSearchText, setJobsSearchText] = useState('');
  const jobs: UseDisplayItemsResponse = useJobs(companyId, jobsSearchText);

  const handleJobIdsChange = (displayItems: number[]) => {
    onReportDataChange({
      ...reportData,
      jobIds: displayItems,
    });
  };

  const [roleSearchText, setRoleSearchText] = useState('');
  const roles: UseDisplayItemsResponse = useRoles(companyId, roleSearchText);

  const handleRoleIdsChange = (displayItems: number[]) => {
    onReportDataChange({
      ...reportData,
      roleIds: displayItems,
    });
  };

  const [supervisorSearchText, setSupervisorSearchText] = useState('');
  const supervisors: UseDisplayItemsResponse = useSupervisors(companyId, supervisorSearchText);
  const handleSupervisorIdsChange = (displayItems: number[]) => {
    onReportDataChange({
      ...reportData,
      supervisorIds: displayItems,
    });
  };

  const [workLocationSearchText, setWorkLocationSearchText] = useState('');
  const workLocations: UseDisplayItemsResponse = useWorkLocations(
    companyId,
    workLocationSearchText
  );
  const handleWorkLocationIdsChange = (displayItems: number[]) => {
    onReportDataChange({
      ...reportData,
      workLocationIds: displayItems,
    });
  };

  function handleActiveFilterTabChange(selectedFilterTabId: FilterTabNameId) {
    setActiveFilterTab(selectedFilterTabId);
  }

  const handleSortByOptionChange = (selectedSortByOption: TaskProfileSortByOption) => {
    onReportDataChange({ ...reportData, sortBy: selectedSortByOption.sortByParams });
  };

  const handlePrimaryDataPointChange = (primaryDataPoint: TaskProfileCardPrimaryDataPoints) => {
    const cardDisplayOption = getPrimaryDataPointCardDisplayOption(primaryDataPoint);
    const cardDisplayOptions: TaskProfileCardFeatures = {
      ...reportData.cardDisplayOptions,
      [cardDisplayOption.id]: true,
    };

    onReportDataChange({ ...reportData, primaryDataPoint, cardDisplayOptions });
  };

  const handleCardDisplayOptionChange = (selectedOption: TaskProfileCardDisplayOption) => {
    const cardDisplayOptions: TaskProfileCardFeatures = {
      ...reportData.cardDisplayOptions,
      [selectedOption.id]: !reportData.cardDisplayOptions[selectedOption.id],
    };

    onReportDataChange({ ...reportData, cardDisplayOptions });
  };

  const handleDetailDisplayModeChange = (selectedOption: TaskProfileDetailModes) => {
    let detailDisplayModes: TaskProfileDetailModes = {
      ...reportData.detailDisplayModes,
      ...selectedOption,
    };

    onReportDataChange({ ...reportData, detailDisplayModes });
  };

  const handleDetailDisplayOptionChange = (selectedOption: TaskProfileDetailDisplayOption) => {
    const detailDisplayOptions: TaskProfileDetailFeatures = {
      ...reportData.detailDisplayOptions,
      [selectedOption.id]: !reportData.detailDisplayOptions[selectedOption.id],
    };

    onReportDataChange({ ...reportData, detailDisplayOptions });
  };

  const handleMessageOfTheDayTitleChange = (title: string) => {
    onReportDataChange({
      ...reportData,
      messageOfTheDay: { ...reportData.messageOfTheDay, title },
    });
  };

  const handleMessageOfTheDayDescriptionChange = (description: string) => {
    onReportDataChange({
      ...reportData,
      messageOfTheDay: { ...reportData.messageOfTheDay, description },
    });
  };

  const handleMessageOfTheDayImageChange = (image: File | undefined) => {
    onMessageOfTheDayImageChange(image);

    if (image === undefined) {
      onReportDataChange({
        ...reportData,
        messageOfTheDay: { ...reportData.messageOfTheDay, image: '' },
      });
    }
  };

  const handleMessageOfTheDayIsEnabledChange = (isEnabled: boolean) => {
    onReportDataChange({
      ...reportData,
      messageOfTheDay: { ...reportData.messageOfTheDay, isEnabled },
    });
  };

  const handleMessageOfTheDayIsShownWhenInactiveChange = (isShownWhenInactive: boolean) => {
    onReportDataChange({
      ...reportData,
      messageOfTheDay: { ...reportData.messageOfTheDay, isShownWhenInactive },
    });
  };

  const handleScrollingOptionChange = (scrollingOptions: ScrollingOptions) => {
    onReportDataChange({
      ...reportData,
      scrollingOptions: {
        ...scrollingOptions,
      },
    });
  };

  const hideAlert = () => {
    setShowAlert(false);
  };

  return (
    <Drawer
      title={
        policies.canCreateOrUpdateTaskProfile ? (
          <Form.Control
            aria-label="Report Name"
            placeholder="Report name..."
            className="bg-white"
            value={reportName}
            onChange={e => onReportNameChange(e.target.value)}
          />
        ) : null
      }
      expanded={expanded}
      onDrawerChange={onDrawerChange}
    >
      <Row className="flex-grow-1 mh-100 h-100 overflow-hidden d-flex flex-column">
        <Container fluid className="h-100">
          <Row className="h-100">
            <Col className="h-100">
              <RBTab.Container defaultActiveKey={activeTab}>
                <Row>
                  <TabWrapper className="bg-white w-100" isVertical={false}>
                    <Tab
                      setActiveTab={setActiveTab}
                      activeTabId={activeTab}
                      tabId={TabNames.View.id}
                      panelId={`${TabNames.View.id}-panel`}
                    >
                      {TabNames.View.name}
                    </Tab>
                    <Tab
                      setActiveTab={setActiveTab}
                      activeTabId={activeTab}
                      tabId={TabNames.Cards.id}
                      panelId={`${TabNames.Cards.id}-panel`}
                    >
                      {TabNames.Cards.name}
                    </Tab>
                    <Tab
                      setActiveTab={setActiveTab}
                      activeTabId={activeTab}
                      tabId={TabNames.Message.id}
                      panelId={`${TabNames.Message.id}-panel`}
                    >
                      {TabNames.Message.name}
                    </Tab>
                    <Tab
                      setActiveTab={setActiveTab}
                      activeTabId={activeTab}
                      tabId={TabNames.Access.id}
                      panelId={`${TabNames.Access.id}-panel`}
                    >
                      {TabNames.Access.name}
                    </Tab>
                  </TabWrapper>
                </Row>
                <Container fluid style={{ height: 'calc(100% - 54px)' }}>
                  <Row className="h-100">
                    <RBTab.Content className="overflow-hidden h-100">
                      <TabPanel
                        className="d-flex flex-column overflow-hidden flex-nowrap h-100"
                        tabId={TabNames.View.id}
                        isActiveTab={activeTab === TabNames.View.id}
                      >
                        <TaskProfileReportDrawerTabView
                          sortByOptions={sortByOptions}
                          activeSortByOption={
                            sortOptionParser.parse(reportData.sortBy) ??
                            TaskProfileSortByOptions.lastnameASC
                          }
                          onSortByOptionChange={handleSortByOptionChange}
                          employeesContent={
                            <TaskProfileMultiPartSelectContainer
                              useDisplayItemResponse={employees}
                              onChange={handleEmployeeIdsChange}
                              selectedIds={reportData.userIds}
                              emptyListText={messages.noAvailableMessage(commonText.employees)}
                              onSearchTextChange={setEmployeeSearchText}
                              tabId={FilterTabNames.employees.id}
                            />
                          }
                          costCentersContent={
                            <TaskProfileMultiPartSelectContainer
                              useDisplayItemResponse={costCenters}
                              onChange={handleCostCenterIdsChange}
                              selectedIds={reportData.costCenterIds}
                              emptyListText={messages.noAvailableMessage(commonText.costCenters)}
                              onSearchTextChange={setCostCenterSearchText}
                              tabId={FilterTabNames.costCenters.id}
                            />
                          }
                          departmentsContent={
                            <TaskProfileMultiPartSelectContainer
                              useDisplayItemResponse={departments}
                              onChange={handleDepartmentIdsChange}
                              selectedIds={reportData.departmentIds}
                              emptyListText={messages.noAvailableMessage(commonText.departments)}
                              onSearchTextChange={setDepartmentSearchText}
                              tabId={FilterTabNames.departments.id}
                            />
                          }
                          jobsContent={
                            <TaskProfileMultiPartSelectContainer
                              useDisplayItemResponse={jobs}
                              onChange={handleJobIdsChange}
                              selectedIds={reportData.jobIds}
                              emptyListText={messages.noAvailableMessage(commonText.jobs)}
                              onSearchTextChange={setJobsSearchText}
                              tabId={FilterTabNames.jobs.id}
                            />
                          }
                          rolesContent={
                            <TaskProfileMultiPartSelectContainer
                              useDisplayItemResponse={roles}
                              onChange={handleRoleIdsChange}
                              selectedIds={reportData.roleIds}
                              emptyListText={messages.noAvailableMessage(commonText.roles)}
                              onSearchTextChange={setRoleSearchText}
                              tabId={FilterTabNames.roles.id}
                            />
                          }
                          supervisorsContent={
                            <TaskProfileMultiPartSelectContainer
                              useDisplayItemResponse={supervisors}
                              onChange={handleSupervisorIdsChange}
                              selectedIds={reportData.supervisorIds}
                              emptyListText={messages.noAvailableMessage(commonText.supervisors)}
                              onSearchTextChange={setSupervisorSearchText}
                              tabId={FilterTabNames.supervisors.id}
                            />
                          }
                          workLocationsContent={
                            <TaskProfileMultiPartSelectContainer
                              useDisplayItemResponse={workLocations}
                              onChange={handleWorkLocationIdsChange}
                              selectedIds={reportData.workLocationIds}
                              emptyListText={messages.noAvailableMessage(commonText.workLocations)}
                              onSearchTextChange={setWorkLocationSearchText}
                              tabId={FilterTabNames.workLocations.id}
                            />
                          }
                          selectedEmployeesCount={reportData.userIds.length}
                          selectedCostCentersCount={reportData.costCenterIds.length}
                          selectedDepartmentsCount={reportData.departmentIds.length}
                          selectedJobsCount={reportData.jobIds.length}
                          selectedRolesCount={reportData.roleIds.length}
                          selectedSupervisorsCount={reportData.supervisorIds.length}
                          selectedWorkLocationsCount={reportData.workLocationIds.length}
                          onActiveTabChanged={handleActiveFilterTabChange}
                          activeTabId={activeFilterTab}
                        />
                      </TabPanel>
                      <TabPanel
                        tabId={TabNames.Cards.id}
                        isActiveTab={activeTab === TabNames.Cards.id}
                      >
                        <TaskProfileReportDrawerTabCards
                          policies={policies}
                          activePrimaryDataPoint={reportData.primaryDataPoint}
                          activeCardDisplayOptions={reportData.cardDisplayOptions}
                          activeDetailDisplayOptions={reportData.detailDisplayOptions}
                          activeDetailDisplayModes={reportData.detailDisplayModes}
                          scrollingOptions={reportData.scrollingOptions}
                          onPrimaryDataPointChange={handlePrimaryDataPointChange}
                          onCardDisplayOptionChange={handleCardDisplayOptionChange}
                          onDetailDisplayOptionChange={handleDetailDisplayOptionChange}
                          onDetailDisplayModeChange={handleDetailDisplayModeChange}
                          onScrollingOptionChange={handleScrollingOptionChange}
                        />
                      </TabPanel>
                      <TabPanel
                        tabId={TabNames.Message.id}
                        isActiveTab={activeTab === TabNames.Message.id}
                      >
                        <TaskProfileReportDrawerTabMessage
                          title={reportData.messageOfTheDay.title}
                          description={reportData.messageOfTheDay.description}
                          image={messageOfTheDayImageUrl}
                          isEnabled={reportData.messageOfTheDay.isEnabled}
                          isShownWhenInactive={reportData.messageOfTheDay.isShownWhenInactive}
                          onTitleChange={handleMessageOfTheDayTitleChange}
                          onDescriptionChange={handleMessageOfTheDayDescriptionChange}
                          onImageChange={handleMessageOfTheDayImageChange}
                          onIsEnabledChange={handleMessageOfTheDayIsEnabledChange}
                          onIsShownWhenInactiveChange={
                            handleMessageOfTheDayIsShownWhenInactiveChange
                          }
                        />
                      </TabPanel>
                      <TabPanel
                        className="d-flex flex-column overflow-hidden flex-nowrap h-100"
                        tabId={TabNames.Access.id}
                        isActiveTab={activeTab === TabNames.Access.id}
                      >
                        <TaskProfileReportDrawerTabAccess>
                          <ShareTabMultiPartSelectContainer
                            companyId={companyId}
                            useData={useSharedUsers}
                            onChange={onSharedUserIds}
                            selectedUsers={sharedUserIds}
                            emptyListText={messages.noAvailableMessage(commonText.users)}
                          />
                        </TaskProfileReportDrawerTabAccess>
                      </TabPanel>
                    </RBTab.Content>
                  </Row>
                </Container>
              </RBTab.Container>
            </Col>
          </Row>
        </Container>
      </Row>
      <Row className="flex-grow-0 justify-content-center align-items-center d-flex flex-row mt-1">
        <Col className="flex-grow-1">&nbsp;</Col>

        {policies.canCreateOrUpdateTaskProfile ? (
          <Col className="mx-2" xs="auto">
            <Button
              className="d-inline-flex align-items-center justify-content-center"
              variant="primary"
              onClick={onSave}
              disabled={isSaveLoading}
            >
              {isSaveLoading && <Spinner className="me-2" variant="secondary" size="sm" />}
              {commonText.save}
            </Button>
          </Col>
        ) : null}

        <Col className="flex-grow-1">
          {canDelete && policies.canDeleteTaskProfile ? (
            <ConfirmButton
              text={
                isDeleteLoading ? messages.deletingResource(commonText.report) : commonText.delete
              }
              confirmText={messages.deleteResource(commonText.report)}
              disabled={isDeleteLoading}
              onConfirm={onDelete}
            />
          ) : null}
        </Col>
      </Row>
      <Row className="flex-grow-0 justify-content-center align-items-center">
        <Col className="flex-grow-1">
          {isSaveSuccess && (
            <Alert
              onClose={() => hideAlert()}
              className="mt-2 mb-0 alert-sm alert-center"
              variant="success"
              show={
                isSaveSuccess && !isDeleteSuccess && !isDeleteError && !isSaveError && showAlert
              }
            >
              <Alert.Heading as="div" className="d-flex flex-row">
                <div className="flex-grow-1">{messages.actionSuccessful(commonText.save)}</div>
                <div className="flex-grow-0">
                  <MdClose role="button" onClick={hideAlert} />
                </div>
              </Alert.Heading>
            </Alert>
          )}
          {isSaveError && (
            <Alert
              onClose={() => hideAlert()}
              className="mt-2 mb-0 alert-sm alert-center"
              variant="danger"
              show={
                !isSaveSuccess && isSaveError && !isDeleteSuccess && !isDeleteError && showAlert
              }
            >
              <Alert.Heading as="div" className="d-flex flex-row">
                <div className="flex-grow-1">{messages.actionFailed(commonText.save)}</div>
                <div className="flex-grow-0">
                  <MdClose role="button" onClick={hideAlert} />
                </div>
              </Alert.Heading>
            </Alert>
          )}
          {isDeleteSuccess && (
            <Alert
              onClose={() => hideAlert()}
              className="mt-2 mb-0 alert-sm alert-center"
              variant="success"
              show={isDeleteSuccess && showAlert}
            >
              <Alert.Heading as="div" className="d-flex flex-row">
                <div className="flex-grow-1">{messages.actionSuccessful(commonText.delete)}</div>
                <div className="flex-grow-0">
                  <MdClose role="button" onClick={hideAlert} />
                </div>
              </Alert.Heading>
            </Alert>
          )}
          {isDeleteError && (
            <Alert
              onClose={() => hideAlert()}
              className="mt-2 mb-0 alert-sm alert-center"
              variant="danger"
              show={isDeleteError && showAlert}
            >
              <Alert.Heading as="div" className="d-flex flex-row">
                <div className="flex-grow-1">{messages.actionFailed(commonText.delete)}</div>
                <div className="flex-grow-0">
                  <MdClose role="button" onClick={hideAlert} />
                </div>
              </Alert.Heading>
            </Alert>
          )}
        </Col>
      </Row>
    </Drawer>
  );
};

export default TaskProfileReportDrawer;
