import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React from 'react';
import Card from 'react-bootstrap/Card';
import { GetLearningHistoryReportRequest } from '../../../api/requests/getLearningHistoryReport';
import {
  LearningHistoryReport,
  LearningHistoryReportResponse,
} from '../../../api/responses/learningHistoryReportResponse';
import { PaginatedResponse } from '../../../api/responses/paginatedResponse';
import learningHistoryConvert from '../../../api/utils/learningHistoryReportConverter';
import { TableColumnModel } from '../../../models/TableColumnModel';
import { TableSortInformation } from '../../../models/TableSortInformation';
import Table from '../../Table/Table';
import LearningHistoryCollapsibleRow from '../LearningHistoryCollapsibleRow/LearningHistoryCollapsibleRow';

export interface LearningHistoryTableProps {
  learningHistoryData?: PaginatedResponse<LearningHistoryReportResponse>[];
  learningHistoryDataCount?: number;
  learningHistoryRequest: GetLearningHistoryReportRequest;
  isLearningHistoryLoading: boolean;
  requestError: boolean;
  onLearningHistoryRequestChange: (request: GetLearningHistoryReportRequest) => void;
  onPageChange: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<
    PaginatedResponse<LearningHistoryReportResponse>,
    AxiosError
  > | void>;
  setQualificationId: React.Dispatch<string>;
  setTaskName: React.Dispatch<string>;
  hasNextPage: boolean;
}

const LearningHistoryTable: React.FC<LearningHistoryTableProps> = ({
  learningHistoryDataCount,
  learningHistoryData,
  learningHistoryRequest,
  isLearningHistoryLoading,
  requestError,
  onLearningHistoryRequestChange,
  setQualificationId,
  setTaskName,
  onPageChange,
  hasNextPage,
}) => {
  const columns: TableColumnModel[] = [
    {
      name: 'placeholder',
      header: '',
      sortKey: '',
      width: 3,
      value: '',
      isSortable: false,
    },
    {
      name: 'task_Code',
      header: 'Code',
      sortKey: 'task_Code',
      width: 8.5,
      value: 'task_Code',
      isSortable: true,
    },
    {
      name: 'task_Name',
      header: 'Name',
      sortKey: 'task_Name',
      width: 46,
      value: 'task_Name',
      isSortable: true,
    },
    {
      name: 'qualification_Completed',
      header: 'Completed',
      sortKey: 'qualification_Completed',
      width: 8.5,
      value: 'qualification_Completed',
      isSortable: true,
    },
    {
      name: 'qualification_Expires',
      header: 'Expires',
      sortKey: 'qualification_Expires',
      width: 8.5,
      value: 'qualification_Expires',
      isSortable: true,
    },
    {
      name: 'qualification_Status',
      header: 'Status',
      sortKey: 'qualification_Status',
      width: 10,
      value: 'qualification_Status',
      isSortable: true,
    },
    {
      name: 'reason',
      header: 'Reason',
      sortKey: 'reason',
      width: 15.5,
      value: 'reason',
      isSortable: true,
    },
  ];
  const onSortChange = (sortInformation: TableSortInformation[]) => {
    onLearningHistoryRequestChange({
      ...learningHistoryRequest,
      sortBy: sortInformation,
    });
  };
  const handlePageChange = () => {
    onPageChange();
  };

  const rowTemplate = (data: LearningHistoryReport) => {
    if (data === undefined) return null;
    return (
      <div key={data.User_Id}>
        <LearningHistoryCollapsibleRow
          data={data}
          columns={columns}
          setQualificationId={setQualificationId}
          setTaskName={setTaskName}
        />
      </div>
    );
  };
  const itemHeight: number = 54;

  return (
    <Card className="w-100">
      <Card.Body className="d-flex" style={{ height: '800px' }}>
        <Table<LearningHistoryReport, GetLearningHistoryReportRequest>
          variableHeight
          data={learningHistoryConvert(
            learningHistoryData?.reduce(
              (
                accum: LearningHistoryReportResponse[],
                current: PaginatedResponse<LearningHistoryReportResponse>
              ) => [...accum, ...current.value],
              []
            )
          )}
          columns={columns}
          height={768}
          itemHeight={itemHeight}
          numberOfItems={learningHistoryDataCount || 0}
          sortInformation={learningHistoryRequest.sortBy || []}
          requestInformation={learningHistoryRequest}
          visibleRows={16}
          rowTemplate={rowTemplate}
          onSortChange={onSortChange}
          isLoading={isLearningHistoryLoading}
          pageSize={50}
          onPageChange={handlePageChange}
          requestError={requestError}
          hasNextPage={hasNextPage}
          estimateSize={() => 54}
          emptyStateMessage="No Records Available"
        />
      </Card.Body>
    </Card>
  );
};

export default LearningHistoryTable;
