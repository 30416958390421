import React from 'react';
import eventUtils from '../../../utils/eventUtils';
import OverflowTooltip from '../../OverflowTooltip/OverflowTooltip';
import Skeleton from '../../Skeleton/Skeleton';

export interface TaskProfileCardNoteProps {
  note: string;
  isLoading: boolean;
}

const TaskProfileCardNote: React.FC<TaskProfileCardNoteProps> = ({ note, isLoading }) => {
  if (isLoading) {
    return <Skeleton width="4rem" />;
  }

  if (!note || note.length === 0) {
    return null;
  }

  return (
    <OverflowTooltip<HTMLParagraphElement> tooltipText={note}>
      {({ ref, style }) => (
        <p
          className="card-subtitle text-muted mt-0 ps-2 text-end flex-fill"
          ref={ref}
          style={style}
          role="presentation"
          onClick={eventUtils.stopPropagation}
        >
          {note}
        </p>
      )}
    </OverflowTooltip>
  );
};

export default TaskProfileCardNote;
