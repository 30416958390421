import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import testIds from '../../../../constants/testIds';
import { CompletionStatus } from '../../../CompletionStatus/CompletionStatus';
import { FaExclamationCircle } from 'react-icons/fa';
export interface TaskProfileDetailListRoleProps {
  id: number;
  name: string;
  isComplete: boolean;
  hasSeriesAccess: boolean;
}

const TaskProfileDetailListRole: React.FC<TaskProfileDetailListRoleProps> = ({
  id,
  name,
  isComplete,
  hasSeriesAccess,
}) => {
  return (
    <ListGroup.Item
      className="d-inline-flex justify-content-between align-items-center border-0"
      data-testid={testIds.taskProfileDetailListRole(id)}
      role="listitem"
    >
      <div className="d-flex flex-row">
        <div style={{ marginRight: 10 }}>{name}</div>
        {!hasSeriesAccess && <div style={{ color: 'red' }}>Needs Series Access</div>}
      </div>
      <span className="flex-shrink-0">
        {!hasSeriesAccess && <FaExclamationCircle size={26} color="red" />}
        <CompletionStatus isCompleted={isComplete} />
      </span>
    </ListGroup.Item>
  );
};

export default TaskProfileDetailListRole;
