import React from 'react';
import { Card } from 'react-bootstrap';
import { FiSettings } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import commonText from '../../constants/commonText';
import routes from '../../constants/routes';
import CurrentIntegrationsRequest from '../../models/CurrentIntegrationsRequest';
import CurrentIntegrationsResponse from '../../models/CurrentIntegrationsResponse';
import { TableColumnModel } from '../../models/TableColumnModel';
import dateFormatter from '../../utils/dateFormatter';
import Table from '../Table/Table';
import Skeleton from '../Skeleton/Skeleton';

interface CurrentIntegrationsTableProps {
  data: CurrentIntegrationsResponse[];
  isLoading: boolean;
  isError: boolean;
}

const CurrentIntegrationsTable = ({
  data,
  isLoading,
  isError,
}: CurrentIntegrationsTableProps): React.ReactElement => {
  const columns: TableColumnModel[] = [
    {
      isSortable: false,
      header: 'Name',
      name: 'name',
      sortKey: 'Name',
      value: 'Name',
      width: 30,
    },
    {
      isSortable: false,
      header: 'Integration Type',
      name: 'type',
      sortKey: 'Type',
      value: 'Type',
      width: 25,
    },
    {
      isSortable: false,
      header: 'Last Edited',
      name: 'lastEdited',
      sortKey: 'Last_Edited',
      value: 'Last_Edited',
      width: 25,
    },
    {
      isSortable: false,
      header: 'Status',
      name: 'status',
      sortKey: 'Status',
      value: 'isEnabled',
      width: 10,
    },
    {
      isSortable: false,
      header: 'Manage',
      name: 'manage',
      sortKey: 'manage',
      value: 'manage',
      width: 10,
    },
  ];
  const itemHeight = 45;

  function rowTemplate(
    integration: CurrentIntegrationsResponse,
    index: number,
    isRowLoading: boolean
  ) {
    const route =
      integration.Id === -1
        ? routes.userIntegration.url({
            integrationName: integration.IntegrationName,
            integrationType: integration.Type,
          })
        : routes.userIntegrationEdit.url({ id: integration.Id });
    return (
      <div
        key={integration.Id}
        className={`w-100 d-flex flex-row flex-nowrap align-items-center ${
          index % 2 === 0 ? '' : 'table-row-background-color'
        }`}
        style={{ height: `${itemHeight}px` }}
      >
        <div className="text-truncate p-2" style={{ flexBasis: `${columns[0].width}%` }}>
          <u>
            <Link to={route} aria-label="integration-name">
              {isRowLoading ? (
                <Skeleton className="table-skeleton" width="100%" />
              ) : (
                integration.IntegrationName
              )}
            </Link>
          </u>
        </div>
        <div className="text-truncate p-2" style={{ flexBasis: `${columns[1].width}%` }}>
          {isRowLoading ? (
            <Skeleton className="table-skeleton" width="100%" />
          ) : integration.Type === 1 ? (
            commonText.userFile
          ) : (
            commonText.userAPI
          )}
        </div>
        <div className="text-truncate p-2" style={{ flexBasis: `${columns[2].width}%` }}>
          {isRowLoading ? (
            <Skeleton className="table-skeleton" width="100%" />
          ) : (
            dateFormatter.formatDate(integration.DateEditedUtc, 'MM/dd/yyyy')
          )}
        </div>
        <div className="text-truncate p-2 d-flex" style={{ flexBasis: `${columns[3].width}%` }}>
          {isRowLoading ? (
            <Skeleton className="table-skeleton" width="100%" />
          ) : integration.IsEnabled ? (
            commonText.enabled
          ) : (
            commonText.disabled
          )}
        </div>
        <div
          className="text-truncate p-2 d-flex justify-content-center"
          style={{ flexBasis: `${columns[4].width}%` }}
        >
          {isRowLoading ? (
            <Skeleton className="table-skeleton" width="100%" />
          ) : (
            <Link to={route} aria-label="manage-integration">
              <FiSettings size={20} color="primary" />
            </Link>
          )}
        </div>
      </div>
    );
  }

  const onSortChange = () => {};

  const handlePageChange = () => {};

  return (
    <Card>
      <Card.Body className="mx-0 w-100 d-flex" style={{ height: '400px' }}>
        <Table<CurrentIntegrationsResponse, CurrentIntegrationsRequest>
          data={data}
          columns={columns}
          height={768}
          itemHeight={itemHeight}
          numberOfItems={data.length}
          sortInformation={[]}
          requestInformation={{ companyId: 1 }}
          visibleRows={16}
          rowTemplate={rowTemplate}
          onSortChange={onSortChange}
          isLoading={isLoading}
          pageSize={50}
          onPageChange={handlePageChange}
          requestError={isError}
          hasNextPage={false}
          estimateSize={() => 54}
          emptyStateMessage="No Integrations Available"
        />
      </Card.Body>
    </Card>
  );
};

export default CurrentIntegrationsTable;
