import { DisplayItem } from '../../models/displayItem';
import defaultDropDownSelections, { DropDownSelectionType } from './defaultDropDownSelections';

export interface CreateUserIntegrationOptions {
  dropDownOptions: DropDownSelectionType;
  title: string;
  description: string;
  leadingZeros: DisplayItem;
  prependUsername: string;
  isEnabled: boolean;
}

const defaultCreateUserIntegrationOptions: CreateUserIntegrationOptions = {
  dropDownOptions: defaultDropDownSelections,
  title: '',
  description: '',
  leadingZeros: { itemId: 0, display: 'none' },
  prependUsername: '',
  isEnabled: true,
};

export default defaultCreateUserIntegrationOptions;
