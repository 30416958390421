import React from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { BsExclamationTriangle } from 'react-icons/bs';
import CopyButton from '../CopyButton';

export interface SshKeyModalProps {
  privateKey: string;
  sshUsername: string;
  isModalOpen: boolean;
  toggleModal: (isOpen: boolean) => void;
}

const SshKeyModal: React.FC<SshKeyModalProps> = ({
  privateKey,
  isModalOpen,
  sshUsername,
  toggleModal,
}) => {
  function handleModalToggle() {
    toggleModal(false);
  }

  function getPpkFile(blobContent: string) {
    const link = document.createElement('a');
    const file = new Blob([blobContent], { type: 'text/plain' });

    link.href = URL.createObjectURL(file);
    link.download = 'privateKey.ppk';

    link.click();
    URL.revokeObjectURL(link.href);
  }

  return (
    <Modal show={isModalOpen}>
      <div className="modal-content" style={{ width: '550px' }}>
        <Modal.Header className="pt-3 border-0" closeButton onClick={handleModalToggle}>
          <Modal.Title className="bg-white border-bottom-0 pull-left mb-0 p-0 text-left">
            SFTP Username & Key
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 w-100">
          <Alert className="d-flex" variant="warning">
            <div className="flex-grow-0 align-self-center">
              <BsExclamationTriangle className="mb-1" size={16} />
            </div>
            <div className="align-self-center ms-3">
              This key will not be displayed again. Once you close this modal, it will be gone
              forever. You will need to generate a new key if you lose control of this key.
            </div>
          </Alert>
          <Alert className="my-2" variant="secondary">
            <span className="fs-6 fw-bold">Username: </span>
            <div className="d-flex align-items-center">
              <span className="fs-6">{sshUsername}</span>
              <CopyButton classname="ms-2" textToCopy={sshUsername} toolTipPlacement="right" />
            </div>
          </Alert>
          <div style={{ height: '525px', width: '525px' }}>
            <textarea
              className="w-100 h-100 overflow-hidden"
              style={{ resize: 'none' }}
              value={privateKey}
              readOnly
            />
          </div>
          <div className="mt-2 d-flex justify-content-end">
            <CopyButton classname="me-2" textToCopy={privateKey} toolTipPlacement="left" />
            <Button color="primary" onClick={() => getPpkFile(privateKey)}>
              Download Key
            </Button>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default SshKeyModal;
