/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import { FileRejection, useDropzone } from 'react-dropzone';
import { BsCloudUpload } from 'react-icons/bs';

export interface DragAndDropFileSelectorProps {
  testId?: string;
  onDropAccepted: (acceptedFiles: File[]) => void;
  maxFiles: number;
  onDropRejected: (rejectedFiles: FileRejection[]) => void;
}

const DragAndDropFileSelector: React.FC<DragAndDropFileSelectorProps> = ({
  testId,
  onDropAccepted,
  maxFiles,
  onDropRejected,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted: acceptedFiles => {
      setFiles(acceptedFiles);
      onDropAccepted(acceptedFiles);
    },
    onDropRejected,
    accept: { 'application/vnd.ms-excel': ['.csv'], 'text/csv': ['.csv'] },
    maxFiles,
  });

  const optionalClasses: string = isDragActive ? 'bg-gray' : '';

  return (
    <div
      {...getRootProps({ className: 'dropzone' })}
      className={`border-secondary rounded text-center p-2 d-flex justify-content-center flex-column font-size-l ${optionalClasses} btn-hover`}
      style={{ border: 'dashed' }}
    >
      <div className="mx-auto">
        <input {...getInputProps()} data-testid={testId} />
        <Row>
          <BsCloudUpload size={36} />
          <div className="my-auto ms-2">
            <div>Drag your .csv here</div>
            <div>or click here</div>
          </div>
        </Row>
      </div>
      <div className="mt-2">
        File: {files.length > 0 ? files.map(file => file.name).join(', ') : null}
      </div>
    </div>
  );
};

export default DragAndDropFileSelector;
