import React from 'react';
import useCompany from '../../hooks/useCompany';
import useUserInformation from '../../hooks/useUserInformation';
import usePolicyService from '../../policyService/usePolicyService';
import NotFound from './NotFound';

const NotFoundContainer: React.FC = () => {
  const { isLoading: isCompanyLoading, company } = useCompany();
  const { isLoading: isUserLoading, user } = useUserInformation();
  const policyService = usePolicyService();

  return (
    <NotFound
      company={company}
      isCompanyLoading={isCompanyLoading}
      isUserLoading={isUserLoading}
      policies={policyService.policies()}
      user={user}
    />
  );
};

export default NotFoundContainer;
