export enum CertificationStatus {
  NotQualified,
  Qualified,
  Disqualified,
  Expired,
  Suspended,
}

export enum JobCompletionStatus {
  All = '',
  Completed = 'Completed',
  NotCompleted = 'NotCompleted',
}

export interface ReportProcessingError {
  /// <summary>
  /// Error message - comes from AWS Step Function's catchers
  /// The AWS object for the error details automatically uses this Error property
  /// </summary>
  error: string;

  /// <summary>
  /// Details behind the error - comes from AWS Step Function's catchers
  /// The AWS object for the error details automatically uses this Cause property to insert the details of the error
  /// </summary>
  cause: string;
}

export enum ReportRequestOutputType {
  //TODO: Mime Types may need to be pulled from a library instead
  UI = 'application/json',
  EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PDF = 'application/pdf',
}

export enum ReportRequestType {
  JOB_READINESS = 'Job Readiness',
  JOB_READINESS_EWALLET = 'Job Readiness eWallet',
  JOB_READINESS_ONBOARD_LIVE = 'Job Readiness OnBoard Live',
  JOB_READINESS_ONBOARD_LIVE_STATS = 'Job Readiness OnBoard Live Statistics',
}

export default interface ReportRequestFilter {
  // =-=-=-=-= Automatic properties.
  certStatus?: CertificationStatus;

  currentCompanyId: number;

  currentUserId: number;

  dateCertExpired?: Date;

  /// <summary>
  /// Flag for whether to include training records for report processing, default to false
  /// </summary>
  includeTrainings?: boolean;

  /// <summary>
  /// Type of report being requested, ex: Job Readiness
  /// </summary>
  /// <see cref="ReportRequestType"/>
  reportType: string;

  /// <summary>
  /// Type of report output requested
  /// </summary>
  /// <see cref="ReportRequestOutputType"/>
  reportOutputType: string;

  /// <summary>
  /// Contains details of step function error that occurred during report processing (if any)
  /// This model maps to how the AWS step functions format the error details when a lambda uses a catcher to handle errors
  /// </summary>
  reportProcessingError?: ReportProcessingError;

  // =-=-=-=-= Calculated properties.
  /// <summary>
  /// URL to OnBoard LMS Job Readiness report page where the report data will be displayed.  Will be used when the user requests the report in the UI (not an export)
  /// </summary>
  reportURL?: string;

  /// <summary>
  /// URL to export file containing report data.  Will be used when the user requests an Excel or PDF export of a report
  /// </summary>
  exportURL?: string;

  /// <summary>
  /// This property is meta-data that a previous Lambda in the Step Function will determine and set.
  /// It is not something that is being passed by the UI as part of the report filters.
  /// Allows the UserTaskProfile Lambda to include contractor users only if needed, in order to improve performance
  /// </summary>
  /// <remarks>
  /// Renaming this filter property to IncludeContractors.  If we set this just based on whether
  /// any of the jobs are marked as shared, then what happens if the current company running the report has jobs shared with them
  /// by one of their parents/owners but they don't have any jobs they share with anyone under them?
  /// The UserService LINQ assumes this flag means a job is shared by the CURRENT company, so it would result in nothing being returned
  /// for the UserTaskProfile query.
  /// If we renamed this, the logic to set it to true/false would check if any of the jobs are marked as shared AND that shared job
  /// belongs to the current company.
  /// </remarks>
  includeContractors: boolean;

  s3KeyNameForJobDetails?: string;

  s3KeyNameForUserOnReport?: string;

  s3KeyNameForUserQualifications?: string;

  s3KeyNameForUserTaskProfiles?: string;

  reportRequestId?: string;

  /// <summary>
  /// This property is meta-data that a previous Lambda in the Step Function will determine and set.
  /// It will include the distinct seriesIds that are associated with the JobMatrix records in the report request.
  /// It is not something that is being passed by the UI as part of the report filters.
  /// Allows the UserTaskProfile Lambda to filter further, in order to improve performance
  /// </summary>
  seriesIds?: Array<number>;

  /// <summary>
  /// This is the total number of report records generated for this report request.
  /// This value is saved to the ReportRequest Dynamo table as well, but also including on this model to avoid
  /// additional lookups/queries to the Dynamo table when we need to reference this value.
  /// </summary>
  totalReportRecords?: number;

  // =-=-=-=-= UserSpecified properties.
  companyIds: Array<number>;

  costCenterIds: Array<number>;

  departmentIds: Array<number>;

  enforceTaskProfiling: boolean;

  jobMatrixIds: Array<number>;

  supervisorIds: Array<number>;

  userIds: Array<number>;

  workLocationIds: Array<number>;

  /// <summary>
  /// Could be null/empty if no Job Completion filter was selected by the user.
  /// Possible values are Complete and Not Complete
  /// </summary>
  jobCompletionStatus: string;
}
