import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';

export interface ListProps<T> {
  data: T[];
  render: (item: T) => React.ReactNode;
  emptyStateMessage: string;
  className?: string;
  style?: React.CSSProperties;
}

function List<T>({
  data,
  render,
  emptyStateMessage,
  className = '',
  style = {},
}: ListProps<T>): JSX.Element {
  return (
    <ListGroup className={`w-100 ${className}`} style={style}>
      {data.length === 0 ? <ListItem>{emptyStateMessage}</ListItem> : data.map(d => render(d))}
    </ListGroup>
  );
}

const ListItem: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <ListGroup.Item
    className="d-inline-flex justify-content-between align-items-center border-0"
    role="listitem"
  >
    {children}
  </ListGroup.Item>
);

export default List;
