import apiHooks from '../api/apiHooks';
import { UseDisplayItemsResponse } from '../models/useDisplayItemsResponse';
import displayItemConverters from '../pages/TaskProfileReport/hooks/displayItemConverters';

function useCostCenters(companyId: number, searchText: string): UseDisplayItemsResponse {
  const { data = [], isLoading } = apiHooks.useCompaniesCostCenters(
    { companyId, searchText },
    {
      keepPreviousData: true,
      select: displayItemConverters.convertCompaniesCostCentersResponse,
    }
  );

  return { data, isLoading };
}

export default useCostCenters;
