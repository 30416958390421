import axios from 'axios';
import QueryString from 'qs';
// eslint-disable-next-line import/no-cycle
import authService from '../auth/authService';
import { CreateReportRequestRoute, GetJobStatsRoute } from './apiRoutesReporting';
import ReportRequestFilter from './requests/ReportRequestFilter';
import { CompaniesUsersJobsResponse } from './responses/companiesUsersJobsResponse';
import { JobStatsResponse } from './responses/jobStatsResponse';

const reportingApi = axios.create({
  baseURL: process.env.REACT_APP_REPORTING_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: {
    serialize: params => QueryString.stringify(params),
  },
});

reportingApi.interceptors.request.use(async config => {
  const bearerToken = await authService.getAuthSession();
  if (config !== undefined && config.headers !== undefined) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${bearerToken.token.idToken}`;
  }

  return config;
});

async function createReportRequest(
  requestParams: ReportRequestFilter
): Promise<CompaniesUsersJobsResponse> {
  const response = await reportingApi.post(CreateReportRequestRoute(), requestParams);

  return response.data;
}

async function getJobStats(requestParams: ReportRequestFilter): Promise<JobStatsResponse> {
  const response = await reportingApi.post(GetJobStatsRoute(), requestParams);

  return response.data;
}

export default {
  createReportRequest,
  getJobStats,
};
