import { PDFViewer } from '@react-pdf/renderer';
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Fade from 'react-bootstrap/Fade';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { AiOutlineFileExcel } from 'react-icons/ai';
import { BsFunnel } from 'react-icons/bs';
import { GetActivitiesReportRequest } from '../../api/requests/getActivitiesReportRequest';
import { CompanyResponse } from '../../api/responses/companyResponse';
import { GetActivitiesReportResponse } from '../../api/responses/getActivitiesReportResponse';
import { ActivityReportAffidavitModel } from '../../api/responses/getActivityAffidavitInformationResponse';
import { PaginatedResponse } from '../../api/responses/paginatedResponse';
import { UserInformation } from '../../api/responses/userInformation';
import ActivitiesTable from '../../components/Activities/ActivitiesTable/ActivitiesTable';
import Button from '../../components/Buttons/Button/Button';
import Dialog from '../../components/Dialog/Dialog';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import TopNavbar from '../../components/Layout/TopNavBar/TopNavBar';
import Affidavit from '../../components/PdfComponents/Affidavit';
import SearchBox from '../../components/SearchBox/SearchBox';
import ToastMessage from '../../components/Toast/ToastMessage';
import commonText from '../../constants/commonText';
import testIds from '../../constants/testIds';
import useCompanyId from '../../hooks/useCompanyId';
import useCompanyTypes from '../../hooks/useCompanyTypes';
import { ToastMessageInformation } from '../../models/ToastMessageInformation';
import { UseDisplayItemsResponse } from '../../models/useDisplayItemsResponse';
import { AvailablePolicies } from '../../policyService/policyEnforcer/policyEnforcer';
import ActivitiesReportDrawer from './ActivitiesReportDrawer';

export interface ToastInformation {
  showToast: boolean;
  isSuccessful: boolean;
  error?: AxiosError;
}

export interface AffidavitModalInformation {
  affidavitData: ActivityReportAffidavitModel | undefined;
  isOpen: boolean;
}

export interface ActivitiesReportProps {
  isCompanyLoading: boolean;
  company?: CompanyResponse;
  policies: Pick<
    AvailablePolicies,
    | 'canViewTaskProfile'
    | 'canViewCustomView'
    | 'canViewActivities'
    | 'canViewIntegrationHub'
    | 'canViewLearningHistory'
  >; // update with new policies/perms later
  activityData?: PaginatedResponse<GetActivitiesReportResponse>[];
  activitiesReportRequest: GetActivitiesReportRequest;
  isActivitiesReportLoading: boolean;
  downloadExcel: Partial<UseQueryResult<number | undefined>>;
  toastInformation: ToastInformation;
  requestError: boolean;
  user: UserInformation | undefined;
  isUserLoading: boolean;
  onActivitiesReportRequestChange: (changes: GetActivitiesReportRequest) => void;
  onPageChange: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<
    PaginatedResponse<GetActivitiesReportResponse>,
    unknown
  > | void>;
  useDepartments: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useCompanySeriesTasks: (
    companyId: number,
    seriesId: number,
    searchText: string
  ) => UseDisplayItemsResponse;
  useCompanyTypes: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useCostCenters: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useStatus: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useRoles: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useAssigners: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useEvaluators: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useSupervisors: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useWorkLocations: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useSeries: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  setToastInformation: (toastInformation: ToastInformation) => void;
  useEmployeesAll: (
    companyId: number,
    activeStatus: number,
    searchText: string
  ) => UseDisplayItemsResponse;
  useActiveEmployeeStatus: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  affidavitModalInformation: AffidavitModalInformation;
  setAffidavitModalInformation: React.Dispatch<AffidavitModalInformation>;
  setAffidavitId: React.Dispatch<string>;
  affidavitLoading: boolean;
  hasNextPage: boolean;
}

const ActivitiesReport: React.FC<ActivitiesReportProps> = ({
  isCompanyLoading,
  company = undefined,
  policies,
  activityData,
  activitiesReportRequest,
  isActivitiesReportLoading,
  downloadExcel,
  toastInformation,
  requestError,
  user,
  isUserLoading,
  onActivitiesReportRequestChange,
  onPageChange,
  useDepartments,
  useStatus,
  useCostCenters,
  useRoles,
  useAssigners,
  useEvaluators,
  useSupervisors,
  useWorkLocations,
  useSeries,
  useCompanySeriesTasks,
  setToastInformation,
  useEmployeesAll,
  useActiveEmployeeStatus,
  affidavitModalInformation,
  setAffidavitModalInformation,
  setAffidavitId,
  affidavitLoading,
  hasNextPage,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showCurrentActivities, setShowCurrentActivities] = useState<boolean>(true);
  const [toast, setToast] = useState<ToastMessageInformation>({
    iconColor: 'success',
    headerText: '',
    bodyText: '',
  });
  const [activityDataCount, setActivityDataCount] = useState<number>(0);

  useEffect(() => {
    if (toastInformation.showToast) {
      if (toastInformation.isSuccessful) {
        setToast({
          iconColor: 'success',
          headerText: commonText.excelSuccessTitle,
          bodyText: commonText.excelSuccessText,
        });
      } else if (toastInformation.error) {
        if (toastInformation.error.response?.status === StatusCodes.FORBIDDEN) {
          setToast({
            iconColor: 'warning',
            headerText: commonText.toastUnauthorizedTitle,
            bodyText: commonText.toastUnauthorizedText,
          });
        } else {
          setToast({
            iconColor: 'danger',
            headerText: commonText.toastUnknownTitle,
            bodyText: commonText.toastUnknownText,
          });
        }
      } else {
        setToast({
          iconColor: 'danger',
          headerText: commonText.excelFailureTitle,
          bodyText: commonText.excelFailureText,
        });
      }
    }
  }, [toastInformation]);

  useEffect(() => {
    const count = activityData?.reduce((accum, current) => {
      return accum + current.value.length;
    }, 0);
    setActivityDataCount(count || 0);
  }, [activityData]);

  const handleClick = () => {
    setIsOpen(prevState => !prevState);
  };
  const { companyId } = useCompanyId();

  const handleShowActivitiesChange = (value: boolean) => {
    setShowCurrentActivities(value);
    onActivitiesReportRequestChange({
      ...activitiesReportRequest,
      isCurrent: value,
    });
  };

  const handleSearchboxChange = (value: string) => {
    onActivitiesReportRequestChange({
      ...activitiesReportRequest,
      searchText: value,
    });
  };

  const handleToastDismiss = () => {
    setToastInformation({ showToast: false, isSuccessful: false });
  };

  const handleDownloadExcelClick = () => {
    if (downloadExcel !== undefined && downloadExcel.refetch !== undefined) {
      downloadExcel.refetch();
    }
  };
  const handleAffidavitClose = () => {
    setAffidavitModalInformation({ affidavitData: undefined, isOpen: false });
    setAffidavitId('');
  };

  return (
    <MainLayout
      isCompanyLoading={isCompanyLoading}
      companyImage={company?.logo}
      companyName={company?.name}
      policies={policies}
    >
      <TopNavbar reportName="Activity Report" user={user} isUserLoading={isUserLoading}>
        <div className="d-inline-flex align-items-center me-2">
          <SearchBox size="sm" onChange={handleSearchboxChange} />
        </div>
      </TopNavbar>
      <Container fluid className="h-100">
        {toastInformation.showToast && (
          <div
            className="position-absolute mx-auto"
            style={{ zIndex: 10000, left: '50%', transform: 'translate(-50%,0)' }}
            data-testid={testIds.toastContainer()}
          >
            <ToastMessage
              iconColor={toast.iconColor}
              headerText={toast.headerText}
              bodyText={toast.bodyText}
              toggle={handleToastDismiss}
              className="top-0 start-50 translate-middle-x bg-white"
            />
          </div>
        )}
        {affidavitModalInformation.isOpen && (
          <Row>
            <Dialog
              size="xl"
              show={affidavitModalInformation.isOpen}
              headerProps={{ closeButton: true, onHide: handleAffidavitClose }}
              header={null}
              body={
                <PDFViewer width="100%" showToolbar style={{ height: '80vh' }}>
                  <Affidavit affidavitInformation={affidavitModalInformation.affidavitData} />
                </PDFViewer>
              }
              footer={null}
            />
          </Row>
        )}
        <Fade in={affidavitLoading} unmountOnExit>
          <div className="drawer-overlay d-flex">
            <Spinner
              className="m-auto"
              style={{ width: 150, height: 150 }}
              data-testid={testIds.spinner()}
            />
          </div>
        </Fade>
        <Row className="mt-3">
          <Col className="mx-2 mb-3">
            <Row>
              <Col>
                <h6>
                  View learning events that can influence a qualification, such as exams,
                  evaluations, and training.
                </h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  onClick={handleClick}
                  variant="primary"
                  dataTestId={testIds.activitiesReportFilterButton()}
                  className="mb-2"
                >
                  <BsFunnel size={15} /> Filter
                </Button>
              </Col>
              <Col className="d-flex justify-content-end">
                <Button
                  variant="light"
                  className="mb-2"
                  onClick={handleDownloadExcelClick}
                  size="sm"
                  disabled={downloadExcel.isFetching}
                  dataTestId={testIds.activitiesReportExcelButton()}
                >
                  {downloadExcel.isFetching ? (
                    <Spinner size="sm" className="mx-1 mt-1" data-testid={testIds.spinner()} />
                  ) : (
                    <AiOutlineFileExcel size={23} />
                  )}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <ActivitiesTable
                  activityData={activityData}
                  activityDataCount={activityDataCount}
                  activitiesReportRequest={activitiesReportRequest}
                  isActivitiesReportLoading={isActivitiesReportLoading}
                  requestError={requestError}
                  onActivitiesReportRequestChange={onActivitiesReportRequestChange}
                  onPageChange={onPageChange}
                  setAffidavitId={setAffidavitId}
                  hasNextPage={hasNextPage}
                />
              </Col>
            </Row>
          </Col>
          <ActivitiesReportDrawer
            expanded={isOpen}
            onDrawChange={handleClick}
            onShowActivitiesChange={handleShowActivitiesChange}
            showCurrentActivities={showCurrentActivities}
            companyId={companyId}
            activitiesReportRequest={activitiesReportRequest}
            onActivitiesReportRequestChange={onActivitiesReportRequestChange}
            useAssigners={useAssigners}
            useDepartments={useDepartments}
            useCompanySeriesTasks={useCompanySeriesTasks}
            useCompanyTypes={useCompanyTypes}
            useCostCenters={useCostCenters}
            useRoles={useRoles}
            useEvaluators={useEvaluators}
            useStatus={useStatus}
            useSupervisors={useSupervisors}
            useWorkLocations={useWorkLocations}
            useSeries={useSeries}
            useEmployeesAll={useEmployeesAll}
            useActiveEmployeeStatus={useActiveEmployeeStatus}
          />
        </Row>
      </Container>
    </MainLayout>
  );
};

export default ActivitiesReport;
