import React from 'react';

interface DrawerMenuGroupProps {
  id?: string;
  label?: string;
  children?: React.ReactNode;
}

const DrawerMenuGroup: React.FC<DrawerMenuGroupProps> = ({ id, label, children }) => {
  return (
    <>
      {label && (
        <p id={id} className="fw-bold mb-2">
          {label}
        </p>
      )}
      {children}
    </>
  );
};

export default DrawerMenuGroup;
