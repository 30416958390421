import { BatchUserStatisticResponse } from '../responses/batchUserStatisticResponse';
import { ApiResult } from '../apiHooks';
import { GetBatchUserStatisticsDetailsRequest } from './requests/getBatchUserStatisticsDetailRequest';
import { GetIntegrationUserStatisticsDetailsRequest } from './requests/getIntegrationUserStatisticsDetailRequest';
import integrationApi from './integrationApi';
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { BatchUserUploadIntegrationResponse } from './responses/BatchUserUploadIntegrationResponse';
import CurrentIntegrationsResponse from '../../models/CurrentIntegrationsResponse';
import { AxiosError } from 'axios';
import DeleteIntegrationRequest from '../requests/deleteIntegrationRequest';
import { BatchUserStatisticsPaginatedResponse } from '../responses/BatchUserStatisticsPaginatedResponse';
import BatchUserStatisticsExcelReport from '../responses/batchUserStatisticsExcelReport';

function useGetBatchUserStatisticsDetails<TData = BatchUserStatisticResponse[]>(
  requestParams: GetBatchUserStatisticsDetailsRequest
): UseQueryResult<TData> {
  return useQuery(
    ['getBatchUserStatisticDetails', requestParams],
    () => integrationApi.getBatchUserStatisticsDetails(requestParams),
    { enabled: false, refetchOnWindowFocus: false }
  );
}

function useGetBatchUserStatisticsByCompanyId<TData = BatchUserUploadIntegrationResponse[]>(
  companyId: number
): ApiResult<TData> {
  return useQuery(['getBatchUserStatisticsByCompanyId', companyId], () =>
    integrationApi.getBatchUserStatisticsByCompanyId(companyId)
  );
}

function useGetIntegrationBatchStatisticsByCompanyId<TData = BatchUserUploadIntegrationResponse[]>(
  companyId: number,
  integrationId: number
): UseQueryResult<TData> {
  return useQuery(
    ['getIntegrationBatchStatisticsByCompanyId', companyId, integrationId],
    () => integrationApi.getIntegrationBatchStatisticsByCompanyId(companyId, integrationId),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}

function useGetCurrentIntegrations(
  companyId: number,
  queryKey: string
): UseQueryResult<CurrentIntegrationsResponse[], AxiosError> {
  return useQuery([queryKey, companyId], () => integrationApi.getCurrentIntegrations(companyId));
}

function useGetAllIntegrations(
  companyId: number
): UseQueryResult<CurrentIntegrationsResponse[], AxiosError> {
  return useQuery(['getAllIntegrations', companyId], () =>
    integrationApi.getCurrentIntegrations(companyId)
  );
}

function useDeleteCurrentIntegration(): UseMutationResult<
  number,
  AxiosError,
  DeleteIntegrationRequest,
  { previousIntegrations: CurrentIntegrationsResponse[] }
> {
  const client = useQueryClient();
  return useMutation<
    number,
    AxiosError,
    DeleteIntegrationRequest,
    { previousIntegrations: CurrentIntegrationsResponse[] }
  >({
    mutationFn: ({ companyId, integrationId }: DeleteIntegrationRequest) =>
      integrationApi.deleteCurrentIntegration(companyId, integrationId),
    onSuccess: async (_: number, variables: DeleteIntegrationRequest) => {
      await client.cancelQueries({ queryKey: ['getCurrentIntegrations'] });
      const previousIntegrations =
        client.getQueryData<CurrentIntegrationsResponse[]>(['getCurrentIntegrations']) || [];
      client.setQueryData(
        ['getCurrentIntegrations', variables.companyId],
        (oldData: CurrentIntegrationsResponse[] | undefined) =>
          oldData?.filter(
            (item: CurrentIntegrationsResponse) => item.Id !== variables.integrationId
          )
      );
      return { previousIntegrations };
    },
    onError: (_, variables, context) => {
      if (context?.previousIntegrations) {
        client.setQueryData(
          ['getCurrentIntegrations', variables.companyId],
          context.previousIntegrations
        );
      }
    },
  });
}

function useGetIntegrationUserStatisticsDetails(
  requestParams: GetIntegrationUserStatisticsDetailsRequest,
  queryString: string
): UseInfiniteQueryResult<BatchUserStatisticsPaginatedResponse, AxiosError> {
  return useInfiniteQuery(
    [queryString, requestParams],
    options => integrationApi.getIntegrationUserStatisticsDetails(requestParams, options),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (
        lastPage: BatchUserStatisticsPaginatedResponse,
        allPages: BatchUserStatisticsPaginatedResponse[]
      ) => {
        const value = lastPage.ItemCount;
        const pages = Math.ceil(value / 50);
        if (pages === allPages.length) return undefined;
        return allPages.length * 50;
      },
      retry: false,
    }
  );
}

function useGetIntegrationHistoryExcelReport(
  requestParams: GetIntegrationUserStatisticsDetailsRequest,
  onSuccess: (data: BatchUserStatisticsExcelReport) => void
): UseQueryResult<BatchUserStatisticsExcelReport, AxiosError> {
  return useQuery(
    ['useGetIntegrationHistoryExcelReport', requestParams],
    () => integrationApi.getIntegrationHistoryExcelReport(requestParams),
    { refetchOnWindowFocus: false, retry: false, onSuccess, enabled: false }
  );
}

export default {
  useGetBatchUserStatisticsDetails,
  useGetBatchUserStatisticsByCompanyId,
  useGetCurrentIntegrations,
  useDeleteCurrentIntegration,
  useGetIntegrationUserStatisticsDetails,
  useGetIntegrationBatchStatisticsByCompanyId,
  useGetAllIntegrations,
  useGetIntegrationHistoryExcelReport,
};
