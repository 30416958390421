import apiHooks from '../api/apiHooks';
import { UseDisplayItemsResponse } from '../models/useDisplayItemsResponse';
import displayItemConverters from '../pages/TaskProfileReport/hooks/displayItemConverters';

function useRoles(companyId: number, searchText: string): UseDisplayItemsResponse {
  const { data = [], isLoading } = apiHooks.useCompaniesRoles(
    { companyId, searchText },
    {
      keepPreviousData: true,
      select: displayItemConverters.convertCompaniesRolesResponse,
    }
  );

  return { data, isLoading };
}

export default useRoles;
