import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import testIds from '../../constants/testIds';
import { Color } from '../../types/color';
import uniqueId from '../../utils/uniqueId';

export interface AccordionProps {
  toggle?: string | React.ReactElement;
  specialToggle?: (isOpen: boolean) => React.ReactElement;
  toggleColor?: Color;
  buttonClassName?: string;
  defaultOpen?: boolean;
  children: React.ReactNode;
}

const Accordion: React.FC<AccordionProps> = ({
  toggle = null,
  toggleColor,
  children,
  buttonClassName = '',
  specialToggle = null,
  defaultOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [accordionId] = useState(uniqueId('accordion'));
  const toggleArrow = isOpen ? (
    <IoIosArrowDown className="arrow-down" />
  ) : (
    <IoIosArrowForward className="arrow-forward" />
  );

  const onToggleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Button
        id={`${accordionId}Button`}
        className={`w-100 d-flex align-items-center mb-0 mx-0 rounded-0 ${buttonClassName}`}
        onClick={onToggleClick}
        variant={toggleColor}
        aria-controls={accordionId}
        aria-expanded={isOpen}
        data-testid={testIds.accordionButton()}
      >
        {!specialToggle && toggleArrow}
        {specialToggle ? specialToggle(isOpen) : toggle}
      </Button>
      <Collapse
        in={isOpen}
        role="region"
        aria-labelledby={`${accordionId}Button`}
        data-testid={testIds.accordionCollapse()}
      >
        <div>{children}</div>
      </Collapse>
    </>
  );
};

export default Accordion;
