import { uniqBy } from 'lodash';
import React, { CSSProperties, useEffect, useState } from 'react';
import { DisplayItem } from '../../../models/displayItem';
import { UseDisplayItemsResponse } from '../../../models/useDisplayItemsResponse';
import BadgeListItem from '../BadgeListItem/BadgeListItem';
import MultiPartSelectWithDropdownContainerBase from '../MultiPartSelectWithDropdownBase/MultiPartSelectWithDropdownBase';

export interface MultiPartSelectWithDropdownContainerProps {
  companyId: number;
  useDropdownData: (companyId: number, text: string) => UseDisplayItemsResponse;
  useListData: (companyId: number, parentId: number, text: string) => UseDisplayItemsResponse;
  onSearch: (text: string) => void;
  onListItemSelection: (item: DisplayItem[]) => void;
  selectedDisplayItems: DisplayItem[];
  searchText: string;
  emptyListText: string;
  availableListGroupStyle?: CSSProperties;
  dropdownLabel: string;
  emptyDropdownLabel: string;
  defaultParentId?: number;
  tabId: string;
}

const MultiPartSelectWithDropdownContainer: React.FC<MultiPartSelectWithDropdownContainerProps> = ({
  companyId,
  onSearch,
  onListItemSelection,
  emptyListText,
  useDropdownData,
  useListData,
  searchText,
  dropdownLabel,
  emptyDropdownLabel,
  selectedDisplayItems,
  tabId,
  availableListGroupStyle = {},
  defaultParentId = 0,
}) => {
  const { data: dropdownData, isLoading: isDropdownLoading } = useDropdownData(companyId, '');
  const [activeParentId, setActiveParentId] = useState<number>(defaultParentId || 0);
  const [activeParent, setActiveParent] = useState<DisplayItem | undefined>(undefined);
  const { data: listData, isLoading: isListLoading } = useListData(
    companyId,
    activeParentId,
    searchText
  );
  useEffect(() => {
    const option = dropdownData.find(item => item.itemId === activeParentId);
    if (option !== undefined) {
      setActiveParent(option);
    }
  }, [activeParentId, dropdownData]);

  const selectedItemTemplate = (listItem: DisplayItem) => (
    <BadgeListItem
      key={listItem.itemId}
      onClick={() => handleSelectedItem(listItem)}
      text={listItem.display}
    />
  );
  const onDropdownChange = (option: DisplayItem): void => {
    setActiveParentId(option.itemId);
  };
  const handleSelectedItem = (displayItem: DisplayItem) => {
    let selectedItems;
    const displayItemFound = selectedDisplayItems.find(
      selectedDisplayItem => selectedDisplayItem.itemId === displayItem.itemId
    );
    // Remove if found.
    if (displayItemFound) {
      selectedItems = selectedDisplayItems.filter(
        selectedDisplayItem => selectedDisplayItem.itemId !== displayItem.itemId
      );
    }
    // Add if not found.
    else {
      selectedItems = selectedDisplayItems.concat(displayItem);
    }
    onListItemSelection(selectedItems);
  };
  const handleOnClear = () => {
    onListItemSelection([]);
  };

  const handleOnSelectAll = () => {
    const uniqueDisplayItems = uniqBy([...selectedDisplayItems, ...listData], 'itemId');

    onListItemSelection(uniqueDisplayItems);
  };
  return (
    <MultiPartSelectWithDropdownContainerBase
      activeDropdownOption={activeParent}
      dropdownItems={dropdownData}
      onDropdownChanged={onDropdownChange}
      listItems={listData}
      onSearch={onSearch}
      onItemSelection={handleSelectedItem}
      selectedItems={selectedDisplayItems.reduce((itemHash, displayItem) => {
        const tempHash = itemHash;
        tempHash[displayItem.itemId] = displayItem;
        return tempHash;
      }, {} as Record<number, DisplayItem>)}
      selectedItemsIds={selectedDisplayItems.map(item => item.itemId)}
      emptyListText={emptyListText}
      isDropdownLoading={isDropdownLoading}
      isListLoading={isListLoading}
      onClear={handleOnClear}
      onSelectAll={handleOnSelectAll}
      selectedItemTemplate={selectedItemTemplate}
      selectedListGroupClassName="flex-wrap overflow-auto flex-shrink-0"
      availableListGroupStyle={availableListGroupStyle}
      dropdownLabel={dropdownLabel}
      emptyDropdownLabel={emptyDropdownLabel}
      tabId={tabId}
    />
  );
};

export default MultiPartSelectWithDropdownContainer;
