import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { TaskByJobMatrixReadListRequest } from '../../api/requests/taskByJobMatrixReadListRequest';
import { CompanyResponse } from '../../api/responses/companyResponse';
import { TaskGetListResponse } from '../../api/responses/taskGetListResponse';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import messages from '../../constants/messages';
import useCompanyId from '../../hooks/useCompanyId';
import useEventListener from '../../hooks/useEventListener';
import { DisplayItem } from '../../models/displayItem';
import ExpressionNode from '../../models/ExpressionNode';
import JobMatrix from '../../models/JobMatrix';
import Series from '../../models/Series';
import { TaskDisplayItem } from '../../models/TaskDisplayItem';
import TaskModel from '../../models/TaskModel';
import { JSONObject, JSONParsed } from '../../types/json-types';
import ExpressionBuilder, {
  ExprEventDetail,
} from '../../utils/expression-builder/expressionBuilder';
import { ExprToken } from '../../utils/expression-builder/exprToken';
import TreeToDiagramTraverserDelegate from '../../utils/expression-builder/treeToDiagramTraverserDelegate';
import EquivalenciesExpressionCanvas from './EquivalenciesExpressionCanvas';
import EquivalenciesExpressionPalette, {
  EquivalenciesExpressionPaletteButtonDisabledState,
  EquivalenciesExpressionPaletteButtonVisibleState,
} from './EquivalenciesExpressionPalette';
import EquivalenciesSelectTasks from './EquivalenciesSelectTasks';
import { GeneralMessageType } from '../../models/GeneralMessage';
import { JobMatrixSaveMutation } from '../../hooks/useJobMatrixSave';

/**
 * @param {boolean} isEditing - Indicates whether the equivalency mapping is a new record if false, or an existing record if true.
 * @param {JobMatrix} jobMatrix - The Job(Matrix) equivalency record being created/edited.
 * @param setJobMatrix - React "state variable" setter for the Job(Matrix).
 * @param {Series[]} availableSeriesList - All the Series to which the user has access for selecting Tasks.
 * @param {boolean} seriesListIsLoading - React `UseQueryResult<TData, TError>.isLoading` indicator.
 * @param setSelectedSeries - Callback called when user changes selected Series.
 * @param {TaskDisplayItem[]} taskDisplayItems - Task display items converted from the selected Series's `TaskModel`s (required by `MultiPartSelectContainer`).
 * @param {string} errorNotification - Any (web API) error to report to the UI (empty string if no error).
 * @param {ExpressionBuilder} expressionBuilder - An instance of `ExpressionBuilder` for constructing the equivalency expression.
 * @param {JobMatrixSaveMutation} saveMutation - Service for creating/editing the Job(Matrix) equivalency record.
 * @param {boolean|undefined} isDisabled - Optional indicator whether this component is disabled, false by default.
 * @param {number} selectedSeriesId - ID of selected Series from `availableSeriesList`.
 */
export interface EquivalenciesMapperProps {
  company?: CompanyResponse;
  isEditing: boolean;
  jobMatrix: JobMatrix;
  jobMatrixIsLoading?: boolean;
  setJobMatrix: Dispatch<SetStateAction<JobMatrix>>;
  availableSeriesList: Series[];
  seriesListIsLoading: boolean;
  setIncludeHistoricalTasks?: (includeHistorical: boolean) => boolean | void;
  includeHistoricalTasks?: boolean;
  setSelectedSeries: (item: Series) => boolean | void;
  selectedSeriesId: number;
  taskDisplayItems: TaskDisplayItem[];
  errorNotification: string;
  expressionBuilder: ExpressionBuilder;
  saveMutation: JobMatrixSaveMutation;
  isDisabled?: boolean;
  useTaskByJobMatrixReadList: (
    requestParams: TaskByJobMatrixReadListRequest,
    options?: UseQueryOptions<
      TaskGetListResponse,
      Error,
      TaskGetListResponse,
      (string | TaskByJobMatrixReadListRequest)[]
    >
  ) => Partial<UseQueryResult<TaskGetListResponse | undefined, Error>>;
}

/**
 * A component for organizing the relationship between the expression "palette" and "canvas".
 * @see {@linkcode EquivalenciesExpressionCanvas}.
 * @see {@linkcode EquivalenciesExpressionPalette}.
 */
const EquivalenciesMapper: React.FC<EquivalenciesMapperProps> = ({
  isEditing,
  jobMatrix,
  jobMatrixIsLoading = true,
  setJobMatrix,
  availableSeriesList,
  seriesListIsLoading,
  setIncludeHistoricalTasks = () => {},
  includeHistoricalTasks = true,
  setSelectedSeries,
  selectedSeriesId,
  taskDisplayItems,
  errorNotification,
  expressionBuilder,
  saveMutation,
  isDisabled = false,
  useTaskByJobMatrixReadList,
}) => {
  const { companyId } = useCompanyId();
  // State management.
  // Flag for whether the default (require all) task mapping has been built
  // Used when editing an existing job whose never had a mapping expression saved, so we make sure
  // the Require All function is only fired once when loading the job and doesn't end up overwriting anything
  const [taskMappingAutoBuilt, setTaskMappingAutoBuilt] = useState<boolean>(false);
  const [selectedTaskDisplayItems, setSelectedTaskDisplayItems] = useState<TaskDisplayItem[]>([]);
  const [availableExpressionTerms, setAvailableExpressionTerms] = useState<ExpressionNode[]>([]);
  const [selectedTasksPaletteVisibleState, setSelectedTaskPaletteVisibility] =
    useState<EquivalenciesExpressionPaletteButtonVisibleState>({});
  const [nextJobMatrixUsedTaskIds, setNextJobMatrixUsedTaskIds] = useState<number[]>([]);
  const [exprBldrMessage, setExprBldrMessage] = useState<GeneralMessageType | null>({
    severity: 'transparent',
    message: '',
  });
  const incompleteMessage: GeneralMessageType = useMemo(
    () => ({
      severity: 'warning',
      message: messages.incompleteInProgress,
    }),
    []
  );

  const onSelectTerm = useCallback(
    (exprTerm: ExpressionNode): void => {
      try {
        const raw: JSONParsed = {
          label: exprTerm.label,
          id: exprTerm.termId ? exprTerm.termId : null,
          description: exprTerm.description ? exprTerm.description : null,
        };

        expressionBuilder.insertToken({
          type: 'CallExpression',
          // This is a serialized function call with an object parameter.
          // value: `task({"label":"${exprTerm.label}", "id":${exprTerm.termId}${
          //   exprTerm.description ? ', "desc":"'.concat(exprTerm.description, '"') : ''
          // }})`,
          value: `task(${JSON.stringify(raw)})`,
          raw,
        });
        setExprGroupDepth(expressionBuilder.groupDepth());
        setFormula(expressionBuilder.stringifyExpression());
        setExprBldrMessage(expressionBuilder.isComplete() ? null : incompleteMessage);
        // call function to update task visible state in palette, we want to hide this task in the palette so it can't be selected again
        updateSelectedTasksPaletteVisibleState(exprTerm.label, false);
      } catch (exc) {
        setExprBldrMessage({ severity: 'danger', message: (exc as Error)?.message });
      }
    },
    [expressionBuilder, incompleteMessage]
  );
  const onSelectOperator = useCallback(
    (exprOper: ExpressionNode): void => {
      try {
        expressionBuilder.insertToken({ type: 'LogicalExpression', value: exprOper.label });
        setExprGroupDepth(expressionBuilder.groupDepth());
        setFormula(expressionBuilder.stringifyExpression());
        setExprBldrMessage(expressionBuilder.isComplete() ? null : incompleteMessage);
      } catch (exc) {
        setExprBldrMessage({ severity: 'danger', message: (exc as Error)?.message });
      }
    },
    [expressionBuilder, incompleteMessage]
  );

  const requireAllSelectedTasks = useCallback((): void => {
    const andOperator = { nodeType: 'logicalOperator', label: '&&' };
    availableExpressionTerms.forEach((exprTerm, i) => {
      if (i > 0) {
        onSelectOperator(andOperator);
      }
      onSelectTerm(exprTerm);
    });
  }, [availableExpressionTerms, onSelectOperator, onSelectTerm]);

  // Preload the JobMatrix's associated `Task`s.  This list might not exist when
  //	historical JobMatrix definitions no longer exist.  In that case, the
  //	associated `Task`s will need to extracted from the expression.
  const [prevJobMatrixUsedTaskList, setPrevJobMatrixUsedTaskList] = useState<TaskModel[]>([]);
  useTaskByJobMatrixReadList(
    { companyId, jobMatrixId: jobMatrix.id },
    {
      refetchOnWindowFocus: false,
      // There are no `Task`s to get for a new JobMatrix.
      enabled: !Number.isNaN(jobMatrix.id) && jobMatrix.id > 0,
      onSuccess(taskGetListResp: TaskGetListResponse) {
        setPrevJobMatrixUsedTaskList(taskGetListResp.data);
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError(jobMatrixGetError: Error) {
        // setJobMatrixError(jobMatrixGetError?.message);
      },
    }
  );
  const initBuilderWithExpression = useCallback(
    (taskMappingExpression: string) => {
      // Logic to compare task mapping expression's task name and code with the current task names and codes from DB
      // Those could have changed since this expression was originally saved, so we want to show the correct name/code
      // Checking for a cleared expression (Clear All button used) which will have a value of ;
      if (taskMappingExpression && taskMappingExpression !== ';') {
        expressionBuilder.initWithExpression({
          code: taskMappingExpression,
          tokenCallback: (callee, json) => {
            // Update expression token's details to have the current task name and code
            const tokenId: number | undefined =
              typeof json === 'object' && !Array.isArray(json) ? (json?.id as number) : undefined;
            const prevTask = prevJobMatrixUsedTaskList.find(
              task => callee === 'task' && task.id === tokenId
            );
            const newJSON = json as JSONObject;
            if (prevTask) {
              newJSON.label = prevTask.code;
              newJSON.description = prevTask.name;
            }
            return newJSON;
          },
        });
      }
    },
    [expressionBuilder, prevJobMatrixUsedTaskList]
  );
  useEffect(() => {
    // If the JobMatrix has no expression because it is historical.
    if (
      !jobMatrixIsLoading &&
      jobMatrix.id > 0 &&
      taskMappingAutoBuilt === false &&
      availableExpressionTerms.length > 0
    ) {
      if (jobMatrix.taskMappingExpression) {
        initBuilderWithExpression(jobMatrix.taskMappingExpression);
      } else {
        requireAllSelectedTasks();
      }
      setTaskMappingAutoBuilt(true);
    }
  }, [
    availableExpressionTerms,
    jobMatrix.id,
    jobMatrix.taskMappingExpression,
    taskMappingAutoBuilt,
    requireAllSelectedTasks,
    initBuilderWithExpression,
    jobMatrixIsLoading,
  ]);

  // have to use a reference to avoid inifinite loop with selectedTasksPaletteVisibleState being changed over and over
  const selectedTasksPaletteEnabledStateRef = useRef(selectedTasksPaletteVisibleState);
  // use this function if we want to pass in all the selected tasks as an array
  const updateSelectedTasksPaletteVisibleStateCallbackAllSelectedTasks = useCallback(
    (selectedTasks: ExpressionNode[]) => {
      const tempSelectedTaskVisibility: EquivalenciesExpressionPaletteButtonVisibleState = {};
      selectedTasks.forEach(selectedTask => {
        if (selectedTasksPaletteEnabledStateRef.current[selectedTask.label] !== undefined) {
          // this task was already selected, keep its current visible state
          tempSelectedTaskVisibility[selectedTask.label] =
            selectedTasksPaletteEnabledStateRef.current[selectedTask.label].valueOf();
        } else {
          // this task was just selected, so add to our visibility variable as true (visible)
          tempSelectedTaskVisibility[selectedTask.label] = true;
        }
      });
      selectedTasksPaletteEnabledStateRef.current = tempSelectedTaskVisibility;
      // call setter function so our "main" variable is updated, which in turn should cause the palette component to be aware of the changes
      setSelectedTaskPaletteVisibility(selectedTasksPaletteEnabledStateRef.current);
    },
    []
  );

  // use this function if we want to update one task at a time
  const updateSelectedTasksPaletteVisibleState = (taskCode: string, isVisible: boolean) => {
    selectedTasksPaletteEnabledStateRef.current[taskCode] = isVisible;
    setSelectedTaskPaletteVisibility(selectedTasksPaletteEnabledStateRef.current);
  };

  useEffect(() => {
    availableExpressionTermsRef.current = prevJobMatrixUsedTaskList.map(taskModel => ({
      nodeType: 'task',
      label: taskModel.code,
      description: taskModel.name,
      termId: taskModel.id,
      evaluation: undefined,
    }));
    // Update expression terms for the expression.
    setAvailableExpressionTerms(availableExpressionTermsRef.current);
    // Update visibility.
    prevJobMatrixUsedTaskList.forEach(taskModel =>
      updateSelectedTasksPaletteVisibleState(taskModel.code, false)
    );
    // Backfilling Step 2 selected tasks for an existing job when the page loads, so when the user manually selects
    // tasks from Step 2 it doesn't cause the tasks already in the palette to disappear because they're not in the Step 2 selected tasks
    setSelectedTaskDisplayItems(
      prevJobMatrixUsedTaskList.map(prevTask => ({
        ...prevTask,
        itemId: prevTask.id,
        display: prevTask.name,
      }))
    );
  }, [prevJobMatrixUsedTaskList, updateSelectedTasksPaletteVisibleStateCallbackAllSelectedTasks]);

  const [exprAsHTML, setExprAsHTML] = useState<HTMLElement | null>(null);
  // Using a state variable to track the expression depth to reduce calls on re-`render`s.
  const [exprGroupDepth, setExprGroupDepth] = useState(() => expressionBuilder?.groupDepth() || 0);
  const [formula, setFormula] = useState<string>();
  // Component behavior.
  const visualizer = new TreeToDiagramTraverserDelegate({
    canvasSetter: (elem: HTMLElement) => {
      setExprAsHTML(elem);
    },
    exprTreeGetter: () => expressionBuilder.serializeTree(),
  });
  // Use `useEventListener` to prevent the event listener from being added on every render.
  // `useEventListener` does not handle `EventListenerObject`s so emulate an `EventListener` using `bind`.
  useEventListener(expressionBuilder, 'insert', visualizer.handleEvent.bind(visualizer));
  useEventListener(expressionBuilder, 'reset', visualizer.handleEvent.bind(visualizer));
  useEventListener(expressionBuilder, 'insert', (evt: Event): void => {
    const customEvent = evt as CustomEvent<ExprEventDetail>;
    if (customEvent) {
      const exprToken: ExprToken | null = customEvent.detail.token;
      if (
        exprToken?.type === 'CallExpression' &&
        typeof exprToken.raw === 'object' &&
        exprToken.raw !== null
      ) {
        const rawParsed = exprToken.raw as JSONObject;
        if (rawParsed.id && !Number.isNaN(rawParsed.id)) {
          const jobMatrixTaskId = rawParsed.id;
          setNextJobMatrixUsedTaskIds(currentJobMatrixUsedTaskIds => {
            const id = parseInt(jobMatrixTaskId.toString(), 10);
            if (currentJobMatrixUsedTaskIds.includes(id)) return currentJobMatrixUsedTaskIds;
            return currentJobMatrixUsedTaskIds.concat(id);
          });
        }
      }
    }
  });
  useEventListener(expressionBuilder, 'complete', () =>
    setJobMatrix(theJobMatrix => ({
      ...theJobMatrix,
      taskMappingExpression: expressionBuilder.stringifyExpression(),
    }))
  );
  const buttonIsDisabledInitialState: EquivalenciesExpressionPaletteButtonDisabledState = {
    closeParen: true,
    logicalOper: true,
    openParen: false,
    taskTerm: false,
  };
  const [buttonIsDisabled, setButtonIsDisabled] = useState(buttonIsDisabledInitialState);
  const updateButtonDisabledState = () => {
    const nextTokens: ExprToken[] = expressionBuilder.calculateNextValidTokens();
    // Disable all.
    buttonIsDisabled.logicalOper = true;
    buttonIsDisabled.openParen = true;
    buttonIsDisabled.taskTerm = true;
    // Enable next valid inputs.
    nextTokens.forEach(nextToken => {
      switch (nextToken.type) {
        case 'CallExpression':
        case 'Literal':
          buttonIsDisabled.taskTerm = false;
          break;
        case 'LogicalExpression':
          buttonIsDisabled.logicalOper = false;
          break;
        case 'Punctuator':
          if (nextToken.value === '(') {
            buttonIsDisabled.openParen = false;
          }
          break;
        default:
          throw new Error(`Unexpected token type: '${nextToken.type}'.`);
      }
    });
    buttonIsDisabled.closeParen = expressionBuilder.groupDepth() === 0;
    setButtonIsDisabled(buttonIsDisabled);
  };
  // Use `useEventListener` to prevent the event listener from being added on every render.
  useEventListener(expressionBuilder, 'insert', updateButtonDisabledState);

  // Callbacks and event handlers.
  const availableExpressionTermsRef = useRef(availableExpressionTerms);
  const addedExprTermsCallback = useCallback(
    (selectedDisplayItems: TaskDisplayItem[]) => {
      const isAdditiveOnly = false;
      const displayItemsNotFound = isAdditiveOnly
        ? selectedDisplayItems.filter(displayItem => {
            const exprTermFound = availableExpressionTermsRef.current.find(
              exprTerm => displayItem.itemId === exprTerm.termId
            );
            return !exprTermFound;
          })
        : selectedDisplayItems;

      const addedExprTerms: ExpressionNode[] = displayItemsNotFound.map(displayItem => ({
        nodeType: 'task',
        termId: displayItem.itemId || undefined,
        label: displayItem.code,
        description: displayItem.display,
        value: undefined,
      }));

      availableExpressionTermsRef.current = isAdditiveOnly
        ? availableExpressionTermsRef.current.concat(addedExprTerms)
        : (availableExpressionTermsRef.current = addedExprTerms);

      setAvailableExpressionTerms(availableExpressionTermsRef.current);
      updateSelectedTasksPaletteVisibleStateCallbackAllSelectedTasks(
        availableExpressionTermsRef.current
      );
    },
    [updateSelectedTasksPaletteVisibleStateCallbackAllSelectedTasks]
  );

  useEffect(() => {
    addedExprTermsCallback(selectedTaskDisplayItems);
  }, [addedExprTermsCallback, selectedTaskDisplayItems]);
  const onSelectPunctuator = (exprPunc: ExpressionNode): void => {
    try {
      expressionBuilder.insertToken({ type: 'Punctuator', value: exprPunc.label });
      setExprGroupDepth(expressionBuilder.groupDepth());
      setFormula(expressionBuilder.stringifyExpression());
      setExprBldrMessage(expressionBuilder.isComplete() ? null : incompleteMessage);
    } catch (exc) {
      setExprBldrMessage({ severity: 'danger', message: (exc as Error)?.message });
    }
  };

  // At this point, `TaskModel`s and `ExprToken`s are difficult to compare (through `DisplayItem`/`TaskDisplayItem`).
  const removeOperators = (token: string) => !['&&', '||', '(', ')'].includes(token);
  const parseTokenParam = (token: string) => JSON.parse(token.replace(/\w+\((.*?)\)/, '$1'));
  const isDisplayItemInUseFilter = (displayItem: DisplayItem): boolean =>
    expressionBuilder
      .tokenize()
      // These `filter` and `map` processes are rather expensive, but simple
      //	comparisons of tokens with property values containing JSON
      //	characters like double-quote, (curly) braces, etc. were not working.
      .filter(removeOperators)
      .map(parseTokenParam)
      .some(tokenParam => tokenParam.description === displayItem.display);
  const reenableItemsInPalette = (): void => {
    setButtonIsDisabled(buttonIsDisabledInitialState);
    // Loops through all the tasks in the palette and re-enables them, used when the user clears the mapping they were building to start over
    Object.keys(selectedTasksPaletteEnabledStateRef.current).forEach(key => {
      selectedTasksPaletteEnabledStateRef.current[key] = true;
    });
  };

  const resetEquivalenciesMapper = (): void => {
    // Reset expression and, as a result, canvas.  Also resets the palette buttons (AND, OR, and grouping buttons)
    expressionBuilder.reset();
    // Reset IDs of used Tasks.
    setNextJobMatrixUsedTaskIds([]);
    // Reset the expression group depth manually, instead of attaching a 'reset' event handler.
    setExprGroupDepth(0);
    setFormula(expressionBuilder.stringifyExpression());
    // Reset expression message.
    setExprBldrMessage(null);
    // Reset palette task buttons' enabled status
    reenableItemsInPalette();
  };

  const saveJobMapping = async (
    theCompanyId: number,
    theJobMatrix: JobMatrix,
    theNextJobMatrixTaskIds: number[]
  ) => {
    await saveMutation.onSaveAsync(theCompanyId, theJobMatrix, theNextJobMatrixTaskIds);
  };

  const userSaveJob = async () => {
    await saveJobMapping(companyId, jobMatrix, nextJobMatrixUsedTaskIds);
  };

  const formIsValid = useCallback(
    () =>
      expressionBuilder.isEmpty() || !expressionBuilder.isComplete() || jobMatrix.title.length < 1,
    [expressionBuilder, jobMatrix]
  );

  // Component.
  return (
    <>
      <Container fluid>
        <Row style={{ columnGap: '75px' }}>
          <Col xl={4}>
            <EquivalenciesSelectTasks
              isEditing={isEditing}
              availableSeriesList={availableSeriesList}
              seriesListIsLoading={seriesListIsLoading}
              setIncludeHistoricalTasks={setIncludeHistoricalTasks}
              includeHistoricalTasks={includeHistoricalTasks}
              setSelectedSeries={setSelectedSeries}
              selectedSeriesId={selectedSeriesId}
              setSelectedTaskDisplayItems={setSelectedTaskDisplayItems}
              taskDisplayItems={taskDisplayItems}
              selectedTaskDisplayItems={selectedTaskDisplayItems}
              error={errorNotification}
              isDisplayItemInUseFilter={isDisplayItemInUseFilter}
              isDisabled={isDisabled}
            />
          </Col>
          <Col xl={7}>
            <EquivalenciesExpressionPalette
              availableExpressionTerms={availableExpressionTerms}
              buttonIsDisabled={buttonIsDisabled}
              expressionGroupDepth={exprGroupDepth}
              onSelectTerm={onSelectTerm}
              onSelectOperator={onSelectOperator}
              onSelectPunctuator={onSelectPunctuator}
              selectedTasksPaletteVisibilityState={selectedTasksPaletteVisibleState}
              isDisabled={isDisabled}
            />
            <Alert variant={exprBldrMessage?.severity} hidden={!exprBldrMessage?.message}>
              {exprBldrMessage?.message}
            </Alert>
            <pre id="expressionFormula" style={{ display: 'none' }}>
              {formula}
            </pre>
            <EquivalenciesExpressionCanvas expressionAsHTML={exprAsHTML} isDisabled={isDisabled} />
            <div className="container p-2">
              <div className="row justify-content-between">
                <div className="col">
                  <ConfirmButton
                    text={messages.clearAll}
                    confirmText={messages.clearMapping}
                    disabled={isDisabled || expressionBuilder.isEmpty()}
                    onConfirm={resetEquivalenciesMapper}
                  />
                </div>
                <div className="col text-center">
                  <Button
                    type="button"
                    className="btn btn-secondary shadow me-2"
                    id="requireAll"
                    onClick={requireAllSelectedTasks}
                    disabled={isDisabled || !expressionBuilder.isEmpty()}
                  >
                    {messages.requireAll}
                  </Button>
                </div>
                <div className="col text-end">
                  <Button
                    type="button"
                    className="btn btn-success shadow"
                    id="saveJob"
                    onClick={userSaveJob}
                    disabled={isDisabled || formIsValid()}
                  >
                    {messages.saveJob}
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EquivalenciesMapper;
