import apiHooks, { ApiResult } from '../../../api/apiHooks';
import { CustomViewReport } from '../../../api/responses/customViewReport';
import defaultTaskProfileReportData from '../../../models/defaultTaskProfileReportData';
import { TaskProfileReportData } from '../../../models/taskProfileReportData';
import customViewReportConverter from '../../../utils/converter/customViewReportConverter';

const defaultTaskProfile: ApiResult<CustomViewReport<TaskProfileReportData>> = {
  data: {
    name: '',
    id: 0,
    reportData: defaultTaskProfileReportData,
  },
  isError: false,
  isLoading: false,
  isSuccess: true,
  isFetching: false,
};

function useInitialData(
  companyId: number,
  customViewId: number,
  isEditing: boolean
): ApiResult<CustomViewReport<TaskProfileReportData>> {
  const mutation = apiHooks.useCustomView(
    { companyId, customViewId },
    {
      select: (data: CustomViewReport<string>) => customViewReportConverter.convert(data),
      enabled: isEditing,
    }
  );

  if (isEditing) {
    return mutation;
  }

  return defaultTaskProfile;
}

export default useInitialData;
