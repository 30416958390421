import React from 'react';
import { useParams } from 'react-router-dom';
import apiHooks from '../../api/apiHooks';
import reportingAPIHooks from '../../api/apiHooksReporting';
import commonText from '../../constants/commonText';
import { OneMinuteFortyFiveSecondsInMilliseconds } from '../../constants/measurements';
import useCompany from '../../hooks/useCompany';
import useCompanyId from '../../hooks/useCompanyId';
import useCostCenters from '../../hooks/useCostCenters';
import useDepartments from '../../hooks/useDepartments';
import useEventListener from '../../hooks/useEventListener';
import useInterval from '../../hooks/useInterval';
import useQueryParam from '../../hooks/useQueryParam';
import useRoles from '../../hooks/useRoles';
import useSupervisors from '../../hooks/useSupervisors';
import useUserInformation from '../../hooks/useUserInformation';
import useWorkLocations from '../../hooks/useWorkLocations';
import usePolicyService from '../../policyService/usePolicyService';
import useCustomViewAccessUsers from './hooks/useCustomViewAccessUsers';
import useEmployees from './hooks/useEmployees';
import useInitialData from './hooks/useInitialData';
import useJobs from './hooks/useJobs';
import useMessageOfTheDayInactivity from './hooks/useMessageOfTheDayInactivity';
import useSave from './hooks/useSave';
import useSharedUsers from './hooks/useSharedUsers';
import TaskProfileReport from './TaskProfileReport';
import useUserId from '../../hooks/useUserId';

const TaskProfileReportContainer: React.FC = () => {
  const { companyId } = useCompanyId();
  const { id } = useParams<{ id: string }>();
  const { company, isLoading: isCompanyLoading } = useCompany();
  const { user, isLoading: isUserLoading } = useUserInformation();
  const customViewId = Number(id);
  const isEditing = !Number.isNaN(customViewId);
  const policyService = usePolicyService();
  const initialData = useInitialData(companyId, customViewId, isEditing);

  const deleteMutation = apiHooks.useDeleteCustomView();
  const saveMutation = useSave(companyId, customViewId, isEditing);

  apiHooks.useUpdateCustomViewConnection(
    { companyId, customViewId },
    { refetchInterval: OneMinuteFortyFiveSecondsInMilliseconds, enabled: isEditing }
  );

  const hasMessageOfTheDayImage =
    initialData.data !== undefined && initialData.data.reportData.messageOfTheDay.image.length > 0;

  const { data: messageOfTheDayImageData } = apiHooks.useGetMessageOfTheDayImage(
    { companyId, customViewId },
    { enabled: isEditing && hasMessageOfTheDayImage, refetchOnWindowFocus: false }
  );

  const customViewAccessUsersResult = useCustomViewAccessUsers(companyId, customViewId, isEditing);

  return (
    <TaskProfileReport
      reportHeader={isEditing ? initialData.data?.name ?? '' : commonText.live}
      canDeleteView={isEditing}
      saveMutation={saveMutation}
      reportDataResult={initialData}
      deleteMutation={deleteMutation}
      customViewAccessUsersResult={customViewAccessUsersResult}
      customViewId={customViewId}
      policyService={{
        policies: policyService.policies(customViewId || undefined),
        isLoading: policyService.isLoading,
        isError: policyService.isError,
      }}
      messageOfTheDayImageResult={messageOfTheDayImageData?.getSignedUrl ?? ''}
      onGetUserStatistics={apiHooks.useCompaniesUsersStatistics}
      companyId={companyId}
      company={company}
      isCompanyLoading={isCompanyLoading}
      isUserLoading={isUserLoading}
      useCostCenters={useCostCenters}
      useDepartments={useDepartments}
      useEmployees={useEmployees}
      useEventListener={useEventListener}
      useInterval={useInterval}
      useJobs={useJobs}
      useMessageOfTheDayInactivity={useMessageOfTheDayInactivity}
      useQueryParam={useQueryParam}
      useRoles={useRoles}
      useSharedUsers={useSharedUsers}
      useSupervisors={useSupervisors}
      useWorkLocations={useWorkLocations}
      user={user}
      useCompanyQualifications={apiHooks.useCompanyQualifications}
      useCompaniesUsersComponents={apiHooks.useCompaniesUsersComponents}
      useCompaniesUsersJobs={apiHooks.useCompaniesUsersJobs}
      useCompaniesUsersRoles={apiHooks.useCompaniesUsersRoles}
      useCompaniesUsersTasks={apiHooks.useCompaniesUsersTasks}
      useCompaniesUsersTrainings={apiHooks.useCompaniesUsersTrainings}
      useUserId={useUserId}
      useReportRequest={reportingAPIHooks.useReportRequest}
      onRequestJobStats={reportingAPIHooks.useJobStatsRequest}
    />
  );
};

export default TaskProfileReportContainer;
