export function calculateIncomplete(complete: number, total: number): number {
  const completeValue = Math.max(0, complete);
  const totalValue = Math.max(0, complete, total);

  return totalValue - completeValue;
}

export default {
  calculateIncomplete,
};
