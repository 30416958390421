import React from 'react';
import Card from 'react-bootstrap/Card';
import Skeleton from '../Skeleton/Skeleton';

const cardStatSkeletonStyle: React.CSSProperties = {
  maxWidth: '8rem',
};

interface TaskProfileCardStatProps {
  className: string;
  isLoading: boolean;
  children: React.ReactNode;
}

const CardStat: React.FC<TaskProfileCardStatProps> = ({ className, isLoading, children }) => {
  return (
    <Card.Text className={className}>
      {isLoading ? <Skeleton style={cardStatSkeletonStyle} /> : <>{children}</>}
    </Card.Text>
  );
};

export default CardStat;
