import React from 'react';
import { Button, Overlay, Tooltip } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/types';
import { RiFileCopyLine } from 'react-icons/ri';
import useCopyButton from './SshkeyModal/useCopyButton';

export interface CopyButtonProps {
  textToCopy: string;
  toolTipPlacement: Placement;
  classname?: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ textToCopy, toolTipPlacement, classname }) => {
  const { handleOnCopyButtonClick, copyButtonRef, isToolTipOpen } = useCopyButton(textToCopy);

  return (
    <>
      <Button
        className={classname}
        variant="transparent"
        onClick={handleOnCopyButtonClick}
        ref={copyButtonRef}
      >
        <RiFileCopyLine size="1.25rem" />
      </Button>
      <Overlay placement={toolTipPlacement} target={copyButtonRef} show={isToolTipOpen}>
        <Tooltip>Copied to clipboard!</Tooltip>
      </Overlay>
    </>
  );
};

export default CopyButton;
