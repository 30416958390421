import { useEffect, useState } from 'react';
import { DisplayItem } from '../models/displayItem';
import { UseDisplayItemsResponse } from '../models/useDisplayItemsResponse';

function useCompanyTypes(companyId: number, searchText: string): UseDisplayItemsResponse {
  useEffect(() => {
    const dataList: DisplayItem[] = [
      // {
      //   itemId: 4,
      //   display: 'Test',
      // },
      {
        itemId: 3,
        display: 'Approved Provider',
      },
      {
        itemId: 2,
        display: 'Contractor',
      },
      {
        itemId: 1,
        display: 'Operator',
      },
      // {
      //   itemId: 0,
      //   display: 'Unknown',
      // },
    ];
    setData(dataList.filter(ct => ct.display.includes(searchText)));
  }, [searchText]);

  const [data, setData] = useState<DisplayItem[]>([]);

  return { data, isLoading: false };
}

export default useCompanyTypes;
