import React, { CSSProperties } from 'react';
import { DisplayItem } from '../../models/displayItem';
import BadgeListItem from './BadgeListItem/BadgeListItem';
import MultiPartSelectBase from './MultiPartSelectBase/MultiPartSelectBase';

export interface MultiPartSelectProps {
  items: DisplayItem[];
  onSearch: (text: string) => void;
  onChange: (item: DisplayItem) => void;
  selectedDisplayItems: DisplayItem[];
  emptyListText: string;
  isLoading: boolean;
  onClear: () => void;
  onSelectAll: () => void;
  availableListGroupStyle?: CSSProperties;
  isDisabled?: boolean;
  tabId: string;
}

const MultiPartSelect: React.FC<MultiPartSelectProps> = ({
  items,
  onSearch,
  onChange,
  selectedDisplayItems,
  emptyListText,
  isLoading,
  onClear,
  onSelectAll,
  tabId,
  availableListGroupStyle = {},
  isDisabled = false,
}) => {
  return (
    <MultiPartSelectBase
      items={items}
      onSearch={onSearch}
      onChange={onChange}
      numberOfSelected={selectedDisplayItems.length}
      emptyListText={emptyListText}
      isLoading={isLoading}
      onClear={onClear}
      onSelectAll={onSelectAll}
      selectedList={
        <>
          {Object.values(selectedDisplayItems).map(listItem => (
            <BadgeListItem
              key={listItem.itemId}
              onClick={() => onChange(listItem)}
              text={listItem.display}
            />
          ))}
        </>
      }
      selectedListItems={selectedDisplayItems.reduce((itemHash, displayItem) => {
        const tempHash = itemHash;
        tempHash[displayItem.itemId] = displayItem;
        return tempHash;
      }, {} as Record<number, DisplayItem>)}
      selectedListGroupClassName="d-flex flex-wrap overflow-auto flex-shrink-0"
      availableListGroupStyle={availableListGroupStyle}
      isDisabled={isDisabled}
      tabId={tabId}
    />
  );
};

export default MultiPartSelect;
