import apiHooks from '../api/apiHooks';
import useFeatureFlagService from '../feature-flags/useFeatureFlagService';
import useCompanyId from '../hooks/useCompanyId';
import { UsersPolicies } from '../models/usersPolicies';
import policyConverter, { PolicyServiceResponse } from '../utils/converter/policyConverter';
import policyEnforcer, { AvailablePolicies } from './policyEnforcer/policyEnforcer';
import UsersCustomViewAccessPolicies from './usersCustomViewAccessPolicies';

const defaultPolicyServiceResponse: PolicyServiceResponse = {
  canDeleteCustomView: false,
  canUpdateCustomView: false,
  canViewCustomViewConnections: false,
  canCreateCustomView: false,
  canViewCustomView: false,
  canViewTaskProfiling: false,
  canCreateJob: false,
  canUpdateJob: false,
  canViewIntegrationHub: false,
};

function usePolicyService(): {
  policies: (customViewId?: number) => AvailablePolicies;
  isLoading: boolean;
  isError: boolean;
} {
  const { companyId } = useCompanyId();
  const { isEnabled } = useFeatureFlagService();
  const { data, isLoading, isError } = apiHooks.useGetUsersPolicies(
    { companyId },
    { select: policyConverter.convert }
  );

  const {
    data: getUsersCustomViewPoliciesResponse,
    isLoading: isUsersCustomViewPoliciesLoading,
    isError: isUsersCustomViewPoliciesError,
  } = apiHooks.useGetUsersCustomViewPolicies({
    companyId,
  });

  function calculateUsersPolicies(customViewId?: number): UsersPolicies {
    return {
      userTypePolicies: data ?? defaultPolicyServiceResponse,
      usersCustomViewAccessPolicies: new UsersCustomViewAccessPolicies(
        getUsersCustomViewPoliciesResponse?.customViewAccessResponses ?? [],
        customViewId
      ),
    };
  }

  return {
    policies: customViewId => {
      return policyEnforcer(calculateUsersPolicies(customViewId), isEnabled, customViewId);
    },
    isLoading: isLoading || isUsersCustomViewPoliciesLoading,
    isError: isError || isUsersCustomViewPoliciesError,
  };
}

export default usePolicyService;
