import React from 'react';
import { Form } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { BsQuestionCircle } from 'react-icons/bs';
import commonText from '../../../constants/commonText';
import testIds from '../../../constants/testIds';
import { DropDownSelectionType } from '../../../pages/CreateUserIntegration/defaultDropDownSelections';
import { UserCreationDataPointId } from '../../../models/UserCreationDataPoint';
import { UserCreationDataPointLabel } from '../../../models/UserCreationDataPointLabel';
import ToolTip from '../../ToolTip/ToolTip';

export interface ApiMappingCardProps {
  checkBoxSelections: DropDownSelectionType;
  handleOnChange: (
    dataPointId: UserCreationDataPointId,
    dataPointLabel: UserCreationDataPointLabel,
    isChecked: boolean
  ) => void;
}

const ApiMappingCard: React.FC<ApiMappingCardProps> = ({ checkBoxSelections, handleOnChange }) => {
  return (
    <Card className="mr-auto mt-4" data-testid={testIds.apiMappingCard()}>
      <Card.Header className="bg-gray">
        <h5 className="fw-bold">Mapping</h5>
      </Card.Header>
      <Card.Body>
        <Alert className="d-flex flex-column" variant="info">
          <span>
            Select any additional fields that are included in your upload file. Note: Required
            fields cannot be deselected.
          </span>
        </Alert>

        <Row className="mt-4">
          <Col>
            <div className="d-flex flex-row text-nowrap mr-2 my-auto">
              <Form.Check
                id={checkBoxSelections.status.dataPointId}
                label={checkBoxSelections.status.dataPointLabel}
                checked
                disabled
              />
              <ToolTip content={commonText.userStatusToolTipText}>
                <BsQuestionCircle
                  className="ms-1 mb-1"
                  data-testid={testIds.userStatusTooltipContainer()}
                />
              </ToolTip>
            </div>
            <Form.Check
              id={checkBoxSelections.email.dataPointId}
              label={checkBoxSelections.email.dataPointLabel}
              checked
              disabled
            />
            <Form.Check
              id={checkBoxSelections.username.dataPointId}
              label={checkBoxSelections.username.dataPointLabel}
              checked
              disabled
            />
            <Form.Check
              id={checkBoxSelections.username2.dataPointId}
              label={checkBoxSelections.username2.dataPointLabel}
              onChange={event =>
                handleOnChange(
                  checkBoxSelections.username2.dataPointId,
                  checkBoxSelections.username2.dataPointLabel,
                  event.target.checked
                )
              }
              checked={checkBoxSelections.username2.columnNumber !== null}
            />
            <Form.Check
              id={checkBoxSelections.firstName.dataPointId}
              label={checkBoxSelections.firstName.dataPointLabel}
              checked
              disabled
            />
            <Form.Check
              id={checkBoxSelections.middleName.dataPointId}
              label={checkBoxSelections.middleName.dataPointLabel}
              onChange={event =>
                handleOnChange(
                  checkBoxSelections.middleName.dataPointId,
                  checkBoxSelections.middleName.dataPointLabel,
                  event.target.checked
                )
              }
              checked={checkBoxSelections.middleName.columnNumber !== null}
            />
            <Form.Check
              id={checkBoxSelections.lastName.dataPointId}
              label={checkBoxSelections.lastName.dataPointLabel}
              checked
              disabled
            />
            <Form.Check
              id={checkBoxSelections.hireDate.dataPointId}
              label={checkBoxSelections.hireDate.dataPointLabel}
              onChange={event =>
                handleOnChange(
                  checkBoxSelections.hireDate.dataPointId,
                  checkBoxSelections.hireDate.dataPointLabel,
                  event.target.checked
                )
              }
              checked={checkBoxSelections.hireDate.columnNumber !== null}
            />
          </Col>
          <Col>
            <Form.Check
              id={checkBoxSelections.supervisorUsername.dataPointId}
              label={checkBoxSelections.supervisorUsername.dataPointLabel}
              onChange={event =>
                handleOnChange(
                  checkBoxSelections.supervisorUsername.dataPointId,
                  checkBoxSelections.supervisorUsername.dataPointLabel,
                  event.target.checked
                )
              }
              checked={checkBoxSelections.supervisorUsername.columnNumber !== null}
            />
            <Form.Check
              id={checkBoxSelections.supervisor2Username.dataPointId}
              label={checkBoxSelections.supervisor2Username.dataPointLabel}
              onChange={event =>
                handleOnChange(
                  checkBoxSelections.supervisor2Username.dataPointId,
                  checkBoxSelections.supervisor2Username.dataPointLabel,
                  event.target.checked
                )
              }
              checked={checkBoxSelections.supervisor2Username.columnNumber !== null}
            />
            <Form.Check
              id={checkBoxSelections.department.dataPointId}
              label={checkBoxSelections.department.dataPointLabel}
              onChange={event =>
                handleOnChange(
                  checkBoxSelections.department.dataPointId,
                  checkBoxSelections.department.dataPointLabel,
                  event.target.checked
                )
              }
              checked={checkBoxSelections.department.columnNumber !== null}
            />
            <Form.Check
              id={checkBoxSelections.costCenter.dataPointId}
              label={checkBoxSelections.costCenter.dataPointLabel}
              onChange={event =>
                handleOnChange(
                  checkBoxSelections.costCenter.dataPointId,
                  checkBoxSelections.costCenter.dataPointLabel,
                  event.target.checked
                )
              }
              checked={checkBoxSelections.costCenter.columnNumber !== null}
            />
            <Form.Check
              id={checkBoxSelections.companySite.dataPointId}
              label={checkBoxSelections.companySite.dataPointLabel}
              onChange={event =>
                handleOnChange(
                  checkBoxSelections.companySite.dataPointId,
                  checkBoxSelections.companySite.dataPointLabel,
                  event.target.checked
                )
              }
              checked={checkBoxSelections.companySite.columnNumber !== null}
            />
            <Form.Check
              id={checkBoxSelections.companyName.dataPointId}
              label={checkBoxSelections.companyName.dataPointLabel}
              onChange={event =>
                handleOnChange(
                  checkBoxSelections.companyName.dataPointId,
                  checkBoxSelections.companyName.dataPointLabel,
                  event.target.checked
                )
              }
              checked={checkBoxSelections.companyName.columnNumber !== null}
            />
            <Form.Check
              id={checkBoxSelections.role.dataPointId}
              label={checkBoxSelections.role.dataPointLabel}
              onChange={event =>
                handleOnChange(
                  checkBoxSelections.role.dataPointId,
                  checkBoxSelections.role.dataPointLabel,
                  event.target.checked
                )
              }
              checked={checkBoxSelections.role.columnNumber !== null}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ApiMappingCard;
