enum Policy {
  CanViewCostCenters = 0,
  CanViewCompanySite = 1,
  CanViewCompanyDetails = 2,
  CanCreateCustomView = 3,
  CanViewCustomView = 4,
  CanUpdateCustomView = 5,
  CanDeleteCustomView = 6,
  CanViewDepartments = 7,
  CanViewQualifications = 8,
  CanViewUserRoles = 9,
  CanViewCompanyRoles = 10,
  CanViewSupervisors = 11,
  CanViewUserTasks = 12,
  CanViewUserComponents = 13,
  CanViewCompanyUsers = 14,
  CanViewUserStatistics = 15,
  CanViewCustomViewConnections = 16,
  CanViewUserTrainings = 17,
  CanViewOnBoardLive = 18,
  CanViewTaskProfiling = 19,
  CanViewJobs = 20,
  CanViewUserJobs = 21,
  CanViewQrCodeScanner = 22,
  CanViewMyTeam = 23,
  CanViewUserTranscripts = 24,
  CanCreateUsers = 25,
  CanCreateJob = 26,
  CanCreateSshUser = 27,
  CanUpdateJob = 28,
  CanViewIntegrationHub = 29,
}

export default Policy;
