const getDataUrlForImageFile = (
  file: File | undefined,
  callback: (dataUrlString: string) => void
): void => {
  if (file === undefined) {
    callback('');
  } else {
    const fileReader = new FileReader();
    fileReader.addEventListener('load', () => callback(fileReader.result as string), false);
    fileReader.readAsDataURL(file);
  }
};

const getFileExtension = (file: File): string => {
  const fileExtensionIndex = file.type.lastIndexOf('/');
  return file.type.substring(fileExtensionIndex + 1);
};

export default {
  getDataUrlForImageFile,
  getFileExtension,
};
