import { QueryFunctionContext } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';
import { DateTime } from 'luxon';
import authService from '../auth/authService';
import LearningHistoryRequestQueryParams from '../models/LearningHistoryRequestQueryParams';
import OdataQueryParams from '../models/OdataQueryParams';
import {
  GetLearningHistoryReportExcelRoute,
  GetLearningHistoryReportRoute,
  GetQualificationAuditInformationRoute,
  GetQualificationsReasonsRoute,
} from './apiRoutes';
import {
  GetLearningHistoryReportRequest,
  GetLearningHistoryReportRequestBody,
} from './requests/getLearningHistoryReport';
import { DateRangeFilter } from './requests/getActivitiesReportRequest';
import { LearningHistoryReportResponse } from './responses/learningHistoryReportResponse';
import { PaginatedResponse } from './responses/paginatedResponse';
import { QualificationAuditInformationResponse } from './responses/qualificationAuditInformationResponse';
import { ReasonsResponse } from './responses/reasonsResponse';
import {
  addPropertyToFilterObject,
  createOdataFilter,
  processSpecialCharacters,
  QueryFilters,
  QueryFilterType,
} from './utils/OdataHelpers';

const learningHistoryReportApi = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // set up url
  headers: {
    'Content-Type': 'application/json',
  },
});

function getLearningHistoryFilterQueryParam(
  request: GetLearningHistoryReportRequest,
  includeArrayValues: boolean
): QueryFilters[] {
  const {
    companyId,
    isCurrent,
    completedDate,
    expirationDate,
    expiresInDays,
    employeeIds,
    seriesIds,
    taskIds,
    qualificationStatus,
    reasons,
    supervisorIds,
    evaluatorIds,
    workLocationIds,
    costCenterIds,
    departmentIds,
  } = request;

  // Default filters
  let filters: QueryFilters[] = [
    {
      key: LearningHistoryRequestQueryParams.companyId,
      type: QueryFilterType.number,
      value: [companyId],
    },
  ];

  let filterCurrent = [true];
  if (isCurrent === 1) {
    filterCurrent = [false];
  } else if (isCurrent === 2) {
    filterCurrent = [];
  }
  filters = addPropertyToFilterObject(
    filters,
    LearningHistoryRequestQueryParams.isCurrent,
    filterCurrent,
    QueryFilterType.boolean
  );

  const minDate = new Date(0).toLocaleDateString('fr-CA');
  const maxDate = new Date(253402300799999).toLocaleDateString('fr-CA');

  let completedDateToSend: DateRangeFilter = {
    To: completedDate?.To || '',
    From: completedDate?.From || '',
  };
  if (completedDate !== undefined) {
    completedDateToSend.From = completedDateToSend.From === '' ? minDate : completedDateToSend.From;
    completedDateToSend.To = completedDateToSend.To === '' ? maxDate : completedDateToSend.To;
  }

  filters = addPropertyToFilterObject(
    filters,
    LearningHistoryRequestQueryParams.completedDate,
    completedDateToSend,
    QueryFilterType.dateRange
  );

  let expirationDateToSend: DateRangeFilter = {
    To: expirationDate?.To || '',
    From: expirationDate?.From || '',
  };
  if (expirationDate !== undefined) {
    expirationDateToSend.From =
      expirationDateToSend.From === '' ? minDate : expirationDateToSend.From;
    expirationDateToSend.To = expirationDateToSend.To === '' ? maxDate : expirationDateToSend.To;
  }

  filters = addPropertyToFilterObject(
    filters,
    LearningHistoryRequestQueryParams.expirationDate,
    expirationDateToSend,
    QueryFilterType.dateRange
  );

  const expiresInDaysValue =
    expiresInDays === undefined || expiresInDays === 0
      ? []
      : {
          From: DateTime.fromJSDate(new Date(Date.now()))
            .startOf('day')
            .plus({ days: expiresInDays })
            .toISO(),
          To: DateTime.fromJSDate(new Date(Date.now()))
            .endOf('day')
            .plus({ days: expiresInDays })
            .toISO(),
        };
  filters.push({
    key: LearningHistoryRequestQueryParams.expirationDate,
    type: QueryFilterType.dateRange,
    value: expiresInDaysValue,
  });

  filters = addPropertyToFilterObject(
    filters,
    LearningHistoryRequestQueryParams.employees,
    employeeIds,
    QueryFilterType.number
  );

  filters = addPropertyToFilterObject(
    filters,
    LearningHistoryRequestQueryParams.seriesIds,
    seriesIds,
    QueryFilterType.number
  );

  filters = addPropertyToFilterObject(
    filters,
    LearningHistoryRequestQueryParams.tasksIds,
    taskIds,
    QueryFilterType.number
  );

  filters = addPropertyToFilterObject(
    filters,
    LearningHistoryRequestQueryParams.reasons,
    reasons,
    QueryFilterType.string
  );

  filters = addPropertyToFilterObject(
    filters,
    LearningHistoryRequestQueryParams.qualificationStatus,
    qualificationStatus,
    QueryFilterType.number
  );

  if (includeArrayValues) {
    filters = addPropertyToFilterObject(
      filters,
      LearningHistoryRequestQueryParams.workLocations,
      workLocationIds,
      QueryFilterType.array
    );

    filters = addPropertyToFilterObject(
      filters,
      LearningHistoryRequestQueryParams.costCenters,
      costCenterIds,
      QueryFilterType.array
    );

    filters = addPropertyToFilterObject(
      filters,
      LearningHistoryRequestQueryParams.departments,
      departmentIds,
      QueryFilterType.array
    );
  }

  filters = addPropertyToFilterObject(
    filters,
    [LearningHistoryRequestQueryParams.activities, LearningHistoryRequestQueryParams.supervisors],
    supervisorIds,
    QueryFilterType.arrayArrayProperty
  );

  filters = addPropertyToFilterObject(
    filters,
    [LearningHistoryRequestQueryParams.activities, LearningHistoryRequestQueryParams.evaluator],
    evaluatorIds,
    QueryFilterType.arrayNumberProperty
  );

  return filters;
}

learningHistoryReportApi.interceptors.request.use(async config => {
  const bearerToken = await authService.getAuthSession();
  if (config !== undefined && config.headers !== undefined) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${bearerToken.token.idToken}`;
  }

  return config;
});

async function getLearningHistoryReport(
  getLearningHistoryReportRequest: GetLearningHistoryReportRequest,
  options: QueryFunctionContext<(string | GetLearningHistoryReportRequest)[], number>
): Promise<PaginatedResponse<LearningHistoryReportResponse>> {
  const { sortBy, searchText } = getLearningHistoryReportRequest;

  const params = new URLSearchParams();

  const filters = getLearningHistoryFilterQueryParam(getLearningHistoryReportRequest, true);
  const filterQuery = createOdataFilter(filters);

  // Filter
  params.append(OdataQueryParams.filter, filterQuery);

  // Paging
  params.append(OdataQueryParams.skip, options.pageParam?.toString() || '0');
  params.append(OdataQueryParams.top, '50');

  // Sorting
  const sorts = sortBy?.map(elem => `${elem.key}${elem.isDesc ? ' desc' : ''}`);
  if (sorts !== undefined && sorts?.length > 0)
    params.append(OdataQueryParams.sortBy, sorts?.join(','));

  // Search
  if (searchText !== undefined && searchText.length > 0) {
    const processedSearchText = processSpecialCharacters(searchText);
    params.append(OdataQueryParams.searchText, `"${processedSearchText}"`);
  }

  // Count
  params.append(OdataQueryParams.count, 'true');

  const config = {
    params,
  };

  const response = await learningHistoryReportApi.get<
    PaginatedResponse<LearningHistoryReportResponse>
  >(GetLearningHistoryReportRoute(), config);
  return response.data;
}

async function getQualificationsReasons(companyId: number): Promise<ReasonsResponse> {
  const config: AxiosRequestConfig = { params: { companyId } };
  const response = await learningHistoryReportApi.get<ReasonsResponse>(
    GetQualificationsReasonsRoute(),
    config
  );

  return response.data;
}

async function getLearningHistoryReportExcel(
  requestConfig: GetLearningHistoryReportRequest
): Promise<number> {
  const params = new URLSearchParams();
  const filters = getLearningHistoryFilterQueryParam(requestConfig, false);
  const filterQuery = createOdataFilter(filters);

  const { costCenterIds, departmentIds, workLocationIds } = requestConfig;

  const body: GetLearningHistoryReportRequestBody = {
    [LearningHistoryRequestQueryParams.costCenters]: costCenterIds || [],
    [LearningHistoryRequestQueryParams.departments]: departmentIds || [],
    [LearningHistoryRequestQueryParams.workLocations]: workLocationIds || [],
    [LearningHistoryRequestQueryParams.roles]: [],
  };

  // Filter
  params.append(OdataQueryParams.filter, filterQuery);
  const config = { params };
  const response = await learningHistoryReportApi.post(
    GetLearningHistoryReportExcelRoute(),
    body,
    config
  );
  return response.status;
}

async function getQualificationAuditInformation(
  qualificationId: string
): Promise<QualificationAuditInformationResponse[]> {
  const response = await learningHistoryReportApi.get<QualificationAuditInformationResponse[]>(
    GetQualificationAuditInformationRoute(qualificationId)
  );
  return response.data;
}

export default {
  getLearningHistoryReport,
  getQualificationsReasons,
  getLearningHistoryReportExcel,
  getQualificationAuditInformation,
};
