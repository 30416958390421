import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { Fade } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { AiOutlineFileExcel } from 'react-icons/ai';
import { BsFunnel } from 'react-icons/bs';
import { GetLearningHistoryReportRequest } from '../../api/requests/getLearningHistoryReport';
import { CompanyResponse } from '../../api/responses/companyResponse';
import { LearningHistoryReportResponse } from '../../api/responses/learningHistoryReportResponse';
import { PaginatedResponse } from '../../api/responses/paginatedResponse';
import { QualificationAuditInformationResponse } from '../../api/responses/qualificationAuditInformationResponse';
import { UserInformation } from '../../api/responses/userInformation';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import TopNavbar from '../../components/Layout/TopNavBar/TopNavBar';
import LearningHistoryDrawer, {
  learningHistoryReportShowOptions,
} from '../../components/LearningHistory/LearningHistoryDrawer/LearningHistoryDrawer';
import LearningHistoryTable from '../../components/LearningHistory/LearningHistoryTable/LearningHistoryTable';
import SearchBox from '../../components/SearchBox/SearchBox';
import ToastMessage from '../../components/Toast/ToastMessage';
import commonText from '../../constants/commonText';
import testIds from '../../constants/testIds';
import { DropdownOption } from '../../models/DropdownOption';
import { ToastMessageInformation } from '../../models/ToastMessageInformation';
import { UseDisplayItemsResponse } from '../../models/useDisplayItemsResponse';
import { AvailablePolicies } from '../../policyService/policyEnforcer/policyEnforcer';
import { ToastInformation } from '../ActivitiesReport/ActivitiesReport';
import LifeCycleModal from './LifeCycleModal';

interface LearningHistoryProps {
  companyId: number;
  isCompanyLoading: boolean;
  isUserLoading: boolean;
  company: CompanyResponse | undefined;
  user: UserInformation | undefined;
  policies: Pick<
    AvailablePolicies,
    | 'canViewTaskProfile'
    | 'canViewCustomView'
    | 'canViewActivities'
    | 'canViewIntegrationHub'
    | 'canViewLearningHistory'
  >;
  learningHistoryData?: PaginatedResponse<LearningHistoryReportResponse>[];
  isLearningHistoryDataLoading: boolean;
  requestError: boolean;
  learningHistoryRequest: GetLearningHistoryReportRequest;
  downloadExcel: Partial<UseQueryResult<number | undefined>>;
  toastInformation: ToastInformation;
  lifeCycleToastInformation: ToastInformation;
  onLearningHistoryRequestChange: (newRequest: GetLearningHistoryReportRequest) => void;
  onPageChange: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<
    PaginatedResponse<LearningHistoryReportResponse>,
    AxiosError
  > | void>;
  useCostCenters: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useDepartments: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useActiveEmployeeStatus: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useEmployeesAll: (
    companyId: number,
    activeStatus: number,
    searchText: string
  ) => UseDisplayItemsResponse;
  useSeries: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useTasks: (companyId: number, seriesId: number, searchText: string) => UseDisplayItemsResponse;
  useQualificationStatus: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useReasons: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useWorkLocations: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useSupervisors: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useEvaluators: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  setToastInformation: (toastInformation: ToastInformation) => void;
  setLifeCycleInformation: (toastInformation: ToastInformation) => void;
  qualificationAuditInformation: QualificationAuditInformationResponse[];
  setQualificationId: React.Dispatch<string>;
  qualificationAuditInformationIsLoading: boolean;
  showLifeCycleDialog: boolean;
  setShowLifeCycleDialog: React.Dispatch<boolean>;
  hasNextPage: boolean;
}

const LearningHistory: React.FC<LearningHistoryProps> = ({
  isCompanyLoading,
  isUserLoading,
  company,
  companyId,
  user,
  policies,
  learningHistoryData,
  learningHistoryRequest,
  isLearningHistoryDataLoading,
  requestError,
  downloadExcel,
  toastInformation,
  onLearningHistoryRequestChange,
  setToastInformation,
  useCostCenters,
  useDepartments,
  useEmployeesAll,
  useActiveEmployeeStatus,
  useSeries,
  useTasks,
  useQualificationStatus,
  useReasons,
  useWorkLocations,
  useSupervisors,
  useEvaluators,
  qualificationAuditInformation,
  setQualificationId,
  qualificationAuditInformationIsLoading,
  showLifeCycleDialog,
  setShowLifeCycleDialog,
  lifeCycleToastInformation,
  setLifeCycleInformation,
  onPageChange,
  hasNextPage,
}) => {
  const [drawerExpanded, setDrawerExpanded] = useState<boolean>(false);
  const [activeShowOption, setActiveShowOption] = useState<DropdownOption>(
    learningHistoryReportShowOptions[0]
  );
  const [toast, setToast] = useState<ToastMessageInformation>({
    iconColor: 'success',
    headerText: '',
    bodyText: '',
  });
  const [lifeCycleToast, setLifeCycleToast] = useState<ToastMessageInformation>({
    iconColor: 'success',
    headerText: '',
    bodyText: '',
  });
  const [taskName, setTaskName] = useState<string>('');
  const [learningHistoryDataCount, setLearningHistoryDataCount] = useState<number>(0);

  useEffect(() => {
    const count = learningHistoryData?.reduce((accum, current) => {
      return accum + current.value.length;
    }, 0);
    setLearningHistoryDataCount(count || 0);
  }, [learningHistoryData]);

  useEffect(() => {
    if (toastInformation.showToast) {
      if (toastInformation.isSuccessful) {
        setToast({
          iconColor: 'success',
          headerText: commonText.excelSuccessTitle,
          bodyText: commonText.excelSuccessText,
        });
      } else {
        setToast({
          iconColor: 'danger',
          headerText: commonText.excelFailureTitle,
          bodyText: commonText.excelFailureText,
        });
      }
    }
  }, [toastInformation]);

  useEffect(() => {
    if (lifeCycleToastInformation.showToast) {
      if (!lifeCycleToastInformation.isSuccessful) {
        setLifeCycleToast({
          iconColor: 'danger',
          headerText: commonText.toastUnknownTitle,
          bodyText: commonText.toastUnknownText,
        });
      }
    }
  }, [lifeCycleToastInformation]);

  const handleToastDismiss = () => {
    setToastInformation({ showToast: false, isSuccessful: false });
    setLifeCycleInformation({ showToast: false, isSuccessful: false });
  };

  const onDrawerExpandChange = () => {
    setDrawerExpanded(currentExpand => !currentExpand);
  };

  const onSearchChange = (searchText: string) => {
    onLearningHistoryRequestChange({
      ...learningHistoryRequest,
      searchText,
    });
  };

  const handleActiveShowOptionChange = (newOption: DropdownOption) => {
    setActiveShowOption(newOption);
    onLearningHistoryRequestChange({
      ...learningHistoryRequest,
      isCurrent: newOption.id,
    });
  };

  const handleDownloadExcelClick = () => {
    if (downloadExcel !== undefined && downloadExcel.refetch !== undefined) {
      downloadExcel.refetch();
    }
  };

  const onHide = () => {
    setQualificationId('');
    setShowLifeCycleDialog(false);
  };

  return (
    <MainLayout
      isCompanyLoading={isCompanyLoading}
      companyImage={company?.logo}
      companyName={company?.name}
      policies={policies}
    >
      <TopNavbar reportName="Learning History" isUserLoading={isUserLoading} user={user}>
        <div className="d-inline-flex align-items-center me-2">
          <SearchBox size="sm" onChange={onSearchChange} />
        </div>
      </TopNavbar>
      <Container fluid className="h-100">
        <Fade
          className="drawer-overlay d-flex"
          in={qualificationAuditInformationIsLoading}
          unmountOnExit
          data-testid={testIds.auditSpinner()}
        >
          <Spinner className="bg-transparent m-auto" style={{ width: 150, height: 150 }} />
        </Fade>
        <Row className="mt-3">
          <Col className="mx-2 mb-3">
            <Row>
              <Col>
                <h6>{commonText.learningHistoryDescription}</h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  onClick={onDrawerExpandChange}
                  variant="primary"
                  data-testid={testIds.learningHistoryReportFilterButton()}
                  className="mb-2"
                >
                  <BsFunnel size={15} /> Filter
                </Button>
              </Col>
              <Col className="d-flex justify-content-end">
                <Button
                  variant="light"
                  className="mb-2"
                  onClick={handleDownloadExcelClick}
                  size="sm"
                  disabled={downloadExcel.isFetching}
                  data-testid={testIds.learningHistoryExcelButton()}
                >
                  {downloadExcel.isFetching ? (
                    <Spinner size="sm" className="mx-1 mt-1" data-testid={testIds.spinner()} />
                  ) : (
                    <AiOutlineFileExcel size={23} />
                  )}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                {toastInformation.showToast && (
                  <div
                    className="position-absolute mx-auto"
                    style={{ zIndex: 10000, left: '50%', transform: 'translate(-50%,0)' }}
                    data-testid={testIds.toastContainer()}
                  >
                    <ToastMessage
                      iconColor={toast.iconColor}
                      headerText={toast.headerText}
                      bodyText={toast.bodyText}
                      toggle={handleToastDismiss}
                      className="bg-white"
                    />
                  </div>
                )}
                {lifeCycleToastInformation.showToast && (
                  <div
                    className="position-absolute mx-auto"
                    style={{ zIndex: 10000, left: '50%', transform: 'translate(-50%,0)' }}
                    data-testid={testIds.lifeCycleToastContainer()}
                  >
                    <ToastMessage
                      iconColor={lifeCycleToast.iconColor}
                      headerText={lifeCycleToast.headerText}
                      bodyText={lifeCycleToast.bodyText}
                      toggle={handleToastDismiss}
                      className="bg-white"
                    />
                  </div>
                )}
                {showLifeCycleDialog && !qualificationAuditInformationIsLoading && (
                  <LifeCycleModal
                    onHide={onHide}
                    showLifeCycle={showLifeCycleDialog}
                    taskName={taskName}
                    qualificationAuditInformation={qualificationAuditInformation}
                  />
                )}
                <LearningHistoryTable
                  learningHistoryData={learningHistoryData}
                  learningHistoryDataCount={learningHistoryDataCount}
                  learningHistoryRequest={learningHistoryRequest}
                  isLearningHistoryLoading={isLearningHistoryDataLoading}
                  requestError={requestError}
                  onLearningHistoryRequestChange={onLearningHistoryRequestChange}
                  setQualificationId={setQualificationId}
                  setTaskName={setTaskName}
                  onPageChange={onPageChange}
                  hasNextPage={hasNextPage}
                />
              </Col>
            </Row>
          </Col>
          <LearningHistoryDrawer
            companyId={companyId}
            expanded={drawerExpanded}
            learningHistoryRequest={learningHistoryRequest}
            onExpandChange={onDrawerExpandChange}
            activeShowOption={activeShowOption}
            onLearningHistoryRequestChange={onLearningHistoryRequestChange}
            handleActiveShowOptionChange={handleActiveShowOptionChange}
            useCostCenters={useCostCenters}
            useDepartments={useDepartments}
            useEmployeesAll={useEmployeesAll}
            useActiveEmployeeStatus={useActiveEmployeeStatus}
            useSeries={useSeries}
            useTasks={useTasks}
            useQualificationStatus={useQualificationStatus}
            useReasons={useReasons}
            useWorkLocations={useWorkLocations}
            useSupervisors={useSupervisors}
            useEvaluators={useEvaluators}
          />
        </Row>
      </Container>
    </MainLayout>
  );
};

export default LearningHistory;
