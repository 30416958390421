import React, { CSSProperties } from 'react';
import { Color } from '../../../types/color';
import { Size } from '../../../types/size';

export interface CircleProps {
  color: Color;
  size?: Size;
  className?: string;
  styleParam?: CSSProperties;
  text?: string;
}

const Circle: React.FC<CircleProps> = ({
  color,
  size = 'md',
  className = '',
  styleParam,
  text,
}) => {
  return (
    <div
      className={`circle-${color} circle-${size} rounded-circle ${className}`}
      style={styleParam}
    >
      {text}
    </div>
  );
};

export default Circle;
