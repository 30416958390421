import React from 'react';
import { PiUserCircleFill } from 'react-icons/pi';
import testIds from '../../../constants/testIds';
import Image from '../../Image/Image';
import Skeleton from '../../Skeleton/Skeleton';

export interface ImageNavLinkProps {
  isLoading: boolean;
  image: string;
  name: string;
}

const UserNavBar: React.FC<ImageNavLinkProps> = ({ image, name, isLoading }) => {
  const getInitials = (): React.ReactNode => {
    const names = name.split(' ');
    if (names.length >= 2) {
      return (
        <div
          className="bg-white rounded-circle d-flex align-items-center justify-content-center fw-bold"
          style={{ height: '36px', width: '36px' }}
        >
          {names[0][0] + names[1][0]}
        </div>
      );
    } else if (names.length === 1 && names[0].length > 0) {
      return (
        <div
          className="bg-white rounded-circle d-flex align-items-center justify-content-center fw-bold"
          style={{ height: '36px', width: '36px' }}
        >
          {names[0][0]}
        </div>
      );
    }
    return (
      <PiUserCircleFill className="text-white" size={36} data-testid={testIds.defaultUserIcon()} />
    );
  };

  const userIcon =
    image.length === 0 ? (
      <div className="rounded-circle">{getInitials()}</div>
    ) : (
      <Image
        src={image}
        alt={name}
        className="rounded-circle"
        style={{ height: '36px', width: '36px', objectFit: 'cover' }}
      />
    );

  return (
    <div className="d-flex flex-nowrap align-items-center justify-content-center mx-1">
      <div style={{ width: '36px', height: '36px' }}>
        {isLoading ? <Skeleton height={36} className="rounded-circle lh-base" /> : userIcon}
      </div>
    </div>
  );
};

export default UserNavBar;
