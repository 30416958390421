import React, { ReactElement } from 'react';
import { Dropdown } from 'react-bootstrap';

export interface UncontrolledDropDownWithLabelProps {
  label: string;
  className?: string;
  isRequired?: boolean;
  testId?: string;
  labelIcon?: ReactElement;
  disabled?: boolean;
  title: string;
  variant: string;
  children: React.ReactNode;
  dropdownStyle?: React.CSSProperties;
}

const UncontrolledDropDownWithLabel: React.FC<UncontrolledDropDownWithLabelProps> = ({
  label,
  className,
  isRequired,
  children,
  testId,
  labelIcon = null,
  disabled = false,
  title,
  variant = 'primary',
  dropdownStyle,
}) => {
  return (
    <div className="d-flex flex-column pb-2">
      <div className="d-flex flex-row text-nowrap me-2 my-auto">
        <div className={`${!isRequired ? 'me-1' : ''}`}>{label}</div>
        {isRequired ? <div className={`text-danger ${isRequired ? 'me-1' : ''}`}>*</div> : null}
        {labelIcon}
      </div>
      <Dropdown data-testid={testId} style={dropdownStyle}>
        <Dropdown.Toggle
          disabled={disabled}
          className={`text-truncate ${
            isRequired && title === 'Select a column'
              ? 'border border-danger'
              : 'border border-secondary'
          }  ${className}`}
          variant={variant}
        >
          {title}
        </Dropdown.Toggle>
        <Dropdown.Menu className={`${className}`}>{children}</Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default UncontrolledDropDownWithLabel;
