import React, { useRef } from 'react';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import eventUtils from '../../utils/eventUtils';
import Connection from '../Connection/Connection';
import List from '../List/List';
import Popover from '../Popover/Popover';

const popoverStyles: React.CSSProperties = { maxHeight: 200, overflow: 'auto' };

export interface ConnectionUsersProps {
  isLoading: boolean;
  connectedUsers: { userId: number; fullName: string }[];
}

const ConnectionUsers: React.FC<ConnectionUsersProps> = ({ isLoading, connectedUsers }) => {
  const connectionRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Popover
        popoverHeader={null}
        trigger="focus"
        popoverBody={
          <List
            style={popoverStyles}
            data={connectedUsers}
            render={user => (
              <ListGroup.Item key={user.userId} className="border-0 p-2">
                {user.fullName}
              </ListGroup.Item>
            )}
            emptyStateMessage="No connected users."
          />
        }
        placement="bottom"
      >
        <Button ref={connectionRef} variant="transparent" onClick={eventUtils.stopPropagation}>
          <Connection isLoading={isLoading} isConnected={connectedUsers.length > 0} />
        </Button>
      </Popover>
    </>
  );
};

export default ConnectionUsers;
