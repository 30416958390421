import * as ESPrima from 'esprima';
import * as ESTree from 'estree';
import { JSONParsed } from '../../types/json-types';
import { ExprToken } from './exprToken';
import TreeToExprTokenTraverserDelegate from './treeToExprTokenTraverserDelegate';

const escodegen = require('escodegen');
const esprima = require('esprima');

// Not exported by `ESTree`.
interface ESPrimaSourceLocation {
  source?: string | null;
  // `offset` property is not included in `ESTree.Position`.
  start: ESTree.Position;
  end: ESTree.Position;
}
// Not exported by `ESPrima`.
type ESPrimaParseDelegate = (
  token: ESPrima.Token,
  metadata?: ESPrimaSourceLocation
) => ESPrima.Token;

export interface ExprEventDetail {
  token: ExprToken | null;
}

export interface ExpressionBuilderOptions {
  defaultPlaceholderText?: string;
}
// TODO this would be better if it implemented `EventTarget`, not extended it
/**
 * `ExpressionBuilder` provides:
 *	- creation of a logic expression,
 *	- filling in placeholder terms as it is being built,
 *	- serializing it to an `ESPrima` tree,
 *	- stringifying to JavaScript expression, and
 *	- knowing whether the expression is complete (contains no placeholders).
 * @see {@linkcode ExprToken}
 */
export default class ExpressionBuilder extends EventTarget {
  // Expression tokens in 'in-order'/natural traversal order.
  private tokens: ExprToken[];

  private exprIsComplete: boolean;

  // Keep track of whether expression was modified since the last serialization.
  private exprIsDirty: boolean;

  private placeholderText: string;

  /**
   * @param {ExpressionBuilderOptions} params
   * @param {string} [params.defaultPlaceholderText='"TBD"'] - Text value to use in a placeholder 'Literal'.
   * @param {string} [params.expression=undefined] - Expression to initialize builder.
   */
  constructor(
    { defaultPlaceholderText = '"TBD"' }: ExpressionBuilderOptions = {
      defaultPlaceholderText: undefined,
    }
  ) {
    super();
    this.tokens = [];
    this.exprIsComplete = false;
    this.exprIsDirty = false;
    this.placeholderText = defaultPlaceholderText;
  }

  public calculateNextValidTokens(): ExprToken[] {
    const contextToken = this.tokens.length > 0 ? this.tokens[this.tokens.length - 1] : null;

    const placeholder = this.createPlaceholderNode().value;
    const callExprToken: ExprToken = {
      type: 'CallExpression',
      value: placeholder,
      raw: null,
    };
    const operExprToken: ExprToken = {
      type: 'LogicalExpression',
      value: placeholder,
    };
    const parenOpenToken: ExprToken = { type: 'Punctuator', value: '(' };
    const parenCloseToken: ExprToken = { type: 'Punctuator', value: ')' };
    // Define accepted grammar.
    const afterCallExpressionClosedGroups = [operExprToken];
    const afterCallExpressionOpenGroups = [operExprToken, parenCloseToken];
    const afterEmptyExpression = [callExprToken, parenOpenToken];
    const afterLogicalExpression = [callExprToken, parenOpenToken];
    const afterParenOpen = [callExprToken, parenOpenToken];
    const afterParenCloseClosedGroups = [operExprToken];
    const afterParenCloseOpenGroups = [operExprToken, parenCloseToken];

    let validTokens: ExprToken[] = [];
    switch (contextToken?.type ?? 'EmptyStatement') {
      case 'CallExpression':
        validTokens =
          this.groupDepth() < 1 ? afterCallExpressionClosedGroups : afterCallExpressionOpenGroups;
        break;
      case 'EmptyStatement':
        validTokens = afterEmptyExpression;
        break;
      case 'LogicalExpression':
        validTokens = afterLogicalExpression;
        break;
      case 'Punctuator':
        if (contextToken?.value === '(') {
          validTokens = afterParenOpen;
        } else if (contextToken?.value === ')') {
          validTokens =
            this.groupDepth() < 1 ? afterParenCloseClosedGroups : afterParenCloseOpenGroups;
        }
        break;
      default:
        // This should not be reachable when appending to the end of an expression
        //	 (because the unknown token `type` should have already been detected).
        throw new TypeError(`Unhandled ECMAScript Token (context) type '${contextToken?.type}'.`);
    }

    return validTokens;
  }

  public createPlaceholderNode(): ExprToken {
    return { type: 'Literal', value: this.placeholderText };
  }

  /* // For use with `JSON.parse( "<serialized>", ExpressionBuilder.esPrimaHydrator )`.
	  static esPrimaHydrator(key: string, value: any): EsNode {
		JSON.parse('', ExpressionBuilder.esPrimaHydrator);
		let parsed = value;
		if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
		  switch (value.type) {
			case 'CallExpression':
			  parsed = new EsCallExpression(value);
			  break;
			case 'ExpressionStatement':
			  parsed = new EsExpressionStatement(value);
			  break;
			case 'Identifier':
			  parsed = new EsIdentifier(value);
			  break;
			case 'Literal':
			  parsed = new EsLiteral(value);
			  break;
			case 'LogicalExpression':
			  parsed = new EsLogicalExpression(value);
			  break;
			case 'Program':
			  parsed = new EsProgram(value);
			  break;
			case 'ObjectExpression':
			  parsed = new EsObjectExpression(value);
			  break;
			case 'Property':
			  parsed = new EsProperty(value);
			  break;
			default:
			  throw new TypeError(`Parsed object value with 'type' ${value.type}`);
		  }
		}
		return parsed;
	  } */

  public groupDepth(): number {
    const openCount = this.tokens.reduce(
      (count, token) => count + (token.type === 'Punctuator' && token.value === '(' ? 1 : 0),
      0
    );
    const closeCount = this.tokens.reduce(
      (count, token) => count + (token.type === 'Punctuator' && token.value === ')' ? 1 : 0),
      0
    );
    const parenthesesBalance = openCount - closeCount;
    return parenthesesBalance;
  }

  public initWithExpression({
    code,
    tokenCallback = (callee, json) => json,
    options,
    delegate,
  }: {
    code: string;
    tokenCallback?: (callee: string, json: JSONParsed) => JSONParsed;
    options?: ESPrima.ParseOptions;
    delegate?: ESPrimaParseDelegate;
  }): void {
    const esTree: ESTree.Node = esprima.parseScript(code, options, delegate);
    // Prevent expression collision.
    this.reset();
    const treeToExprToken = new TreeToExprTokenTraverserDelegate({
      tokenMapper: (tokens: ExprToken[]): void => {
        tokens.forEach(token => this.insertToken(token));
      },
      exprTreeGetter: () => esTree,
      tokenCallback,
    });
    treeToExprToken.handleEvent(new CustomEvent<ExprEventDetail>('init'));

    // this.dispatchEvent(
    //   new CustomEvent<ExprEventDetail>('complete', {
    //     detail: { token: this.tokens[this.tokens.length - 1] },
    //   })
    // );
  }

  /**
   * @param {ExprToken} token - An expression token to add.
   * @fires ExpressionBuilder#change
   * @returns {ExpressionBuilder} reference to `this` for chaining.
   */
  // TODO add `contextToken` as an optional second parameter
  public insertToken(token: ExprToken): ExpressionBuilder {
    this.exprIsDirty = true;
    const contextToken = this.tokens.length > 0 ? this.tokens[this.tokens.length - 1] : null;
    switch (contextToken?.type ?? 'EmptyStatement') {
      case 'CallExpression':
        switch (token.type) {
          case 'CallExpression':
            throw new SyntaxError(`Cannot add a term directly after another term.`);
          case 'LogicalExpression':
            break;
          case 'Punctuator':
            if (token.value === '(') {
              throw new SyntaxError(`Cannot add an opening parenthesis directly after a term.`);
            } else if (token.value === ')') {
              if (this.groupDepth() < 1) {
                throw new SyntaxError(
                  `Cannot add more closing parentheses than opening parentheses.`
                );
              }
            }
            break;
          default:
            throw new TypeError(`Unhandled ECMAScript Token (inserted) type: '${token.type}'.`);
        }
        break;
      case 'EmptyStatement':
        switch (token.type) {
          case 'CallExpression':
            break;
          case 'LogicalExpression':
            throw new SyntaxError(`Cannot add logical operator to empty statement.`);
          case 'Punctuator':
            if (token.value === ')') {
              throw new SyntaxError(`Cannot add an closing parenthesis to an empty statement.`);
            }
            break;
          default:
            throw new TypeError(`Unhandled ECMAScript Token (inserted) type: '${token.type}'.`);
        }
        break;
      case 'LogicalExpression':
        switch (token.type) {
          case 'CallExpression':
            break;
          case 'LogicalExpression':
            throw new SyntaxError(
              `Cannot add a logical operator directly after another logical operator.`
            );
          case 'Punctuator':
            if (token.value === ')') {
              throw new SyntaxError(
                `Cannot add a closing parenthesis directly after a logical operator.`
              );
            }
            break;
          default:
            throw new TypeError(`Unhandled ECMAScript Token (inserted) type: '${token.type}'.`);
        }
        break;
      case 'Punctuator':
        switch (token.type) {
          case 'CallExpression':
            if (contextToken?.value === ')') {
              throw new SyntaxError(`Cannot add a term directly after a closing parenthesis.`);
            }
            break;
          case 'LogicalExpression':
            break;
          case 'Punctuator':
            if (contextToken?.value === '(') {
              if (token.value === ')') {
                throw new SyntaxError(`Cannot add an empty parenthesis group.`);
              }
            } else if (contextToken?.value === ')') {
              if (token.value === '(') {
                throw new SyntaxError(
                  `Cannot add an opening parenthesis directly after a closing parenthesis.`
                );
              } else if (token.value === ')' && this.groupDepth() < 1) {
                throw new SyntaxError(
                  `Cannot add more closing parentheses than opening parentheses.`
                );
              }
            }
            break;
          default:
            throw new TypeError(`Unhandled ECMAScript Token (inserted) type '${token?.type}'.`);
        }
        break;
      default:
        // This should not be reachable when appending to the end of an expression
        //	 (because the unknown token `type` should have already been detected).
        throw new TypeError(`Unhandled ECMAScript Token (context) type '${contextToken?.type}'.`);
    }
    // Essentially `Array.prototype.push`, but accommodates context nodes not at the end of the expression.
    const contextTokenIndex = contextToken
      ? this.tokens.indexOf(contextToken) + 1
      : this.tokens.length;
    this.tokens.splice(contextTokenIndex, 0, token);
    this.dispatchEvent(new CustomEvent<ExprEventDetail>('insert', { detail: { token } }));
    return this;
  }

  public isComplete(): boolean {
    if (this.exprIsDirty) {
      this.stringifyExpression();
    }
    return this.exprIsComplete;
  }

  public isEmpty(): boolean {
    return this.tokens.length === 0;
  }

  // commenting out for now because this isn't being used and is hurting test coverage, but we might need this as we continue working on Equivalencies project
  // public isPlaceholderNode(node: ESTree.Node, parent: ESTree.Node | null): boolean {
  //   return (
  //     node?.type === 'Literal' &&
  //     node?.value === this.placeholderText &&
  //     (parent === null || parent.type === 'Program')
  //   );
  // }

  /**
   * @fires ExpressionBuilder#reset
   * @fires ExpressionBuilder#change if `ExpressionBuilder#reset` is not cancelled.
   */
  public reset(): void {
    this.tokens = [];
    this.exprIsComplete = false;
    this.exprIsDirty = false;
    const allowed = this.dispatchEvent(
      new CustomEvent<ExprEventDetail>('reset', { cancelable: true, detail: { token: null } })
    );
    if (allowed) {
      this.dispatchEvent(new CustomEvent<ExprEventDetail>('complete', { detail: { token: null } }));
    }
  }

  /**
   * @fires ExpressionBuilder#complete
   * @fires ExpressionBuilder#incomplete
   */
  public serializeTree(): ESTree.Node {
    // Deep copy.
    const tokenList = this.tokens.slice();
    let isCompleteTemp = true;
    // Terms.
    const lastToken = this.tokens.length > 0 ? this.tokens[this.tokens.length - 1] : null;
    if (lastToken === null) {
      isCompleteTemp = false;
    } else if (['(', '&&', '||'].includes(lastToken?.value)) {
      isCompleteTemp = false;
      tokenList.push(this.createPlaceholderNode());
    }
    // Parentheses.
    const groupDepth = this.groupDepth();
    if (groupDepth > 0) {
      isCompleteTemp = false;
      tokenList.push(...Array(groupDepth).fill({ type: 'Punctuator', value: ')' }));
    }
    // Statement.
    tokenList.push({ type: 'Punctuator', value: ';' });

    this.exprIsDirty = false;
    if (this.exprIsComplete !== isCompleteTemp) {
      this.exprIsComplete = isCompleteTemp;
      const eventName = this.exprIsComplete ? 'complete' : 'incomplete';
      this.dispatchEvent(
        new CustomEvent<ExprEventDetail>(eventName, {
          detail: { token: lastToken },
        })
      );
    }

    const tree = esprima.parseScript(tokenList.map(token => token.value).join(' '));
    return tree;
  }

  // eslint-disable-next-line class-methods-use-this
  public stringToCallExpression(func: string, arg: JSONParsed): ExprToken {
    return {
      type: 'CallExpression',
      // Using `JSON.stringify` allows for object parameters to be expanded.
      value: `${func}(${JSON.stringify(arg)})`,
      raw: arg,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public stringToLogicalExpression(oper: string): ExprToken {
    return {
      type: 'LogicalExpression',
      value: oper,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public stringToPunctuator(punc: string): ExprToken {
    return { type: 'Punctuator', value: punc };
  }

  public stringifyExpression(): string {
    const formatOptions = {
      format: {
        // Without identation.
        indent: { style: '' },
        // Compact vertically.
        newline: '',
        // Important for being JSON parsable.
        quotes: 'double',
        // Somewhat readable horizontally.
        space: ' ',
      },
    };
    const stringified = escodegen.generate(this.serializeTree(), formatOptions);
    return stringified;
  }

  public tokenize(): string[] {
    return this.tokens.map(token => token.value);
  }
}
