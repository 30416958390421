import React, { useEffect, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Tab from 'react-bootstrap/Tab';
import { GetActivitiesReportRequest } from '../../api/requests/getActivitiesReportRequest';
import Count from '../../components/Count/Count';
import DateFilterContainer from '../../components/DateFilterContainer/DateFilterContainer';
import Dropdown from '../../components/Dropdown/Dropdown';
import Drawer from '../../components/Layout/Drawer/Drawer';
import DrawerMenuGroup from '../../components/Layout/Drawer/DrawerMenuGroup';
import TabPanel from '../../components/Layout/TabPanel/TabPanel';
import MultiPartSelectContainer from '../../components/MultiPartSelect/Container/MultiPartSelectContainer';
import MultiPartSelectWithDropdownContainer from '../../components/MultiPartSelect/MultiPartSelectWithDropdownContainer/MultiPartSelectWithDropdownContainer';
import commonText from '../../constants/commonText';
import messages from '../../constants/messages';
import testIds from '../../constants/testIds';
import { DisplayItem } from '../../models/displayItem';
import DrawerFilterTab from '../../models/DrawerFilterTab';
import { DropdownOption } from '../../models/DropdownOption';
import FilterTabNameId from '../../models/FilterTabNameId';
import FilterTabNames from '../../models/FilterTabNames';
import { UseDisplayItemsResponse } from '../../models/useDisplayItemsResponse';

export interface ActivitiesReportDrawerProps {
  expanded: boolean;
  companyId: number;
  activitiesReportRequest: GetActivitiesReportRequest;
  showCurrentActivities: boolean;
  onDrawChange: () => void;
  onShowActivitiesChange: (value: boolean) => void;
  onActivitiesReportRequestChange: (reportData: GetActivitiesReportRequest) => void;
  useDepartments: (companyId: number, text: string) => UseDisplayItemsResponse;
  useCompanySeriesTasks: (
    companyId: number,
    seriesId: number,
    searchText: string
  ) => UseDisplayItemsResponse;
  useCompanyTypes: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useStatus: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useCostCenters: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useRoles: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useAssigners: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useEvaluators: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useSupervisors: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useWorkLocations: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useSeries: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useActiveEmployeeStatus: (companyId: number, searchText: string) => UseDisplayItemsResponse;
  useEmployeesAll: (
    companyId: number,
    activeStatus: number,
    searchText: string
  ) => UseDisplayItemsResponse;
}

const ActiviesReportShowOptions: DropdownOption[] = [
  {
    id: 0,
    name: 'All Activities',
  },
  {
    id: 1,
    name: 'Current Activities',
  },
];
const showOptions = Object.values(ActiviesReportShowOptions);

const ActivitiesReportDrawer: React.FC<ActivitiesReportDrawerProps> = ({
  expanded,
  companyId,
  activitiesReportRequest,
  showCurrentActivities,
  onDrawChange,
  onShowActivitiesChange,
  useDepartments,
  onActivitiesReportRequestChange,
  useCompanySeriesTasks,
  useCompanyTypes,
  useStatus,
  useCostCenters,
  useRoles,
  useAssigners,
  useEvaluators,
  useSupervisors,
  useWorkLocations,
  useSeries,
  useEmployeesAll,
  useActiveEmployeeStatus,
}) => {
  const [activeFilterTab, setActiveFilterTab] = useState<FilterTabNameId>(
    FilterTabNames.assigners.id
  );

  const handleActiveFilterTabChange = (
    updatedSearchText: string,
    selectedFilterTabId: FilterTabNameId,
    stateSetter: React.Dispatch<React.SetStateAction<string>> | null
  ) => {
    setActiveFilterTab(selectedFilterTabId);
    if (stateSetter) stateSetter(updatedSearchText);
  };

  // Start Departments Section
  const [departmentsSearchText, setDepartmentsSearchText] = useState('');
  const departments: UseDisplayItemsResponse = useDepartments(companyId, '');
  const departmentDisplayItems = departments.data.filter(departmentDisplayItem =>
    activitiesReportRequest.departmentIds?.includes(departmentDisplayItem.itemId)
  );

  const handleDepartmentIdsChange = (displayItems: DisplayItem[]) => {
    onActivitiesReportRequestChange({
      ...activitiesReportRequest,
      departmentIds: displayItems.map(di => di.itemId),
    });
  };
  // End Departments Section

  // Start Company Type Section
  const [companyTypesSearchText, setCompanyTypesSearchText] = useState('');
  const companyTypes: UseDisplayItemsResponse = useCompanyTypes(companyId, '');
  const companyTypeDisplayItems = companyTypes.data.filter(companyTypeDisplayItem =>
    activitiesReportRequest.companyTypeIds?.includes(companyTypeDisplayItem.itemId)
  );

  const handleCompanyTypeIdsChange = (displayItems: DisplayItem[]) => {
    onActivitiesReportRequestChange({
      ...activitiesReportRequest,
      companyTypeIds: displayItems.map(di => di.itemId),
    });
  };
  // End Company Type Section

  // Start Status Section
  const [statusSearchText, setStatusSearchText] = useState('');
  const statuses: UseDisplayItemsResponse = useStatus(companyId, '');
  const statusesDisplayItems = statuses.data.filter(statusDisplayItem =>
    activitiesReportRequest.activityStatusIds?.includes(statusDisplayItem.itemId)
  );

  const handleStatusIdsChange = (displayItems: DisplayItem[]) => {
    onActivitiesReportRequestChange({
      ...activitiesReportRequest,
      activityStatusIds: displayItems.map(s => s.itemId),
    });
  };
  // End Status Section

  // Start Cost Centers Section
  const [costCentersSearchText, setCostCentersSearchText] = useState('');
  const costCenters: UseDisplayItemsResponse = useCostCenters(companyId, '');
  const costCentersDisplayItems = costCenters.data.filter(costCentersDisplayItem =>
    activitiesReportRequest.costCenterIds?.includes(costCentersDisplayItem.itemId)
  );

  const handleCostCentersIdsChange = (displayItems: DisplayItem[]) => {
    onActivitiesReportRequestChange({
      ...activitiesReportRequest,
      costCenterIds: displayItems.map(cc => cc.itemId),
    });
  };
  // End Cost Centers Section

  // Start Roles Section
  const [rolesSearchText, setRolesSearchText] = useState('');
  const roles: UseDisplayItemsResponse = useRoles(companyId, '');
  const rolesDisplayItems = roles.data.filter(role =>
    activitiesReportRequest.roleIds?.includes(role.itemId)
  );

  const handleRoleIdsChange = (displayItems: DisplayItem[]) => {
    onActivitiesReportRequestChange({
      ...activitiesReportRequest,
      roleIds: displayItems.map(r => r.itemId),
    });
  };
  // End Roles Section

  // Start Assigners Section
  const [assignersSearchText, setAssignersSearchText] = useState('');
  const assigners: UseDisplayItemsResponse = useAssigners(companyId, '');
  const assignersDisplayItems = assigners.data.filter(assigner =>
    activitiesReportRequest.activityAssignerIds?.includes(assigner.itemId)
  );

  const handleAssignerIdsChange = (displayItems: DisplayItem[]) => {
    onActivitiesReportRequestChange({
      ...activitiesReportRequest,
      activityAssignerIds: displayItems.map(r => r.itemId),
    });
  };
  // End Assigners Section

  // Start Evaluators Section
  const [evaluatorsSearchText, setEvaluatorsSearchText] = useState('');
  const evaluators: UseDisplayItemsResponse = useAssigners(companyId, '');
  const evaluatorsDisplayItems = evaluators.data.filter(evaluator =>
    activitiesReportRequest.activityEvaluatorIds?.includes(evaluator.itemId)
  );

  const handleEvaluatorsIdsChange = (displayItems: DisplayItem[]) => {
    onActivitiesReportRequestChange({
      ...activitiesReportRequest,
      activityEvaluatorIds: displayItems.map(e => e.itemId),
    });
  };
  // End Evaluators Section

  // Start Supervisor Section
  const [supervisorSearchText, setSupervisorsSearchText] = useState('');
  const supervisors: UseDisplayItemsResponse = useSupervisors(companyId, '');
  const supervisorDisplayItems = supervisors.data.filter(supervisor =>
    activitiesReportRequest.userSupervisorIds?.includes(supervisor.itemId)
  );

  const handleSupervisorIdsChange = (displayItems: DisplayItem[]) => {
    onActivitiesReportRequestChange({
      ...activitiesReportRequest,
      userSupervisorIds: displayItems.map(r => r.itemId),
    });
  };
  // End Supervisor Section

  // Start Work Locations Section
  const [workLocationSearchText, setWorkLocationSearchText] = useState('');
  const workLocations: UseDisplayItemsResponse = useWorkLocations(companyId, '');
  const workLocationDisplayItems = workLocations.data.filter(workLocation =>
    activitiesReportRequest.workLocationIds?.includes(workLocation.itemId)
  );

  const handleWorkLocationIdsChange = (displayItems: DisplayItem[]) => {
    onActivitiesReportRequestChange({
      ...activitiesReportRequest,
      workLocationIds: displayItems.map(r => r.itemId),
    });
  };
  // End Work Location Section

  // Start Series Section
  const [seriesSearchText, setSeriesSearchText] = useState('');
  const series: UseDisplayItemsResponse = useSeries(companyId, '');
  const seriesDisplayItems = series.data.filter(s =>
    activitiesReportRequest.seriesIds?.includes(s.itemId)
  );

  const handleSeriesIdsChange = (displayItems: DisplayItem[]) => {
    onActivitiesReportRequestChange({
      ...activitiesReportRequest,
      seriesIds: displayItems.map(r => r.itemId),
    });
  };
  // End Series Section

  // Start Tasks Section
  const [tasksSearchText, setTasksSearchText] = useState('');
  const [tasksDisplayItems, setTasksDisplayItems] = useState<DisplayItem[]>([]);

  const handleTasksIdsChange = (displayItems: DisplayItem[]) => {
    onActivitiesReportRequestChange({
      ...activitiesReportRequest,
      taskIds: displayItems.map(r => r.itemId),
    });
    setTasksDisplayItems(displayItems);
  };
  // End Tasks Section

  // Show activities section
  const [showActivitiesActiveOption, setShowActivitiesActiveOption] = useState<DropdownOption>(
    ActiviesReportShowOptions[1]
  );

  function handleOnShowChanged(option: DropdownOption) {
    onShowActivitiesChange(option.id !== 0);
  }

  useEffect(() => {
    setShowActivitiesActiveOption(showCurrentActivities ? showOptions[1] : showOptions[0]);
  }, [showCurrentActivities]);
  // End show activities section

  // Start Employees Section
  const [employeeSearchText, setEmployeeSearchText] = useState('');
  const [employeesDisplayItems, setEmployeesDisplayItems] = useState<DisplayItem[]>([]);

  const handleEmployeeIdsChange = (displayItems: DisplayItem[]) => {
    onActivitiesReportRequestChange({
      ...activitiesReportRequest,
      userIds: displayItems.map(r => r.itemId),
    });
    setEmployeesDisplayItems(displayItems);
  };
  // End Employees Section

  // Start Date section
  const handleDateFiltersChange = (changes: Partial<GetActivitiesReportRequest>) => {
    onActivitiesReportRequestChange({
      ...activitiesReportRequest,
      ...changes,
    });
  };

  const handleDateFiltersClear = () => {
    onActivitiesReportRequestChange({
      ...activitiesReportRequest,
      expiresInDays: 0,
      lastAccessedDate: undefined,
      completedDate: undefined,
      expirationDate: undefined,
    });
  };
  // End Date Section

  const tabs: DrawerFilterTab[] = [
    {
      id: FilterTabNames.assigners.id,
      label: FilterTabNames.assigners.name,
      count: (
        <Count className="ms-1" count={activitiesReportRequest.activityAssignerIds?.length || 0} />
      ),
      onFilterChangeStateSetter: setAssignersSearchText,
    },
    {
      id: FilterTabNames.costCenters.id,
      label: FilterTabNames.costCenters.name,
      count: <Count className="ms-1" count={activitiesReportRequest.costCenterIds?.length || 0} />,
      onFilterChangeStateSetter: setCostCentersSearchText,
    },
    {
      id: FilterTabNames.departments.id,
      label: FilterTabNames.departments.name,
      count: <Count className="ms-1" count={activitiesReportRequest.departmentIds?.length || 0} />,
      onFilterChangeStateSetter: setDepartmentsSearchText,
    },
    // TODO Uncomment when required to show Company Type Filter
    // {
    //   id: FilterTabNames.companyType.id,
    //   label: FilterTabNames.companyType.name,
    //   count: <Count className="ms-1" count={activitiesReportRequest.companyTypeIds?.length || 0} />,
    //   onFilterChangeStateSetter: setCompanyTypesSearchText,
    // },
    {
      id: FilterTabNames.date.id,
      label: FilterTabNames.date.name,
      count: null,
      onFilterChangeStateSetter: null,
    },
    {
      id: FilterTabNames.employees.id,
      label: FilterTabNames.employees.name,
      count: <Count className="ms-1" count={activitiesReportRequest.userIds?.length || 0} />,
      onFilterChangeStateSetter: setEmployeeSearchText,
    },
    {
      id: FilterTabNames.status.id,
      label: FilterTabNames.status.name,
      count: (
        <Count className="ms-1" count={activitiesReportRequest.activityStatusIds?.length || 0} />
      ),
      onFilterChangeStateSetter: setStatusSearchText,
    },
    {
      id: FilterTabNames.roles.id,
      label: FilterTabNames.roles.name,
      count: <Count className="ms-1" count={activitiesReportRequest.roleIds?.length || 0} />,
      onFilterChangeStateSetter: setRolesSearchText,
    },
    {
      id: FilterTabNames.supervisors.id,
      label: FilterTabNames.supervisors.name,
      count: (
        <Count className="ms-1" count={activitiesReportRequest.userSupervisorIds?.length || 0} />
      ),
      onFilterChangeStateSetter: setSupervisorsSearchText,
    },
    {
      id: FilterTabNames.tasks.id,
      label: FilterTabNames.tasks.name,
      count: <Count className="ms-1" count={activitiesReportRequest.taskIds?.length || 0} />,
      onFilterChangeStateSetter: setTasksSearchText,
    },
    {
      id: FilterTabNames.evaluators.id,
      label: FilterTabNames.evaluators.name,
      count: (
        <Count className="ms-1" count={activitiesReportRequest.activityEvaluatorIds?.length || 0} />
      ),
      onFilterChangeStateSetter: setEvaluatorsSearchText,
    },
    {
      id: FilterTabNames.workLocations.id,
      label: FilterTabNames.workLocations.name,
      count: (
        <Count className="ms-1" count={activitiesReportRequest.workLocationIds?.length || 0} />
      ),
      onFilterChangeStateSetter: setWorkLocationSearchText,
    },
    {
      id: FilterTabNames.series.id,
      label: FilterTabNames.series.name,
      count: <Count className="ms-1" count={activitiesReportRequest.seriesIds?.length || 0} />,
      onFilterChangeStateSetter: setSeriesSearchText,
    },
  ];

  return (
    <Drawer expanded={expanded} onDrawerChange={onDrawChange}>
      <DrawerMenuGroup>
        <div className="d-inline-flex align-items-center">
          <p className="fw-bold my-0 me-3">Show</p>
          <Dropdown<DropdownOption>
            items={showOptions}
            title={showActivitiesActiveOption.name}
            itemKey={(item: DropdownOption) => item.id}
            itemLabel={(item: DropdownOption) => item.name}
            emptyListLabel=""
            variant="transparent"
            onItemSelect={handleOnShowChanged}
            isLoading={false}
            dropdownTestId={testIds.activitiesActiveDropdown()}
            selectedItem={showActivitiesActiveOption.id}
          />
        </div>
      </DrawerMenuGroup>
      <DrawerMenuGroup label="Filters">
        <Tab.Container activeKey={activeFilterTab}>
          <ListGroup className="w-100 flex-wrap" horizontal>
            {tabs.map(({ id, label, count, onFilterChangeStateSetter = null }: DrawerFilterTab) => {
              return (
                <ListGroup.Item
                  key={id}
                  role="tab"
                  as="button"
                  className="btn-sm border-0 rounded"
                  variant="transparent"
                  id={`activities-drawer-${id}`}
                  onClick={() => handleActiveFilterTabChange('', id, onFilterChangeStateSetter)}
                  active={activeFilterTab === id}
                >
                  {label}
                  {count}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
          <Tab.Content className="overflow-auto h-100">
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.assigners.id}
              isActiveTab={activeFilterTab === FilterTabNames.assigners.id}
            >
              <MultiPartSelectContainer
                companyId={companyId}
                useData={useAssigners}
                onChange={handleAssignerIdsChange}
                selectedDisplayItems={assignersDisplayItems}
                emptyListText={messages.noAvailableMessage(commonText.assigners)}
                onSearchTextChanged={setAssignersSearchText}
                searchText={assignersSearchText}
                tabId={FilterTabNames.assigners.id}
              />
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.departments.id}
              isActiveTab={activeFilterTab === FilterTabNames.departments.id}
            >
              <MultiPartSelectContainer
                companyId={companyId}
                useData={useDepartments}
                onChange={handleDepartmentIdsChange}
                selectedDisplayItems={departmentDisplayItems}
                emptyListText={messages.noAvailableMessage(commonText.departments)}
                onSearchTextChanged={setDepartmentsSearchText}
                searchText={departmentsSearchText}
                tabId={FilterTabNames.departments.id}
              />
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.departments.id}
              isActiveTab={activeFilterTab === FilterTabNames.companyType.id}
            >
              <MultiPartSelectContainer
                companyId={companyId}
                useData={useCompanyTypes}
                onChange={handleCompanyTypeIdsChange}
                selectedDisplayItems={companyTypeDisplayItems}
                emptyListText={messages.noAvailableMessage(commonText.companyType)}
                onSearchTextChanged={setCompanyTypesSearchText}
                searchText={companyTypesSearchText}
                tabId={FilterTabNames.departments.id}
              />
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.employees.id}
              isActiveTab={activeFilterTab === FilterTabNames.employees.id}
            >
              <MultiPartSelectWithDropdownContainer
                companyId={companyId}
                useDropdownData={useActiveEmployeeStatus}
                useListData={useEmployeesAll}
                onListItemSelection={handleEmployeeIdsChange}
                emptyListText={messages.noAvailableMessage(commonText.employees)}
                onSearch={setEmployeeSearchText}
                searchText={employeeSearchText}
                dropdownLabel={commonText.employeesStatus}
                emptyDropdownLabel={commonText.selectEmployeesStatus}
                selectedDisplayItems={employeesDisplayItems}
                defaultParentId={1}
                tabId={FilterTabNames.employees.id}
              />
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.status.id}
              isActiveTab={activeFilterTab === FilterTabNames.status.id}
            >
              <MultiPartSelectContainer
                companyId={companyId}
                useData={useStatus}
                onChange={handleStatusIdsChange}
                selectedDisplayItems={statusesDisplayItems}
                emptyListText={messages.noAvailableMessage(commonText.status)}
                onSearchTextChanged={setStatusSearchText}
                searchText={statusSearchText}
                tabId={FilterTabNames.status.id}
              />
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.costCenters.id}
              isActiveTab={activeFilterTab === FilterTabNames.costCenters.id}
            >
              <MultiPartSelectContainer
                companyId={companyId}
                useData={useCostCenters}
                onChange={handleCostCentersIdsChange}
                selectedDisplayItems={costCentersDisplayItems}
                emptyListText={messages.noAvailableMessage(commonText.costCenters)}
                onSearchTextChanged={setCostCentersSearchText}
                searchText={costCentersSearchText}
                tabId={FilterTabNames.costCenters.id}
              />
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.roles.id}
              isActiveTab={activeFilterTab === FilterTabNames.roles.id}
            >
              <MultiPartSelectContainer
                companyId={companyId}
                useData={useRoles}
                onChange={handleRoleIdsChange}
                selectedDisplayItems={rolesDisplayItems}
                emptyListText={messages.noAvailableMessage(commonText.roles)}
                onSearchTextChanged={setRolesSearchText}
                searchText={rolesSearchText}
                tabId={FilterTabNames.roles.id}
              />
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.supervisors.id}
              isActiveTab={activeFilterTab === FilterTabNames.supervisors.id}
            >
              <MultiPartSelectContainer
                companyId={companyId}
                useData={useSupervisors}
                onChange={handleSupervisorIdsChange}
                selectedDisplayItems={supervisorDisplayItems}
                emptyListText={messages.noAvailableMessage(commonText.supervisors)}
                onSearchTextChanged={setSupervisorsSearchText}
                searchText={supervisorSearchText}
                tabId={FilterTabNames.supervisors.id}
              />
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.workLocations.id}
              isActiveTab={activeFilterTab === FilterTabNames.workLocations.id}
            >
              <MultiPartSelectContainer
                companyId={companyId}
                useData={useWorkLocations}
                onChange={handleWorkLocationIdsChange}
                selectedDisplayItems={workLocationDisplayItems}
                emptyListText={messages.noAvailableMessage(commonText.supervisors)}
                onSearchTextChanged={setWorkLocationSearchText}
                searchText={workLocationSearchText}
                tabId={FilterTabNames.workLocations.id}
              />
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.evaluators.id}
              isActiveTab={activeFilterTab === FilterTabNames.evaluators.id}
            >
              <MultiPartSelectContainer
                companyId={companyId}
                useData={useEvaluators}
                onChange={handleEvaluatorsIdsChange}
                selectedDisplayItems={evaluatorsDisplayItems}
                emptyListText={messages.noAvailableMessage(commonText.evaluators)}
                onSearchTextChanged={setEvaluatorsSearchText}
                searchText={evaluatorsSearchText}
                tabId={FilterTabNames.evaluators.id}
              />
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.series.id}
              isActiveTab={activeFilterTab === FilterTabNames.series.id}
            >
              <MultiPartSelectContainer
                companyId={companyId}
                useData={useSeries}
                onChange={handleSeriesIdsChange}
                selectedDisplayItems={seriesDisplayItems}
                emptyListText={messages.noAvailableMessage(commonText.series)}
                onSearchTextChanged={setSeriesSearchText}
                searchText={seriesSearchText}
                tabId={FilterTabNames.series.id}
              />
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.tasks.id}
              isActiveTab={activeFilterTab === FilterTabNames.tasks.id}
            >
              <MultiPartSelectWithDropdownContainer
                companyId={companyId}
                useDropdownData={useSeries}
                useListData={useCompanySeriesTasks}
                onListItemSelection={handleTasksIdsChange}
                emptyListText={messages.noAvailableMessage(commonText.task)}
                onSearch={setTasksSearchText}
                searchText={tasksSearchText}
                dropdownLabel={commonText.series}
                emptyDropdownLabel={commonText.selectSeries}
                selectedDisplayItems={tasksDisplayItems}
                tabId={FilterTabNames.tasks.id}
              />
            </TabPanel>
            <TabPanel
              className="h-100 flex-column flex-nowrap overflow-hidden d-flex"
              tabId={FilterTabNames.date.id}
              isActiveTab={activeFilterTab === FilterTabNames.date.id}
            >
              <DateFilterContainer
                completedDayFilter={activitiesReportRequest.completedDate || { From: '', To: '' }}
                expirationDateFilter={
                  activitiesReportRequest.expirationDate || { From: '', To: '' }
                }
                lastAccessedFilter={
                  activitiesReportRequest.lastAccessedDate || { From: '', To: '' }
                }
                expiresInDays={activitiesReportRequest.expiresInDays || 0}
                onDataChange={handleDateFiltersChange}
                onClear={handleDateFiltersClear}
              />
            </TabPanel>
          </Tab.Content>
        </Tab.Container>
      </DrawerMenuGroup>
    </Drawer>
  );
};

export default ActivitiesReportDrawer;
