import apiHooks from '../../../api/apiHooks';
import { DisplayItemWithCustomViewAccessType } from '../../../models/displayItemWithCustomViewAccessType';
import Policy from '../../../models/policy';
import { UseDisplayItemsResponse } from '../../../models/useDisplayItemsResponse';
import displayItemConverters from './displayItemConverters';

function useSharedUsers(
  companyId: number,
  searchText: string
): UseDisplayItemsResponse<DisplayItemWithCustomViewAccessType> {
  const { data = [], isLoading } = apiHooks.useCompaniesUsers(
    {
      companyId,
      searchText,
      policy: Policy.CanViewOnBoardLive,
      isVisible: false,
    },
    {
      keepPreviousData: true,
      select: displayItemConverters.convertSharedCompaniesUsersResponse,
    }
  );

  return { data, isLoading };
}

export default useSharedUsers;
