import { BarDatum, ComputedDatum, ResponsiveBar } from '@nivo/bar';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { BatchUserUploadIntegrationDisplayItem } from '../../hooks/useIntegrationBatchStatisticsByCompanyId';
import CurrentIntegrationsResponse from '../../models/CurrentIntegrationsResponse';
import RecentUploadsChartColumnIds from '../../models/RecentUploadsChartColumnIds';
import IntegrationPickerDropdown from '../IntegrationHistory/IntegrationPickerDropdown';

export interface BatchUserStatisticBarData extends BarDatum {
  batchId: number;
  display: string;
  batchDate: string;
  'New Users': number;
  'Update Users': number;
  'Total Errors': number;
}

export interface RecentUploadsChartProps {
  userStatistics: BatchUserUploadIntegrationDisplayItem[];
  onBarClick: (e: ComputedDatum<BatchUserStatisticBarData>) => void;
  isBatchUserStatisticsLoading: boolean;
  currentIntegrations: CurrentIntegrationsResponse[];
  isCurrentIntegrationsLoading: boolean;
  setSelectedIntegration: (currentIntegration: CurrentIntegrationsResponse) => void;
  selectedIntegration: CurrentIntegrationsResponse;
}

const RecentUploadsChart = ({
  userStatistics,
  isBatchUserStatisticsLoading,
  onBarClick,
  currentIntegrations,
  isCurrentIntegrationsLoading,
  selectedIntegration,
  setSelectedIntegration,
}: RecentUploadsChartProps): React.ReactElement => {
  const [recentUploads, setRecentUploads] = useState<BatchUserStatisticBarData[]>([
    {
      batchId: 1,
      display: '',
      batchDate: '',
      'New Users': 0,
      'Update Users': 0,
      'Total Errors': 0,
    },
  ]);

  const [chartScaleMax, setChartScaleMax] = useState<number | 'auto'>(100);

  useEffect(() => {
    if (userStatistics.length > 0) {
      const results: BatchUserStatisticBarData[] = userStatistics
        .map(value => ({
          batchId: value.BatchId,
          display: value.display,
          [RecentUploadsChartColumnIds.newUsers]: value.TotalNewUsers,
          [RecentUploadsChartColumnIds.updateUsers]: value.TotalUpdateUsers,
          [RecentUploadsChartColumnIds.errorUsers]: value.TotalErrors,
          batchDate: value.DateCreatedUTC,
        }))
        .reverse();

      setChartScaleMax('auto');
      setRecentUploads(results);
    }
  }, [userStatistics, setRecentUploads]);

  return (
    <Card>
      <Card.Body style={{ height: '300px', cursor: 'pointer' }}>
        <IntegrationPickerDropdown
          currentIntegrations={currentIntegrations}
          isCurrentIntegrationsLoading={isCurrentIntegrationsLoading}
          onIntegrationSelection={setSelectedIntegration}
          selectedIntegration={selectedIntegration}
          dropdownButtonClassName="text-truncate border"
        />
        {isBatchUserStatisticsLoading || isCurrentIntegrationsLoading ? (
          <div className="d-flex align-items-center justify-content-center h-100 text-secondary">
            <Spinner animation="border" style={{ width: '60px', height: '60px' }} />
          </div>
        ) : (
          <ResponsiveBar
            keys={[
              RecentUploadsChartColumnIds.newUsers,
              RecentUploadsChartColumnIds.updateUsers,
              RecentUploadsChartColumnIds.errorUsers,
            ]}
            data={recentUploads}
            indexBy="display"
            enableLabel={false}
            isInteractive
            margin={{ top: 25, bottom: 50, right: 150, left: 40 }}
            padding={0.4}
            groupMode="grouped"
            valueScale={{ type: 'linear', min: 0, max: chartScaleMax }}
            axisLeft={{ format: e => Math.floor(e) === e && e }}
            colors={['#709E4C', '#223555', '#666666']}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                symbolSize: 20,
              },
            ]}
            ariaLabel="Integration Analytics"
            onClick={onBarClick}
            animate={false}
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default RecentUploadsChart;
