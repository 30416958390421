import { useDrag } from '@use-gesture/react';
import React from 'react';
import { OneSecondInMilliseconds } from '../constants/measurements';

export interface SwipeConfig {
  onSwipeLeft?: () => void;
  onSwipeRight?: () => void;
}

export interface UseSwipeReturn {
  (...args: unknown[]): React.DOMAttributes<EventTarget>;
}

const useSwipe = (config: SwipeConfig): UseSwipeReturn => {
  const bind = useDrag(
    ({ swipe: [swipeX] }) => {
      if (swipeX > 0) {
        config.onSwipeRight?.();
      } else if (swipeX < 0) {
        config.onSwipeLeft?.();
      }
    },
    {
      axis: 'x',
      pointer: { touch: true },
      swipe: {
        duration: OneSecondInMilliseconds,
        distance: [1, 1],
        velocity: [0.01, 0.01],
      },
    }
  );

  return bind;
};

export default useSwipe;
