import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { RiLayoutMasonryLine } from 'react-icons/ri';

export interface ParentNavLinkProps {
  expanded: boolean;
  linkText: string;
  children: React.ReactNode;
}

const ParentNavLink: React.FC<ParentNavLinkProps> = ({ children, expanded, linkText }) => {
  const [open, setOpen] = useState(true);

  return (
    <ul className={`ps-1 ${expanded ? 'active' : ''}`}>
      <Button
        variant="link"
        onClick={() => setOpen(!open)}
        data-toggle="collapse"
        aria-controls={`collapse-${linkText}`}
        aria-expanded={open}
        className="d-flex flex-row flex-nowrap text-decoration-none align-items-center w-100"
      >
        <RiLayoutMasonryLine size="24" />
        <p className="w-100 text-uppercase text-start d-flex align-items-center justify-content-between">
          {linkText}
          {!open && <FaCaretDown />}
          {open && <FaCaretUp />}
        </p>
      </Button>
      <Collapse in={open}>
        <div id={`collapse-${linkText}`}>{children}</div>
      </Collapse>
    </ul>
  );
};

export default ParentNavLink;
