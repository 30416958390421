import localStorageKeys from '../constants/localStorageKeys';
import Radix from '../constants/radix';

export interface UserHook {
  userId: number;
  setUserId: (userId: number) => void;
}

export default function useUserId(): UserHook {
  return {
    userId: parseInt(localStorage.getItem(localStorageKeys.userId) ?? '-1', Radix.Default),
    setUserId: (userId: number) => localStorage.setItem(localStorageKeys.userId, `${userId}`),
  };
}
