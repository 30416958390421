import { useEffect, useRef } from 'react';

function useInterval(callBack: () => void, interval: number | undefined): void {
  const callBackRef = useRef(callBack);

  useEffect(() => {
    callBackRef.current = callBack;
  }, [callBack]);

  useEffect(() => {
    if (interval === undefined) {
      return undefined;
    }

    const timer = setInterval(() => callBackRef.current(), interval);

    return () => {
      clearInterval(timer);
    };
  }, [interval]);
}

export default useInterval;
