import { QueryFunctionContext } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';
import { DateTime } from 'luxon';
import authService from '../auth/authService';
import activitiesReportRequestQueryParams from '../models/ActivitiesReportRequestQueryParam';
import { AffidavitPayloadInformation } from '../models/affidavitPayloadInformation';
import OdataQueryParams from '../models/OdataQueryParams';
import {
  GetActivitiesReportExcelRoute,
  GetActivitiesReportRoute,
  GetActivityAffidavitByIdRoute,
} from './apiRoutes';
import { GetActivitiesReportRequest } from './requests/getActivitiesReportRequest';
import { GetActivitiesReportResponse } from './responses/getActivitiesReportResponse';
import {
  ActivityReportAffidavitModel,
  ActivityReportAffidavitResponse,
} from './responses/getActivityAffidavitInformationResponse';
import { PaginatedResponse } from './responses/paginatedResponse';
import {
  addPropertyToFilterObject,
  createOdataFilter,
  processSpecialCharacters,
  QueryFilters,
  QueryFilterType,
} from './utils/OdataHelpers';

const activitiesReportApi = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // set up url
  headers: {
    'Content-Type': 'application/json',
  },
});

activitiesReportApi.interceptors.request.use(async config => {
  const bearerToken = await authService.getAuthSession();
  if (bearerToken !== undefined && config.headers !== undefined) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${bearerToken.token.idToken}`;
  }

  return config;
});

function getActivitesReportFilterQueryParam(request: GetActivitiesReportRequest): QueryFilters[] {
  const {
    companyId,
    departmentIds,
    companyTypeIds,
    activityStatusIds,
    costCenterIds,
    roleIds,
    activityAssignerIds,
    activityEvaluatorIds,
    userSupervisorIds,
    workLocationIds,
    seriesIds,
    taskIds,
    isCurrent,
    userIds,
    completedDate,
    expirationDate,
    lastAccessedDate,
    expiresInDays,
  } = request;

  // Default filters
  let filters: QueryFilters[] = [
    {
      key: activitiesReportRequestQueryParams.companyId,
      type: QueryFilterType.number,
      value: [companyId],
    },
    {
      key: activitiesReportRequestQueryParams.isEnabled,
      type: QueryFilterType.boolean,
      value: [true],
    },
  ];
  // Filters
  filters = addPropertyToFilterObject(
    filters,
    activitiesReportRequestQueryParams.departmentId,
    departmentIds,
    QueryFilterType.array
  );

  filters = addPropertyToFilterObject(
    filters,
    activitiesReportRequestQueryParams.companyType,
    companyTypeIds,
    QueryFilterType.number
  );

  filters = addPropertyToFilterObject(
    filters,
    activitiesReportRequestQueryParams.activityStatusId,
    activityStatusIds,
    QueryFilterType.number
  );

  filters = addPropertyToFilterObject(
    filters,
    activitiesReportRequestQueryParams.costCenterId,
    costCenterIds,
    QueryFilterType.array
  );

  filters = addPropertyToFilterObject(
    filters,
    activitiesReportRequestQueryParams.roleId,
    roleIds,
    QueryFilterType.array
  );

  filters = addPropertyToFilterObject(
    filters,
    activitiesReportRequestQueryParams.activityAssignerId,
    activityAssignerIds,
    QueryFilterType.number
  );

  filters = addPropertyToFilterObject(
    filters,
    activitiesReportRequestQueryParams.userSupervisorId,
    userSupervisorIds,
    QueryFilterType.array
  );

  filters = addPropertyToFilterObject(
    filters,
    activitiesReportRequestQueryParams.workLocationId,
    workLocationIds,
    QueryFilterType.array
  );

  filters = addPropertyToFilterObject(
    filters,
    activitiesReportRequestQueryParams.activityEvaluatorId,
    activityEvaluatorIds,
    QueryFilterType.number
  );

  filters = addPropertyToFilterObject(
    filters,
    activitiesReportRequestQueryParams.seriesId,
    seriesIds,
    QueryFilterType.number
  );

  filters = addPropertyToFilterObject(
    filters,
    activitiesReportRequestQueryParams.taskId,
    taskIds,
    QueryFilterType.array
  );

  filters = addPropertyToFilterObject(
    filters,
    activitiesReportRequestQueryParams.userId,
    userIds,
    QueryFilterType.number
  );

  filters = addPropertyToFilterObject(
    filters,
    activitiesReportRequestQueryParams.completedDate,
    completedDate,
    QueryFilterType.dateRange
  );

  filters = addPropertyToFilterObject(
    filters,
    activitiesReportRequestQueryParams.expirationDate,
    expirationDate,
    QueryFilterType.dateRange
  );

  filters = addPropertyToFilterObject(
    filters,
    activitiesReportRequestQueryParams.lastAccessedDate,
    lastAccessedDate,
    QueryFilterType.dateRange
  );

  const expiresInDaysValue =
    expiresInDays === undefined || expiresInDays === 0
      ? undefined
      : [
          DateTime.fromJSDate(new Date(Date.now()))
            .startOf('day')
            .plus({ days: expiresInDays })
            .toISO(),
          DateTime.fromJSDate(new Date(Date.now()))
            .endOf('day')
            .plus({ days: expiresInDays })
            .toISO(),
        ];
  filters.push({
    key: activitiesReportRequestQueryParams.expirationDate,
    type: QueryFilterType.date,
    value: expiresInDaysValue,
  });

  const filterCurrent = isCurrent === undefined || isCurrent ? [true] : [];
  filters = addPropertyToFilterObject(
    filters,
    activitiesReportRequestQueryParams.isCurrent,
    filterCurrent,
    QueryFilterType.boolean
  );

  return filters;
}

function getRequestConfig(
  getActivitiesReportRequest: GetActivitiesReportRequest,
  options: QueryFunctionContext<(string | GetActivitiesReportRequest)[], number>
): URLSearchParams {
  const { sortBy, searchText } = getActivitiesReportRequest;

  const params = new URLSearchParams();

  const filters = getActivitesReportFilterQueryParam(getActivitiesReportRequest);

  const filterQuery = createOdataFilter(filters);

  params.append(OdataQueryParams.filter, filterQuery);

  // Search
  if (searchText !== undefined && searchText.length > 0) {
    const processedSearchText = processSpecialCharacters(searchText);
    params.append(OdataQueryParams.searchText, `"${processedSearchText}"`);
  }

  // Sorting
  const sorts = sortBy?.map(elem => `${elem.key}${elem.isDesc ? ' desc' : ''}`);
  if (sorts !== undefined && sorts?.length > 0)
    params.append(OdataQueryParams.sortBy, sorts?.join(','));

  // Paging
  params.append(OdataQueryParams.skip, options.pageParam?.toString() || '0');
  params.append(OdataQueryParams.top, '50');

  // Count
  params.append(OdataQueryParams.count, 'true');

  return params;
}

async function getActivitiesReport(
  getActivitiesReportRequest: GetActivitiesReportRequest,
  options: QueryFunctionContext<(string | GetActivitiesReportRequest)[], number>
): Promise<PaginatedResponse<GetActivitiesReportResponse>> {
  const params = getRequestConfig(getActivitiesReportRequest, options);

  const requestConfig: AxiosRequestConfig = { params };

  const response = await activitiesReportApi.get<PaginatedResponse<GetActivitiesReportResponse>>(
    GetActivitiesReportRoute(),
    requestConfig
  );
  return response.data;
}

async function getActivitiesReportExcel(
  getActivitiesReportRequest: GetActivitiesReportRequest
): Promise<number> {
  const {
    companyId,
    departmentIds,
    companyTypeIds,
    activityStatusIds,
    costCenterIds,
    roleIds,
    activityAssignerIds,
    activityEvaluatorIds,
    userSupervisorIds,
    workLocationIds,
    searchText,
    seriesIds,
    taskIds,
    userIds,
    // TODO: When excel endpoint is updated to support date ranges, update filter logic
    // completedDate,
    // expirationDate,
    // lastAccessedDate,
    // expiresInDays,
  } = getActivitiesReportRequest;

  const params = new URLSearchParams();
  // Default filters
  params.append(activitiesReportRequestQueryParams.companyId, companyId.toString());
  params.append(activitiesReportRequestQueryParams.isEnabled, 'true');
  // Filters
  departmentIds?.forEach(val =>
    params.append(activitiesReportRequestQueryParams.departmentId, val.toString())
  );
  companyTypeIds?.forEach(val =>
    params.append(activitiesReportRequestQueryParams.companyType, val.toString())
  );
  activityStatusIds?.forEach(val =>
    params.append(activitiesReportRequestQueryParams.activityStatusId, val.toString())
  );
  costCenterIds?.forEach(val =>
    params.append(activitiesReportRequestQueryParams.costCenterId, val.toString())
  );
  roleIds?.forEach(val => params.append(activitiesReportRequestQueryParams.roleId, val.toString()));
  activityAssignerIds?.forEach(val =>
    params.append(activitiesReportRequestQueryParams.activityAssignerId, val.toString())
  );
  userSupervisorIds?.forEach(val =>
    params.append(activitiesReportRequestQueryParams.userSupervisorId, val.toString())
  );
  workLocationIds?.forEach(val =>
    params.append(activitiesReportRequestQueryParams.workLocationId, val.toString())
  );
  activityEvaluatorIds?.forEach(val =>
    params.append(activitiesReportRequestQueryParams.activityEvaluatorId, val.toString())
  );
  seriesIds?.forEach(val =>
    params.append(activitiesReportRequestQueryParams.seriesId, val.toString())
  );
  taskIds?.forEach(val => params.append(activitiesReportRequestQueryParams.taskId, val.toString()));
  userIds?.forEach(val => params.append(activitiesReportRequestQueryParams.userId, val.toString()));

  if (searchText !== undefined && searchText.length > 0) {
    params.append(OdataQueryParams.searchText, searchText);
  }

  const requestConfig: AxiosRequestConfig = { params };

  const response = await activitiesReportApi.get(GetActivitiesReportExcelRoute(), requestConfig);
  return response.status;
}

async function getActivityAffidavitInformation(
  activityAffidavitId: string | undefined
): Promise<ActivityReportAffidavitModel> {
  const stringResponse = await activitiesReportApi.get(
    GetActivityAffidavitByIdRoute(activityAffidavitId)
  );

  const response: ActivityReportAffidavitResponse[] = JSON.parse(
    stringResponse.data.activity_Document
  );

  const payload: AffidavitPayloadInformation[] = JSON.parse(response[0].Payload as string);

  return { ...response[0], Payload: payload[0] };
}

export default {
  getRequestConfig,
  getActivitiesReport,
  getActivitiesReportExcel,
  getActivityAffidavitInformation,
};
