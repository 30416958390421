import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { CompanyUserJobTaskResponse } from '../../../../api/responses/companyUserJobTaskResponse';
import testIds from '../../../../constants/testIds';
import { CompletionStatus } from '../../../CompletionStatus/CompletionStatus';

interface TaskProfileDetailListJobItemTasksProps {
  tasks?: CompanyUserJobTaskResponse[];
}

const TaskProfileDetailListJobItemTasks: React.FC<TaskProfileDetailListJobItemTasksProps> = ({
  tasks = [],
}) => {
  if (tasks.length === 0) {
    return null;
  }

  return (
    <ListGroup className="w-100" data-testid={testIds.taskProfileDetailListJobItemTasks()}>
      {tasks.map(task => (
        <ListGroup.Item
          key={task.id}
          className="d-inline-flex justify-content-start align-items-start border-0 ms-4 py-0 pe-3"
          style={{ fontSize: '0.8571em' }}
          role="listitem"
        >
          <div className="fw-bold me-2 ms-1">&bull;</div>
          <div className="pe-2 flex-fill">{task.name}</div>
          <div className="text-end" style={{ width: '4rem' }}>
            <CompletionStatus isCompleted={task.isComplete} />
          </div>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default TaskProfileDetailListJobItemTasks;
