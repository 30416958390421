import React from 'react';
import { LearningHistoryActivity } from '../../../api/responses/learningHistoryReportResponse';
import commonText from '../../../constants/commonText';
import testIds from '../../../constants/testIds';
import { TableColumnModel } from '../../../models/TableColumnModel';
import dateFormatter from '../../../utils/dateFormatter';

export interface LearningHistorySkillProps {
  skills: LearningHistoryActivity[];
  columns: TableColumnModel[];
  className?: string;
}

const LearningHistorySkill: React.FC<LearningHistorySkillProps> = ({
  skills,
  columns,
  className = '',
}) => {
  const getActivityStatus = (statusCode: number): string => {
    let status = '';
    if (statusCode === 3) {
      status = commonText.failed;
    } else if (statusCode === 5) {
      status = commonText.passed;
    }
    return status;
  };

  const skillRows = skills.map(skill => {
    return (
      <div
        key={skill.Activity_Report_Id}
        className={`w-100 d-flex flex-row flex-nowrap align-items-center ${className}`}
        style={{ height: `${45}px` }}
      >
        <div className="p-2" style={{ flexBasis: `${columns[0].width}%` }} />
        <div className="text-truncate p-2 text-start" style={{ flexBasis: `${columns[1].width}%` }}>
          {skill.Activity_Code}
        </div>
        <div className="text-truncate p-2 text-start" style={{ flexBasis: `${columns[2].width}%` }}>
          {skill.Activity_Name}
        </div>
        <div className="text-truncate p-2 text-start" style={{ flexBasis: `${columns[3].width}%` }}>
          {dateFormatter.formatDate(skill.Activity_Completed, 'MM/dd/yyyy')}
        </div>
        <div className="text-truncate p-2 text-start" style={{ flexBasis: `${columns[4].width}%` }}>
          {dateFormatter.formatDate(skill.Activity_Expires, 'MM/dd/yyyy')}
        </div>
        <div className="text-truncate p-2 text-start" style={{ flexBasis: `${columns[5].width}%` }}>
          {getActivityStatus(skill.Activity_Status_Code)}
        </div>
      </div>
    );
  });

  return <div data-testid={testIds.learningHistorySkillsContainer()}>{skillRows}</div>;
};

export default LearningHistorySkill;
