import { GetUserPoliciesResponse } from '../../api/responses/getUserPoliciesResponse';
import Policy from '../../models/policy';

export interface PolicyServiceResponse {
  canViewTaskProfiling: boolean;
  canViewCustomView: boolean;
  canCreateCustomView: boolean;
  canViewCustomViewConnections: boolean;
  canUpdateCustomView: boolean;
  canDeleteCustomView: boolean;
  canCreateJob: boolean;
  canUpdateJob: boolean;
  canViewIntegrationHub: boolean;
}

function convert(getUserPoliciesResponse: GetUserPoliciesResponse): PolicyServiceResponse {
  const policies = getUserPoliciesResponse?.policies ?? [];
  return {
    canViewTaskProfiling: policies.includes(Policy.CanViewTaskProfiling),
    canViewCustomView: policies.includes(Policy.CanViewCustomView),
    canCreateCustomView: policies.includes(Policy.CanCreateCustomView),
    canViewCustomViewConnections: policies.includes(Policy.CanViewCustomViewConnections),
    canUpdateCustomView: policies.includes(Policy.CanUpdateCustomView),
    canDeleteCustomView: policies.includes(Policy.CanDeleteCustomView),
    canCreateJob: policies.includes(Policy.CanCreateJob),
    canUpdateJob: policies.includes(Policy.CanUpdateJob),
    canViewIntegrationHub: policies.includes(Policy.CanViewIntegrationHub),
  };
}

export default { convert };
