import apiHooks, { ApiResult } from '../../../api/apiHooks';
import { UsersCustomViewsRequest } from '../../../api/requests/usersCustomViewsRequest';
import { CustomViewsResponse } from '../../../api/responses/customViewsResponse';
import { TaskProfileReportData } from '../../../models/taskProfileReportData';
import customViewResponseConverter from '../../../utils/converter/customViewResponseConverter';

function useCustomViewReports(
  requestParams: UsersCustomViewsRequest
): ApiResult<CustomViewsResponse<TaskProfileReportData>> {
  return apiHooks.useUsersCustomViews(requestParams, {
    keepPreviousData: true,
    select: (data: CustomViewsResponse<string>) => customViewResponseConverter.convert(data),
  });
}

export default useCustomViewReports;
