import React from 'react';
import { BsFillCaretDownFill, BsFillCaretUpFill } from 'react-icons/bs';
import testIds from '../../../constants/testIds';
import { TableColumnModel } from '../../../models/TableColumnModel';
import { TableSortInformation } from '../../../models/TableSortInformation';

export interface HeaderProps {
  columns: TableColumnModel[];
  itemHeight: number;
  sortInformation: TableSortInformation[];
  onSortChange: (request: TableSortInformation[]) => void;
}

const Header: React.FC<HeaderProps> = ({ columns, itemHeight, sortInformation, onSortChange }) => {
  const renderHeader = (column: TableColumnModel, index: number) => {
    return (
      <div
        key={column.name}
        className="fw-bold text-truncate p-2 bg-white"
        style={{
          height: `${itemHeight}px`,
          flexBasis: `${column.width}%`,
          cursor: column.isSortable ? 'pointer' : 'default',
        }}
        role="button"
        onClick={() => onHeaderClick(column.sortKey, column.isSortable)}
        onKeyDown={e => onHeaderClick(column.sortKey, column.isSortable, e.key)}
        tabIndex={index}
      >
        <div className="d-flex align-items-center flex-nowrap h-100">
          <span className="text-truncate">{column.header.toUpperCase()}</span>
          <span className="d-flex align-items-center">{getSortIcon(column.sortKey)}</span>
        </div>
      </div>
    );
  };

  const getSortIcon = (sortKey: string) => {
    let icon = null;
    if (sortInformation === undefined || sortInformation.length === 0) return icon;
    const item = sortInformation.find(sortByItem => sortByItem.key === sortKey);
    if (item !== undefined) {
      icon = item.isDesc ? <BsFillCaretDownFill /> : <BsFillCaretUpFill />;
    }
    return icon;
  };

  const onHeaderClick = (sortKey: string, isSortable: boolean, key?: string) => {
    if (!isSortable) return;
    if (key !== undefined && key !== 'Enter') return;
    if (sortInformation === undefined || sortInformation.length === 0) {
      // If sort information is empty or undefined we just create the array
      onSortChange([{ key: sortKey, isDesc: false }]);
    } else {
      // If sort information not empty, we need to see if we are updating a sort information element
      const itemIndex = sortInformation.findIndex(sortByItem => sortByItem.key === sortKey);
      if (itemIndex === -1) {
        onSortChange([...sortInformation, { key: sortKey, isDesc: false }]);
      } else {
        const item = sortInformation[itemIndex];
        if (item.isDesc) {
          sortInformation.splice(itemIndex, 1);
          onSortChange(sortInformation);
        } else {
          sortInformation.splice(itemIndex, 1, { key: sortKey, isDesc: true });
          onSortChange(sortInformation);
        }
      }
    }
  };

  return (
    <div
      className="w-100 d-flex flex-row flex-nowrap sticky-top table-light table-header flex-grow-0"
      data-testid={testIds.tableHeader()}
    >
      {columns.map((item, index) => renderHeader(item, index))}
    </div>
  );
};

export default Header;
