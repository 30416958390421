import React from 'react';
import { Link } from 'react-router-dom';

export interface ChildNavLinkProps {
  mini: string;
  name: string;
  link: string;
  active?: boolean;
}

const ChildNavLink: React.FC<ChildNavLinkProps> = ({ mini, name, link, active = false }) => {
  return (
    <li data-toggle="collapse" className={`${active ? 'active' : ''}`}>
      <Link to={link} className="d-block">
        <div className="sidebar-mini-icon">
          <span>{mini}</span>
        </div>
        <span className="ps-2 sidebar-normal text-nowrap">{name}</span>
      </Link>
    </li>
  );
};

export default ChildNavLink;
