import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { CustomViewConnectionUser } from '../../../api/responses/customViewConnectionUser';
import testIds from '../../../constants/testIds';
import { TaskProfileReportData } from '../../../models/taskProfileReportData';
import customViewFilterParser from '../../../utils/parsers/customViewFilterParser';
import sortByOptionParser from '../../../utils/parsers/sortOptionParser';
import taskProfileCardFeaturesParser from '../../../utils/parsers/taskProfileCardFeaturesParser';
import CardStat from '../../CardStat/CardStat';
import ConnectionUsers from '../../ConnectionUsers/ConnectionUsers';

export interface CustomReportCardProps {
  customViewId: number;
  isLoading: boolean;
  connectedUsers: CustomViewConnectionUser[];
  reportName: string;
  reportData: TaskProfileReportData;
  onClick: () => void;
  canViewCustomViewConnections: boolean;
}

const CustomReportCard: React.FC<CustomReportCardProps> = ({
  customViewId,
  isLoading,
  connectedUsers,
  reportName,
  reportData,
  onClick,
  canViewCustomViewConnections,
}) => {
  const sortOption = sortByOptionParser.parse(reportData.sortBy);
  const filters = customViewFilterParser.parse(reportData);
  const cardsText = taskProfileCardFeaturesParser.parse(reportData.cardDisplayOptions);

  return (
    <Card onClick={onClick} data-testid={testIds.customViewCard(customViewId)}>
      <Card.Body className="pb-0 px-3">
        <Row>
          <Col>
            <Card.Title className="mb-1" as="h4">
              {reportName}
            </Card.Title>
          </Col>
          {canViewCustomViewConnections ? (
            <Col xs="auto">
              <Card.Subtitle className="text-muted mt-0">
                <ConnectionUsers isLoading={isLoading} connectedUsers={connectedUsers} />
              </Card.Subtitle>
            </Col>
          ) : null}
        </Row>
      </Card.Body>
      <Card.Footer className="pt-0 px-3">
        <Row className="justify-content-between align-items-end">
          <Col className="pt-2" xs="auto">
            <CardStat className="mb-0" isLoading={isLoading}>
              <b>Sort:</b> {sortOption?.name ?? ''}
            </CardStat>
            <CardStat className="mb-0" isLoading={isLoading}>
              <b>Filters:</b> {filters}
            </CardStat>
            <CardStat className="mb-0" isLoading={isLoading}>
              <b>Cards:</b> {cardsText}
            </CardStat>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

export default CustomReportCard;
