import { stringify } from 'qs';
import { generatePath } from 'react-router-dom';

const HomePageRoot = '/home';
const Authorize = '/authorize';
const ReportPageRoot = '/reports';
const TaskProfileReport = `${ReportPageRoot}/task-profile` as const;
const TaskProfileReportCustomView = `${TaskProfileReport}/:id?` as const;
const ViewsReport = `${ReportPageRoot}/views` as const;
const JobsCreate = '/jobs' as const;
const JobsEdit = '/jobs/:id' as const;
const IntegrationAnalytics = '/integrations/analytics' as const;
const UserIntegration = '/integrations/users' as const;
const UserIntegrationEdit = '/integrations/users/:id' as const;
const ActivitiesReport = `${ReportPageRoot}/activities` as const;
const ErrorPage = '/error';
const LearningHistory = `${ReportPageRoot}/learninghistory` as const;
const NotFound = '/not-found';
const IntegrationHistory = '/integrations/history' as const;

const queryParamKeys = {
  authorize: {
    companyId: 'companyId',
    userId: 'userId',
    token: 'token',
    to: 'to',
    callbackUrl: 'callbackUrl',
    basicToken: 'basicToken',
  },
  taskProfileReport: {
    isDrawerExpanded: 'isDrawerExpanded',
  },
} as const;

const routes = {
  homePageRoot: {
    queryParams: undefined,
    path: HomePageRoot,
    url: (): string => routes.homePageRoot.path,
  },
  authorize: {
    queryParams: queryParamKeys.authorize,
    path: Authorize,
    url: (): string => routes.authorize.path,
  },
  jobsCreate: {
    queryParams: undefined,
    path: JobsCreate,
    url: (): string => routes.jobsCreate.path,
  },
  jobsEdit: {
    queryParams: undefined,
    path: JobsEdit,
    url: (params: { id: number }): string => {
      return generateRouteUrl(routes.jobsEdit.path, params, {});
    },
  },
  taskProfileReport: {
    queryParams: queryParamKeys.taskProfileReport,
    path: TaskProfileReport,
    url: (params?: { [queryParamKeys.taskProfileReport.isDrawerExpanded]: boolean }): string => {
      return generateRouteUrl(routes.taskProfileReport.path, undefined, params);
    },
  },
  taskProfileReportCustomView: {
    queryParams: undefined,
    path: TaskProfileReportCustomView,
    url: (params: { id: number; isDrawerExpanded?: boolean }): string => {
      return generateRouteUrl(routes.taskProfileReportCustomView.path, params, {
        isDrawerExpanded: params.isDrawerExpanded,
      });
    },
  },
  viewsReport: {
    queryParams: undefined,
    path: ViewsReport,
    url: (): string => routes.viewsReport.path,
  },
  errorPage: {
    queryParams: undefined,
    path: ErrorPage,
    url: (params: { [queryParamKeys.authorize.callbackUrl]: string }): string => {
      return generateRouteUrl(routes.errorPage.path, params, { callbackUrl: params.callbackUrl });
    },
  },
  integrationAnalytics: {
    queryParams: undefined,
    path: IntegrationAnalytics,
    url: (): string => routes.integrationAnalytics.path,
  },
  activitiesReport: {
    queryParams: undefined,
    path: ActivitiesReport,
    url: (): string => routes.activitiesReport.path,
  },
  learningHistory: {
    queryParams: undefined,
    path: LearningHistory,
    url: (): string => routes.learningHistory.path,
  },
  userIntegration: {
    queryParams: undefined,
    path: UserIntegration,
    url: (queryParams?: { integrationName: string; integrationType: number }): string => {
      return generateRouteUrl(routes.userIntegration.path, null, queryParams);
    },
  },
  userIntegrationEdit: {
    queryParams: undefined,
    path: UserIntegrationEdit,
    url: (params: { id: number }): string => {
      return generateRouteUrl(routes.userIntegrationEdit.path, params);
    },
  },
  notFound: {
    queryParams: undefined,
    path: NotFound,
    url: (): string => routes.notFound.path,
  },
  integrationHistory: {
    queryParams: undefined,
    path: IntegrationHistory,
    url: (
      params?: unknown,
      queryParams?: { integrationId: number; date: string; activeTab: string }
    ): string => {
      return generateRouteUrl(routes.integrationHistory.path, params, queryParams);
    },
  },
} as const;

function generateRouteUrl<TPath extends string, TQueryParam extends {}>(
  path: TPath,
  // eslint-disable-next-line
  pathParameters?: any | undefined,
  queryParameters?: TQueryParam
): string {
  const generatedPath = generatePath(path, pathParameters);

  const queryString = stringify(queryParameters);
  if (queryString.length > 0) {
    return `${generatedPath}?${queryString}`;
  }

  return generatedPath;
}

export default routes;
