import React from 'react';
import CloseButton from 'react-bootstrap/CloseButton';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export interface DrawerHeaderProps {
  title?: React.ReactNode;
  onCloseClick: () => void;
}

const DrawerHeader: React.FC<DrawerHeaderProps> = ({ title, onCloseClick }) => {
  return (
    <Row className="justify-content-between mb-1 flex-grow-0">
      {title && <Col className="flex-grow-1 pe-0">{title}</Col>}
      <Col className="flex-grow-0 ms-auto">
        <CloseButton onClick={onCloseClick} />
      </Col>
    </Row>
  );
};

export default DrawerHeader;
