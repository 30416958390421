import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';
import React from 'react';
import Card from 'react-bootstrap/Card';
import { BsCheckCircle, BsFilePdf, BsStopwatch, BsXCircle } from 'react-icons/bs';
import { GetActivitiesReportRequest } from '../../../api/requests/getActivitiesReportRequest';
import { GetActivitiesReportResponse } from '../../../api/responses/getActivitiesReportResponse';
import { PaginatedResponse } from '../../../api/responses/paginatedResponse';
import { TableColumnModel } from '../../../models/TableColumnModel';
import { TableSortInformation } from '../../../models/TableSortInformation';
import dateFormatter from '../../../utils/dateFormatter';
import Table from '../../Table/Table';

export interface ActivitiesTableProps {
  activityData?: PaginatedResponse<GetActivitiesReportResponse>[];
  activitiesReportRequest: GetActivitiesReportRequest;
  isActivitiesReportLoading: boolean;
  activityDataCount: number | undefined;
  requestError: boolean;
  onActivitiesReportRequestChange: (reportData: GetActivitiesReportRequest) => void;
  onPageChange: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<
    PaginatedResponse<GetActivitiesReportResponse>,
    unknown
  > | void>;
  setAffidavitId: React.Dispatch<string>;
  hasNextPage: boolean;
}

const ActivitiesTable: React.FC<ActivitiesTableProps> = ({
  activityData,
  activitiesReportRequest,
  isActivitiesReportLoading,
  activityDataCount,
  requestError,
  onActivitiesReportRequestChange,
  onPageChange,
  setAffidavitId,
  hasNextPage,
}) => {
  const columns: TableColumnModel[] = [
    {
      name: 'employee',
      header: 'Employee',
      sortKey: 'User_Full_Name',
      width: 9,
      value: 'User_Full_Name',
      isSortable: true,
    },
    {
      name: 'Company',
      header: 'Company',
      sortKey: 'Company_Name',
      width: 8,
      value: 'Company_Name',
      isSortable: true,
    },
    {
      name: 'Series',
      header: 'Series',
      sortKey: 'Series_Name',
      width: 8,
      value: 'Series_Name',
      isSortable: true,
    },
    {
      name: 'Activity',
      header: 'Activity',
      sortKey: 'Activity_Name',
      width: 19.5,
      value: 'Activity_Name',
      isSortable: true,
    },
    {
      name: 'Type',
      header: 'Type',
      sortKey: 'Activity_Type',
      width: 5,
      value: 'Activity_Type',
      isSortable: true,
    },
    {
      name: 'Completed',
      header: 'Completed',
      sortKey: 'Activity_Completion_Date',
      width: 12,
      value: 'Activity_Completion_Date',
      isSortable: true,
    },
    {
      name: 'Expires',
      header: 'Expires',
      sortKey: 'Activity_Expiration_Date',
      width: 6,
      value: 'Activity_Expiration_Date',
      isSortable: true,
    },
    {
      name: 'Attempt',
      header: 'Attempt',
      sortKey: 'Activity_Attempt',
      width: 6,
      value: 'Activity_Attempt',
      isSortable: true,
    },
    {
      name: 'Proctor/Evaluator',
      header: 'Proctor/Evaluator',
      sortKey: 'Activity_Evaluator_Full_Name',
      width: 12.5,
      value: 'Activity_Evaluator_Full_Name',
      isSortable: true,
    },
    {
      name: 'Status',
      header: 'Status',
      sortKey: 'Activity_Status',
      width: 8,
      value: 'Activity_Status',
      isSortable: true,
    },
    {
      name: 'Affidavit',
      header: 'Affidavit',
      sortKey: 'Activity_Affidavit_Id',
      width: 6,
      value: 'Activity_Affidavit_Id',
      isSortable: false,
    },
  ];
  const itemHeight: number = 45;

  const getStatusIcon = (status: string) => {
    let icon = null;
    const status2 = status.toLowerCase();
    if (status2 === 'passed' || status2 === 'completed') {
      icon = <BsCheckCircle className="pe-1" fontSize={18} color="green" />;
    } else if (status2 === 'failed') {
      icon = <BsXCircle className="pe-1" fontSize={18} color="red" />;
    } else if (status2 === 'incomplete') {
      icon = <BsStopwatch className="pe-1" fontSize={18} />;
    }
    return icon;
  };

  const rowTemplate = (activity: GetActivitiesReportResponse, index: number) => {
    const status =
      activity.Activity_Status !== undefined
        ? activity.Activity_Status.charAt(0).toUpperCase() +
          activity.Activity_Status.slice(1).toLowerCase()
        : '';
    return (
      <div
        key={activity.Id}
        className={`w-100 d-flex flex-row flex-nowrap align-items-center ${
          index % 2 === 0 ? '' : 'table-row-background-color'
        }`}
        style={{ height: `${itemHeight}px` }}
      >
        <div className="text-truncate p-2" style={{ flexBasis: `${columns[0].width}%` }}>
          {activity.User_Full_Name}
        </div>
        <div className="text-truncate p-2" style={{ flexBasis: `${columns[1].width}%` }}>
          {activity.Company_Name}
        </div>
        <div className="text-truncate p-2" style={{ flexBasis: `${columns[2].width}%` }}>
          {activity.Series_Name}
        </div>
        <div className="text-truncate p-2" style={{ flexBasis: `${columns[3].width}%` }}>
          {activity.Activity_Name}
        </div>
        <div className="text-truncate p-2" style={{ flexBasis: `${columns[4].width}%` }}>
          {activity.Activity_Type}
        </div>
        <div className="text-truncate p-2" style={{ flexBasis: `${columns[5].width}%` }}>
          {dateFormatter.formatDate(activity.Activity_Completion_Date, 'MM/dd/yyyy hh:mm a ZZZZ')}
        </div>
        <div className="text-truncate p-2" style={{ flexBasis: `${columns[6].width}%` }}>
          {dateFormatter.formatDate(activity.Activity_Expiration_Date, 'MM/dd/yyyy')}
        </div>
        <div className="text-truncate p-2" style={{ flexBasis: `${columns[7].width}%` }}>
          {activity.Activity_Attempt}
        </div>
        <div className="text-truncate p-2" style={{ flexBasis: `${columns[8].width}%` }}>
          {activity.Activity_Evaluator_Full_Name}
        </div>
        <div className="p-2" style={{ flexBasis: `${columns[9].width}%` }}>
          <div className="d-flex align-items-center">
            {getStatusIcon(status)}
            {status}
          </div>
        </div>
        <div className="text-center text-truncate" style={{ flexBasis: `${columns[10].width}%` }}>
          {activity.Activity_Affidavit_Id !== undefined &&
          activity.Activity_Affidavit_Id !== null ? (
            <BsFilePdf
              fontSize={20}
              style={{ cursor: 'pointer' }}
              onClick={() => setAffidavitId(activity.Activity_Affidavit_Id)}
            />
          ) : null}
        </div>
      </div>
    );
  };

  const onSortChange = (sortInformation: TableSortInformation[]) => {
    onActivitiesReportRequestChange({
      ...activitiesReportRequest,
      sortBy: sortInformation,
    });
  };

  const handlePageChange = () => {
    onPageChange();
  };

  return (
    <Card className="w-100">
      <Card.Body className="d-flex" style={{ height: '800px' }}>
        <Table<GetActivitiesReportResponse, GetActivitiesReportRequest>
          data={activityData?.reduce(
            (
              accum: GetActivitiesReportResponse[],
              item: PaginatedResponse<GetActivitiesReportResponse>
            ) => [...accum, ...item.value],
            []
          )}
          columns={columns}
          height={768}
          itemHeight={itemHeight}
          numberOfItems={activityDataCount || 0}
          sortInformation={activitiesReportRequest.sortBy || []}
          requestInformation={activitiesReportRequest}
          visibleRows={16}
          rowTemplate={rowTemplate}
          onSortChange={onSortChange}
          isLoading={isActivitiesReportLoading}
          pageSize={50}
          onPageChange={handlePageChange}
          requestError={requestError}
          hasNextPage={hasNextPage}
          estimateSize={() => 54}
          emptyStateMessage="No Records Available"
        />
      </Card.Body>
    </Card>
  );
};

export default ActivitiesTable;
