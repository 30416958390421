import { Dimensions } from '../models/dimensions';

function getContentDimensions(element: HTMLElement): Dimensions {
  const { paddingLeft, paddingRight, paddingTop, paddingBottom } = window.getComputedStyle(element);
  const xPadding = (parseFloat(paddingLeft) || 0) + (parseFloat(paddingRight) || 0);
  const yPadding = (parseFloat(paddingTop) || 0) + (parseFloat(paddingBottom) || 0);

  return {
    height: element.clientHeight - yPadding,
    width: element.clientWidth - xPadding,
  };
}

export default { getContentDimensions };
