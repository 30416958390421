import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import React from 'react';
import Chart from '../../Chart/Chart';

export interface DoughnutChartProps {
  dataset: ChartDataset<'doughnut', number[]>;
  isTooltipEnabled: boolean;
}

const DoughnutChart: React.FC<DoughnutChartProps> = ({ dataset, isTooltipEnabled }) => {
  const data: ChartData = {
    labels: [' Complete', ' Incomplete'],
    datasets: [dataset],
  };

  const options: ChartOptions<'doughnut'> = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: isTooltipEnabled,
      },
    },
    cutout: '82%',
    animation: {
      animateRotate: false,
    },
  };

  return <Chart type="doughnut" options={options} data={data} />;
};

export default DoughnutChart;
