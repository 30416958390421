const colors = {
  red: '#cc2127',
  green: '#7A9D4B',
  gray: 'lightgray',
};

const progressChart: [string, string] = [colors.green, colors.red];

export default {
  colors,
  progressChart,
};
