/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { BsExclamationTriangle, BsQuestionCircle } from 'react-icons/bs';
import commonText from '../../../constants/commonText';
import testIds from '../../../constants/testIds';
import { DisplayItem } from '../../../models/displayItem';
import UncontrolledDropDownWithLabel from '../../DropDownWithLabel/UncontrolledDropDownWithLabel';
import ToolTip from '../../ToolTip/ToolTip';

export interface AdvanceConfigurationCardProps {
  onLeadingZerosChange: (leadingZeroDisplayItem: DisplayItem) => void;
  selectedLeadingZerosDisplayItem: DisplayItem;
  onPrependUsernameChange: (prependUsername: string) => void;
  handleMissingFieldsAssignmentsOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  missingFields: boolean;
  prependUsername: string;
}

const displayItemOptions: DisplayItem[] = [
  { itemId: 0, display: 'none' },
  { itemId: 2, display: '2' },
  { itemId: 3, display: '3' },
  { itemId: 4, display: '4' },
  { itemId: 5, display: '5' },
  { itemId: 6, display: '6' },
  { itemId: 7, display: '7' },
  { itemId: 8, display: '8' },
  { itemId: 9, display: '9' },
  { itemId: 10, display: '10' },
];

const AdvanceConfigurationCard: React.FC<AdvanceConfigurationCardProps> = ({
  onLeadingZerosChange,
  selectedLeadingZerosDisplayItem,
  onPrependUsernameChange,
  handleMissingFieldsAssignmentsOnChange,
  missingFields,
  prependUsername,
}) => {
  const [disabledState, setDisabledState] = useState<boolean>(true);

  useEffect(() => {
    handleUsernamePrefixChange(prependUsername);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prependUsername]);

  function handleUsernamePrefixChange(input: string) {
    const regex = /[a-zA-Z]/;
    if (regex.test(input)) {
      setDisabledState(false);
    } else {
      setDisabledState(true);
      if (selectedLeadingZerosDisplayItem.itemId !== displayItemOptions[0].itemId) {
        onLeadingZerosChange(displayItemOptions[0]);
      }
    }
  }

  function callsForUsernamePrefix(input: string) {
    onPrependUsernameChange(input);
  }

  return (
    <>
      <Alert className="mt-3 d-flex" variant="warning">
        <div className="flex-grow-0 mr-2 align-self-center" style={{ paddingRight: 4 }}>
          <BsExclamationTriangle className="mb-1" size={16} />
        </div>
        <div className="align-self-center">
          If using username prefix and/or leading zeros the username should always be sent without
          the prefix or leading zeros format.
        </div>
      </Alert>
      <div className="d-flex flex-row p-1">
        <Form.Check
          id="missingFieldsCheckbox"
          data-testid="missingFieldsCheckbox"
          className="me-1"
          label={commonText.createMissingFieldsText}
          checked={missingFields}
          onChange={handleMissingFieldsAssignmentsOnChange}
        />
      </div>
      <div className="d-flex flex-row p-1">
        <div className="me-1">Username Prefix</div>
        <ToolTip content={commonText.usernamePrefixToolTipText}>
          <BsQuestionCircle
            className="ml-1 mb-1"
            data-testid={testIds.usernamePrefixTooltipContainer()}
          />
        </ToolTip>
      </div>
      <Form.Control
        className="border-secondary"
        aria-label="Title"
        placeholder="Username Prefix"
        onChange={event => callsForUsernamePrefix(event.target.value)}
        value={prependUsername}
      />
      <div className="mt-2 p-1">
        <UncontrolledDropDownWithLabel
          label="Leading Zeros"
          disabled={disabledState}
          labelIcon={
            <ToolTip content={commonText.leadingZerosToolTipText}>
              <BsQuestionCircle
                className="ml-1 mb-1"
                data-testid={testIds.leadingZeroTooltipContainer()}
              />
            </ToolTip>
          }
          title={selectedLeadingZerosDisplayItem.display}
          variant="transparent"
          testId={testIds.leadingZerosDropdown()}
        >
          {displayItemOptions.map(option => (
            <Dropdown.Item
              key={option.itemId}
              onClick={() => onLeadingZerosChange(option)}
              active={selectedLeadingZerosDisplayItem.itemId === option.itemId}
            >
              {option.display}
            </Dropdown.Item>
          ))}
        </UncontrolledDropDownWithLabel>
      </div>
    </>
  );
};

export default AdvanceConfigurationCard;
