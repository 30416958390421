import React from 'react';
import { useLocation } from 'react-router-dom';
import commonText from '../../../constants/commonText';
import routes from '../../../constants/routes';
import testIds from '../../../constants/testIds';
import { AvailablePolicies } from '../../../policyService/policyEnforcer/policyEnforcer';
import navigation from '../../../utils/navigation';
import ChildNavLink from '../ChildNavLink/ChildNavLink';
import ChildNavList from '../ChildNavList/ChildNavList';
import ImageNavLink from '../ImageNavLink/ImageNavLink';
import ParentNavLink from '../ParentNavLink/ParentNavLink';
import Sidebar from '../Sidebar/Sidebar';

export interface MainLayoutProps {
  isCompanyLoading: boolean;
  companyImage?: string;
  companyName?: string;
  policies: Pick<
    AvailablePolicies,
    | 'canViewTaskProfile'
    | 'canViewCustomView'
    | 'canViewActivities'
    | 'canViewIntegrationHub'
    | 'canViewLearningHistory'
  >;
  isSidebarVisible?: boolean;
  className?: string;
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({
  isCompanyLoading,
  companyImage,
  companyName,
  children,
  policies,
  isSidebarVisible = true,
}) => {
  const location = useLocation();

  return (
    <div className="wrapper overflow-hidden">
      {isSidebarVisible ? (
        <Sidebar
          company={
            <div data-testid={testIds.companySidebarSection()}>
              <ImageNavLink
                isLoading={isCompanyLoading}
                image={companyImage ?? ''}
                imageAltText="Company Avatar"
                name={companyName ?? ''}
              />
            </div>
          }
        >
          <ParentNavLink expanded linkText="Reports">
            <ChildNavList expanded>
              {policies.canViewTaskProfile ? (
                <ChildNavLink
                  mini="L"
                  name={commonText.live}
                  link={routes.taskProfileReport.url()}
                  active={navigation.isRouteActive(
                    location.pathname,
                    routes.taskProfileReport.path
                  )}
                />
              ) : null}
              {policies.canViewCustomView ? (
                <ChildNavLink
                  mini="V"
                  name={commonText.views}
                  link={routes.viewsReport.url()}
                  active={navigation.isRouteActive(location.pathname, routes.viewsReport.path)}
                />
              ) : null}
              {policies.canViewActivities ? (
                <ChildNavLink
                  mini="A"
                  name={commonText.activity}
                  link={routes.activitiesReport.url()}
                  active={navigation.isRouteActive(location.pathname, routes.activitiesReport.path)}
                />
              ) : null}
              {policies.canViewLearningHistory ? (
                <ChildNavLink
                  mini="LH"
                  name={commonText.learningHistory}
                  link={routes.learningHistory.url()}
                  active={navigation.isRouteActive(location.pathname, routes.learningHistory.path)}
                />
              ) : null}
            </ChildNavList>
          </ParentNavLink>

          {policies.canViewIntegrationHub ? (
            <ParentNavLink expanded linkText="Integration Hub">
              <ChildNavList expanded>
                <ChildNavLink
                  mini="A"
                  name={commonText.analytics}
                  link={routes.integrationAnalytics.url()}
                  active={navigation.isRouteActive(
                    location.pathname,
                    routes.integrationAnalytics.path
                  )}
                />
                <ChildNavLink
                  mini="H"
                  name={commonText.history}
                  link={routes.integrationHistory.url()}
                  active={navigation.isRouteActive(
                    location.pathname,
                    routes.integrationHistory.path
                  )}
                />
              </ChildNavList>
            </ParentNavLink>
          ) : null}
        </Sidebar>
      ) : null}
      <main className={`main-panel d-flex flex-column h-100 ${!isSidebarVisible ? 'w-100' : ''}`}>
        {children}
      </main>
    </div>
  );
};

export default MainLayout;
