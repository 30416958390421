import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import testIds from '../../../constants/testIds';
import pagingUtils from '../../../utils/pagingUtils';

const nextPrevPaginationItemStyles: React.CSSProperties = {
  minWidth: '6.25rem',
};

export interface PagingProps {
  totalPages: number;
  currentPage: number;
  onClick: (page: number) => void;
}

const Paging: React.FC<PagingProps> = ({ totalPages, currentPage, onClick }) => {
  const numPagesShown = Math.min(totalPages, 3);
  const startingRange = pagingUtils.getStartingRange(currentPage, totalPages, numPagesShown);

  return (
    <Pagination className="mb-2">
      <Pagination.Item
        style={nextPrevPaginationItemStyles}
        disabled={currentPage === 1}
        onClick={() => onClick(currentPage - 1)}
      >
        <div
          className="d-inline-flex justify-content-center align-items-center px-3"
          data-testid={testIds.pagingPreviousPage()}
        >
          <span className="me-2" aria-hidden="true">
            &laquo;
          </span>
          <span>Previous</span>
        </div>
      </Pagination.Item>
      {Array.from(Array(numPagesShown), (_, index) => {
        const page = startingRange + index;
        const isActive = page === currentPage;

        return (
          <Pagination.Item
            key={index}
            className={isActive ? 'active' : ''}
            onClick={() => onClick(page)}
          >
            <div
              className="d-flex align-items-center justify-content-center"
              aria-label={isActive ? `page ${page}` : `go to page ${page}`}
              aria-current={isActive ? true : undefined}
            >
              {page}
            </div>
          </Pagination.Item>
        );
      })}
      <Pagination.Item
        style={nextPrevPaginationItemStyles}
        disabled={currentPage === totalPages}
        onClick={() => onClick(currentPage + 1)}
      >
        <div
          className="d-inline-flex justify-content-center align-items-center px-3 w-100"
          data-testid={testIds.pagingNextPage()}
        >
          <span>Next</span>
          <span className="ms-2" aria-hidden="true">
            &raquo;
          </span>
        </div>
      </Pagination.Item>
    </Pagination>
  );
};

export default Paging;
