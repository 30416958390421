import {
  SortByOption,
  TaskProfileSortByOption,
  TaskProfileSortByOptions,
} from '../../api/requests/companyQualificationRequest';
import { DeepWriteable } from '../../types/writeable';
import array from '../array';

function parse(sortOptions?: readonly SortByOption[]): TaskProfileSortByOption | undefined {
  if (sortOptions === undefined) {
    return undefined;
  }

  const sortOption: TaskProfileSortByOption | undefined = Object.values(
    TaskProfileSortByOptions as DeepWriteable<typeof TaskProfileSortByOptions>
  ).find(taskProfileSortOption => {
    return array.areEqual(taskProfileSortOption.sortByParams, sortOptions as SortByOption[]);
  });

  return sortOption;
}

export default { parse };
