import { ChartData, ChartDataset, ChartOptions, ChartType } from 'chart.js';
// eslint-disable-next-line import/no-extraneous-dependencies
import ChartJSChart from 'chart.js/auto';
import React, { useEffect } from 'react';

export interface ChartProps<TChartType extends ChartType = ChartType> {
  type: TChartType;
  data: ChartData;
  options?: ChartOptions<TChartType>;
}

const Chart = <TChartType extends ChartType = ChartType>({
  type,
  data,
  options,
}: ChartProps<TChartType>): JSX.Element => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const chartRef = React.useRef<ChartJSChart>();

  useEffect(() => {
    if (canvasRef.current !== null && chartRef.current === undefined) {
      chartRef.current = new ChartJSChart(canvasRef.current, {
        type,
        data,
        options,
      });
    } else if (canvasRef.current !== null && chartRef.current !== undefined) {
      chartRef.current.data?.datasets.forEach((dataset: ChartDataset) => {
        // eslint-disable-next-line no-param-reassign
        dataset.data = data.datasets[0].data;
        // eslint-disable-next-line no-param-reassign
        dataset.backgroundColor = data.datasets[0].backgroundColor;
      });
      chartRef.current.update('none');
    }
  }, [type, data, options]);

  return <canvas ref={canvasRef} />;
};

export default Chart;
