import { createContext } from 'react';

export interface ZoomContextParams {
  zoomLevel: number;
  zoomIn: () => void;
  zoomOut: () => void;
}

const ZoomContext = createContext<ZoomContextParams>({
  zoomLevel: 1,
  zoomIn: () => {},
  zoomOut: () => {},
});

export default ZoomContext;
