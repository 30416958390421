import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import Html from 'react-pdf-html';
import { ActivityReportAffidavitModel } from '../../api/responses/getActivityAffidavitInformationResponse';
import OnBoardLogoSwirl from '../../assets/images/OnBoard-logo-transparent-swirl.png';

export interface AffidavitProps {
  affidavitInformation: ActivityReportAffidavitModel | undefined;
}

const styles = StyleSheet.create({
  systemInfo: {
    marginTop: 20,
    color: '#aaa',
    fontSize: 8,
  },
  icon: {
    height: 22,
    width: 22,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: 8,
    fontSize: 12,
  },
  table: {
    width: 'auto',
    margin: 16,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  cell: {
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 8,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 100,
  },
  cellHeader: {
    fontSize: 14,
    fontWeight: 'ultrabold',
  },
  cellInformation: {
    fontSize: 11,
    width: 'auto',
    maxWidth: '100%',
  },
  odd: {
    backgroundColor: 'rgba(0,0,0,0.05)',
  },
  procedureIcons: {
    borderRadius: 20,
    borderWidth: 1,
    width: 15,
    height: 15,
    fontSize: 10,
    display: 'flex',
    justifyContent: 'center',
    marginRight: '4px',
    alignItems: 'center',
    backgroundColor: 'white',
    color: 'black',
  },
  procedureIconCorrect: {
    backgroundColor: 'green',
    color: 'white',
  },
  procedureIconIncorrect: {
    backgroundColor: 'red',
    color: 'white',
  },
  procedureIconContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 5,
  },
  line: {
    borderBottomColor: 'black',
    borderStyle: 'solid',
    borderBottomWidth: '1',
  },
});

const procedureStyles = {
  img: {
    height: 30,
    width: 30,
    padding: 2,
  },
  div: {
    fontSize: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    wordBreak: 'break-all',
    whiteSpace: 'normal',
  },
  'div:last-child': {
    minWidth: 0,
    maxWidth: '95%',
  },
  li: {
    fontSize: 10,
  },
  ul: {
    fontSize: 10,
  },
};

const getProcedureIcon = (status: number): JSX.Element => {
  return (
    <View style={styles.procedureIconContainer}>
      <View style={[styles.procedureIcons, status === 5 ? styles.procedureIconCorrect : {}]}>
        <Text>Y</Text>
      </View>
      <View style={[styles.procedureIcons, status !== 5 ? styles.procedureIconIncorrect : {}]}>
        <Text>N</Text>
      </View>
    </View>
  );
};

function getProcedureName(procedureName: string): JSX.Element {
  let name = procedureName.replace('®', '');
  name = name.replace(/<p>/, '<div>');
  name = name.replace(/<\/p>/, '</div>');
  return <Html stylesheet={procedureStyles}>{name}</Html>;
}

function procedureListFormatting(affidavitInformation: ActivityReportAffidavitModel): JSX.Element {
  const procedureList = affidavitInformation.Payload.Skill.Procedures.map(procedure => (
    <View style={styles.line} wrap={false} key={procedure.Id}>
      {getProcedureIcon(procedure.Status)}
      {getProcedureName(procedure.Name)}
    </View>
  ));
  return <>{procedureList}</>;
}

const formatDuration = (seconds: number): string => {
  const date = new Date(0, 0, 0, 0, 0, seconds);
  return date.toLocaleTimeString('it-IT');
};

const Affidavit: React.FC<AffidavitProps> = ({ affidavitInformation }) => {
  if (affidavitInformation === undefined) return null;
  return (
    <Document>
      <Page>
        <View style={styles.header}>
          <Image style={styles.icon} src={OnBoardLogoSwirl} />
          <Text style={{ padding: 5 }}>OnBoard Learning Management System Field Evaluation</Text>
        </View>
        <View style={styles.table}>
          <View style={styles.row}>
            <View style={styles.cell}>
              <View>
                <Text style={styles.cellHeader}>Task</Text>
              </View>
              <View>
                <Text style={styles.cellInformation}>
                  {affidavitInformation.Payload.Skill.Name}
                </Text>
              </View>
            </View>
            <View style={styles.cell}>
              <View>
                <Text style={styles.cellHeader}>Date Performed</Text>
              </View>
              <View>
                <Text style={styles.cellInformation}>
                  {`${new Date(affidavitInformation.Payload.SystemTime).toLocaleString()} ${
                    affidavitInformation.Payload.TimeZone
                  }`}
                </Text>
              </View>
            </View>
          </View>
          <View style={{ ...styles.row, ...styles.odd }}>
            <View style={styles.cell}>
              <View>
                <Text style={styles.cellHeader}>Student</Text>
              </View>
              <View>
                <Text style={styles.cellInformation}>
                  {`${affidavitInformation.Payload.Student.FullName} (${affidavitInformation.Payload.Student.Username})`}
                </Text>
              </View>
            </View>
            <View style={styles.cell}>
              <View>
                <Text style={styles.cellHeader}>Evaluator</Text>
              </View>
              <View>
                <Text style={styles.cellInformation}>
                  {`${affidavitInformation.Payload.Evaluator.FullName} (${affidavitInformation.Payload.Evaluator.Username})`}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.cell}>
              <View>
                <Text style={styles.cellHeader}>Status</Text>
              </View>
              <View>
                <Text style={styles.cellInformation}>
                  {affidavitInformation.Payload.Status === 5 ? 'Passed' : 'Failed'}
                </Text>
              </View>
            </View>
            <View style={styles.cell}>
              <View>
                <Text style={styles.cellHeader}>Method</Text>
              </View>
              <View>
                <Text style={styles.cellInformation}>
                  {affidavitInformation.Payload.Method.ShortMethod}
                </Text>
              </View>
            </View>
          </View>
          <View style={{ ...styles.row, ...styles.odd }}>
            <View style={styles.cell}>
              <View>
                <Text style={styles.cellHeader}>Location</Text>
              </View>
              <View>
                <Text style={styles.cellInformation}>
                  {affidavitInformation.Latitude === null
                    ? 'Unavailable'
                    : `${affidavitInformation.Street} ${affidavitInformation.City} ${affidavitInformation.State} ${affidavitInformation.Zip} (${affidavitInformation.Latitude}, ${affidavitInformation.Longitude})`}
                </Text>
              </View>
            </View>
            <View style={styles.cell}>
              <View>
                <Text style={styles.cellHeader}>Duration(hh:mm:ss)</Text>
              </View>
              <View>
                <Text style={styles.cellInformation}>
                  {affidavitInformation.Payload.SessionTime > 0
                    ? formatDuration(affidavitInformation.Payload.SessionTime)
                    : 'Unavailable'}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={{ padding: 8 }}>
          <Text>Procedures</Text>
          <Text style={{ fontSize: 10 }}>Satisfactory (Y/N)</Text>
          <View>{procedureListFormatting(affidavitInformation)}</View>
        </View>

        <View style={{ padding: 8 }}>
          <Text>Signatures</Text>
        </View>
        <View
          wrap={false}
          style={{
            padding: 20,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <View>
            <Text style={{ fontSize: 10 }}>Evaluator Signature</Text>
            {affidavitInformation.Payload.Evaluator.SignatureUrl !== null &&
            affidavitInformation.Payload.Evaluator.SignatureUrl !== '' ? (
              <Image
                style={{ height: 200, width: 200 }}
                src={affidavitInformation.Payload.Evaluator.SignatureUrl}
              />
            ) : (
              <Text>No evaluator signature</Text>
            )}
          </View>
          <View>
            <Text style={{ fontSize: 10 }}>Student Signature</Text>
            {affidavitInformation.Payload.Student.SignatureUrl !== null &&
            affidavitInformation.Payload.Student.SignatureUrl !== '' ? (
              <Image
                style={{ height: 200, width: 200 }}
                src={affidavitInformation.Payload.Student.SignatureUrl}
              />
            ) : (
              <Text>No student signature</Text>
            )}
          </View>
        </View>
        <View style={{ padding: 8 }}>
          <Text style={styles.systemInfo}>System Info:</Text>
          <View style={{ fontSize: 8, color: '#aaa' }}>
            <Text>
              Date:
              {affidavitInformation.Payload.DatePerformed}
            </Text>
            <Text>Device Id: {affidavitInformation.DeviceId}</Text>
            <Text>
              Connect Mode: {affidavitInformation.Payload.Mode === 1 ? 'Online' : 'Offline'}
            </Text>
            <Text>IP: {affidavitInformation.IP} </Text>
            <Text>User Agent: {affidavitInformation.UserAgent}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Affidavit;
