import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';

export interface ListItemProps {
  text: string;
  onClick: () => void;
  isDisabled?: boolean;
}

const ListItem: React.FC<ListItemProps> = ({ text, onClick, isDisabled = false }) => {
  return (
    <ListGroup.Item
      onClick={onClick}
      as="li"
      role="listitem"
      className="btn-sm border-0 rounded-0 btn-pointer-hover user-select-none list-group-item-action"
      disabled={isDisabled}
    >
      {text}
    </ListGroup.Item>
  );
};

export default ListItem;
