import React, { useCallback } from 'react';
import { ApiResult } from '../../../api/apiHooks';
import { UserQualification } from '../../../api/responses/userQualification';
import { UserStatisticsResponse } from '../../../api/responses/userStatisticsResponse';
import CardGridCollection from '../../CardGrid/CardGridCollection';
import { TaskProfileCard } from '../Card/TaskProfileCard';
import { TaskProfileModalProps } from '../Modal/TaskProfileModalProps';
import { TaskProfileCardFeatures } from '../ReportDrawer/taskProfileCardDisplayOptions';
import { TaskProfileCardPrimaryDataPoints } from '../ReportDrawer/taskProfileCardPrimaryDataPoints';
import { JobStatsResponse } from '../../../api/responses/jobStatsResponse';
import JobAssocMethod from '../../../models/JobAssocMethod';

export interface TaskProfileCardGridCollectionProps {
  data: UserQualification[];
  userStatistics?: Record<number, UserStatisticsResponse>;
  isLoadingUserStatistics: boolean;
  features: TaskProfileCardFeatures;
  primaryDataPoint: TaskProfileCardPrimaryDataPoints;
  numOfCards: number;
  onCardClick: (modalProps: TaskProfileModalProps) => void;
  onGetUserStatistics: (userId: number) => ApiResult<UserStatisticsResponse>;
  onGetJobStatistics: (userId: number) => ApiResult<JobStatsResponse>;
  jobAssocMethod: JobAssocMethod;
}

const TaskProfileCardGridCollection: React.FC<TaskProfileCardGridCollectionProps> = ({
  data,
  features,
  primaryDataPoint,
  numOfCards,
  onCardClick,
  onGetUserStatistics,
  onGetJobStatistics,
  jobAssocMethod,
}) => {
  const renderCard = useCallback(
    (taskData: UserQualification) => (
      <TaskProfileCard
        fullName={taskData.fullName}
        userName={taskData.userName}
        onClick={onCardClick}
        userId={taskData.userId}
        note={taskData.note}
        features={features}
        primaryDataPoint={primaryDataPoint}
        onGetUserStatistics={onGetUserStatistics}
        onGetJobStatistics={onGetJobStatistics}
        jobAssocMethod={jobAssocMethod}
      />
    ),
    [
      onCardClick,
      features,
      primaryDataPoint,
      onGetUserStatistics,
      onGetJobStatistics,
      jobAssocMethod,
    ]
  );

  return <CardGridCollection numOfCards={numOfCards} data={data} render={renderCard} />;
};

export default TaskProfileCardGridCollection;
