// Enum associated with Task Profile custom views' settings, specifically how a user's job report data should be calculated
// to show on their task profile report card.
enum JobAssocMethod {
  // Legacy jobs (all tasks associated with the job are considered required for a user to be complete in the job)
  Jobs = 0,
  // Supports complex job task mappings and enforces task profiling
  JobsRespectMappingsAndTaskProfiling = 1,
  // Supports complex job task mappings and does NOT enforce task profiling
  JobsRespectMappingsOnly = 2,
}

export default JobAssocMethod;
