import { round } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ZoomContext from './ZoomContext';

const zoomStep = 0.1;

interface ZoomProviderProps {
  zoomLevel?: number;
  children?: React.ReactNode;
}

const ZoomProvider: React.FC<ZoomProviderProps> = ({
  zoomLevel: initialZoomLevel = 1,
  children,
}) => {
  const [zoomLevel, setZoomLevel] = useState<number>(initialZoomLevel);

  useEffect(() => {
    if (zoomLevel === 1) {
      document.documentElement.style.fontSize = '';
    } else {
      document.documentElement.style.fontSize = `calc(16px * ${zoomLevel})`;
    }
  }, [zoomLevel]);

  const zoomOut = useCallback(
    () => setZoomLevel(prevZoomLevel => Math.max(zoomStep, round(prevZoomLevel - zoomStep, 1))),
    []
  );

  const zoomIn = useCallback(
    () => setZoomLevel(prevZoomLevel => round(prevZoomLevel + zoomStep, 1)),
    []
  );

  const value = useMemo(
    () => ({
      zoomLevel,
      zoomIn,
      zoomOut,
    }),
    [zoomLevel, zoomOut, zoomIn]
  );

  return <ZoomContext.Provider value={value}>{children}</ZoomContext.Provider>;
};

export default ZoomProvider;
