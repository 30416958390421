import React from 'react';

interface IcCheck48pxProps {
  className?: string;
  title?: string;
}

const IcCheck48px: React.FC<IcCheck48pxProps> = ({
  className = 'icon-32',
  title = 'ic check 48px',
}) => {
  return (
    <svg
      className={className}
      height="32"
      width="32"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill="#2de415">
        <path d="M18 32.34L9.66 24l-2.83 2.83L18 38l24-24-2.83-2.83z" />
      </g>
    </svg>
  );
};

export default IcCheck48px;
