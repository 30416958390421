import apiHooks from '../api/apiHooks';
import { UseDisplayItemsResponse } from '../models/useDisplayItemsResponse';
import displayItemConverters from '../pages/TaskProfileReport/hooks/displayItemConverters';

export default function useCompanyEvaluators(
  companyId: number,
  searchText: string
): UseDisplayItemsResponse {
  const {
    data = [],
    isLoading,
    isSuccess,
  } = apiHooks.useCompaniesEvaluators(companyId, {
    keepPreviousData: true,
    select: evaluators => {
      let displayItems = displayItemConverters.convertCompanyEvaluatorsResponse(evaluators);

      if (searchText !== '') {
        displayItems = displayItems.filter(a => a.display.includes(searchText));
      }
      return displayItems;
    },
  });
  return { data, isLoading, isSuccess };
}
