import { unionWith } from 'lodash';
import React, { useState } from 'react';
import { UserAccessResponse } from '../../../api/responses/userAccessResponse';
import CustomViewAccessType from '../../../models/customViewAccessType';
import { DisplayItemWithCustomViewAccessType } from '../../../models/displayItemWithCustomViewAccessType';
import { UseDisplayItemsResponse } from '../../../models/useDisplayItemsResponse';
import ShareTabMultiPartSelect from './ShareTabMultipartSelect';

export interface ShareTabMultiPartSelectContainerProps {
  companyId: number;
  useData: (
    companyId: number,
    text: string
  ) => UseDisplayItemsResponse<DisplayItemWithCustomViewAccessType>;
  onChange: (selectedIds: UserAccessResponse[]) => void;
  selectedUsers: UserAccessResponse[];
  emptyListText: string;
}

const ShareTabMultiPartSelectContainer: React.FC<ShareTabMultiPartSelectContainerProps> = ({
  companyId,
  useData,
  onChange,
  selectedUsers,
  emptyListText,
}) => {
  const [searchText, setSearchText] = useState<string>('');
  const { data, isLoading }: UseDisplayItemsResponse<DisplayItemWithCustomViewAccessType> = useData(
    companyId,
    searchText
  );

  function handleSelectedItem(event: UserAccessResponse) {
    if (
      selectedUsers.map(userAccessResponse => userAccessResponse.userId).indexOf(event.userId) > -1
    ) {
      onChange(selectedUsers.filter(selectedId => selectedId.userId !== event.userId));
    } else {
      onChange(selectedUsers.concat(event));
    }
  }

  function handleOnClear() {
    const owner: UserAccessResponse | undefined = selectedUsers.find(
      user => user.customViewAccessType === CustomViewAccessType.Owner
    );

    if (owner !== undefined) {
      onChange([owner]);
    } else {
      onChange([]);
    }
  }

  function handleOnSelectAll() {
    const allUsers = data.map(value => ({
      userId: value.itemId,
      customViewAccessType: value.customViewAccessType,
    }));
    const union = unionWith(
      selectedUsers,
      allUsers,
      (item1, item2) => item1.userId === item2.userId
    );
    onChange(union);
  }

  function handleAccessChange(displayItem: DisplayItemWithCustomViewAccessType) {
    const resultArray = selectedUsers.map(value => {
      if (value.userId === displayItem.itemId) {
        return {
          userId: displayItem.itemId,
          customViewAccessType: displayItem.customViewAccessType,
        };
      }

      return value;
    });

    onChange(resultArray);
  }

  return (
    <ShareTabMultiPartSelect
      items={data}
      onSearch={setSearchText}
      onChange={item =>
        handleSelectedItem({
          userId: item.itemId,
          customViewAccessType: CustomViewAccessType.ViewOnly,
        })
      }
      selectedUsers={selectedUsers}
      emptyListText={emptyListText}
      isLoading={isLoading}
      onClear={handleOnClear}
      onSelectAll={handleOnSelectAll}
      onAccessChange={handleAccessChange}
    />
  );
};

export default ShareTabMultiPartSelectContainer;
