import React, { FC, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { CompanyUserJobResponse } from '../../../api/responses/companyUserJobResponse';
import { CompletionRoleResponse } from '../../../api/responses/completionRoleResponse';
import { ComponentResponse } from '../../../api/responses/componentResponse';
import { TaskResponse } from '../../../api/responses/taskResponse';
import { TrainingResponse } from '../../../api/responses/trainingResponse';
import messages from '../../../constants/messages';
import useMediaQuery, { MediaQuery } from '../../../hooks/useMediaQuery';
import { Values } from '../../../types/values';
import Dialog from '../../Dialog/Dialog';
import Tab from '../../Layout/Tab/Tab';
import TabPanel from '../../Layout/TabPanel/TabPanel';
import Tabs from '../../Layout/Tabs/Tabs';
import List from '../../List/List';
import TaskProfileDetailListJob from '../DetailList/Job/TaskProfileDetailListJob';
import TaskProfileDetailListRole from '../DetailList/Role/TaskProfileDetailListRole';
import TaskProfileDetailListTask from '../DetailList/Task/TaskProfileDetailListTask';
import TaskProfileDetailListTraining from '../DetailList/Training/TaskProfileDetailListTraining';
import TaskProfileDetailWePeListItem from '../DetailList/WePe/TaskProfileDetailWePeListItem';
import {
  TaskProfileDetailDisplayOptions,
  TaskProfileDetailFeatures,
} from '../ReportDrawer/taskProfileDetailDisplayOptions';
import './TaskProfileDetailModal.scss';
import Skeleton from '../../Skeleton/Skeleton';
import JobAssocMethod from '../../../models/JobAssocMethod';
import commonText from '../../../constants/commonText';

export const TabNames = {
  IncompleteTasks: { id: 'IncompleteTasks', name: 'Incomplete Tasks' },
  CompleteTasks: { id: 'CompleteTasks', name: 'Complete Tasks' },
  Roles: { id: 'Roles', name: 'Roles' },
  Jobs: { id: 'Jobs', name: 'Jobs' },
  IncompleteWePe: { id: 'IncompleteWePe', name: 'Incomplete WE/PE' },
  CompleteWePe: { id: 'CompleteWePe', name: 'Complete WE/PE' },
  IncompleteTrainings: {
    id: TaskProfileDetailDisplayOptions.incompleteTrainings.id,
    name: TaskProfileDetailDisplayOptions.incompleteTrainings.label,
  },
  CompleteTrainings: {
    id: TaskProfileDetailDisplayOptions.completeTrainings.id,
    name: TaskProfileDetailDisplayOptions.completeTrainings.label,
  },
} as const;

type TabNameId = Values<typeof TabNames>['id'];

export interface TaskProfileDetailModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  fullName: string;
  userName: string;
  incompleteTasks: TaskResponse[];
  completeTasks: TaskResponse[];
  roles: CompletionRoleResponse[];
  incompleteWePes: ComponentResponse[];
  completeWePes: ComponentResponse[];
  incompleteTrainings: TrainingResponse[];
  completeTrainings: TrainingResponse[];
  jobs: CompanyUserJobResponse[];
  features: TaskProfileDetailFeatures;
  isFetching?: boolean;
  jobAssocMethod?: JobAssocMethod;
}

const TaskProfileDetailModal: FC<TaskProfileDetailModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  fullName,
  userName,
  incompleteTasks,
  completeTasks,
  roles,
  incompleteWePes,
  completeWePes,
  incompleteTrainings,
  completeTrainings,
  jobs,
  features,
  isFetching = false,
  jobAssocMethod = JobAssocMethod.Jobs,
}) => {
  const [activeTab, setActiveTab] = useState<TabNameId>(TabNames.Roles.id);
  const isLargeScreen = useMediaQuery(MediaQuery.lg);

  const onHide = () => setIsModalOpen(false);

  const jobsTabHeading =
    jobAssocMethod === JobAssocMethod.JobsRespectMappingsAndTaskProfiling
      ? commonText.jobsRespectMappingsAndTaskProfiling
      : jobAssocMethod === JobAssocMethod.JobsRespectMappingsOnly
      ? commonText.jobsRespectMappingsOnly
      : // Show no heading if the Job association method is the legacy `JobAssocMethod.Jobs` method.
        '';

  return (
    <Dialog
      className="task-profile-detail"
      show={isModalOpen}
      size="lg"
      onHide={onHide}
      headerProps={{
        closeButton: true,
        onHide,
      }}
      scrollable
      header={
        <Card.Header className="px-3 py-2 bg-white border-bottom-0 pull-left text-start">
          <Card.Title className="mb-1" as="h4">
            {fullName}
          </Card.Title>
          <Card.Subtitle className="text-muted">{userName}</Card.Subtitle>
        </Card.Header>
      }
      body={
        <>
          <Tabs
            isVertical={isLargeScreen}
            activeKey={activeTab}
            tabs={
              <>
                {features.roles && (
                  <Tab
                    setActiveTab={setActiveTab}
                    activeTabId={activeTab}
                    tabId={TabNames.Roles.id}
                    panelId={`${TabNames.Roles.id}-panel`}
                  >
                    {TabNames.Roles.name}
                  </Tab>
                )}
                {features.jobs && (
                  <Tab
                    setActiveTab={setActiveTab}
                    activeTabId={activeTab}
                    tabId={TabNames.Jobs.id}
                    panelId={`${TabNames.Jobs.id}-panel`}
                  >
                    {TabNames.Jobs.name}
                  </Tab>
                )}
                {features.incompleteTasks && (
                  <Tab
                    setActiveTab={setActiveTab}
                    activeTabId={activeTab}
                    tabId={TabNames.IncompleteTasks.id}
                    panelId={`${TabNames.IncompleteTasks.id}-panel`}
                  >
                    {TabNames.IncompleteTasks.name}
                  </Tab>
                )}
                {features.completeTasks && (
                  <Tab
                    setActiveTab={setActiveTab}
                    activeTabId={activeTab}
                    tabId={TabNames.CompleteTasks.id}
                    panelId={`${TabNames.CompleteTasks.id}-panel`}
                  >
                    {TabNames.CompleteTasks.name}
                  </Tab>
                )}
                {features.incompleteWePe && (
                  <Tab
                    setActiveTab={setActiveTab}
                    activeTabId={activeTab}
                    tabId={TabNames.IncompleteWePe.id}
                    panelId={`${TabNames.IncompleteWePe.id}-panel`}
                  >
                    {TabNames.IncompleteWePe.name}
                  </Tab>
                )}
                {features.completeWePe && (
                  <Tab
                    setActiveTab={setActiveTab}
                    activeTabId={activeTab}
                    tabId={TabNames.CompleteWePe.id}
                    panelId={`${TabNames.CompleteWePe.id}-panel`}
                  >
                    {TabNames.CompleteWePe.name}
                  </Tab>
                )}
                {features.incompleteTrainings && (
                  <Tab
                    setActiveTab={setActiveTab}
                    activeTabId={activeTab}
                    tabId={TabNames.IncompleteTrainings.id}
                    panelId={`${TabNames.IncompleteTrainings.id}-panel`}
                  >
                    {TabNames.IncompleteTrainings.name}
                  </Tab>
                )}
                {features.completeTrainings && (
                  <Tab
                    setActiveTab={setActiveTab}
                    activeTabId={activeTab}
                    tabId={TabNames.CompleteTrainings.id}
                    panelId={`${TabNames.CompleteTrainings.id}-panel`}
                  >
                    {TabNames.CompleteTrainings.name}
                  </Tab>
                )}
              </>
            }
            tabPanes={
              <>
                {features.roles && (
                  <TabPanel tabId={TabNames.Roles.id} isActiveTab={activeTab === TabNames.Roles.id}>
                    <List
                      data={roles}
                      render={role => (
                        <TaskProfileDetailListRole
                          key={role.roleId ?? 0}
                          id={role.roleId ?? 0}
                          name={role.roleName}
                          isComplete={role.isComplete}
                          hasSeriesAccess={role.hasSeriesAccess}
                        />
                      )}
                      emptyStateMessage={messages.noRoles}
                    />
                  </TabPanel>
                )}
                {features.jobs && (
                  <TabPanel tabId={TabNames.Jobs.id} isActiveTab={activeTab === TabNames.Jobs.id}>
                    <>
                      <h6 aria-level={2} className="tab-heading">
                        {jobsTabHeading}
                      </h6>
                      <section>
                        {isFetching ? (
                          <>
                            <Skeleton style={{ height: '42px' }} className="py-1 mt-3 pe-3" />
                            <Skeleton style={{ height: '42px' }} className="py-1 mt-3 pe-3" />
                            <Skeleton style={{ height: '42px' }} className="py-1 mt-3 pe-3" />
                          </>
                        ) : (
                          <List
                            data={jobs}
                            render={job => (
                              <TaskProfileDetailListJob
                                key={job.id}
                                id={job.id}
                                name={job.title}
                                isComplete={job.isComplete}
                                tasks={job.tasks}
                              />
                            )}
                            emptyStateMessage={messages.noJobs}
                          />
                        )}
                      </section>
                    </>
                  </TabPanel>
                )}
                {features.incompleteTasks && (
                  <TabPanel
                    tabId={TabNames.IncompleteTasks.id}
                    isActiveTab={activeTab === TabNames.IncompleteTasks.id}
                  >
                    <List
                      data={incompleteTasks}
                      render={task => (
                        <TaskProfileDetailListTask
                          key={task.taskId}
                          id={task.taskId}
                          name={task.taskName}
                          isComplete={false}
                          components={task.components}
                          associatedTasks={task.associatedTasks}
                          hasSeriesAccess={task.hasSeriesAccess}
                        />
                      )}
                      emptyStateMessage={messages.noIncompleteTasks}
                    />
                  </TabPanel>
                )}
                {features.completeTasks && (
                  <TabPanel
                    tabId={TabNames.CompleteTasks.id}
                    isActiveTab={activeTab === TabNames.CompleteTasks.id}
                  >
                    <List
                      data={completeTasks}
                      render={task => (
                        <TaskProfileDetailListTask
                          key={task.taskId}
                          id={task.taskId}
                          name={task.taskName}
                          isComplete
                          components={task.components}
                          hasSeriesAccess={task.hasSeriesAccess}
                        />
                      )}
                      emptyStateMessage={messages.noCompleteTasks}
                    />
                  </TabPanel>
                )}
                {features.incompleteWePe && (
                  <TabPanel
                    tabId={TabNames.IncompleteWePe.id}
                    isActiveTab={activeTab === TabNames.IncompleteWePe.id}
                  >
                    <List
                      data={incompleteWePes}
                      render={component => (
                        <TaskProfileDetailWePeListItem
                          key={component.id}
                          name={component.name}
                          requiredStatusText={component.requiredStatusText ?? ''}
                          isComplete={false}
                          listItemId={component.id}
                          hasSeriesAccess={component.hasSeriesAccess}
                        />
                      )}
                      emptyStateMessage={messages.noIncompleteWePe}
                    />
                  </TabPanel>
                )}
                {features.completeWePe && (
                  <TabPanel
                    tabId={TabNames.CompleteWePe.id}
                    isActiveTab={activeTab === TabNames.CompleteWePe.id}
                  >
                    <List
                      data={completeWePes}
                      render={component => (
                        <TaskProfileDetailWePeListItem
                          key={component.id}
                          name={component.name}
                          requiredStatusText={component.requiredStatusText ?? ''}
                          isComplete
                          listItemId={component.id}
                          hasSeriesAccess={component.hasSeriesAccess}
                        />
                      )}
                      emptyStateMessage={messages.noCompleteWePe}
                    />
                  </TabPanel>
                )}
                {features.incompleteTrainings && (
                  <TabPanel
                    tabId={TabNames.IncompleteTrainings.id}
                    isActiveTab={activeTab === TabNames.IncompleteTrainings.id}
                  >
                    <List
                      data={incompleteTrainings}
                      render={training => (
                        <TaskProfileDetailListTraining
                          key={training.id}
                          id={training.id}
                          name={training.name}
                          isComplete={false}
                          hasSeriesAccess={training.hasSeriesAccess}
                        />
                      )}
                      emptyStateMessage={messages.noIncompleteTrainings}
                    />
                  </TabPanel>
                )}
                {features.completeTrainings && (
                  <TabPanel
                    tabId={TabNames.CompleteTrainings.id}
                    isActiveTab={activeTab === TabNames.CompleteTrainings.id}
                  >
                    <List
                      data={completeTrainings}
                      render={training => (
                        <TaskProfileDetailListTraining
                          key={training.id}
                          id={training.id}
                          name={training.name}
                          isComplete
                          hasSeriesAccess={training.hasSeriesAccess}
                        />
                      )}
                      emptyStateMessage={messages.noCompleteTrainings}
                    />
                  </TabPanel>
                )}
              </>
            }
          />
        </>
      }
      footer={null}
    />
  );
};

export default TaskProfileDetailModal;
