import React from 'react';
import commonText from '../../constants/commonText';
import testIds from '../../constants/testIds';
import CurrentIntegrationsResponse from '../../models/CurrentIntegrationsResponse';
import Dropdown from '../Dropdown/Dropdown';

export interface IntegrationPickerDropdownProps {
  currentIntegrations: CurrentIntegrationsResponse[];
  isCurrentIntegrationsLoading: boolean;
  onIntegrationSelection: (currentIntegration: CurrentIntegrationsResponse) => void;
  selectedIntegration: CurrentIntegrationsResponse;
  dropdownButtonClassName: string;
  hideLabel?: boolean;
}

const IntegrationPickerDropdown: React.FC<IntegrationPickerDropdownProps> = ({
  currentIntegrations,
  isCurrentIntegrationsLoading,
  onIntegrationSelection,
  selectedIntegration,
  dropdownButtonClassName,
  hideLabel = false,
}) => {
  return (
    <>
      {hideLabel && <b style={{ fontSize: '12px' }}>Integration</b>}
      <Dropdown
        dropdownTestId={testIds.integrationPickerDropdown()}
        emptyListLabel={commonText.selectAnIntegration}
        items={currentIntegrations}
        itemKey={item => item.Id}
        itemLabel={item => {
          let suffix = '';
          if (!item.IsEnabled) suffix = ` - ${commonText.disabled}`;
          return item.IntegrationName + suffix;
        }}
        onItemSelect={onIntegrationSelection}
        selectedItem={selectedIntegration.Id}
        title={`${selectedIntegration.IntegrationName}${
          selectedIntegration.IsEnabled ? '' : ` - ${commonText.disabled}`
        }`}
        isLoading={isCurrentIntegrationsLoading}
        variant="light"
        toogleClassName={dropdownButtonClassName}
      />
    </>
  );
};

export default IntegrationPickerDropdown;
