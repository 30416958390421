import React from 'react';
import testIds from '../../../../constants/testIds';
import { CompletionStatus } from '../../../CompletionStatus/CompletionStatus';

interface TaskProfileDetailListJobItemProps {
  id: number;
  name: string;
  isComplete: boolean;
}

const TaskProfileDetailListJobItem: React.FC<TaskProfileDetailListJobItemProps> = ({
  id,
  name,
  isComplete,
}) => (
  <div
    className="d-inline-flex justify-content-between align-items-center border-0 w-100 text-start pb-0 text-dark fw-bold"
    data-testid={testIds.taskProfileDetailListJob(id)}
  >
    <span className="px-2">{name}</span>
    <span className="flex-shrink-0 text-end" style={{ width: '4rem' }}>
      <CompletionStatus isCompleted={isComplete} />
    </span>
  </div>
);

export default TaskProfileDetailListJobItem;
