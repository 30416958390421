import { createContext } from 'react';
import { AuthSession } from '../models/authSession';

export interface AuthContextParams {
  signInWithToken(userId: number, token: string): Promise<AuthSession | undefined>;
  isUserAuthenticated(): Promise<AuthSession | undefined>;
}

const AuthContext = createContext<AuthContextParams>({} as AuthContextParams);

export default AuthContext;
