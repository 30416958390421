import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { CompanySeriesTaskRequest } from '../../api/requests/companySeriesTaskRequest';
import { TaskByJobMatrixReadListRequest } from '../../api/requests/taskByJobMatrixReadListRequest';
import { CompanyResponse } from '../../api/responses/companyResponse';
import { CompanySeriesResponse } from '../../api/responses/companySeriesResponse';
import { CompanySeriesTaskResponse } from '../../api/responses/companySeriesTaskResponse';
import { TaskGetListResponse } from '../../api/responses/taskGetListResponse';
import { UserInformation } from '../../api/responses/userInformation';
import ConfirmDialog, { ConfirmDialogProps } from '../../components/ConfirmDialog/ConfirmDialog';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import TopNavbar from '../../components/Layout/TopNavBar/TopNavBar';
import messages from '../../constants/messages';
import JobMatrix from '../../models/JobMatrix';
import Series from '../../models/Series';
import TaskModel from '../../models/TaskModel';
import { AvailablePolicies } from '../../policyService/policyEnforcer/policyEnforcer';
import ExpressionBuilder from '../../utils/expression-builder/expressionBuilder';
import EquivalenciesMapper from '../../components/JobsEdit/EquivalenciesMapper';
import { GeneralMessageType } from '../../models/GeneralMessage';
import { JobMatrixSaveMutation } from '../../hooks/useJobMatrixSave';
import JobsGeneralInfo from '../../components/JobsEdit/JobsGeneralInfo';

export interface JobsEditProps {
  isCompanyLoading: boolean;
  company: CompanyResponse | undefined;
  user: UserInformation | undefined;
  isUserLoading: boolean;
  policies: Pick<
    AvailablePolicies,
    | 'canViewTaskProfile'
    | 'canViewCustomView'
    | 'canViewActivities'
    | 'canViewIntegrationHub'
    | 'canViewLearningHistory'
  >;
  jobPageTitle: string;
  jobMatrixMessage: GeneralMessageType | null;
  jobMatrix: JobMatrix;
  formIsDisabled: boolean;
  useCustomJobsIsEnabled: boolean;
  jobMatrixIsLoading: boolean;
  isEditing: boolean;
  companySeriesData: CompanySeriesResponse | undefined;
  isCompanySeriesDataLoading: boolean;
  companySeriesTasksRequest: CompanySeriesTaskRequest;
  companySeriesTaskResponseData: CompanySeriesTaskResponse | undefined;
  isCompanySeriesError: boolean;
  isCompanySeriesTaskResponseError: boolean;
  exprBuilder: ExpressionBuilder;
  saveMutation: JobMatrixSaveMutation;
  jobSaveDialogIsOpen: boolean;
  jobSaveDialogProps: ConfirmDialogProps;
  setCompanySeriesTasksRequest: React.Dispatch<React.SetStateAction<CompanySeriesTaskRequest>>;
  setJobMatrix: React.Dispatch<React.SetStateAction<JobMatrix>>;
  setJobSaveDialogIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  useTaskByJobMatrixReadList: (
    requestParams: TaskByJobMatrixReadListRequest,
    options?: UseQueryOptions<
      TaskGetListResponse,
      Error,
      TaskGetListResponse,
      (string | TaskByJobMatrixReadListRequest)[]
    >
  ) => Partial<UseQueryResult<TaskGetListResponse | undefined, Error>>;
}

const JobsEdit: React.FC<JobsEditProps> = ({
  company,
  isCompanyLoading,
  isUserLoading,
  policies,
  user,
  jobMatrixMessage,
  jobPageTitle,
  jobMatrix,
  formIsDisabled,
  useCustomJobsIsEnabled,
  jobMatrixIsLoading,
  isEditing,
  companySeriesData,
  isCompanySeriesDataLoading,
  companySeriesTasksRequest,
  companySeriesTaskResponseData,
  isCompanySeriesError,
  isCompanySeriesTaskResponseError,
  exprBuilder,
  saveMutation,
  jobSaveDialogIsOpen,
  jobSaveDialogProps,
  setJobMatrix,
  setCompanySeriesTasksRequest,
  setJobSaveDialogIsOpen,
  useTaskByJobMatrixReadList,
}: JobsEditProps) => {
  return (
    <MainLayout
      isCompanyLoading={isCompanyLoading}
      companyImage={company?.logo}
      companyName={company?.name}
      policies={policies}
    >
      <TopNavbar reportName={jobPageTitle} user={user} isUserLoading={isUserLoading} />
      <Alert variant={jobMatrixMessage?.severity} hidden={!jobMatrixMessage?.message}>
        {jobMatrixMessage?.message}
      </Alert>
      <JobsGeneralInfo
        jobMatrix={jobMatrix}
        setJobMatrix={setJobMatrix}
        useCustomJobsIsEnabled={useCustomJobsIsEnabled}
        isDisabled={formIsDisabled}
      />
      <EquivalenciesMapper
        company={company}
        isEditing={isEditing}
        jobMatrix={jobMatrix}
        jobMatrixIsLoading={jobMatrixIsLoading}
        setJobMatrix={setJobMatrix}
        availableSeriesList={companySeriesData?.series ?? []}
        seriesListIsLoading={isCompanySeriesDataLoading}
        setIncludeHistoricalTasks={(includeHistorical: boolean) =>
          setCompanySeriesTasksRequest(prevState => ({
            ...prevState,
            includeHistorical,
          }))
        }
        includeHistoricalTasks={companySeriesTasksRequest.includeHistorical}
        setSelectedSeries={(series: Series) =>
          setCompanySeriesTasksRequest(prevState => ({
            ...prevState,
            seriesId: series.id,
          }))
        }
        selectedSeriesId={companySeriesTasksRequest.seriesId}
        taskDisplayItems={
          companySeriesTaskResponseData?.tasks.map((taskModel: TaskModel) => ({
            // Fill in `DisplayItem` interface properties.
            itemId: taskModel.id,
            display: taskModel.name,
            // Fill in `TaskModel` interface properties.
            ...taskModel,
          })) ?? []
        }
        errorNotification={
          // eslint-disable-next-line no-nested-ternary
          isCompanySeriesError
            ? messages.getSeriesListError
            : isCompanySeriesTaskResponseError
            ? messages.getTaskListError
            : ''
        }
        expressionBuilder={exprBuilder}
        saveMutation={saveMutation}
        isDisabled={formIsDisabled}
        useTaskByJobMatrixReadList={useTaskByJobMatrixReadList}
      />
      <ConfirmDialog
        isDialogOpen={jobSaveDialogIsOpen}
        setIsDialogOpen={setJobSaveDialogIsOpen}
        headerText={jobSaveDialogProps.headerText}
        bodyText={jobSaveDialogProps.bodyText}
        onClose={jobSaveDialogProps.onClose}
        confirmText={jobSaveDialogProps.confirmText}
        cancelText={jobSaveDialogProps.cancelText}
      />
    </MainLayout>
  );
};

export default JobsEdit;
