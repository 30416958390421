import commonText from '../../constants/commonText';
import CustomViewAccessType from '../../models/customViewAccessType';

function convert(customViewAccessType: CustomViewAccessType): string {
  switch (customViewAccessType) {
    case CustomViewAccessType.ViewOnly:
      return commonText.viewOnly;
    case CustomViewAccessType.Editor:
      return commonText.editor;
    case CustomViewAccessType.Owner:
      return commonText.owner;
    default:
      return '';
  }
}
export default { convert };
