import React from 'react';
import Form from 'react-bootstrap/Form';
import { Color } from '../../types/color';

export interface CheckboxProps {
  label: string;
  color?: Color;
  inputStyle?: React.CSSProperties;
  className?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  id: string;
  checked?: boolean;
  disabled?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  color = 'primary',
  id,
  onChange = () => {},
  checked = false,
  inputStyle,
  className = '',
  disabled = false,
}) => {
  return (
    <div className="d-flex">
      <div className="d-flex justify-content-center" style={inputStyle}>
        <Form.Check type="checkbox" id={id} className={`custom-switch-${color} ${className}`}>
          <Form.Check.Input
            type="checkbox"
            onChange={onChange}
            disabled={disabled}
            checked={checked}
          />
          <Form.Check.Label>{label}</Form.Check.Label>
        </Form.Check>
      </div>
    </div>
  );
};

export default Checkbox;
