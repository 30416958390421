import FeatureFlags, { FeatureFlag } from '../../feature-flags/featureFlags';
import { UsersPolicies } from '../../models/usersPolicies';

export interface AvailablePolicies {
  canCreateOrUpdateTaskProfile: boolean;
  canViewTaskProfileDrawer: boolean;
  canViewTaskProfile: boolean;
  canDeleteTaskProfile: boolean;
  canViewCustomView: boolean;
  canCreateCustomView: boolean;
  canViewCustomViewConnections: boolean;
  canAccessCustomViewReport: boolean;
  canCreateJob: boolean;
  canUpdateJob: boolean;
  canViewActivities: boolean;
  canViewIntegrationHub: boolean;
  canViewLearningHistory: boolean;
  canViewNewJobReport: boolean;
}

function policyEnforcer(
  usersPolicies: UsersPolicies,
  isFeatureFlagEnabled: (flag: FeatureFlag, defaultValue?: boolean | undefined) => boolean,
  customViewId?: number
): AvailablePolicies {
  function canCreateOrUpdateReport(): boolean {
    return customViewId !== undefined
      ? usersPolicies.usersCustomViewAccessPolicies.HasOwnerAccess() ||
          usersPolicies.usersCustomViewAccessPolicies.HasEditorAccessToCustomView()
      : usersPolicies.userTypePolicies.canCreateCustomView ||
          usersPolicies.userTypePolicies.canUpdateCustomView;
  }

  function canDeleteTaskProfilingReport(): boolean {
    return (
      customViewId !== undefined && usersPolicies.usersCustomViewAccessPolicies.HasOwnerAccess()
    );
  }

  function canViewTaskProfilingDrawer(): boolean {
    return customViewId !== undefined
      ? usersPolicies.usersCustomViewAccessPolicies.HasEditorAccessToCustomView() ||
          usersPolicies.usersCustomViewAccessPolicies.HasOwnerAccess()
      : usersPolicies.userTypePolicies.canViewTaskProfiling;
  }

  function canViewActivitiesReport(): boolean {
    return isFeatureFlagEnabled(FeatureFlags.Has_Activity_Report_Access);
  }

  function canAddOrUpdateJobs(): boolean {
    // `canCreateJob` and `canUpdateJob` are sharing a function until we know
    //	whether both permissions will be needed.
    return (
      (usersPolicies.userTypePolicies.canCreateJob ||
        usersPolicies.userTypePolicies.canUpdateJob) &&
      isFeatureFlagEnabled(FeatureFlags.Equivalencies_Jobs_New_Mapping)
    );
  }

  function canViewNewJobReport(): boolean {
    return isFeatureFlagEnabled(FeatureFlags.Equivalencies_Jobs_New_Mapping);
  }

  function canViewIntegrationHub(): boolean {
    return (
      usersPolicies.userTypePolicies.canViewIntegrationHub &&
      isFeatureFlagEnabled(FeatureFlags.CanViewIntegrationHub)
    );
  }

  function canViewLearningHistoryReport(): boolean {
    return isFeatureFlagEnabled(FeatureFlags.Has_Learning_History_Report_Access);
  }

  return {
    canCreateOrUpdateTaskProfile: canCreateOrUpdateReport(),
    canViewTaskProfileDrawer: canViewTaskProfilingDrawer(),
    canViewTaskProfile: usersPolicies.userTypePolicies.canViewTaskProfiling,
    canDeleteTaskProfile: canDeleteTaskProfilingReport(),
    canViewCustomView: usersPolicies.userTypePolicies.canViewCustomView,
    canCreateCustomView: usersPolicies.userTypePolicies.canCreateCustomView,
    canViewCustomViewConnections: usersPolicies.userTypePolicies.canViewCustomViewConnections,
    canAccessCustomViewReport: usersPolicies.usersCustomViewAccessPolicies.HasAccess(),
    canCreateJob: canAddOrUpdateJobs(),
    canUpdateJob: canAddOrUpdateJobs(),
    canViewActivities: canViewActivitiesReport(),
    canViewIntegrationHub: canViewIntegrationHub(),
    canViewLearningHistory: canViewLearningHistoryReport(),
    canViewNewJobReport: canViewNewJobReport(),
  };
}

export default policyEnforcer;
