// organize-imports-ignore
// "Why did you render" import must come before all other imports in order for it to work correctly
import './wdyr';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient } from '@tanstack/react-query';
import App from './App';
import makeAppProvider from './AppProvider';
import './assets/styles/index.scss';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

(async () => {
  const AppProvider = await makeAppProvider();
  const container = document.getElementById('root');

  const root = createRoot(container!);
  root.render(
    <React.StrictMode>
      <AppProvider queryClient={queryClient}>
        <App />
      </AppProvider>
    </React.StrictMode>
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
