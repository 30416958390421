import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';

interface FeatureProviderProps {
  children?: React.ReactNode;
}

async function makeFeatureProvider(): Promise<React.FC<{ children: React.ReactNode }>> {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID ?? '',
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  });

  const FeatureProvider: React.FC<FeatureProviderProps> = ({ children }) => {
    return <LDProvider>{children}</LDProvider>;
  };

  return FeatureProvider;
}

export default makeFeatureProvider;
