import React from 'react';
import Skeleton from '../../../Skeleton/Skeleton';

const ProgressDoughnutChartSkeleton: React.FC = () => {
  return (
    <div className="position-relative h-100 w-100">
      <Skeleton className="d-block h-100 rounded-circle" />
      <div
        className="d-flex flex-column justify-content-center align-items-center position-absolute rounded-circle bg-white"
        style={{ inset: '.625rem' }}
      >
        <Skeleton className="mb-1" width="3rem" />
        <Skeleton width="3rem" />
      </div>
    </div>
  );
};

export default ProgressDoughnutChartSkeleton;
