import apiHooks from '../api/apiHooksLearningHistory';
import { UseDisplayItemsResponse } from '../models/useDisplayItemsResponse';

const useQualificationsReasons = (
  companyId: number,
  searchText: string
): UseDisplayItemsResponse => {
  const { data = [], isLoading } = apiHooks.useQualificationReasons(companyId, searchText);

  return { data, isLoading };
};

export default useQualificationsReasons;
