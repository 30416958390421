import React from 'react';
import BrokenImage from '../../assets/images/BrokenImage.svg';

export interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {}

const Image: React.FC<ImageProps> = ({ src, alt, ...rest }) => {
  return (
    <img
      src={src}
      onError={e => {
        e.currentTarget.onerror = null;
        e.currentTarget.src = BrokenImage;
        e.currentTarget.style.padding = '0.375rem';
      }}
      alt={alt}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
};

export default Image;
