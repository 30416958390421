import apiHooks from '../api/apiHooks';
import { UseDisplayItemsResponse } from '../models/useDisplayItemsResponse';
import displayItemConverters from '../pages/TaskProfileReport/hooks/displayItemConverters';

function useEmployeesAll(
  companyId: number,
  activeStatus: number,
  searchText: string
): UseDisplayItemsResponse {
  const { data = [], isLoading } = apiHooks.useCompaniesUsersAll(
    { companyId, searchText, activeStatus },
    {
      keepPreviousData: true,
      select: displayItemConverters.convertCompaniesUsersResponse,
    }
  );

  return { data, isLoading };
}

export default useEmployeesAll;
