import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import CustomViewAccessType from '../models/customViewAccessType';
import activitiesApi from './activitiesReportApi';
import api from './api';
import { CompaniesCompanySitesRequest } from './requests/companiesCompanySitesRequest';
import { CompaniesCostCentersRequest } from './requests/companiesCostCentersRequest';
import { CompaniesDepartmentsRequest } from './requests/companiesDepartmentsRequest';
import { CompaniesJobsRequest } from './requests/companiesJobsRequest';
import { CompaniesRolesRequest } from './requests/companiesRolesRequest';
import { CompaniesSupervisorsRequest } from './requests/companiesSupervisorsRequest';
import { CompaniesUsersAllRequest } from './requests/companiesUsersAllRequest';
import { CompaniesUsersJobsRequest } from './requests/companiesUsersJobsRequest';
import { CompaniesUsersRequest } from './requests/companiesUsersRequest';
import { CompaniesUsersRolesRequest } from './requests/companiesUsersRolesRequest';
import {
  CompaniesUsersStatisticsRequest,
  CompaniesUserStatisticsRequest,
} from './requests/companiesUsersStatisticsRequest';
import { CompaniesUsersTasksRequest } from './requests/companiesUsersTasksRequest';
import { CompaniesUsersTrainingsRequest } from './requests/companiesUsersTrainingsRequest';
import { CompanyQualificationRequest } from './requests/companyQualificationRequest';
import { CompanySeriesRequest } from './requests/companySeriesRequest';
import { CompanySeriesTaskRequest } from './requests/companySeriesTaskRequest';
import { CustomViewSaveRequest } from './requests/customViewSaveRequest';
import { DeleteCustomViewRequest } from './requests/deleteCustomViewRequest';
import { GetActivitiesReportRequest } from './requests/getActivitiesReportRequest';
import { GetCustomViewAccessUsersRequest } from './requests/getCustomViewAccessUsersRequest';
import { GetCustomViewConnectionsRequest } from './requests/getCustomViewConnectionRequest';
import { GetCustomViewRequest } from './requests/getCustomViewRequest';
import { GetMessageOfTheDayImageRequest } from './requests/getMessageOfTheDayImageRequest';
import { GetMessageOfTheDayImageUrlsRequest } from './requests/getMessageOfTheDayImageUrlsRequest';
import { GetUserPoliciesRequest } from './requests/getUserPoliciesRequest';
import { GetUsersCustomViewPoliciesRequest } from './requests/getUsersCustomViewPoliciesRequest';
import { PostCompanyImportSettingsRequestBody } from './requests/PostCompanyImportSettingsRequest';
import { PutMessageOfTheDayImageUrlsRequest } from './requests/putMessageOfTheDayImageUrlsRequest';
import RegenerateCompanySftpUserRequest from './requests/regenerateCompanySftpUserRequest';
import { UpdateCustomViewAccessRequest } from './requests/updateCustomViewAccessRequest';
import { UpdateCustomViewConnectionRequest } from './requests/updateCustomViewConnectionRequest';
import { UpdateCustomViewRequest } from './requests/updateCustomViewRequest';
import { UsersCustomViewsRequest } from './requests/usersCustomViewsRequest';
import { CompaniesCompanySiteResponse } from './responses/companiesCompanySiteResponse';
import { CompaniesCostCentersResponse } from './responses/companiesCostCentersResponse';
import { CompaniesDepartmentsResponse } from './responses/companiesDepartmentsResponse';
import { CompaniesJobsResponse } from './responses/companiesJobsResponse';
import { CompaniesRolesResponse } from './responses/companiesRolesResponse';
import { CompaniesSupervisorsResponse } from './responses/companiesSupervisorsResponse';
import { CompaniesUsersJobsResponse } from './responses/companiesUsersJobsResponse';
import { CompaniesUsersResponse } from './responses/companiesUsersResponse';
import { CompaniesUsersRolesResponse } from './responses/companiesUsersRolesResponse';
import { CompaniesUsersTasksResponse } from './responses/companiesUsersTasksResponse';
import { CompanyQualificationResponse } from './responses/companyQualificationResponse';
import { CompanyResponse } from './responses/companyResponse';
import { CompanySeriesResponse } from './responses/companySeriesResponse';
import { CompanySeriesTaskResponse } from './responses/companySeriesTaskResponse';
import {
  CreateCompanySftpUser,
  CreateCompanySftpUserResponse,
} from './responses/createCompanySftpUserResponse';
import { CustomViewAccessResponse } from './responses/customViewAccessResponse';
import { CustomViewConnectionsResponse } from './responses/customViewConnectionsResponse';
import { CustomViewReport } from './responses/customViewReport';
import { CustomViewsResponse } from './responses/customViewsResponse';
import { GetActivitiesReportResponse } from './responses/getActivitiesReportResponse';
import { ActivityReportAffidavitModel } from './responses/getActivityAffidavitInformationResponse';
import { CompanySftpUserResponse } from './responses/getCompanySftpUserResponse';
import { GetCustomViewAccessUsersResponse } from './responses/getCustomViewAccessUsersResponse';
import { GetMessageOfTheDayImageResponse } from './responses/getMessageOfTheDayImageResponse';
import { GetMessageOfTheDayImageUrlsResponse } from './responses/getMessageOfTheDayImageUrlsResponse';
import { GetUserPoliciesResponse } from './responses/getUserPoliciesResponse';
import { GetUsersCustomViewPoliciesResponse } from './responses/getUsersCustomViewPoliciesResponse';
import { PaginatedResponse } from './responses/paginatedResponse';
import { PutMessageOfTheDayImageUrlsResponse } from './responses/putMessageOfTheDayImageUrlsResponse';
import { UserComponentResponse } from './responses/userComponentResponse';
import { UserInformation } from './responses/userInformation';
import { UserIntegration } from './responses/userIntegration';
import { UserResponse } from './responses/userResponse';
import { UserStatisticsResponse } from './responses/userStatisticsResponse';
import { UserTrainingResponse } from './responses/userTrainingResponse';

export interface ApiResult<TData> {
  data?: TData;
  isError: boolean;
  isFetched?: boolean;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
}

export interface ApiRequestOptions<TResponseData = unknown, TData = TResponseData> {
  enabled?: boolean;
  keepPreviousData?: boolean;
  cacheTime?: number;
  staleTime?: number;
  refetchInterval?: number | false;
  refetchOnWindowFocus?: boolean;
  select?: (data: TResponseData) => TData;
}

export interface ApiSaveResult<TRequestData, TResponseData> {
  mutate: (data: TRequestData) => void;
  mutateAsync: (data: TRequestData) => Promise<TResponseData>;
  data?: TResponseData;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
}

export interface ApiSaveRequestOptions<TData = unknown, TVariables = unknown, TContext = unknown> {
  onSuccess?: (
    data: TData,
    variables: TVariables,
    context: TContext | undefined
  ) => Promise<unknown> | void;
}

function useCompanyDetails<TData = CompanyResponse>(
  companyId: number,
  options?: ApiRequestOptions<CompanyResponse, TData>
): ApiResult<TData> {
  return useQuery(
    ['getCompanyDetails', companyId],
    () => api.getCompanyDetails(companyId),
    options
  );
}

function useCompanyQualifications<TData = CompanyQualificationResponse>(
  requestParams: CompanyQualificationRequest,
  options?: ApiRequestOptions<CompanyQualificationResponse, TData>
): ApiResult<TData> {
  return useQuery(
    ['getCompanyQualifications', requestParams],
    () => api.getCompanyQualifications(requestParams),
    options
  );
}

function useCompanySeries<TData = CompanySeriesResponse>(
  requestParams: CompanySeriesRequest,
  options?: ApiRequestOptions<CompanySeriesResponse, TData>
): ApiResult<TData> {
  return useQuery(
    ['getCompanySeries', requestParams],
    () => api.getCompanySeries(requestParams),
    options
  );
}

function useCompanySeriesTasks<TData = CompanySeriesTaskResponse>(
  requestParams: CompanySeriesTaskRequest,
  options?: ApiRequestOptions<CompanySeriesTaskResponse, TData>
): ApiResult<TData> {
  return useQuery(
    ['getCompanySeriesTasks', requestParams],
    () => {
      let response: Promise<CompanySeriesTaskResponse> = Promise.resolve({ tasks: [] });
      if (requestParams.seriesId !== 0) {
        response = api.getCompanySeriesTasks(requestParams);
      }
      return response;
    },
    options
  );
}

function useCompaniesUserStatistics<TData = UserStatisticsResponse>(
  requestParams: CompaniesUserStatisticsRequest,
  options?: ApiRequestOptions<UserStatisticsResponse, TData>
): ApiResult<TData> {
  return useQuery(
    ['getCompaniesUserStatistics', requestParams],
    () => api.getCompaniesUserStatistics(requestParams),
    options
  );
}

function useCompaniesUsersStatistics<TData = UserStatisticsResponse>(
  requestParams: CompaniesUsersStatisticsRequest,
  options?: ApiRequestOptions<UserStatisticsResponse, TData>
): ApiResult<TData> {
  return useQuery(
    ['getCompaniesUsersStatistics', requestParams],
    () => {
      return api.getCompaniesUserStatistics(requestParams);
    },
    options
  );
}

function useCompaniesRoles<TData = CompaniesRolesResponse>(
  requestParams: CompaniesRolesRequest,
  options?: ApiRequestOptions<CompaniesRolesResponse, TData>
): ApiResult<TData> {
  return useQuery(
    ['getCompaniesRoles', requestParams],
    () => api.getCompaniesRoles(requestParams),
    options
  );
}

function useCompaniesUsersRoles<TData = CompaniesUsersRolesResponse>(
  requestParams: CompaniesUsersRolesRequest,
  options?: ApiRequestOptions<CompaniesUsersRolesResponse, TData>
): ApiResult<TData> {
  return useQuery(
    ['getCompaniesUsersRoles', requestParams],
    () => api.getCompaniesUsersRoles(requestParams),
    options
  );
}

function useCompaniesUsersTasks<TData = CompaniesUsersTasksResponse>(
  requestParams: CompaniesUsersTasksRequest,
  options?: ApiRequestOptions<CompaniesUsersTasksResponse, TData>
): ApiResult<TData> {
  return useQuery(
    ['getCompaniesUsersTasks', requestParams],
    () => api.getCompaniesUsersTasks(requestParams),
    options
  );
}

function useCompaniesUsersComponents<TData = UserComponentResponse>(
  requestParams: CompaniesUsersTasksRequest,
  options?: ApiRequestOptions<UserComponentResponse, TData>
): ApiResult<TData> {
  return useQuery(
    ['getCompaniesUsersComponents', requestParams],
    () => api.getCompaniesUsersComponents(requestParams),
    options
  );
}

function useCompaniesUsersTrainings<TData = UserTrainingResponse>(
  requestParams: CompaniesUsersTrainingsRequest,
  options?: ApiRequestOptions<UserTrainingResponse, TData>
): ApiResult<TData> {
  return useQuery(
    ['getCompaniesUsersTrainings', requestParams],
    () => api.getCompaniesUsersTrainings(requestParams),
    options
  );
}

function useCompaniesUsersJobs<TData = CompaniesUsersJobsResponse>(
  requestParams: CompaniesUsersJobsRequest,
  options?: ApiRequestOptions<CompaniesUsersJobsResponse, TData>
): ApiResult<TData> {
  return useQuery(
    ['getCompaniesUsersJobs', requestParams],
    () => api.getCompaniesUsersJobs(requestParams),
    options
  );
}

function useCompaniesCompanySites<TData = CompaniesCompanySiteResponse>(
  requestParams: CompaniesCompanySitesRequest,
  options?: ApiRequestOptions<CompaniesCompanySiteResponse, TData>
): ApiResult<TData> {
  return useQuery(
    ['getCompaniesCompanySites', requestParams],
    () => api.getCompaniesCompanySites(requestParams),
    options
  );
}

function useCompaniesSupervisors<TData = CompaniesSupervisorsResponse>(
  requestParams: CompaniesSupervisorsRequest,
  options?: ApiRequestOptions<CompaniesSupervisorsResponse, TData>
): ApiResult<TData> {
  return useQuery(
    ['getCompaniesSupervisors', requestParams],
    () => api.getCompaniesSupervisors(requestParams),
    options
  );
}

function useCompaniesCostCenters<TData = CompaniesCostCentersResponse>(
  requestParams: CompaniesCostCentersRequest,
  options?: ApiRequestOptions<CompaniesCostCentersResponse, TData>
): ApiResult<TData> {
  return useQuery(
    ['getCompaniesCostCenters', requestParams],
    () => api.getCompaniesCostCenters(requestParams),
    options
  );
}

function useCompaniesDepartments<TData = CompaniesDepartmentsResponse>(
  requestParams: CompaniesDepartmentsRequest,
  options?: ApiRequestOptions<CompaniesDepartmentsResponse, TData>
): ApiResult<TData> {
  return useQuery(
    ['getCompaniesDepartments', requestParams],
    () => api.getCompaniesDepartments(requestParams),
    options
  );
}

function useCompaniesUsers<TData = CompaniesUsersResponse>(
  requestParams: CompaniesUsersRequest,
  options?: ApiRequestOptions<CompaniesUsersResponse, TData>
): ApiResult<TData> {
  return useQuery(
    ['getCompaniesUsers', requestParams],
    () => api.getCompaniesUsers(requestParams),
    options
  );
}

function useCompaniesUsersAll<TData = CompaniesUsersResponse>(
  requestParams: CompaniesUsersAllRequest,
  options?: ApiRequestOptions<CompaniesUsersResponse, TData>
): ApiResult<TData> {
  return useQuery(
    ['getCompaniesUsersAll', requestParams],
    () => api.getCompaniesUsersAll(requestParams),
    options
  );
}

function useCompaniesJobs<TData = CompaniesJobsResponse>(
  requestParams: CompaniesJobsRequest,
  options?: ApiRequestOptions<CompaniesJobsResponse, TData>
): ApiResult<TData> {
  return useQuery(
    ['getCompaniesJobs', requestParams],
    () => api.getCompaniesJobs(requestParams),
    options
  );
}

type CreateCustomViewRequest = CustomViewSaveRequest &
  Pick<UpdateCustomViewAccessRequest, 'customViewAccessRequests'> & { messageOfTheDayImage?: File };

function useCreateCustomView(
  options?: ApiSaveRequestOptions<CustomViewReport<string>, CreateCustomViewRequest>
): ApiSaveResult<CreateCustomViewRequest, CustomViewReport<string>> {
  const queryClient = useQueryClient();

  return useMutation(
    ['createCustomView'],
    async requestParams => {
      const customView = await api.createCustomView(requestParams);

      await api.updateCustomViewAccess({
        companyId: requestParams.companyId,
        customViewId: customView.id,
        customViewAccessRequests: requestParams.customViewAccessRequests,
      });

      if (requestParams.messageOfTheDayImage !== undefined) {
        const imageUpdate = await api.putMessageOfTheDayImageUrls({
          companyId: requestParams.companyId,
          customViewId: customView.id,
          messageOfTheDayImage: requestParams.messageOfTheDayImage,
        });

        const updatedRequestParams = {
          ...requestParams,
          customViewId: customView.id,
          data: {
            ...requestParams.data,
            reportData: {
              ...requestParams.data.reportData,
              messageOfTheDay: {
                ...requestParams.data.reportData.messageOfTheDay,
                image: imageUpdate.key,
              },
            },
          },
        };

        await api.updateCustomView(updatedRequestParams);
      }

      return customView;
    },
    {
      ...options,
      onSuccess: (data, customViewSaveRequest, context) => {
        const getCustomViewRequest: GetCustomViewRequest = {
          companyId: customViewSaveRequest.companyId,
          customViewId: data.id,
        };

        queryClient.setQueryData(['getCustomView', getCustomViewRequest], data);

        const getUsersCustomViewPoliciesRequest: GetUsersCustomViewPoliciesRequest = {
          companyId: customViewSaveRequest.companyId,
        };

        const queryData: GetUsersCustomViewPoliciesResponse | undefined = queryClient.getQueryData([
          'getUsersCustomViewPolicies',
          getUsersCustomViewPoliciesRequest,
        ]);

        const updatedCustomViewAccessResponses: CustomViewAccessResponse[] = [
          ...(queryData?.customViewAccessResponses ?? []),
          {
            customViewId: data.id,
            customViewAccessType: CustomViewAccessType.Owner,
          },
        ];

        const getUsersCustomViewPoliciesResponse: GetUsersCustomViewPoliciesResponse = {
          customViewAccessResponses: updatedCustomViewAccessResponses,
        };

        queryClient.setQueryData(
          ['getUsersCustomViewPolicies', getUsersCustomViewPoliciesRequest],
          getUsersCustomViewPoliciesResponse
        );

        options?.onSuccess?.(data, customViewSaveRequest, context);
      },
    }
  );
}

export type EditCustomViewRequest = UpdateCustomViewRequest &
  UpdateCustomViewAccessRequest & { messageOfTheDayImage?: File };

type EditCustomViewResponse = {
  customView: CustomViewReport<string>;
  putMessageOfTheDayImageUrlsResponse: PutMessageOfTheDayImageUrlsResponse | undefined;
};

function useUpdateCustomView(
  options?: ApiSaveRequestOptions<EditCustomViewResponse, EditCustomViewRequest>
): ApiSaveResult<EditCustomViewRequest, EditCustomViewResponse> {
  const queryClient = useQueryClient();

  return useMutation(
    ['updateCustomView'],
    async requestParams => {
      let messageOfTheDayImage = requestParams.data.reportData.messageOfTheDay.image;
      let putMessageOfTheDayImageUrlsResponse: PutMessageOfTheDayImageUrlsResponse | undefined;
      // Upload the message of the day image when a file is provided.
      if (requestParams.messageOfTheDayImage !== undefined) {
        putMessageOfTheDayImageUrlsResponse = await api.putMessageOfTheDayImageUrls({
          companyId: requestParams.companyId,
          customViewId: requestParams.customViewId,
          messageOfTheDayImage: requestParams.messageOfTheDayImage,
        });
        messageOfTheDayImage = putMessageOfTheDayImageUrlsResponse.key;
      }

      // Make sure to update the reportData.messageOfTheDay.image property to be the key of the image.
      // - If we are removing an image the key should be an empty string
      // - If we are uploading an image the key should be the key of that new image
      const updatedRequestParams = {
        ...requestParams,
        data: {
          ...requestParams.data,
          reportData: {
            ...requestParams.data.reportData,
            messageOfTheDay: {
              ...requestParams.data.reportData.messageOfTheDay,
              image: messageOfTheDayImage,
            },
          },
        },
      };
      const customView = await api.updateCustomView(updatedRequestParams);
      await api.updateCustomViewAccess(requestParams);

      return { customView, putMessageOfTheDayImageUrlsResponse };
    },
    {
      ...options,
      onSuccess: (data, updateCustomViewRequest, context) => {
        // 1. Set the getCustomView query data
        const getCustomViewRequest: GetCustomViewRequest = {
          companyId: updateCustomViewRequest.companyId,
          customViewId: updateCustomViewRequest.customViewId,
        };
        const customViewResponse: CustomViewReport<string> = data.customView;
        queryClient.setQueryData(['getCustomView', getCustomViewRequest], customViewResponse);

        // 2. Set the getMessageOftheDayImage query data
        let getMessageOfTheDayImageSignedUrl: string | undefined;
        if (
          data.putMessageOfTheDayImageUrlsResponse !== undefined &&
          data.putMessageOfTheDayImageUrlsResponse.getSignedUrl !== ''
        ) {
          getMessageOfTheDayImageSignedUrl = data.putMessageOfTheDayImageUrlsResponse.getSignedUrl;
        } else if (updateCustomViewRequest.data.reportData.messageOfTheDay.image === '') {
          getMessageOfTheDayImageSignedUrl = '';
        }

        if (getMessageOfTheDayImageSignedUrl !== undefined) {
          const getMessageOfTheDayImageRequest: GetMessageOfTheDayImageRequest = {
            companyId: updateCustomViewRequest.companyId,
            customViewId: updateCustomViewRequest.customViewId,
          };

          const getMessageOfTheDayImageResponse: GetMessageOfTheDayImageResponse = {
            getSignedUrl: getMessageOfTheDayImageSignedUrl,
          };

          queryClient.setQueryData(
            ['getMessageOftheDayImage', getMessageOfTheDayImageRequest],
            getMessageOfTheDayImageResponse
          );
        }

        options?.onSuccess?.(data, updateCustomViewRequest, context);
      },
    }
  );
}

function useDeleteCustomView(
  options?: ApiSaveRequestOptions<void, DeleteCustomViewRequest>
): ApiSaveResult<DeleteCustomViewRequest, void> {
  return useMutation(
    ['deleteCustomView'],
    requestParams => api.deleteCustomView(requestParams),
    options
  );
}

function useUsersCustomViews<TData = CustomViewsResponse<string>>(
  requestParams: UsersCustomViewsRequest,
  options?: ApiRequestOptions<CustomViewsResponse<string>, TData>
): ApiResult<TData> {
  return useQuery(
    ['getUsersCustomViews', requestParams],
    () => api.getUsersCustomViews(requestParams),
    options
  );
}

function useCustomView<TData = CustomViewReport<string>>(
  requestParams: GetCustomViewRequest,
  options?: ApiRequestOptions<CustomViewReport<string>, TData>
): ApiResult<TData> {
  return useQuery(
    ['getCustomView', requestParams],
    () => api.getCustomViews(requestParams),
    options
  );
}

function useGetCustomViewAccessUsers<TData = GetCustomViewAccessUsersResponse>(
  requestParams: GetCustomViewAccessUsersRequest,
  options?: ApiRequestOptions<GetCustomViewAccessUsersResponse, TData>
): ApiResult<TData> {
  return useQuery(
    ['getCustomViewAccessUsers', requestParams],
    () => api.getCustomViewAccessUsers(requestParams),
    options
  );
}

function useUpdateCustomViewAccess(
  options?: ApiSaveRequestOptions<void, UpdateCustomViewAccessRequest>
): ApiSaveResult<UpdateCustomViewAccessRequest, void> {
  return useMutation(
    ['updateCustomViewAccess'],
    requestParams => api.updateCustomViewAccess(requestParams),
    options
  );
}

function useUpdateCustomViewConnection(
  requestParams: UpdateCustomViewConnectionRequest,
  options?: ApiRequestOptions<void, void>
): ApiResult<void> {
  return useQuery(
    ['updateCustomViewConnection', requestParams],
    () => api.updateCustomViewConnection(requestParams),
    options
  );
}

function useGetCustomViewConnections<TData = CustomViewConnectionsResponse>(
  requestParams: GetCustomViewConnectionsRequest,
  options?: ApiRequestOptions<CustomViewConnectionsResponse, TData>
): ApiResult<TData> {
  return useQuery(
    ['getCustomViewConnections', requestParams],
    () => api.getCustomViewConnections(requestParams),
    options
  );
}

function useGetMessageOfTheDayImageUrls<TData = GetMessageOfTheDayImageUrlsResponse>(
  requestParams: GetMessageOfTheDayImageUrlsRequest,
  options?: ApiRequestOptions<GetMessageOfTheDayImageUrlsResponse, TData>
): ApiResult<TData> {
  return useQuery(
    ['getMessageOftheDayImageUrls', requestParams],
    () => api.getMessageOfTheDayImageUrls(requestParams),
    options
  );
}

function useGetMessageOfTheDayImage<TData = GetMessageOfTheDayImageResponse>(
  requestParams: GetMessageOfTheDayImageRequest,
  options?: ApiRequestOptions<GetMessageOfTheDayImageResponse, TData>
): ApiResult<TData> {
  return useQuery(
    ['getMessageOftheDayImage', requestParams],
    () => api.getMessageOfTheDayImage(requestParams),
    options
  );
}

function usePutMessageOfTheDayImageUrls(
  requestParams: PutMessageOfTheDayImageUrlsRequest
): ApiResult<void> {
  return useQuery(['putMessageOfTheDayImageUrls', requestParams], () =>
    api.putMessageOfTheDayImageUrls(requestParams)
  );
}

function useGetUsersPolicies<TData = GetUserPoliciesResponse>(
  getUserPoliciesRequest: GetUserPoliciesRequest,
  options: ApiRequestOptions<GetUserPoliciesResponse, TData>
): ApiResult<TData> {
  return useQuery(
    ['getUserPolicies', getUserPoliciesRequest],
    () => api.getUsersPolicies(getUserPoliciesRequest),
    options
  );
}

function useGetUsersCustomViewPolicies<TData = GetUsersCustomViewPoliciesResponse>(
  getUsersCustomViewPoliciesRequest: GetUsersCustomViewPoliciesRequest
): ApiResult<TData> {
  return useQuery(['getUsersCustomViewPolicies', getUsersCustomViewPoliciesRequest], () =>
    api.getUsersCustomViewPolicies(getUsersCustomViewPoliciesRequest)
  );
}

function useGetActivitiesReport(
  getActivitiesReportRequest: GetActivitiesReportRequest,
  onError: (err: AxiosError) => void
): UseInfiniteQueryResult<PaginatedResponse<GetActivitiesReportResponse>, AxiosError> {
  return useInfiniteQuery(
    ['getActivitesReport', getActivitiesReportRequest],
    options => activitiesApi.getActivitiesReport(getActivitiesReportRequest, options),
    {
      getNextPageParam: (
        lastPage: PaginatedResponse<GetActivitiesReportResponse>,
        allPages: PaginatedResponse<GetActivitiesReportResponse>[]
      ) => {
        const value = lastPage['@odata.count'];
        const pages = Math.ceil(value / 50);
        if (pages === allPages.length) return undefined;
        return allPages.length * 50;
      },
      retry: false,
      onError,
    }
  );
}

function useCompaniesAssigners<TData = UserResponse[]>(
  companyId: number,
  options?: ApiRequestOptions<UserResponse[], TData>
): ApiResult<TData> {
  return useQuery(
    ['getCompanyAssigners', companyId],
    () => api.getCompanyAssigners(companyId),
    options
  );
}

function useCompaniesEvaluators<TData = UserResponse[]>(
  companyId: number,
  options?: ApiRequestOptions<UserResponse[], TData>
): ApiResult<TData> {
  return useQuery(
    ['getCompanyEvaluators', companyId],
    () => api.getCompanyEvaluators(companyId),
    options
  );
}

function useGetActivitiesReportExcel(
  getActivitiesReportRequest: GetActivitiesReportRequest,
  options?: UseQueryOptions<number, unknown, number, (string | GetActivitiesReportRequest)[]>
): UseQueryResult<number> {
  return useQuery(
    ['getActivitesReportExcel', getActivitiesReportRequest],
    () => activitiesApi.getActivitiesReportExcel(getActivitiesReportRequest),
    options
  );
}

function useGetActivityAffidavitInformation(
  affidavitId: string | undefined,
  options?: UseQueryOptions<
    ActivityReportAffidavitModel,
    AxiosError<unknown, unknown>,
    ActivityReportAffidavitModel,
    (string | undefined)[]
  >
): UseQueryResult<ActivityReportAffidavitModel, AxiosError> {
  return useQuery(
    ['getActivityAffidavitInformation', affidavitId],
    () => activitiesApi.getActivityAffidavitInformation(affidavitId),
    options
  );
}

function useUserInformation(
  userId: number,
  options?: ApiRequestOptions<UserInformation, UserInformation>
): UseQueryResult<UserInformation> {
  return useQuery(['getUserInformation', userId], () => api.getUserInformation(userId), options);
}

function useSaveCompanyUserImportSettings(
  companyId: number,
  userIntegrationId: number,
  options?: ApiSaveRequestOptions<UserIntegration, PostCompanyImportSettingsRequestBody>
): ApiSaveResult<PostCompanyImportSettingsRequestBody, UserIntegration> {
  return useMutation(
    ['createCompanyUserImportSettings'],
    async requestParams => {
      if (Number.isNaN(userIntegrationId)) {
        return api.createCompanyUserImportSettings({
          companyId,
          data: requestParams,
          userIntegrationId: 0,
        });
      }

      return api.updateCompanyUserImportSettings({
        companyId,
        data: requestParams,
        userIntegrationId,
      });
    },
    {
      ...options,
    }
  );
}

function useGetAllTransferClientUser<TData = CompanySftpUserResponse[]>(
  companyId: number
): ApiResult<TData> {
  return useQuery(['getAllTransferClientUser', companyId], () =>
    api.getAllTransferClientUser(companyId)
  );
}

function useCreateCompanySftpUser(
  companyId: number,
  options?: ApiSaveRequestOptions<CreateCompanySftpUserResponse, number>
): ApiSaveResult<number, CreateCompanySftpUserResponse> {
  const queryClient = useQueryClient();

  return useMutation(
    ['createCompanySftpUserResponse'],
    requestCompanyId => {
      return api.postCompanySftpUser(requestCompanyId);
    },
    {
      ...options,
      onSuccess: data => {
        const companySftpUserResponses: CompanySftpUserResponse[] = [
          {
            Id: data.id,
            CompanyId: data.companyId,
            SftpUsername: data.sftpUsername,
            DateCreated: data.dateCreated,
            CredentialsDateCreated: data.credentialsDateCreated,
            SshPublicKeyId: data.sshPublicKeyId,
          },
        ];

        queryClient.setQueryData(['getAllTransferClientUser', companyId], companySftpUserResponses);
      },
    }
  );
}

function useRegenerateSftpCredentials(
  request: RegenerateCompanySftpUserRequest,
  options?: ApiSaveRequestOptions<CreateCompanySftpUserResponse, RegenerateCompanySftpUserRequest>
): ApiSaveResult<RegenerateCompanySftpUserRequest, CreateCompanySftpUserResponse> {
  const queryClient = useQueryClient();
  return useMutation(
    ['regenerateSftpCredentials'],
    () => api.postRegenerateCompanySftpCredentials(request.companyId, request.companySftpId),
    {
      ...options,
      onSuccess: data => {
        const companySftpUserResponses: CreateCompanySftpUser[] = [
          {
            SftpUsername: data.sftpUsername,
            DateCreated: data.dateCreated,
            PrivateKey: data.privateKey,
            CompanyId: data.companyId,
            CredentialsDateCreated: data.credentialsDateCreated,
            Id: data.id,
            SshPublicKeyId: data.sshPublicKeyId,
          },
        ];

        queryClient.setQueryData(
          ['getAllTransferClientUser', request.companyId],
          companySftpUserResponses
        );
      },
    }
  );
}

function useGetUserIntegration(
  companyId: number,
  userIntegrationId: number,
  options: UseQueryOptions<UserIntegration, AxiosError, UserIntegration, (string | number)[]>
): UseQueryResult<UserIntegration, AxiosError> {
  return useQuery(
    ['getUserIntegration', userIntegrationId],
    () => api.getUserIntegration(companyId, userIntegrationId),
    {
      ...options,
      enabled: !Number.isNaN(userIntegrationId),
      retry: 3,
      retryDelay: 1000,
    }
  );
}

export default {
  useCompanyDetails,
  useCompanyQualifications,
  useCompanySeries,
  useCompanySeriesTasks,
  useCompaniesUserStatistics,
  useCompaniesUsersStatistics,
  useCompaniesUsersRoles,
  useCompaniesUsersTasks,
  useCompaniesUsersComponents,
  useCompaniesUsersTrainings,
  useCompaniesUsersJobs,
  useCompaniesRoles,
  useCompaniesCompanySites,
  useCompaniesSupervisors,
  useCompaniesCostCenters,
  useCompaniesDepartments,
  useCompaniesUsers,
  useCompaniesJobs,
  useCreateCustomView,
  useUpdateCustomView,
  useDeleteCustomView,
  useUsersCustomViews,
  useCustomView,
  useGetCustomViewAccessUsers,
  useUpdateCustomViewAccess,
  useUpdateCustomViewConnection,
  useGetCustomViewConnections,
  useGetMessageOfTheDayImageUrls,
  useGetMessageOfTheDayImage,
  usePutMessageOfTheDayImageUrls,
  useGetUsersPolicies,
  useGetUsersCustomViewPolicies,
  useGetActivitiesReport,
  useCompaniesAssigners,
  useCompaniesEvaluators,
  useGetActivitiesReportExcel,
  useCompaniesUsersAll,
  useGetActivityAffidavitInformation,
  useUserInformation,
  useGetAllTransferClientUser,
  useSaveCompanyUserImportSettings,
  useCreateCompanySftpUser,
  useRegenerateSftpCredentials,
  useGetUserIntegration,
};
