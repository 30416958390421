import { Values } from '../../types/values';

export interface CompaniesUserStatisticsRequest {
  userId: number;
  companyId: number;
  roleIds: number[];
  include: TaskProfileIncludeStatistic[];
}

export interface CompaniesUsersStatisticsRequest {
  userId: number;
  companyId: number;
  roleIds: number[];
  include: TaskProfileIncludeStatistic[];
}

export const TaskProfileIncludeStatistics = {
  roles: 'roles',
  jobs: 'jobs',
  tasks: 'tasks',
  trainings: 'trainings',
  wePes: 'wePes',
} as const;

export type TaskProfileIncludeStatistic = Values<typeof TaskProfileIncludeStatistics>;
