import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { CompanyResponse } from '../../api/responses/companyResponse';
import { UserInformation } from '../../api/responses/userInformation';
import IntegrationDateFilter from '../../components/IntegrationHistory/IntegrationDateFilter';
import IntegrationPickerDropdown from '../../components/IntegrationHistory/IntegrationPickerDropdown';
import IntegrationHistoryTabsContainer from '../../components/IntegrationHistory/IntegrationHistoryTabsContainer';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import TopNavbar from '../../components/Layout/TopNavBar/TopNavBar';
import CurrentIntegrationsResponse from '../../models/CurrentIntegrationsResponse';
import { AvailablePolicies } from '../../policyService/policyEnforcer/policyEnforcer';
import { DateFilters } from './IntegrationHistoryContainer';
import { BatchUserStatisticsPaginatedResponse } from '../../api/responses/BatchUserStatisticsPaginatedResponse';
import Button from '../../components/Buttons/Button/Button';
import { AiOutlineFileExcel } from 'react-icons/ai';
import testIds from '../../constants/testIds';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export interface IntegrationHistoryProps {
  policies: AvailablePolicies;
  company: CompanyResponse | undefined;
  isCompanyLoading: boolean;
  isUserLoading: boolean;
  user: UserInformation | undefined;
  currentIntegrations: CurrentIntegrationsResponse[];
  isCurrentIntegrationsLoading: boolean;
  selectedIntegration: CurrentIntegrationsResponse;
  dateFilters: DateFilters;
  newUserStatistics: BatchUserStatisticsPaginatedResponse[];
  updatedUserStatistics: BatchUserStatisticsPaginatedResponse[];
  totalErrorsStatistics: BatchUserStatisticsPaginatedResponse[];
  setDateFilters: (change: Partial<DateFilters>) => void;
  setSelectedIntegration: React.Dispatch<CurrentIntegrationsResponse>;
  activeTab: string;
  setActiveTab: (tabId: string) => void;
  isNewUserStatisticsLoading: boolean;
  isUpdatedUserStatisticsLoading: boolean;
  isTotalErrorsUserStatisticsLoading: boolean;
  newUsersHasNextPage: boolean;
  updatedUsersHasNextPage: boolean;
  totalErrorsHasNextPage: boolean;
  fetchNextNewUsersPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<
    InfiniteQueryObserverResult<BatchUserStatisticsPaginatedResponse, AxiosError<unknown, unknown>>
  >;
  fetchNextUpdatedUsersPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<
    InfiniteQueryObserverResult<BatchUserStatisticsPaginatedResponse, AxiosError<unknown, unknown>>
  >;
  fetchNextTotalErrorsPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<
    InfiniteQueryObserverResult<BatchUserStatisticsPaginatedResponse, AxiosError<unknown, unknown>>
  >;
  onExcelReportClick: () => void;
  isExcelReportLoading: boolean;
}

const IntegrationHistory: React.FC<IntegrationHistoryProps> = ({
  policies,
  company,
  isCompanyLoading,
  isUserLoading,
  user,
  currentIntegrations,
  isCurrentIntegrationsLoading,
  selectedIntegration,
  dateFilters,
  newUserStatistics,
  updatedUserStatistics,
  totalErrorsStatistics,
  setSelectedIntegration,
  setDateFilters,
  activeTab,
  setActiveTab,
  isNewUserStatisticsLoading,
  isUpdatedUserStatisticsLoading,
  isTotalErrorsUserStatisticsLoading,
  fetchNextNewUsersPage,
  fetchNextTotalErrorsPage,
  fetchNextUpdatedUsersPage,
  newUsersHasNextPage,
  totalErrorsHasNextPage,
  updatedUsersHasNextPage,
  onExcelReportClick,
  isExcelReportLoading,
}) => {
  return (
    <MainLayout
      policies={policies}
      companyImage={company?.logo}
      companyName={company?.name}
      isCompanyLoading={isCompanyLoading}
    >
      <TopNavbar reportName="Integration History" isUserLoading={isUserLoading} user={user} />
      <Container fluid className="h-100 overflow-hidden">
        <Row className="h-100 py-2">
          <Col className="mx-2 mb-3 py-2 d-flex flex-column h-100 mb-2">
            <Row className="mb-2">
              <Col xs={6} sm={4} md={3} lg={2}>
                <IntegrationPickerDropdown
                  currentIntegrations={currentIntegrations}
                  isCurrentIntegrationsLoading={isCurrentIntegrationsLoading}
                  onIntegrationSelection={setSelectedIntegration}
                  selectedIntegration={selectedIntegration}
                  dropdownButtonClassName="w-100 text-truncate border"
                  hideLabel={true}
                />
              </Col>
              <IntegrationDateFilter
                dateFilters={dateFilters}
                setDateFilters={setDateFilters}
                disableDatePickers={selectedIntegration.Id === -1}
              />
              <div
                className="align-self-end"
                style={{ width: 'fit-content', position: 'absolute', right: 0 }}
              >
                <Button
                  variant="light"
                  size="sm"
                  className="border"
                  disabled={
                    selectedIntegration.Id === -1 ||
                    isExcelReportLoading ||
                    (dateFilters.startDate === '' && dateFilters.endDate === '')
                  }
                  onClick={onExcelReportClick}
                  dataTestId={testIds.integrationHistoryExcelButton()}
                >
                  {isExcelReportLoading ? (
                    <Spinner size="sm" className="mx-1 mt-1" data-testid={testIds.spinner()} />
                  ) : (
                    <AiOutlineFileExcel className="mr-0" size={23} />
                  )}
                </Button>
              </div>
            </Row>
            <Row className="overflow-hidden flex-grow-1">
              <Col className="h-100">
                <IntegrationHistoryTabsContainer
                  newUserStatistics={newUserStatistics}
                  updatedUserStatistics={updatedUserStatistics}
                  totalErrorsStatistics={totalErrorsStatistics}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  isNewUserStatisticsLoading={isNewUserStatisticsLoading}
                  isUpdatedUserStatisticsLoading={isUpdatedUserStatisticsLoading}
                  isTotalErrorsUserStatisticsLoading={isTotalErrorsUserStatisticsLoading}
                  fetchNextNewUsersPage={fetchNextNewUsersPage}
                  fetchNextUpdatedUsersPage={fetchNextUpdatedUsersPage}
                  fetchNextTotalErrorsPage={fetchNextTotalErrorsPage}
                  newUsersHasNextPage={newUsersHasNextPage}
                  totalErrorsHasNextPage={totalErrorsHasNextPage}
                  updatedUserHasNextPage={updatedUsersHasNextPage}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default IntegrationHistory;
