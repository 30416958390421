import { useEffect, useState } from 'react';
import { DisplayItem } from '../models/displayItem';
import { UseDisplayItemsResponse } from '../models/useDisplayItemsResponse';

function useActiveEmployeeStatus(companyId: number, searchText: string): UseDisplayItemsResponse {
  useEffect(() => {
    const dataList: DisplayItem[] = [
      {
        itemId: 1,
        display: 'Active Employees',
      },
      {
        itemId: 0,
        display: 'Inactive Employees',
      },
    ];
    setData(dataList);
  }, [searchText]);

  const [data, setData] = useState<DisplayItem[]>([]);

  return { data, isLoading: false };
}

export default useActiveEmployeeStatus;
