import React from 'react';
import testIds from '../../../../constants/testIds';
import { FaExclamationCircle } from 'react-icons/fa';

interface TaskProfileDetailListTaskAssociationItemProps {
  id: number;
  name: string;
  requiredStatusText?: string;
  hasSeriesAccess: boolean;
}

const TaskProfileDetailListTaskAssociationItem: React.FC<
  TaskProfileDetailListTaskAssociationItemProps
> = ({ id, name, requiredStatusText, hasSeriesAccess }) => (
  <div
    className="d-inline-flex justify-content-between align-items-center border-0 w-100 text-start pb-0 text-dark fw-bold"
    data-testid={testIds.taskProfileDetailListTask(id)}
  >
    <span className="px-2">{name}</span>
    {!hasSeriesAccess && (
      <div style={{ color: 'red' }}>
        Needs Series Access <FaExclamationCircle size={16} color="red" />
      </div>
    )}
    <div className="text-end">{requiredStatusText ? <>{requiredStatusText}</> : null}</div>
  </div>
);

export default TaskProfileDetailListTaskAssociationItem;
