import { parse, ParseResult } from 'papaparse';
import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { MultiValue, SingleValue } from 'react-select';
import { ApiSaveResult } from '../../api/apiHooks';
import { PostCompanyImportSettingsRequestBody } from '../../api/requests/PostCompanyImportSettingsRequest';
import RegenerateCompanySftpUserRequest from '../../api/requests/regenerateCompanySftpUserRequest';
import { CreateCompanySftpUserResponse } from '../../api/responses/createCompanySftpUserResponse';
import { CompanySftpUserResponse } from '../../api/responses/getCompanySftpUserResponse';
import { RolesResponse } from '../../api/responses/rolesResponse';
import { UserInformation } from '../../api/responses/userInformation';
import { UserIntegration } from '../../api/responses/userIntegration';
import ConfigurationCard from '../../components/CreateUserIntegration/ConfigurationCard/ConfigurationCard';
import { MappingOption } from '../../models/MappingOption';
import GeneralConfigurationCard from '../../components/CreateUserIntegration/GeneralConfigurationCard/GeneralConfigurationCard';
import ApiMappingCard from '../../components/CreateUserIntegration/MappingCard/ApiMappingCard';
import MappingCard from '../../components/CreateUserIntegration/MappingCard/MappingCard';
import UserCredentialsCard from '../../components/CreateUserIntegration/UserCredentialsCard/UserCredentialsCard';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import TopNavbar from '../../components/Layout/TopNavBar/TopNavBar';
import commonText from '../../constants/commonText';
import messages from '../../constants/messages';
import CompanyUserImportSettingsType from '../../models/CompanyUserImportSettingsType';
import { DisplayItem } from '../../models/displayItem';
import Series from '../../models/Series';
import createCompanyUserImportBodyValidator from './createCompanyUserImportBodyValidator';
import defaultCreateUserIntegrationOptions, {
  CreateUserIntegrationOptions,
} from './defaultCreateUserIntegrationOptions';
import defaultDropDownSelections from './defaultDropDownSelections';
import { UserCreationDataPointId } from '../../models/UserCreationDataPoint';
import { UserCreationDataPointLabel } from '../../models/UserCreationDataPointLabel';
import { Col, Container, Row } from 'react-bootstrap';
import testIds from '../../constants/testIds';
import ToastMessage from '../../components/Toast/ToastMessage';
import routes from '../../constants/routes';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';

export interface CreateUserIntegrationProps {
  dragAndDropInputTestId?: string;
  user: UserInformation | undefined;
  isUserLoading: boolean;
  companyId: number;
  companyImportSettingsMutation: ApiSaveResult<
    PostCompanyImportSettingsRequestBody,
    UserIntegration
  >;
  companySftpUsers: CompanySftpUserResponse[];
  createCompanySftpUserMutation: ApiSaveResult<number, CreateCompanySftpUserResponse>;
  regenerateCompanySftpuserMutation: ApiSaveResult<
    RegenerateCompanySftpUserRequest,
    CreateCompanySftpUserResponse
  >;
  availableSeriesList: Series[];
  availableRolesList: RolesResponse[];
  navbarTitle: string;
  userIntegration: UserIntegration | null;
  isEditing: boolean;
  showToast: boolean;
  setShowToast: React.Dispatch<boolean>;
  navigateTo: (url: string) => void;
  companyImage?: string;
  companyName?: string;
  isCompanyLoading: boolean;
  integrationTypeQueryParam: string | null;
  integrationNameQueryParam: string | null;
  isUserIntegrationLoading: boolean;
}

const CreateUserIntegration: React.FC<CreateUserIntegrationProps> = ({
  dragAndDropInputTestId,
  isUserLoading,
  user,
  companyId,
  companyImportSettingsMutation,
  companySftpUsers,
  createCompanySftpUserMutation,
  regenerateCompanySftpuserMutation,
  availableSeriesList,
  availableRolesList,
  navbarTitle,
  userIntegration,
  isEditing,
  setShowToast,
  showToast,
  navigateTo,
  companyImage,
  companyName,
  isCompanyLoading,
  integrationNameQueryParam,
  integrationTypeQueryParam,
  isUserIntegrationLoading,
}) => {
  const [createUserIntegrationOptions, setCreateUserIntegrationOptions] =
    useState<CreateUserIntegrationOptions>(defaultCreateUserIntegrationOptions);
  const [headers, setHeaders] = useState<string[]>([]);
  const [seriesListValues, setSeriesListValues] = useState<
    MultiValue<{ label: string; value: number }>
  >([]);
  const [fileDropError, setFileDropError] = useState<boolean>(false);
  const [validationResult, setValidationResult] = useState<{ isValid: boolean; errors: string[] }>({
    isValid: true,
    errors: [],
  });
  const [fileUploadType, setFileUploadType] = useState<CompanyUserImportSettingsType>(
    CompanyUserImportSettingsType.SftpFileTransfer
  );
  const [showSaveAlert, setShowSaveAlert] = useState(false);
  const [privateKey, setPrivateKey] = useState<string>('');
  const [hasHeaderRow, setHasHeaderRow] = useState(true);
  const [makeTaskProfilingAssignments, setMakeTaskProfilingAssignments] = useState(false);
  const [missingFields, setMissingFields] = useState(false);
  const [defaultRoleValue, setDefaultRoleValue] = useState<
    | {
        label: string;
        value: number | undefined;
      }
    | undefined
  >(undefined);
  const [advancedConfigurationState, setAdvancedConfigurationState] = useState<boolean>(false);
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [isInitialLoadingComplete, setIsInitialLoadingComplete] = useState(false);

  useEffect(() => {
    setReadOnly(isEditing);
  }, [isEditing]);

  useEffect(() => {
    if (integrationNameQueryParam !== null)
      setCreateUserIntegrationOptions(prevState => ({
        ...prevState,
        title: integrationNameQueryParam,
      }));
  }, [integrationNameQueryParam]);

  useEffect(() => {
    if (integrationTypeQueryParam !== null)
      setFileUploadType(Number.parseInt(integrationTypeQueryParam));
  }, [integrationTypeQueryParam]);

  useEffect(() => {
    if (userIntegration === null || !isEditing) return;

    const leadingZeros =
      userIntegration.LeadingZerosFormat === 0
        ? defaultCreateUserIntegrationOptions.leadingZeros
        : {
            display: userIntegration.LeadingZerosFormat?.toString() || 'none',
            itemId: userIntegration.LeadingZerosFormat || 0,
          };
    setFileUploadType(userIntegration.Type);
    const selectedRole = availableRolesList.filter(
      item => item.roleId === userIntegration.DefaultRoleId
    )[0];
    setDefaultRoleValue({
      value: userIntegration.DefaultRoleId || 0,
      label: selectedRole?.roleName || '',
    });
    const series = userIntegration?.SeriesIds?.split(',').map(item => Number(item));
    const selectedSeries = availableSeriesList
      .filter(item => series?.includes(item.id))
      .map(item => ({ label: item.name, value: item.id }));
    setSeriesListValues(selectedSeries);
    setHasHeaderRow(userIntegration.HasHeaderRow);
    setMakeTaskProfilingAssignments(userIntegration.MakeTaskProfilingAssignments);
    const usernameFormatLength = userIntegration.PrependFormat?.length || 0;
    setMissingFields(userIntegration.CreateDependentData || usernameFormatLength > 0);
    if (userIntegration.Type === CompanyUserImportSettingsType.API) {
      setApiUploadMapping(userIntegration, leadingZeros);
    } else if (userIntegration.Type === CompanyUserImportSettingsType.SftpFileTransfer) {
      setFileUploadMapping(userIntegration, leadingZeros);
    }
    setAdvancedConfigurationState(userIntegration.CreateDependentData);
  }, [userIntegration, availableRolesList, availableSeriesList, isEditing]);

  useEffect(() => {
    if (
      createCompanySftpUserMutation.data?.privateKey !== '' &&
      regenerateCompanySftpuserMutation.data?.privateKey !== '' &&
      createCompanySftpUserMutation.data !== undefined &&
      regenerateCompanySftpuserMutation.data !== undefined
    ) {
      setPrivateKey(regenerateCompanySftpuserMutation.data?.privateKey || '');
    } else if (
      createCompanySftpUserMutation.data?.privateKey !== '' &&
      createCompanySftpUserMutation.data !== undefined &&
      (regenerateCompanySftpuserMutation.data?.privateKey === '' ||
        regenerateCompanySftpuserMutation.data === undefined)
    ) {
      setPrivateKey(createCompanySftpUserMutation.data?.privateKey || '');
    } else if (
      regenerateCompanySftpuserMutation.data?.privateKey !== '' &&
      regenerateCompanySftpuserMutation.data !== undefined &&
      (createCompanySftpUserMutation.data?.privateKey === '' ||
        createCompanySftpUserMutation.data === undefined)
    ) {
      setPrivateKey(regenerateCompanySftpuserMutation.data?.privateKey || '');
    } else {
      setPrivateKey('');
    }
  }, [createCompanySftpUserMutation, regenerateCompanySftpuserMutation]);

  useEffect(() => {
    if (
      isUserIntegrationLoading &&
      createCompanySftpUserMutation.isLoading &&
      regenerateCompanySftpuserMutation.isLoading &&
      companyImportSettingsMutation.isLoading
    ) {
      setIsInitialLoadingComplete(true);
    }
  }, [
    isUserIntegrationLoading,
    createCompanySftpUserMutation,
    regenerateCompanySftpuserMutation,
    companyImportSettingsMutation,
  ]);

  const setApiUploadMapping = (integration: UserIntegration, leadingZeros: DisplayItem) => {
    const dropdownSelections = { ...defaultDropDownSelections };
    const {
      UserNameColumnIndex,
      UserName2ColumnIndex,
      FirstNameColumnIndex,
      LastNameColumnIndex,
      MiddleNameColumnIndex,
      EmailColumnIndex,
      RoleColumnIndex,
      DepartmentColumnIndex,
      SupervisorUsernameColumnIndex,
      CompanyIdColumnIndex,
      CompanySiteColumnIndex,
      CompanyCostCenterColumnIndex,
      UserStatusNameColumnIndex,
      HireDateColumnIndex,
      CompanyNameColumnIndex,
      JobTitleColumnIndex,
      Supervisor2UsernameColumnIndex,
    } = integration;
    if (UserNameColumnIndex !== null) {
      dropdownSelections.username = {
        ...dropdownSelections.username,
        columnNumber: 1,
      };
    }
    if (UserName2ColumnIndex !== null) {
      dropdownSelections.username2 = {
        ...dropdownSelections.username2,
        columnNumber: 1,
      };
    }
    if (FirstNameColumnIndex !== null) {
      dropdownSelections.firstName = {
        ...dropdownSelections.firstName,
        columnNumber: 1,
      };
    }
    if (MiddleNameColumnIndex !== null) {
      dropdownSelections.middleName = {
        ...dropdownSelections.middleName,
        columnNumber: 1,
      };
    }
    if (LastNameColumnIndex !== null) {
      dropdownSelections.lastName = {
        ...dropdownSelections.lastName,
        columnNumber: 1,
      };
    }
    if (EmailColumnIndex !== null) {
      dropdownSelections.email = {
        ...dropdownSelections.email,
        columnNumber: 1,
      };
    }
    if (RoleColumnIndex !== null) {
      dropdownSelections.role = {
        ...dropdownSelections.role,
        columnNumber: 1,
      };
    }
    if (DepartmentColumnIndex !== null) {
      dropdownSelections.department = {
        ...dropdownSelections.department,
        columnNumber: 1,
      };
    }
    if (SupervisorUsernameColumnIndex !== null) {
      dropdownSelections.supervisorUsername = {
        ...dropdownSelections.supervisorUsername,
        columnNumber: 1,
      };
    }
    if (Supervisor2UsernameColumnIndex !== null) {
      dropdownSelections.supervisor2Username = {
        ...dropdownSelections.supervisor2Username,
        columnNumber: 1,
      };
    }
    if (CompanyIdColumnIndex !== null) {
      dropdownSelections.companyId = {
        ...dropdownSelections.companyId,
        columnNumber: 1,
      };
    }
    if (CompanySiteColumnIndex !== null) {
      dropdownSelections.companySite = {
        ...dropdownSelections.companySite,
        columnNumber: 1,
      };
    }
    if (CompanyCostCenterColumnIndex !== null) {
      dropdownSelections.costCenter = {
        ...dropdownSelections.costCenter,
        columnNumber: 1,
      };
    }
    if (UserStatusNameColumnIndex !== null) {
      dropdownSelections.status = {
        ...dropdownSelections.status,
        columnNumber: 1,
      };
    }
    if (HireDateColumnIndex !== null) {
      dropdownSelections.hireDate = {
        ...dropdownSelections.hireDate,
        columnNumber: 1,
      };
    }
    if (CompanyNameColumnIndex !== null) {
      dropdownSelections.companyName = {
        ...dropdownSelections.companyName,
        columnNumber: 1,
      };
    }
    if (JobTitleColumnIndex !== null) {
      dropdownSelections.jobTitle = {
        ...dropdownSelections.jobTitle,
        columnNumber: 1,
      };
    }
    setCreateUserIntegrationOptions(prevState => ({
      ...prevState,
      title: integration.IntegrationName || '',
      description: integration.Description || '',
      leadingZeros,
      prependUsername: integration.PrependFormat || '',
      dropDownOptions: dropdownSelections,
      isEnabled: integration.IsEnabled,
    }));
  };

  const setFileUploadMapping = (integration: UserIntegration, leadingZeros: DisplayItem) => {
    const dropdownSelections = { ...defaultDropDownSelections };
    const {
      UserNameColumnIndex,
      UserName2ColumnIndex,
      FirstNameColumnIndex,
      LastNameColumnIndex,
      MiddleNameColumnIndex,
      EmailColumnIndex,
      RoleColumnIndex,
      DepartmentColumnIndex,
      SupervisorUsernameColumnIndex,
      CompanySiteColumnIndex,
      CompanyCostCenterColumnIndex,
      UserStatusNameColumnIndex,
      HireDateColumnIndex,
      CompanyNameColumnIndex,
      Supervisor2UsernameColumnIndex,
    } = integration;
    const tempHeaders: string[] = Array.from(Array(15), () => '');
    if (UserNameColumnIndex !== null && UserNameColumnIndex !== undefined) {
      dropdownSelections.username = {
        ...dropdownSelections.username,
        columnNumber: UserNameColumnIndex,
        columnName: dropdownSelections.username.dataPointLabel,
      };
      if (tempHeaders[UserNameColumnIndex] === '') {
        tempHeaders[UserNameColumnIndex] = dropdownSelections.username.dataPointLabel;
      }
    }
    if (UserName2ColumnIndex !== null && UserName2ColumnIndex !== undefined) {
      dropdownSelections.username2 = {
        ...dropdownSelections.username2,
        columnNumber: UserName2ColumnIndex,
        columnName: dropdownSelections.username2.dataPointLabel,
      };
      if (tempHeaders[UserName2ColumnIndex] === '') {
        tempHeaders[UserName2ColumnIndex] = dropdownSelections.username2.dataPointLabel;
      }
    }
    if (FirstNameColumnIndex !== null && FirstNameColumnIndex !== undefined) {
      dropdownSelections.firstName = {
        ...dropdownSelections.firstName,
        columnNumber: FirstNameColumnIndex,
        columnName: dropdownSelections.firstName.dataPointLabel,
      };
      if (tempHeaders[FirstNameColumnIndex] === '') {
        tempHeaders[FirstNameColumnIndex] = dropdownSelections.firstName.dataPointLabel;
      }
    }
    if (MiddleNameColumnIndex !== null && MiddleNameColumnIndex !== undefined) {
      dropdownSelections.middleName = {
        ...dropdownSelections.middleName,
        columnNumber: MiddleNameColumnIndex,
        columnName: dropdownSelections.middleName.dataPointLabel,
      };
      if (tempHeaders[MiddleNameColumnIndex] === '') {
        tempHeaders[MiddleNameColumnIndex] = dropdownSelections.middleName.dataPointLabel;
      }
    }
    if (LastNameColumnIndex !== null && LastNameColumnIndex !== undefined) {
      dropdownSelections.lastName = {
        ...dropdownSelections.lastName,
        columnNumber: LastNameColumnIndex,
        columnName: dropdownSelections.lastName.dataPointLabel,
      };
      if (tempHeaders[LastNameColumnIndex] === '') {
        tempHeaders[LastNameColumnIndex] = dropdownSelections.lastName.dataPointLabel;
      }
    }
    if (EmailColumnIndex !== null && EmailColumnIndex !== undefined) {
      dropdownSelections.email = {
        ...dropdownSelections.email,
        columnNumber: EmailColumnIndex,
        columnName: dropdownSelections.email.dataPointLabel,
      };
      if (tempHeaders[EmailColumnIndex] === '') {
        tempHeaders[EmailColumnIndex] = dropdownSelections.email.dataPointLabel;
      }
    }
    if (RoleColumnIndex !== null && RoleColumnIndex !== undefined) {
      dropdownSelections.role = {
        ...dropdownSelections.role,
        columnNumber: RoleColumnIndex,
        columnName: dropdownSelections.role.dataPointLabel,
      };
      if (tempHeaders[RoleColumnIndex] === '') {
        tempHeaders[RoleColumnIndex] = dropdownSelections.role.dataPointLabel;
      }
    }
    if (DepartmentColumnIndex !== null && DepartmentColumnIndex !== undefined) {
      dropdownSelections.department = {
        ...dropdownSelections.department,
        columnNumber: DepartmentColumnIndex,
        columnName: dropdownSelections.department.dataPointLabel,
      };
      if (tempHeaders[DepartmentColumnIndex] === '') {
        tempHeaders[DepartmentColumnIndex] = dropdownSelections.department.dataPointLabel;
      }
    }
    if (SupervisorUsernameColumnIndex !== null && SupervisorUsernameColumnIndex !== undefined) {
      dropdownSelections.supervisorUsername = {
        ...dropdownSelections.supervisorUsername,
        columnNumber: SupervisorUsernameColumnIndex,
        columnName: dropdownSelections.supervisorUsername.dataPointLabel,
      };
      if (tempHeaders[SupervisorUsernameColumnIndex] === '') {
        tempHeaders[SupervisorUsernameColumnIndex] =
          dropdownSelections.supervisorUsername.dataPointLabel;
      }
    }
    if (Supervisor2UsernameColumnIndex !== null && Supervisor2UsernameColumnIndex !== undefined) {
      dropdownSelections.supervisor2Username = {
        ...dropdownSelections.supervisor2Username,
        columnNumber: Supervisor2UsernameColumnIndex,
        columnName: dropdownSelections.supervisor2Username.dataPointLabel,
      };
      if (tempHeaders[Supervisor2UsernameColumnIndex] === '') {
        tempHeaders[Supervisor2UsernameColumnIndex] =
          dropdownSelections.supervisor2Username.dataPointLabel;
      }
    }
    if (CompanySiteColumnIndex !== null && CompanySiteColumnIndex !== undefined) {
      dropdownSelections.companySite = {
        ...dropdownSelections.companySite,
        columnNumber: CompanySiteColumnIndex,
        columnName: dropdownSelections.companySite.dataPointLabel,
      };
      if (tempHeaders[CompanySiteColumnIndex] === '') {
        tempHeaders[CompanySiteColumnIndex] = dropdownSelections.companySite.dataPointLabel;
      }
    }
    if (CompanyCostCenterColumnIndex !== null && CompanyCostCenterColumnIndex !== undefined) {
      dropdownSelections.costCenter = {
        ...dropdownSelections.costCenter,
        columnNumber: CompanyCostCenterColumnIndex,
        columnName: dropdownSelections.costCenter.dataPointLabel,
      };
      if (tempHeaders[CompanyCostCenterColumnIndex] === '') {
        tempHeaders[CompanyCostCenterColumnIndex] = dropdownSelections.costCenter.dataPointLabel;
      }
    }
    if (UserStatusNameColumnIndex !== null && UserStatusNameColumnIndex !== undefined) {
      dropdownSelections.status = {
        ...dropdownSelections.status,
        columnNumber: UserStatusNameColumnIndex,
        columnName: dropdownSelections.status.dataPointLabel,
      };
      if (tempHeaders[UserStatusNameColumnIndex] === '') {
        tempHeaders[UserStatusNameColumnIndex] = dropdownSelections.status.dataPointLabel;
      }
    }
    if (HireDateColumnIndex !== null && HireDateColumnIndex !== undefined) {
      dropdownSelections.hireDate = {
        ...dropdownSelections.hireDate,
        columnNumber: HireDateColumnIndex,
        columnName: dropdownSelections.hireDate.dataPointLabel,
      };
      if (tempHeaders[HireDateColumnIndex] === '') {
        tempHeaders[HireDateColumnIndex] = dropdownSelections.hireDate.dataPointLabel;
      }
    }
    if (CompanyNameColumnIndex !== null && CompanyNameColumnIndex !== undefined) {
      dropdownSelections.companyName = {
        ...dropdownSelections.companyName,
        columnNumber: CompanyNameColumnIndex,
        columnName: dropdownSelections.companyName.dataPointLabel,
      };
      if (tempHeaders[CompanyNameColumnIndex] === '') {
        tempHeaders[CompanyNameColumnIndex] = dropdownSelections.companyName.dataPointLabel;
      }
    }
    setCreateUserIntegrationOptions(prevState => ({
      ...prevState,
      title: integration.IntegrationName || '',
      description: integration.Description || '',
      leadingZeros,
      prependUsername: integration.PrependFormat || '',
      dropDownOptions: dropdownSelections,
      isEnabled: integration.IsEnabled,
    }));
  };

  const getFileUploadRequestBody = (): PostCompanyImportSettingsRequestBody => {
    const seriesList = seriesListValues.map(item => item.value).join(',');
    return {
      IntegrationName: createUserIntegrationOptions.title,
      Description: createUserIntegrationOptions.description,
      HasHeaderRow: hasHeaderRow,
      CreateDependentData: missingFields,
      UniqueUsernames: false,
      IsPGPEncrypted: false,
      IsEnabled: createUserIntegrationOptions.isEnabled,
      UserNameColumnIndex: createUserIntegrationOptions.dropDownOptions.username.columnNumber,
      UserName2ColumnIndex: createUserIntegrationOptions.dropDownOptions.username2.columnNumber,
      FirstNameColumnIndex: createUserIntegrationOptions.dropDownOptions.firstName.columnNumber,
      LastNameColumnIndex: createUserIntegrationOptions.dropDownOptions.lastName.columnNumber,
      MiddleNameColumnIndex: createUserIntegrationOptions.dropDownOptions.middleName.columnNumber,
      EmailColumnIndex: createUserIntegrationOptions.dropDownOptions.email.columnNumber,
      RoleColumnIndex: createUserIntegrationOptions.dropDownOptions.role.columnNumber,
      SeriesColumnIndex: null,
      DepartmentColumnIndex: createUserIntegrationOptions.dropDownOptions.department.columnNumber,
      SupervisorUsernameColumnIndex:
        createUserIntegrationOptions.dropDownOptions.supervisorUsername.columnNumber,
      CompanyIdColumnIndex: createUserIntegrationOptions.dropDownOptions.companyId.columnNumber,
      CompanySiteColumnIndex: createUserIntegrationOptions.dropDownOptions.companySite.columnNumber,
      CompanyCostCenterColumnIndex:
        createUserIntegrationOptions.dropDownOptions.costCenter.columnNumber,
      UserStatusNameColumnIndex: createUserIntegrationOptions.dropDownOptions.status.columnNumber,
      HireDateColumnIndex: createUserIntegrationOptions.dropDownOptions.hireDate.columnNumber,
      CompanyNameColumnIndex: createUserIntegrationOptions.dropDownOptions.companyName.columnNumber,
      JobTitleColumnIndex: createUserIntegrationOptions.dropDownOptions.jobTitle.columnNumber,
      Supervisor2UsernameColumnIndex:
        createUserIntegrationOptions.dropDownOptions.supervisor2Username.columnNumber,
      DefaultCompanyId: companyId,
      DefaultRoleId: defaultRoleValue?.value,
      SeriesIds: seriesList,
      ActiveStatus: '',
      PrimaryUserLookupField: '',
      UseSecondaryUserLookupField: false,
      LeadingZerosFormat: createUserIntegrationOptions.leadingZeros.itemId,
      PrependFormat: createUserIntegrationOptions.prependUsername,
      MakeTaskProfilingAssignments: makeTaskProfilingAssignments,
      ExceptionReportEmailRecipientIds: '',
      HasMultipleCompanies: false,
      Type: CompanyUserImportSettingsType.SftpFileTransfer,
    };
  };

  const getApiUploadRequestBody = (): PostCompanyImportSettingsRequestBody => {
    const seriesList = seriesListValues.map(item => item.value).join(',');
    return {
      IntegrationName: createUserIntegrationOptions.title,
      Description: createUserIntegrationOptions.description,
      HasHeaderRow: false,
      CreateDependentData: missingFields,
      UniqueUsernames: false,
      IsPGPEncrypted: false,
      IsEnabled: createUserIntegrationOptions.isEnabled,
      UserNameColumnIndex: 1, // required
      UserStatusNameColumnIndex: 1, // required
      FirstNameColumnIndex: 1, // required
      LastNameColumnIndex: 1, // required
      EmailColumnIndex: 1, // required
      MiddleNameColumnIndex: createUserIntegrationOptions.dropDownOptions.middleName.columnNumber,
      UserName2ColumnIndex: createUserIntegrationOptions.dropDownOptions.username2.columnNumber,
      RoleColumnIndex: createUserIntegrationOptions.dropDownOptions.role.columnNumber,
      SeriesColumnIndex: null,
      DepartmentColumnIndex: createUserIntegrationOptions.dropDownOptions.department.columnNumber,
      SupervisorUsernameColumnIndex:
        createUserIntegrationOptions.dropDownOptions.supervisorUsername.columnNumber,
      CompanySiteColumnIndex: createUserIntegrationOptions.dropDownOptions.companySite.columnNumber,
      CompanyCostCenterColumnIndex:
        createUserIntegrationOptions.dropDownOptions.costCenter.columnNumber,
      HireDateColumnIndex: createUserIntegrationOptions.dropDownOptions.hireDate.columnNumber,
      CompanyNameColumnIndex: createUserIntegrationOptions.dropDownOptions.companyName.columnNumber,
      Supervisor2UsernameColumnIndex:
        createUserIntegrationOptions.dropDownOptions.supervisor2Username.columnNumber,
      DefaultCompanyId: companyId,
      CompanyIdColumnIndex: null,
      JobTitleColumnIndex: null,
      DefaultRoleId: defaultRoleValue?.value,
      SeriesIds: seriesList,
      ActiveStatus: '',
      PrimaryUserLookupField: '',
      UseSecondaryUserLookupField: false,
      LeadingZerosFormat: createUserIntegrationOptions.leadingZeros.itemId,
      PrependFormat: createUserIntegrationOptions.prependUsername,
      MakeTaskProfilingAssignments: makeTaskProfilingAssignments,
      ExceptionReportEmailRecipientIds: '',
      HasMultipleCompanies: false,
      Type: CompanyUserImportSettingsType.API,
    };
  };

  const getRequestBody = (): PostCompanyImportSettingsRequestBody => {
    return fileUploadType === CompanyUserImportSettingsType.SftpFileTransfer
      ? getFileUploadRequestBody()
      : getApiUploadRequestBody();
  };

  function handleSave() {
    const body = getRequestBody();

    const result = createCompanyUserImportBodyValidator.validate(body);
    setValidationResult({ isValid: result.isValid, errors: result.invalidPropertyNames });

    if (result.isValid) {
      companyImportSettingsMutation.mutate(body);
      setShowSaveAlert(true);
      setFormIsDirty(false);
    }
  }

  const onComplete = (results: ParseResult<string[]>) => {
    setCreateUserIntegrationOptions(prevState => ({
      ...prevState,
      dropDownOptions: defaultCreateUserIntegrationOptions.dropDownOptions,
    }));
    setHeaders(results.data[0]);
    setReadOnly(false);
  };

  function handleOnDrop(acceptedFiles: File[]): void {
    setFormIsDirty(true);
    parse<string[]>(acceptedFiles[0], {
      complete: onComplete,
    });
  }

  function handleDropRejection(): void {
    setFileDropError(true);
  }

  function handleRejectionToastDismiss(): void {
    setFileDropError(false);
  }

  function handlePrependUsernameChange(prependUsername: string) {
    setFormIsDirty(true);
    setCreateUserIntegrationOptions(prevState => ({ ...prevState, prependUsername }));
  }

  function handleLeadingZerosChange(leadingZeros: DisplayItem) {
    setFormIsDirty(true);
    setCreateUserIntegrationOptions(prevState => ({ ...prevState, leadingZeros }));
  }

  function handleTitleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFormIsDirty(true);
    setCreateUserIntegrationOptions(prevState => ({ ...prevState, title: event.target.value }));
  }

  function handleIsEnabledChange() {
    setFormIsDirty(true);
    setCreateUserIntegrationOptions(prevState => ({
      ...prevState,
      isEnabled: !prevState.isEnabled,
    }));
  }

  function handleDescriptionChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFormIsDirty(true);
    setCreateUserIntegrationOptions(prevState => ({
      ...prevState,
      description: event.target.value,
    }));
  }

  function handleSelectionChange(option: MappingOption): void {
    setFormIsDirty(true);
    const updatedDropDownSelections = { ...createUserIntegrationOptions.dropDownOptions };
    updatedDropDownSelections[option.dataPointId] = { ...option };

    setCreateUserIntegrationOptions(prevState => ({
      ...prevState,
      dropDownOptions: updatedDropDownSelections,
    }));
  }

  function validateTitle(input: string) {
    return input.match(/^ *$/) !== null;
  }

  function handleOnGenerateSftpCredentials(): void {
    createCompanySftpUserMutation.mutate(companyId);
  }

  function handleOnRegenerateSftpCredentials(): void {
    regenerateCompanySftpuserMutation.mutate({ companyId, companySftpId: companySftpUsers[0].Id });
  }

  function handleOnChangeCheckbox(
    dataPointId: UserCreationDataPointId,
    dataPointLabel: UserCreationDataPointLabel,
    isChecked: boolean
  ): void {
    setFormIsDirty(true);
    const updatedDropDownSelections = { ...createUserIntegrationOptions.dropDownOptions };
    const option: MappingOption = {
      columnName: '',
      columnNumber: isChecked ? 1 : null,
      dataPointId,
      dataPointLabel,
      isRequired: false,
    };
    updatedDropDownSelections[option.dataPointId] = { ...option };

    setCreateUserIntegrationOptions(prevState => ({
      ...prevState,
      dropDownOptions: updatedDropDownSelections,
    }));
  }

  function handleSeriesCheckboxChange(
    newValue: MultiValue<{
      value: number;
      label: string;
    }>
  ) {
    setFormIsDirty(true);
    setSeriesListValues(newValue);
  }

  function handleHasHeaderRowChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFormIsDirty(true);
    setHasHeaderRow(event.target.checked);
  }

  function handleMakeTaskProfilingAssignmentsChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFormIsDirty(true);
    setMakeTaskProfilingAssignments(event.target.checked);
  }

  function handleMissingFieldsAssignmentsChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFormIsDirty(true);
    setMissingFields(event.target.checked);
  }

  function handleDefaultRole(
    newValue: SingleValue<{
      value: number | undefined;
      label: string;
    }>
  ) {
    setFormIsDirty(true);
    const selected = newValue?.value;
    if (newValue && selected !== undefined) {
      if (selected !== defaultRoleValue?.value) {
        setDefaultRoleValue(newValue);
      }
    } else {
      setDefaultRoleValue(undefined);
    }
  }

  const navigateToAnalytics = () => {
    navigateTo(routes.integrationAnalytics.url());
  };

  const handleDialogClose = (response: boolean) => {
    if (!response) return;
    navigateToAnalytics();
  };

  const onCloseClick = () => {
    if (formIsDirty) {
      setOpenDialog(true);
    } else {
      navigateToAnalytics();
    }
  };

  const onEditMapping = () => {
    setFormIsDirty(true);
    setCreateUserIntegrationOptions(prevState => ({
      ...prevState,
      dropDownOptions: defaultDropDownSelections,
    }));
    setReadOnly(false);
  };

  const handleToastDismiss = () => setShowToast(false);

  return (
    <MainLayout
      isCompanyLoading={isCompanyLoading}
      policies={{
        canViewCustomView: true,
        canViewTaskProfile: true,
        canViewActivities: true,
        canViewIntegrationHub: true,
        canViewLearningHistory: true,
      }}
      companyImage={companyImage}
      companyName={companyName}
    >
      <TopNavbar
        reportName={`${navbarTitle} User Integration`}
        user={user}
        isUserLoading={isUserLoading}
      />
      <Container className="py-3">
        {showToast && (
          <div
            className="position-absolute mx-auto"
            style={{ zIndex: 10000, left: '50%', transform: 'translate(-50%,0)' }}
            data-testid={testIds.toastContainer()}
          >
            <ToastMessage
              className="top-0 start-50 translate-middle-x bg-white"
              iconColor="danger"
              headerText={commonText.userIntegrationNotFoundHeader}
              bodyText={commonText.userIntegrationNotFoundBody}
              toggle={handleToastDismiss}
            />
          </div>
        )}
        {fileDropError && (
          <div
            className="position-absolute mx-auto"
            style={{ zIndex: 10000, left: '50%', transform: 'translate(-50%,0)' }}
            data-testid={testIds.toastContainer()}
          >
            <ToastMessage
              className="top-0 start-50 bg-white"
              iconColor="danger"
              headerText={messages.fileUploadError()}
              bodyText={messages.mappingFileSelectionError()}
              toggle={handleRejectionToastDismiss}
            />
          </div>
        )}
        <ConfirmDialog
          isDialogOpen={openDialog}
          setIsDialogOpen={setOpenDialog}
          headerText={commonText.leaveIntegrationHeader}
          bodyText={commonText.leaveIntegrationText}
          onClose={handleDialogClose}
          confirmText={commonText.close}
          confirmButtonVariant="danger"
          cancelButtonVariant="primary"
        />
        <Row className="justify-content-center">
          {isInitialLoadingComplete ? null : (
            <Col lg={8} md={10} sm={12}>
              <GeneralConfigurationCard
                createUserIntegrationOptions={createUserIntegrationOptions}
                handleDescriptionChange={handleDescriptionChange}
                handleTitleChange={handleTitleChange}
                validateTitle={validateTitle}
                handleIsEnabledChange={handleIsEnabledChange}
              />
              {fileUploadType === CompanyUserImportSettingsType.SftpFileTransfer && (
                <MappingCard
                  onDropAccepted={handleOnDrop}
                  onDropRejected={handleDropRejection}
                  dropDownSelections={createUserIntegrationOptions.dropDownOptions}
                  onDropdownSelection={handleSelectionChange}
                  optionHeaders={headers}
                  dragAndDropInputTestId={dragAndDropInputTestId}
                  readOnly={readOnly}
                  onEditMapping={onEditMapping}
                />
              )}
              {fileUploadType === CompanyUserImportSettingsType.API && (
                <ApiMappingCard
                  checkBoxSelections={createUserIntegrationOptions.dropDownOptions}
                  handleOnChange={handleOnChangeCheckbox}
                />
              )}
              <ConfigurationCard
                availableSeriesList={availableSeriesList}
                handleOnChange={handleSeriesCheckboxChange}
                handleHasHeaderRowOnChange={handleHasHeaderRowChange}
                handleMakeTaskProfilingAssignmentsOnChange={
                  handleMakeTaskProfilingAssignmentsChange
                }
                fileUploadType={fileUploadType}
                availableRolesList={availableRolesList}
                handleDefaultRoleOnChange={handleDefaultRole}
                leadingZeros={createUserIntegrationOptions.leadingZeros}
                handleLeadingZerosChange={handleLeadingZerosChange}
                handleMissingFieldsAssignmentsChange={handleMissingFieldsAssignmentsChange}
                handlePrependUsernameChange={handlePrependUsernameChange}
                hasHeaderRow={hasHeaderRow}
                missingFields={missingFields}
                defaultRoleValue={defaultRoleValue}
                seriesAccessValues={seriesListValues}
                makeTaskProfilingAssignments={makeTaskProfilingAssignments}
                prependUsernameValue={createUserIntegrationOptions.prependUsername}
                initialAdvancedConfigurationToogleState={advancedConfigurationState}
              />
              {fileUploadType === CompanyUserImportSettingsType.SftpFileTransfer && (
                <UserCredentialsCard
                  companySftpUsers={companySftpUsers}
                  onGenerateSftpCredentials={handleOnGenerateSftpCredentials}
                  onRegenerateSftpCredentials={handleOnRegenerateSftpCredentials}
                  isCreateSftpCredentialsLoading={createCompanySftpUserMutation.isLoading}
                  isRegenerateSftpCredentialsLoading={regenerateCompanySftpuserMutation.isLoading}
                  privateKey={privateKey}
                />
              )}
              <div className="mt-4 ms-auto d-flex">
                <div>
                  <Button className="me-2" variant="danger" size="lg" onClick={onCloseClick}>
                    {commonText.close}
                  </Button>
                  <Button
                    className="me-2"
                    variant="primary"
                    size="lg"
                    onClick={handleSave}
                    disabled={companyImportSettingsMutation.isLoading}
                  >
                    {companyImportSettingsMutation.isLoading && (
                      <Spinner className="me-2" variant="secondary" size="sm" />
                    )}
                    {commonText.save}
                  </Button>
                </div>
                <Alert
                  className="text-light pb-0"
                  variant="danger"
                  dismissible
                  closeVariant="white"
                  show={!validationResult.isValid}
                  onClose={() =>
                    setValidationResult(prevState => ({ ...prevState, isValid: true }))
                  }
                >
                  <h5 className="border-bottom border-light">Required Properties</h5>
                  <p>{validationResult.errors.join(', ')}</p>
                </Alert>
                {companyImportSettingsMutation.isSuccess && (
                  <Alert
                    className="mb-0"
                    dismissible
                    variant="success"
                    show={showSaveAlert}
                    onClose={() => setShowSaveAlert(false)}
                  >
                    {messages.actionSuccessful(commonText.save)}
                  </Alert>
                )}
                {companyImportSettingsMutation.isError && (
                  <Alert
                    className="mb-0"
                    dismissible
                    variant="danger"
                    show={showSaveAlert}
                    onClose={() => setShowSaveAlert(false)}
                  >
                    {messages.actionFailed(commonText.save)}
                  </Alert>
                )}
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </MainLayout>
  );
};

export default CreateUserIntegration;
