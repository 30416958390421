import { CompaniesCompanySiteResponse } from '../../../api/responses/companiesCompanySiteResponse';
import { CompaniesCostCentersResponse } from '../../../api/responses/companiesCostCentersResponse';
import { CompaniesDepartmentsResponse } from '../../../api/responses/companiesDepartmentsResponse';
import { CompaniesJobsResponse } from '../../../api/responses/companiesJobsResponse';
import { CompaniesRolesResponse } from '../../../api/responses/companiesRolesResponse';
import { CompaniesSupervisorsResponse } from '../../../api/responses/companiesSupervisorsResponse';
import { CompaniesUsersResponse } from '../../../api/responses/companiesUsersResponse';
import { CompanySeriesResponse } from '../../../api/responses/companySeriesResponse';
import { CompanySeriesTaskResponse } from '../../../api/responses/companySeriesTaskResponse';
import { CompanySftpUserResponse } from '../../../api/responses/getCompanySftpUserResponse';
import { ReasonsResponse } from '../../../api/responses/reasonsResponse';
import { UserResponse } from '../../../api/responses/userResponse';
import CustomViewAccessType from '../../../models/customViewAccessType';
import { DisplayItem } from '../../../models/displayItem';
import { DisplayItemWithCustomViewAccessType } from '../../../models/displayItemWithCustomViewAccessType';

const getUserDisplay = (user: { userId: number; userName: string; fullName: string }): string => {
  return `${user.fullName} (${user.userName} | ${user.userId})`;
};

const getAssignerDisplay = (user: {
  userId: number;
  userName: string;
  fullName: string;
  companyName?: string;
}): string => {
  return `${user.fullName} (${user.userName} | ${user.userId}) - ${user.companyName}`;
};

export default {
  convertCompaniesCostCentersResponse(data: CompaniesCostCentersResponse): DisplayItem[] {
    return data.costCenters.map(({ id, name }) => ({
      itemId: id,
      display: name,
    }));
  },
  convertCompaniesDepartmentsResponse(data: CompaniesDepartmentsResponse): DisplayItem[] {
    return data.departments.map(({ id, name }) => ({
      itemId: id,
      display: name,
    }));
  },
  convertCompaniesUsersResponse(data: CompaniesUsersResponse): DisplayItem[] {
    return data.users.map(({ userId, userName, fullName }) => ({
      itemId: userId,
      display: getUserDisplay({ userId, userName, fullName }),
    }));
  },
  convertSharedCompaniesUsersResponse(
    data: CompaniesUsersResponse
  ): DisplayItemWithCustomViewAccessType[] {
    return data.users.map(({ userId, userName, fullName }) => ({
      itemId: userId,
      display: getUserDisplay({ userId, userName, fullName }),
      customViewAccessType: CustomViewAccessType.ViewOnly,
    }));
  },
  convertCompaniesRolesResponse(data: CompaniesRolesResponse): DisplayItem[] {
    return data.roles.map(({ roleId, roleName }) => ({
      itemId: roleId ?? 0,
      display: roleName,
    }));
  },
  convertCompaniesSupervisorsResponse(data: CompaniesSupervisorsResponse): DisplayItem[] {
    return data.supervisors.map(({ userId, userName, fullName }) => ({
      itemId: userId,
      display: getUserDisplay({ userId, userName, fullName }),
    }));
  },
  convertCompaniesCompanySiteResponse(data: CompaniesCompanySiteResponse): DisplayItem[] {
    return data.companySites.map(({ id, name }) => ({
      itemId: id,
      display: name,
    }));
  },
  convertCompaniesJobsResponse(data: CompaniesJobsResponse): DisplayItem[] {
    return data.jobs.map(({ id, title }) => ({
      itemId: id,
      display: title,
    }));
  },
  convertCompanyAssignersResponse(data: UserResponse[]): DisplayItem[] {
    return data.map(({ userId, userName, fullName, companyName }) => ({
      itemId: userId,
      display: getAssignerDisplay({ userId, userName, fullName, companyName }),
    }));
  },
  convertCompanyEvaluatorsResponse(data: UserResponse[]): DisplayItem[] {
    return data.map(({ userId, userName, fullName, companyName }) => ({
      itemId: userId,
      display: getAssignerDisplay({ userId, userName, fullName, companyName }),
    }));
  },
  convertCompanySeriesResponse(data: CompanySeriesResponse): DisplayItem[] {
    return data.series.map(({ id, name }) => ({
      itemId: id ?? 0,
      display: name,
    }));
  },
  convertCompanySeriesTasksResponse(data: CompanySeriesTaskResponse): DisplayItem[] {
    return data.tasks.map(({ id, name, code }) => ({
      itemId: id,
      display: `${code}: ${name}`,
    }));
  },

  convertCompanySftpUserResponse(data: CompanySftpUserResponse): DisplayItem {
    return {
      itemId: data.Id,
      display: `${data.SftpUsername}`,
    };
  },

  convertQualificationsReasons(data: ReasonsResponse): DisplayItem[] {
    return Object.entries(data).map(([, value], index: number) => {
      return {
        itemId: index,
        display: value,
      };
    });
  },
};
