import { SyntheticEvent } from 'react';

function preventDefault<T = Element>(e: SyntheticEvent<T>): void {
  e.preventDefault();
}

function stopPropagation<T = Element>(e: SyntheticEvent<T>): void {
  e.stopPropagation();
}

export default {
  preventDefault,
  stopPropagation,
};
