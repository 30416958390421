function parse(isIncomplete: boolean, totalCompleted: number, total: number): string {
  return isIncomplete ? `${total - totalCompleted}` : `${totalCompleted}/${total}`;
}

function parseJobStatistics(isIncomplete: boolean, totalCompleted: number, total: number): string {
  return isIncomplete ? `${total - totalCompleted}` : `${totalCompleted}`;
}

export default {
  parse,
  parseJobStatistics,
};
