import React from 'react';
import { TabsProps as RBTabsProps } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

export interface TabsProps extends RBTabsProps {
  isVertical: boolean;
  tabs: React.ReactNode;
  tabPanes: React.ReactNode;
  activeKey: string | number;
  tabRowContainerClassname?: string;
  tabContentRowContainerClassname?: string;
  tabContentClassName?: string;
}

const Tabs: React.FC<TabsProps> = ({
  isVertical,
  tabs,
  tabPanes,
  activeKey,
  tabRowContainerClassname = '',
  tabContentRowContainerClassname = '',
  tabContentClassName = '',
}) => {
  return (
    <Tab.Container activeKey={activeKey}>
      <Row className={tabRowContainerClassname}>
        <Col sm={isVertical ? 2 : 12} className="p-0">
          <Nav variant="tabs" fill className={`${isVertical ? 'nav-stacked flex-column' : ''}`}>
            {tabs}
          </Nav>
        </Col>
        <Col sm={isVertical ? 10 : 12} className={`bg-white ${tabContentRowContainerClassname}`}>
          <Tab.Content className={tabContentClassName}>{tabPanes}</Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default Tabs;
