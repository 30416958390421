import React, { FC } from 'react';
import testIds from '../../../../constants/testIds';
import { CompletionStatus } from '../../../CompletionStatus/CompletionStatus';
import { FaExclamationCircle } from 'react-icons/fa';

export interface TaskProfileDetailWePeListItemProps {
  name: string;
  requiredStatusText: string;
  isComplete: boolean;
  listItemId: number;
  hasSeriesAccess: boolean;
}

const TaskProfileDetailWePeListItem: FC<TaskProfileDetailWePeListItemProps> = ({
  name,
  requiredStatusText,
  isComplete,
  listItemId,
  hasSeriesAccess,
}) => (
  <div
    className="d-flex justify-content-center align-items-center flex-fill px-3 pt-3"
    data-testid={testIds.taskProfileDetailIncompleteWePe(listItemId)}
  >
    <div className="flex-grow-1">
      <div className="d-flex flex-row">
        <div style={{ marginRight: 10 }}>
          {name}
          <span className="px-1">|</span>
          {requiredStatusText}
        </div>
        {!hasSeriesAccess && <div style={{ color: 'red' }}>Needs Series Access</div>}
      </div>
    </div>
    <span className="flex-shrink-0">
      {!hasSeriesAccess && <FaExclamationCircle size={22} color="red" />}
      <CompletionStatus isCompleted={isComplete} />
    </span>
  </div>
);

export default TaskProfileDetailWePeListItem;
