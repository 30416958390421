import apiHooks from '../api/apiHooks';
import { UserInformation } from '../api/responses/userInformation';
import { OneHourInMilliseconds } from '../constants/measurements';
import useUserId from './useUserId';

export interface UserInformationHook {
  user?: UserInformation;
  isLoading: boolean;
}

export default function useUserInformation(): UserInformationHook {
  const { userId } = useUserId();
  const { data, isLoading } = apiHooks.useUserInformation(userId, {
    cacheTime: OneHourInMilliseconds,
    staleTime: OneHourInMilliseconds,
  });

  return {
    user: data,
    isLoading,
  };
}
