import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
import commonText from '../../../constants/commonText';
import testIds from '../../../constants/testIds';
import { DisplayItem } from '../../../models/displayItem';
import Button from '../../Buttons/Button/Button';
import Dropdown from '../../Dropdown/Dropdown';
import SearchBox from '../../SearchBox/SearchBox';
import ListItem from '../ListItem/ListItem';

interface MultiPartSelectWithDropdownBaseProps<
  T extends DisplayItem = DisplayItem,
  U extends DisplayItem = DisplayItem
> {
  activeDropdownOption: DisplayItem | undefined;
  availableListGroupStyle?: React.CSSProperties;
  emptyListText: string;
  dropdownItems: T[];
  listItems: U[];
  isDropdownLoading: boolean;
  isListLoading: boolean;
  selectedItems: Record<number, T>;
  selectedItemsIds: number[];
  selectedListGroupClassName?: string;
  selectedItemTemplate: (item: DisplayItem) => React.ReactNode;
  onItemSelection: (item: DisplayItem) => void;
  onClear: () => void;
  onSearch: (text: string) => void;
  onSelectAll: () => void;
  onDropdownChanged: (option: DisplayItem) => void;
  dropdownLabel: string;
  emptyDropdownLabel: string;
  tabId: string;
}

const MultiPartSelectWithDropdownBase: React.FC<MultiPartSelectWithDropdownBaseProps> = ({
  activeDropdownOption,
  availableListGroupStyle,
  emptyListText,
  dropdownItems,
  listItems,
  isDropdownLoading,
  isListLoading,
  selectedItems,
  selectedItemsIds,
  selectedListGroupClassName,
  selectedItemTemplate,
  onClear,
  onDropdownChanged,
  onItemSelection,
  onSearch,
  onSelectAll,
  dropdownLabel,
  emptyDropdownLabel,
  tabId,
}) => {
  const emptyStateText = isListLoading ? (
    <Spinner color="secondary" role="status" />
  ) : (
    emptyListText
  );

  return (
    <>
      <div className="pt-3 ms-1">
        <SearchBox onChange={onSearch} />
      </div>
      <div className="d-inline-flex my-1 align-items-center ms-1">
        <div className="fw-bold">{dropdownLabel}:</div>
        <Dropdown<DisplayItem>
          emptyListLabel={emptyDropdownLabel}
          title={activeDropdownOption?.display || emptyDropdownLabel}
          items={dropdownItems}
          isLoading={isDropdownLoading}
          itemKey={(item: DisplayItem) => item.itemId}
          itemLabel={(item: DisplayItem) => item.display}
          onItemSelect={onDropdownChanged}
          variant="transparent"
          dropdownTestId={testIds.multiPartSelectWithDropdown()}
          selectedItem={activeDropdownOption?.itemId}
        />
      </div>
      <div className="my-1">
        <Button
          size="sm"
          variant="transparent"
          disabled={selectedItemsIds.length <= 0}
          onClick={onClear}
        >
          {commonText.clearAll}
        </Button>
        <Button size="sm" variant="transparent" onClick={onSelectAll}>
          {commonText.selectAll}
        </Button>
      </div>
      <ListGroup
        className={selectedListGroupClassName}
        style={{ maxHeight: 'calc(50% - 50px)' }}
        horizontal
        aria-label="selected"
        data-testid={testIds.multiPartSelectSelectedItemsContainer()}
      >
        {Object.values(selectedItems).map(selectedItemTemplate)}
      </ListGroup>
      {Object.values(selectedItems).length > 0 ? <hr className="w-100" /> : null}
      {listItems.length > 0 ? (
        <ListGroup
          className="overflow-auto flex-fill"
          id={`${tabId}-panel`}
          style={availableListGroupStyle}
          as="ul"
          aria-label="available"
          data-testid={testIds.multiSelectWithDropdown()}
        >
          {listItems.map(listItem =>
            selectedItems[listItem.itemId] === undefined ? (
              <ListItem
                key={listItem.itemId}
                onClick={() => onItemSelection(listItem)}
                text={listItem.display}
              />
            ) : null
          )}
        </ListGroup>
      ) : (
        <div className="ms-3">{emptyStateText}</div>
      )}
    </>
  );
};

export default MultiPartSelectWithDropdownBase;
