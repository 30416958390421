import { RefObject, useRef, useState } from 'react';

const useCopyButton = (
  textToCopy: string
): {
  handleOnCopyButtonClick: () => void;
  copyButtonRef: RefObject<HTMLButtonElement>;
  isToolTipOpen: boolean;
  keyTimeoutId: NodeJS.Timeout | undefined;
} => {
  const [isToolTipOpen, setIsToolTipOpen] = useState(false);
  const [keyTimeoutId, setKeyTimeoutId] = useState<ReturnType<typeof setTimeout>>();
  const copyButtonRef = useRef<HTMLButtonElement>(null);

  function handleOnCopyButtonClick() {
    if (keyTimeoutId !== undefined) {
      clearTimeout(keyTimeoutId);
      setKeyTimeoutId(undefined);
    }

    navigator.clipboard.writeText(textToCopy);
    setIsToolTipOpen(true);
    const timer = setTimeout(() => {
      setIsToolTipOpen(false);
    }, 3000);

    setKeyTimeoutId(timer);
  }

  return {
    handleOnCopyButtonClick,
    copyButtonRef,
    isToolTipOpen,
    keyTimeoutId,
  };
};

export default useCopyButton;
