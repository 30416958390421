import commonText from '../constants/commonText';
import CompanyUserImportSettingsType from './CompanyUserImportSettingsType';
import CurrentIntegrationsResponse from './CurrentIntegrationsResponse';

const DefaultAPIIntegration: CurrentIntegrationsResponse = {
  DateEditedUtc: null,
  Id: -1,
  IntegrationName: commonText.userAPI,
  IsEnabled: false,
  Type: CompanyUserImportSettingsType.API,
};

export default DefaultAPIIntegration;
