import React from 'react';
import Zoom from '../../../zoom/Zoom';
import Button from '../../Buttons/Button/Button';
import Fullscreen from '../../Icons/Fullscreen';
import TopNavbar, { TopNavBarProps } from '../../Layout/TopNavBar/TopNavBar';
import SearchBox from '../../SearchBox/SearchBox';

export interface TaskProfileTopNavBarProps extends TopNavBarProps {
  isFullscreenActive: boolean;
  onSearch: (text: string) => void;
}

const TaskProfileTopNavBar: React.FC<TaskProfileTopNavBarProps> = ({
  isFullscreenActive,
  reportName,
  onSearch,
  user,
  isUserLoading,
}) => {
  return (
    <TopNavbar
      reportName={reportName}
      isSidebarButtonActive={!isFullscreenActive}
      user={user}
      isUserLoading={isUserLoading}
    >
      <div className="d-inline-flex align-items-center pe-1">
        <SearchBox size="sm" onChange={onSearch} />
        <Zoom className="w-auto mx-2" />
        {isFullscreenActive || !document.fullscreenEnabled ? null : (
          <Button
            className="bg-transparent align-self-end p-0"
            onClick={async () => document.documentElement.requestFullscreen()}
            variant="transparent"
          >
            <Fullscreen />
          </Button>
        )}
      </div>
    </TopNavbar>
  );
};

export default TaskProfileTopNavBar;
