import { useEffect, useState } from 'react';
import { DisplayItem } from '../models/displayItem';
import { UseDisplayItemsResponse } from '../models/useDisplayItemsResponse';

function useStatus(companyId: number, searchText: string): UseDisplayItemsResponse {
  useEffect(() => {
    const dataList: DisplayItem[] = [
      {
        itemId: 5,
        display: 'Passed',
      },
      {
        itemId: 4,
        display: 'Completed',
      },
      {
        itemId: 3,
        display: 'Failed',
      },
      {
        itemId: 2,
        display: 'In Progress',
      },
      //   {
      //     itemId: 1,
      //     display: 'Browsed',
      //   },
      // {
      //   itemId: 0,
      //   display: 'Not Completed', // Not Attempted in the DB
      // },
    ];
    setData(dataList.filter(ct => ct.display.includes(searchText)));
  }, [searchText]);

  const [data, setData] = useState<DisplayItem[]>([]);

  return { data, isLoading: false };
}

export default useStatus;
