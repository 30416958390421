import commonText from '../../constants/commonText';
import { TaskProfileReportData } from '../../models/taskProfileReportData';

function parse(reportData: TaskProfileReportData): string {
  const filters = [];

  if (reportData.userIds.length > 0) {
    filters.push(`${commonText.employees} (${reportData.userIds.length})`);
  }

  if (reportData.costCenterIds.length > 0) {
    filters.push(`${commonText.costCenters} (${reportData.costCenterIds.length})`);
  }

  if (reportData.departmentIds.length > 0) {
    filters.push(`${commonText.departments} (${reportData.departmentIds.length})`);
  }

  if (reportData.jobIds.length > 0) {
    filters.push(`${commonText.jobs} (${reportData.jobIds.length})`);
  }

  if (reportData.roleIds.length > 0) {
    filters.push(`${commonText.roles} (${reportData.roleIds.length})`);
  }

  if (reportData.supervisorIds.length > 0) {
    filters.push(`${commonText.supervisors} (${reportData.supervisorIds.length})`);
  }

  if (reportData.workLocationIds.length > 0) {
    filters.push(`${commonText.workLocations} (${reportData.workLocationIds.length})`);
  }

  return filters.length > 0 ? filters.join(' | ') : 'No filters selected';
}

export default { parse };
