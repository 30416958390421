import { useMemo } from 'react';
import apiHooks, { ApiResult } from '../../../api/apiHooks';
import { GetCustomViewAccessUsersResponse } from '../../../api/responses/getCustomViewAccessUsersResponse';
import useUserId from '../../../hooks/useUserId';
import CustomViewAccessType from '../../../models/customViewAccessType';

const defaultCustomViewAccessUsersResponse = (
  userId: number
): ApiResult<GetCustomViewAccessUsersResponse> => ({
  data: {
    userAccessResponses: [{ userId, customViewAccessType: CustomViewAccessType.Owner }],
  },
  isError: false,
  isLoading: false,
  isSuccess: true,
  isFetching: false,
});

function useCustomViewAccessUsers(
  companyId: number,
  customViewId: number,
  isEditing: boolean
): ApiResult<GetCustomViewAccessUsersResponse> {
  const customViewAccessUsersResult = apiHooks.useGetCustomViewAccessUsers(
    { companyId, customViewId },
    { enabled: isEditing }
  );
  const { userId } = useUserId();

  const defaultResponse = useMemo(() => {
    return defaultCustomViewAccessUsersResponse(userId);
  }, [userId]);

  if (isEditing) {
    return customViewAccessUsersResult;
  }

  return defaultResponse;
}

export default useCustomViewAccessUsers;
