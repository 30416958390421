import commonText from '../constants/commonText';
import CompanyUserImportSettingsType from './CompanyUserImportSettingsType';
import CurrentIntegrationsResponse from './CurrentIntegrationsResponse';

const DefaultFileIntegration: CurrentIntegrationsResponse = {
  DateEditedUtc: null,
  Id: -1,
  IntegrationName: commonText.userFile,
  IsEnabled: false,
  Type: CompanyUserImportSettingsType.SftpFileTransfer,
};

export default DefaultFileIntegration;
