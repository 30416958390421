import React from 'react';
import Image from '../../Image/Image';
import Skeleton from '../../Skeleton/Skeleton';

export interface ImageNavLinkProps {
  image: string;
  imageAltText: string;
  name: string;
  isLoading: boolean;
}

const ImageNavLink: React.FC<ImageNavLinkProps> = ({ image, imageAltText, name, isLoading }) => {
  return (
    <div className="image-nav-link">
      <div className="image-nav-link-logo">
        {isLoading ? (
          <Skeleton height={34} />
        ) : (
          <Image className="img-cover" src={image} alt={imageAltText} />
        )}
      </div>
      <span className="image-nav-link-name">{isLoading ? <Skeleton /> : name}</span>
    </div>
  );
};

export default ImageNavLink;
