import React from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

export interface PillButtonWithLabelAndIconProps {
  label: string;
  icon: React.ReactElement;
  buttonColor: string;
  iconBackgroundColor: string;
  iconFront?: boolean;
  isLoading?: boolean;
  className?: string;
  size?: 'sm' | 'lg';
  onClick: () => void;
}

const ButtonWithLabelAndIcon: React.FC<PillButtonWithLabelAndIconProps> = ({
  label,
  icon,
  buttonColor,
  iconBackgroundColor,
  iconFront = false,
  isLoading = false,
  className,
  size,
  onClick,
}) => {
  function getIcon(isIconLoading: boolean, displayIcon: React.ReactElement): React.ReactElement {
    if (isIconLoading) {
      return (
        <div
          className={`rounded-circle bg-primary p-1 my-auto d-flex justify-content-center align-items-center ${iconBackgroundColor}`}
        >
          <Spinner className="mr-2" color="secondary" size="sm" />
        </div>
      );
    }

    return (
      <h4
        className={`rounded-circle bg-primary p-1 my-auto d-flex justify-content-center align-items-center ${iconBackgroundColor}`}
        style={{ width: '1.75rem', height: '1.75rem' }}
      >
        {displayIcon}
      </h4>
    );
  }

  return (
    <Button
      className={`btn-icon ${className}`}
      variant={buttonColor}
      size={size}
      aria-label={`${label} Button`}
      onClick={onClick}
      disabled={isLoading}
    >
      {iconFront && getIcon(isLoading, icon)}
      <span className="my-auto mx-1 text-nowrap text-left flex-fill">{label}</span>
      {!iconFront && getIcon(isLoading, icon)}
    </Button>
  );
};

export default ButtonWithLabelAndIcon;
