import { TaskProfileSortByOptions } from '../api/requests/companyQualificationRequest';
import {
  getPrimaryDataPointCardDisplayOption,
  TaskProfileCardPrimaryDataPoint,
} from '../components/TaskProfile/ReportDrawer/taskProfileCardPrimaryDataPoints';
import { TaskProfileDetailDisplayModes } from '../components/TaskProfile/ReportDrawer/taskProfileDetailDisplayModes';
import { ThirtySecondsInMilliseconds } from '../constants/measurements';
import { TaskProfileReportData } from './taskProfileReportData';

const primaryDataPoint = TaskProfileCardPrimaryDataPoint.roles;

const primaryDataPointCardDisplayOption = getPrimaryDataPointCardDisplayOption(primaryDataPoint);

const defaultTaskProfileReportData: TaskProfileReportData = {
  sortBy: TaskProfileSortByOptions.lastnameASC.sortByParams,
  userIds: [],
  costCenterIds: [],
  departmentIds: [],
  jobIds: [],
  roleIds: [],
  supervisorIds: [],
  workLocationIds: [],
  primaryDataPoint,
  cardDisplayOptions: {
    username: true,
    userId: true,
    note: false,
    roles: false,
    jobs: false,
    tasks: true,
    training: false,
    wePe: false,
    viewAsIncomplete: false,
    [primaryDataPointCardDisplayOption.id]: true,
  },
  detailDisplayModes: {
    jobsAssocMethod: TaskProfileDetailDisplayModes.jobsAssocMethod[0].id,
  },
  detailDisplayOptions: {
    incompleteTasks: true,
    completeTasks: true,
    roles: true,
    jobs: true,
    incompleteWePe: true,
    completeWePe: true,
    incompleteTrainings: true,
    completeTrainings: true,
  },
  messageOfTheDay: {
    title: '',
    description: '',
    image: '',
    isEnabled: false,
    isShownWhenInactive: false,
  },
  scrollingOptions: {
    isScrolling: false,
    scrollingIntervalMilliseconds: ThirtySecondsInMilliseconds,
  },
};

export default defaultTaskProfileReportData;
