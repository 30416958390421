import React from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import testIds from '../../../constants/testIds';
import { CreateUserIntegrationOptions } from '../../../pages/CreateUserIntegration/defaultCreateUserIntegrationOptions';
import Switch from '../../Switch/Switch';

interface GeneralConfigurationCardProps {
  createUserIntegrationOptions: CreateUserIntegrationOptions;
  handleDescriptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleTitleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validateTitle: (title: string) => boolean;
  handleIsEnabledChange: () => void;
}

const GeneralConfigurationCard = ({
  createUserIntegrationOptions,
  handleDescriptionChange,
  handleTitleChange,
  validateTitle,
  handleIsEnabledChange,
}: GeneralConfigurationCardProps): React.ReactElement => {
  return (
    <div>
      <Card className="me-auto" data-testid={testIds.generalConfiguration()}>
        <Card.Header className="bg-gray">
          <h5 className="fw-bold">General Configuration</h5>
        </Card.Header>
        <Card.Body>
          <div className="d-flex flex-row">
            <div>Title</div>
            <div className="text-danger ms-1">*</div>
          </div>
          <Form.Control
            className={`${
              validateTitle(createUserIntegrationOptions.title)
                ? 'border-danger'
                : 'border-secondary'
            }`}
            aria-label="Title"
            placeholder="userIntegration"
            onChange={handleTitleChange}
            value={createUserIntegrationOptions.title}
          />
          <div className="mt-2">Description</div>
          <Form.Control
            className="border-secondary"
            aria-label="Title"
            placeholder="my user integration"
            onChange={handleDescriptionChange}
            value={createUserIntegrationOptions.description}
          />
          <Switch
            className="mt-2"
            id="1"
            label={createUserIntegrationOptions.isEnabled ? 'Enabled' : 'Disabled'}
            color="success"
            checked={createUserIntegrationOptions.isEnabled}
            onChange={handleIsEnabledChange}
            testId={testIds.isEnabledSwitch()}
          />
        </Card.Body>
      </Card>
    </div>
  );
};

export default GeneralConfigurationCard;
