import { OneMinuteInMilliseconds } from '../constants/measurements';
import { Values } from '../types/values';

export const ActivityState = {
  active: 'active',
  idle: 'idle',
} as const;

type ActivityStates = Values<typeof ActivityState>;

export interface ActivityDetectorOptions {
  /**
   * The various window events which will cause the state to become active
   */
  activityEvents?: Array<keyof WindowEventMap>;

  /**
   * The amount of time in milliseconds before the state becomes idle
   */
  idleTimeMilliseconds?: number;
}

export interface ActivityDetector {
  /**
   * Register an event listener which will be invoked whenever the state changes to the provided activityState
   */
  on(activityState: ActivityStates, listener: () => void): void;

  /**
   * Stop the activity detector and remove any registered listeners
   */
  destroy: () => void;
}

function activityDetector({
  activityEvents = [
    'click',
    'mousemove',
    'keydown',
    'mousedown',
    'touchstart',
    'touchmove',
    'focus',
    'scroll',
  ],
  idleTimeMilliseconds = OneMinuteInMilliseconds,
}: ActivityDetectorOptions = {}): ActivityDetector {
  const listeners: Record<ActivityStates, Array<() => void>> = {
    active: [],
    idle: [],
  };
  let currentState: ActivityStates;
  let idleTimer: ReturnType<typeof setTimeout>;

  // Init the activity detector

  init();

  // Public methods

  return {
    on(activityState: ActivityStates, listener: () => void) {
      listeners[activityState].push(listener);
    },
    destroy() {
      listeners.active = [];
      listeners.idle = [];

      clearInterval(idleTimer);

      activityEvents.forEach(eventName =>
        window.removeEventListener(eventName, handleActivityEvent)
      );
    },
  };

  // Private methods

  function init() {
    setActivityState(ActivityState.active);
    activityEvents.forEach(eventName => window.addEventListener(eventName, handleActivityEvent));
  }

  function handleActivityEvent() {
    setActivityState(ActivityState.active);
  }

  function setActivityState(newState: ActivityStates) {
    clearTimeout(idleTimer);
    if (newState === ActivityState.active) {
      idleTimer = setTimeout(() => setActivityState(ActivityState.idle), idleTimeMilliseconds);
    }

    if (currentState !== newState) {
      currentState = newState;
      listeners[newState].forEach(listener => listener());
    }
  }
}

export default activityDetector;
