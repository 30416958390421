import React from 'react';

export interface ButtonProps {
  active?: boolean;
  disabled?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  variant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'info'
    | 'light'
    | 'dark'
    | 'link'
    | 'transparent'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-success'
    | 'outline-warning'
    | 'outline-danger'
    | 'outline-info'
    | 'outline-light'
    | 'outline-dark'
    | 'outline-transparent';
  onClick?: () => void;
  className?: string;
  dataTestId?: string;
  hidden?: boolean;
  type?: 'button' | 'reset' | 'submit' | undefined;
  ariaLabel?: string;
  children?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  active = false,
  disabled = false,
  size = 'md',
  variant = 'primary',
  children,
  onClick,
  dataTestId = '',
  className = '',
  type = 'button',
  hidden = false,
  ariaLabel = '',
}) => {
  return (
    <button
      aria-label={ariaLabel}
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={`btn btn-${variant} btn-${size} ${active ? 'active' : ''} ${className}`}
      disabled={disabled}
      onClick={onClick}
      data-testid={dataTestId}
      hidden={hidden}
    >
      {children}
    </button>
  );
};

export default Button;
