import React from 'react';
import { ApiResult } from '../../../api/apiHooks';
import { UserQualification } from '../../../api/responses/userQualification';
import { UserStatisticsResponse } from '../../../api/responses/userStatisticsResponse';
import CardGridBase from '../../CardGrid/CardGridBase';
import useCardGrid from '../../CardGrid/useCardGrid';
import EmptyState from '../../EmptyState/EmptyState';
import { TaskProfileModalProps } from '../Modal/TaskProfileModalProps';
import MessageOfTheDay from '../ReportDrawer/MessageOfTheDay';
import { TaskProfileCardFeatures } from '../ReportDrawer/taskProfileCardDisplayOptions';
import { TaskProfileCardPrimaryDataPoints } from '../ReportDrawer/taskProfileCardPrimaryDataPoints';
import TaskProfileCardGridCollection from './TaskProfileCardGridCollection';
import useTaskProfileCardDimensions from './useTaskProfileCardDimensions';
import useTaskProfileEmptyStateMessage from './useTaskProfileEmptyStateMessage';
import { JobStatsResponse } from '../../../api/responses/jobStatsResponse';
import JobAssocMethod from '../../../models/JobAssocMethod';

export interface TaskProfileCardGridProps {
  data: UserQualification[];
  features: TaskProfileCardFeatures;
  primaryDataPoint: TaskProfileCardPrimaryDataPoints;
  isLoading: boolean;
  isError: boolean;
  isShowingMessageOfTheDay: boolean;
  messageOfTheDayTitle: string;
  messageOfTheDayDescription: string;
  messageOfTheDayImage: string;
  onCardClick: (modalProps: TaskProfileModalProps) => void;
  onResize: (numCardsShown: number) => void;
  onGetUserStatistics: (userId: number) => ApiResult<UserStatisticsResponse>;
  onGetJobStatistics: (userId: number) => ApiResult<JobStatsResponse>;
  jobAssocMethod: JobAssocMethod;
}

const TaskProfileCardGrid: React.FC<TaskProfileCardGridProps> = ({
  data,
  features,
  primaryDataPoint,
  isShowingMessageOfTheDay,
  messageOfTheDayTitle,
  messageOfTheDayDescription,
  messageOfTheDayImage,
  isLoading,
  isError,
  onCardClick,
  onResize,
  onGetUserStatistics,
  onGetJobStatistics,
  jobAssocMethod,
}) => {
  const cardDimensions = useTaskProfileCardDimensions();
  const emptyStateMessage = useTaskProfileEmptyStateMessage(data.length, isLoading, isError);
  const { ref, gridDimensions } = useCardGrid(
    cardDimensions.width,
    cardDimensions.height,
    onResize
  );
  const numColumns = isShowingMessageOfTheDay ? 1 : gridDimensions.numColumns;
  const numOfCards = gridDimensions.numRows * numColumns;
  return (
    <>
      {emptyStateMessage.length > 0 && <EmptyState message={emptyStateMessage} />}
      <CardGridBase numColumns={numColumns} ref={ref}>
        {isShowingMessageOfTheDay ? (
          <MessageOfTheDay
            className="overflow-hidden"
            title={messageOfTheDayTitle}
            description={messageOfTheDayDescription}
            image={messageOfTheDayImage}
          />
        ) : (
          <TaskProfileCardGridCollection
            data={data}
            features={features}
            primaryDataPoint={primaryDataPoint}
            numOfCards={numOfCards}
            onCardClick={onCardClick}
            isLoadingUserStatistics
            onGetUserStatistics={onGetUserStatistics}
            onGetJobStatistics={onGetJobStatistics}
            jobAssocMethod={jobAssocMethod}
          />
        )}
      </CardGridBase>
    </>
  );
};

export default TaskProfileCardGrid;
