import {
  TaskProfileIncludeStatistic,
  TaskProfileIncludeStatistics,
} from '../../../api/requests/companiesUsersStatisticsRequest';
import commonText from '../../../constants/commonText';
import messages from '../../../constants/messages';
import { OptionsFlags } from '../../../types/optionsFlags';
import { Values } from '../../../types/values';

export const TaskProfileCardDisplayOptions = {
  username: { id: 'username', label: commonText.username },
  userId: { id: 'userId', label: commonText.userId },
  note: { id: 'note', label: commonText.note },
  roles: { id: 'roles', label: commonText.roles },
  jobs: { id: 'jobs', label: commonText.jobs },
  tasks: { id: 'tasks', label: commonText.tasks },
  training: { id: 'training', label: commonText.training },
  wePe: { id: 'wePe', label: `${commonText.we}/${commonText.pe}` },
  viewAsIncomplete: { id: 'viewAsIncomplete', label: messages.viewAsIncomplete },
} as const;

export type TaskProfileCardDisplayOption = Values<typeof TaskProfileCardDisplayOptions>;

export type TaskProfileCardFeatures = OptionsFlags<typeof TaskProfileCardDisplayOptions>;

export const getCardDisplayOptionIncludeParams = (
  displayOptions: TaskProfileCardFeatures
): TaskProfileIncludeStatistic[] => {
  const result: TaskProfileIncludeStatistic[] = [];

  if (displayOptions.roles) {
    result.push(TaskProfileIncludeStatistics.roles);
  }
  if (displayOptions.jobs) {
    result.push(TaskProfileIncludeStatistics.jobs);
  }
  if (displayOptions.tasks) {
    result.push(TaskProfileIncludeStatistics.tasks);
  }
  if (displayOptions.training) {
    result.push(TaskProfileIncludeStatistics.trainings);
  }
  if (displayOptions.wePe) {
    result.push(TaskProfileIncludeStatistics.wePes);
  }

  return result;
};
