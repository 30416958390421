import React from 'react';

interface FullscreenProps {
  className?: string;
}

const Fullscreen: React.FC<FullscreenProps> = ({ className = 'icon-32' }) => {
  return (
    <svg
      className={className}
      height="32"
      width="32"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>fullscreen</title>
      <g fill="none">
        <path
          d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default Fullscreen;
