import React from 'react';
import Circle from '../Icons/Circle/Circle';
import Skeleton from '../Skeleton/Skeleton';

export interface ConnectionProps {
  isLoading: boolean;
  isConnected: boolean;
}

const Connection: React.FC<ConnectionProps> = ({ isLoading, isConnected }) => {
  const connectionText = isConnected ? 'Connected' : 'Disconnected';
  const connectionColor = isConnected ? 'success' : 'danger';

  if (isLoading) {
    return <Skeleton width="4rem" />;
  }

  return (
    <div className="d-inline-flex align-items-center">
      <span className="me-2">{connectionText}</span> <Circle color={connectionColor} size="sm" />
    </div>
  );
};

export default Connection;
