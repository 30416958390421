import apiHooks from '../api/apiHooks';
import { UseDisplayItemsResponse } from '../models/useDisplayItemsResponse';
import displayItemConverters from '../pages/TaskProfileReport/hooks/displayItemConverters';

function useAssigners(companyId: number, searchText: string): UseDisplayItemsResponse {
  const {
    data = [],
    isLoading,
    isSuccess,
  } = apiHooks.useCompaniesAssigners(companyId, {
    keepPreviousData: true,
    select: assigner => {
      let displayItems = displayItemConverters.convertCompanyAssignersResponse(assigner);

      if (searchText !== '') {
        displayItems = displayItems.filter(a =>
          a.display.toLowerCase().includes(searchText.toLowerCase())
        );
      }
      return displayItems;
    },
  });

  return { data, isLoading, isSuccess };
}

export default useAssigners;
