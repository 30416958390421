import React, { useCallback, useRef, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { CompanyResponse } from '../../api/responses/companyResponse';
import ConfirmDialog, { ConfirmDialogProps } from '../ConfirmDialog/ConfirmDialog';
import SeriesDropDown from '../Dropdown/SeriesDropDown';
import Circle from '../Icons/Circle/Circle';
import MultiPartSelectContainer from '../MultiPartSelect/Container/MultiPartSelectContainer';
import Switch from '../Switch/Switch';
import commonText from '../../constants/commonText';
import messages from '../../constants/messages';
import { DisplayItem } from '../../models/displayItem';
import JobMatrix from '../../models/JobMatrix';
import Series from '../../models/Series';
import { TaskDisplayItem } from '../../models/TaskDisplayItem';
import { UseDisplayItemsResponse } from '../../models/useDisplayItemsResponse';

/**
 * @param {boolean} isEditing - Indicates whether the equivalency mapping is a new record if false, or an existing record if true.
 * @param {Series[]} availableSeriesList - All the Series to which the user has access for selecting Tasks.
 * @param {boolean} seriesListIsLoading - React `UseQueryResult<TData, TError>.isLoading` indicator.
 * @param setSelectedSeries - Callback called when user changes selected Series.
 * @param setSelectedTaskDisplayItems - React "state variable" setter for the Task display items the user selects.
 * @param {TaskDisplayItem[]} taskDisplayItems - Task display items converted from the selected Series's `TaskModel`s (required by `MultiPartSelectContainer`).
 * @param {TaskDisplayItem[]} selectedTaskDisplayItems - List of `TaskDisplayItem`s selected in the `MultiPartSelectContainer` component.
 * @param {string} error - Any (web API) error to report to the UI (empty string if no error).
 * @param isDisplayItemInUseFilter - Optional callback which returns whether a `DisplayItem` (from `MultiPartSelectContainer`) is being used by the equivalencies expression of this component.
 * @param {boolean|undefined} isDisabled - Optional indicator whether this component is disabled, false by default.
 * @param {number} selectedSeriesId - ID of selected Series from `availableSeriesList`.
 */
export interface EquivalenciesSelectTasksProps {
  company?: CompanyResponse;
  isEditing: boolean;
  availableSeriesList: Series[];
  seriesListIsLoading: boolean;
  setIncludeHistoricalTasks?: (includeHistorical: boolean) => boolean | void;
  includeHistoricalTasks?: boolean;
  setSelectedSeries: (item: Series) => boolean | void;
  selectedSeriesId: number;
  setSelectedTaskDisplayItems: (item: TaskDisplayItem[]) => TaskDisplayItem[] | void;
  taskDisplayItems: TaskDisplayItem[];
  selectedTaskDisplayItems: TaskDisplayItem[];
  error: string;
  isDisplayItemInUseFilter?: (item: DisplayItem) => boolean;
  isDisabled?: boolean;
}

/**
 * Displays Series in a dropdown and selected Tasks as "badges".  Similar to
 *	`MultiPartSelect`, except the selected Tasks are preserved when the
 *	selected Series is changed.
 */
const EquivalenciesSelectTasks: React.FC<EquivalenciesSelectTasksProps> = ({
  company = null,
  isEditing,
  availableSeriesList,
  seriesListIsLoading,
  setIncludeHistoricalTasks = () => {},
  includeHistoricalTasks = true,
  setSelectedSeries,
  selectedSeriesId,
  setSelectedTaskDisplayItems,
  taskDisplayItems,
  selectedTaskDisplayItems,
  error,
  isDisplayItemInUseFilter = () => true,
  isDisabled = false,
}) => {
  const jobMatrix: JobMatrix = {
    id: 0,
    companyId: 0,
    title: '',
    description: '',
    isEnabled: true,
    isShared: false,
    options: 0,
    dateCreated: new Date(),
    createdBy: 0,
    createdInVerify: false,
    useForCustom: false,
  };
  if (isEditing) {
    jobMatrix.companyId = company?.id ?? 0;
  }
  const [tasksSearchText, setTasksSearchText] = useState('');
  const [taskRemoveDialogIsOpen, setTaskRemoveDialogIsOpen] = useState(false);
  const [taskDialogProps, setTaskDialogProps] = useState<ConfirmDialogProps>({
    isDialogOpen: false,
    setIsDialogOpen: () => {},
    onClose: () => {},
    headerText: '',
    bodyText: '',
  });

  // Note the task search is client-side.  The API will pull in all the tasks for the series and the search will filter those down.
  function searchListForIn<T>(
    list: T[],
    text: string,
    properties: (keyof T)[],
    includeEmptyValues: boolean = false
  ) {
    const textLowerCase = text.toLowerCase();
    return list.filter((item: T) => {
      return properties.some((property: keyof T) => {
        const value = item[property] as Object;
        return value ? String(value).toLowerCase().includes(textLowerCase) : includeEmptyValues;
      });
    });
  }

  const useFilteredTaskList = useCallback(
    (_companyId: number, text: string): UseDisplayItemsResponse<TaskDisplayItem> => {
      const filteredTaskItems = searchListForIn(taskDisplayItems, text, ['code', 'display']);
      return {
        data: filteredTaskItems,
        isLoading: false,
      };
    },
    [taskDisplayItems]
  );

  const taskDisplayItemsChanged = useCallback(
    (selectedDisplayItems: DisplayItem[]) => {
      const taskDisplayItemList: TaskDisplayItem[] = selectedDisplayItems.reduce(
        (list, displayItem) => {
          const found = taskDisplayItems.find(
            taskDisplayItem => taskDisplayItem.itemId === displayItem.itemId
          );
          if (found) {
            list.push(found);
          }
          // This keeps the selected item even if the available list changes.
          else {
            list.push(displayItem as TaskDisplayItem);
          }
          return list;
        },
        [] as TaskDisplayItem[]
      );
      const itemsWereRemoved = selectedTaskDisplayItems.length > selectedDisplayItems.length;
      if (itemsWereRemoved) {
        const removedTaskDisplayItems = selectedTaskDisplayItems.filter(
          taskDisplayItem =>
            !selectedDisplayItems.some(displayItem => displayItem.itemId === taskDisplayItem.itemId)
        );
        const itemsInUseList = removedTaskDisplayItems.filter(isDisplayItemInUseFilter);
        if (itemsInUseList.length > 0) {
          const itemsInUseString = itemsInUseList
            .map(taskDisplayItem => `"${taskDisplayItem.display}" (${taskDisplayItem.code})`)
            .join(', ');
          const messageBody = `This Task is used in the mapping being built.  If removed, you may want to clear the mapping.  Remove ${itemsInUseString} from Selected Tasks?`;
          setTaskRemoveDialogIsOpen(true);
          setTaskDialogProps({
            isDialogOpen: taskRemoveDialogIsOpen,
            setIsDialogOpen: setTaskRemoveDialogIsOpen,
            onClose: (answer?: boolean) => {
              if (answer === true) {
                // Remove selected item(s) by updating list with them omitted.
                setSelectedTaskDisplayItems(taskDisplayItemList);
              }
            },
            headerText: `Remove Task?`,
            bodyText: messageBody,
          });
        } else {
          // Remove selected item(s) by updating list with them omitted.
          setSelectedTaskDisplayItems(taskDisplayItemList);
        }
      } else {
        // Add selected item(s) by updating list with them included.
        setSelectedTaskDisplayItems(taskDisplayItemList);
      }
    },
    [
      taskDisplayItems,
      setSelectedTaskDisplayItems,
      selectedTaskDisplayItems,
      taskRemoveDialogIsOpen,
      isDisplayItemInUseFilter,
    ]
  );

  const seriesKey = useCallback((seriesItem: Series) => seriesItem.id, []);
  const seriesLabel = useCallback((seriesItem: Series) => seriesItem.name, []);
  const handleSelectedSeriesChange = useCallback(
    (seriesItem: Series) => setSelectedSeries(seriesItem),
    [setSelectedSeries]
  );
  const collatorRef = useRef(new Intl.Collator([], { sensitivity: 'base' }));
  const seriesCollator = useCallback(
    (left: Series, right: Series) => collatorRef.current?.compare(left.name, right.name),
    [collatorRef]
  );

  return (
    <div className="section">
      <div id="select-tasks-headers" className="d-inline-flex mt-2">
        <Circle
          className="mx-auto mt-1 text-center text-white"
          color="primary"
          text="2"
          styleParam={{ aspectRatio: '1' }}
        />
        <div>
          <h3 className="header mb-0 ms-1">Select Tasks</h3>
          <h6 className="text-muted ms-1">
            Select all tasks associated with the job. These tasks will populate below and in the
            toolbox of Step 3.
          </h6>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <SeriesDropDown<Series>
            items={availableSeriesList}
            isLoading={seriesListIsLoading}
            defaultLabel="Series"
            emptyListLabel="No Series"
            keyExtractor={seriesKey}
            labelExtractor={seriesLabel}
            onChange={handleSelectedSeriesChange}
            itemSorter={seriesCollator}
            isDisabled={isDisabled}
            variant="secondary"
            selectedSeriesId={selectedSeriesId}
          />
        </div>
        <div className="col fs-5">
          <Switch
            key="key"
            id="tasksIncludeHistorical"
            label="Include Historical?"
            checked={includeHistoricalTasks}
            onChange={() => {
              setIncludeHistoricalTasks(!includeHistoricalTasks);
            }}
          />
        </div>
      </div>
      <Alert variant="danger" hidden={!error}>
        {error}
      </Alert>
      <MultiPartSelectContainer
        companyId={jobMatrix.companyId}
        useData={useFilteredTaskList}
        onChange={taskDisplayItemsChanged}
        selectedDisplayItems={selectedTaskDisplayItems}
        emptyListText={messages.noAvailableMessage(commonText.task)}
        onSearchTextChanged={setTasksSearchText}
        searchText={tasksSearchText}
        availableListGroupStyle={{ maxHeight: '30vh' }}
        isDisabled={isDisabled}
        tabId="equivalencies-tasks"
      />
      <ConfirmDialog
        isDialogOpen={taskRemoveDialogIsOpen}
        setIsDialogOpen={setTaskRemoveDialogIsOpen}
        headerText={taskDialogProps.headerText}
        bodyText={taskDialogProps.bodyText}
        onClose={taskDialogProps.onClose}
      />
    </div>
  );
};

export default EquivalenciesSelectTasks;
