import { DateTime } from 'luxon';
import integrationApiHooks from '../api/integrationApi/integrationApiHooks';
import { BatchUserUploadIntegrationResponse } from '../api/integrationApi/responses/BatchUserUploadIntegrationResponse';
import { DisplayItem } from '../models/displayItem';

function useIntegrationBatchStatisticsByCompanyId(
  companyId: number,
  integrationId: number
): {
  data: BatchUserUploadIntegrationDisplayItem[];
  isLoading: boolean;
  isFetching: boolean;
  refetch: () => void;
  isInitialLoading: boolean;
} {
  const { data, isLoading, refetch, isFetching, isInitialLoading } =
    integrationApiHooks.useGetIntegrationBatchStatisticsByCompanyId(companyId, integrationId);

  const batchUserUploadIntegrationDisplayItems = convertBatchUserUploadToDisplayItem(data ?? []);

  return {
    data: batchUserUploadIntegrationDisplayItems,
    isLoading,
    refetch,
    isFetching,
    isInitialLoading,
  };
}

export interface BatchUserUploadIntegrationDisplayItem
  extends DisplayItem,
    BatchUserUploadIntegrationResponse {}

export function convertBatchUserUploadToDisplayItem(
  responses: BatchUserUploadIntegrationResponse[]
): BatchUserUploadIntegrationDisplayItem[] {
  return responses.map(response => ({
    itemId: response.BatchId,
    display: DateTime.fromISO(response.DateCreatedUTC, { zone: 'utc' })
      .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .toFormat('M/d/yyyy, h:mm:ss a'),
    DateCreatedUTC: response.DateCreatedUTC,
    BatchId: response.BatchId,
    TotalNewUsers: response.TotalNewUsers,
    TotalUpdateUsers: response.TotalUpdateUsers,
    TotalErrors: response.TotalErrors,
  }));
}

export default useIntegrationBatchStatisticsByCompanyId;
