import React from 'react';
import DoughnutChart from '../DoughnutChart/DoughnutChart';
import { calculateIncomplete } from './progressCalculator';
import ProgressDoughnutChartSkeleton from './ProgressDoughnutChartSkeleton';

export interface ProgressDoughnutChartProps {
  complete: number;
  total: number;
  centerText: string;
  chartColors: [string, string];
  className?: string;
  isLoading?: boolean;
}

const ProgressDoughnutChart: React.FC<ProgressDoughnutChartProps> = ({
  complete,
  total,
  centerText,
  chartColors,
  className = '',
  isLoading = false,
}) => {
  const incomplete = calculateIncomplete(complete, total);

  const hasInvalidData = complete === 0 && incomplete === 0;

  const finalIncomplete = hasInvalidData ? 1 : incomplete;

  return isLoading ? (
    <ProgressDoughnutChartSkeleton />
  ) : (
    <div className={`position-relative ${className}`.trimEnd()}>
      <DoughnutChart
        dataset={{
          data: [complete, finalIncomplete],
          backgroundColor: chartColors,
          hoverOffset: 0,
          borderWidth: 0,
        }}
        isTooltipEnabled={hasInvalidData}
      />
      <div
        className="position-absolute d-flex flex-column align-items-center"
        style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)', lineHeight: '1' }}
      >
        <h5 className="mb-0">{`${Math.max(complete, 0)}/${Math.max(total, 0)}`}</h5>
        <h5 className="mb-0 mt-n1">{centerText}</h5>
      </div>
    </div>
  );
};

export default ProgressDoughnutChart;
