import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apiHooks from '../../api/apiHooks';
import { UsersCustomViewsRequest } from '../../api/requests/usersCustomViewsRequest';
import { OneMinuteFortyFiveSecondsInMilliseconds } from '../../constants/measurements';
import routes from '../../constants/routes';
import useCompany from '../../hooks/useCompany';
import useCompanyId from '../../hooks/useCompanyId';
import useUserInformation from '../../hooks/useUserInformation';
import usePolicyService from '../../policyService/usePolicyService';
import customViewConnectionsResponseConverter from '../../utils/converter/customViewConnectionsResponseConverter';
import useCustomViewReports from './hooks/useCustomViewReports';
import ViewsReport from './ViewsReport';

const ViewsReportContainer: React.FC = () => {
  const navigate = useNavigate();
  const { companyId } = useCompanyId();
  const { company, isLoading: isCompanyLoading } = useCompany();
  const { user, isLoading: isUserLoading } = useUserInformation();
  const [take, setTake] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchByText, setSearchByText] = useState('');
  const { policies, isLoading: isLoadingPolicies, isError: isErrorPolicies } = usePolicyService();

  const requestParams: UsersCustomViewsRequest = {
    companyId,
    skip: Math.max((currentPage - 1) * take, 0),
    take,
    searchText: searchByText,
  };
  const { isLoading, isError, data: responseData } = useCustomViewReports(requestParams);

  const customViewIds = responseData?.ResultData.map(customView => customView.id) ?? [];
  const customViewConnectionsRequest = apiHooks.useGetCustomViewConnections(
    {
      companyId,
      customViewIds,
    },
    {
      keepPreviousData: true,
      select: customViewConnectionsResponseConverter.convert,
      refetchInterval: OneMinuteFortyFiveSecondsInMilliseconds,
      enabled: customViewIds.length > 0,
    }
  );

  const handleAddViewClick = () => {
    navigate(routes.taskProfileReport.url({ isDrawerExpanded: true }));
  };

  const handleOnCardClick = (id: number) => {
    navigate(routes.taskProfileReportCustomView.url({ id }));
  };

  const handleSearch = (text: string) => {
    setCurrentPage(1);
    setSearchByText(text);
  };

  return (
    <ViewsReport
      data={responseData ?? { ItemCount: 0, ResultData: [] }}
      isLoading={isLoading || isLoadingPolicies}
      isError={isError || isErrorPolicies}
      customViewConnectionsRequest={customViewConnectionsRequest}
      onPageClick={setCurrentPage}
      currentPage={currentPage}
      take={take}
      isCompanyLoading={isCompanyLoading}
      company={company}
      user={user}
      isUserLoading={isUserLoading}
      onPageResize={setTake}
      onCardClick={handleOnCardClick}
      onAddViewClick={handleAddViewClick}
      onSearch={handleSearch}
      policies={policies()}
    />
  );
};

export default ViewsReportContainer;
