import React, { useEffect } from 'react';
import useResizeObserver from '../../hooks/useResizeObserver';
import { Dimensions } from '../../models/dimensions';
import { GridDimensions } from '../../models/gridDimensions';
import elementUtils from '../../utils/elementUtils';
import useZoom from '../../zoom/useZoom';

const calculateDimensions = (
  { height: containerHeight, width: containerWidth }: Dimensions,
  { height: elementHeight, width: elementWidth }: Dimensions,
  gutterSize: number
): GridDimensions => {
  // First see how many rows and columns we can fit without any spacing for the gutter.
  const numRows = Math.floor(containerHeight / elementHeight);
  const numColumns = Math.floor(containerWidth / elementWidth);

  // If we can fit more than 1 row without a gutter, then lets account for the gutter.
  const columnGutterSize = numColumns > 0 ? gutterSize : 0;
  const rowGutterSize = numRows > 1 ? gutterSize : 0;

  const height =
    elementHeight === 0 ? 0 : Math.floor(containerHeight / (elementHeight + rowGutterSize));

  const width =
    elementWidth === 0 ? 0 : Math.floor(containerWidth / (elementWidth + columnGutterSize));

  const minimumNumberOfRowsAndColumns: number = 1;

  const minimumHeight =
    height <= minimumNumberOfRowsAndColumns ? minimumNumberOfRowsAndColumns : height;

  const minimumWidth =
    width <= minimumNumberOfRowsAndColumns ? minimumNumberOfRowsAndColumns : width;

  const maximumNumberOfRowsAndColumns: number = 7;

  const maximumHeight =
    minimumHeight > maximumNumberOfRowsAndColumns ? maximumNumberOfRowsAndColumns : minimumHeight;

  const maximumWidth =
    minimumWidth > maximumNumberOfRowsAndColumns ? maximumNumberOfRowsAndColumns : minimumWidth;

  return {
    numRows: maximumHeight,
    numColumns: maximumWidth,
  };
};

function useCardGridDimensions(
  elementRef: React.RefObject<HTMLElement>,
  cardHeight: number,
  cardWidth: number,
  gutterSize = 8
): GridDimensions {
  const { zoomLevel } = useZoom();
  const containerDimensionsRef = React.useRef<Dimensions>({ height: 0, width: 0 });
  const [numRows, setNumRows] = React.useState(0);
  const [numColumns, setNumColumns] = React.useState(0);

  useEffect(() => {
    if (elementRef.current) {
      containerDimensionsRef.current = elementUtils.getContentDimensions(elementRef.current);
    }
  }, [elementRef]);

  useEffect(() => {
    const dimensions = calculateDimensions(
      containerDimensionsRef.current,
      { height: cardHeight * zoomLevel, width: cardWidth * zoomLevel },
      gutterSize
    );

    setNumRows(dimensions.numRows);
    setNumColumns(dimensions.numColumns);
  }, [cardHeight, cardWidth, gutterSize, zoomLevel]);

  useResizeObserver({
    callback: React.useCallback(
      entries => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }

        containerDimensionsRef.current = {
          height: entries[0].contentRect.height,
          width: entries[0].contentRect.width,
        };

        const dimensions = calculateDimensions(
          containerDimensionsRef.current,
          { height: cardHeight * zoomLevel, width: cardWidth * zoomLevel },
          gutterSize
        );

        setNumRows(dimensions.numRows);
        setNumColumns(dimensions.numColumns);
      },
      [cardHeight, cardWidth, gutterSize, zoomLevel]
    ),
    elementRef,
  });

  return React.useMemo(() => ({ numRows, numColumns }), [numRows, numColumns]);
}

export default useCardGridDimensions;
