import round from 'lodash/round';
import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from '../components/Buttons/Button/Button';
import useZoom from './useZoom';

export interface ZoomProps {
  className?: string;
}

const Zoom: React.FC<ZoomProps> = ({ className = '' }) => {
  const { zoomLevel, zoomIn, zoomOut } = useZoom();

  const currentZoom = round(zoomLevel * 100, 1);

  return (
    <InputGroup className={`flex-nowrap ${className}`} size="sm">
      <InputGroup.Text className="p-0">
        <Button
          className="m-1"
          variant="transparent"
          onClick={zoomOut}
          size="xs"
          ariaLabel="zoom out"
        >
          -
        </Button>
      </InputGroup.Text>
      <InputGroup.Text className="rounded-0 bg-light border-0">{currentZoom}%</InputGroup.Text>
      <InputGroup.Text className="p-0">
        <Button
          className="m-1"
          variant="transparent"
          onClick={zoomIn}
          size="xs"
          ariaLabel="zoom in"
        >
          +
        </Button>
      </InputGroup.Text>
    </InputGroup>
  );
};

export default Zoom;
