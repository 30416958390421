import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { CompanyResponse } from '../../api/responses/companyResponse';
import { UserInformation } from '../../api/responses/userInformation';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import TopNavbar from '../../components/Layout/TopNavBar/TopNavBar';
import commonText from '../../constants/commonText';
import { AvailablePolicies } from '../../policyService/policyEnforcer/policyEnforcer';

export interface NotFoundProps {
  isCompanyLoading: boolean;
  company: CompanyResponse | undefined;
  isUserLoading: boolean;
  user: UserInformation | undefined;
  policies: Pick<
    AvailablePolicies,
    | 'canViewTaskProfile'
    | 'canViewCustomView'
    | 'canViewActivities'
    | 'canViewIntegrationHub'
    | 'canViewLearningHistory'
  >;
}

const NotFound: React.FC<NotFoundProps> = ({
  company,
  isCompanyLoading,
  isUserLoading,
  policies,
  user,
}) => {
  return (
    <MainLayout
      isCompanyLoading={isCompanyLoading}
      companyImage={company?.logo}
      companyName={company?.name}
      policies={policies}
    >
      <TopNavbar reportName="404 Not Found" isUserLoading={isUserLoading} user={user} />
      <Container fluid className="h-100">
        <Row className="mt-3">
          <h1 className="text-center">{commonText.pageNotFound}</h1>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default NotFound;
