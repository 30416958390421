import { Values } from '../../../types/values';
import {
  TaskProfileCardDisplayOption,
  TaskProfileCardDisplayOptions,
} from './taskProfileCardDisplayOptions';

export const TaskProfileCardPrimaryDataPoint = {
  roles: 0,
  tasks: 1,
  jobs: 2,
} as const;

export type TaskProfileCardPrimaryDataPoints = Values<typeof TaskProfileCardPrimaryDataPoint>;

export const getPrimaryDataPointText = (dataPoint: TaskProfileCardPrimaryDataPoints): string => {
  const cardDisplayOption = getPrimaryDataPointCardDisplayOption(dataPoint);
  return cardDisplayOption.label;
};

export const getPrimaryDataPointCardDisplayOption = (
  dataPoint: TaskProfileCardPrimaryDataPoints
): TaskProfileCardDisplayOption => {
  const primaryDataPointMap: Record<
    TaskProfileCardPrimaryDataPoints,
    TaskProfileCardDisplayOption
  > = {
    [TaskProfileCardPrimaryDataPoint.roles]: TaskProfileCardDisplayOptions.roles,
    [TaskProfileCardPrimaryDataPoint.tasks]: TaskProfileCardDisplayOptions.tasks,
    [TaskProfileCardPrimaryDataPoint.jobs]: TaskProfileCardDisplayOptions.jobs,
  };

  return primaryDataPointMap[dataPoint];
};
