import { uniqBy } from 'lodash';
import React, { CSSProperties } from 'react';
import { DisplayItem } from '../../../models/displayItem';
import { UseDisplayItemsResponse } from '../../../models/useDisplayItemsResponse';
import MultiPartSelect from '../MultiPartSelect';

export interface MultiPartSelectContainerProps {
  companyId: number;
  useData: (companyId: number, text: string) => UseDisplayItemsResponse;
  onChange: (selectedIds: DisplayItem[]) => void;
  selectedDisplayItems: DisplayItem[];
  emptyListText: string;
  searchText: string;
  onSearchTextChanged: (searchText: string) => void;
  availableListGroupStyle?: CSSProperties;
  isDisabled?: boolean;
  tabId: string;
}

const MultiPartSelectContainer: React.FC<MultiPartSelectContainerProps> = ({
  companyId,
  useData,
  onChange,
  selectedDisplayItems,
  emptyListText,
  searchText,
  onSearchTextChanged,
  tabId,
  availableListGroupStyle = {},
  isDisabled = false,
}) => {
  const { data, isLoading }: UseDisplayItemsResponse = useData(companyId, searchText);

  function handleSelectedItem(displayItem: DisplayItem) {
    let selectedItems;
    const displayItemFound = selectedDisplayItems.find(
      selectedDisplayItem => selectedDisplayItem.itemId === displayItem.itemId
    );
    // Remove if found.
    if (displayItemFound) {
      selectedItems = selectedDisplayItems.filter(
        selectedDisplayItem => selectedDisplayItem.itemId !== displayItem.itemId
      );
    }
    // Add if not found.
    else {
      selectedItems = selectedDisplayItems.concat(displayItem);
    }
    onChange(selectedItems);
  }

  function handleOnClear() {
    onChange([]);
  }

  function handleOnSelectAll() {
    const uniqueDisplayItems = uniqBy([...selectedDisplayItems, ...data], 'itemId');

    onChange(uniqueDisplayItems);
  }

  return (
    <MultiPartSelect
      items={data}
      onSearch={onSearchTextChanged}
      onChange={handleSelectedItem}
      selectedDisplayItems={selectedDisplayItems}
      emptyListText={emptyListText}
      isLoading={isLoading}
      onClear={handleOnClear}
      onSelectAll={handleOnSelectAll}
      availableListGroupStyle={availableListGroupStyle}
      isDisabled={isDisabled}
      tabId={tabId}
    />
  );
};

export default MultiPartSelectContainer;
