export const JobMatrixGetItemRoute = (_companyId: number, jobMatrixId: number): string =>
  `/jobmatrices/${jobMatrixId}`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const JobMatrixPostRoute = (_companyId: number): string => `/jobmatrices`;

export const JobMatrixPutRoute = (_companyId: number, jobMatrixId: number): string =>
  `/jobmatrices/${jobMatrixId}`;

export const JobMatrixTaskDeleteRoute = (
  _companyId: number,
  jobMatrixId: number,
  taskId: number
): string => `/companies/jobs/${jobMatrixId}/jobmatrixtasks/${taskId}`;

export const JobMatrixTaskGetListRoute = (companyId: number, jobMatrixId: number): string =>
  `/companies/${companyId}/jobs/${jobMatrixId}/jobmatrixtasks`;

export const JobMatrixTaskPostRoute = (companyId: number, jobMatrixId: number): string =>
  `/companies/jobs/${jobMatrixId}/jobmatrixtasks`;

export const TaskByJobMatrixGetListRoute = (companyId: number, jobMatrixId: number): string =>
  `/companies/${companyId}/jobs/${jobMatrixId}/tasks`;

export const UserDemographicsGetItemRoute = (userId: number): string =>
  `/users/${userId}/demographics`;

export default {
  JobMatrixGetItemRoute,
  JobMatrixPostRoute,
  JobMatrixPutRoute,
  JobMatrixTaskDeleteRoute,
  JobMatrixTaskGetListRoute,
  JobMatrixTaskPostRoute,
  TaskByJobMatrixGetListRoute,
  UserDemographicsGetItemRoute,
};
