import apiHooks from '../api/integrationApi/integrationApiHooks';
import CurrentIntegrationsResponse from '../models/CurrentIntegrationsResponse';
import DefaultFileIntegration from '../models/DefaultFileIntegration';
import DefaultAPIIntegration from '../models/DefaultAPIIntegration';
import CompanyUserImportSettingsType from '../models/CompanyUserImportSettingsType';

function useGetCurrentIntegrationsTable(companyId: number): {
  data: CurrentIntegrationsResponse[];
  isLoading: boolean;
  isError: boolean;
  refetch: Function;
  isFetching: boolean;
} {
  const {
    data = [],
    isLoading,
    isError,
    refetch,
    isFetching,
  } = apiHooks.useGetCurrentIntegrations(companyId, 'getCurrentIntegrationsTable');

  if (data.length === 0) {
    data.push(DefaultAPIIntegration, DefaultFileIntegration);
  } else if (data.length === 1 && data[0].Type === CompanyUserImportSettingsType.API) {
    data.push(DefaultFileIntegration);
  } else if (data.length === 1 && data[0].Type === CompanyUserImportSettingsType.SftpFileTransfer) {
    data.push(DefaultAPIIntegration);
  }

  return { data, isLoading, isError, refetch, isFetching };
}

export default useGetCurrentIntegrationsTable;
