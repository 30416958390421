import React from 'react';
import Form from 'react-bootstrap/Form';
import { Color } from '../../types/color';

export interface RadioButtonProps<T> {
  item: T;
  name: string;
  isSelected: (item: T) => boolean;
  getLabel: (item: T) => string;
  getValue: (item: T) => string | number;
  getId?: (item: T) => string | number;
  onChange?: (item: T) => void;
  color?: Color;
  style?: React.CSSProperties;
  className?: string;
  isDisabled?: (item: T) => boolean;
}

function RadioButton<T>({
  item,
  name,
  isSelected,
  getLabel,
  getValue,
  getId = it => `${name}-${getValue(it)}`,
  onChange = () => {},
  color = 'primary',
  style,
  className,
  isDisabled = () => false,
}: RadioButtonProps<T>): JSX.Element {
  const itemId = getId(item);
  return (
    <Form.Check
      type="radio"
      id={String(itemId)}
      className={`custom-radio-group-option-${color} ${className ?? ''}`}
    >
      <Form.Check.Input
        type="radio"
        name={name}
        value={getValue(item)}
        onChange={() => onChange(item)}
        checked={isSelected(item)}
        style={style}
        disabled={isDisabled(item)}
      ></Form.Check.Input>
      <Form.Check.Label>{getLabel(item)}</Form.Check.Label>
    </Form.Check>
  );
}

export default RadioButton;
