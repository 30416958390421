import { JWTTokenResponse } from '../api/responses/jwtTokenResponse';
import { FiveMinutesInMilliseconds, OneSecondInMilliseconds } from '../constants/measurements';
import { AuthSession } from '../models/authSession';

function getAuthSession(
  userId: number,
  refreshToken: string,
  jwtTokenResponse: JWTTokenResponse
): AuthSession {
  const authSession: AuthSession = {
    user: {
      userId,
    },
    token: {
      expiresAt: getTokenExpirationDate(jwtTokenResponse.JWT.ExpiresIn * OneSecondInMilliseconds),
      expiresIn: jwtTokenResponse.JWT.ExpiresIn,
      accessToken: jwtTokenResponse.JWT.AccessToken,
      idToken: jwtTokenResponse.JWT.IdToken,
      refreshToken,
    },
  };

  return authSession;
}

function getTokenExpirationDate(
  expiresInMilliseconds: number,
  expirationBufferInMilliseconds: number = FiveMinutesInMilliseconds
): Date {
  return new Date(Date.now() + expiresInMilliseconds - expirationBufferInMilliseconds);
}

export default {
  getAuthSession,
  getTokenExpirationDate,
};
