import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import apiHooks from '../../api/apiHooks';
import apiHooksOnBoardWebApi from '../../api/apiHooksOnBoardWebApi';
import onBoardWebApiSvc from '../../api/apiOnBoardWebApi';
import { CompanySeriesRequest } from '../../api/requests/companySeriesRequest';
import { CompanySeriesTaskRequest } from '../../api/requests/companySeriesTaskRequest';
import { JobMatrixGetItemRequest } from '../../api/requests/jobMatrixGetItemRequest';
import { CompanySeriesResponse } from '../../api/responses/companySeriesResponse';
import { CompanySeriesTaskResponse } from '../../api/responses/companySeriesTaskResponse';
import { ConfirmDialogProps } from '../../components/ConfirmDialog/ConfirmDialog';
import commonText from '../../constants/commonText';
import routes from '../../constants/routes';
import useCompany from '../../hooks/useCompany';
import useCompanyId from '../../hooks/useCompanyId';
import useUserId from '../../hooks/useUserId';
import useUserInformation from '../../hooks/useUserInformation';
import CompanyPreferences from '../../models/CompanyPreferences';
import JobMatrix from '../../models/JobMatrix';
import usePolicyService from '../../policyService/usePolicyService';
import ExpressionBuilder from '../../utils/expression-builder/expressionBuilder';
import { GeneralMessageType } from '../../models/GeneralMessage';
import useJobMatrixSave, { JobMatrixSaveMutation } from '../../hooks/useJobMatrixSave';
import JobsEdit from './JobsEdit';

/**
 * Parameterless wrapper container for Job Equivalencies Mapping.
 * A `JobMatrix.id` is extracted from the URL parameters of the page.  If an
 *	ID is found, it enters "edit" mode; otherwise, it enters "create" mode.
 */
const JobsEditContainer: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const jobMatrixId = Number(id);
  const isEditing = !Number.isNaN(jobMatrixId);
  const jobPageTitle = isEditing ? `Edit ${commonText.job}` : `Add ${commonText.job}`;
  const { company, isLoading: isCompanyLoading } = useCompany();
  const { companyId } = useCompanyId();
  const { isLoading: isUserLoading, user } = useUserInformation();
  const policyService = usePolicyService();
  const useCustomJobsIsEnabled =
    // eslint-disable-next-line no-bitwise
    (BigInt(company?.preferences ?? 0) & BigInt(CompanyPreferences.UseCustomJobs)) ===
    BigInt(CompanyPreferences.UseCustomJobs);

  // Preset values.
  const [jobMatrix, setJobMatrix] = useState<JobMatrix>({
    // JobMatrix POST requires the `id` to be a number.
    id: jobMatrixId || 0,
    companyId,
    title: '',
    description: '',
    isEnabled: true,
    isShared: false,
    options: undefined,
    dateCreated: undefined,
    createdBy: undefined,
    createdInVerify: false,
    useForCustom: false,
    taskMappingExpression: undefined,
  });
  const [formIsDisabled, setFormIsDisabled] = useState(false);
  // This flag needs to be managed manually because the `UseQueryResult.isLoading`
  //	flag is updated _before_ the `UseQueryOptions.onSuccess` is called.  So,
  //	any code (`useEffect`, etc.) waiting on the response will be triggered
  //	before the data has been captured.
  const [jobMatrixIsLoading, setJobMatrixIsLoading] = useState(true);

  const navigate = useNavigate();
  const policies = policyService.policies(undefined);
  const { userId } = useUserId();
  const [jobSaveDialogIsOpen, setJobSaveDialogIsOpen] = useState(false);
  const [jobSaveDialogProps, setJobSaveDialogProps] = useState<ConfirmDialogProps>({
    isDialogOpen: false,
    setIsDialogOpen: () => {},
    onClose: () => {},
    headerText: '',
    bodyText: '',
  });
  // TODO: currently not being called by `react-query`
  const jobSaveSuccess = (data: JobMatrix): Promise<JobMatrix> | void => {
    setJobSaveDialogIsOpen(true);
    setJobSaveDialogProps({
      isDialogOpen: jobSaveDialogIsOpen,
      setIsDialogOpen: setJobSaveDialogIsOpen,
      onClose: (answer?: boolean) => {
        if (answer === true) {
          // Navigate to the create page.
          window.location.reload();
        } else {
          // Navigate to the edit page.
          navigate(routes.jobsEdit.url({ id: data.id }));
        }
      },
      headerText: `Job Create`,
      bodyText: `Job created successfully.  Would you like to stay on this Job or create another?`,
      confirmText: `Create Another`,
      cancelText: `Stay`,
    });
  };
  const jobUpdateSuccess = (): Promise<void> | void => {
    setJobSaveDialogIsOpen(true);
    setJobSaveDialogProps({
      isDialogOpen: jobSaveDialogIsOpen,
      setIsDialogOpen: setJobSaveDialogIsOpen,
      onClose: () => {},
      headerText: `Job Update`,
      bodyText: `Job updated successfully.`,
      confirmText: 'OK',
      cancelText: '',
    });
  };
  const saveMutation: JobMatrixSaveMutation = useJobMatrixSave(
    companyId,
    jobMatrix,
    isEditing,
    { onSuccess: jobSaveSuccess },
    { onSuccess: jobUpdateSuccess }
  );
  const [jobMatrixMessage, setJobMatrixMessage] = useState<GeneralMessageType | null>({
    severity: 'transparent',
    message: '',
  });
  const requestParams: JobMatrixGetItemRequest = { companyId, jobMatrixId };

  apiHooksOnBoardWebApi.useJobMatrixReadItem<Error | null>(requestParams, {
    refetchOnWindowFocus: false,
    enabled: !Number.isNaN(jobMatrixId),
    onSuccess(jobMatrixGetResp) {
      setFormIsDisabled(!jobMatrixGetResp.data.isEnabled);
      setJobMatrix({
        ...jobMatrixGetResp.data,
        // `description` is `null` in the DB, but the React DOM prefers `undefined`.
        //	Setting it to empty string would create empty descriptions.
        description: jobMatrixGetResp.data.description || undefined,
      });
      setJobMatrixIsLoading(false);
    },
    onError(jobMatrixGetError) {
      setJobMatrixMessage({ severity: 'danger', message: jobMatrixGetError?.message ?? '' });
      setFormIsDisabled(true);
    },
  });

  const companySeriesRequest: CompanySeriesRequest = {
    companyId,
  };
  const {
    data: companySeriesData,
    isLoading: isCompanySeriesDataLoading,
    isError: isCompanySeriesError,
  } = apiHooks.useCompanySeries<CompanySeriesResponse>(companySeriesRequest);

  const [exprBuilder] = useState(() => new ExpressionBuilder());
  const [companySeriesTasksRequest, setCompanySeriesTasksRequest] =
    useState<CompanySeriesTaskRequest>({
      companyId,
      seriesId: 0,
      includeHistorical: true,
      searchText: '',
    });
  const { data: companySeriesTaskResponseData, isError: isCompanySeriesTaskResponseError } =
    apiHooks.useCompanySeriesTasks<CompanySeriesTaskResponse>(companySeriesTasksRequest);

  useEffect(() => {
    onBoardWebApiSvc
      .userDemographicsReadItem({ userId })
      .then(() => {
        if (jobMatrixMessage?.message === 'Invalid authentication.') {
          setJobMatrixMessage(null);
        }
      })
      .catch(() => setJobMatrixMessage({ severity: 'danger', message: 'Invalid authentication.' }));
  }, [jobMatrixMessage?.message, userId]);

  return (
    <JobsEdit
      company={company}
      user={user}
      companySeriesData={companySeriesData}
      companySeriesTaskResponseData={companySeriesTaskResponseData}
      companySeriesTasksRequest={companySeriesTasksRequest}
      exprBuilder={exprBuilder}
      formIsDisabled={formIsDisabled}
      isCompanyLoading={isCompanyLoading}
      isCompanySeriesDataLoading={isCompanySeriesDataLoading}
      isCompanySeriesError={isCompanySeriesError}
      isCompanySeriesTaskResponseError={isCompanySeriesTaskResponseError}
      isEditing={isEditing}
      isUserLoading={isUserLoading}
      jobMatrix={jobMatrix}
      jobMatrixIsLoading={jobMatrixIsLoading}
      jobMatrixMessage={jobMatrixMessage}
      jobPageTitle={jobPageTitle}
      jobSaveDialogIsOpen={jobSaveDialogIsOpen}
      jobSaveDialogProps={jobSaveDialogProps}
      policies={policies}
      saveMutation={saveMutation}
      useCustomJobsIsEnabled={useCustomJobsIsEnabled}
      setCompanySeriesTasksRequest={setCompanySeriesTasksRequest}
      setJobMatrix={setJobMatrix}
      setJobSaveDialogIsOpen={setJobSaveDialogIsOpen}
      useTaskByJobMatrixReadList={apiHooksOnBoardWebApi.useTaskByJobMatrixReadList}
    />
  );
};

export default JobsEditContainer;
