export default {
  customViewCard: (customViewId: number): string => `customViewCard${customViewId}`,
  drawer: (): string => 'drawer',
  drawerOverlay: (): string => 'drawerOverlay',
  modalBody: (): string => 'modal-body',
  taskProfileCard: (userId: number): string => `userCard${userId}`,
  taskProfileCardProgressChart: (userId: number): string => `userCard${userId}ProgressChart`,
  taskProfileDetailListRole: (id: number): string => `role${id}`,
  taskProfileDetailListTask: (id: number): string => `task${id}`,
  taskProfileDetailListJob: (id: number): string => `job${id}`,
  taskProfileDetailListTaskComponents: (taskId: number): string => `task${taskId}Components`,
  taskProfileDetailListComponent: (id: number): string => `component${id}`,
  taskProfileDetailIncompleteWePe: (id: number): string => `incompleteWePe${id}`,
  multiSelect: (): string => 'multiSelectItemsContainer',
  multiSelectWithDropdown: (): string => 'multiSelectItemsWithDropdownContainer',
  activitiesReportFilterButton: (): string => 'activitiesReportFilterButton',
  pagingPreviousPage: (): string => 'previousPage',
  pagingNextPage: (): string => 'nextPage',
  messageOfTheDayImageInput: (): string => 'messageOfTheDayImageInput',
  dropdownMenuItem: (id: number): string => `dropdownMenuItem${id}`,
  activitiesTable: (): string => 'activitiesTable',
  dropdownMenuToggle: (): string => 'dropdownMenuToggle',
  multiPartSelectSelectedItemsContainer: (): string => 'multiPartSelectSelectedItemsContainer',
  tableContainer: (): string => 'tableContainer',
  tableHeader: (): string => 'tableHeader',
  tableBody: (): string => 'tableBody',
  tableLoadingRow: (): string => 'tableLoadingRow',
  activitiesReportExcelButton: (): string => 'activitiesReportExcelButton',
  toastContainer: (): string => 'toastContainer',
  dateFilterContainer: (): string => 'dateFilterContainer',
  companySidebarSection: (): string => 'companySidebarSection',
  userSidebarSection: (): string => 'userSidebarSection',
  defaultUserIcon: (): string => 'defaultUserIcon',
  affidavitModalCloseButton: (): string => 'affidavitModalCloseButton',
  learningHistorySkillsContainer: (): string => 'learningHistorySkillsContainer',
  learningHistoryQualificationsContainer: (): string => 'learningHistoryQualificationsContainer',
  learningHistoryCollapsibleHeader: (): string => 'learningHistoryCollapsibleHeader',
  learningHistoryReportFilterButton: (): string => 'learningHistoryReportFilterButton',
  createUserIntegrationDragAndDrop: (): string => 'dragAndDropId',
  learningHistoryDropdownToggle: (): string => 'learningHistoryDropdownToggle',
  filterTabButtons: (): string => 'filterTabButtons',
  selectedFiltersContainer: (): string => 'selectedFiltersContainer',
  dropdownItemsContainer: (): string => 'dropdownItemsContainer',
  learningHistoryExcelButton: (): string => 'learningHistoryExcelButton',
  userStatusTooltipContainer: (): string => 'userStatusTooltipContainer',
  usernamePrefixTooltipContainer: (): string => 'usernamePrefixTooltipContainer',
  leadingZeroTooltipContainer: (): string => 'leadingZeroTooltipContainer',
  missingFieldsTooltipContainer: (): string => 'missingFieldsTooltipContainer',
  activitiesActiveDropdown: (): string => 'activitiesActiveDropdown',
  seriesDropdown: (): string => 'seriesDropdown',
  multiPartSelectWithDropdown: (): string => 'multiPartSelectWithDropdown',
  primaryDataPointToggle: (): string => 'primaryDataPointToggle',
  primaryDataPoint: (dataPoint: string): string => `primaryDataPoint-${dataPoint}`,
  cardGridContainer: (): string => 'cardGridContainer',
  mappingCardContainer: (): string => 'mappingCardContainer',
  uncontrolledDropdownItem: (): string => 'uncontrolledDropdownItem',
  listItemWithDropdownToggle: (): string => 'listItemWithDropdownToggle',
  listItemWithDropdownContainer: (): string => 'listItemWithDropdownContainer',
  accordionButton: (): string => 'accordionButton',
  accordionCollapse: (): string => 'accordionCollapse',
  drawerButton: (): string => 'drawerButton',
  drawerContent: (): string => 'drawerContent',
  activitiesDrawer: (): string => 'activitiesDrawer',
  topNavBar: (): string => 'topNavBar',
  jobName: (): string => 'jobName',
  jobDescription: (): string => 'jobDescription',
  shareWithClientCompanies: (): string => 'shareWithClientCompanies',
  useWithCustomFieldVerification: (): string => 'useWithCustomFieldVerification',
  taskProfileDetailListJobItemTasks: (): string => 'taskProfileDetailListJobItemTasks',
  equivalenciesMapperCanvas: (): string => 'equivalenciesMapperCanvas',
  confirmButton: (): string => 'confirmButton',
  apiMappingCard: (): string => 'apiMappingCard',
  generalConfiguration: (): string => 'generalConfiguration',
  mappingCard: (): string => 'mappingCard',
  configurationCard: (): string => 'configurationCard',
  advanceConfigurationCard: (): string => 'advanceConfigurationCard',
  userCredentialsCard: (): string => 'userCredentialsCard',
  searchbox: (): string => 'searchbox',
  tableSkeletonBody: (): string => 'tableSkeletonBody',
  spinner: (): string => 'spinner',
  auditSpinner: (): string => 'auditSpinner',
  lifeCycleToastContainer: (): string => 'lifeCycleToastContainer',
  toggleAdvanceCofiguration: (): string => 'toggleAdvanceCofiguration',
  dropdownMenu: (): string => 'dropdownMenu',
  fileUploadRadioButton: (): string => 'fileUploadRadioButton',
  apiUploadRadioButton: (): string => 'apiUploadRadioButton',
  hasHeaderRow: (): string => 'hasHeaderRow',
  makeAssigmentsOnCreation: (): string => 'makeAssigmentsOnCreation',
  leadingZerosDropdown: (): string => 'leadingZerosDropdown',
  deleteIntegration: (): string => 'deleteIntegration',
  integrationPickerDropdown: (): string => 'integrationPickerDropdown',
  integrationHistoryExcelButton: (): string => 'integrationHistoryExcelButton',
  isEnabledSwitch: (): string => 'isEnabledSwitch',
};
