import apiHooks from '../api/apiHooks';
import { UseDisplayItemsResponse } from '../models/useDisplayItemsResponse';
import displayItemConverters from '../pages/TaskProfileReport/hooks/displayItemConverters';

function useWorkLocations(companyId: number, searchText: string): UseDisplayItemsResponse {
  const { data = [], isLoading } = apiHooks.useCompaniesCompanySites(
    { companyId, searchText },
    { keepPreviousData: true, select: displayItemConverters.convertCompaniesCompanySiteResponse }
  );

  return { data, isLoading };
}

export default useWorkLocations;
