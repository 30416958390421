import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { DisplayItem } from '../models/displayItem';
import displayItemConverters from '../pages/TaskProfileReport/hooks/displayItemConverters';
import { ApiResult } from './apiHooks';
import learningHistoryReportApi from './learningHistoryReportApi';
import { GetLearningHistoryReportRequest } from './requests/getLearningHistoryReport';
import { LearningHistoryReportResponse } from './responses/learningHistoryReportResponse';
import { PaginatedResponse } from './responses/paginatedResponse';
import { QualificationAuditInformationResponse } from './responses/qualificationAuditInformationResponse';

function useGetLearningHistoryReport(
  getLearningHistoryReportRequest: GetLearningHistoryReportRequest
): UseInfiniteQueryResult<PaginatedResponse<LearningHistoryReportResponse>, AxiosError> {
  return useInfiniteQuery(
    ['getLearningHistoryReport', getLearningHistoryReportRequest],
    options =>
      learningHistoryReportApi.getLearningHistoryReport(getLearningHistoryReportRequest, options),
    {
      getNextPageParam: (
        lastPage: PaginatedResponse<LearningHistoryReportResponse>,
        allPages: PaginatedResponse<LearningHistoryReportResponse>[]
      ) => {
        const value = lastPage['@odata.count'];
        const pages = Math.ceil(value / 50);
        if (pages === allPages.length) return undefined;
        return allPages.length * 50;
      },
      retry: false,
    }
  );
}

function useQualificationReasons(companyId: number, searchText: string): ApiResult<DisplayItem[]> {
  return useQuery(
    ['getQualificationsReasons', companyId],
    () => learningHistoryReportApi.getQualificationsReasons(companyId),
    {
      keepPreviousData: true,
      select: reasons => {
        let displayItems = displayItemConverters.convertQualificationsReasons(reasons);

        if (searchText !== '') {
          displayItems = displayItems.filter(s =>
            s.display.toLowerCase().includes(searchText.toLowerCase())
          );
        }
        return displayItems;
      },
    }
  );
}

function useGetLearningHistoryReportExcel(
  getLearningHistoryReportRequest: GetLearningHistoryReportRequest,
  options?: UseQueryOptions<
    number,
    AxiosError,
    number,
    (string | GetLearningHistoryReportRequest)[]
  >
): UseQueryResult<number> {
  return useQuery(
    ['getLearningHistoryReportExcel', getLearningHistoryReportRequest],
    () => learningHistoryReportApi.getLearningHistoryReportExcel(getLearningHistoryReportRequest),
    options
  );
}

function useGetQualificationAuditInformation(
  qualificationId: string,
  options?: UseQueryOptions<
    QualificationAuditInformationResponse[],
    AxiosError,
    QualificationAuditInformationResponse[],
    string[]
  >
): UseQueryResult<QualificationAuditInformationResponse[], AxiosError> {
  return useQuery(
    ['getQualificationAuditInformation', qualificationId],
    () => learningHistoryReportApi.getQualificationAuditInformation(qualificationId),
    options
  );
}

export default {
  useGetLearningHistoryReport,
  useQualificationReasons,
  useGetLearningHistoryReportExcel,
  useGetQualificationAuditInformation,
};
