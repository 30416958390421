import React, { ReactElement } from 'react';
import Nav from 'react-bootstrap/Nav';
import OnBoardLogoMini from '../../../assets/images/OnBoard-logo-transparent-bkg-logo.png';
import OnBoardLogo from '../../../assets/images/OnBoard-logo-transparent-bkg-text.png';

export interface SidebarProps {
  company?: ReactElement;
  children: React.ReactNode;
}

const Sidebar: React.FC<SidebarProps> = ({ children, company }) => {
  return (
    <div className="sidebar" data-color="white" data-active-color="primary">
      <div className="logo d-inline-flex align-items-center justify-content-center py-2 w-100">
        <div className="logo-wrapper logo-wrapper-mini flex-shrink-0">
          <img src={OnBoardLogoMini} alt="mini-logo" className="logo-mini" />
        </div>
        <div className="logo-wrapper logo-wrapper-main">
          <img src={OnBoardLogo} alt="main-logo" className="logo-normal" />
        </div>
      </div>
      <div className="sidebar-wrapper overflow-hidden">
        {company}
        <Nav>{children}</Nav>
      </div>
    </div>
  );
};

export default Sidebar;
