import React, { CSSProperties } from 'react';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
import testIds from '../../../constants/testIds';
import { DisplayItem } from '../../../models/displayItem';
import Button from '../../Buttons/Button/Button';
import SearchBox from '../../SearchBox/SearchBox';
import ListItem from '../ListItem/ListItem';

export interface MultiPartSelectBaseProps<T extends DisplayItem = DisplayItem> {
  items: T[];
  onSearch: (text: string) => void;
  onChange: (item: T) => void;
  numberOfSelected: number;
  emptyListText: string;
  isLoading: boolean;
  onClear: () => void;
  onSelectAll: () => void;
  selectedList: React.ReactElement;
  selectedListItems: Record<number, T>;
  selectedListGroupClassName?: string;
  availableListGroupStyle?: CSSProperties;
  isDisabled?: boolean;
  tabId: string;
}

const MultiPartSelectBase = <T extends DisplayItem>({
  items,
  onSearch,
  onChange,
  numberOfSelected,
  emptyListText,
  isLoading,
  onClear,
  onSelectAll,
  selectedList,
  selectedListItems,
  selectedListGroupClassName = '',
  availableListGroupStyle = {},
  isDisabled = false,
  tabId,
}: MultiPartSelectBaseProps<T>): JSX.Element => {
  const emptyStateText = isLoading ? <Spinner color="secondary" /> : emptyListText;
  const selectedListGroupStyle: CSSProperties = {
    maxHeight: 'calc(50% - 50px)',
    pointerEvents: isDisabled ? 'none' : undefined,
  };
  const availableListStyle: CSSProperties = {
    pointerEvents: isDisabled ? 'none' : undefined,
    ...availableListGroupStyle,
  };
  return (
    <>
      <div className="pt-3">
        <SearchBox onChange={onSearch} isDisabled={isDisabled} />
      </div>
      <div className="mb-1 mt-2">
        <Button
          size="sm"
          variant="transparent"
          onClick={onClear}
          disabled={isDisabled || numberOfSelected <= 0}
        >
          Clear All
        </Button>
        <Button size="sm" variant="transparent" onClick={onSelectAll} disabled={isDisabled}>
          Select All
        </Button>
      </div>
      <Container
        className={selectedListGroupClassName}
        style={selectedListGroupStyle}
        aria-label="selected"
        data-testid={testIds.selectedFiltersContainer()}
      >
        {selectedList}
      </Container>
      {Object.values(selectedListItems).length > 0 ? <hr className="w-100" /> : null}
      {items.length > 0 ? (
        <ListGroup
          id={`${tabId}-panel`}
          className="overflow-auto flex-fill"
          as="ul"
          style={availableListStyle}
          aria-label="available"
          data-testid={testIds.multiSelect()}
        >
          {items.map(listItem =>
            selectedListItems[listItem.itemId] === undefined ? (
              <ListItem
                key={listItem.itemId}
                onClick={() => onChange(listItem)}
                text={listItem.display}
              />
            ) : null
          )}
        </ListGroup>
      ) : (
        emptyStateText
      )}
    </>
  );
};

export default MultiPartSelectBase;
