import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { ComponentResponse } from '../../../../api/responses/componentResponse';
import testIds from '../../../../constants/testIds';

interface TaskProfileDetailListTaskItemComponentsProps {
  taskId: number;
  components?: ComponentResponse[];
}

const TaskProfileDetailListTaskItemComponents: React.FC<
  TaskProfileDetailListTaskItemComponentsProps
> = ({ taskId, components = [] }) => {
  if (components.length === 0) {
    return null;
  }

  return (
    <ListGroup className="w-100" data-testid={testIds.taskProfileDetailListTaskComponents(taskId)}>
      {components.map(component => (
        <ListGroup.Item
          key={component.id}
          className="d-inline-flex justify-content-start align-items-start border-0 py-0"
          data-testid={testIds.taskProfileDetailListComponent(component.id)}
          style={{ fontSize: '0.8571em' }}
        >
          <div className="fw-bold me-2 ms-1" style={{ width: '0.375rem' }}>
            &bull;
          </div>
          <div className="pe-2 flex-fill">{component.name}</div>
          <div className="text-end" style={{ width: '4rem' }}>
            {component.requiredStatusText ? <>{component.requiredStatusText}</> : null}
          </div>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default TaskProfileDetailListTaskItemComponents;
