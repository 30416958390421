import React, { useRef } from 'react';
import useEffectAfterFirstRender from '../../hooks/useEffectAfterFirstRender';
import { GridDimensions } from '../../models/gridDimensions';
import useCardGridDimensions from './useCardGridDimensions';

interface UseCardGrid {
  /**
   * A ref for the HTML Element that will contain the cards
   */
  ref: React.RefObject<HTMLDivElement>;

  /**
   * The dimensions for the number of cards which can fit within the grid
   */
  gridDimensions: GridDimensions;
}

const useCardGrid = (
  /**
   * The minimum width of a card within the grid
   */
  minWidth: number,

  /**
   * The minimum height of a card within the grid.
   */
  minHeight: number,

  /**
   * A callback function which is invoked whenever the grid is resized and the number of cards displayed has changed
   */
  onResize: (numCardsShown: number) => void
): UseCardGrid => {
  const ref = useRef<HTMLDivElement>(null);
  const gridDimensions = useCardGridDimensions(ref, minHeight, minWidth);
  const numOfCards = gridDimensions.numRows * gridDimensions.numColumns;

  useEffectAfterFirstRender(() => {
    onResize(numOfCards);
  }, [numOfCards, onResize]);

  return React.useMemo(() => ({ ref, gridDimensions }), [gridDimensions]);
};

export default useCardGrid;
