import React from 'react';
import commonText from '../../constants/commonText';
import Completed from '../Icons/IcCheck48px';
import NotCompleted from '../Icons/IcClose48px';

export interface CompletionStatusProps {
  isCompleted: boolean;
}

export const CompletionStatus: React.FC<CompletionStatusProps> = ({ isCompleted }) => {
  return isCompleted ? (
    <Completed title={commonText.complete} />
  ) : (
    <NotCompleted title={commonText.incomplete} />
  );
};
