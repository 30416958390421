export enum IntegrationDetailType {
  Error = 1,
  NewUser,
  UpdatedUser,
}

export interface BatchUserStatisticResponse {
  Username: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  FullName: string;
  SupervisorUsername: string;
  Supervisor2Username: string;
  DetailType: IntegrationDetailType;
  DetailTypeDescription: string;
  Error: string;
}
