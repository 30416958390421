/* eslint jsx-a11y/label-has-associated-control: ["error", { assert: "either" } ] */
import React, { ChangeEvent, CSSProperties } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Circle from '../Icons/Circle/Circle';
import testIds from '../../constants/testIds';
/* import { AvailablePolicies } from '../../policyService/policyEnforcer/policyEnforcer'; */
import JobMatrix from '../../models/JobMatrix';

/**
 * @property {JobMatrix} jobMatrix - The Job(Matrix) object being created/edited.
 * @property setJobMatrix - React "state variable" setter for the Job(Matrix).
 * @property {boolean} useCustomJobsIsEnabled - `CompanyPreference.UseCustomJobs` (1<<45).
 * @property {boolean|undefined} isDisabled - Optional indicator whether this component is disabled, false by default.
 */
export interface JobsGeneralInfoProps {
  jobMatrix: JobMatrix;
  setJobMatrix: (jobMatrix: JobMatrix) => void;
  useCustomJobsIsEnabled: boolean;
  isDisabled?: boolean;
  /* policies: Pick<
    AvailablePolicies,
    | 'canViewTaskProfile'
    | 'canCreateCustomView'
    | 'canViewCustomView'
    | 'canViewCustomViewConnections'
  >; */
}

/**
 * Subsection component of creating/editing the general values of a `JobMatrix`.
 */
const JobsGeneralInfo: React.FC<JobsGeneralInfoProps> = ({
  jobMatrix,
  setJobMatrix,
  useCustomJobsIsEnabled,
  isDisabled = false,
  /* policies, */
}) => {
  const useWithCustomFieldVerificationStyle: CSSProperties = {
    display: useCustomJobsIsEnabled ? undefined : 'none',
  };

  const updateJobName = (evt: ChangeEvent<HTMLInputElement>) =>
    setJobMatrix({
      ...jobMatrix,
      ...{ title: evt.target.value },
    });

  const updateJobDescription = (evt: ChangeEvent<HTMLTextAreaElement>) =>
    setJobMatrix({
      ...jobMatrix,
      ...{ description: evt.target.value },
    });

  const updateJobIsShared = (evt: ChangeEvent<HTMLInputElement>) =>
    setJobMatrix({
      ...jobMatrix,
      ...{ isShared: evt.target.checked },
    });

  const updateJobUseWithCustomFieldVerification = (evt: ChangeEvent<HTMLInputElement>) =>
    setJobMatrix({
      ...jobMatrix,
      ...{ useForCustom: evt.target.checked },
    });

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="section" id="general-information">
            <div id="general-info-headers" className="d-inline-flex mt-2">
              <Circle
                className="mx-auto mt-1 text-center text-white"
                color="primary"
                text="1"
                styleParam={{ aspectRatio: '1' }}
              />
              <div>
                <h3 className="header mb-0 ms-1">General Information</h3>
                <h6 className="text-muted ms-1">
                  Start by adding a title and description for the job.
                </h6>
              </div>
            </div>
            <Form aria-disabled={isDisabled}>
              <fieldset className="border rounded-1 p-2" disabled={isDisabled}>
                <legend className="float-none w-auto mb-0 font-size-l">
                  <label htmlFor="jobName" className="form-label required-field-label mb-0">
                    Title
                  </label>
                </legend>
                <div className="control-grouping">
                  <input
                    type="text"
                    className="form-control bg-light"
                    id="jobName"
                    data-testid={testIds.jobName()}
                    value={jobMatrix.title}
                    onChange={updateJobName}
                  />
                </div>
              </fieldset>
              <fieldset className="border rounded-1 p-2" disabled={isDisabled}>
                <legend className="float-none w-auto mb-0 font-size-l">
                  <label htmlFor="jobDescription" className="form-label mb-0">
                    Description
                  </label>
                </legend>
                <div className="control-grouping">
                  <textarea
                    className="form-control bg-light"
                    id="jobDescription"
                    data-testid={testIds.jobDescription()}
                    value={jobMatrix.description}
                    onChange={updateJobDescription}
                  />
                </div>
              </fieldset>
              <fieldset className="border-0 rounded-1 pt-2" disabled={isDisabled}>
                <div className="text-start">
                  <div
                    className="form-check form-check-inline"
                    style={useWithCustomFieldVerificationStyle}
                  >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="useWithCustomFieldVerification"
                      data-testid={testIds.useWithCustomFieldVerification()}
                      checked={jobMatrix.useForCustom}
                      onChange={updateJobUseWithCustomFieldVerification}
                    />
                    <label htmlFor="useWithCustomFieldVerification" className="form-check-label">
                      Use with Custom Field Verification?
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="shareWithClientCompanies"
                      data-testid={testIds.shareWithClientCompanies()}
                      checked={jobMatrix.isShared}
                      onChange={updateJobIsShared}
                    />
                    <label htmlFor="shareWithClientCompanies" className="form-check-label">
                      Share with client companies?
                    </label>
                  </div>
                </div>
              </fieldset>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default JobsGeneralInfo;
