import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppSetupProvider from './AppSetupProvider';
import AuthProvider from './auth/AuthProvider';
import makeFeatureProvider from './feature-flags/FeatureProvider';
import ZoomProvider from './zoom/ZoomProvider';

interface AppProviderProps {
  queryClient: QueryClient;
  children?: React.ReactNode;
}

async function makeAppProvider(): Promise<React.FC<AppProviderProps>> {
  const FeatureProvider = await makeFeatureProvider();

  const AppProvider: React.FC<AppProviderProps> = ({ queryClient, children }) => {
    return (
      <FeatureProvider>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <ZoomProvider zoomLevel={1}>
              <AppSetupProvider>
                <Router>{children}</Router>
              </AppSetupProvider>
            </ZoomProvider>
          </AuthProvider>
          {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
      </FeatureProvider>
    );
  };

  return AppProvider;
}

export default makeAppProvider;
