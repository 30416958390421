import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { IoMdAdd } from 'react-icons/io';
import { ApiResult } from '../../api/apiHooks';
import { CompanyResponse } from '../../api/responses/companyResponse';
import { CustomViewConnection } from '../../api/responses/customViewConnection';
import { CustomViewsResponse } from '../../api/responses/customViewsResponse';
import { UserInformation } from '../../api/responses/userInformation';
import CustomReportCardGrid from '../../components/CustomReport/CardGrid/CustomReportCardGrid';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import Paging from '../../components/Layout/Paging/Paging';
import SwipeContainer from '../../components/Layout/SwipeContainer/SwipeContainer';
import TopNavbar from '../../components/Layout/TopNavBar/TopNavBar';
import SearchBox from '../../components/SearchBox/SearchBox';
import commonText from '../../constants/commonText';
import { TaskProfileReportData } from '../../models/taskProfileReportData';
import { AvailablePolicies } from '../../policyService/policyEnforcer/policyEnforcer';
import pagingUtils from '../../utils/pagingUtils';
import Zoom from '../../zoom/Zoom';

export interface ViewsReportProps {
  data: CustomViewsResponse<TaskProfileReportData>;
  isLoading: boolean;
  isError: boolean;
  customViewConnectionsRequest: ApiResult<Record<number, CustomViewConnection>>;
  currentPage: number;
  take: number;
  isCompanyLoading: boolean;
  company?: CompanyResponse;
  user: UserInformation | undefined;
  isUserLoading: boolean;
  onPageClick: (page: number) => void;
  onPageResize: (numberCardsShown: number) => void;
  onAddViewClick: () => void;
  onCardClick: (id: number) => void;
  onSearch: (text: string) => void;
  policies: Pick<
    AvailablePolicies,
    | 'canViewTaskProfile'
    | 'canCreateCustomView'
    | 'canViewCustomView'
    | 'canViewCustomViewConnections'
    | 'canViewActivities'
    | 'canViewIntegrationHub'
    | 'canViewLearningHistory'
  >;
}

const ViewsReport: React.FC<ViewsReportProps> = ({
  data,
  isLoading,
  isError,
  customViewConnectionsRequest,
  currentPage,
  take,
  isCompanyLoading,
  company = undefined,
  user,
  isUserLoading,
  onPageClick,
  onPageResize,
  onAddViewClick,
  onCardClick,
  onSearch,
  policies,
}) => {
  const totalPages = pagingUtils.getTotalPages(data?.ItemCount ?? 0, take);
  const handleSwipeLeft = () => onPageClick(Math.min(totalPages, currentPage + 1));
  const handleSwipeRight = () => onPageClick(Math.max(1, currentPage - 1));

  return (
    <MainLayout
      isCompanyLoading={isCompanyLoading}
      companyImage={company?.logo}
      companyName={company?.name}
      policies={policies}
    >
      <TopNavbar reportName={commonText.views} user={user} isUserLoading={isUserLoading}>
        <div className="d-inline-flex align-items-center">
          <SearchBox size="sm" onChange={onSearch} />
          <Zoom className="w-auto mx-2" />
        </div>
      </TopNavbar>
      <SwipeContainer
        className="flex-grow-1 p-0 overflow-hidden h-100"
        onSwipeLeft={handleSwipeLeft}
        onSwipeRight={handleSwipeRight}
      >
        <CustomReportCardGrid
          data={data.ResultData}
          isLoading={isLoading}
          isError={isError}
          customViewConnectionsData={customViewConnectionsRequest?.data ?? {}}
          onResize={onPageResize}
          onCardClick={onCardClick}
          policies={policies}
        />
        {policies.canCreateCustomView ? (
          <Button
            className="btn-icon rounded-circle shadow fixed-bottom left-initial mb-2 me-2"
            variant="primary"
            size="lg"
            aria-label="Add Custom View"
            onClick={onAddViewClick}
          >
            <IoMdAdd color="white" />
          </Button>
        ) : null}
      </SwipeContainer>
      <Row className="justify-content-center">
        <Col xs="auto">
          <Paging totalPages={totalPages} currentPage={currentPage} onClick={onPageClick} />
        </Col>
      </Row>
    </MainLayout>
  );
};

export default ViewsReport;
