import React from 'react';
import { useNavigate } from 'react-router-dom';
import useRefreshToken from '../../auth/useRefreshToken';
import routes from '../../constants/routes';
import useCompany from '../../hooks/useCompany';
import useQueryParam from '../../hooks/useQueryParam';
import useUserInformation from '../../hooks/useUserInformation';
import usePolicyService from '../../policyService/usePolicyService';
import AuthorizationErrorPage from './AuthorizationErrorPage';

const AuthorizationErrorPageContainer: React.FC = () => {
  const { company, isLoading: isCompanyLoading } = useCompany();
  const { user, isLoading: isUserLoading } = useUserInformation();
  const { policies } = usePolicyService();
  const params: URLSearchParams = useQueryParam();
  const navigate = useNavigate();
  const { error } = useRefreshToken();
  const callbackUrlProp: string | null = params.get(routes.authorize.queryParams.callbackUrl);

  function pushHistory(callbackUrl: string): void {
    navigate(callbackUrl);
  }

  return (
    <AuthorizationErrorPage
      isCompanyLoading={isCompanyLoading}
      company={company}
      error={error}
      callbackUrl={callbackUrlProp}
      isUserLoading={isUserLoading}
      user={user}
      policies={policies()}
      pushHistory={pushHistory}
    />
  );
};

export default AuthorizationErrorPageContainer;
