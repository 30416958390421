import React, { CSSProperties } from 'react';

export interface SkeletonProps {
  className?: string;
  width?: number | string;
  height?: number | string;
  style?: CSSProperties;
}

const Skeleton: React.FC<SkeletonProps> = ({ className = '', width, height, style = {} }) => {
  return (
    <span
      className={`skeleton ${className}`}
      style={{
        width,
        height,
        ...style,
      }}
    >
      &nbsp;
    </span>
  );
};

export default Skeleton;
