import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import React, { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import apiHooks from '../../api/apiHooks';
import { CompaniesRolesResponse } from '../../api/responses/companiesRolesResponse';
import { CompanySeriesResponse } from '../../api/responses/companySeriesResponse';
import { UserIntegration } from '../../api/responses/userIntegration';
import routes from '../../constants/routes';
import testIds from '../../constants/testIds';
import useCompany from '../../hooks/useCompany';
import useCompanyId from '../../hooks/useCompanyId';
import useGetAllTransferClientUser from '../../hooks/useGetAllTransferClientUser';
import useUserInformation from '../../hooks/useUserInformation';
import CreateUserIntegration from './CreateUserIntegration';

const CreateUserIntegrationContainer: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const userIntegrationId = Number(id);
  const isEditing = !Number.isNaN(userIntegrationId);
  const { user, isLoading: isUserLoading } = useUserInformation();
  const { companyId } = useCompanyId();
  const { company, isLoading: isCompanyLoading } = useCompany();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const navigateTo = (url: string) => {
    navigate(url);
  };
  const integrationType = searchParams.get('integrationType');
  const integrationName = searchParams.get('integrationName');
  const companyImportUserSettingsMutation = apiHooks.useSaveCompanyUserImportSettings(
    companyId,
    userIntegrationId,
    {
      onSuccess: (data: UserIntegration) => {
        if (!isEditing) {
          navigateTo(routes.userIntegrationEdit.url({ id: data.Id }));
        }
      },
    }
  );
  const { companySftpUsers } = useGetAllTransferClientUser(companyId);
  const createCompanySftpUserMutation = apiHooks.useCreateCompanySftpUser(companyId);
  const regenerateCredentials = apiHooks.useRegenerateSftpCredentials({
    companyId,
    companySftpId: companySftpUsers[0]?.Id ?? 0,
  });
  const { data: seriesResponse } = apiHooks.useCompanySeries<CompanySeriesResponse>({
    companyId,
  });

  const { data: rolesList } = apiHooks.useCompaniesRoles<CompaniesRolesResponse>({
    companyId,
    searchText: '',
  });

  const [showToast, setShowToast] = useState<boolean>(false);

  const { data: userIntegration, isFetching: isFetchingUserIntegration } =
    apiHooks.useGetUserIntegration(companyId, userIntegrationId, {
      onError: (error: AxiosError) => {
        if (error.response?.status === StatusCodes.NOT_FOUND) setShowToast(true);
      },
    });

  return (
    <CreateUserIntegration
      user={user}
      isUserLoading={isUserLoading}
      companyId={companyId}
      companyImportSettingsMutation={companyImportUserSettingsMutation}
      companySftpUsers={companySftpUsers}
      dragAndDropInputTestId={testIds.createUserIntegrationDragAndDrop()}
      createCompanySftpUserMutation={createCompanySftpUserMutation}
      regenerateCompanySftpuserMutation={regenerateCredentials}
      availableSeriesList={seriesResponse?.series ?? []}
      availableRolesList={rolesList?.roles ?? []}
      navbarTitle={isEditing ? 'Edit' : 'Create'}
      userIntegration={userIntegration || null}
      isUserIntegrationLoading={isFetchingUserIntegration}
      isEditing={isEditing}
      showToast={showToast}
      setShowToast={setShowToast}
      navigateTo={navigateTo}
      companyImage={company?.logo}
      companyName={company?.name}
      isCompanyLoading={isCompanyLoading}
      integrationTypeQueryParam={integrationType}
      integrationNameQueryParam={integrationName}
    />
  );
};

export default CreateUserIntegrationContainer;
