import { useEffect } from 'react';
import { OneMinuteInMilliseconds } from '../../../constants/measurements';
import useIdle from '../../../hooks/useIdle';

export const messageOfTheDayInactivityOptions = { idleTimeMilliseconds: OneMinuteInMilliseconds };

function useMessageOfTheDayInactivity(
  isEnabled: boolean,
  isShownWhenInactive: boolean,
  isUserDetailModalOpen: boolean,
  onInactive: () => void
): void {
  const isIdle = useIdle(messageOfTheDayInactivityOptions);

  useEffect(() => {
    if (isIdle && isEnabled && isShownWhenInactive) {
      onInactive();
    }
  }, [isIdle, isEnabled, isShownWhenInactive, onInactive]);

  useEffect(() => {
    if (!isUserDetailModalOpen && isEnabled && isShownWhenInactive) {
      onInactive();
    }
  }, [isEnabled, isShownWhenInactive, isUserDetailModalOpen, onInactive]);
}

export default useMessageOfTheDayInactivity;
