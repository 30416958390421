function insertOrRemove<T extends unknown>(
  item: T,
  currentItems: T[],
  comparisonFunc: (item1: T, item2: T) => boolean
): T[] {
  const isRemovingItem = currentItems.some(o => comparisonFunc(o, item));

  if (isRemovingItem) {
    return currentItems.filter(o => !comparisonFunc(o, item));
  }

  return currentItems.concat(item);
}

function areEqual<T extends unknown>(array1: T[], array2: T[]): boolean {
  if (array1.length !== array2.length) {
    return false;
  }

  return array1.every((value, index) => {
    return value === array2[index];
  });
}

export default {
  insertOrRemove,
  areEqual,
};
