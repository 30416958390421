import React from 'react';
import Card from 'react-bootstrap/Card';
import CloseButton from 'react-bootstrap/CloseButton';
import Image from '../../Image/Image';

export interface MessageOfTheDayProps {
  title: string;
  description: string;
  image: string;
  className?: string;
  onImageRemoved?: () => void;
}

const MessageOfTheDay: React.FC<MessageOfTheDayProps> = ({
  title,
  description,
  image,
  className = '',
  onImageRemoved,
}) => {
  return (
    <Card className={`text-center ${className}`}>
      <Card.Body className="flex-shrink-0">
        <Card.Title as="h4">{title}</Card.Title>
        <Card.Text className="white-space-pre-wrap">{description}</Card.Text>
      </Card.Body>
      {image && (
        <Card.Body className="flex-shrink-1 pt-0">
          {onImageRemoved !== undefined ? <CloseButton onClick={onImageRemoved} /> : null}
          <Image
            className="card-img mh-100 mw-100 w-auto"
            src={image}
            alt="Message Of The Day Image"
          />
        </Card.Body>
      )}
    </Card>
  );
};

export default MessageOfTheDay;
