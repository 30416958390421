import apiHooks from '../../../api/apiHooks';
import { UseDisplayItemsResponse } from '../../../models/useDisplayItemsResponse';
import displayItemConverters from './displayItemConverters';

function useEmployees(companyId: number, searchText: string): UseDisplayItemsResponse {
  const { data = [], isLoading } = apiHooks.useCompaniesUsers(
    { companyId, searchText, isVisible: true },
    {
      keepPreviousData: true,
      select: displayItemConverters.convertCompaniesUsersResponse,
    }
  );

  return { data, isLoading };
}

export default useEmployees;
