import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import apiHooks from '../../../api/apiHooks';
import { CustomViewAccessRequest } from '../../../api/requests/customViewAccessRequest';
import { CustomViewSaveRequestBody } from '../../../api/requests/customViewSaveRequest';
import routes from '../../../constants/routes';
import { CustomViewSaveMutations } from '../../../models/customViewSaveMutations';

function useSave(
  companyId: number,
  customViewId: number,
  isEditing: boolean
): CustomViewSaveMutations {
  const navigate = useNavigate();
  const isAfterCreateSuccessful = useRef(false);

  const createMutation = apiHooks.useCreateCustomView({
    onSuccess: data => {
      navigate(routes.taskProfileReportCustomView.url({ id: data.id, isDrawerExpanded: true }));
      isAfterCreateSuccessful.current = true;
    },
  });

  const handleCreateReport = (
    data: CustomViewSaveRequestBody,
    customViewAccessRequests: CustomViewAccessRequest[],
    messageOfTheDayImage?: File
  ) => {
    createMutation.mutate({
      companyId,
      data,
      customViewAccessRequests,
      messageOfTheDayImage,
    });
  };

  const updatedMutation = apiHooks.useUpdateCustomView({
    onSuccess: () => {
      isAfterCreateSuccessful.current = false;
    },
  });

  const handleUpdateReport = (
    data: CustomViewSaveRequestBody,
    customViewAccessRequests: CustomViewAccessRequest[],
    messageOfTheDayImage?: File
  ) => {
    updatedMutation.mutate({
      customViewId,
      companyId,
      data,
      customViewAccessRequests,
      messageOfTheDayImage,
    });
  };

  return {
    onSave: isEditing ? handleUpdateReport : handleCreateReport,
    isError: isEditing ? updatedMutation.isError : createMutation.isError,
    isSuccess: isEditing
      ? updatedMutation.isSuccess || isAfterCreateSuccessful.current
      : createMutation.isSuccess,
    isLoading: isEditing ? updatedMutation.isLoading : createMutation.isLoading,
  };
}

export default useSave;
