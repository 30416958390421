/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { AiOutlineMinusSquare, AiOutlinePlusSquare } from 'react-icons/ai';
import { BsCheckCircle, BsXCircle } from 'react-icons/bs';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import {
  LearningHistoryQualification,
  LearningHistoryReport,
} from '../../../api/responses/learningHistoryReportResponse';
import testIds from '../../../constants/testIds';
import { TableColumnModel } from '../../../models/TableColumnModel';
import dateFormatter from '../../../utils/dateFormatter';
import Accordion from '../../Accordion/Accordion';
import LearningHistorySkill from '../LearningHistorySkills/LearningHistorySkills';

export interface LearningHistoryCollapsibleRowProps {
  data: LearningHistoryReport;
  columns: TableColumnModel[];
  setQualificationId: React.Dispatch<string>;
  setTaskName: React.Dispatch<string>;
}

const LearningHistoryCollapsibleRow: React.FC<LearningHistoryCollapsibleRowProps> = ({
  data,
  columns,
  setQualificationId,
  setTaskName,
}) => {
  const onClick = (qualification: LearningHistoryQualification) => {
    setQualificationId(qualification.Qualification_Report_Id);
    setTaskName(qualification.Task_Name);
  };

  const renderQualifications = data.Qualifications.map(
    (qualification: LearningHistoryQualification, index: number) => {
      const header = (isOpen: boolean) => (
        <div
          key={qualification.Qualification_Report_Id}
          className={`w-100 d-flex flex-row flex-nowrap align-items-center ${
            index % 2 === 0 ? 'table-row-clear-background-color' : 'table-row-background-color'
          }`}
          style={{ height: `${45}px` }}
        >
          <div className="p-2" style={{ flexBasis: `${columns[0].width}%` }}>
            {isOpen ? (
              <IoIosArrowDown className="arrow-down" size={16} />
            ) : (
              <IoIosArrowForward className="arrow-forward" size={16} />
            )}
          </div>
          <div
            className="text-truncate p-2 text-start"
            style={{ flexBasis: `${columns[1].width}%` }}
          >
            {qualification.Task_Code}
          </div>
          <div
            className="text-truncate p-2 text-start text-decoration-underline"
            style={{ flexBasis: `${columns[2].width}%` }}
            onClick={e => {
              onClick(qualification);
              e.stopPropagation();
            }}
          >
            {qualification.Task_Name}
          </div>
          <div
            className="text-truncate p-2 text-start"
            style={{ flexBasis: `${columns[3].width}%` }}
          >
            {dateFormatter.formatDate(qualification.Qualification_Completed, 'MM/dd/yyyy')}
          </div>
          <div
            className="text-truncate p-2 text-start"
            style={{ flexBasis: `${columns[4].width}%` }}
          >
            {dateFormatter.formatDate(qualification.Qualification_Expires, 'MM/dd/yyyy')}
          </div>
          <div
            className="text-truncate p-2 text-start"
            style={{ flexBasis: `${columns[5].width}%` }}
          >
            {qualification.Qualification_Status === 'Qualified' ? (
              <BsCheckCircle fontSize={24} color="green" />
            ) : (
              <BsXCircle fontSize={24} color="red" />
            )}
          </div>
          <div className="text-truncate p-2" style={{ flexBasis: `${columns[6].width}%` }}>
            {qualification.Qualification_Status === 'Qualified' ? '' : qualification.Reason}
          </div>
        </div>
      );

      return (
        <div key={qualification.Qualification_Report_Id}>
          <Accordion
            specialToggle={header}
            toggleColor="light"
            buttonClassName="p-0 table-body-row"
          >
            <LearningHistorySkill
              skills={qualification.Activities}
              columns={columns}
              className={
                index % 2 === 0 ? 'table-row-clear-background-color' : 'table-row-background-color'
              }
            />
          </Accordion>
        </div>
      );
    }
  );

  const userToggle = (isOpen: boolean) => {
    return (
      <div
        className="w-100 d-flex flex-row flex-nowrap align-items-center"
        data-testid={testIds.learningHistoryCollapsibleHeader()}
      >
        <div className="p-2" style={{ flexBasis: `${columns[0].width}%` }}>
          {isOpen ? (
            <AiOutlineMinusSquare size={24} className="user-expanded" />
          ) : (
            <AiOutlinePlusSquare size={24} className="user-collapsed" />
          )}
        </div>
        <div className="p-2">
          {data.User_Full_Name} ({data.User_Name} | {data.User_Id}) - {data.Company_Name}
        </div>
      </div>
    );
  };

  return (
    <Accordion defaultOpen specialToggle={userToggle} toggleColor="primary" buttonClassName="px-0">
      <div data-testid={testIds.learningHistoryQualificationsContainer()}>
        {renderQualifications}
      </div>
    </Accordion>
  );
};

export default LearningHistoryCollapsibleRow;
