/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { ModalHeaderProps, ModalProps } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import testIds from '../../constants/testIds';

export interface DialogProps extends ModalProps {
  header: React.ReactNode;
  body: React.ReactNode;
  footer: React.ReactNode;
  headerProps?: ModalHeaderProps;
}

const Dialog: React.FC<DialogProps> = ({ header, body, footer, headerProps, ...props }) => {
  return (
    <Modal {...props}>
      <Modal.Header {...headerProps}>{header}</Modal.Header>
      <Modal.Body data-testid={testIds.modalBody()}>{body}</Modal.Body>
      <Modal.Footer>{footer}</Modal.Footer>
    </Modal>
  );
};

export default Dialog;
