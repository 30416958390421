import React, { FC } from 'react';
import Button from '../Buttons/Button/Button';
import Dialog from '../Dialog/Dialog';

export interface ConfirmDialogProps {
  isDialogOpen: boolean;
  setIsDialogOpen: (isOpen: boolean) => void;
  headerText: string;
  bodyText: string;
  onClose: (isConfirmed: boolean) => void;
  confirmText?: string;
  cancelText?: string;
  size?: 'sm' | 'lg' | 'xl';
  confirmButtonVariant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'info'
    | 'light'
    | 'dark'
    | 'link'
    | 'transparent'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-success'
    | 'outline-warning'
    | 'outline-danger'
    | 'outline-info'
    | 'outline-light'
    | 'outline-dark'
    | 'outline-transparent';
  cancelButtonVariant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'info'
    | 'light'
    | 'dark'
    | 'link'
    | 'transparent'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-success'
    | 'outline-warning'
    | 'outline-danger'
    | 'outline-info'
    | 'outline-light'
    | 'outline-dark'
    | 'outline-transparent';
}

/**
 * @param {ConfirmDialogProps} options - Configuration options.
 * @param {boolean} options.isDialogOpen - Specifies whether dialog is initially open.
 * @param {boolean} options.setIsDialogOpen - Opens and closes the dialog.
 * @param {string} options.headerText - Display text of dialog header.
 * @param {string} options.bodyText - Display text of dialog body.
 * @param {function(isConfirmed?:boolean):void} options.onClose - Callback when dialog is closed.  `isConfirmed` will be `undefined` if the dialog is closed without a button.
 * @param {string} [options.confirmText="Confirm"] - Optional display text of button which closes dialog with `true`, defaults to "Confirm".
 * @param {string} [options.cancelText="Cancel"] - Optional display text of button which closes dialog with `false`, defaults to "Cancel".  An empty string signfies it is used and will be hidden.
 * @param {string} options.size - General size of dialog, one of 'sm', 'md', 'lg, or 'xl'.
 * @returns a `reactstrap` `Modal`.
 */
const ConfirmDialog: FC<ConfirmDialogProps> = ({
  isDialogOpen,
  setIsDialogOpen,
  headerText,
  bodyText,
  onClose,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  size = 'lg',
  cancelButtonVariant = 'primary',
  confirmButtonVariant = 'secondary',
}) => {
  const closeWith = (response: boolean) => {
    onClose(response);
    setIsDialogOpen(false);
  };

  return (
    <Dialog
      className="confirm-dialog"
      show={isDialogOpen}
      size={size}
      scrollable
      onExit={() => {}}
      onHide={() => closeWith(false)}
      headerProps={{ className: 'px-3 pt-3 pb-0 border-0', closeButton: true }}
      header={headerText}
      body={bodyText}
      centered
      footer={
        <>
          <Button onClick={() => closeWith(true)} variant={confirmButtonVariant}>
            {confirmText}
          </Button>
          <Button
            variant={cancelButtonVariant}
            onClick={() => closeWith(false)}
            hidden={cancelText === ''}
          >
            {cancelText}
          </Button>
        </>
      }
    />
  );
};

export default ConfirmDialog;
