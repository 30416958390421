import React from 'react';

interface IcClose48pxProps {
  className?: string;
  title?: string;
}

const IcClose48px: React.FC<IcClose48pxProps> = ({
  className = 'icon-32',
  title = 'ic close 48px',
}) => {
  return (
    <svg
      className={className}
      height="32"
      width="32"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill="#fd2929">
        <path d="M38 12.83L35.17 10 24 21.17 12.83 10 10 12.83 21.17 24 10 35.17 12.83 38 24 26.83 35.17 38 38 35.17 26.83 24z" />
      </g>
    </svg>
  );
};

export default IcClose48px;
