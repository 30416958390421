import React, { CSSProperties, useCallback } from 'react';
import testIds from '../../constants/testIds';

/**
 * @param {HTMLElement|null} expressionAsHTML - The HTML representation of the equivalencies expression (calculated by `TreeToDiagramTraverserDelegate`).
 * @param {boolean|undefined} isDisabled - Optional indicator whether this component is disabled, false by default.
 */
export interface EquivalenciesExpressionCanvasProps {
  expressionAsHTML: HTMLElement | null;
  isDisabled?: boolean;
}

/**
 * Wraps the `expressionAsHTML` as a component for displaying logic expressions.
 * @see {@linkcode EquivalenciesExpressionPalette}.
 */
const EquivalenciesExpressionCanvas: React.FC<EquivalenciesExpressionCanvasProps> = ({
  expressionAsHTML,
  isDisabled = false,
}) => {
  const expressionChanged = useCallback(
    (element: HTMLDivElement | null): void => {
      if (element?.hasChildNodes()) {
        element?.childNodes.forEach(childNode => childNode.remove());
      }
      if (expressionAsHTML) {
        element?.appendChild(expressionAsHTML);
      }
    },
    [expressionAsHTML]
  );
  const canvasDivStyle: CSSProperties = {
    pointerEvents: isDisabled ? 'none' : undefined,
  };
  return (
    <div
      className="canvas"
      data-testid={testIds.equivalenciesMapperCanvas()}
      ref={expressionChanged}
      style={canvasDivStyle}
    />
  );
};

export default EquivalenciesExpressionCanvas;
