import React from 'react';
import { Color } from '../../types/color';
import RadioButton from './RadioButton';

export interface RadioButtonGroupProps<T> {
  list: Array<T>;
  name: string;
  isSelected: (item: T) => boolean;
  getLabel: (item: T) => string;
  getValue: (item: T) => string | number;
  getId?: (item: T) => string | number;
  onChange?: (item: T) => void;
  orientation?: 'horizontal' | 'vertical';
  color?: Color;
  groupStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  className?: string;
  isDisabled?: (item: T) => boolean;
}

function RadioButtonGroup<T>({
  list,
  name,
  isSelected,
  getLabel,
  getValue,
  getId = item => `${name}-${getValue(item)}`,
  onChange = () => {},
  orientation = 'horizontal',
  color = 'primary',
  groupStyle,
  inputStyle,
  className,
  isDisabled = () => false,
}: RadioButtonGroupProps<T>): JSX.Element {
  return (
    <div className="d-flex">
      <div className="d-flex justify-content-center" style={groupStyle}>
        <div
          role="group"
          className={`custom-radio-group-${color} ${className ?? ''} ${
            orientation === 'horizontal' ? 'btn-group' : 'btn-group-vertical'
          }`}
        >
          {list.map(item => {
            const itemId = getId(item);
            const itemValue = getValue(item);
            return (
              <RadioButton<T>
                key={itemId}
                name={name}
                item={item}
                getId={getId}
                getLabel={getLabel}
                getValue={() => itemValue}
                onChange={onChange}
                isSelected={isSelected}
                style={inputStyle}
                isDisabled={isDisabled}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default RadioButtonGroup;
