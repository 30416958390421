import apiHooks from '../api/apiHooks';
import { UseDisplayItemsResponse } from '../models/useDisplayItemsResponse';
import displayItemConverters from '../pages/TaskProfileReport/hooks/displayItemConverters';

function useSeries(companyId: number, searchText: string): UseDisplayItemsResponse {
  const {
    data = [],
    isLoading,
    isSuccess,
  } = apiHooks.useCompanySeries(
    { companyId },
    {
      keepPreviousData: true,
      select: series => {
        let displayItems = displayItemConverters.convertCompanySeriesResponse(series);

        if (searchText !== '') {
          displayItems = displayItems.filter(s =>
            s.display.toLowerCase().includes(searchText.toLowerCase())
          );
        }
        return displayItems;
      },
    }
  );

  return { data, isLoading, isSuccess };
}

export default useSeries;
