import apiHooks from '../api/apiHooks';
import { CompanySftpUserResponse } from '../api/responses/getCompanySftpUserResponse';

export interface GetAllTransferClientUserHook {
  companySftpUsers: CompanySftpUserResponse[];
  isLoading: boolean;
}

function useGetAllTransferClientUser(companyId: number): GetAllTransferClientUserHook {
  const { data, isLoading } = apiHooks.useGetAllTransferClientUser(companyId);

  return {
    companySftpUsers: data ?? [],
    isLoading,
  };
}

export default useGetAllTransferClientUser;
