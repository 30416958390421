import { MappingOption } from '../../models/MappingOption';

export interface DropDownSelectionType {
  email: MappingOption;
  username: MappingOption;
  username2: MappingOption;
  firstName: MappingOption;
  middleName: MappingOption;
  lastName: MappingOption;
  hireDate: MappingOption;
  jobTitle: MappingOption;
  supervisorUsername: MappingOption;
  supervisor2Username: MappingOption;
  department: MappingOption;
  status: MappingOption;
  costCenter: MappingOption;
  companySite: MappingOption;
  companyId: MappingOption;
  companyName: MappingOption;
  role: MappingOption;
}

const defaultDropDownSelections: DropDownSelectionType = {
  email: {
    dataPointId: 'email',
    columnName: '',
    columnNumber: null,
    dataPointLabel: 'Email',
    isRequired: true,
  },
  username: {
    dataPointId: 'username',
    columnName: '',
    columnNumber: null,
    dataPointLabel: 'Username',
    isRequired: true,
  },
  username2: {
    dataPointId: 'username2',
    columnName: '',
    columnNumber: null,
    dataPointLabel: 'Username 2',
    isRequired: false,
  },
  firstName: {
    dataPointId: 'firstName',
    columnName: '',
    columnNumber: null,
    dataPointLabel: 'First Name',
    isRequired: true,
  },
  middleName: {
    dataPointId: 'middleName',
    columnName: '',
    columnNumber: null,
    dataPointLabel: 'Middle Name',
    isRequired: false,
  },
  lastName: {
    dataPointId: 'lastName',
    columnName: '',
    columnNumber: null,
    dataPointLabel: 'Last Name',
    isRequired: true,
  },
  hireDate: {
    dataPointId: 'hireDate',
    columnName: '',
    columnNumber: null,
    dataPointLabel: 'Hire Date',
    isRequired: false,
  },
  jobTitle: {
    dataPointId: 'jobTitle',
    columnName: '',
    columnNumber: null,
    dataPointLabel: 'Job Title',
    isRequired: false,
  },
  supervisorUsername: {
    dataPointId: 'supervisorUsername',
    columnName: '',
    columnNumber: null,
    dataPointLabel: 'Supervisor Username',
    isRequired: false,
  },
  supervisor2Username: {
    dataPointId: 'supervisor2Username',
    columnName: '',
    columnNumber: null,
    dataPointLabel: 'Supervisor 2 Username',
    isRequired: false,
  },
  department: {
    dataPointId: 'department',
    columnName: '',
    columnNumber: null,
    dataPointLabel: 'Department',
    isRequired: false,
  },
  status: {
    dataPointId: 'status',
    columnName: '',
    columnNumber: null,
    dataPointLabel: 'User Status',
    isRequired: true,
  },
  costCenter: {
    dataPointId: 'costCenter',
    columnName: '',
    columnNumber: null,
    dataPointLabel: 'Cost Center',
    isRequired: false,
  },
  companySite: {
    dataPointId: 'companySite',
    columnName: '',
    columnNumber: null,
    dataPointLabel: 'Work Location',
    isRequired: false,
  },
  companyId: {
    dataPointId: 'companyId',
    columnName: '',
    columnNumber: null,
    dataPointLabel: 'Company Id',
    isRequired: false,
  },
  companyName: {
    dataPointId: 'companyName',
    columnName: '',
    columnNumber: null,
    dataPointLabel: 'Company Name',
    isRequired: false,
  },
  role: {
    dataPointId: 'role',
    columnName: '',
    columnNumber: null,
    dataPointLabel: 'Role',
    isRequired: false,
  },
};

export default defaultDropDownSelections;
