import React from 'react';
import Form from 'react-bootstrap/Form';
import { Color } from '../../types/color';

export interface SwitchProps {
  label: string;
  color?: Color;
  inputStyle?: React.CSSProperties;
  className?: string;
  onChange?: () => void;
  checked?: boolean;
  id: string;
  testId?: string;
}

const Switch: React.FC<SwitchProps> = ({
  label,
  color = 'primary',
  id,
  onChange,
  checked,
  inputStyle,
  className,
  testId,
}) => {
  return (
    <div className="d-flex">
      <div className="d-flex justify-content-center" style={inputStyle}>
        <Form.Check
          id={id}
          onChange={onChange}
          checked={checked}
          className={`custom-switch-${color} ${className ?? ''}`}
          type="switch"
          label={label}
          data-testid={testId}
        />
      </div>
    </div>
  );
};

export default Switch;
