import React from 'react';
import Button from 'react-bootstrap/Button';
import testIds from '../../../constants/testIds';

export interface DrawerToggleProps {
  onToggle: () => void;
  expanded: boolean;
}

const DrawerToggle: React.FC<DrawerToggleProps> = ({ onToggle, expanded }) => {
  return (
    <Button
      className={`drawer-btn drawer-btn-center d-flex flex-row justify-content-center align-items-center px-0 py-3 ${
        expanded ? 'drawer-btn-expanded' : ''
      }`}
      variant="light"
      onClick={onToggle}
      aria-label="toggle drawer"
      data-testid={testIds.drawerButton()}
    >
      <div className="drawer-btn-bar me-1" />
      <div className="drawer-btn-bar" />
    </Button>
  );
};

export default DrawerToggle;
