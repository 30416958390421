import { datadogRum } from '@datadog/browser-rum';
import React from 'react';
import AppRouting from './AppRouting';

const App: React.FC = () => {
  datadogRum.init({
    applicationId: 'f25a7f12-16b9-4dcf-87cf-04a2e50d881c',
    clientToken: 'pub6dbf8597a5632815a940668c0e92ba93',
    site: 'datadoghq.com',
    service: 'onboard-live',
    env: process.env.DATA_DOG_ENV as string,
    sampleRate: 100,
    sessionReplaySampleRate: 20,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
  });
  datadogRum.startSessionReplayRecording();
  return <AppRouting />;
};

export default App;
