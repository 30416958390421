import axios, { AxiosRequestConfig } from 'axios';
import QueryString from 'qs';
// eslint-disable-next-line import/no-cycle
import authService from '../auth/authService';
import { TwentySecondsInMilliseconds } from '../constants/measurements';
import TaskModel from '../models/TaskModel';
import file from '../utils/file';
import {
  CompaniesCompanySitesRoute,
  CompaniesCostCentersRoute,
  CompaniesDepartmentsRoute,
  CompaniesJobsRoute,
  CompaniesRolesRoute,
  CompaniesSupervisorsRoute,
  CompaniesUsersAllRoute,
  CompaniesUsersComponentsRoute,
  CompaniesUsersJobsRoute,
  CompaniesUsersRolesRoute,
  CompaniesUsersRoute,
  CompaniesUsersTasksRoute,
  CompaniesUserStatisticsRoute,
  CompaniesUsersTrainingsRoute,
  CompanyDetailsRoute,
  CompanyQualificationsRoute,
  CompanySeriesRoute,
  CompanySeriesTasksRoute,
  DeleteCustomViewRoute,
  GetAllTransferClientUser,
  GetCompanyAssignersRoute,
  GetCompanyEvaluatorsRoute,
  GetCustomViewAccessUsersRoute,
  GetCustomViewConnectionsRoute,
  GetMessageOfTheDayImageRoute,
  GetMessageOfTheDayImageUrlsRoute,
  GetUserInformation,
  GetUserIntegrationRoute,
  GetUsersCustomViewPoliciesRoute,
  GetUsersPoliciesRoute,
  PostCompanySftpUserRoute,
  PostCompanyUserImportSettingsRoute,
  PostRegenerateCompanySftpCredentialsRoute,
  PutCompanyUserImportSettingsRoute,
  ReadCustomViewsRoute,
  RefreshTokenRoute,
  SaveCustomViewRoute,
  UpdateCustomViewAccessRoute,
  UpdateCustomViewConnectionRoute,
  UpdateCustomViewRoute,
  UsersCustomViewsRoute,
} from './apiRoutes';
import { CompaniesCompanySitesRequest } from './requests/companiesCompanySitesRequest';
import { CompaniesCostCentersRequest } from './requests/companiesCostCentersRequest';
import { CompaniesDepartmentsRequest } from './requests/companiesDepartmentsRequest';
import { CompaniesJobsRequest } from './requests/companiesJobsRequest';
import { CompaniesRolesRequest } from './requests/companiesRolesRequest';
import { CompaniesSupervisorsRequest } from './requests/companiesSupervisorsRequest';
import { CompaniesUsersAllRequest } from './requests/companiesUsersAllRequest';
import { CompaniesUsersJobsRequest } from './requests/companiesUsersJobsRequest';
import { CompaniesUsersRequest } from './requests/companiesUsersRequest';
import { CompaniesUsersRolesRequest } from './requests/companiesUsersRolesRequest';
import { CompaniesUserStatisticsRequest } from './requests/companiesUsersStatisticsRequest';
import { CompaniesUsersTasksRequest } from './requests/companiesUsersTasksRequest';
import { CompaniesUsersTrainingsRequest } from './requests/companiesUsersTrainingsRequest';
import { CompanyQualificationRequest } from './requests/companyQualificationRequest';
import { CompanySeriesRequest } from './requests/companySeriesRequest';
import { CompanySeriesTaskRequest } from './requests/companySeriesTaskRequest';
import { CustomViewSaveRequest } from './requests/customViewSaveRequest';
import { DeleteCustomViewRequest } from './requests/deleteCustomViewRequest';
import { GetCustomViewAccessUsersRequest } from './requests/getCustomViewAccessUsersRequest';
import { GetCustomViewConnectionsRequest } from './requests/getCustomViewConnectionRequest';
import { GetCustomViewRequest } from './requests/getCustomViewRequest';
import { GetMessageOfTheDayImageRequest } from './requests/getMessageOfTheDayImageRequest';
import { GetMessageOfTheDayImageUrlsRequest } from './requests/getMessageOfTheDayImageUrlsRequest';
import { GetUserPoliciesRequest } from './requests/getUserPoliciesRequest';
import { GetUsersCustomViewPoliciesRequest } from './requests/getUsersCustomViewPoliciesRequest';
import { SaveCompanyImportSettingsRequest } from './requests/PostCompanyImportSettingsRequest';
import { PutMessageOfTheDayImageUrlsRequest } from './requests/putMessageOfTheDayImageUrlsRequest';
import { UpdateCustomViewAccessRequest } from './requests/updateCustomViewAccessRequest';
import { UpdateCustomViewConnectionRequest } from './requests/updateCustomViewConnectionRequest';
import { UpdateCustomViewRequest } from './requests/updateCustomViewRequest';
import { UsersCustomViewsRequest } from './requests/usersCustomViewsRequest';
import { CompaniesCompanySiteResponse } from './responses/companiesCompanySiteResponse';
import { CompaniesCostCentersResponse } from './responses/companiesCostCentersResponse';
import { CompaniesDepartmentsResponse } from './responses/companiesDepartmentsResponse';
import { CompaniesJobsResponse } from './responses/companiesJobsResponse';
import { CompaniesRolesResponse } from './responses/companiesRolesResponse';
import { CompaniesSupervisorsResponse } from './responses/companiesSupervisorsResponse';
import { CompaniesUsersJobsResponse } from './responses/companiesUsersJobsResponse';
import { CompaniesUsersResponse } from './responses/companiesUsersResponse';
import { CompaniesUsersRolesResponse } from './responses/companiesUsersRolesResponse';
import { CompaniesUsersTasksResponse } from './responses/companiesUsersTasksResponse';
import { CompanyQualificationResponse } from './responses/companyQualificationResponse';
import { CompanyResponse } from './responses/companyResponse';
import { CompanySeriesResponse } from './responses/companySeriesResponse';
import { CompanySeriesTaskResponse } from './responses/companySeriesTaskResponse';
import { CreateCompanySftpUserResponse } from './responses/createCompanySftpUserResponse';
import { CustomViewConnectionsResponse } from './responses/customViewConnectionsResponse';
import { CustomViewReport } from './responses/customViewReport';
import { CustomViewsResponse } from './responses/customViewsResponse';
import { CompanySftpUserResponse } from './responses/getCompanySftpUserResponse';
import { GetCustomViewAccessUsersResponse } from './responses/getCustomViewAccessUsersResponse';
import { GetMessageOfTheDayImageResponse } from './responses/getMessageOfTheDayImageResponse';
import { GetMessageOfTheDayImageUrlsResponse } from './responses/getMessageOfTheDayImageUrlsResponse';
import { GetUserPoliciesResponse } from './responses/getUserPoliciesResponse';
import { GetUsersCustomViewPoliciesResponse } from './responses/getUsersCustomViewPoliciesResponse';
import { JWTTokenResponse } from './responses/jwtTokenResponse';
import { PutMessageOfTheDayImageUrlsResponse } from './responses/putMessageOfTheDayImageUrlsResponse';
import { UserComponentResponse } from './responses/userComponentResponse';
import { UserInformation } from './responses/userInformation';
import { UserIntegration } from './responses/userIntegration';
import { UserResponse } from './responses/userResponse';
import { UserStatisticsResponse } from './responses/userStatisticsResponse';
import { UserTrainingResponse } from './responses/userTrainingResponse';
import requestParamUtils from './utils/requestParamUtils';

const unAuthenticated = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/verifyAPI`,
  headers: {
    'Content-Type': 'application/json',
  },
});

const verifyApi = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/verifyAPI`,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: {
    serialize: params => QueryString.stringify(params),
  },
});

verifyApi.interceptors.request.use(async config => {
  const bearerToken = await authService.getAuthSession();
  if (config !== undefined && config.headers !== undefined) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${bearerToken.token.idToken}`;
  }

  return config;
});

const awsApi = axios.create();

async function refreshToken(userId: number, token: string): Promise<JWTTokenResponse> {
  const response = await unAuthenticated.post<JWTTokenResponse>(RefreshTokenRoute(), {
    UserId: userId,
    RefreshToken: token,
  });

  return response.data;
}

async function getCompanyDetails(companyId: number): Promise<CompanyResponse> {
  const response = await verifyApi.get(CompanyDetailsRoute(companyId));

  return {
    id: response.data.Id,
    name: response.data.Name,
    logo: `${process.env.REACT_APP_STATIC_LOCATION}/img/logos/${response.data.Logo}`,
    draft: response.data.Draft,
    allowNonOQ: response.data.AllowNonOQ,
    verifyCreateJob: response.data.VerifyCreateJob,
    verifyNCMS: response.data.VerifyNCMS,
    preferences: response.data.Preference,
  };
}

async function getCompanyQualifications(
  requestParams: CompanyQualificationRequest
): Promise<CompanyQualificationResponse> {
  const requestQueryParams: Pick<CompanyQualificationRequest, 'skip' | 'take'> = {
    skip: requestParams.skip,
    take: requestParams.take,
  };
  const requestData: Omit<CompanyQualificationRequest, 'companyId' | 'skip' | 'take'> = {
    sortBy: requestParams.sortBy,
    userIds: requestParams.userIds,
    costCenterIds: requestParams.costCenterIds,
    departmentIds: requestParams.departmentIds,
    jobIds: requestParams.jobIds,
    roleIds: requestParams.roleIds,
    supervisorIds: requestParams.supervisorIds,
    workLocationIds: requestParams.workLocationIds,
    searchBy: requestParams.searchBy.trim(),
  };

  const requestConfig: AxiosRequestConfig = {
    params: requestQueryParams,
  };
  const response = await verifyApi.post<CompanyQualificationResponse>(
    CompanyQualificationsRoute(requestParams.companyId),
    requestData,
    requestConfig
  );

  return response.data;
}

async function getCompanySeries(
  requestParams: CompanySeriesRequest
): Promise<CompanySeriesResponse> {
  const response = await verifyApi.get(CompanySeriesRoute(requestParams.companyId));

  return response.data;
}

async function getCompanySeriesTasks(
  requestParams: CompanySeriesTaskRequest
): Promise<CompanySeriesTaskResponse> {
  const requestConfig: AxiosRequestConfig = {
    params: {
      includeHistorical:
        requestParams.includeHistorical !== undefined && requestParams.includeHistorical !== null
          ? requestParams.includeHistorical
          : true,
    },
  };
  const response = await verifyApi.get(
    CompanySeriesTasksRoute(requestParams.companyId, requestParams.seriesId),
    requestConfig
  );

  if (Array.isArray(response.data?.tasks)) {
    response.data.tasks = response.data.tasks.map((remoteTask: TaskModel) => ({
      ...remoteTask,
      dateCreated: new Date(remoteTask.dateCreated),
    }));
  }

  return response.data;
}

async function getCompaniesUserStatistics(
  requestParams: CompaniesUserStatisticsRequest
): Promise<UserStatisticsResponse> {
  const requestConfig: AxiosRequestConfig = {
    params: { roleIds: requestParams.roleIds, include: requestParams.include },
    timeout: TwentySecondsInMilliseconds,
  };

  const response = await verifyApi.get<UserStatisticsResponse>(
    CompaniesUserStatisticsRoute(requestParams.companyId, requestParams.userId),
    requestConfig
  );

  return response.data;
}

async function getCompaniesUsersRoles(
  requestParams: CompaniesUsersRolesRequest
): Promise<CompaniesUsersRolesResponse> {
  const response = await verifyApi.get<CompaniesUsersRolesResponse>(
    CompaniesUsersRolesRoute(requestParams.companyId, requestParams.userId),
    {
      params: {
        roleIds: requestParams.roleIds,
      },
    }
  );

  return response.data;
}

async function getCompaniesUsersTasks(
  requestParams: CompaniesUsersTasksRequest
): Promise<CompaniesUsersTasksResponse> {
  const requestConfig: AxiosRequestConfig = { params: { roleIds: requestParams.roleIds } };

  if (requestParams.complete !== undefined) {
    requestConfig.params.complete = requestParams.complete;
  }

  const response = await verifyApi.get<CompaniesUsersTasksResponse>(
    CompaniesUsersTasksRoute(requestParams.companyId, requestParams.userId),
    requestConfig
  );

  return response.data;
}

async function getCompaniesUsersComponents(
  requestParams: CompaniesUsersTasksRequest
): Promise<UserComponentResponse> {
  const requestConfig: AxiosRequestConfig = { params: { roleIds: requestParams.roleIds } };

  if (requestParams.complete !== undefined) {
    requestConfig.params.complete = requestParams.complete;
  }

  const response = await verifyApi.get<UserComponentResponse>(
    CompaniesUsersComponentsRoute(requestParams.companyId, requestParams.userId),
    requestConfig
  );

  return response.data;
}

async function getCompaniesUsersTrainings(
  requestParams: CompaniesUsersTrainingsRequest
): Promise<UserTrainingResponse> {
  const requestConfig: AxiosRequestConfig = { params: { roleIds: requestParams.roleIds } };

  if (requestParams.complete !== undefined) {
    requestConfig.params.complete = requestParams.complete;
  }

  const response = await verifyApi.get<UserTrainingResponse>(
    CompaniesUsersTrainingsRoute(requestParams.companyId, requestParams.userId),
    requestConfig
  );

  return response.data;
}

async function getCompaniesUsersJobs(
  requestParams: CompaniesUsersJobsRequest
): Promise<CompaniesUsersJobsResponse> {
  const requestConfig: AxiosRequestConfig = { params: { jobIds: requestParams.jobIds } };

  const response = await verifyApi.get<CompaniesUsersJobsResponse>(
    CompaniesUsersJobsRoute(requestParams.companyId, requestParams.userId),
    requestConfig
  );

  return response.data;
}

async function getCompaniesRoles(
  requestParams: CompaniesRolesRequest
): Promise<CompaniesRolesResponse> {
  const requestConfig: AxiosRequestConfig = {
    params: requestParamUtils.getSearchRequestParams(requestParams),
  };

  const response = await verifyApi.get<CompaniesRolesResponse>(
    CompaniesRolesRoute(requestParams.companyId),
    requestConfig
  );

  return response.data;
}

async function getCompaniesCompanySites(
  requestParams: CompaniesCompanySitesRequest
): Promise<CompaniesCompanySiteResponse> {
  const requestConfig: AxiosRequestConfig = {
    params: requestParamUtils.getSearchRequestParams(requestParams),
  };

  const response = await verifyApi.get<CompaniesCompanySiteResponse>(
    CompaniesCompanySitesRoute(requestParams.companyId),
    requestConfig
  );

  return response.data;
}

async function getCompaniesSupervisors(
  requestParams: CompaniesSupervisorsRequest
): Promise<CompaniesSupervisorsResponse> {
  const requestConfig: AxiosRequestConfig = {
    params: requestParamUtils.getSearchRequestParams(requestParams),
  };

  const response = await verifyApi.get<CompaniesSupervisorsResponse>(
    CompaniesSupervisorsRoute(requestParams.companyId),
    requestConfig
  );

  return response.data;
}

async function getCompaniesCostCenters(
  requestParams: CompaniesCostCentersRequest
): Promise<CompaniesCostCentersResponse> {
  const requestConfig: AxiosRequestConfig = {
    params: requestParamUtils.getSearchRequestParams(requestParams),
  };

  const response = await verifyApi.get<CompaniesCostCentersResponse>(
    CompaniesCostCentersRoute(requestParams.companyId),
    requestConfig
  );

  return response.data;
}

async function getCompaniesDepartments(
  requestParams: CompaniesDepartmentsRequest
): Promise<CompaniesDepartmentsResponse> {
  const requestConfig: AxiosRequestConfig = {
    params: requestParamUtils.getSearchRequestParams(requestParams),
  };

  const response = await verifyApi.get<CompaniesDepartmentsResponse>(
    CompaniesDepartmentsRoute(requestParams.companyId),
    requestConfig
  );

  return response.data;
}

async function getCompaniesUsers(
  requestParams: CompaniesUsersRequest
): Promise<CompaniesUsersResponse> {
  const queryParams: Partial<typeof requestParams> =
    requestParamUtils.getSearchRequestParams(requestParams);

  if (requestParams.policy) {
    queryParams.policy = requestParams.policy;
  }

  if (requestParams.isVisible != null) {
    queryParams.isVisible = requestParams.isVisible;
  }

  const requestConfig: AxiosRequestConfig = {
    params: queryParams,
  };

  const response = await verifyApi.get<CompaniesUsersResponse>(
    CompaniesUsersRoute(requestParams.companyId),
    requestConfig
  );

  return response.data;
}

async function getCompaniesUsersAll(
  requestParams: CompaniesUsersAllRequest
): Promise<CompaniesUsersResponse> {
  const queryParams: Partial<typeof requestParams> =
    requestParamUtils.getSearchRequestParams(requestParams);

  queryParams.activeStatus = requestParams.activeStatus;

  const requestConfig: AxiosRequestConfig = {
    params: queryParams,
  };

  const response = await verifyApi.get<CompaniesUsersResponse>(
    CompaniesUsersAllRoute(requestParams.companyId),
    requestConfig
  );

  return response.data;
}

async function getCompaniesJobs(
  requestParams: CompaniesJobsRequest
): Promise<CompaniesJobsResponse> {
  const requestConfig: AxiosRequestConfig = {
    params: requestParamUtils.getSearchRequestParams(requestParams),
  };

  const response = await verifyApi.get<CompaniesJobsResponse>(
    CompaniesJobsRoute(requestParams.companyId),
    requestConfig
  );

  return response.data;
}

async function createCustomView(
  requestParams: CustomViewSaveRequest
): Promise<CustomViewReport<string>> {
  const response = await verifyApi.post<CustomViewReport<string>>(
    SaveCustomViewRoute(requestParams.companyId),
    { ...requestParams.data, reportData: JSON.stringify(requestParams.data.reportData) }
  );

  return response.data;
}

async function updateCustomView(
  requestParams: UpdateCustomViewRequest
): Promise<CustomViewReport<string>> {
  const response = await verifyApi.put<CustomViewReport<string>>(
    UpdateCustomViewRoute(requestParams.companyId, requestParams.customViewId),
    { ...requestParams.data, reportData: JSON.stringify(requestParams.data.reportData) }
  );

  return response.data;
}

async function deleteCustomView(requestParams: DeleteCustomViewRequest): Promise<void> {
  const response = await verifyApi.delete<void>(
    DeleteCustomViewRoute(requestParams.companyId, requestParams.customViewId)
  );

  return response.data;
}

async function getUsersCustomViews(
  requestParams: UsersCustomViewsRequest
): Promise<CustomViewsResponse<string>> {
  const requestQueryParams: Pick<UsersCustomViewsRequest, 'skip' | 'take'> = {
    skip: requestParams.skip,
    take: requestParams.take,
    ...requestParamUtils.getSearchRequestParams(requestParams),
  };

  const requestConfig: AxiosRequestConfig = {
    params: requestQueryParams,
  };

  const response = await verifyApi.get<CustomViewsResponse<string>>(
    UsersCustomViewsRoute(requestParams.companyId),
    requestConfig
  );

  return response.data;
}

async function getCustomViews(
  requestParams: GetCustomViewRequest
): Promise<CustomViewReport<string>> {
  const response = await verifyApi.get<CustomViewReport<string>>(
    ReadCustomViewsRoute(requestParams.companyId, requestParams.customViewId)
  );

  return response.data;
}

async function getCustomViewAccessUsers(
  requestParams: GetCustomViewAccessUsersRequest
): Promise<GetCustomViewAccessUsersResponse> {
  const response = await verifyApi.get<GetCustomViewAccessUsersResponse>(
    GetCustomViewAccessUsersRoute(requestParams.companyId, requestParams.customViewId)
  );

  return response.data;
}

async function updateCustomViewAccess(requestParams: UpdateCustomViewAccessRequest): Promise<void> {
  const requestBody: Omit<UpdateCustomViewAccessRequest, 'companyId' | 'customViewId'> = {
    customViewAccessRequests: requestParams.customViewAccessRequests,
  };

  const response = await verifyApi.put<void>(
    UpdateCustomViewAccessRoute(requestParams.companyId, requestParams.customViewId),
    requestBody
  );

  return response.data;
}

async function updateCustomViewConnection(
  requestParams: UpdateCustomViewConnectionRequest
): Promise<void> {
  const response = await verifyApi.put<void>(
    UpdateCustomViewConnectionRoute(requestParams.companyId, requestParams.customViewId)
  );

  return response.data;
}

async function getCustomViewConnections(
  requestParams: GetCustomViewConnectionsRequest
): Promise<CustomViewConnectionsResponse> {
  const requestConfig: AxiosRequestConfig = {
    params: { customViewIds: requestParams.customViewIds },
  };

  const response = await verifyApi.get<CustomViewConnectionsResponse>(
    GetCustomViewConnectionsRoute(requestParams.companyId),
    requestConfig
  );

  return response.data;
}

async function getMessageOfTheDayImageUrls(
  requestParams: GetMessageOfTheDayImageUrlsRequest
): Promise<GetMessageOfTheDayImageUrlsResponse> {
  const requestConfig: AxiosRequestConfig = {
    params: { extension: requestParams.extension },
  };

  const response = await verifyApi.get<GetMessageOfTheDayImageUrlsResponse>(
    GetMessageOfTheDayImageUrlsRoute(requestParams.companyId, requestParams.customViewId),
    requestConfig
  );

  return response.data;
}

async function getMessageOfTheDayImage(
  requestParams: GetMessageOfTheDayImageRequest
): Promise<GetMessageOfTheDayImageResponse> {
  const response = await verifyApi.get<GetMessageOfTheDayImageResponse>(
    GetMessageOfTheDayImageRoute(requestParams.companyId, requestParams.customViewId)
  );

  return response.data;
}

async function putMessageOfTheDayImageUrls(
  requestParams: PutMessageOfTheDayImageUrlsRequest
): Promise<PutMessageOfTheDayImageUrlsResponse> {
  const requestConfig: AxiosRequestConfig = {
    headers: { 'Content-Type': requestParams.messageOfTheDayImage.type },
  };

  const getImageUrlsResponse = await getMessageOfTheDayImageUrls({
    companyId: requestParams.companyId,
    customViewId: requestParams.customViewId,
    extension: file.getFileExtension(requestParams.messageOfTheDayImage),
  });

  await awsApi.put(
    getImageUrlsResponse.putSignedUrl,
    requestParams.messageOfTheDayImage,
    requestConfig
  );

  return getImageUrlsResponse;
}

async function getUsersPolicies(
  getUserPoliciesRequest: GetUserPoliciesRequest
): Promise<GetUserPoliciesResponse> {
  const requestConfig: AxiosRequestConfig = {
    params: { companyId: getUserPoliciesRequest.companyId },
  };

  const response = await verifyApi.get<GetUserPoliciesResponse>(
    GetUsersPoliciesRoute(getUserPoliciesRequest.companyId),
    requestConfig
  );

  return response.data;
}

async function getUsersCustomViewPolicies(
  getUsersCustomViewPoliciesRequest: GetUsersCustomViewPoliciesRequest
): Promise<GetUsersCustomViewPoliciesResponse> {
  const response = await verifyApi.get<GetUsersCustomViewPoliciesResponse>(
    GetUsersCustomViewPoliciesRoute(getUsersCustomViewPoliciesRequest.companyId)
  );

  return response.data;
}

async function getCompanyAssigners(companyId: number): Promise<UserResponse[]> {
  const response = await verifyApi.get<UserResponse[]>(GetCompanyAssignersRoute(companyId));

  return response.data;
}

async function getCompanyEvaluators(companyId: number): Promise<UserResponse[]> {
  const response = await verifyApi.get<UserResponse[]>(GetCompanyEvaluatorsRoute(companyId));

  return response.data;
}

async function getUserInformation(userId: number): Promise<UserInformation> {
  const response = await verifyApi.get<UserInformation>(GetUserInformation(userId));

  return response.data;
}

async function createCompanyUserImportSettings(
  requestParams: SaveCompanyImportSettingsRequest
): Promise<UserIntegration> {
  const response = await verifyApi.post(
    PostCompanyUserImportSettingsRoute(requestParams.companyId),
    { ...requestParams.data }
  );

  return response.data;
}

async function updateCompanyUserImportSettings(
  requestParams: SaveCompanyImportSettingsRequest
): Promise<UserIntegration> {
  const response = await verifyApi.put(
    PutCompanyUserImportSettingsRoute(requestParams.companyId, requestParams.userIntegrationId),
    { ...requestParams.data }
  );

  return response.data;
}

async function getAllTransferClientUser(companyId: number): Promise<CompanySftpUserResponse[]> {
  const response = await verifyApi.get<CompanySftpUserResponse[]>(
    GetAllTransferClientUser(companyId)
  );
  return response.data;
}

async function postCompanySftpUser(companyId: number): Promise<CreateCompanySftpUserResponse> {
  const response = await verifyApi.post<CreateCompanySftpUserResponse>(
    PostCompanySftpUserRoute(companyId)
  );

  return response.data;
}

async function postRegenerateCompanySftpCredentials(
  companyId: number,
  companySftpId: number
): Promise<CreateCompanySftpUserResponse> {
  const response = await verifyApi.post<CreateCompanySftpUserResponse>(
    PostRegenerateCompanySftpCredentialsRoute(companyId, companySftpId)
  );

  return response.data;
}

async function getUserIntegration(
  companyId: number,
  userIntegrationId: number
): Promise<UserIntegration> {
  const response = await verifyApi.get<UserIntegration>(
    GetUserIntegrationRoute(companyId, userIntegrationId)
  );
  return response.data;
}

export default {
  refreshToken,
  getCompanyDetails,
  getCompanyQualifications,
  getCompanySeries,
  getCompanySeriesTasks,
  getCompaniesUserStatistics,
  getCompaniesUsersRoles,
  getCompaniesUsersTasks,
  getCompaniesUsersComponents,
  getCompaniesUsersTrainings,
  getCompaniesUsersJobs,
  getCompaniesRoles,
  getCompaniesCompanySites,
  getCompaniesSupervisors,
  getCompaniesCostCenters,
  getCompaniesDepartments,
  getCompaniesUsers,
  getCompaniesJobs,
  createCustomView,
  updateCustomView,
  deleteCustomView,
  getUsersCustomViews,
  getCustomViews,
  getCustomViewAccessUsers,
  updateCustomViewAccess,
  updateCustomViewConnection,
  getCustomViewConnections,
  getMessageOfTheDayImageUrls,
  getMessageOfTheDayImage,
  putMessageOfTheDayImageUrls,
  getUsersPolicies,
  getUsersCustomViewPolicies,
  getCompanyAssigners,
  getCompanyEvaluators,
  getCompaniesUsersAll,
  getUserInformation,
  createCompanyUserImportSettings,
  updateCompanyUserImportSettings,
  getAllTransferClientUser,
  postCompanySftpUser,
  postRegenerateCompanySftpCredentials,
  getUserIntegration,
};
