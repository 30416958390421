function getTotalPages(numberOfItems: number, numberPerPage: number): number {
  return numberPerPage === 0 ? 0 : Math.ceil(numberOfItems / numberPerPage);
}

function getStartingRange(currentPage: number, totalPages: number, numPagesShown: number): number {
  // The number of pages that should be adjacent to the current page.
  const numAdjacentPages = Math.max(1, Math.floor(numPagesShown / 2));

  if (currentPage + numAdjacentPages > totalPages) {
    return totalPages - numPagesShown + 1;
  }

  return Math.max(1, currentPage - numAdjacentPages);
}

function getNextPage(currentPage: number, totalPages: number): number {
  let nextPage = currentPage + 1;

  if (nextPage > totalPages) {
    nextPage = 1;
  }

  return nextPage;
}

export default {
  getTotalPages,
  getStartingRange,
  getNextPage,
};
