import React, { useEffect } from 'react';
import { ApiRequestOptions, ApiResult } from '../../../api/apiHooks';
import { CompaniesUsersJobsRequest } from '../../../api/requests/companiesUsersJobsRequest';
import { CompaniesUsersRolesRequest } from '../../../api/requests/companiesUsersRolesRequest';
import { CompaniesUsersTasksRequest } from '../../../api/requests/companiesUsersTasksRequest';
import { CompaniesUsersJobsResponse } from '../../../api/responses/companiesUsersJobsResponse';
import { CompaniesUsersRolesResponse } from '../../../api/responses/companiesUsersRolesResponse';
import { CompaniesUsersTasksResponse } from '../../../api/responses/companiesUsersTasksResponse';
import { UserComponentResponse } from '../../../api/responses/userComponentResponse';
import { UserTrainingResponse } from '../../../api/responses/userTrainingResponse';
import { ThirtySecondsInMilliseconds } from '../../../constants/measurements';
import useIdle from '../../../hooks/useIdle';
import { TaskProfileDetailFeatures } from '../ReportDrawer/taskProfileDetailDisplayOptions';
import TaskProfileDetailModal from './TaskProfileDetailModal';
import JobAssocMethod from '../../../models/JobAssocMethod';
import ReportRequestFilter, {
  JobCompletionStatus,
  ReportRequestOutputType,
  ReportRequestType,
} from '../../../api/requests/ReportRequestFilter';
import { UserHook } from '../../../hooks/useUserId';

const idleActivityDetectorOptions = {
  idleTimeMilliseconds: ThirtySecondsInMilliseconds,
};

export interface TaskProfileDetailModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  userId: number;
  companyId: number;
  fullName: string;
  userName: string;
  roleIds: number[];
  jobIds: number[];
  features: TaskProfileDetailFeatures;
  jobAssocMethod: JobAssocMethod;
  useCompaniesUsersTasks: (
    requestParams: CompaniesUsersTasksRequest,
    options?:
      | ApiRequestOptions<CompaniesUsersTasksResponse, CompaniesUsersTasksResponse>
      | undefined
  ) => ApiResult<CompaniesUsersTasksResponse>;
  useCompaniesUsersRoles: (
    requestParams: CompaniesUsersRolesRequest,
    options?:
      | ApiRequestOptions<CompaniesUsersRolesResponse, CompaniesUsersRolesResponse>
      | undefined
  ) => ApiResult<CompaniesUsersRolesResponse>;
  useCompaniesUsersComponents: (
    requestParams: CompaniesUsersTasksRequest,
    options?: ApiRequestOptions<UserComponentResponse, UserComponentResponse> | undefined
  ) => ApiResult<UserComponentResponse>;
  useCompaniesUsersTrainings: (
    requestParams: CompaniesUsersTasksRequest,
    options?: ApiRequestOptions<UserTrainingResponse, UserTrainingResponse> | undefined
  ) => ApiResult<UserTrainingResponse>;
  useCompaniesUsersJobs: (
    requestParams: CompaniesUsersJobsRequest,
    options?: ApiRequestOptions<CompaniesUsersJobsResponse, CompaniesUsersJobsResponse> | undefined
  ) => ApiResult<CompaniesUsersJobsResponse>;
  useUserId: () => UserHook;
  useReportRequest: (
    requestParams: ReportRequestFilter,
    options?: ApiRequestOptions<CompaniesUsersJobsResponse, CompaniesUsersJobsResponse> | undefined
  ) => ApiResult<CompaniesUsersJobsResponse>;
}

const TaskProfileDetailModalContainer: React.FC<TaskProfileDetailModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  userId,
  companyId,
  fullName,
  userName,
  roleIds,
  jobIds,
  features,
  jobAssocMethod = JobAssocMethod.Jobs,
  useCompaniesUsersComponents,
  useCompaniesUsersJobs,
  useCompaniesUsersRoles,
  useCompaniesUsersTasks,
  useCompaniesUsersTrainings,
  useUserId,
  useReportRequest,
}) => {
  const { data: companyIncompleteTaskResponse } = useCompaniesUsersTasks(
    { userId, companyId, complete: false, roleIds },
    { enabled: isModalOpen }
  );

  const { data: companyCompleteTaskResponse } = useCompaniesUsersTasks(
    { userId, companyId, complete: true, roleIds },
    { enabled: isModalOpen }
  );

  const { data: companyRoleResponse } = useCompaniesUsersRoles(
    { userId, companyId, roleIds },
    { enabled: isModalOpen }
  );

  const { data: userIncompleteComponentResponse } = useCompaniesUsersComponents(
    {
      userId,
      companyId,
      roleIds,
      complete: false,
    },
    { enabled: isModalOpen }
  );

  const { data: userCompleteComponentResponse } = useCompaniesUsersComponents(
    {
      userId,
      companyId,
      roleIds,
      complete: true,
    },
    { enabled: isModalOpen }
  );

  const { data: userIncompleteTrainingResponse } = useCompaniesUsersTrainings(
    {
      userId,
      companyId,
      roleIds,
      complete: false,
    },
    { enabled: isModalOpen }
  );

  const { data: userCompleteTrainingResponse } = useCompaniesUsersTrainings(
    {
      userId,
      companyId,
      roleIds,
      complete: true,
    },
    { enabled: isModalOpen }
  );

  const { data: userJobsResponse, isFetching: userJobsIsFetching } = useCompaniesUsersJobs(
    {
      userId,
      companyId,
      jobIds,
    },
    {
      enabled: isModalOpen && jobAssocMethod === JobAssocMethod.Jobs,
    }
  );

  const { userId: currentUserId } = useUserId(); // get the current logged in user's Id

  // Create request object for Reporting API calls to get a user's job info
  const request: ReportRequestFilter = {
    currentCompanyId: companyId,
    currentUserId: currentUserId,
    reportOutputType: ReportRequestOutputType.UI,
    reportType: ReportRequestType.JOB_READINESS_ONBOARD_LIVE,
    includeContractors: false,
    enforceTaskProfiling: jobAssocMethod === JobAssocMethod.JobsRespectMappingsAndTaskProfiling,
    jobCompletionStatus: JobCompletionStatus.All,
    companyIds: [companyId],
    costCenterIds: [],
    departmentIds: [],
    jobMatrixIds: [],
    supervisorIds: [],
    userIds: [userId],
    workLocationIds: [],
  };

  // Reporting API hook for job info using new logic that supports complex job task mappings and (if user's custom view settings have it enabled)
  // enforcing task profiling
  const { data: userJobReportingAPIResponse, isFetching: userJobReportingAPIIsFetching } =
    useReportRequest(request, {
      enabled: isModalOpen && jobAssocMethod !== JobAssocMethod.Jobs && features.jobs,
    });

  const isIdle = useIdle(idleActivityDetectorOptions);

  useEffect(() => {
    if (isIdle && isModalOpen) {
      setIsModalOpen(false);
    }
  }, [isIdle, isModalOpen, setIsModalOpen]);

  return (
    <TaskProfileDetailModal
      isModalOpen={isModalOpen}
      isFetching={userJobsIsFetching || userJobReportingAPIIsFetching}
      setIsModalOpen={setIsModalOpen}
      fullName={fullName}
      userName={userName}
      incompleteTasks={companyIncompleteTaskResponse?.tasks ?? []}
      completeTasks={companyCompleteTaskResponse?.tasks ?? []}
      roles={companyRoleResponse?.roles ?? []}
      incompleteWePes={userIncompleteComponentResponse?.components ?? []}
      completeWePes={userCompleteComponentResponse?.components ?? []}
      incompleteTrainings={userIncompleteTrainingResponse?.trainings ?? []}
      completeTrainings={userCompleteTrainingResponse?.trainings ?? []}
      // Use Reporting API's job info response if we have it, otherwise use the legacy job response
      jobs={
        userJobReportingAPIIsFetching || userJobsIsFetching
          ? []
          : userJobReportingAPIResponse?.jobs ?? userJobsResponse?.jobs ?? []
      }
      features={features}
      jobAssocMethod={jobAssocMethod}
    />
  );
};

export default TaskProfileDetailModalContainer;
