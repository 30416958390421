import React from 'react';
import Badge from 'react-bootstrap/Badge';

export interface ListItemProps {
  text: string;
  onClick: () => void;
  canBeRemoved?: () => boolean;
  isDisabled?: boolean;
  isHidden?: boolean;
}

const BadgeListItem: React.FC<ListItemProps> = ({
  text,
  onClick,
  canBeRemoved = () => true,
  isHidden = false,
}) => {
  return (
    <Badge
      onClick={onClick}
      role="button"
      className={`m-1 py-2 cursorPointer ${isHidden ? ' d-none' : ' d-flex'}`}
      bg="primary"
    >
      <span>{text}</span>
      <span className={`ps-1${canBeRemoved() ? '' : ' d-none'}`} aria-hidden>
        &#x2716;
      </span>
    </Badge>
  );
};

export default BadgeListItem;
