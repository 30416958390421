import React from 'react';
import Nav from 'react-bootstrap/Nav';

interface TabProps<T extends string> {
  activeTabId: T;
  setActiveTab: (tabId: T) => void;
  tabId: T;
  panelId: string;
  children: React.ReactNode;
}

const Tab = <T extends string>({
  activeTabId,
  setActiveTab,
  tabId,
  panelId,
  children,
}: TabProps<T>): JSX.Element => (
  <Nav.Item className="text-start">
    <Nav.Link
      id={`nav-link-${tabId}`}
      eventKey={tabId}
      data-toggle="tab"
      href="#"
      role="tab"
      aria-selected={tabId === activeTabId}
      aria-controls={`nav-link-${panelId}`}
      className={tabId === activeTabId ? 'active' : ''}
      onClick={() => {
        setActiveTab(tabId);
      }}
    >
      {children}
    </Nav.Link>
  </Nav.Item>
);

export default Tab;
