import React, { useEffect, useState } from 'react';
import { DisplayItem } from '../../../models/displayItem';
import { UseDisplayItemsResponse } from '../../../models/useDisplayItemsResponse';
import MultiPartSelect from '../../MultiPartSelect/MultiPartSelect';

export interface TaskProfileMultiPartSelectContainerProps {
  useDisplayItemResponse: UseDisplayItemsResponse;
  onChange: (selectedIds: number[]) => void;
  selectedIds: number[];
  emptyListText: string;
  onSearchTextChange: (text: string) => void;
  tabId: string;
}

const TaskProfileMultiPartSelectContainer: React.FC<TaskProfileMultiPartSelectContainerProps> = ({
  useDisplayItemResponse,
  onChange,
  selectedIds,
  emptyListText,
  onSearchTextChange,
  tabId,
}) => {
  const { data, isLoading }: UseDisplayItemsResponse = useDisplayItemResponse;

  const [filteredSelectedDisplayItems, setFilteredSelectedDisplayItems] = useState<DisplayItem[]>(
    []
  );

  useEffect(() => {
    const filteredSelectedIds: DisplayItem[] = [];

    selectedIds.forEach(selectedId => {
      const value = data.find(item => item.itemId === selectedId);
      if (value !== undefined) {
        filteredSelectedIds.push(value);
      }
    });

    setFilteredSelectedDisplayItems(filteredSelectedIds);
  }, [data, selectedIds]);

  function handleSelectedItem(event: DisplayItem) {
    if (selectedIds.indexOf(event.itemId) > -1) {
      onChange(selectedIds.filter(selectedId => selectedId !== event.itemId));
    } else {
      onChange(selectedIds.concat(event.itemId));
    }
  }

  function handleOnClear() {
    onChange([]);
  }

  function handleOnSelectAll() {
    onChange(data.map(displayItem => displayItem.itemId));
  }

  return (
    <MultiPartSelect
      items={data}
      onSearch={onSearchTextChange}
      onChange={handleSelectedItem}
      selectedDisplayItems={filteredSelectedDisplayItems}
      emptyListText={emptyListText}
      isLoading={isLoading}
      onClear={handleOnClear}
      onSelectAll={handleOnSelectAll}
      tabId={tabId}
    />
  );
};

export default TaskProfileMultiPartSelectContainer;
